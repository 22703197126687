export default [
  //JASA
  {
    path: '/print-barcode',
    name: 'route-print-barcode',
    component: () => import('@/views/pages/heykama/printbarcode/List.vue'),
    meta: {
      module: 'Print Barcode',
      pageTitle: 'Print Barcode',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
]
