import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// Fungsi untuk memuat file terjemahan
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// Array untuk menyimpan key yang belum diterjemahkan
const missingKeys = []

// Konfigurasi VueI18n
export default new VueI18n({
  locale: "en", // Bahasa default
  fallbackLocale: "en", // Bahasa fallback
  messages: loadLocaleMessages(),
  missing: (locale, key) => {
    // console.warn(
    //   `[vue-i18n] Missing translation for key: "${key}" in locale: "${locale}"`
    // );
    console.log(`"${key}": "${key}",`);
    if (!missingKeys.includes(key)) {
      missingKeys.push(key); // Simpan key yang hilang ke array
    }
  },
});

// Ekspor daftar key yang belum diterjemahkan untuk analisis lebih lanjut
export { missingKeys };
