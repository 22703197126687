<template>
  <section>
    <b-row>
      <b-col md="12" sm="12" class="my-1">
        <b-button-group>
          <b-button @click.prevent="add" variant="primary">
            <!-- <feather-icon icon="PlusIcon" class="mr-50" /> -->
            {{ $t('Add') }}
          </b-button>
          <b-button :disabled="selectpackaging.length === 0" @click.prevent="massremove" variant="danger">
            <!-- <feather-icon icon="PlusIcon" class="mr-50" /> -->
            {{ $t('Mass Delete') }}
          </b-button>
        </b-button-group>
        <b-modal v-model="showModalAdd" size="xl" id="modal-tambah" cancel-variant="secondary" ok-title="Add"
          cancel-title="Batal" centered title="Form Add">
          <b-form>
            <b-row>
              <b-col md="6" sm="6" class="my-1">
                <b-form-group>
                  <label for="master">Kategori</label>
                  <v-select v-model="kategori_id" multiple :options="optkategori" :reduce="(option) => option.value"
                    label="text" />
                  <!-- <b-form-select multiple v-model="master_kategori_id" :options="optkategori" id="master"
                placeholder="Pilih Master Kategori" /> -->
                </b-form-group>
              </b-col>
              <b-col md="6" sm="6" class="my-1">
                <!-- <b-form-group>
                  <label for="master">Kategori</label>
                  <v-select @input="getBarang()" v-model="kategori_id2" :options="categoryaksesorisOptions"
                    :reduce="(option) => option.value" label="text" />
                </b-form-group> -->
                <b-form-group>
                  <label for="kategori">{{$t("Choose Items")}}</label> <b-button @click="getDataAksesoris()">Refresh</b-button>
                  <b-form-checkbox-group id="checkbox-group-1" v-model="barang_ids" :options="aksesoris"
                    name="flavour-1" class="demo-inline-spacing" />
                  <!-- <v-select v-model="barang_ids" multiple :options="aksesoris" :reduce="(option) => option.value"
                    label="text" /> -->
                </b-form-group>
                <!-- {{ barang_ids }} -->
              </b-col>
            </b-row>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                Add
              </b-button>
              <b-button size="sm" variant="danger" @click="resetForm">
                Batal
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal v-if="allowUpdate()" v-model="showModalEdit" id="modal-ubah" cancel-variant="secondary"
          ok-:title="$t('Edit')" cancel-title="Batal" centered title="Form Ubah">
          <b-form>
            <b-form-group>
              <label for="master">Master Kategori</label>
              <b-form-select v-model="master_kategori_id" :options="optmasterkategori" id="master"
                placeholder="Pilih Master Kategori" />
            </b-form-group>
            <b-form-group>
              <label for="kategori">k=Kategori:</label>
              <b-form-input v-model="kategori" id="kategori" placeholder="Nama kategori" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" variant="danger" @click="resetForm">
                Batal
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col md="2" sm="2" class="my-1">
              <b-form-group class="mb-0" :label="$t('Per page')" label-for="perPage" size="sm">
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="2" class="my-1">
              <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
                <b-input-group size="sm">
                  <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="8" sm="6" class="my-1">
              <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0" size="sm">
                <b-input-group>
                  <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                    @keydown.enter.prevent="getData()"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline-primary" @click="getData()" size="sm">
                      Cari
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-overlay :show="loading">
                <b-table striped hover responsive :per-page="perPage" :items="items" :fields="fields">
                  <template #head(checkbox)>
                    <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                  </template>
                  <template #cell(checkbox)="{ item }">
                    <b-form-checkbox v-model="selectpackaging" :value="item"></b-form-checkbox>
                  </template>
                  <template #cell(no)="row">
                    <strong class="text-center">
                      {{ (row.index + 1) }}
                    </strong>
                  </template>
                  <template #cell(actions)="row">
                    <b-button v-if="allowUpdate()" :title="$t('Edit')" size="sm" @click="edit(row.item)" class="mr-1"
                      variant="outline-info">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button :title="$t('Delete')" size="sm" @click="remove(row.item)" class="mr-1"
                      variant="outline-danger">
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </template>

                  <template #cell(barangs)="{ item }">
                    <!-- <b-card> -->
                    <ul>
                      <li v-for="value in item.barangs">
                        {{ value.nama }}
                      </li>
                    </ul>
                    <!-- </b-card> -->
                  </template>
                </b-table>
              </b-overlay>
            </b-col>

            <b-col cols="12">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
                class="my-0" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, VBTooltip,
  BAvatar, BForm,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend, BCard,
  BButton, BButtonGroup, BOverlay, BFormCheckboxGroup, BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BCardActions, VBTooltip, ToastificationContent,
    BModal, BForm,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend, BCard,
    BButton, BButtonGroup, BOverlay, BFormCheckboxGroup, BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selectpackaging: [],
      allChecked: false,
      showModalAdd: false,
      showModalEdit: false,
      id: null,
      kategori: null,
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: 'checkbox', label: '#' },
        {
          key: "no",
          label: "No",
        },
        {
          key: "kategori",
          label: "Kategori",
        },
        // { key: "nama", label: "Nama", sortable: true },
        { key: "barangs", label: "Product Packaging", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      loading: false,
      optmasterkategori: [],
      master_kategori_id: null,
      optkategori: [],
      kategori_id: [],
      optkategori2: [],
      kategori_id2: [],
      barang_ids: [],
      barangs: [],
      aksesoris: [],
      categoryaksesorisOptions: [],
      blok_id: null,
      akses: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // Set the initial number of items

    this.getDatablok()
    this.getData()

    // this.getDataMasKategori()

  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    allChecked(val) {
      if (val) {
        this.selectpackaging = this.items
        console.log('ALL', this.selectpackaging)
      }
      else {
        this.selectpackaging = []
      }
    },
  },
  methods: {
    async getBarang() {
      // const jenis = this.isOwner ? 1 : 2
      const params = {
        kategori_id: this.kategori_id2 ? this.kategori_id2 : null,
      };
      this.barangs = await this.$store.dispatch("barang/getData", params);
      this.barangs.map((barang) => {
        barang.text = barang.nama + ' - ' + barang.varian;
        barang.value = barang.id;
      });
    },
    maskategori() {
      this.$router.push(`/kategori/master`)
    },
    settingpackaging() {
      this.$router.push(`/kategori/settingpackaging`)
    },
    add() {
      this.id = null
      this.activeAction = 'tambah'
      this.showModalAdd = true
      this.showModalEdit = false
    },
    massremove() {
      let filteredData = this.selectpackaging.map(item => ({
        kategori_id: item.kategori_id,
        kategori: item.kategori
      }));
      let joinedCategories = this.selectpackaging.map(item => item.kategori).join(", ");
      this.$swal({
        title: 'Anda yakin?',
        text: `Data Setting Packaging dari "${joinedCategories}" ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('kategori/deleteSetting', filteredData)
            .then(() => {
              // this.pesanBerhasilHapus()
              this.displaySuccess({
                message: 'Berhasil Hapus'
              })
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              // this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
        }
      })
    },
    remove(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Data Setting Packaging dari "${item.kategori}" ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          let payload = {
            kategori_id: item.kategori_id
          }
          item.fungsi = 1 // soft delete
          this.$store.dispatch('kategori/deleteSetting', [payload])
            .then(() => {
              // this.pesanBerhasilHapus()
              this.displaySuccess({
                message: 'Berhasil Hapus'
              })
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              // this.pesanGagalHapus()
              this.displayError(e)
              return false
            })
        }
      })
    },
    resetForm() {
      this.id = null
      this.kategori = null
      this.showModalAdd = false
      this.showModalEdit = false
    },
    submit() {
      if (this.kategori_id == null || this.kategori_id == '') {
        // this.pesanGagalSimpan()
        this.displayWarning({
          message: 'Gagal menyimpan data, harap cek kembali data yang diisi'
        })
        return false
      }
      // let payload = {
      //   barang_id: this.barang_ids,
      //   kategori_id: this.kategori_id
      // }
      let payload2 = this.kategori_id.map(kategori_id => {
        let item = {
          barang_id: this.barang_ids,
          kategori_id: kategori_id
        };

        // Tambahkan `id` jika `this.id` ada
        if (this.id) {
          item.id = this.id;
        }

        return item;
      });

      this.$store.dispatch('kategori/saveSetting', payload2)
        .then(() => {
          if (this.activeAction == 'tambah') {
            this.id = null
          }
          this.kategori_id = null
          this.showModalAdd = false
          this.showModalEdit = false
          this.getData()
          this.displaySuccess({
            message: 'Berhasil Simpan'
          })
          // this.pesanBerhasilSimpan()
        })

        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    edit(item) {
      let { id, kategori, master_kategori_id } = item
      this.id = id
      this.master_kategori_id = master_kategori_id
      this.kategori = kategori
      this.showModalEdit = true
    },
    async getDatablok() {
      let params = {
        jenis: 1,
      };
      // if (this.isAdminGudang) {
      params.gudang_id = this.myGudang.id ? this.myGudang.id : this.myGudang.data.id;
      // }
      this.$store.dispatch("tokoblok/getData", params).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.tokoblok.datas));
        ter.map((item) => {
          item.value = item.id;
          item.text = item.blok;
        });
        this.blok_id = ter;
        this.getDataKategori()
      });
    },
    async getDataAksesoris() {
      const payload = {
        kategori_id: this.akses != null ? this.akses.join(',') : null,
        blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,

        // search: 'XIOMI'
      };
      await this.$store
        .dispatch("tokopenyimpanan/getData", payload)
        .then((response) => {
          let res = response
          res.map((item) => {
            item.value = item.barang.id;
            item.text = item.barang.nama + ' - ' + item.barang.varian;
          });
          this.aksesoris = res
          console.log('CEKK', this.aksesoris)
          // this.loading = false
        })

        .catch((e) => {
          this.loading = false
          this.displayError(e);
          return false;
        });
    },
    async getDataKategori() {
      await this.$store.dispatch('kategori/getData')
        .then((response) => {
          let res = response;
          let akses = [];

          res.forEach(jb => {
            // Check for "Lensa" or "Frame" categories and prevent duplicates
            if (
              jb.master_kategori &&
              jb.master_kategori.master_kategori &&
              (jb.master_kategori.master_kategori.match(/lensa/gi) ||
                jb.master_kategori.master_kategori.match(/frame/gi)) &&
              !this.optkategori.some(item => item.id === jb.id)
            ) {
              this.optkategori.push(jb);
            }

            // Check for accessory categories and prevent duplicates
            if (
              jb.kategori.match(/lens cleaner|kartu|lap|cepuk|case|voucher|box/gi)
            ) {
              if (!this.akses.some(item => item.id === jb.id)) {
                this.categoryaksesorisOptions.push(jb);
                this.akses.push(jb.id);
              }
            }
          });

          // Map to update text and value properties
          this.optkategori.forEach((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          // console.log('CEK', this.optkategori)
          this.categoryaksesorisOptions.forEach((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });

          // Fetch accessory data if applicable
          // if (akses.length > 0) {
          this.getDataAksesoris();
          // }
        })
        .catch(e => console.error(e));
    },
    getData() {
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        // order: this.sortBy ? this.sortBy : 'desc',
        start: currentPage,
        length: this.perPage,
        // filter_by: "nama_lengkap",
        // show_stock: 1,
        // kategori_id: this.category,
      };
      this.$store.dispatch('kategori/getDataSetting', payload)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kategori.datassetting));
          // let items = response.data
          let items_total = this.$store.state.kategori.totalssetting;
          this.items = items
          this.totalRows = items_total
          this.loading = false
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    getDataMasKategori() {
      const payload = {
        search: this.filter != null ? this.filter : null,
        type: 'barang',
        product_type: 'barang',
        kategori_id: this.kategori_id2 ? this.kategori_id2 : null,
      };
      // this.loading = true
      this.$store.dispatch('barang/getData', payload)
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.masterkategori.datas));
          // let items_total = this.$store.state.masterkategori.totals;

          items.map((item) => {
            item.value = item.id;
            item.text = item.master_kategori;
          });

          this.optmasterkategori = items
          // this.totalRows = items_total
          // this.loading = false
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch(e => {
          this.displayError(e)
          return false
        })
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
