var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('div', {
    staticClass: "bg-success text-white p-1"
  }, [_vm._v("ORDER")]), _c('div', {
    staticClass: "p-1 border"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadOrder
    }
  }, [_c('div', {
    staticClass: "p-1",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Total Order")])]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('div', {
    staticClass: "text-white text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#9fa0a2"
    }
  }, [_c('b-badge', {
    staticClass: "text-white p-1 mr-1",
    attrs: {
      "variant": "secondary"
    }
  }, [_c('span', [_vm._v("Total")])]), _c('strong', [_vm._v(_vm._s(_vm.total))])], 1), _c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#fde6ba"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("Lenses")])]), _c('strong', [_vm._v(_vm._s(_vm.total_lensa))])], 1), _c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#ebf6fc"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Frames")])]), _c('strong', [_vm._v(_vm._s(_vm.total_frame))])], 1), _c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#eff6ef"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Accs.")])]), _c('strong', [_vm._v(_vm._s(_vm.total_accs))])], 1)]), _c('div', {
    staticClass: "col-md-8 mt-1"
  }, [_c('div', {
    staticClass: "bg-white"
  }, [_c('canvas', {
    attrs: {
      "height": "300",
      "id": "orderChart"
    }
  })])])])])]), _c('div', {
    staticClass: "p-1 row mt-1"
  }, [_c('div', {
    staticClass: "p-1 col-md-6",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadCancel
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('h3', {
    staticClass: "mb-0 mt-1"
  }, [_vm._v("Total Products Cancel")])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "text-white text-center p-1 rounded",
    staticStyle: {
      "background": "#9fa0a2"
    }
  }, [_c('b-badge', {
    staticClass: "p-1 mr-1",
    attrs: {
      "variant": "secondary"
    }
  }, [_c('span', [_vm._v("Total")])]), _c('strong', [_vm._v(_vm._s(_vm.totalCancel))])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#fde6ba"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("Lenses")])]), _c('strong', [_vm._v(_vm._s(_vm.product_cancel.total_lensa))])], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#ebf6fc"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Frames")])]), _c('strong', [_vm._v(_vm._s(_vm.product_cancel.total_frame))])], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#eff6ef"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Accs.")])]), _c('strong', [_vm._v(_vm._s(_vm.product_cancel.total_accs))])], 1)])])])], 1), _c('div', {
    staticClass: "p-1 col-md-6",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadReturn
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('h3', {
    staticClass: "mb-0 mt-1"
  }, [_vm._v("Total Products Return")])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "text-white text-center p-1 rounded",
    staticStyle: {
      "background": "#9fa0a2"
    }
  }, [_c('b-badge', {
    staticClass: "p-1 mr-1",
    attrs: {
      "variant": "secondary"
    }
  }, [_c('span', [_vm._v("Total")])]), _c('strong', [_vm._v(_vm._s(_vm.totalReturn))])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#fde6ba"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("Lenses")])]), _c('strong', [_vm._v(_vm._s(_vm.product_return.total_lensa))])], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#ebf6fc"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Frames")])]), _c('strong', [_vm._v(_vm._s(_vm.product_return.total_frame))])], 1)]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#eff6ef"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Accs.")])]), _c('strong', [_vm._v(_vm._s(_vm.product_return.total_accs))])], 1)])])])], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }