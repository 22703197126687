<template>
  <b-card-actions action-collapse title="Pengaturan Role">
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click.prevent="addMenu" variant="primary">
          <feather-icon icon="PlusIcon" class="mr-50" />
          Add
        </b-button>
        <b-modal v-model="showModal" id="modal-tambah" cancel-variant="secondary" ok-title="Submit" cancel-title="Batal"
          title="Form Role Management" no-close-on-backdrop no-close-on-esc size="lg">
          <b-form>
            <b-row>
              <b-col sm="12" md="6" class="mb-2">
                <b-form-group>
                  <label for="level_id"><strong>Level</strong></label>
                  <b-form-select v-model="form.level_id" :options="levels" label="Level" />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6" class="mb-2">
                <b-form-group>
                  <label for="menu_id"><strong>Menu</strong></label>
                  <b-form-select v-model="form.menu_id" :options="menus" label="Menu" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <p>
                  <strong> Pengaturan Menu </strong>
                </p>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group>
                  <label for=""><strong>Addkan submenu?</strong></label>
                  <b-form-checkbox switch line v-model="isParentMenu" />
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <!-- Status di menu -->
                <b-form-group>
                  <label class="mb-1"><strong>Status di menu (opsional)</strong></label>
                  <v-select :options="statusOptions" v-model="selectedStatus" multiple label="text" />
                </b-form-group>
                <!-- / -->
              </b-col>
            </b-row>

            <b-form-group v-if="isParentMenu">
              <label for="sub_menu">Sub Menu</label>
              <v-select multiple :options="sub_menus" v-model="selectedSubMenus" label="text" />
            </b-form-group>
            <br />
            <b-row>
              <b-col sm="12" md="6">
                <label class="mb-1"><strong>Jenis Akses</strong></label>
                <section class="d-flex align-items-center mb-1">
                  <b-form-group>
                    <b-form-checkbox v-model="form.create" switch inline> Add </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="form.update" switch inline> Ubah </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox v-model="form.delete" switch inline> Hapus </b-form-checkbox>
                  </b-form-group>
                </section>
              </b-col>
              <b-col sm="12" md="6">
                <!-- Peruntukan Akses -->
                <label class="mb-1"><strong>Peruntukan Akses</strong></label>
                <section class="d-flex align-items-center">
                  <b-form-group>
                    <b-form-checkbox switch inline v-model="form.web"> Aplikasi Web </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox switch inline v-model="form.mobile"> Aplikasi Mobile </b-form-checkbox>
                  </b-form-group>
                </section>
                <!-- / -->
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group>
                  <label for=""><strong>Kode Notifikasi Per Level</strong> <feather-icon icon="InfoIcon" /></label>
                  <v-select :options="notif_params" label="text" v-model="form.param_notif"
                    :reduce="(option) => option.value"></v-select>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group>
                  <label for=""><strong>Filter Hanya Data Sendiri</strong></label>
                  <b-form-checkbox switch inline v-model="form.filter"> </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center mb-2">
              <b-button variant="primary" @click="submit"> Simpan </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group class="w-100">
          <div class="d-flex align-items-center w-100">
            <label class="d-inline-block text-sm-left mr-50">Level</label>
            <b-form-select id="perPageSelect" v-model="selectedLevel" :options="levels" class="w-50" />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm"
          label-for="filterInput" class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{ $t('Clear') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table striped small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
          :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #cell(no)="row">
            <strong class="text-center">
              {{ row.index + 1 }}
            </strong>
          </template>

          <template #cell(level_id)="data">
            <b-badge variant="dark">
              {{ getlevelName(data.item) }}
            </b-badge>
          </template>

          <template #cell(menu_id)="data">
            <b-badge variant="secondary">
              {{ getmenuName(data.item) }}
            </b-badge>
          </template>

          <template #cell(sub_menu)="data">
            <b-button v-if="getsubmenuName(data.item)" title="'Sub Menu'" size="sm"
              @click.prevent="$router.push(`/role-management/sub-menu/${data.item.id}`)" class="mr-1"
              variant="outline-success">
              <feather-icon icon="EyeIcon" />
            </b-button>
            <!-- <b-badge variant="info" v-if="getsubmenuName(data.item)">
              {{ getsubmenuName(data.item) }}
            </b-badge> -->
            <feather-icon icon="SlashIcon" class="text-danger" v-else></feather-icon>
          </template>
          <template #cell(create)="{ item }">
            <feather-icon v-if="item.create" icon="CheckIcon" class="text-success" size="24" />
            <feather-icon v-else icon="XIcon" class="text-danger" size="24" />
          </template>
          <template #cell(update)="{ item }">
            <feather-icon v-if="item.update" icon="CheckIcon" class="text-success" size="24" />
            <feather-icon v-else icon="XIcon" class="text-danger" size="24" />
          </template>
          <template #cell(delete)="{ item }">
            <feather-icon v-if="item.delete" icon="CheckIcon" class="text-success" size="24" />
            <feather-icon v-else icon="XIcon" class="text-danger" size="24" />
          </template>

          <template #cell(actions)="row">
            <b-dropdown id="dropdown-3" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" right
              class="dropdown-icon-wrapper mr-1">
              <template #button-content>
                <!-- <span class="mr-1"></span> -->
                <feather-icon icon="ListIcon" size="12" class="align-middle" />
              </template>
              <b-dropdown-item><b-button title="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1"
                  variant="outline-info">
                  <feather-icon icon="EditIcon" />Ubah </b-button></b-dropdown-item>
              <b-dropdown-item><b-button
                  v-if="row.item.name != 'Role Management' && row.item.name != 'Menu' && row.item.name != 'Level'"
                  title="'Hapus'" size="sm" @click="remove(row.item)" class="mr-1" variant="outline-danger">
                  <feather-icon icon="TrashIcon" />Hapus </b-button></b-dropdown-item>
            </b-dropdown>
            <b-button title="'Atas'" size="sm" @click="atas(row.item)" class="mr-1" variant="outline-success">
              <feather-icon icon="ArrowUpIcon" />
            </b-button>
            <b-button title="'Bawah'" size="sm" @click="bawah(row.item)" class="mr-1" variant="outline-info">
              <feather-icon icon="ArrowDownIcon" />
            </b-button>
          </template>

          <template #cell(urutan)="{ item }">
            {{ item.urutan }}
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BForm, BTable, VBTooltip, BDropdown, BDropdownItem, BAvatar, BBadge, BModal, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    BForm,
    BCardActions,
    ToastificationContent,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      rawSelectedSb: [],
      slSubMenus: [],
      levelOptions: [
        {
          value: 1,
          text: "Admin",
        },
        {
          value: 2,
          text: "Owner",
        },
        {
          value: 3,
          text: "General Manager",
        },
        {
          value: 4,
          text: "Finance",
        },
        {
          value: 5,
          text: "Admin Gudang",
        },
        {
          value: 6,
          text: "SPV",
        },
        {
          value: 7,
          text: "Sales",
        },
        {
          value: 8,
          text: "Human Resource",
        },
        {
          value: 9,
          text: "Admin Asset",
        },
        // {
        //     value: 10,
        //     text: "-"
        // },
        {
          value: 11,
          text: "cabang"
        },
        {
          value: 13,
          text: "marketing"
        },
        {
          value: 14,
          text: "register"
        },
      ],

      selectedLevel: 1,
      selectedStatus: [],
      statusOptions: [],
      selectedSubMenus: [],
      isParentMenu: false,
      showModal: false,
      menusForParent: [], // menus dengan id nya id_role
      menusForSingle: [], // menus dengan id nya menu_id itu sendiri
      sub_menus: [],
      menus: [],
      levels: [],
      id: null,
      form: {
        menu_id: 0,
        level_id: 0,
        sub_menu: 0,
        create: false,
        update: false,
        delete: false,
        web: false,
        mobile: false,
        filter: false,
        param_notif: null,
      },
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "level.nama_level", label: "Level", sortable: true },
        { key: "menu.name", label: "Menu", sortable: true },
        { key: "sub_menu", label: "Sub Menu", sortable: true },
        { key: "create", label: "Add" },
        { key: "update", label: "Edit" },
        { key: "delete", label: "Delete" },
        { key: "actions", label: "aksi" },
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      notif_params: [],
    };
  },
  watch: {
    selectedSubMenus(val) {
      if (val && !this.id) {
        this.rawSelectedSb = val
      }
    },
    selectedLevel(status) {
      if (status) {
        this.getData();
      }
    },
    "form.level_id"(level_id) {
      const filteredMenu = this.items.filter((role) => role.level && role.level.id == level_id);

      if (filteredMenu.length > 0) {
        this.menu_id = [];
        filteredMenu.map((role) => {
          let roleMenu = {
            value: role.id,
            text: "-",
          };

          if (role.menu) {
            roleMenu.text = role.menu.name;
          }
          this.menu_id.push(roleMenu);
        });
      }
    },
    "form.menu_id"(menu_id) {
      this.getStatus();
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.getNotifParams();
    // Set the initial number of items
    this.getData();
    this.getDatalevel();
    this.getDatamenu();
    setTimeout(() => (this.isParentMenu = false), 1000);
    // this.getDatasubmenu()
  },
  methods: {
    atas(item) {
      let payload = {
        role_id: item.id,
        aksi: "up",
      };
      this.$store
        .dispatch("role/urutan", [payload])
        .then((res) => {
          this.getData();
          this.displaySuccess({
            message: "Merubah urutan ke atas berhasil !",
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    bawah(item) {
      let payload = {
        role_id: item.id,
        aksi: "down",
      };
      this.$store
        .dispatch("role/urutan", [payload])
        .then((res) => {
          this.getData();
          this.displaySuccess({
            message: "Merubah urutan ke bawah berhasil !",
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getStatus() {
      const status = await this.$store.dispatch("statusrole/getData", {
        level_id: this.form.level_id,
        menu_id: this.form.menu_id,
      });

      this.statusOptions = [];
      status.map((st) => {
        this.statusOptions.push({
          text: st.nama_status,
          value: st.id,
        });
      });
    },
    addMenu() {
      this.id = null;
      this.resetForm();
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data role ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const payload = {
            fungsi: 2,
            id: item.id,
            level_id: item.level ? item.level.id : null,
            menu_id: item.menu ? item.menu.id : null
          }
          this.$store
            .dispatch("role/save", [payload])
            .then(() => {
              this.updateMenuPermission(item);
              this.getData();
              this.displaySuccess({
                message: "Role Management berhasil diperbaharui",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        menu_id: 0,
        level_id: 0,
        sub_menu: 0,
        create: false,
        update: false,
        delete: false,
        web: false,
        mobile: false,
        filter: false,
      };
    },
    async submit() {
      if (this.form.level_id == null || this.form.level_id == "") {
        this.displayError({
          message: "Harap pilih level!",
        });
        return false;
      }

      if (this.id) {
        this.form.id = this.id;
      }
      // const menusId = this.form.sub_menu
      let payload = {
        level_id: this.form.level_id,
        menu_id: this.form.menu_id,
        sub_menu: this.form.sub_menu,
        create: this.form.create,
        update: this.form.update,
        delete: this.form.delete,
        web: this.form.web,
        mobile: this.form.mobile,
        filter: this.form.filter,
        param_notif: this.form.param_notif,
      };
      if (this.form.id) {
        payload.id = this.form.id;
      }
      if (this.selectedStatus && this.selectedStatus.length > 0) {
        payload.status = this.selectedStatus.map((item) => item.value).join(",");
      }
      let payloads = [payload];

      // first input main menu
      try {
        const sendMenu = await this.$store.dispatch("role/save", payloads);

        let sbDeleted = []
        if (this.isParentMenu) {
          // if user create sub menu, get lastInsertedId Role to be parent menu
          const insertedRoleId = sendMenu.id;

          if (this.selectedSubMenus.length < 1) {
            this.displayError({
              message: "Harap pilih sub menu!",
            });
            return false;
          }

          if (this.id && this.rawSelectedSb.length > 0) {
            // check for submenus deleted
            this.rawSelectedSb.map(menu => {
              const role = this.slSubMenus.find(item => item.menu && item.menu.id == menu.id)
              const find = this.selectedSubMenus.find(item => item.id == menu.id)
              if (!find && role) {
                let payload = {
                  fungsi: 1,
                  id: role.id
                }
                sbDeleted.push(payload)
              }
            })
          }


          // set payload
          payload = [];
          this.selectedSubMenus.map((sb) => {
            const role = this.slSubMenus.find(item => item.menu && item.menu.id == sb.id)
            const datasb = {
              level_id: this.form.level_id,
              menu_id: sb.id,
              sub_menu: insertedRoleId,
              status: 1,
              create: this.form.create,
              update: this.form.update,
              delete: this.form.delete,
              web: this.form.web,
              mobile: this.form.mobile,
              filter: this.form.filter,
            }

            // check if sub menu is delete
            if (role) {
              datasb.id = role.id
            }

            payload.push(datasb);
          });

          const sendSubMenu = await this.$store.dispatch("role/save", payload);
          this.displaySuccess({
            message: "Menu berhasil dibuat!",
          });
        } else {
          // check if menu transform to single menu
          if (this.id && this.rawSelectedSb.length > 0) {
            // delete sub menu
            const sbPayloads = []
            this.rawSelectedSb.map(menu => {
              const role = this.slSubMenus.find(item => item.menu && item.menu.id == menu.id)
              if (role) {
                sbPayloads.push({
                  id: role.id,
                  fungsi: 2
                })
              }
            })

            if (sbPayloads.length > 0) {
              await this.$store.dispatch('role/save', sbPayloads)
              this.selectedSubMenus = []
              this.rawSelectedSb = []
            }
          }
          this.displaySuccess({
            message: "Menu berhasil dibuat!",
          });
        }

        if (this.activeAction == "tambah") {
          this.id = null;
        }

        if (sbDeleted.length > 0) {
          await this.$store.dispatch('role/save', sbDeleted)
        }
        this.getData();

        this.showModal = false;

        // if update role self, update ability immediately
        this.updateMenuPermission();
        this.resetForm();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async updateMenuPermission(role = null) {
      const { level } = this.user;
      // if user/admin add role to it self, update menu and abilities immediatly
      const isMyRoleUpdated = (localRole) => {
        if (localRole == null) {
          // action add || edit
          return this.form.level_id == level.id && this.form.web == true;
        } else {
          // action delete
          return localRole.level.id == level.id;
        }
      };

      if (isMyRoleUpdated(role)) {
        // call
        const roles = await this.$store.dispatch("role/getData", { level_id: level.id });
        localStorage.setItem("userRoles", JSON.stringify(roles));
        this.updateAbility(roles);

        // update menu in sidebar
        let accessMenu = roles.map((item) => item.menu);

        // add title and route in myAccessMenu
        accessMenu.map((item) => {
          item.title = item.name;
          item.route = item.url;
        });

        accessMenu = accessMenu.sort(function (a, b) {
          return a.urutan - b.urutan;
        });

        localStorage.setItem("accessMenu", JSON.stringify(accessMenu));

        this.resetForm();

        // refresh for get dynamic menu
        setTimeout(() => window.location.reload(), 500);
      }
    },
    async getNotifParams() {
      const notif_params = await this.$store.dispatch("role/getNotifParams");
      notif_params.map((item) => {
        this.notif_params.push({
          value: item.id,
          text: item.param,
        });
      });
    },
    async edit(item) {
      this.id = item.id;
      this.form.menu_id = item.menu ? item.menu.id : null
      this.form.level_id = item.level ? item.level.id : null
      this.form.create = item.create
      this.form.update = item.update
      this.form.delete = item.delete
      this.form.web = item.web
      this.form.mobile = item.mobile
      if (item.param_notif) {
        this.form.param_notif = item.param_notif.id;
      }
      if (!this.form.web) this.form.web = false;
      if (!this.form.mobile) this.form.mobile = false;
      if (!this.form.filter) this.form.filter = false;
      if (item.sub_menu && item.sub_menu.length > 0) {
        this.isParentMenu = true

        // Call the sub menu id
        const params = {
          sub_menu: item.id
        }

        let submenus = await this.$store.dispatch('role/getData', params)
        submenus = submenus.filter(item => item.menu)
        const submenuIds = submenus.map(item => item.menu.id)
        const slSubMenus = this.sub_menus.filter(menu => submenuIds.includes(menu.id))
        this.rawSelectedSb = slSubMenus

        this.slSubMenus = submenus
        this.selectedSubMenus = slSubMenus
      }
      else {
        this.isParentMenu = false

      }
      this.selectedStatus = [];
      if (item.status != null) {
        item.status.map((status) => {
          status.value = status.id;
          status.text = status.nama_status;
        });
      }
      this.selectedStatus = item.status;
      // this.form = item;
      // this.form.level_id = item.level ? item.level.id : null;
      // this.form.menu_id = item.menu ? item.menu.id : null;
      // this.form.sub_menu = 0;
      this.showModal = true;
    },
    async getData() {
      this.menu_id;
      let params = { level_id: 1 };
      if (this.selectedLevel && this.selectedLevel != "admin") {
        params.level_id = this.selectedLevel;
      }

      let roles = await this.$store.dispatch("role/getData", params)
      roles = roles.filter(role => role.menu != null && role.parent_menu == 0)
      this.items = roles
      this.totalRows = roles.length
    },
    getDatalevel() {
      this.$store.dispatch("level/getData", {}).then(() => {
        let level = JSON.parse(JSON.stringify(this.$store.state.level.datas));
        level.map((item) => {
          item.value = item.id;
          item.text = item.nama_level;
        });
        this.levels = level;
      });
    },
    getlevelName(data) {
      return data.level ? data.level.nama_level : "-";
    },
    getDatamenu() {
      this.$store.dispatch("menu/getData", {}).then(() => {
        let menu = JSON.parse(JSON.stringify(this.$store.state.menu.datas));
        menu.map((item) => {
          item.value = item.id;
          item.text = item.name + ' (URL : ' + item.url + ')';
        });
        this.sub_menus = menu;
        this.menus = menu;
      });
    },
    getmenuName(data) {
      return data.menu ? data.menu.name : "-";
    },
    getsubmenuName(item) {
      if (item.sub_menu) {
        return item.sub_menu.map((sb) => sb.name).join(", ");
      }

      return null;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  },
};
</script>
