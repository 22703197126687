var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('header', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('h4', [_vm._v(_vm._s(_vm.pembelian ? _vm.pembelian.no : ""))]), _c('div', {
    staticClass: "d-flex align-item-center"
  }, [_c('p', [_vm._v(" Tanggal Pembelian: " + _vm._s(_vm.pembelian ? _vm.humanDate(_vm.pembelian.tanggal) : "") + " ")])])]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm._v("Pembuat")]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v(_vm._s(_vm.pembelian ? _vm.pembelian.creator ? _vm.pembelian.creator.nama_lengkap : "-" : "-"))]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Tanggal Terima")]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v(_vm._s(_vm.penerimaan ? _vm.penerimaan.tanggal_penerimaan ? _vm.humanDate(_vm.penerimaan.tanggal_penerimaan) : "-" : "-"))]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("Keterangan")]), _c('strong', {
    staticClass: "d-block mb-2"
  }, [_vm._v(_vm._s(_vm.penerimaan ? _vm.penerimaan.keterangan ? _vm.penerimaan.keterangan : "-" : "-"))])]), !_vm.hasPermissionPay ? _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "4"
    }
  }, [_c('span', {
    staticClass: "d-block mb-1"
  }, [_vm._v("Informasi Supplier")]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v("Instansi: " + _vm._s(_vm.pembelian ? _vm.pembelian.supplier ? _vm.pembelian.supplier.instansi : null : ""))]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v("Email: " + _vm._s(_vm.pembelian ? _vm.pembelian.supplier ? _vm.pembelian.supplier.email : null : ""))]), _c('strong', {
    staticClass: "d-block mb-1"
  }, [_vm._v("No. HP: " + _vm._s(_vm.pembelian ? _vm.pembelian.supplier ? _vm.pembelian.supplier.nohp : null : ""))])]) : _vm._e()], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "mb-2"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('h4', {}, [_vm._v(" " + _vm._s(_vm.$t("Item Details"))), _vm.rincian && _vm.rincian.length > 0 ? _c('span', [_vm._v(" (" + _vm._s(_vm.rincian.length) + ")")]) : _vm._e()])]), _c('b-col', {
    attrs: {
      "md": "8",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Clear")))])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [(_vm.penerimaan ? _vm.penerimaan.selesai == 0 : null) ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Additional Items")))]) : _vm._e(), _c('barang-modal', {
    on: {
      "submit": _vm.onChooseBarang
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.rincian,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "cell(kode)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.barang_short ? item.barang_short.kode : null) + " ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.printbar(item, _vm.pembelian);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PrinterIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(barang)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.barang_short ? _c('span', [_vm._v(" " + _vm._s(item.barang_short.varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang_short.kategori ? item.barang_short.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang_short.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.pembelian.qty) + " /" + _vm._s(item.barang_short && item.barang_short.satuan ? item.barang_short.satuan.satuan.toLowerCase() : "") + " ")])];
      }
    }, {
      key: "cell(sisa)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.sisa) + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), item.jumlah > item.sisa ? [_c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v(_vm._s(_vm.$t("Jumlah tidak boleh lebih dari sisa")))])])] : _vm._e()];
      }
    }, {
      key: "cell(in_transit_stock)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "switch": ""
          },
          model: {
            value: item.in_transit_stock,
            callback: function ($$v) {
              _vm.$set(item, "in_transit_stock", $$v);
            },
            expression: "item.in_transit_stock"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm.penerimaan.selesai == 1 ? [_vm._v(" " + _vm._s(item.keterangan) + " ")] : [_c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })]];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.selisih = item.jumlah - item.pembelian.qty) + " ")];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga_beli)) + " ")];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }])
  })], 1)], 1), _vm.selectedBarangs.length > 0 ? _c('hr') : _vm._e(), _vm.selectedBarangs.length > 0 ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t("Additional Items"))), _vm.selectedBarangs && _vm.selectedBarangs.length > 0 ? _c('span', [_vm._v(" (" + _vm._s(_vm.selectedBarangs.length) + ")")]) : _vm._e()])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "fields": _vm.fieldstambahan,
      "items": _vm.selectedBarangs,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref11) {
        var fields = _ref11.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref12) {
        var index = _ref12.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(item.kategori ? item.kategori.kategori : "-") + " ")];
      }
    }, {
      key: "cell(no_container)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: MRT123213"
          },
          model: {
            value: item.no_container,
            callback: function ($$v) {
              _vm.$set(item, "no_container", $$v);
            },
            expression: "item.no_container"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.varian_item[0] ? item.varian_item[0].varian : item.varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(no_seal)",
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: GR123"
          },
          model: {
            value: item.no_seal,
            callback: function ($$v) {
              _vm.$set(item, "no_seal", $$v);
            },
            expression: "item.no_seal"
          }
        })];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref18) {
        var item = _ref18.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref19) {
        var item = _ref19.item;
        return [_c('b-input-group', {
          attrs: {
            "size": "lg",
            "append": item.satuan ? item.satuan.satuan : null
          }
        }, [_c('b-form-input', {
          staticClass: "w-70",
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        })], 1)];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref21) {
        var item = _ref21.item,
          index = _ref21.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref22) {
        var item = _ref22.item;
        return [_c('b-form-input', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }, {
      key: "cell(in_transit_stock)",
      fn: function (_ref23) {
        var item = _ref23.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "switch": ""
          },
          model: {
            value: item.in_transit_stock,
            callback: function ($$v) {
              _vm.$set(item, "in_transit_stock", $$v);
            },
            expression: "item.in_transit_stock"
          }
        })];
      }
    }], null, false, 1821761978)
  })], 1)], 1) : _vm._e(), _vm.rinciantambahan.length > 0 ? _c('hr') : _vm._e(), _vm.rinciantambahan.length > 0 ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t("Additional Items Detail"))), _vm.rinciantambahan && _vm.rinciantambahan.length > 0 ? _c('span', [_vm._v(" (" + _vm._s(_vm.rinciantambahan.length) + ")")]) : _vm._e()])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "fields": _vm.fieldstambahan,
      "items": _vm.rinciantambahan,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref24) {
        var fields = _ref24.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref25) {
        var index = _ref25.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref26) {
        var item = _ref26.item;
        return [_c('div', [_vm._v(" " + _vm._s(item.barang_short ? item.barang_short.kode : null) + " ")])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref27) {
        var item = _ref27.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.barang_short ? item.barang_short.varian : item.varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang_short.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang_short.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref28) {
        var item = _ref28.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref29) {
        var item = _ref29.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref30) {
        var item = _ref30.item;
        return [_c('b-input-group', {
          attrs: {
            "size": "lg",
            "append": item.barang_short.satuan ? item.barang_short.satuan.satuan : null
          }
        }, [_c('b-form-input', {
          staticClass: "w-70",
          attrs: {
            "type": "number",
            "readonly": ""
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        })], 1)];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref31) {
        var item = _ref31.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))) + " ")];
      }
    }, {
      key: "cell(in_transit_stock)",
      fn: function (_ref32) {
        var item = _ref32.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "switch": ""
          },
          model: {
            value: item.in_transit_stock,
            callback: function ($$v) {
              _vm.$set(item, "in_transit_stock", $$v);
            },
            expression: "item.in_transit_stock"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref33) {
        var item = _ref33.item;
        return [_vm._v(" " + _vm._s(item.keterangan) + " ")];
      }
    }], null, false, 4272948480)
  })], 1)], 1) : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [(_vm.penerimaan ? _vm.penerimaan.selesai == 1 : null) ? [_c('h5', [_vm._v(_vm._s(_vm.$t("Note Purchase")) + " :")]), _vm._v(" " + _vm._s(_vm.penerimaan ? _vm.penerimaan.keterangan : null) + " ")] : [_c('h5', [_vm._v(_vm._s(_vm.$t("Note Purchase")) + " :")]), _c('b-form-textarea', {
    attrs: {
      "placeholder": _vm.$t('Note Purchase')
    },
    model: {
      value: _vm.keterangan,
      callback: function ($$v) {
        _vm.keterangan = $$v;
      },
      expression: "keterangan"
    }
  })]], 2), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [(_vm.penerimaan ? _vm.penerimaan.selesai == 0 : null) ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "success"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "print-modal",
      "size": "md",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submitprintbarcode($event);
            }
          }
        }, [_vm._v("Print")])];
      },
      proxy: true
    }])
  }, [_c('header', {
    staticClass: "mb-1"
  }, [_c('h4', [_vm._v(" Product (" + _vm._s(_vm.printbarang ? _vm.printbarang.nama + " - " + _vm.printbarang.varian : null) + "). ")])]), _c('b-row', [_c('b-col', [_c('h4', [_vm._v("Label Design")]), _c('b-form-group', {
    attrs: {
      "label": "Select Label Design"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-around"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "exqr"
    },
    model: {
      value: _vm.selectlabel,
      callback: function ($$v) {
        _vm.selectlabel = $$v;
      },
      expression: "selectlabel"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "width": "75%"
    },
    attrs: {
      "src": require("../printbarcode/exqr.png"),
      "alt": "QR"
    }
  })]), _c('b-form-radio', {
    attrs: {
      "value": "exbar"
    },
    model: {
      value: _vm.selectlabel,
      callback: function ($$v) {
        _vm.selectlabel = $$v;
      },
      expression: "selectlabel"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "width": "75%"
    },
    attrs: {
      "src": require("../printbarcode/exbar.png"),
      "alt": "Barcode"
    }
  })])], 1)]), _c('b-form-group', {
    attrs: {
      "label": "Select Size"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "33x18"
    },
    model: {
      value: _vm.selectsize,
      callback: function ($$v) {
        _vm.selectsize = $$v;
      },
      expression: "selectsize"
    }
  }, [_vm._v(" 33 x 18 (mm) ")]), _c('b-form-radio', {
    attrs: {
      "value": "A4"
    },
    model: {
      value: _vm.selectsize,
      callback: function ($$v) {
        _vm.selectsize = $$v;
      },
      expression: "selectsize"
    }
  }, [_vm._v(" A4 ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Copies"
    }
  }, [_c('b-form-input', {
    staticClass: "w-70",
    attrs: {
      "type": "number",
      "value": _vm.printbarang ? _vm.printbarang.qty : 0
    },
    model: {
      value: _vm.custom_text.qty,
      callback: function ($$v) {
        _vm.$set(_vm.custom_text, "qty", $$v);
      },
      expression: "custom_text.qty"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Custom Text"
    }
  }, [_c('b-input-group', {}, [_c('b-form-input', {
    attrs: {
      "placeholder": "Masukkan teks pertama"
    },
    model: {
      value: _vm.custom_text.text,
      callback: function ($$v) {
        _vm.$set(_vm.custom_text, "text", $$v);
      },
      expression: "custom_text.text"
    }
  }), _c('b-form-select', {
    attrs: {
      "options": [{
        value: ' ',
        text: 'spasi'
      }, {
        value: '_',
        text: '_'
      }, {
        value: '-',
        text: '-'
      }]
    },
    model: {
      value: _vm.tandapemisah,
      callback: function ($$v) {
        _vm.tandapemisah = $$v;
      },
      expression: "tandapemisah"
    }
  }), _c('b-form-select', {
    attrs: {
      "options": _vm.printbarangoption,
      "placeholder": "Masukkan teks kedua"
    },
    model: {
      value: _vm.namabarang,
      callback: function ($$v) {
        _vm.namabarang = $$v;
      },
      expression: "namabarang"
    }
  })], 1), _vm.custom_text.text == null || _vm.custom_text.text == '' ? _c('small', {
    staticClass: "text-danger"
  }, [_c('i', [_vm._v("Wajib isi")])]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Kode Supplier"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Kode Supplier"
    },
    model: {
      value: _vm.kode_supplier,
      callback: function ($$v) {
        _vm.kode_supplier = $$v;
      },
      expression: "kode_supplier"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_vm.hasPermissionApprove && _vm.pembelian ? _c('div', [_vm.pembelian.izin == 0 ? _c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.approve($event);
      }
    }
  }, [_vm._v("Approve Pembelian")]) : _vm._e()], 1) : _vm._e(), _vm.hasPermissionCancel && _vm.pembelian ? _c('div', {
    staticClass: "ml-1"
  }, [_vm.pembelian.izin == 0 ? _c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.cancel($event);
      }
    }
  }, [_vm._v("Batalkan Pembelian")]) : _vm._e()], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }