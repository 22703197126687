import service from "@/services";
import EstimatedRevenue from "../../views/pages/heykama/dashboard/crm/components/EstimatedRevenue.vue";
import Order from "../../views/pages/heykama/dashboard/crm/components/Order.vue";

// Module Vuex kategori
export default {
  namespaced: true,
  state: {
    datas: [],
    dataLaba: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_DATA_LABA(state, data) {
      state.dataLaba = data;
    },
    SET_DATA_HEY_PENERIMAAN(state, data) {
      state.dataheypenerimaan = data;
    },
  },
  actions: {
    async checkCompanyDebt({}, params = {}) {
      try {
        const config = {
          url: "/check-hutang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getGrafikBarang({}, params = {}) {
      try {
        const config = {
          url: "/dashboard-info-barang",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getGrafikPenjualan({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard-grafik-penjualan",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data.labels && response.data.datas ? response.data : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getGrafikLabaRugi({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard-grafik-laba-rugi",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data.label && response.data.data ? response.data : [];
        commit("SET_DATA_LABA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async cardInfo({}, payload = {}) {
      try {
        const config = {
          url: "/dashboard-info",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    //HEYKAMA
    async heypenerimaan({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard-penerimaan",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;
        commit("SET_DATA_HEY_PENERIMAAN", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async EstimatedRevenue({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/estimated-revenue",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async Order({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/total-order",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async OrderCancel({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/total-product-cancel",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async OrderReturn({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/total-product-return",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async ProductSold({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/total-product-sold",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async ProductSoldPerStore({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/product-sold-by-category",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async ProductSoldAllStore({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/product-sold-by-store",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async Export({ commit }, params = {}) {
      try {
        const config = {
          url: "/dashboard/export",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
