<template>
  <div class="container mt-3">
    <b-card>
      <validation-observer ref="formKaryawan">
        <b-form class="">
          <div class="row">
            <!-- Personal Info -->
            <div class="col-md-8 mt-2">
              <b-card-title class="bg-success text-white p-1">Personal Info
              </b-card-title>
              <hr style="height: 3px; background-color: black; border: none" />
              <b-table-simple class="table border">
                <b-tbody>
                  <tr>
                    <td>Full Name</td>
                    <td>{{ profile.nama_lengkap }}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{{ profile.jk === "l" ? "Male" : "Female" }}</td>
                  </tr>
                  <tr>
                    <td>Birth Place</td>
                    <td>{{ profile.tempat_lahir }}</td>
                  </tr>
                  <tr>
                    <td>Birth Date</td>
                    <td>{{ profile.tanggal_lahir }}</td>
                  </tr>
                  <tr>
                    <td>Religion</td>
                    <td>{{ religion(profile.agama) }}</td>
                  </tr>
                  <tr>
                    <td>ID Type</td>
                    <td>KTP</td>
                  </tr>
                  <tr>
                    <td>ID No</td>
                    <td>{{ profile.nik }}</td>
                  </tr>
                </b-tbody>
              </b-table-simple>
            </div>

            <!-- Work Info -->
            <div class="col-md-4 mt-2">
              <b-card-title class="bg-success text-white p-1">Work</b-card-title>
              <hr style="height: 3px; background-color: black; border: none" />
              <b-table-simple class="table border">
                <b-tbody>
                  <tr>
                    <td>Division</td>
                    <td>Warehouse</td>
                  </tr>
                  <tr>
                    <td>Occupation</td>
                    <td>{{ profile.jabatan }}</td>
                  </tr>
                  <tr>
                    <td>Employee ID</td>
                    <td>{{ profile.nip }}</td>
                  </tr>
                </b-tbody>
              </b-table-simple>
              <center>
                <img v-if="myAvatarProfile" :src="myAvatarProfile" class="rounded mt-2"
                  style="width: 100px; height: 100px; object-fit: cover" alt="Profile Photo" />
                <br />
                <b-button variant="info" class="w-30" @click.prevent="openFotoModal">
                  Change Photo
                </b-button>
              </center>
            </div>
          </div>

          <!-- Contact Section -->
          <div class="row mt-4">
            <div class="col-md-6">
              <b-card-title class="bg-success text-white p-1">Contact</b-card-title>
              <hr style="height: 3px; background-color: black; border: none" />
              <b-form-group label="Personal Email">
                <b-form-input :value="profile.email"></b-form-input>
              </b-form-group>
              <b-form-group label="Phone Number">
                <b-form-input :value="profile.no_hp"></b-form-input>
              </b-form-group>
              <b-form-group label="Residential Address">
                <b-form-textarea :value="profile.alamat"></b-form-textarea>
              </b-form-group>
            </div>

            <!-- Emergency Contact -->
            <div class="col-md-6">
              <b-card-title class="bg-success text-white p-1">Emergency Contact</b-card-title>
              <hr style="height: 3px; background-color: black; border: none" />
              <b-form-group label="Contact Name">
                <b-form-input value="Emergency Name"></b-form-input>
              </b-form-group>
              <b-form-group label="Phone Number">
                <b-form-input value="Emergency Phone"></b-form-input>
              </b-form-group>
              <b-form-group label="Relationship">
                <b-form-input value="Relationship"></b-form-input>
              </b-form-group>
            </div>
          </div>

          <!-- Password Section -->
          <div class="row mt-4">
            <div class="col-12">
              <b-card-title class="bg-success text-white p-1">Password</b-card-title>
              <hr style="height: 3px; background-color: black; border: none" />
              <b-form-group label="Current Password">
                <div class="input-group">
                  <b-form-input :type="showCurrentPassword ? 'text' : 'password'"
                    v-model="passwords.current"></b-form-input>
                  <div class="input-group-append">
                    <b-button variant="outline-secondary" @click="togglePasswordVisibility('current')">
                      <feather-icon :icon="showCurrentPassword ? 'EyeOffIcon' : 'EyeIcon'" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="New Password">
                <div class="input-group">
                  <b-form-input :type="showNewPassword ? 'text' : 'password'" v-model="passwords.new"></b-form-input>
                  <div class="input-group-append">
                    <b-button variant="outline-secondary" @click="togglePasswordVisibility('new')">
                      <feather-icon :icon="showNewPassword ? 'EyeOffIcon' : 'EyeIcon'" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label="Confirm Password">
                <div class="input-group">
                  <b-form-input :type="showConfirmPassword ? 'text' : 'password'"
                    v-model="passwords.confirm"></b-form-input>
                  <div class="input-group-append">
                    <b-button variant="outline-secondary" @click="togglePasswordVisibility('confirm')">
                      <feather-icon :icon="showConfirmPassword ? 'EyeOffIcon' : 'EyeIcon'" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
              <b-button variant="warning" @click.prevent="submit" class="float-right">UPDATE</b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
      <foto-modal @submitFoto="submitFoto" />
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTableSimple,
  BTbody,
} from "bootstrap-vue";
import FotoModal from "./components/FotoModal.vue";
export default {
  components: {
    FotoModal,
    ValidationProvider,
    ValidationObserver,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BTableSimple,
    BTbody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      profile: {
        id: null,
        nama_lengkap: null,
        tanggal_lahir: null,
        tempat_lahir: null,
        jk: null,
        nik: null,
        nip: null,
        email: null,
        no_hp: null,
        agama: null,
        alamat: null,
        status_perkawinan: null,
        status_karyawan: null,
        kampus_sekolah: null,
        pendidikan_terakhir: null,
        kualifikasi_pendidikan: "Bagus",
        masa_kerja: "3 tahun",
        grade_karyawan: "A",
        jabatan: null,
        file_foto: null,
        tempat_lahir: "Bandung",
        tanggal_lahir: "2000-02-02",
        status_sp: 2,
        jabatan_id: null,
      },
      passwords: {
        current: "",
        new: "",
        confirm: "",
      },
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    myAvatarProfile() {
      return this.user.karyawan.url_foto;
    },
  },
  methods: {
    initProfile() {
      this.profile.id = this.user.id;
      this.profile.karyawan_id = this.user?.karyawan?.id;
      this.profile.email = this.user.email;
      this.profile.no_hp = this.user.no_hp;
      this.profile.nama_lengkap = this.user.karyawan.nama_lengkap;
      this.profile.nip = this.user.karyawan.nip;
      this.profile.nik = this.user.karyawan.nik;
      this.profile.jk = this.user.karyawan.jk;
      this.profile.tempat_lahir = this.user.karyawan.tempat_lahir;
      this.profile.tanggal_lahir = this.user.karyawan.tanggal_lahir;
      this.profile.status_sp = this.user.karyawan.status_sp;
      this.profile.status_perkawinan = this.user.karyawan.status_perkawinan;
      this.profile.jabatan_id = this.user.karyawan.jabatan
        ? this.user.karyawan.jabatan.id
        : null;
      this.profile.jabatan = this.user.karyawan.jabatan
        ? this.user.karyawan.jabatan.nama_jabatan
        : null;
      this.profile.grade_karyawan = this.user.karyawan.grade_karyawan;
      this.profile.masa_kerja = this.user.karyawan.masa_kerja;
      this.profile.kualifikasi_pendidikan =
        this.user.karyawan.kualifikasi_pendidikan;
      this.profile.pendidikan_terakhir = this.user.karyawan.pendidikan_terakhir;
      this.profile.kampus_sekolah = this.user.karyawan.kampus_sekolah;
      this.profile.alamat = this.user.karyawan.alamat;
      this.profile.agama = this.user.karyawan.agama;
      console.log("this.profile", this.profile);
    },
    togglePasswordVisibility(type) {
      if (type === "current")
        this.showCurrentPassword = !this.showCurrentPassword;
      else if (type === "new") this.showNewPassword = !this.showNewPassword;
      else if (type === "confirm")
        this.showConfirmPassword = !this.showConfirmPassword;
    },
    religion(religion) {
      if (religion == 1) {
        return "Islam";
      } else if (religion == 2) {
        return "Kristen";
      } else if (religion == 3) {
        return "Katolik";
      } else if (religion == 4) {
        return "Hindu";
      } else if (religion == 5) {
        return "Buddha";
      } else if (religion == 6) {
        return "Khonghuchu";
      }
    },
    openFotoModal() {
      this.$bvModal.show("foto-modal");
    },
    async submitFoto(fd) {
      for (let key in this.profile) {
        if (this.profile[key]) {
          if (key != "email" && key != "no_hp") {
            if (key == "id") {
              fd.append(`data[0][${key}]`, this.profile["karyawan_id"]);
            } else {
              fd.append(`data[0][${key}]`, this.profile[key]);
            }
          }
        }
      }

      try {
        this.loading = true;
        const karyawan = await this.$store.dispatch("karyawan/save", fd);
        this.loading = false;
        this.$bvModal.hide("foto-modal");
        this.updateProfile(karyawan);
      } catch (e) {
        this.loading = false;
        this.$bvModal.show("foto-modal");
        this.displayError(e);
        return false;
      }
    },
    submit() {
      this.$refs.formKaryawan.validate().then((success) => {
        if (success) {
          let fd = this.profile;
          this.label = "Loading...";
          this.$store
            .dispatch("member/updatemember", fd)
            .then((karyawan) => {
              this.label = "Save Change";
              this.displaySuccess({
                message: "Profile berhasil diperbaharui",
              });
              this.updateProfile(karyawan);
              if (
                passwords.current != "" ||
                passwords.new != "" ||
                passwords.confirm != ""
              ) {
                this.updatePassword();
              }
            })
            .catch((e) => {
              this.label = "Save Change";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    updateProfile(karyawan) {
      const user = JSON.parse(localStorage.userData);
      if (user.karyawan) {
        user.karyawan.nama_lengkap = karyawan.nama_lengkap;
        user.karyawan.nip = karyawan.nip;
        user.karyawan.nik = karyawan.nik;
        user.karyawan.jk = karyawan.jk;
        user.karyawan.tempat_lahir = karyawan.tempat_lahir;
        user.karyawan.tanggal_lahir = karyawan.tanggal_lahir;
        user.karyawan.status_sp = karyawan.status_sp;
        user.karyawan.status_perkawinan = karyawan.status_perkawinan;
        user.karyawan.jabatan_id = karyawan.jabatan
          ? karyawan.jabatan.id
          : null;
        user.karyawan.jabatan = karyawan.jabatan
          ? karyawan.jabatan.nama_jabatan
          : null;
        user.karyawan.grade_karyawan = karyawan.grade_karyawan;
        user.karyawan.masa_kerja = karyawan.masa_kerja;
        user.karyawan.kualifikasi_pendidikan = karyawan.kualifikasi_pendidikan;
        user.karyawan.pendidikan_terakhir = karyawan.pendidikan_terakhir;
        user.karyawan.kampus_sekolah = karyawan.kampus_sekolah;
        user.karyawan.alamat = karyawan.alamat;
        user.karyawan.agama = karyawan.agama;
        user.karyawan.url_foto = karyawan.url_foto;

        localStorage.setItem("userData", JSON.stringify(user));
        window.location.reload();
      }
    },
    updatePassword() {
      let payload = this.user.karyawan;
      payload.password = this.forms.new;
      payload.member_id = this.user.member_id;
      this.$store
        .dispatch("member/updatemember", payload)
        .then(() => {
          this.displaySuccess({
            message: "Password berhasil diubah!",
          });
        })
        .catch((e) => this.displayError(e));
    },
  },

  mounted() {
    this.initProfile();
  },
};
</script>
