<template>
  <main>
    <div class="bg-success text-white p-2">PRODUCT SOLD by STORE</div>
    <div class="p-1 border">
      <b-overlay :show="loadProductSold">
        <div
          class="p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row">
            <div class="col-md-2">
              <h3 class="mb-0">Total Product Sold</h3>
            </div>
            <div class="col-md-2">
              <div
                style="background: #9fa0a2"
                class="text-white text-center p-1 rounded mt-1"
              >
                <b-badge variant="secondary" class="text-white p-1 mr-1">
                  <span>Total</span>
                </b-badge>
                <strong>{{ totalProductSold }}</strong>
              </div>
              <div
                style="background: #fde6ba"
                class="text-dark text-center p-1 rounded mt-1"
              >
                <b-badge variant="warning" class="text-dark p-1 mr-1">
                  <span>Lenses</span>
                </b-badge>
                <strong>{{ total_lensa }}</strong>
              </div>
              <div
                style="background: #ebf6fc"
                class="text-dark text-center p-1 rounded mt-1"
              >
                <b-badge variant="info" class="text-dark p-1 mr-1">
                  <span>Frames</span>
                </b-badge>
                <strong>{{ total_frame }}</strong>
              </div>
              <div
                style="background: #eff6ef"
                class="text-dark text-center p-1 rounded mt-1"
              >
                <b-badge variant="success" class="text-dark p-1 mr-1">
                  <span>Accs.</span>
                </b-badge>
                <strong>{{ total_accs }}</strong>
              </div>
            </div>
            <div class="col-md-8 mt-1">
              <div class="bg-white">
                <canvas height="300" id="productSoldChart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-overlay :show="loadProductSoldPerStore">
        <div
          class="mt-1 p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row">
            <div class="col-md-6">
              <div
                style="background: #fde6ba"
                class="text-dark text-center p-1 rounded mt-1 col-sm-4"
              >
                <b-badge variant="warning" class="text-dark p-1 mr-1">
                  <span>Lenses</span>
                </b-badge>
                <strong>{{ lensa.total }}</strong>
              </div>
              <table
                class="table table-bordered table-responsive table-striped bg-white mt-4"
              >
                <tr>
                  <td v-for="(data, i) in lensa.datas" :key="i">
                    {{ data.channel_name }}
                  </td>
                </tr>
                <tr>
                  <td v-for="(data, i) in lensa.datas" :key="i">
                    {{ data.total }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-6">
              <canvas class="bg-white" id="lensaChart"></canvas>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-overlay :show="loadProductSoldPerStore">
        <div
          class="mt-1 p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row">
            <div class="col-md-6">
              <div
                style="background: #ebf6fc"
                class="text-dark text-center p-1 rounded mt-1 col-sm-4"
              >
                <b-badge variant="info" class="text-dark p-1 mr-1">
                  <span>Frames</span>
                </b-badge>
                <strong>{{ frame.total }} </strong>
              </div>
              <table
                class="table table-bordered table-responsive table-striped bg-white mt-4"
              >
                <tr>
                  <td v-for="(data, i) in frame.datas" :key="i">
                    {{ data.channel_name }}
                  </td>
                </tr>
                <tr>
                  <td v-for="(data, i) in frame.datas" :key="i">
                    {{ data.total }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-6">
              <canvas class="bg-white" id="frameChart"></canvas>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-overlay :show="loadProductSoldPerStore">
        <div
          class="mt-1 p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row">
            <div class="col-md-6">
              <div
                style="background: #eff6ef"
                class="text-dark text-center p-1 rounded mt-1 col-sm-4"
              >
                <b-badge variant="success" class="text-dark p-1 mr-1">
                  <span>Accs.</span>
                </b-badge>
                <strong>{{ accs.total }}</strong>
              </div>
              <table
                class="table table-bordered table-responsive table-striped bg-white mt-4"
              >
                <tr>
                  <td v-for="(data, i) in accs.datas" :key="i">
                    {{ data.channel_name }}
                  </td>
                </tr>
                <tr>
                  <td v-for="(data, i) in accs.datas" :key="i">
                    {{ data.total }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-md-6">
              <canvas class="bg-white" id="accsChart"></canvas>
            </div>
          </div>
        </div>
      </b-overlay>
      <b-overlay :show="loadProductSoldAllStore">
        <div
          class="mt-1 p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row">
            <div class="col-md-2 mt-1">
              <h3 class="mb-0">Total Product Sold By Store</h3>
            </div>
            <div class="col-md-3 mt-1">
              <table class="bg-white table table-bordered">
                <tr v-for="(data, i) in dataProductSoldAllStore" :key="i">
                  <td>{{ data.text }}</td>
                  <td>{{ data.total }}</td>
                </tr>
              </table>
            </div>
            <div class="col-md-7 mt-1">
              <div class="bg-white">
                <canvas height="300" id="productSoldAllStoreChart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </main>
</template>

<script>
import Chart from "chart.js";
import { BBadge, BOverlay } from "bootstrap-vue";

export default {
  props: [
    "loadProductSold",
    "loadProductSoldPerStore",
    "loadProductSoldAllStore",
    "dataProductSold",
    "totalProductSold",
    "grafikProductSold",
    "dataProductSoldPerStore",
    "dataProductSoldAllStore",
    "grafikProductSoldAllStore",
  ],
  data() {
    return {
      total_lensa: 0,
      total_frame: 0,
      total_accs: 0,
      lensa: {},
      frame: {},
      accs: {},
    };
  },
  components: {
    BBadge,
    BOverlay,
  },
  watch: {
    dataProductSold(val) {
      this.total_lensa = val.find((res) => {
        return res.mark_category_as == "lense";
      }).total;
      this.total_frame = val.find((res) => {
        return res.mark_category_as == "frame";
      }).total;
      this.total_accs = val.find((res) => {
        return res.mark_category_as == "accessory";
      }).total;
    },
    grafikProductSold(val) {
      this.renderProductSoldChart();
    },
    dataProductSoldPerStore(val) {
      this.lensa = val.find((res) => {
        return res.mark_category_as == "lense";
      });
      this.frame = val.find((res) => {
        return res.mark_category_as == "frame";
      });
      this.accs = val.find((res) => {
        return res.mark_category_as == "accessory";
      });
      this.renderProductSoldPerStore();
    },
    grafikProductSoldAllStore(val) {
      this.renderProductSoldAllStoreChart();
    },
  },
  methods: {
    renderProductSoldChart() {
      const ctx = document.getElementById("productSoldChart").getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: this.grafikProductSold,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    renderProductSoldPerStore() {
      const lensa = document.getElementById("lensaChart").getContext("2d");
      const frame = document.getElementById("frameChart").getContext("2d");
      const accs = document.getElementById("accsChart").getContext("2d");
      new Chart(lensa, {
        type: "pie",
        data: this.lensa.graphic_data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
      new Chart(frame, {
        type: "pie",
        data: this.frame.graphic_data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
      new Chart(accs, {
        type: "pie",
        data: this.accs.graphic_data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
    renderProductSoldAllStoreChart() {
      const ctx = document
        .getElementById("productSoldAllStoreChart")
        .getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: this.grafikProductSoldAllStore,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

<style>
canvas {
  max-height: 600px;
}
</style>
