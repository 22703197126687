var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.add($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Add')) + " ")]), _c('b-button', {
    attrs: {
      "disabled": _vm.selectpackaging.length === 0,
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.massremove($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Mass Delete')) + " ")])], 1), _c('b-modal', {
    attrs: {
      "size": "xl",
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Add"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Add ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalAdd,
      callback: function ($$v) {
        _vm.showModalAdd = $$v;
      },
      expression: "showModalAdd"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "master"
    }
  }, [_vm._v("Kategori")]), _c('v-select', {
    attrs: {
      "multiple": "",
      "options": _vm.optkategori,
      "reduce": function (option) {
        return option.value;
      },
      "label": "text"
    },
    model: {
      value: _vm.kategori_id,
      callback: function ($$v) {
        _vm.kategori_id = $$v;
      },
      expression: "kategori_id"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "kategori"
    }
  }, [_vm._v(_vm._s(_vm.$t("Choose Items")))]), _vm._v(" "), _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.getDataAksesoris();
      }
    }
  }, [_vm._v("Refresh")]), _c('b-form-checkbox-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "id": "checkbox-group-1",
      "options": _vm.aksesoris,
      "name": "flavour-1"
    },
    model: {
      value: _vm.barang_ids,
      callback: function ($$v) {
        _vm.barang_ids = $$v;
      },
      expression: "barang_ids"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.allowUpdate() ? _c('b-modal', {
    attrs: {
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-:title": "$t('Edit')",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Ubah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Batal ")])], 1)];
      },
      proxy: true
    }], null, false, 459184876),
    model: {
      value: _vm.showModalEdit,
      callback: function ($$v) {
        _vm.showModalEdit = $$v;
      },
      expression: "showModalEdit"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "master"
    }
  }, [_vm._v("Master Kategori")]), _c('b-form-select', {
    attrs: {
      "options": _vm.optmasterkategori,
      "id": "master",
      "placeholder": "Pilih Master Kategori"
    },
    model: {
      value: _vm.master_kategori_id,
      callback: function ($$v) {
        _vm.master_kategori_id = $$v;
      },
      expression: "master_kategori_id"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "kategori"
    }
  }, [_vm._v("k=Kategori:")]), _c('b-form-input', {
    attrs: {
      "id": "kategori",
      "placeholder": "Nama kategori"
    },
    model: {
      value: _vm.kategori,
      callback: function ($$v) {
        _vm.kategori = $$v;
      },
      expression: "kategori"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPage",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "8",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari",
      "size": "sm"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectpackaging,
            callback: function ($$v) {
              _vm.selectpackaging = $$v;
            },
            expression: "selectpackaging"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": _vm.$t('Edit'),
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": _vm.$t('Delete'),
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }, {
      key: "cell(barangs)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('ul', _vm._l(item.barangs, function (value) {
          return _c('li', [_vm._v(" " + _vm._s(value.nama) + " ")]);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }