<template>
  <b-overlay :show="loading">
    <div class="card" id="printableArea" v-if="detailTransaksi">
      <div class="card-body">
        <!-- <center>
          <img class="mb-4" src="../../../../../public/assets/heykama-sm.png" alt="" style="width: 10%" />
        </center> -->
        <div class="d-flex justify-content-between">
          <h3><b>INVOICE</b></h3>
          <h2>
            <!-- <b v-if="detailTransaksi.status_bayar == 'lunas'" class="text-success">LUNAS</b>
            <b v-else class="text-danger">BELUM LUNAS</b> -->
          </h2>
        </div>
        <span class="text--primary h6"
          ><b>{{ detailTransaksi.no_invoice }}</b></span
        >
        <div class="row mt-3">
          <div class="col-sm">
            <h5><b>DITERBITKAN ATAS NAMA</b></h5>
            Penjual : <b>{{ user.karyawan.nama_lengkap }}</b>
          </div>
          <div class="col-sm">
            <h5><b>UNTUK</b></h5>
            <table>
              <tr>
                <td width="200px">Pembeli</td>
                <td>
                  <b>
                    :
                    {{
                      detailTransaksi && detailTransaksi.customer
                        ? detailTransaksi.customer.nama
                        : "-"
                    }}</b
                  >
                </td>
              </tr>
              <tr>
                <td>Tanggal Pembelian</td>
                <td>
                  <b>: {{ humanDate(detailTransaksi.waktu_transaksi) }}</b>
                </td>
              </tr>
              <tr>
                <td>No. Telepon</td>
                <td>
                  <b
                    >:
                    {{
                      detailTransaksi.customer
                        ? detailTransaksi.customer.no_hp
                        : "-"
                    }}</b
                  >
                </td>
              </tr>
              <tr>
                <td style="display: flex">Alamat Pembelian</td>
                <td>
                  <b
                    >:
                    {{
                      detailTransaksi &&
                      detailTransaksi.customer &&
                      detailTransaksi.customer.data_pengiriman
                        ? detailTransaksi.customer.data_pengiriman.alamat
                        : "-"
                    }}</b
                  >,
                  {{
                    detailTransaksi &&
                    detailTransaksi.customer &&
                    detailTransaksi.customer.data_pengiriman
                      ? detailTransaksi.customer.data_pengiriman.desa.name
                      : "-"
                  }},
                  {{
                    detailTransaksi &&
                    detailTransaksi.customer &&
                    detailTransaksi.customer.data_pengiriman
                      ? detailTransaksi.customer.data_pengiriman.kecamatan.name
                      : "-"
                  }},
                  {{
                    detailTransaksi &&
                    detailTransaksi.customer &&
                    detailTransaksi.customer.data_pengiriman
                      ? detailTransaksi.customer.data_pengiriman.kab_kota.name
                      : "-"
                  }},
                  {{
                    detailTransaksi &&
                    detailTransaksi.customer &&
                    detailTransaksi.customer.data_pengiriman
                      ? detailTransaksi.customer.data_pengiriman.provinsi.name
                      : "-"
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="table-responsive mt-5">
          <table class="table">
            <thead>
              <tr>
                <th>INFO PRODUK</th>
                <th>JUMLAH</th>
                <th>HARGA BARANG (Rp)</th>
                <th>TOTAL HARGA (Rp)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in detailTransaksi.item" :key="i">
                <td>
                  <div class="text--primary">{{ item.varian }}</div>
                  <div class="text--primary">{{ item.barang_nama }}</div>
                </td>
                <td>{{ item.qty }}</td>
                <td>{{ formatRupiah(item.harga) }}</td>
                <td>{{ formatRupiah(item.total_harga) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col-sm-6">
            Catatan : <br />
            {{ detailTransaksi.catatan }}
            <!-- <br>
            Prescription :<br>
            <b-row class="mt-1" v-if="dataresep.length > 0">
              <b-col>
                <template v-for=" (item, index) in dataresep">
                  <b-button variant="warning" block :id="'popover-target-' + item.id"><feather-icon icon="PrinterIcon"
                      class="mr-1" @click="printResep2(item)" />
                    {{ $t('Print Data Prescription') + (index + 1) }}
                    <b-popover variant="warning" :target="'popover-target-' + item.id" triggers="hover" placement="top">
                      <template #title>Resep</template>
<ul class="list-unstyled">
  <li v-if="item.items[2]">Frame</li>
  <ul v-if="item.items[2]">
    <li>{{ item.items[2].penyimpanan.nama + ' - ' + item.items[2].penyimpanan.varian + ' x'
      +
      item.items[2].qty }}</li>
  </ul>
  <li>Lensa</li>
  <ul>
    <li>Kiri : {{ item.prescription.left.sph + ' x' + 1 }}</li>
    <li>Kanan : {{ item.prescription.right.sph + ' x' + 1 }}</li>
  </ul>
</ul>
<b-button variant="primary" block @click="printResep2(item)"><feather-icon icon="PrinterIcon" class="mr-1" />
  {{ $t('Print Data Prescription ')
  + (index + 1) }}
</b-button>
</b-popover>
</b-button>
</template>
</b-col>
</b-row> -->
          </div>
          <div class="col-sm">
            <div class="d-flex justify-content-between">
              <div><b>TOTAL HARGA</b></div>
              <div>
                <b>Rp. {{ formatRupiah(detailTransaksi.uang_masuk) }}</b>
              </div>
            </div>
            <!-- <div class="d-flex justify-content-between">
              <div>Ongkos Kirim</div>
              <div>Diinfokan di whatsapp</div>
            </div> -->
            <hr />
            <div class="d-flex justify-content-between">
              <div><b>ONGKIR</b></div>
              <div>
                <b>Rp. {{ formatRupiah(detailTransaksi.ongkir) }}</b>
              </div>
            </div>
            <!-- <hr />
            <div class="d-flex justify-content-between">
              <div><b>BIAYA APLIKASI</b></div>
              <div>
                <b>Rp. {{ formatRupiah(detailTransaksi.biaya_aplikasi) }}</b>
              </div>
            </div>
            <hr />
            <div v-if="detailTransaksi.metode_bayar == 'payment_gateway'" class="d-flex justify-content-between">
              <div><b>BIAYA TRANSAKSI</b></div>
              <div>
                <b>Rp. {{ formatRupiah(detailTransaksi.biaya_pg) }}</b>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between">
              <div><b>KODE UNIK</b></div>
              <div>
                <b>{{
                  detailTransaksi.kode_unik ? detailTransaksi.kode_unik : "-"
                }}</b>
              </div>
            </div> -->
            <hr />
            <div class="d-flex justify-content-between">
              <div><b>TOTAL ORDER</b></div>
              <div>
                <b>Rp. {{ formatRupiah(detailTransaksi.uang_masuk) }}</b>
              </div>
            </div>

            <!-- <hr />
            <div class="d-flex justify-content-between">
              <div>Biaya Jasa Aplikasi</div>
              <div>Rp300</div>
            </div> -->
            <!-- <hr />
            <div class="d-flex justify-content-between">
              <div><b>TOTAL TAGIHAN</b></div>
              <div>
                <b>Rp. {{ formatRupiah(detailTransaksi.total_bayar) }}</b>
              </div>
            </div> -->
          </div>
        </div>
        <hr />
        <div class="row">
          <!-- <div class="col-sm-6">
            <span v-if="detailTransaksi.transaction_type != 'jasa'">
              Pengiriman :
              <h6>
                <b>{{
                  detailTransaksi.shipment_option == "take_away"
                    ? "Ambil Sendiri"
                    : "Dikirim"
                }}</b>
              </h6>
            </span>
          </div> -->
          <div class="col-sm-6">
            Metode Pembayaran :
            <h6>
              <b v-if="detailTransaksi.metode_bayar == 'manual_transfer'">
                Transfer Manual
                {{
                  detailTransaksi.kode_bayar_detail
                    ? detailTransaksi.kode_bayar_detail.deskripsi
                    : ""
                }}
                {{
                  detailTransaksi.kode_bayar_detail
                    ? detailTransaksi.kode_bayar_detail.no_rekening
                    : ""
                }}</b
              >
              <b v-else>
                {{ metodeBayarUpper }}
              </b>
            </h6>
          </div>
          <div
            class="col-sm-6"
            v-if="
              detailTransaksi.nama_kurir != 'null' &&
              detailTransaksi.nama_kurir != null
            "
          >
            Kurir :
            <h6>
              <b
                >{{
                  detailTransaksi.nama_kurir != "null" &&
                  detailTransaksi.nama_kurir != null
                    ? detailTransaksi.nama_kurir
                    : "Nama kurir tidak diisi"
                }}
                -
                {{
                  detailTransaksi.no_resi != "null" &&
                  detailTransaksi.no_resi != null
                    ? detailTransaksi.no_resi
                    : "Nomor resi tidak diisi"
                }}
              </b>
              <b-button
                id="tombol"
                variant="warning"
                v-if="detailTransaksi.label_pengiriman"
                size="sm"
                target="_blank"
                :href="
                  'https://api-heykama.notive.my.id' +
                  detailTransaksi.label_pengiriman
                "
                ><feather-icon icon="DownloadIcon" class="mr-1" />Download Label
                Pengiriman</b-button
              >
            </h6>
          </div>
        </div>
      </div>
    </div>
    <b-button class="text-center w-100" variant="primary" @click="printContent"
      >Print</b-button
    >
    <!-- <b-button class="text-center w-50" variant="warning" @click="printResep()">Print Resep</b-button> -->

    <b-row class="mt-1" v-if="dataresep.length > 0">
      <b-col>
        <template v-for="(item, index) in dataresep">
          <b-button variant="warning" block :id="'popover-target-' + item.id"
            ><feather-icon
              icon="PrinterIcon"
              class="mr-1"
              @click="printResep2(item)"
            />
            {{ $t("Print Data Prescription") + (index + 1) }}
            <b-popover
              variant="warning"
              :target="'popover-target-' + item.id"
              triggers="hover"
              placement="top"
            >
              <template #title>Resep</template>
              <!-- I am popover <b>component</b> content! -->
              <ul class="list-unstyled">
                <li v-if="item.items[2]">Frame</li>
                <ul v-if="item.items[2]">
                  <li>
                    {{
                      item.items[2].penyimpanan.nama +
                      " - " +
                      item.items[2].penyimpanan.varian +
                      " x" +
                      item.items[2].qty
                    }}
                  </li>
                </ul>
                <!-- <ul v-else>
                                <li>{{ item.items[2].penyimpanan.nama + ' - ' + item.items[2].penyimpanan.varian + ' x'
                                  +
                                  item.items[2].qty }}</li>
                              </ul> -->
                <li>Lensa</li>
                <ul>
                  <li>Kiri : {{ item.prescription.left.sph + " x" + 1 }}</li>
                  <li>Kanan : {{ item.prescription.right.sph + " x" + 1 }}</li>
                </ul>
                <!-- <li>Accessories</li>
                              <ul>

                              </ul> -->
                <!-- <li>Packaging</li>
                              <ul>
                                <template v-for="akses in item.accessories">
                                  <li>{{ akses.penyimpanan.varian + ' x' + 1 }}</li>
                                </template>
                              </ul> -->
              </ul>
              <!-- <b-col cols="5"> -->
              <b-button variant="primary" block @click="printResep2(item)"
                ><feather-icon icon="PrinterIcon" class="mr-1" />
                {{ $t("Print Data Prescription ") + (index + 1) }}
              </b-button>
              <!-- <b-button variant="danger" block @click="deleteresep(item)"><feather-icon icon="TrashIcon"
                    class="mr-1" />
                  {{ $t('Delete Data Prescription ')
                    + (index + 1) }}
                </b-button> -->
              <!-- </b-col> -->
            </b-popover>
          </b-button>
        </template>
      </b-col>
    </b-row>
    <div v-else>
      <b-card-text class="mt-1">
        <b-alert show variant="danger">
          <h4 class="alert-heading">
            {{ $t("Data Prescription not yet created") }}
          </h4>
        </b-alert>
      </b-card-text>
    </div>
  </b-overlay>
</template>
<style>
@media print {
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* Optional, but helps with sizing */
  }

  /* Gaya khusus untuk cetakan */
  body * {
    visibility: hidden;
  }

  #printableArea,
  #printableArea * {
    visibility: visible;
  }

  #printableArea {
    left: 0;
    margin: 0;
    padding: 0;
  }

  #tombol {
    display: none;
  }
}

/* Addkan gaya CSS sesuai kebutuhan */
.order-status {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.order-status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.iconPemesanan {
  width: 30px;
  height: 30px;
  background-color: #f8f9fa;
  border: 2px solid #dee2e6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: #495057;
  margin-bottom: 8px;
}

.text {
  color: #495057;
}

.order-status-item.active .iconPemesanan {
  background-color: #007bff;
  color: #ffffff;
  border-color: #007bff;
}

.order-status-item.done .iconPemesanan {
  background-color: #28a745;
  color: #ffffff;
  border-color: #28a745;
}

.order-status-item.active .text {
  color: #007bff;
}

.order-status-item.done .text {
  color: #28a745;
}
</style>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import {
  BFormSelect,
  BForm,
  BOverlay,
  BCard,
  BCardHeader,
  BCardBody,
  BFormInput,
  BRow,
  BCol,
  BPagination,
  BTable,
  BAlert,
  BButton,
  BFormGroup,
  BBadge,
  BListGroup,
  BListGroupItem,
  BCardText,
  BPopover,
  VBPopover,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import FormTakTertagih from "./components/FormTakTertagih.vue";
export default {
  components: {
    BFormInput,
    BCardText,
    BPopover,
    VBPopover,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormSelect,
    BForm,
    BPagination,
    BCardActions,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,
    BAlert,
    BTable,
    BListGroup,
    BListGroupItem,
    vSelect,
    FormTakTertagih,
  },
  data: () => ({
    loading: false,
    cancelForm: {
      keterangan: null,
    },
    no_resi_show: false,
    no_resi: null,
    pengiriman_status: null,
    optionPengiriman: [
      { text: "Diproses", value: "diproses" },
      { text: "Dikirim/Diambil", value: "dikirim" },
    ],
    orderStatus: [
      { text: "Diproses", icon: "BoxIcon", active: true, done: true },
      { text: "Dikirim/Diambil", icon: "TruckIcon", active: true, done: false },
      {
        text: "Sampai/Diterima",
        icon: "MapPinIcon",
        active: false,
        done: false,
      },
      { text: "Selesai", icon: "CheckIcon", active: false, done: false },
    ],
    invoiceItems: [
      { no: 1, nama: "Produk A", harga: 500000, jumlah: 2 },
      { no: 2, nama: "Produk B", harga: 250000, jumlah: 1 },
      { no: 3, nama: "Produk C", harga: 250000, jumlah: 1 },
    ],
    // Definisi kolom untuk tabel
    fields: ["no", "nama", "harga", "jumlah"],
    rute: null,
    currentPage: 1,
    totalRows: 0,
    barangFields: [
      { key: "barang", label: "Nama Barang" },
      { key: "qty", label: "Jumlah Barang" },
      { key: "harga_jual", label: "Harga Satuan" },
      { key: "diskon", label: "Diskon" },
      { key: "harga_total", label: "Harga Total" },
    ],
    detailTransaksi: {},
    kasOptions: [],
    payments: [
      {
        metode: 1,
        nominal: 20000,
      },
    ],
    selectedKas: null,
    formkonfirmasi: {
      no_invoice: null,
      bukti_tf: {
        file: null,
      },
    },
    konfirmasi: null,
    id_akun: [],
    id_kas: [],
    transactionType: null,
    debitAkun: [],
    kreditAkun: [],
    showKonfirmasi: false,
    dataresep: [],
  }),
  methods: {
    getResep() {
      this.loadingresep = true;
      let payload = {
        no_invoice: this.detailTransaksi.no_invoice,
        order: "asc",
      };
      this.$store
        .dispatch("pesanan/getResep", payload)
        .then((response) => {
          this.dataresep = response.data.data;
          this.loadingresep = false;
        })
        .catch((e) => {
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
    },
    deleteresep(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Menghapus resep ini`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingsubmitresep = true;
          let payload = {
            id: item.id,
          };
          this.$store
            .dispatch("pesanan/deleteResep", [payload])
            .then(() => {
              this.displaySuccess({
                text: "Hapus Resep berhasil",
              });
              this.loadingsubmitresep = false;
              // this.showModalTerapis = false;
              // this.itemTransaksiTerapis = {};
              this.getlensa();
              // this.getCustomer2();
              this.getResep(item.no_invoice);
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    printResep2(item) {
      let params = item.id;
      // console.log('CEK', params)
      this.$store
        .dispatch("pesanan/printResep", params)
        .then((response) => {
          window.open(response.link, "_blank");
          // console.log('CEK', response.datali)
          // this.loadingresep = false
        })
        .catch((e) => {
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
    },
    getExtension(file) {
      return file.slice(Math.max(0, file.lastIndexOf(".")) + 1);
    },
    submitkonfirmasi() {
      this.$refs.vkonfirmasi.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          // let join = this.pilihteritori;
          // let string = join.join(",");
          // var array = JSON.parse("[" + string + "]");
          let payload = {
            no_invoice: this.detailTransaksi.no_invoice,
            // nama_cabang: this.form.nama_cabang,
            akun_id: this.formkonfirmasi.id_akun,
            kas_id: this.formkonfirmasi.id_kas,
          };
          this.$store
            .dispatch("transaksiOnline/tandaiLunas", payload)
            .then(() => {
              this.showKonfirmasi = false;
              this.displaySuccess({
                text: "Berhasil dikonfirmasi",
              });
              this.getDetailTransaksi();
              // this.formKonfirmasi = null
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    modalEditPembayaran() {
      this.$bvModal.show("editPembayaran");
    },
    modalEditPengiriman() {
      this.$bvModal.show("editPengiriman");
    },
    async printNotaPenjualan() {
      this.loading = true;
      const response = await this.$store.dispatch(
        "penjualan/printNota",
        this.$route.params.id
      );
      this.loading = false;
      window.open(URL.createObjectURL(response));
    },
    getMonthAbbreviation(monthNumber) {
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Agu",
        "Sep",
        "Okt",
        "Nov",
        "Des",
      ];
      return months[monthNumber];
    },

    addLeadingZero(number) {
      return number < 10 ? "0" + number : number;
    },
    getDateTime(dateAndTime) {
      var expired_Time = new Date(dateAndTime);

      var day = this.addLeadingZero(expired_Time.getDate());
      var month = this.getMonthAbbreviation(expired_Time.getMonth());
      var year = expired_Time.getFullYear();
      var hours = this.addLeadingZero(expired_Time.getHours());
      var minutes = this.addLeadingZero(expired_Time.getMinutes());

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return (
        day + " " + month + " " + year + ", " + hours + ":" + minutes + " WIB"
      );
    },
    getDate(date) {
      var expired_Time = new Date(date);

      var year = expired_Time.getFullYear();
      var month = expired_Time.getMonth() + 1;
      var day = expired_Time.getDate();

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return day + "-" + month + "-" + year;
    },
    batalkanPesanan() {
      this.$swal({
        title: "Peringatan!",
        text: "Anda yakin ingin membatalkan pesanan ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = new FormData();
          payload.append("no_invoice", this.detailTransaksi.no_invoice);
          this.$store
            .dispatch("transaksiOnline/batalOrder", payload)
            .then((res) => {
              this.getDetailTransaksi();
              this.displaySuccess({
                message: "Pesanan Berhasil Dibatalkan",
              });
            })
            .catch((err) => {
              this.displayError(err);
              return false;
            });
        }
      });
    },
    async doCancel() {
      const penjualan = this.$store.getters["penjualan/search"](
        this.$route.params.id
      );
      if (!penjualan) {
        // this.$router.push(`/penjualan`);
      }

      const payload = {
        id_penjualan: penjualan.id,
        tanggal: penjualan.tanggal,
        no_kwitansi: penjualan.no_kwitansi,
        keterangan: this.cancelForm.keterangan,
      };

      try {
        this.$bvModal.hide("cancel-form");
        this.loading = true;
        await this.$store.dispatch("penjualan/cancel", [payload]);
        await this.getAllPenjualan();
        this.displaySuccess({
          message: "Data transaksi penjualan telah dibatalkan",
        });
        this.loading = false;

        setTimeout(() => window.location.reload(), 1000);
      } catch (e) {
        this.loading = false;
        this.$bvModal.show("cancel-form");
        this.displayError(e);
        return false;
      }
    },
    async getDetailTransaksi() {
      let params = {
        id: this.$route.params.id,
        show_bukti_tf: 1,
        view_as_invoice: 1,
        show_reservation_info: 1,
      };
      this.$store
        .dispatch("transaksiOnline/getDataById", params)
        .then((res) => {
          // console.log(res);
          this.detailTransaksi = res;
          if (res.status == "pending") {
            this.optionPengiriman = [{ text: "Diproses", value: "diproses" }];
            this.orderStatus = [
              { text: "Diproses", icon: "BoxIcon", active: false, done: false },
              {
                text: "Dikirim/Diambil",
                icon: "TruckIcon",
                active: false,
                done: false,
              },
              {
                text: "Sampai/Diterima",
                icon: "MapPinIcon",
                active: false,
                done: false,
              },
              {
                text: "Selesai",
                icon: "CheckIcon",
                active: false,
                done: false,
              },
            ];
          } else if (res.status == "diproses") {
            this.optionPengiriman = [
              { text: "Dikirim/Diambil", value: "dikirim" },
            ];
            this.orderStatus = [
              { text: "Diproses", icon: "BoxIcon", active: true, done: false },
              {
                text: "Dikirim/Diambil",
                icon: "TruckIcon",
                active: false,
                done: false,
              },
              {
                text: "Sampai/Diterima",
                icon: "MapPinIcon",
                active: false,
                done: false,
              },
              {
                text: "Selesai",
                icon: "CheckIcon",
                active: false,
                done: false,
              },
            ];
          } else if (res.status == "dikirim") {
            this.optionPengiriman = [{ text: "Diterima", value: "diterima" }];
            this.orderStatus = [
              { text: "Diproses", icon: "BoxIcon", active: true, done: true },
              {
                text: "Dikirim/Diambil",
                icon: "TruckIcon",
                active: true,
                done: false,
              },
              {
                text: "Sampai/Diterima",
                icon: "MapPinIcon",
                active: false,
                done: false,
              },
              {
                text: "Selesai",
                icon: "CheckIcon",
                active: false,
                done: false,
              },
            ];
          } else if (res.status == "diterima") {
            this.optionPengiriman = [
              { text: "Diproses", value: "diproses" },
              { text: "Dikirim/Diambil", value: "dikirim" },
              { text: "Sampai/Diterima", value: "sampai_tujuan" },
              { text: "Selesai", value: "selesai" },
            ];
            this.orderStatus = [
              { text: "Diproses", icon: "BoxIcon", active: true, done: true },
              {
                text: "Dikirim/Diambil",
                icon: "TruckIcon",
                active: true,
                done: true,
              },
              {
                text: "Sampai/Diterima",
                icon: "MapPinIcon",
                active: true,
                done: false,
              },
              {
                text: "Selesai",
                icon: "CheckIcon",
                active: false,
                done: false,
              },
            ];
          } else if (res.status == "dibatalkan") {
            this.optionPengiriman = [
              { text: "Diproses", value: "diproses" },
              { text: "Dikirim/Diambil", value: "dikirim" },
              { text: "Sampai/Diterima", value: "sampai_tujuan" },
              { text: "Selesai", value: "selesai" },
            ];
            this.orderStatus = [
              { text: "Diproses", icon: "BoxIcon", active: false, done: false },
              {
                text: "Dikirim/Diambil",
                icon: "TruckIcon",
                active: false,
                done: false,
              },
              {
                text: "Sampai/Diterima",
                icon: "MapPinIcon",
                active: false,
                done: false,
              },
              {
                text: "Selesai",
                icon: "CheckIcon",
                active: false,
                done: false,
              },
            ];
          } else {
            this.optionPengiriman = [
              { text: "Diproses", value: "diproses" },
              { text: "Dikirim/Diambil", value: "dikirim" },
              { text: "Sampai/Diterima", value: "sampai_tujuan" },
            ];
            this.orderStatus = [
              { text: "Diproses", icon: "BoxIcon", active: true, done: true },
              {
                text: "Dikirim/Diambil",
                icon: "TruckIcon",
                active: true,
                done: true,
              },
              {
                text: "Sampai/Diterima",
                icon: "MapPinIcon",
                active: true,
                done: true,
              },
              { text: "Selesai", icon: "CheckIcon", active: true, done: true },
            ];
          }
          this.getResep();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllPenjualan() {
      const params = this.isSales
        ? { order: "desc", id_sales: this.user.karyawan.id }
        : { order: "desc" };
      await this.$store.dispatch("penjualan/getData", params); // belum difilter per sales
    },
    simpanPengiriman() {
      let payload = new FormData();
      payload.append("no_invoice", this.detailTransaksi.no_invoice);
      payload.append("status", this.pengiriman_status.value);
      if (this.no_resi != null) {
        payload.append("no_resi", this.no_resi);
      }
      this.$store
        .dispatch("transaksiOnline/updateStatus", payload)
        .then((res) => {
          this.no_resi = null;
          this.no_resi_show = false;
          this.pengiriman_status = null;
          this.getDetailTransaksi();
          this.$bvModal.hide("editPengiriman");
          this.displaySuccess({
            message: "Status pengiriman berhasil diubah",
          });
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
    },
    tandaiLunas() {
      this.$swal({
        title: "Peringatan!",
        text: "Anda yakin ingin menandai lunas transaksi ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-outline-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = new FormData();
          payload.append("no_invoice", this.detailTransaksi.no_invoice);
          this.$store
            .dispatch("transaksiOnline/tandaiLunas", payload)
            .then((res) => {
              this.getDetailTransaksi();
              this.$bvModal.hide("editPembayaran");
              this.displaySuccess({
                message: "Berhasil Ditandai lunas!",
              });
            })
            .catch((err) => {
              this.displayError(err);
              return false;
            });
        }
      });
    },
    printContent() {
      window.print();
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          hem.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getAkun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akuns.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
          this.kreditAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
  },
  computed: {
    metodeBayarUpper() {
      return this.detailTransaksi.metode_bayar
        ? this.detailTransaksi.metode_bayar.toUpperCase()
        : "";
    },
    isKreditAkun() {
      return this.transactionType == 2;
    },
    isDebitAkun() {
      return this.transactionType == 1;
    },
    apiFile() {
      return "https://api-heykama.notive.my.id/";
    },
    totalDiskon() {
      if (!this.penjualan || !this.penjualan.rincian) {
        return 0;
      }

      return this.penjualan.rincian.reduce(
        (total, barang) => (total += barang.diskon),
        0
      );
    },
    thereIsDebt() {
      const jumlahDiskon = this.penjualan
        ? parseInt(this.penjualan.diskon) + parseInt(this.penjualan.saldo)
        : 0;
      if (this.penjualan) {
        return this.penjualan.total_pembayaran < this.penjualan.total_bayar;
      }

      return 0;
    },
    debt() {
      const jumlahDiskon = this.penjualan ? parseInt(this.penjualan.diskon) : 0;

      if (this.penjualan) {
        let totalBayar = this.penjualan.total_pembayaran;
        if (this.penjualan.saldo) {
          totalBayar += parseInt(this.penjualan.saldo);
        }
        return totalBayar - this.penjualan.total_bayar;
      }
      return 0;
    },
    thereIsChange() {
      const tagihan = this.penjualan ? this.penjualan.total_bayar : 0; // sudah dipotong diskon
      let totalBayar = 0;
      if (
        this.penjualan &&
        this.penjualan.pembayaran &&
        this.penjualan.pembayaran.length > 0
      ) {
        totalBayar += this.penjualan.pembayaran.reduce(
          (total, item) => (total += item.nominal),
          0
        );
      }
      return totalBayar > tagihan;
    },
    change() {
      const tagihan = this.penjualan ? this.penjualan.total_bayar : 0; // sudah dipotong diskon
      console.log("tagihan", this.penjualan);
      if (this.penjualan) {
        let totalBayar = this.penjualan.pembayaran.reduce(
          (total, item) => (total += item.nominal),
          0
        );
        return totalBayar - tagihan;
      }

      return 0;
    },
  },
  watch: {
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
    pengiriman_status(val) {
      if (val != null) {
        if (val.value == "dikirim") {
          this.no_resi_show = true;
        }
      } else {
        this.no_resi_show = false;
      }
    },
  },
  async created() {
    // this.getKas();
    await this.getDetailTransaksi();
    this.getKas();
    this.getAkun();

    // this.getRute();

    // if (this.isFinance) {
    //   await this.getAkun();
    //   await this.getKas();
    // }
  },
};
</script>
