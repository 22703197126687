var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "barang-modal",
      "size": "xl",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Choose Items")))])];
      },
      proxy: true
    }])
  }, [_c('header', {
    staticClass: "mb-1"
  }, [_c('h4', [_c('b', [_c('u', [_vm._v(_vm._s(_vm.$t("Items Selected")) + " (" + _vm._s(_vm.selectedBarangs.length) + ")")])])]), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Category ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.itemsKategori
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.kategori_id,
      callback: function ($$v) {
        _vm.kategori_id = $$v;
      },
      expression: "kategori_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Sort ")]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'asc',
        text: 'Ascending'
      }, {
        value: 'desc',
        text: 'Descending'
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("None")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBarang,
      callback: function ($$v) {
        _vm.sortBarang = $$v;
      },
      expression: "sortBarang"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Lainnya ")]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-form-select', {
    staticClass: "custom-select",
    attrs: {
      "options": [{
        value: 'kelas_id',
        text: 'Kelas'
      }, {
        value: 'main_sku',
        text: 'SKU Ginee'
      }, {
        value: 'kode',
        text: 'Barcode'
      }]
    },
    model: {
      value: _vm.searchOptions,
      callback: function ($$v) {
        _vm.searchOptions = $$v;
      },
      expression: "searchOptions"
    }
  })], 1), _vm.searchOptions !== 'kelas_id' ? _c('b-form-input', {
    attrs: {
      "placeholder": "Masukkan teks"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.customefilter,
      callback: function ($$v) {
        _vm.customefilter = $$v;
      },
      expression: "customefilter"
    }
  }) : _vm._e(), _vm.searchOptions == 'kelas_id' ? _c('b-form-select', {
    attrs: {
      "options": _vm.itemsKelas
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }], null, false, 1495490819),
    model: {
      value: _vm.kelas_id,
      callback: function ($$v) {
        _vm.kelas_id = $$v;
      },
      expression: "kelas_id"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Pre Order ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [
      // { value: null, text: 'All' },
      {
        value: 1,
        text: 'Ya'
      }, {
        value: 0,
        text: 'Tidak'
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.is_pre_order,
      callback: function ($$v) {
        _vm.is_pre_order = $$v;
      },
      expression: "is_pre_order"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPage",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari",
      "size": "sm"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.localBarangs.length > 0 ? [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "fields": _vm.fields,
      "items": _vm.localBarangs
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedBarangs,
            callback: function ($$v) {
              _vm.selectedBarangs = $$v;
            },
            expression: "selectedBarangs"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item ? item.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item ? item.no_batch : "-"))])];
      }
    }, {
      key: "cell(fotopro)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.photo && item.photo.length > 0 ? [_c('img', {
          staticStyle: {
            "width": "50px"
          },
          attrs: {
            "src": item.photo[0].path,
            "alt": "Image description"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal(item.photo[0].path, item.barcode);
            }
          }
        })] : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Photo product item not yet created")]), _c('br'), item.barcode ? _c('img', {
          staticStyle: {
            "width": "75px"
          },
          attrs: {
            "src": item.barcode,
            "alt": "Foto"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal(item.photo.length > 0 ? item.photo[0].path : null, item.barcode);
            }
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")]), _c('b-modal', {
          ref: "imageModal",
          attrs: {
            "id": "image-modal",
            "title": "Preview Photo Product Item and Barcode",
            "hide-footer": true,
            "size": "lg",
            "centered": ""
          }
        }, [_c('div', {
          ref: "imageViewer"
        }, [_vm.currentImage ? _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.currentImage,
            "alt": "Full-size"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Photo product item not yet created")]), _c('br'), _vm.currentBarcode ? _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.currentBarcode,
            "alt": "Full-size"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")])])])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item ? item.nama : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item ? item.varian : "-") + " ")]), _c('br'), _vm._v(" " + _vm._s(item.kelas ? item.kelas.kelas : "-") + " ")];
      }
    }, {
      key: "cell(stok_ginee)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.stok_ginee ? _vm.formatRupiah(item.stok_ginee) : 0) + " ")];
      }
    }, {
      key: "cell(stocks)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('b-card', {
          staticClass: "mb-0",
          attrs: {
            "border-variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "text-center"
        }, [_vm._l(item.stocks, function (data) {
          return [_c('b-row', [_c('b-col', [_c('div', {
            staticClass: "text-right"
          }, [_vm._v(_vm._s(data.blok.blok))])]), _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_vm._v(" : ")]), _c('b-col', [data.jumlah > data.minimum_stok ? [_c('div', {
            staticClass: "text-left"
          }, [_c('b', {
            staticClass: "text-success"
          }, [_vm._v(_vm._s(_vm.formatRupiah(data.jumlah)))])])] : _vm._e(), data.jumlah <= data.minimum_stok ? [_c('div', {
            staticClass: "text-left"
          }, [_c('b', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(_vm.formatRupiah(data.jumlah)))])])] : _vm._e()], 2)], 1)];
        }), item.stocks.length == 0 ? _c('b-row', [_c('b-col', [_c('small', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.$t("Data not found")))])])])], 1) : _vm._e(), _c('b-row', [_c('b-col', [_c('div', {
          staticClass: "text-right"
        }, [_vm._v("Total")])]), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_vm._v(" : ")]), _c('b-col', [item.total_stok > _vm.totalJumlah(item.stocks) ? [_c('div', {
          staticClass: "text-left"
        }, [_c('b', {
          staticClass: "text-success"
        }, [_vm._v(_vm._s(_vm.formatRupiah(item.total_stok)))])])] : _vm._e(), item.total_stok <= _vm.totalJumlah(item.stocks) ? [_c('div', {
          staticClass: "text-left"
        }, [_c('b', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.formatRupiah(item.total_stok)))])])] : _vm._e()], 2)], 1)], 2), _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" (Min. Stocks : " + _vm._s(_vm.formatRupiah(_vm.totalJumlah(item.stocks))) + ") ")])])];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : "-") + " ")];
      }
    }, {
      key: "cell(palet)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.palet ? item.palet.palet : "-") + " ")];
      }
    }, {
      key: "cell(rak)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.rak ? item.rak.rak : "-") + " ")];
      }
    }, {
      key: "cell(laci)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.laci ? item.laci.laci : "-") + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [item.jumlah > item.minimum_stok ? [_c('b', {
          staticClass: "text-success"
        }, [_vm._v(_vm._s(item.jumlah ? item.jumlah : 0))]), _vm._v(" / " + _vm._s(_vm.formatRupiah(item.jumlah * item.barang.harga_dasar))), _c('br'), _c('small', [_vm._v(" (Konversi Satuan : " + _vm._s(item.konversi_ket == null ? 0 : item.konversi_ket) + ") "), _c('br'), _vm._v("(Min. Stok = " + _vm._s(item.minimum_stok) + ") ")])] : _vm._e(), item.jumlah <= item.minimum_stok ? [_c('b', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(item.jumlah ? item.jumlah : 0))]), _vm._v(" / " + _vm._s(_vm.formatRupiah(item.jumlah * item.barang.harga_dasar))), _c('br'), _c('small', [_vm._v(" (Konversi Satuan : " + _vm._s(item.konversi_ket == null ? 0 : item.konversi_ket) + ") "), _c('br'), _vm._v("(Min. Stok = " + _vm._s(item.minimum_stok) + ") ")])] : _vm._e()];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.konversi_ket) + " ")];
      }
    }, {
      key: "cell(selesai)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" " + _vm._s(data.item.status_ket ? data.item.status_ket.toUpperCase() : "-") + " ")])];
      }
    }], null, false, 922477929)
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)] : [_c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t("Data not found")))])])]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }