<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col cols="12">
        <b-card-actions :no-actions="true">
          <section class="d-flex justify-content-between">
            <section>
              <b-button-group>
                <b-button
                  v-if="allowCreate()"
                  variant="warning"
                  @click.prevent="$router.push({ name: 'route-barang-add' })"
                >
                  {{ $t("Add") }}
                </b-button>
                <b-button
                  v-if="allowCreate()"
                  @click.prevent="addimport"
                  variant="primary"
                >
                  {{ $t("Import") }}
                  <modal-import @getData="getData"></modal-import>
                </b-button>

                <b-button
                  :disabled="selectedginee.length === 0"
                  @click.prevent="massremove"
                  variant="danger"
                >
                  <!-- <feather-icon icon="PlusIcon" class="mr-50" /> -->
                  {{ $t("Mass Delete") }}
                </b-button>
              </b-button-group>
            </section>
            <section>
              <b-button-group>
                <b-button @click.prevent="exportexcelModal" variant="success">
                  <feather-icon icon="DownloadIcon" class="mr-50" />
                  {{ $t("Export") }}
                </b-button>
              </b-button-group>

              <b-modal
                header-bg-variant="success"
                id="export-modal"
                v-model="modalexport"
                size="lg"
                title="Export : Product List"
                ok-variant="secondary"
                ok-only
                centered
                no-close-on-backdrop
                hide-footer
              >
                <b-row>
                  <b-col xl="2" lg="2" md="2" sm="2">
                    <label>Field(s) :</label>
                  </b-col>
                  <b-col xl="10" lg="10" md="10" sm="10">
                    <b-button
                      variant="primary"
                      show
                      block
                      class="text-center mb-1"
                    >
                      <div class="button-body">
                        <span><strong>Field</strong></span>
                      </div>
                    </b-button>
                    <b-form-checkbox-group v-model="cekkolom" class="row">
                      <b-form-checkbox
                        v-for="(option, index) in kolom"
                        :key="index"
                        :value="option.value"
                        class="ml-1 col-10 col-md-3 mb-1"
                      >
                        {{ option.text }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-col>
                  <hr />
                  <b-col xl="2" lg="2" md="2" sm="2">
                    <label>Format :</label>
                  </b-col>
                  <b-col xl="2" lg="2" md="2" sm="2">
                    <!-- <div class=""> -->
                    <!-- <b-form-group label="Format:" label-md-3 label-for="export-format"> -->
                    <b-form-select
                      id="export-format"
                      v-model="selectedFormat"
                      :options="formatOptions"
                    ></b-form-select>
                    <!-- </b-form-group> -->
                    <!-- </div> -->
                  </b-col>
                  <b-col xl="4" lg="4" md="4" sm="4"> </b-col>
                  <b-col xl="4" lg="4" md="4" sm="4">
                    <b-button
                      block
                      variant="outline-warning"
                      @click="$bvModal.hide('export-modal')"
                    >
                      Cancel
                    </b-button>
                    <b-button block variant="warning" @click="exportData"
                      >Export</b-button
                    >
                  </b-col>
                </b-row>
                <template #modal-footer>
                  <!-- <b-button variant="secondary" @click="$bvModal.hide('export-modal')">
                    Cancel
                  </b-button>
                  <b-button variant="warning" @click="exportData">Export</b-button> -->
                </template>
              </b-modal>
            </section>
          </section>
          <header>
            <b-row class="mt-1">
              <b-col md="1" sm="1"> Category </b-col>
              <b-col md="3" sm="3">
                <b-form-group>
                  <b-form-select
                    v-model="kategori_id"
                    :options="itemsKategori"
                    @change="getData()"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >-- {{ $t("All") }} --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="1"> Sort </b-col>
              <b-col md="2" sm="2">
                <b-form-group>
                  <b-form-select
                    v-model="sortBarang"
                    :options="[
                      { value: 'asc', text: 'Ascending' },
                      { value: 'desc', text: 'Descending' },
                    ]"
                    @change="getData()"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >-- {{ $t("None") }} --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="1" sm="1" class=""> Lainnya </b-col>
              <b-col md="4" sm="4" class="">
                <b-form-group>
                  <b-input-group>
                    <!-- b-form-select sebagai bagian dari input group -->
                    <b-input-group-prepend>
                      <b-form-select
                        v-model="searchOptions"
                        :options="[
                          { value: 'kelas_id', text: 'Kelas' },
                          { value: 'main_sku', text: 'SKU Ginee' },
                          { value: 'kode', text: 'Barcode' },
                        ]"
                        class="custom-select"
                      >
                        <template #first>
                          <b-form-select-option :value="null"
                            >-- {{ $t("Choose") }} --</b-form-select-option
                          >
                        </template></b-form-select
                      >
                    </b-input-group-prepend>

                    <!-- b-form-input sebagai bagian dari input group -->
                    <b-form-input
                      v-if="searchOptions !== 'kelas_id'"
                      @keydown.enter.prevent="getData()"
                      v-model="customefilter"
                      placeholder="Masukkan teks"
                    ></b-form-input>

                    <b-form-select
                      v-if="searchOptions == 'kelas_id'"
                      v-model="kelas_id"
                      :options="itemsKelas"
                      @change="getData()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- {{ $t("All") }} --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col md="1" sm="1"> Pre Order </b-col>
              <b-col md="3" sm="3">
                <b-form-group>
                  <b-form-select
                    v-model="is_pre_order"
                    :options="[
                      // { value: null, text: 'All' },
                      { value: 1, text: 'Ya' },
                      { value: 0, text: 'Tidak' },
                    ]"
                    @change="getData()"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >-- {{ $t("All") }} --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col md="1" sm="1" class="mb-1">
                Lainnya
              </b-col>
              <b-col md="11" sm="11" class="mb-1">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-select v-model="searchOptions" :options="[
                        { value: 'kelas_id', text: 'Kelas' },
                        { value: 'main_sku', text: 'SKU Ginee' },
                        { value: 'kode', text: 'Barcode' },
                      ]" class="custom-select"></b-form-select>
                    </b-input-group-prepend>

                    <b-form-input v-if="searchOptions !== 'kelas_id'" @keydown.enter.prevent="getData()"
                      v-model="customefilter" placeholder="Masukkan teks"></b-form-input>

                    <b-form-select v-if="searchOptions == 'kelas_id'" v-model="kelas_id" :options="itemsKelas"
                      @change="getData()">
                      <template #first>
                        <b-form-select-option :value="null">-- {{ $t('All') }} --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row> -->
            <b-row>
              <b-col md="2" sm="2" class="my-1">
                <b-form-group
                  class="mb-0"
                  :label="$t('Per page')"
                  label-for="perPage"
                  size="sm"
                >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" sm="6" class="my-1">
                <b-form-group
                  :label="$t('Filter')"
                  label-for="filterInput"
                  class="mb-0"
                  size="sm"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="filter"
                      placeholder="Tekan enter untuk cari"
                      size="sm"
                      @keydown.enter.prevent="getData()"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="getData()"
                        size="sm"
                      >
                        Cari
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- <b-col md="4" sm="4" class="my-1">
                <b-form-group label="Tabel" label-for="tabel" class="mb-0" label-size="sm">
                  <b-form-select v-model="tabel" size="sm" :options="tabeloption">
                  </b-form-select>
                </b-form-group>
              </b-col> -->
              <b-col
                v-if="!isCabang && optis_member.length > 0"
                class="my-1"
                xl="3"
                lg="3"
                md="3"
                sm="12"
              >
                <b-form-group
                  label="Pilih Affiliator"
                  label-for="is_member"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="is_member"
                      v-model="is_member"
                      :options="optis_member"
                      class=""
                      @input="getData()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </header>

          <!-- /form filter -->
          <b-table
            striped
            small
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <!-- Stasiun Qurban -->
            <template #head(checkbox)>
              <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{ item }">
              <b-form-checkbox
                v-model="selectedginee"
                :value="item"
              ></b-form-checkbox>
            </template>
            <template #cell(fotopro)="{ item }">
              <template
                v-if="item.photo && item.photo.length > 0"
                class="image-gallery"
              >
                <!-- <img v-for="(image, index) in item.photo" :key="index" :src="image.path" :alt="image.path"
                  class="viewer-image" ref="imageElement" /> -->
                <img
                  style="width: 50px"
                  @click="openModal(item.photo[0].path, item.barcode)"
                  :src="item.photo[0].path"
                  alt="Image description"
                />
              </template>
              <small v-else class="text-danger"
                >Photo product item not yet created</small
              >
              <!-- <feather-icon v-else icon="Icon" size="32" /> -->
              <br />
              <img
                v-if="item.barcode"
                @click="
                  openModal(
                    item.photo.length > 0 ? item.photo[0].path : null,
                    item.barcode
                  )
                "
                style="width: 75px"
                :src="item.barcode"
                alt="Foto"
              />
              <small v-else class="text-danger">Barcode not yet created</small>
              <!-- <feather-icon v-else icon="Icon" size="32" /> -->
              <b-modal
                id="image-modal"
                title="Preview Photo Product Item and Barcode"
                ref="imageModal"
                :hide-footer="true"
                size="lg"
                centered
              >
                <div ref="imageViewer">
                  <img
                    v-if="currentImage"
                    :src="currentImage"
                    class="img-fluid"
                    alt="Full-size"
                  />
                  <small v-else class="text-danger"
                    >Photo product item not yet created</small
                  >
                  <br />
                  <img
                    v-if="currentBarcode"
                    :src="currentBarcode"
                    class="img-fluid"
                    alt="Full-size"
                  />
                  <small v-else class="text-danger"
                    >Barcode not yet created</small
                  >
                </div>
              </b-modal>
            </template>
            <template #cell(foto)="{ item }">
              <img
                v-if="item.barcode"
                style="width: 75px"
                :src="item.barcode"
                alt="Foto"
              />
              <small v-else class="text-danger">Barcode not yet created</small>
              <feather-icon v-else icon="Icon" size="32" />
            </template>
            <template #cell(harga_dasarsts)="{ item }">
              <b-form-input
                size="sm"
                v-model="item.varian_item[0].harga_dasar"
                @keydown.enter.prevent="ubahvarian(item)"
              />
            </template>
            <template #cell(harga_jualsts)="{ item }">
              <template v-if="item.harga_jual && item.harga_jual.length > 0">
                <b-form-input
                  size="sm"
                  v-model="item.harga_jual[0].share_penjual"
                  @keydown.enter.prevent="ubahhargajual(item)"
                />
              </template>
            </template>
            <template #cell(kode)="{ item }">
              {{ item.kode }}<br />
              <small>SKU WMS : {{ item.varian_item[0].no_batch }}</small
              ><br />
              <small>SKU Ginee : {{ item.varian_item[0].main_sku }}</small>
              <!-- <small>{{ item.nama }}</small> -->
            </template>
            <template #cell(nama)="{ item }">
              {{ item.nama }}<br />
              <small>{{ item.kategori.kategori }}</small> /
              <small>{{ item.varian }}</small
              ><br />
              {{ item.kelas ? item.kelas.kelas : "-" }}
            </template>
            <!-- <template #cell(kategori)="{ item }">
                        {{item.varian}}<br>
                        <small>{{item.kategori}}</small>
            </template> -->
            <template #cell(kodests)="{ item }">
              <b-form-input
                size="sm"
                v-model="item.varian_item[0].kode"
                @keydown.enter.prevent="ubahvarian(item)"
              />
            </template>
            <template #cell(variansts)="{ item }">
              <b-form-input
                size="sm"
                v-model="item.varian_item[0].varian"
                @keydown.enter.prevent="ubahvarian(item)"
              />
            </template>
            <template #cell(namasts)="{ item }">
              <b-form-input
                size="sm"
                v-model="item.nama"
                @keydown.enter.prevent="ubahnama(item)"
              />
            </template>
            <template #cell(margin)="{ item }">
              {{
                formatRupiah(
                  item.harga_jual[0].share_penjual -
                    item.varian_item[0].harga_dasar
                )
              }}
            </template>
            <template #cell(show_detailsts)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>
            <!-- Check box for show detail -->
            <template #cell(show_detail)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>
            <!-- Detail -->
            <template #row-details="{ toggleDetails, item }">
              <!-- <b-card> -->
              <b-row v-if="tabel == 1" class="mb-1">
                <b-col>
                  <quill-editor v-model="item.deskripsi"> </quill-editor>
                </b-col>
              </b-row>
              <b-row class="mb-1" v-else>
                <b-col cols="12">
                  <b-table
                    :fields="[
                      { key: 'checkbox', label: '#' },
                      { key: 'fotopro', label: 'Photo' },
                      { key: 'foto', label: 'Barcode' },
                      { key: 'kode', label: 'Barcode' },
                      { key: 'main_sku', label: 'SKU Ginee' },
                      { key: 'no_batch', label: 'SKU' },
                      { key: 'varian', label: 'Varian' },
                      { key: 'harga_dasar', label: 'Harga Dasar' },
                      // { key: 'harga_coret', label: 'Harga Coret' },
                      // { key: 'diskon.value', label: 'Diskon' },
                      { key: 'aksi', label: '#' },
                    ]"
                    striped
                    small
                    responsive
                    :items="item ? item.varian_item : []"
                    table-variant="primary"
                  >
                    <template #cell(harga_dasar)="{ item }">
                      {{ formatRupiah(item.harga_dasar) }}
                    </template>
                    <!-- <template #head(checkbox)>
              <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template> -->
                    <template #cell(checkbox)="{ item }">
                      <b-form-checkbox
                        v-model="selectedginee"
                        :value="item"
                      ></b-form-checkbox>
                    </template>
                    <template #cell(fotopro)="{ item }">
                      <img
                        v-if="item.photo"
                        :src="item.photo[0].link"
                        alt="Foto2"
                      />
                      <feather-icon v-else icon="UserIcon" size="32" />
                    </template>
                    <template #cell(foto)="{ item }">
                      <img v-if="item.barcode" :src="item.barcode" alt="Foto" />
                      <feather-icon v-else icon="UserIcon" size="32" />
                    </template>
                    <template #cell(harga_coret)="{ item }">
                      {{ formatRupiah(item.harga_coret) }}
                    </template>
                    <template #cell(dis)="row">
                      {{ row.item.diskon == null ? 0 : row.item.diskon.value }}
                    </template>
                    <template #cell(kode)="{ item }">
                      {{ item.kode }}
                    </template>
                    <template #cell(varian)="{ item }">
                      {{ item.varian }}
                    </template>
                    <template
                      v-if="modeedit == true"
                      #cell(main_sku)="{ item }"
                    >
                      <b-form-input
                        size="sm"
                        v-model="item.main_sku"
                        @keydown.enter.prevent="ubahvarianitem(item)"
                      />
                    </template>
                    <template
                      v-if="modeedit == true"
                      #cell(no_batch)="{ item }"
                    >
                      <b-form-input
                        size="sm"
                        v-model="item.no_batch"
                        @keydown.enter.prevent="ubahvarianitem(item)"
                      />
                    </template>
                    <template
                      v-if="modeedit == true"
                      #cell(harga_dasar)="{ item }"
                    >
                      <b-form-input
                        size="sm"
                        v-model="item.harga_dasar"
                        @keydown.enter.prevent="ubahvarianitem(item)"
                      />
                    </template>
                    <template v-if="modeedit == true" #cell(varian)="{ item }">
                      <b-form-input
                        size="sm"
                        v-model="item.varian"
                        @keydown.enter.prevent="ubahvarianitem(item)"
                      />
                    </template>
                    <template v-if="modeedit == true" #cell(kode)="{ item }">
                      <b-form-input
                        size="sm"
                        v-model="item.kode"
                        @keydown.enter.prevent="ubahvarianitem(item)"
                      />
                    </template>
                    <template
                      v-if="modeedit == true"
                      #cell(harga_coret)="{ item }"
                    >
                      <b-form-input
                        size="sm"
                        v-model="item.harga_coret"
                        @keydown.enter.prevent="ubahvarianitem(item)"
                      />
                    </template>
                    <template v-if="modeedit == true" #cell(dis)="{ item }">
                      <b-form-input
                        size="sm"
                        v-model="item.diskon.value"
                        @keydown.enter.prevent="aturdiskon(item)"
                      />
                    </template>
                    <template #cell(aksi)="{ item }">
                      <section class="d-flex justify-content-end">
                        <b-button
                          :id="`edit-btn-${item.barang_id}`"
                          size="sm"
                          @click.prevent="aturharga(item)"
                          class="mr-1"
                          variant="outline-info"
                          title="Atur Harga"
                        >
                          <!-- <feather-icon icon="EditIcon" /> -->
                          Atur Harga Jual
                        </b-button>
                        <b-button
                          :id="`delete-btn-${item.barang_id}`"
                          size="sm"
                          @click.prevent="deleteSingle(item.barang_id)"
                          class="mr-1"
                          variant="outline-danger"
                          title="Hapus Produk"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                        <!-- <b-button :id="`edit-btn-${item.barang_id}`" size="sm" @click.prevent="aturdiskon(item)"
                          class="mr-1" variant="outline-info" title="Atur Harga">Atur Diskon
                        </b-button> -->
                      </section>
                    </template>
                  </b-table>
                </b-col>
                <!-- <b-col cols="1">
                  <b-form-checkbox
                    v-model="modeedit"
                  >
                    Mode Edit
                  </b-form-checkbox>
                </b-col> -->
                <!-- <b-col cols="3">
                  <table border="3">
                    <tr>
                      <th>No</th>
                      <th>Satuan Dari</th>
                      <th>Nilai</th>
                      <th>Satuan Barang</th>
                    </tr>
                    <tr v-for="(satuan, i) in item.konversi" :key="i">
                      <td>{{ i + 1 }}</td>
                      <td>{{ satuan.no }}</td>
                      <td>1 {{ satuan.satuan ? satuan.satuan.satuan : "-" }}</td>
                      <td>
                        {{ formatRupiah(satuan.nilai) }}
                      </td>
                      <td>
                    {{ formatRupiah(satuan.barang.satuan.satuan) }}
                  </td>
                    </tr>
                  </table>
                </b-col> -->
              </b-row>
              <b-button
                v-if="tabel == 1"
                size="sm"
                variant="outline-success"
                @click="ubahnama(item)"
                class="mb-1 mr-1"
              >
                Simpan
              </b-button>
              <b-button
                size="sm"
                variant="outline-danger"
                @click="toggleDetails"
                class="mb-1"
              >
                Hide
              </b-button>
              <!-- </b-card> -->
            </template>
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(jenis)="{ item }">
              {{ item.jenis == 1 ? "Rokok" : "Non-Rokok" }}
            </template>
            <!-- <template #cell(harga_jual)="{ item }">
              {{ item.harga_jual && item.harga_jual.length > 0 ? formatRupiah(item.harga_jual[0].harga) : 0 }}
            </template> -->

            <template #cell(id_satuan)="{ item }">
              {{ item.satuan ? item.satuan.satuan : "-" }}
            </template>
            <template #cell(action)="{ item }">
              <section class="d-flex justify-content-end">
                <!-- <b-button v-if="item.varian_item.length > 1" :id="`edit-btn-${item.id}`" size="sm"
                  @click.prevent="aturharga(item)" class="mr-1" variant="outline-info" title="Atur Harga">
                  Varian
                </b-button> -->

                <b-button
                  v-if="allowUpdate()"
                  :id="`edit-btn-${item.id}`"
                  size="sm"
                  @click.prevent="$router.push(`/barang/edit/${item.id}`)"
                  class="mr-1"
                  variant="outline-info"
                  title="Edit Produk"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <!-- <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip> -->

                <b-button
                  v-if="allowDelete()"
                  :id="`delete-btn-${item.id}`"
                  size="sm"
                  @click.prevent="deleteSingle(item.id)"
                  class="mr-1"
                  variant="outline-danger"
                  title="Hapus Produk"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
                <!-- <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip> -->
              </section>
            </template>
          </b-table>
          <b-row>
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
          <b-modal
            v-model="showaturharga"
            id="modal-lg"
            size="lg"
            :title="itembarang ? itembarang.varian : ''"
            ok-title="Tutup"
            ok-variant="secondary"
            ok-only
            centered
            no-close-on-backdrop
          >
            <b-row>
              <b-col xl="6" lg="6" md="6" sm="12">
                <b-alert variant="danger" show>
                  <h4 class="alert-heading">Contoh yang salah</h4>
                  <div class="alert-body">
                    <span
                      >Jangan ada data tipe konsumen dan satuan yang sama
                      <ol>
                        <li>
                          Data Tipe Konsumen "Retail" dan Satuan "Barang",
                        </li>
                        <li>
                          Data Tipe Konsumen "Retail" dan Satuan "Barang",
                        </li>
                        Untuk Data kedua jangan sampai ada data yg berisikan
                        seperti diatas, harus berbeda satuannya
                      </ol>
                    </span>
                  </div>
                </b-alert>
              </b-col>
              <b-col xl="6" lg="6" md="6" sm="12">
                <b-alert variant="success" show>
                  <h4 class="alert-heading">Contoh yang benar</h4>
                  <div class="alert-body">
                    <span>
                      <ol>
                        <li>
                          Data Tipe Konsumen "Retail" dan Satuan "Barang",
                        </li>
                        <li>Data Tipe Konsumen "Retail" dan Satuan "Sesi",</li>
                      </ol>
                    </span>
                  </div>
                </b-alert>
              </b-col>
              <b-col xl="12" lg="12" md="12" sm="12">
                <!-- <b-form-group label="Tipe Konsumen" label-for="tipekonsumen" class="mb-0" label-size="sm">
                  <b-form-select
                    v-model="form2.tipe_konsumen_id"
                    :options="tipe_konsumen"
                    :reduce="(option) => option.id"
                    label="nama"
                  >

                </b-form-select>
                </b-form-group> -->
                <b-button
                  block
                  @click.prevent="tambahhargajual()"
                  class="mb-1"
                  variant="primary"
                  title="Add Harga Jual"
                >
                  <!-- <feather-icon icon="PlusIcon" /> -->
                  Add Harga Jual
                </b-button>
                <b-modal
                  v-model="kelolahargajual"
                  id="modal-md"
                  size="md"
                  :title="itembarang ? itembarang.varian : ''"
                  ok-title="Tutup"
                  ok-variant="secondary"
                  ok-only
                  centered
                  no-close-on-backdrop
                >
                  <b-form>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Tipe Konsumen"
                          label-for="h-tipe-konsumen"
                          label-cols-md="4"
                          ><b-form-select
                            v-model="form2.tipe_konsumen_id"
                            :options="tipe_konsumen"
                            :reduce="(option) => option.id"
                            label="nama"
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Konversi Satuan"
                          label-for="h-satuan"
                          label-cols-md="4"
                          ><b-form-select
                            v-model="form2.satuan_id"
                            :options="optsatuan"
                            :reduce="(option) => option.id"
                            label="nama"
                          >
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <template v-if="isFinance">
                          <b-form-group
                            label="Payout"
                            label-for="h-share_jasa"
                            label-cols-md="4"
                          >
                            <b-form-input
                              v-model="form2.share_jasa"
                              id="h-share_jasa"
                              placeholder="Payout"
                            />
                          </b-form-group>
                        </template>
                        <template v-else>
                          <b-form-group
                            label="Payout"
                            label-for="h-share_jasa"
                            label-cols-md="4"
                          >
                            <b-form-input
                              v-model="form2.share_jasa"
                              id="h-share_jasa"
                              placeholder="Payout"
                              disabled
                            />
                          </b-form-group>
                        </template>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group
                          label="Margin"
                          label-for="h-share_penjual"
                          label-cols-md="4"
                        >
                          <b-form-input
                            v-model="form2.share_penjual"
                            id="h-share_penjual"
                            placeholder="Harga Jual Mitra"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- submit and reset -->
                      <b-col offset-md="4">
                        <b-button
                          @click="submithargajual()"
                          variant="primary"
                          class="mr-1"
                        >
                          Submit
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-modal>
              </b-col>
              <b-col cols="12">
                <b-table
                  :fields="[
                    { key: 'tipe', label: 'Tipe Konsumen' },
                    { key: 'satuan', label: 'Satuan' },
                    // { key: 'share_jasa', label: 'Payout' },
                    { key: 'harga_dasar', label: 'Harga Dasar' },
                    { key: 'share_penjual', label: 'Margin' },
                    { key: 'harga', label: 'Harga Jual' },
                    // { key: 'margin2', label: 'Margin' },
                    { key: 'aksi', label: '#' },
                  ]"
                  small
                  hover
                  responsive
                  :items="listhargajual ? listhargajual : []"
                >
                  <template #cell(aksi)="{ item }">
                    <section class="d-flex justify-content-end">
                      <b-button
                        :id="`edit-btn-${item.id}`"
                        size="sm"
                        @click.prevent="hapushargajual(item)"
                        class="mr-1"
                        variant="outline-danger"
                        title="Hapus Harga Jual"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </section>
                  </template>
                  <template #cell(tipe)="{ item, index }">
                    {{ item.tipe ? item.tipe.nama : "-" }}
                  </template>
                  <template #cell(satuan)="{ item, index }">
                    {{ item.satuan ? item.satuan.satuan : "-" }}
                  </template>
                  <template #cell(share_jasa)="{ item, index }">
                    <template v-if="isFinance">
                      <b-form-input
                        v-model="item.share_jasa"
                        type="text"
                        @keyup="
                          doFormatHarga({ index, item });
                          hargaJual(index, item);
                        "
                        placeholder="Mark Up"
                        @keydown.enter.prevent="ubahharga(item)"
                      />
                    </template>
                    <template v-else>
                      <b-form-input
                        v-model="item.share_jasa"
                        type="text"
                        @keyup="
                          doFormatHarga({ index, item });
                          hargaJual(index, item);
                        "
                        placeholder="Mark Up"
                        @keydown.enter.prevent="ubahharga(item)"
                        readonly
                      />
                    </template>
                  </template>
                  <template #cell(share_penjual)="{ item, index }">
                    <b-form-radio
                      v-model="selected"
                      name="some-radios"
                      value="persentase"
                      >Persentase</b-form-radio
                    >
                    <b-form-radio
                      v-model="selected"
                      name="some-radios"
                      value="nominal"
                      >Nominal</b-form-radio
                    >
                    <template v-if="selected == 'nominal'">
                      <b-form-input
                        v-model="item.share_penjual"
                        type="text"
                        @keyup="
                          doFormatHarga({ index, item });
                          hargaJual(index, item);
                        "
                        placeholder="Margin"
                        @keydown.enter.prevent="ubahharga(item)"
                      />
                    </template>
                    <template v-else>
                      <b-form-group :label="selected" label-for="h-persentase">
                        <b-form-input
                          v-model="item.persentase"
                          id="h-persentase"
                          placeholder="Persentase"
                        />
                      </b-form-group>
                      <b-form-group label="Margin" label-for="h-margin">
                        <b-form-input
                          v-model="item.share_penjual"
                          type="text"
                          @keyup="
                            doFormatHarga({ index, item });
                            hargaJual(index, item);
                          "
                          placeholder="Margin"
                          @keydown.enter.prevent="ubahharga(item)"
                        />
                      </b-form-group>
                    </template>
                  </template>
                  <template #cell(harga)="{ item, index }">
                    <b-form-input
                      v-model="item.harga"
                      type="text"
                      @keyup="
                        doFormatHarga({ index, item });
                        hargaJual(index, item);
                      "
                      placeholder="Harga"
                    />
                  </template>
                  <template #cell(harga_dasar)="{ item, index }">
                    {{ item.harga_dasar }}
                  </template>
                  <template #cell(margin)="{ item, index }">
                    {{ formatRupiah(item.harga - item.harga_dasar) }}
                  </template>
                  <template #cell(margin2)="{ item }">
                    {{
                      unFormatRupiah(item.harga) -
                      unFormatRupiah(item.harga_dasar)
                    }}
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-modal>
          <b-modal
            no-close-on-esc
            no-close-on-backdrop
            v-model="showaturdiskon"
            id="modal-diskon"
            size="md"
            ok-variant="secondary"
            centered
            title="Atur Diskon per Produk"
          >
            <b-row>
              <b-col xl="12" lg="12" md="12" sm="12">
                <!-- <b-card border-variant="primary"> -->
                <!-- <hr /> -->
                <!-- <validation-observer ref="formbiaya"> -->
                <b-form>
                  <b-row>
                    <b-col xl="8" lg="8" md="8" sm="12">
                      <b-form-group
                        v-if="itembdiskon.diskon != null"
                        label="Nilai Nominal"
                        label-for="v-value"
                      >
                        <b-form-input v-model="itembdiskon.diskon.value" />
                      </b-form-group>
                      <b-form-group
                        v-else
                        label="Nilai Nominal"
                        label-for="v-value"
                      >
                        <b-form-input v-model="itemformdiskon.value" />
                      </b-form-group>
                    </b-col>
                    <b-col xl="4" lg="4" md="4" sm="12">
                      <b-form-group label="-" label-for="v-value">
                        <b-button
                          variant="primary"
                          @click.prevent="submitaturdiskon"
                          block
                        >
                          Simpan
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-col>
            </b-row>
            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button
                  size="sm"
                  variant="danger"
                  @click="
                    showaturdiskon = false;
                    getData();
                  "
                >
                  Keluar
                </b-button>
              </section>
            </template>
          </b-modal>
        </b-card-actions>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
// import './dist/viewer.css'
// import VueViewer from 'v-viewer'
import ModalImport from "./Import.vue";
import AturHarga from "./components/AturHarga.vue";
import service from "@/services";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BFormRadio,
  BButtonGroup,
  BInputGroupPrepend,
  BFormCheckboxGroup,
  BFormSelectOption,
  BModal,
  BAlert,
  BForm,
  BOverlay,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCardText,
  BTable,
  BInputGroupAppend,
  BInputGroup,
  BTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import DataBarang from "./components/DataBarang.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
// new Viewer(images, {
//   zoomable: true,
//   rotatable: true,
//   scalable: true,
//   navbar: true,
//   title: false
// });

export default {
  components: {
    Viewer,
    // VueViewer,
    // viewer: VueViewer,
    ModalImport,
    BFormRadio,
    BButtonGroup,
    BInputGroupPrepend,
    BFormSelectOption,
    BModal,
    BForm,
    BAlert,
    BFormCheckboxGroup,
    AturHarga,
    quillEditor,
    DataBarang,
    BCardText,
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BInputGroupAppend,
    BInputGroup,
    BTooltip,
    BFormCheckbox,
  },
  // directives: {
  //   viewer: viewer({
  //     debug: true,
  //   }),
  // },
  data() {
    return {
      is_pre_order: null,
      selected: "nominal",
      //Mode
      modeedit: true,
      //Atur Diskon
      showaturdiskon: false,
      itembdiskon: {
        diskon: {
          value: 0,
        },
      },
      itemformdiskon: {
        value: 0,
      },
      //Atur Harga
      optsatuan: [],
      kelolahargajual: false,
      showaturharga: false,
      itembarang: null,
      itembarangvarian: null,
      form2: {
        varian_id: null,
        tipe_konsumen_id: null,
      },
      konversiSatuanItem: [],
      listhargajual: [],
      //List Barang
      pageOptions: [10, 20, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      loaded: true,
      tabel: 0,
      tabeloption: null,
      fields: null,
      fields1: [
        { key: "show_detailsts", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "kodests", label: "Kode" },
        { key: "namasts", label: "Nama Produk", sortable: true },
        { key: "variansts", label: "Varian" },
        // { key: "harga_dasarsts", label: "Harga Dasar (Rp)", sortable: true },
        { key: "harga_jualsts", label: "Harga Jual (Rp)", sortable: true },
        // { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      fieldsDefault: [
        { key: "checkbox", label: "#" },
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "fotopro", label: "Photo", sortable: true },
        // { key: "foto", label: "Barcode", sortable: true },
        // { key: "kategori", label: "Kategori", sortable: true },
        { key: "kode", label: "Barcode" },
        { key: "nama", label: "Nama Produk", sortable: true },
        // { key: "kelas", label: "Kelas", sortable: true },
        // { key: "varian", label: "Varian" },
        // { key: "harga_jual", label: "Harga Jual", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      //Finance
      fields3: [
        { key: "show_detailsts", label: "#" },
        { key: "no", label: "No", sortable: true },
        // { key: "kategori", label: "Kategori", sortable: true },
        { key: "kodests", label: "Kode" },
        { key: "namasts", label: "Nama Produk", sortable: true },
        { key: "variansts", label: "Varian" },
        { key: "harga_dasarsts", label: "Harga Dasar (Rp)", sortable: true },
        { key: "margin", label: "Margin", sortable: true },
        { key: "harga_jualsts", label: "Harga Jual (Rp)", sortable: true },
        { key: "action", label: "#" },
      ],
      fields4: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "kodests", label: "Kode" },
        { key: "namasts", label: "Nama Produk", sortable: true },
        { key: "variansts", label: "Varian" },
        // { key: "harga_dasarsts", label: "Harga Dasar (Rp)", sortable: true },
        { key: "harga_jualsts", label: "Harga Jual (Rp)", sortable: true },
        // { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      loading: false,
      params: {
        order: "desc",
        // start: 0,
        // length: 10,
      },
      optis_member: [],
      is_member: null,
      items: [],
      tipe_konsumen: [],
      showMassDelButton: false,
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      loadingkategori: false,
      itemsKategori: [],
      kategori_id: null,
      searchOptions: null,
      customefilter: null,
      kelas_id: null,
      itemsKelas: [],
      loadingkelas: false,
      sortBarang: null,
      currentImage: null,
      currentBarcode: null,
      viewer: null,
      allChecked: false,
      selectAll2: false,
      selectedginee: [],
      modalexport: false,
      tableFields: [{ key: "field", label: "Field" }],
      selectedFields: [],
      formatOptions: [".pdf", ".xlsx"],
      selectedFormat: ".xlsx",
      kolom: [],
      cekkolom: [],
    };
  },
  watch: {
    allChecked(val) {
      if (val) {
        // console.log('cekk', val)
        this.selectedginee = this.items;
      } else {
        this.selectedginee = [];
      }
    },
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    tabel(val) {
      if (val == 1) {
        this.fields = this.fields1;
      } else if (val == 2) {
        this.fields = this.fieldsDefault;
      } else if (val == 3) {
        this.fields = this.fields3;
      } else if (val == 4) {
        this.fields = this.fields4;
      } else if (val == 0 || null) {
        this.fields = this.fieldsDefault;
      }
    },
  },
  methods: {
    exportData() {
      // console.log('CEKKK', this.selectedFormat, this.cekkolom)
      if (this.selectedFormat === ".xlsx") {
        this.exportexcel2Modal();
      } else {
        this.exportpdfModal();
      }
      // console.log("Selected Fields:", this.selectedFields);
      // console.log("Selected Format:", this.selectedFormat);
      // this.$bvModal.hide("export-modal");
    },
    exportexcel2Modal() {
      if (this.cekkolom.length > 0) {
        this.$swal({
          title: "Sedang Memproses...",
          text: "Tunggu sebentar, proses sedang berjalan.",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        this.loading = true;
        let params = {
          filter: this.cekkolom.join(","),
        };
        this.$store
          .dispatch("barang/exportExcel", params)
          .then((response) => {
            // console.log('CEKKK', response)
            if (response) {
              const fileUrl = response.data; // Gabungkan base URL dengan URL dari response
              window.open(fileUrl, "_blank"); // Membuka tab baru dengan URL yang benar
            } else {
              console.error("Response tidak mengandung URL");
              this.displayError("Gagal mendapatkan file untuk diunduh.");
            }

            this.$swal.close();
            // this.getGinee();
            this.loading = false;
            // window.open(response.link, '_blank');
            // console.log('CEK', response)
            // this.loadingresep = false
          })
          .catch((e) => {
            this.$swal.close();
            this.loading = false;
            this.displayError(e);
            return false;
          });
      } else {
        this.displayWarning({
          message: "Harap isi form tanggal yang disediakan",
        });
      }
    },
    exportpdfModal() {
      if (this.cekkolom.length > 0) {
        this.$swal({
          title: "Sedang Memproses...",
          text: "Tunggu sebentar, proses sedang berjalan.",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        this.loading = true;
        let params = {
          filter: this.cekkolom.join(","),
        };
        this.$store
          .dispatch("barang/exportPdf", params)
          .then((response) => {
            if (response && response.url) {
              const fileUrl =
                "https://api-heykama.notive.my.id/" + response.url; // Gabungkan base URL dengan URL dari response
              window.open(fileUrl, "_blank"); // Membuka tab baru dengan URL yang benar
            } else {
              console.error("Response tidak mengandung URL");
              this.displayError("Gagal mendapatkan file untuk diunduh.");
            }

            this.$swal.close();
            // this.getGinee();
            this.loading = false;
            // window.open(response.link, '_blank');
            // console.log('CEK', response)
            // this.loadingresep = false
          })
          .catch((e) => {
            this.$swal.close();
            this.loading = false;
            this.displayError(e);
            return false;
          });
      } else {
        this.displayWarning({
          message: "Harap isi form tanggal yang disediakan",
        });
      }
    },
    exportexcelModal() {
      this.modalexport = true;
    },
    massremove() {
      let filteredData = this.selectedginee.map((item) => ({
        id: item.id,
        fungsi: 1,
      }));
      let joinedCategories = this.selectedginee
        .map((item) => item.varian)
        .join(",");
      this.$swal({
        title: "Anda yakin?",
        text: `Data Setting Packaging dari "${joinedCategories}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("barang/save", filteredData)
            .then(() => {
              // this.pesanBerhasilHapus()
              this.displaySuccess({
                message: "Berhasil Hapus",
              });
              // this.resetForm()
              this.getData();
            })
            .catch((e) => {
              // this.pesanGagalHapus()
              this.displayError(e);
              return false;
            });
        }
      });
    },
    openModal(src, src2) {
      // Set gambar saat ini dan buka modal
      this.currentImage = src;
      this.currentBarcode = src2;
      this.$refs.imageModal.show();

      // Inisialisasi Viewer.js
      this.$nextTick(() => {
        if (this.viewer) {
          this.viewer.destroy();
        }
        this.viewer = new Viewer(this.$refs.imageViewer, {
          inline: false,
          zoomable: true,
          scalable: true,
        });
      });
    },
    show(cek) {
      this.$viewerApi({
        images: cek,
      });
    },
    getKategori() {
      this.loadingkategori = true;
      this.$store
        .dispatch("kategori/getData")
        .then(() => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.kategori.datas)
          );
          // let items_total = this.$store.state.kategori.totals;
          this.itemsKategori = items;
          this.itemsKategori.map((item) => {
            item.text = item.kategori;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKategori)
          this.loadingkategori = false;
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    getKelas() {
      this.loadingkelas = true;
      this.$store
        .dispatch("kelas/getData")
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
          // let items_total = this.$store.state.kelas.totals;
          this.itemsKelas = items;
          this.itemsKelas.map((item) => {
            item.text = item.kelas;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKelas)
          this.loadingkelas = false;
          // this.items = this.$store.state.kelas.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    addimport() {
      // this.resetForm()
      this.$bvModal.show("modal-import");
    },
    hapushargajual(item) {
      this.$swal({
        title: "Yakin hapus?",
        text: "Data ini akan terhapus jika klik 'Ya, Hapus'",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            this.$store.dispatch("hargajual/save", [
              {
                id: item.id,
                tipe_konsumen_id: item.tipe_konsumen_id,
                barang_id: item.barang_id,
                satuan_id: item.satuan_id,
                fungsi: 1,
              },
            ]);
            this.$swal({
              icon: "success",
              title: "Terhapus!",
              text: "Data kamu berhasil terhapus",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            this.displaySuccess({
              message: "Harga berhasil terhapus",
            });
            this.gethargajual();
          } catch (e) {
            this.displayError(e);
            return false;
          }
        }
      });
      // this.$bvModal
      //   .msgBoxConfirm("Anda yakin akan menghapus barang ini? Data yang didalamnya akan hilang!", {
      //     title: "Konfirmasi Penghapusan",
      //     size: "sm",
      //     okVariant: "danger",
      //     okTitle: "Yes",
      //     cancelTitle: "No",
      //     cancelVariant: "outline-secondary",
      //     hideHeaderClose: false,
      //     centered: true,
      //   })
      //   .then((value) => {
      //     // this.boxTwo = value
      //     this.$store
      //       .dispatch("hargajual/save", [
      //         {
      //           id,
      //           fungsi: 1,
      //         },
      //       ])
      //       .then(() => this.gethargajual())
      //       .catch((e) => this.displayError(e));
      //   });
    },
    async submithargajual() {
      let payload = {
        tipe_konsumen_id: this.form2.tipe_konsumen_id,
        barang_id: this.itembarang.id,
        satuan_id: this.form2.satuan_id,
        share_jasa: this.form2.share_jasa
          ? this.unFormatRupiah(this.form2.share_jasa)
          : 0,
        share_penjual: this.unFormatRupiah(this.form2.share_penjual),
        // harga: this.unFormatRupiah(this.form2.share_penjual),
      };
      // if (this.form2.id) {
      //   payload.id = this.form2.id;
      // }
      try {
        await this.$store.dispatch("hargajual/save", [payload]);
        this.displaySuccess({
          message: "Harga berhasil disimpan",
        });
        this.kelolahargajual = false;
        this.gethargajual();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahhargajual(item1) {
      const ubah = item1.varian_item[0];
      const payload = {
        barang_utama: item1.id,
        nama: item1.nama,
        jenis: item1.jenis,
        jenis_obat: item1.jenis_obat,
        generik: item1.generik,
        narkotik: item1.narkotik,
        klasifikasi_usia: item1.klasifikasi_usia,
        id_kategori: item1.kategori ? item1.kategori.id : null,
        id_satuan: item1.satuan ? item1.satuan.id : null,
        milik: item1.milik,
        deskripsi: item1.deskripsi,
        kelompok_id: item1.kelompok_id,
        kondisi: item1.kondisi,
        min_pesanan: item1.min_pesanan,
        berat: item1.berat,
        panjang: item1.panjang,
        lebar: item1.lebar,
        tinggi: item1.tinggi,
        asuransi_pengiriman: item1.asuransi_pengiriman,
        is_consignment: item1.is_consignment == true ? 1 : 0,
        is_pre_order: item1.is_pre_order == true ? 1 : 0,
        pre_order_day: item1.pre_order_day == true ? 1 : 0,
        show_to_others: item1.show_to_others == true ? 1 : 0,
        kode: ubah.kode,
        varian: ubah.varian,
        harga_dasar: ubah.harga_dasar,
        item: [
          {
            barang_id: ubah.barang_id,
            varian: ubah.varian,
            kode: ubah.kode,
            harga_dasar: ubah.harga_dasar,
            no_batch: ubah.no_batch,
            expire_date: ubah.expire_date,
          },
        ],
      };
      try {
        await this.$store.dispatch("barang/save2", payload);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    tambahhargajual() {
      this.kelolahargajual = true;
    },
    hargaJual(index, item) {
      let getSatuan = this.listhargajual[index];
      // let harga =
      //   parseInt(this.unFormatRupiah(getSatuan.share_jasa)) +
      //   parseInt(this.unFormatRupiah(getSatuan.share_penjual));
      let harga =
        parseInt(this.unFormatRupiah(getSatuan.share_penjual)) +
        parseInt(this.unFormatRupiah(getSatuan.harga_dasar));
      this.listhargajual[index].harga = this.formatRupiah(harga);
    },
    doFormatHarga({ index, item }) {
      // console.log(this.listhargajual[index]);
      this.listhargajual[index].share_jasa = this.formatRupiah(
        this.listhargajual[index].share_jasa
      );
      this.listhargajual[index].harga_dasar = this.formatRupiah(
        this.listhargajual[index].harga_dasar
      );
      this.listhargajual[index].share_penjual = this.formatRupiah(
        this.listhargajual[index].share_penjual
      );
      const prices = Object.assign([], this.listhargajual);
      this.listhargajual = [];
      this.listhargajual = prices;
    },
    //Atur Harga
    aturharga(item) {
      this.itembarangvarian = item;
      this.showaturharga = true;
      this.getBarangId();
      // this.getTypeKonsumen()
    },
    //Atur Diskon
    aturdiskon(item) {
      this.itembdiskon = item;
      this.showaturdiskon = true;
      // this.getBarangId()
      // this.getTypeKonsumen()
    },
    submitaturdiskon() {
      if (this.itembdiskon.diskon == null) {
        const payload = {
          type: "nominal",
          value: this.itemformdiskon.value,
          barang_id: this.itembdiskon.barang_id,
        };
        try {
          this.$store.dispatch("diskon/saveperproduk", [payload]);
          this.displaySuccess({
            message: "Diskon per barang berhasil diperbarui",
          });
          this.getData();
          this.itemformdiskon.value = 0;
          this.showaturdiskon = false;
        } catch (e) {
          this.displayError(e);
          return false;
        }
      } else {
        const payload = {
          id: this.itembdiskon.diskon.id,
          type: "nominal",
          value: this.itembdiskon.diskon.value,
          barang_id: this.itembdiskon.barang_id,
        };
        try {
          this.$store.dispatch("diskon/saveperproduk", [payload]);
          this.displaySuccess({
            message: "Diskon per barang berhasil diperbarui",
          });
          this.getData();
          this.showaturdiskon = false;
        } catch (e) {
          this.displayError(e);
          return false;
        }
      }
    },
    async getBarangId() {
      let barang = await this.$store.dispatch(
        "barang/getDataByIdV2",
        this.itembarangvarian.barang_id
      );
      // console.log('AJI',barang)
      this.itembarang = barang;
      this.gethargajual();
    },
    getFilter() {
      this.$store
        .dispatch("barang/getfilterkolom")
        .then((response) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          // let items_total = this.$store.state.barang.totals;
          this.kolom = response.data;
          // this.totalRows = items_total;
          // this.loaded = true;
          // this.loading = false;
        })
        .catch((e) => {
          // this.loaded = true;
          // this.loading = false;
          // console.error(e);
          this.displayError(e);

          return false;
        });
    },
    async gethargajual() {
      let params = {
        barang_id: this.itembarang.id,
        // tipe_konsumen_id: this.form2.tipe_konsumen_id,
      };
      let hargaJual = await this.$store.dispatch("hargajual/getData", params);
      hargaJual = JSON.parse(JSON.stringify(hargaJual));
      this.listhargajual = hargaJual;
      this.getDataKonversi();
      this.getTypeKonsumen();
    },
    async getTypeKonsumen() {
      this.tipe_konsumen = [];
      const tipeKonsumen = await this.$store.dispatch("tipekonsumen/getData");
      tipeKonsumen.map((item) => {
        item.value = item.id;
        item.text = item.nama;
      });
      // var result = this.listhargajual.map(a => a.tipe.id);
      // const filterInPlace = (array, predicate) => {
      //   let end = 0;
      //   for (let i = 0; i < array.length; i++) {
      //     const obj = array[i];
      //     if (predicate(obj)) {
      //       array[end++] = obj;
      //     }
      //   }
      //   array.length = end;
      // };
      // const toDelete = new Set(result);
      // filterInPlace(tipeKonsumen, obj => !toDelete.has(obj.id));
      this.tipe_konsumen = tipeKonsumen;
      // console.log('jual', tipeKonsumen)
    },
    async getDataKonversi() {
      // const konversi = []
      const konversi = await this.$store.dispatch("konversi/getData", {
        id_barang: this.itembarang.id,
      });
      // this.optsatuan = this.$store.state.konversi.datas;
      // konversi = JSON.parse(JSON.stringify(konversi));
      konversi.map((item) => {
        item.value = item.id;
        item.text = item.satuan ? item.satuan.satuan : null;
      });
      // console.log('AJI',this.itembarang)
      var arr = [
        {
          value: this.itembarang.satuan.id,
          text: this.itembarang.satuan.satuan,
        },
        ...konversi,
      ];
      // console.log('satuan', arr)
      this.optsatuan = arr;
    },

    //List Barang
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    doFormatRupiah(i) {
      item[i].harga_dasar = this.formatRupiah(this.form.item[i].harga_dasar);
    },
    async ubahharga(item) {
      const payload = {
        id: item.id,
        tipe_konsumen_id: item.tipe_konsumen_id,
        barang_id: item.barang_id,
        harga:
          parseInt(this.unFormatRupiah(item.share_penjual)) +
          parseInt(this.unFormatRupiah(item.harga_dasar)),
        satuan_id: item.satuan ? item.satuan.id : item.satuan_id,
        share_jasa: parseInt(this.unFormatRupiah(item.share_jasa)),
        share_penjual: parseInt(this.unFormatRupiah(item.share_penjual)),
      };
      console.log("AAA", payload);
      try {
        await this.$store.dispatch("hargajual/save", [payload]);
        this.displaySuccess({
          message: "Harga jual berhasil diperbarui",
        });
        this.getData();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahvarianitem(item1) {
      // const ubah = item1.varian_item[0]
      const payload = {
        id: item1.barang_id,
        main_sku: item1.main_sku,
        no_batch: item1.no_batch,
        kode: item1.kode,
        varian: item1.varian,
        harga_dasar: this.unFormatRupiah(item1.harga_dasar),
        harga_coret: this.unFormatRupiah(item1.harga_coret),
      };
      try {
        await this.$store.dispatch("barang/save2", [payload]);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahvarian(item1) {
      const ubah = item1.varian_item[0];
      const payload = {
        barang_utama: item1.id,
        nama: item1.nama,
        jenis: item1.jenis,
        jenis_obat: item1.jenis_obat,
        generik: item1.generik,
        narkotik: item1.narkotik,
        klasifikasi_usia: item1.klasifikasi_usia,
        id_kategori: item1.kategori ? item1.kategori.id : null,
        id_satuan: item1.satuan ? item1.satuan.id : null,
        milik: item1.milik,
        deskripsi: item1.deskripsi,
        kelompok_id: item1.kelompok_id,
        kondisi: item1.kondisi,
        min_pesanan: item1.min_pesanan,
        berat: item1.berat,
        panjang: item1.panjang,
        lebar: item1.lebar,
        tinggi: item1.tinggi,
        asuransi_pengiriman: item1.asuransi_pengiriman,
        is_consignment: item1.is_consignment == true ? 1 : 0,
        is_pre_order: item1.is_pre_order == true ? 1 : 0,
        pre_order_day: item1.pre_order_day == true ? 1 : 0,
        show_to_others: item1.show_to_others == true ? 1 : 0,
        kode: ubah.kode,
        varian: ubah.varian,
        harga_dasar: ubah.harga_dasar,
        item: [
          {
            barang_id: ubah.barang_id,
            varian: ubah.varian,
            kode: ubah.kode,
            harga_dasar: ubah.harga_dasar,
            no_batch: ubah.no_batch,
            expire_date: ubah.expire_date,
          },
        ],
      };
      try {
        await this.$store.dispatch("barang/save2", payload);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    async ubahnama(item1) {
      const ubah = item1.varian_item[0];
      const payload = {
        barang_utama: item1.id,
        nama: item1.nama,
        jenis: item1.jenis,
        jenis_obat: item1.jenis_obat,
        generik: item1.generik,
        narkotik: item1.narkotik,
        klasifikasi_usia: item1.klasifikasi_usia,
        id_kategori: item1.kategori ? item1.kategori.id : null,
        id_satuan: item1.satuan ? item1.satuan.id : null,
        milik: item1.milik,
        deskripsi: item1.deskripsi,
        kelompok_id: item1.kelompok_id,
        kondisi: item1.kondisi,
        min_pesanan: item1.min_pesanan,
        berat: item1.berat,
        panjang: item1.panjang,
        lebar: item1.lebar,
        tinggi: item1.tinggi,
        asuransi_pengiriman: item1.asuransi_pengiriman,
        is_consignment: item1.is_consignment == true ? 1 : 0,
        is_pre_order: item1.is_pre_order == true ? 1 : 0,
        pre_order_day: item1.pre_order_day == true ? 1 : 0,
        show_to_others: item1.show_to_others == true ? 1 : 0,
        kode: ubah.kode,
        varian: ubah.varian,
        harga_dasar: ubah.harga_dasar,
        item: [
          {
            barang_id: ubah.barang_id,
            varian: ubah.varian,
            kode: ubah.kode,
            harga_dasar: ubah.harga_dasar,
            no_batch: ubah.no_batch,
            expire_date: ubah.expire_date,
          },
        ],
      };
      try {
        await this.$store.dispatch("barang/save2", payload);
        this.displaySuccess({
          message: "Barang berhasil diperbarui",
        });
        this.getData();
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },
    deleteSingle(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Anda yakin akan menghapus barang ini? Data yang didalamnya akan hilang!",
          {
            title: "Konfirmasi Penghapusan",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          // this.boxTwo = value
          this.$store
            .dispatch("barang/save", [
              {
                id,
                fungsi: 1,
              },
            ])
            .then(() => this.getData())
            .catch((e) => this.displayError(e));
        });
    },
    async clearExportedFile(filename) {
      try {
        const config = {
          url: `/clear-export`,
          method: "get",
          params: {
            filename,
          },
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async print() {
      try {
        const config = {
          url: `/excel-data-barang`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return this.customRejectPromise(e);
      }
    },
    async exportBarang() {
      try {
        this.loading = true;

        const response = await this.print();
        await window.open(response);
        setTimeout(async () => {
          // delete selected file
          const arrFile = response.split("/");
          const filename = arrFile[arrFile.length - 1];

          await this.clearExportedFile(filename);
        }, 1000);

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    updatePage(val) {
      this.currentPage = val;
      this.getData();
    },
    getData() {
      // if (this.items > 1)
      this.loading = true;
      this.loaded = false;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        type: "barang",
        product_type: "barang",
        kategori_id: this.kategori_id ? this.kategori_id : null,
        sort: this.sortBarang ? this.sortBarang : null,
        is_pre_order: this.is_pre_order,
        // filter_by: "nama_lengkap",
      };
      if (this.searchOptions == "kelas_id") {
        payload.kelas_id = this.kelas_id ? this.kelas_id : null;
      }
      if (this.searchOptions == "main_sku") {
        payload.main_sku = this.customefilter ? this.customefilter : null;
      }
      if (this.searchOptions == "kode") {
        payload.kode = this.customefilter ? this.customefilter : null;
      }
      if (this.isCabang) {
        payload.member_id = this.user.member_id;
      }
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.barang.datas)
          );
          let items_total = this.$store.state.barang.totals;
          this.items = items;
          this.totalRows = items_total;
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    onItemChecked(payload) {
      if (payload.selectedRows.length > 0) {
        this.showMassDelButton = true;
      } else {
        this.showMassDelButton = false;
      }
    },
    getMember() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        sebagai: "cabang",
        // order: "desc",
        // start: currentPage,
        // length: this.perPage,
        // filter_by: "nama_lengkap",
        // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
        // user_id: this.user.id,
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        items.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.optis_member = items;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      });
    },
  },
  computed: {
    rows() {
      return this.items.total;
    },
    // Create an options list from our fields
    // sortOptions() {
    //   return this.fields
    //   .filter((f) => f.sortable)
    //   .map((f) => ({ text: f.label, value: f.key }));
    // },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeDestroy() {
    // Pastikan untuk menghancurkan instance Viewer.js saat komponen dihapus
    if (this.viewer) {
      this.viewer.destroy();
    }
  },
  mounted() {
    this.getData();
    this.getKategori();
    this.getKelas();
    this.getFilter();
    if (!this.isCabang) {
      this.getMember();
    }
    // this.getTypeKonsumen()
  },
  created() {
    if (this.user.karyawan.nama_lengkap == "Stasiun Qurban") {
      this.tabel = 1;
      this.tabeloption = [
        { value: 1, text: "Stasiun Qurban" },
        { value: 2, text: "Default" },
        // { value: 3, text: "Finance" },
        // { value: 4, text: "View 4" },
      ];
    } else if (this.isFinance) {
      this.tabel = 3;
      this.tabeloption = [
        { value: 1, text: "Stasiun Qurban" },
        { value: 2, text: "Default" },
        { value: 3, text: "Finance" },
        { value: 4, text: "View 4" },
      ];
    } else {
      this.tabel = 2;
      this.tabeloption = [
        // { value: 1, text: "Stasiun Qurban" },
        { value: 2, text: "Default" },
        // { value: 3, text: "Finance" },
        { value: 4, text: "Default 2" },
      ];
    }
    // this.fields = this.fieldsDefault
    // this.getDataV2();
    // this.rows = barang.list;
  },
};
</script>
<style scoped>
.border-custom {
  border-width: 2px !important;
}

.viewer-image {
  width: 100%;
  cursor: pointer;
}
</style>
<style>
.image-gallery {
  display: flex;
  flex-wrap: wrap;
}

.thumbnail {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 5px;
  cursor: pointer;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
</style>
