<template>
  <main>
    <b-overlay :show="loading">
      <!-- <form-filter-export :type_export="['excel']" @onExport="exportSales">
      <template #default>
        <b-form-group v-if="!isSales" label="Sales " class="mr-2">
          <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group label="Konsumen (opsional)">
          <v-select :options="konsumenOptions" v-model="dataExport.konsumen_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
      </template>
</form-filter-export> -->
      <b-card>
        <!-- <b-row class="align-items-center">
          <b-col sm="12" md="12" class="my-1">
            <section class="d-flex justify-content-between">
              <section>
                <b-button-group>

                  <b-button variant="warning" id="popover-target-manual">
                    <feather-icon icon="MailIcon" class="mr-50" />
                    {{ $t("Manual Order") }}
                  </b-button>

                  <b-button variant="primary" id="popover-target-sync">
                    <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                    {{ $t("Sync") }}
                  </b-button>
                </b-button-group>
                <b-popover
                  variant="primary"
                  target="popover-target-sync"
                  triggers="hover"
                  placement="top">
                  <template #title>Sync</template>
                  <label>Tanggal Mulai</label>
                  <b-form-datepicker
                    id="start_datesync"
                    :value="start_datesync"
                    locale="id"
                    v-model="start_datesync" />

                  <label class="mt-1">Tanggal Akhir</label>
                  <b-form-datepicker
                    id="end_datesync"
                    :value="end_datesync"
                    locale="id"
                    v-model="end_datesync" />

                  <b-button
                    variant="primary"
                    @click="syncModal()"
                    class="mt-1"
                    block>
                    {{ $t("Sync Now") }}</b-button
                  >
                  <b-button
                    class="mt-1"
                    block
                    v-if="isTokenSyncAvailable"
                    variant="primary"
                    @click="ceksync()"
                    >Cek Sync</b-button
                  >
                </b-popover>
                <b-popover
                  variant="warning"
                  target="popover-target-manual"
                  triggers="hover"
                  placement="top">
                  <b-button
                    class="mt-1"
                    block
                    variant="warning"
                    @click.prevent="$router.push('/pesanan/manual-order')">
                    <feather-icon icon="ShoppingBagIcon" class="mr-50" />
                    {{ $t("Add") }}</b-button
                  >
                  <b-button
                    class="mt-1"
                    block
                    variant="warning"
                    @click.prevent="$router.push('/pesanan/manual-order-list')">
                    <feather-icon icon="ListIcon" class="mr-50" />
                    {{ $t("List") }}</b-button
                  >
                </b-popover>
              </section>
              <section>
                <b-button
                  variant="primary"
                  @click="showModalPrintHistory = true">
                  <feather-icon icon="FileIcon" class="" />
                  {{ $t("Print History") }}
                </b-button>
                <b-button
                  class="ml-1"
                  variant="success"
                  id="popover-target-export">
                  <feather-icon icon="DownloadIcon" class="mr-50" />
                  {{ $t("Export") }}
                </b-button>
                <b-popover
                  variant="primary"
                  target="popover-target-export"
                  triggers="hover"
                  placement="top">
                  <template #title>Export</template>
                  <label>Tanggal Mulai</label>
                  <b-form-datepicker
                    id="start_dateexport"
                    :value="start_dateexport"
                    locale="id"
                    v-model="start_dateexport" />

                  <label class="mt-1">Tanggal Akhir</label>
                  <b-form-datepicker
                    id="end_dateexport"
                    :value="end_dateexport"
                    locale="id"
                    v-model="end_dateexport" />

                  <b-button
                    class="mt-1"
                    variant="success"
                    block
                    @click="exportexcelModal()">
                    <feather-icon icon="FileTextIcon" class="mr-50" />
                    {{ $t("Excel") }}
                  </b-button>
                  <b-button
                    class=""
                    variant="danger"
                    block
                    @click="exportpdfModal()">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    {{ $t("PDF") }}
                  </b-button>
                </b-popover>
              </section>
            </section>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Store</label>
          </b-col>
          <b-col xl="10" lg="10" md="10" sm="12" class="">
            <b-form-radio-group
              id="radio-groupstore"
              v-model="storemodel"
              plain
              @change="getGinee()"
              name="storeradio">
              <template #first>
                <b-form-radio :value="null">{{ $t("--ALL--") }}</b-form-radio>
              </template>
              <template v-for="item in storeOptions">
                <b-form-radio :value="item.shopId">
                  <b
                    ><b-img
                      :src="getImageUrl(item.channel)"
                      style="width: 15px"
                      alt="logo" />
                    {{ item.name }}</b
                  ></b-form-radio
                >
              </template>
            </b-form-radio-group>
            <b-button size="sm"
              v-if="storeOptions.length == 0"
              @click="getGineeStore()"
              >{{ $t("Refresh") }}</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Status Order</label>
          </b-col>
          <b-col
            xl="10"
            lg="10"
            md="10"
            sm="12"
            class="d-flex align-items-center">
            <b-form-radio-group
              id="statusorder"
              v-model="statusordermodel"
              plain
              @change="getGinee()"
              name="flavour-1">
              <template #first>
                <b-form-radio :value="null">{{ $t("--ALL--") }}</b-form-radio>
              </template>
            </b-form-radio-group>
            <b-form-radio-group
              class="flex-grow-1"
              id="statusorder"
              v-model="statusordermodel"
              plain
              :options="statusorderOptions"
              @change="getGinee()"
              name="flavour-1">
            </b-form-radio-group>
            <b-button size="sm"
              v-if="statusorderOptions.length == 0"
              @click="getGineeStatus()"
              >{{ $t("Refresh") }}</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Logistic</label>
          </b-col>
          <b-col
            xl="10"
            lg="10"
            md="10"
            sm="12"
            class="mb-1 d-flex align-items-center">
            <b-form-checkbox
              v-model="selectAll2"
              @change="toggleAll2"
              plain
              class="mr-1">
              --ALL--
            </b-form-checkbox>
            <v-select
              class="flex-grow-1"
              v-model="logisticNames"
              :options="logisticNamesOptions"
              label="text"
              multiple
              :reduce="(option) => option.value"
              clearable
              placeholder="-Select separately-"
              :close-on-select="false"
              @input="updateSelectAll">
            </v-select>
            <b-button size="sm"
              v-if="logisticNamesOptions.length == 0"
              @click="getGineeLogistic()"
              >{{ $t("Refresh") }}</b-button
            >
          </b-col>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Date</label>
          </b-col>
          <b-col xl="3" lg="3" md="3" sm="12" class="">
            <b-form-group>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range',
                  enableTime: true,
                  time_24hr: true, // Gunakan format 24 jam, jika diinginkan
                  dateFormat: 'Y-m-d H:i' // Format tanggal dan waktu
                 }" />
            </b-form-group>
          </b-col>
          <b-col xl="7" lg="7" md="7" sm="12" class="">
            <b-form-group>
              <b-button class="mr-1" variant="primary" @click="todaydate()"
                >Today</b-button
              >
              <b-button class="mr-1" variant="primary" @click="seventodaydate()"
                >7 Days</b-button
              >
              <b-button variant="primary" @click="tigapuluhtodaydate()"
                >30 Days</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Status Print</label>
          </b-col>
          <b-col xl="10" lg="10" md="10" sm="12" class="">
            <b-form-radio-group
              id="statuslabel"
              v-model="statuslabel"
              plain
              :options="statuslabelOptions"
              @change="getGinee()"
              name="flavour-label">
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Other</label>
          </b-col>
          <b-col xl="10" lg="10" md="10" sm="12" class="">
            <b-row>
              <b-col>
                <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Product Name"
                  label-for="product-sm">
                  <b-form-input
                    @keydown.enter.prevent="getGinee()"
                    v-model="namaproduk">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Sort By"
                  label-for="product-sm">
                  <b-form-select
                    :options="sortOptions"
                    v-model="sortBy"
                    @change="getGinee()">
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Product Type"
                  label-for="product-sm">
                  <b-form-select
                    :options="typeOptions"
                    v-model="productType"
                    @change="getGinee()">
                    <template #first>
                      <b-form-select-option :value="null">{{
                        $t("--ALL--")
                      }}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>{{ $t("Per Page") }}</label>
          </b-col>
          <b-col xl="10" lg="10" md="10" sm="12" class="">
            <b-row>
              <b-col cols="2">
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions" />
              </b-col>
              <b-col>
                <b-form-group
                  label-cols="4"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="$t('Order Number')"
                  label-for="filterInput"
                  class="mb-0">
                  <b-input-group>
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Tekan enter untuk cari"
                      @keydown.enter.prevent="getGinee()" />
                    <b-input-group-append>
                      <b-button variant="primary" size="sm" @click="getGinee()">
                        {{ $t("Search") }}
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        variant="outline-danger"
                        size="sm"
                        @click="
                          filter = '';
                          getGinee();
                        ">
                        {{ $t("Reset") }}
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-button variant="outline-warning" block>
                  <h5 class="text-warning">Total : {{ ginee.length }}</h5>
                  <h5 class="text-warning">
                    Selected : {{ selectedginee.length }}
                  </h5>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col xl="2" lg="2" md="4" sm="4" class="">
            <b-form-group class="mb-0" :label="$t('Per page')" label-for="perPageSelect">
              <b-form-select id="perPageSelect" size="sm" v-model="perPage" :options="pageOptions" />
            </b-form-group>
          </b-col> -->
        <!-- <b-col md="4" sm="8" class="">
            <b-form-group :label="$t('Sort')"   label-for="sortBySelect"
              class="mb-0">
              <b-input-group >
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc"  :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col> -->
        <!-- <b-col xl="8" lg="8" md="8" sm="8" class="">
            <b-form-group :label="$t('Order Number')" label-for="filterInput" class="mb-0">
              <b-input-group>
                <b-form-input id="filterInput" size="sm" v-model="filter" type="search"
                  placeholder="Tekan enter untuk cari" @keydown.enter.prevent="getGinee()" />
                <b-input-group-append>
                  <b-button :disabled="!filter" size="sm" @click="
                    filter = '';
                  getGinee();
                  ">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <span><br>
              <h5>Total : {{ ginee.length }}</h5>
              <h5>Total Selected : {{ selectedginee.length }}</h5>
            </span>
          </b-col>
        </b-row> -->
        <!-- <b-row v-if="isFinance || isOwner || isCEO">
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group
              label="Status Bayar"
              label-for="status_bayar"
              class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="status_bayar"
                  v-model="status_bayar"
                  :options="optstatus_bayar"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group label="Status" label-for="statuskirim" class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="statuskirim"
                  v-model="statuskirim"
                  :options="optstatuskirim"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group
              label="Daftar yang sudah masuk ke akun dan kas"
              label-for="is_accounted"
              class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="is_accounted"
                  v-model="is_accounted"
                  :options="optis_accounted"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
            <b-form-group
              label="Pilih Affiliator"
              label-for="is_member"
              class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="is_member"
                  v-model="is_member"
                  :options="optis_member"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCabang || isMarketing">
          <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
            <b-form-group
              label="Status Bayar"
              label-for="status_bayar"
              class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="status_bayar"
                  v-model="status_bayar"
                  :options="optstatus_bayar"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
            <b-form-group label="Status" label-for="statuskirim" class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="statuskirim"
                  v-model="statuskirim"
                  :options="optstatuskirim"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
            <b-form-group label="Status" label-for="typeTransaksi" class="mb-0">
              <b-input-group>
                <b-form-select
                  size="sm"
                  id="typeTransaksi"
                  v-model="typeTransaksi"
                  :options="opttypetransaksi"
                  class=""
                  @input="getTransaksi()">
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- Semua --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col cols="2">
            <b-row>
              <b-col>
                <b-table
                :tbody-tr-class="rowClass"
                  small
                  hover
                  responsive
                  :per-page="perPage"
                  :items="ginee"
                  :fields="fieldsginee"
                  ref="selectableTable"
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
                  >
                  <!-- <template #cell(selectedginee)="{ rowSelected }">
        <template v-if="rowSelected == selectedginee">
          <i class="feather icon-disc primary" />
        </template>

        <template v-else>
          <i class="feather icon-circle" />
        </template>
      </template> -->
                  <template #head(checkbox)>
                    <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                  </template>
                  <template #cell(checkbox)="{ item }">
                    <b-form-checkbox
                      v-model="selectedginee"
                      :value="item"></b-form-checkbox>
                  </template>
                  <template #cell(no)="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template #cell(productinfo)="{ item }">
                    <b variant="primary" show
                      >
                      {{ item.externalOrderId }}
                      <!-- <b-button
                        :title="$t('Copy Order Number')"
                        v-clipboard:copy="item.externalOrderId"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                        size="sm"
                        variant="flat-primary"
                        class="btn-icon rounded-circle">
                        <feather-icon icon="CopyIcon" />  -->
                        </b-button
                    >
                  </b>
                    <!-- <b
                      :title="item.paymentMethod"
                      v-if="
                        item.paymentMethod == 'COD' ||
                        item.paymentMethod == 'Cash on Delivery'
                      ">
                      <b-img src="@/assets/images/svg/cod.svg" alt="logo" /> |
                    </b>
                    <b
                      :title="item.paymentMethod + ' / Pre Order'"
                      v-if="
                        item.paymentMethod == 'PrePaid' &&
                        item.orderType == 'PRE_SALE'
                      ">
                      <b-img src="@/assets/images/svg/pre_sale.svg" alt="logo" /> |
                    </b>
                    <b v-else></b>
                    <b
                      :title="'LABEL ' + item.printInfo.labelPrintStatus"
                      v-if="item.printInfo.labelPrintStatus == 'PRINTED'">
                      <b-img
                        src="@/assets/images/svg/printed_express.svg"
                        alt="logo" />
                      |
                    </b>
                    <b v-else></b>
                    <b>Status : {{ item.orderStatus }} | </b>
                    <b
                      ><b-img
                        :src="getImageUrl(item.channel)"
                        style="width: 15px"
                        :title="item.channel"
                        :alt="item.channel"
                    /></b> -->
                    <!-- <template v-for="hem in item.items">
                      <b-card
                        bg-variant="transparent"
                        no-body
                        class="overflow-hidden">
                        <b-row no-gutters>
                          <b-col md="3">
                            <b-card-img
                              style="width: 80px"
                              :src="hem.productImageUrl"
                              alt="Image"
                              class="rounded-0"></b-card-img>
                            <b-button
                              size="sm"
                              variant="warning"
                              @click.prevent="
                                getlensa(hem);
                                getCustomer(item);
                                getResep(item.externalOrderId);
                                getInfo(item);
                              "
                              v-if="
                                !hem.productName.match(/frame/gi) &&
                                !hem.productName.match(/pembersih/gi) &&
                                !hem.productName.match(/rubber/gi)
                              ">
                              {{ $t("Choose Lensa") }}</b-button
                            >
                          </b-col>
                          <b-col md="9">
                            <b-card-body :title="hem.productName">
                            <b-card-text>
                              <span :title="hem.productName">{{
                                truncateString(hem.productName, 30)
                              }}</span
                              ><br />
                              {{ hem.variationName }}<br />
                              {{ hem.masterSku }}<br />
                              {{ formatRupiah(hem.actualPrice) }} x
                              {{ hem.quantity }}
                            </b-card-text>
                            </b-card-body>
                          </b-col>
                        </b-row>
                      </b-card>
                    </template> -->
                  </template>
                  <template #cell(durasi)="{ item }">
                    <!-- <b variant="primary" show>{{ item.externalOrderId }} <b-button :title="$t('Copy Order Number')"
                        v-clipboard:copy="item.externalOrderId" v-clipboard:success="onCopy" v-clipboard:error="onError"
                        size="sm" variant="flat-primary" class="btn-icon rounded-circle">
                        <feather-icon icon="CopyIcon" />
                      </b-button></b>
                    <b :title="item.paymentMethod"
                      v-if="item.paymentMethod == 'COD' || item.paymentMethod == 'Cash on Delivery'"> <b-img
                        src="@/assets/images/svg/cod.svg" alt="logo" /> | </b>
                    <b :title="item.paymentMethod + ' / Pre Order'"
                      v-if="item.paymentMethod == 'PrePaid' && item.orderType == 'PRE_SALE'">
                      <b-img src="@/assets/images/svg/pre_sale.svg" alt="logo" /> | </b>
                    <b v-else></b>
                    <b>Status : {{ item.orderStatus }} | </b>
                    <b><b-img :src="getImageUrl(item.channel)" style="width: 15px;" :title="item.channel"
                        :alt="item.channel" /></b> -->
                    <!-- <template v-if="item.customerInfo">
                      <b-card bg-variant="transparent" no-body class="overflow-hidden">
                        <b-row no-gutters> -->
                    <!-- <b-col md="3">
                            <b-card-img style="width: 80px;" :src="hem.productImageUrl" alt="Image"
                              class="rounded-0"></b-card-img>
                            <b-button size="sm" variant="primary"
                              @click.prevent="getlensa(hem); getCustomer(item); getDataBarangFrame()"
                              v-if="!hem.productName.match(/frame/gi) && !hem.productName.match(/pembersih/gi) && !hem.productName.match(/hidung/gi)">
                              {{ $t('Choose Lensa') }}</b-button>
                          </b-col>a -->
                    <!-- <b-col md="9"> -->
                    <!-- <b-card-body :title="hem.productName"> -->
                    <b>{{ item.createAt ? $t("Creation Time") : null }}</b
                    ><br v-if="item.createAt" />
                    {{ item.createAt ? humanDateTime(item.createAt) : null
                    }}<br v-if="item.createAt" />
                    <b>{{ item.payAt ? $t("Payment Time") : null }}</b
                    ><br v-if="item.payAt" />
                    {{ item.payAt ? humanDateTime(item.payAt) : null
                    }}<br v-if="item.payAt" />
                    <b>{{ item.cancelAt ? $t("Cancel Time") : null }}</b
                    ><br v-if="item.cancelAt" />
                    {{ item.cancelAt ? humanDateTime(item.cancelAt) : null
                    }}<br v-if="item.cancelAt" />
                    <b>{{
                      item.printInfo.labelPrintTime ? $t("Print Time") : null
                    }}</b
                    ><br v-if="item.printInfo.labelPrintTime" />
                    {{
                      item.printInfo.labelPrintTime
                        ? humanDateTime(item.printInfo.labelPrintTime)
                        : null
                    }}
                    <!-- </b-card-body> -->
                    <!-- </b-col> -->
                    <!-- </b-row>
                      </b-card>
                    </template> -->
                  </template>
                  <template #cell(logistics)="{ item }">
                    {{
                      item.logisticsInfos
                        ? item.logisticsInfos[0].logisticsProviderName
                        : null
                    }}<br />
                    {{
                      item.logisticsInfos
                        ? item.logisticsInfos[0].logisticsTrackingNumber
                        : null
                    }}<br /><br />
                    {{ item.customerInfo ? item.customerInfo.name : null }}<br />
                    {{ item.customerInfo ? item.customerInfo.mobile : null
                    }}<br /><br />
                    {{ $t("Note By Buyer") }} : <br />
                    <template v-if="item.extraInfo.noteByBuyer">
                      <!-- <p
                        :title="item ? item.extraInfo ? item.extraInfo.noteByBuyer == '' && item.extraInfo.noteByBuyer == null ? null : item.extraInfo.noteByBuyer : null : null">
                        <b-img src="@/assets/images/svg/message.svg" style="width: 20px;" alt="logo" />
                      </p> -->
                      <p
                        :title="
                          item
                            ? item.extraInfo
                              ? item.extraInfo.noteByBuyer == '' &&
                                item.extraInfo.noteByBuyer == null
                                ? null
                                : item.extraInfo.noteByBuyer
                              : null
                            : null
                        ">
                        {{
                          item
                            ? item.extraInfo
                              ? item.extraInfo.noteByBuyer == "" &&
                                item.extraInfo.noteByBuyer == null
                                ? null
                                : item.extraInfo.noteByBuyer
                              : null
                            : null
                        }}
                        <!-- <b-button block variant="primary" size="sm">
                          <feather-icon icon="FileTextIcon" />
                          {{ $t("View") }}</b-button> -->
                      </p>
                    </template>
                    <template v-else>
                      <p class="text-danger">{{ $t("Note not found") }}</p>
                    </template>
                    <!-- <p><b>{{ item ? item.extraInfo ? item.extraInfo.noteByBuyer : null : null }}</b></p> -->
                  </template>
                  <template #cell(outbondorder)="{ item }">
                    <b-alert variant="primary" show></b-alert>
                    <template v-for="hem in item.items">
                      <b-card
                        bg-variant="transparent"
                        no-body
                        class="overflow-hidden">
                        <b-row no-gutters>
                          <b-col md="9">
                            <!-- <b-card-body :title="hem.productName"> -->
                            <b-card-text>
                              <!-- <span :title="hem.productName">{{ truncateString(hem.productName,
                                20) }}</span><br>
                              {{ hem.variationName }}<br>
                              {{ hem.sku }}<br>
                              {{ formatRupiah(hem.actualPrice) }} x {{ hem.quantity }} -->
                            </b-card-text>
                            <!-- </b-card-body> -->
                          </b-col>
                        </b-row>
                      </b-card>
                    </template>
                  </template>
                  <template #cell(status_bayar)="{ item }">
                    <b-badge v-if="item.status_bayar == 'lunas'" variant="success"
                      >LUNAS</b-badge
                    >
                    <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
                  </template>
                  <template #cell(payment_option)="{ item }">
                    <b-badge
                      v-if="item.payment_option == 'manual_transfer'"
                      variant="warning"
                      >Manual Transfer</b-badge
                    >
                    <b-badge
                      v-else-if="item.payment_option == 'pg_midtrans'"
                      variant="success"
                      >Otomatis</b-badge
                    >
                    <b-badge v-else variant="danger">COD</b-badge>
                  </template>
                  <template #cell(status)="{ item }">
                    <b-badge v-if="item.status == 'selesai'" variant="success"
                      >SELESAI</b-badge
                    >
                    <b-badge v-else-if="item.status == 'diproses'" variant="warning"
                      >DIPROSES</b-badge
                    >
                    <b-badge v-else-if="item.status == 'dikirim'" variant="primary"
                      >DIKIRIM</b-badge
                    >
                    <b-badge v-else-if="item.status == 'diterima'" variant="info"
                      >DITERIMA</b-badge
                    >
                    <b-badge
                      v-else-if="item.status == 'dibatalkan'"
                      variant="danger"
                      >DIBATALKAN</b-badge
                    >
                    <b-badge v-else variant="secondary">PENDING</b-badge>
                  </template>
                  <template #cell(rincian)="{ item }">
                    {{ item.rincian[0] ? item.rincian[0].nama_barang : "-" }}
                  </template>
                  <template #cell(waktu_transaksi)="{ item }">
                    <strong>{{ humanDateTime(item.waktu_transaksi) }}</strong>
                  </template>
                  <template #cell(uang_masuk)="{ item }">
                    <strong>Rp {{ formatRupiah(item.uang_masuk) }}</strong>
                  </template>
                  <template #cell(total_bayar)="{ item }">
                    <strong>Rp {{ formatRupiah(item.total_bayar) }}</strong>
                  </template>
                  <template #cell(transaction_type)="{ item }">
                    <strong>
                      {{
                        item.transaction_type == "jasa" ? "Jasa" : "Barang"
                      }}</strong
                    >
                  </template>
                  <template #cell(action)="{ item }">
                    <!-- <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                      openNewTabWithParam(item)
                      ">
                      <feather-icon icon="EyeIcon" /> Detail
                    </b-link>
                    <br> -->
                    <!-- <b-button
                      size="sm"
                      variant="warning"
                      class="text-dark mt-1"
                      @click.prevent="aturpengirimansingle(item)">
                      <feather-icon icon="CalendarIcon" class="text-dark mr-1" />
                      <span class="text-dark mr-1">{{
                        $t("Arrange Shipment")
                      }}</span>
                    </b-button>
                    <br /> -->
                    <b-button
                      size="sm"
                      variant="warning"
                      class="text-dark mt-1"
                      @click.prevent="printdoc(item)">
                      <feather-icon icon="PrinterIcon" class="text-dark mr-1" />
                      <span class="text-dark mr-1">Print Label</span>
                    </b-button>
                    <br />
                    <!-- <b-button size="sm" variant="warning" class="text-dark" @click.prevent="printinv(item)">
                      <feather-icon icon="PrinterIcon" class="text-dark" />
                      <span class="text-dark">View Invoice</span>
                    </b-button>
                    <br /> -->
                    <b-button
                      size="sm"
                      variant="warning"
                      class="text-dark mt-1"
                      @click.prevent="printinvv2(item)">
                      <feather-icon icon="PrinterIcon" class="text-dark mr-1" />
                      <span class="text-dark mr-1">Print Invoice</span>
                    </b-button>
                    <br />
                    <b-button
                      size="sm"
                      variant="warning"
                      class="text-dark mt-1"
                      @click.prevent="syncper(item)">
                      <feather-icon icon="RefreshCcwIcon" class="text-dark mr-1" />
                      <span class="text-dark mr-1">Sync</span>
                    </b-button>
                    <!-- <br /> -->
                    <!-- <b-button size="sm" variant="dark" class="text-dark" @click.prevent="
                      printmodalinv(item)
                      ">
                      <feather-icon icon="PrinterIcon" /> Print Invoice
                    </b-button>
                    <br> -->
                    <!-- <template v-for="hem in item.items"> -->
                    <!-- <b-button size="sm" variant="warning" class="text-dark" @click.prevent="getCustomerFrame(item)">
                      <feather-icon icon="BoxIcon" class="text-dark" />
                      <span class="text-dark"> Process to Warehouse </span>
                    </b-button> -->
                    <!-- </template> -->
                    <!-- <template v-for=" hem in item.items">
                      <b-link size="sm" variant="dark" class="text-dark" @click.prevent="printdoc(item)"
                        v-if="!hem.productName.match(/lensa/gi)">
                        <feather-icon icon="BoxIcon" /> Process Warehouse
                      </b-link>
                    </template> -->
                    <b-button
                      v-if="
                        item.transaction_type == 'jasa' &&
                        item.status != 'dibatalkan'
                      "
                      size="sm"
                      variant="outline-primary"
                      @click.prevent="modalTerapis(item)"
                      v-b-tooltip.hover.top="'Pilih Terapis'">
                      <feather-icon icon="UserIcon" />
                    </b-button>
                    <b-button
                      v-if="
                        item.status == 0 &&
                        allowUpdate() &&
                        item.tak_tertagih == 0 &&
                        item.bayar == 0
                      "
                      size="sm"
                      variant="outline-warning"
                      @click.prevent="
                        $router.push(`/transaksi-penjualan/edit/${item.id}`)
                      ">
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </template>
                  <template #cell(actionFinance)="{ item }">
                    <b-button
                      size="sm"
                      variant="warning"
                      @click.prevent="
                        $router.push(`/transaksi-penjualan/detail/${item.id}`)
                      ">
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      title="Konfirmasi Pembayaran Manual"
                      v-if="item.status_bayar != 'lunas'"
                      size="sm"
                      variant="primary"
                      @click="aksiKonfirmasiawal(item)">
                      <feather-icon icon="ListIcon" />
                    </b-button>
                    <b-button
                      title="Cek Pembayaran Otomatis"
                      v-if="
                        item.status_bayar != 'lunas' &&
                        item.payment_option == 'pg_midtrans'
                      "
                      size="sm"
                      variant="info"
                      @click="aksiKonfirmasipg(item)">
                      <feather-icon icon="DollarSignIcon" />
                    </b-button>
                    <!-- <b-button title="Konfirmasi Pembayaran" v-if="item.status_bayar != 'lunas' && item.status != 'dibatalkan'" size="sm" variant="primary" @click="aksiKonfirmasi(item)">
                      <feather-icon icon="CheckIcon" />
                    </b-button> -->
                    <b-modal
                      hide-backdrop
                      no-close-on-esc
                      no-close-on-backdrop
                      v-model="showKonfirmasi"
                      id="modal-konfirmasi"
                      ok-variant="secondary"
                      centered
                      :title="'Konfirmasi Pembayaran ' + formkonfirmasi.no_invoice">
                      <b-row>
                        <!-- {{ this.konfirmasi != null ? this.konfirmasi : '-' }} -->
                        <b-col xl="12" lg="12" md="12" sm="12">
                          <!-- <b-card> -->
                          <!-- <validation-observer ref="vkonfirmasi">
                            <b-form>
                                <b-row>
                                  <b-col sm="12" md="12">
                                    <b-form-group label="Jenis Transaksi">
                                      <b-form-select
                                        v-model="transactionType"
                                        :options="[
                                          { value: 1, text: 'Debit' },
                                          { value: 2, text: 'Kredit' },
                                        ]"
                                        id="v-transaction_type"
                                        name="transaction_type"
                                      />
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="12" md="12" v-if="transactionType">
                                    <b-form-group label="Pilih Akun">
                                      <validation-provider #default="{ errors }" rules="required" name="id_akun">
                                        <b-form-select v-model="formkonfirmasi.id_akun" :options="id_akun" id="v-id_akun" name="id_akun" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                  <b-col sm="12" md="12">
                                    <b-form-group label="Pilih Kas">
                                      <validation-provider #default="{ errors }" rules="required" name="id_kas">
                                        <b-form-select v-model="formkonfirmasi.id_kas" :options="id_kas" id="v-id_kas" name="id_kas" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </b-form> -->
                          <!-- <b-button block>Lunas</b-button> -->
                          <!-- </validation-observer> -->
                          <b-button
                            variant="primary"
                            @click.prevent="submitkonfirmasi"
                            block
                            class="mb-2">
                            Konfirmasi Lunas
                          </b-button>
                          <!-- </b-card> -->
                          <!-- </b-col>
                        <b-col xl="6" lg="6" md="12" sm="12"> -->
                          <!-- <b-card border-variant="primary">
                            
                          </b-card> -->
                          <!-- </b-col>
                        <b-col xl="6" lg="6" md="12" sm="12"> -->
                          <!-- <b-card> -->
                          <b-overlay :show="loadingfoto">
                            <h3 align="center">Bukti Transfer</h3>
                            <template v-if="formkonfirmasi.bukti_tf">
                              <b-img
                                :src="apiFile + formkonfirmasi.bukti_tf.file"
                                alt=""
                                fluid-grow />
                            </template>
                            <template v-else>
                              <center>
                                <h3 class="text-danger">Belum diupload</h3>
                              </center>
                            </template>
                          </b-overlay>
                          <!-- </b-card> -->
                        </b-col>
                      </b-row>
                      <template #modal-footer>
                        <section
                          class="d-flex justify-content-end align-items-center">
                          <b-button
                            size="sm"
                            variant="danger"
                            @click="
                              showKonfirmasi = false;
                              getTransaksi();
                            ">
                            Keluar
                          </b-button>
                        </section>
                      </template>
                    </b-modal>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0" />
              </b-col>
            </b-row>
          </b-col>
          <b-col>
        <b-overlay :show="loadingsubmitresep">
          <!-- <b-card> -->
          <b-row>
            <b-col xl="4" lg="4" md="12" sm="12">
              <b-card
                header-bg-variant="warning"
                header-text-variant="white"
                header-tag="header"
                border-variant="warning">
                <template #header>
                  <h3 class="mb-0">
                    <b>{{ $t("Note By Buyer") }}</b>
                  </h3>
                </template>
                <b-row>
                  <b-col md="12" sm="12" class="mt-1">
                    <!-- <h4 align="left">{{ $t('Note By Buyer') }}</h4> -->
                    <b-card-text align="left">
                      <h5>
                        <b>{{ customer ? customer["customerName"] : null }}</b>
                      </h5>
                    </b-card-text>
                    <b-card-text align="left">{{
                      lensa ? lensa["buyerNote"] : null
                    }}</b-card-text>
                    <b-card-text align="left">
                      <h5>
                        <b
                          >{{ customer ? customer.externalOrderId : null }}
                          <b-button
                            @click="copyToClipboard(customer)"
                            :title="$t('Copy Order Number')"
                            size="sm"
                            variant="flat-primary"
                            class="btn-icon rounded-circle">
                            <feather-icon icon="CopyIcon" /> </b-button
                        ></b>
                      </h5>
                      <h5>
                        <b>{{
                          customer
                            ? customer.extraInfo
                              ? customer.extraInfo.noteByBuyer
                              : null
                            : null
                        }}</b>
                      </h5>
                    </b-card-text>
                  </b-col>
                </b-row>
                <!-- </b-card>
						<b-card border-variant="primary" header-border-variant="primary" align="center"> -->
                <b-row class="mt-1">
                  <b-col md="12" sm="12">
                    <h4 align="left">
                      <b>{{ $t("Product Information") }}</b>
                    </h4>
                    <template
                      v-for="lensa in customer ? customer['items'] : null">
                      <b-row no-gutters>
                        <b-col md="3">
                          <b-card-img
                            style="width: 80px"
                            :src="lensa ? lensa.productImageUrl : null"
                            alt="Image"
                            class="rounded-0"></b-card-img>
                        </b-col>
                        <b-col md="9">
                          <b-card-text align="left">
                            <span :title="lensa ? lensa.productName : null">{{
                              truncateString(
                                lensa ? lensa.productName : null,
                                30
                              )
                            }}</span
                            ><br />
                            {{ lensa ? lensa.variationName : null }}<br />
                            {{ lensa ? lensa.masterSku : null }}<br />
                            {{ formatRupiah(lensa ? lensa.actualPrice : null) }}
                            x {{ lensa ? lensa.quantity : null }}
                          </b-card-text>
                          <b-button
                            variant="primary"
                            @click="getAcc(lensa)"
                            v-if="
                              (!lensa.productName.match(/lensa/gi) &&
                                !lensa.productName.match(/frame/gi)) ||
                              lensa.productName.match(/pembersih/gi) ||
                              lensa.productName.match(/rubber/gi)
                            "
                            >Add</b-button
                          >
                        </b-col>
                      </b-row>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
              <b-overlay :show="loadingresep">
                <b-card
                  header-bg-variant="warning"
                  header-text-variant="white"
                  header-tag="header"
                  border-variant="warning">
                  <template #header>
                    <h3 class="mb-0">
                      <b>{{ $t("Data Prescription") }}</b>
                    </h3>
                  </template>
                  <b-row class="mt-1" v-if="dataresep.length > 0">
                    <b-col>
                      <template v-for="(item, index) in dataresep">
                        <b-button
                          variant="warning"
                          block
                          :id="'popover-target-' + item.id"
                          ><feather-icon
                            icon="PrinterIcon"
                            class="mr-1"
                            @click="printResep2(item)" />
                          {{ $t("Print Data Prescription") + (index + 1) }}
                          <b-popover
                            variant="warning"
                            :target="'popover-target-' + item.id"
                            triggers="hover"
                            placement="top">
                            <template #title>Resep</template>
                            <!-- I am popover <b>component</b> content! -->
                            <ul class="list-unstyled">
                              <li v-if="item.items[2]">Frame</li>
                              <ul v-if="item.items[2]">
                                <li>
                                  {{
                                    item.items[2].penyimpanan.nama +
                                    " - " +
                                    item.items[2].penyimpanan.varian +
                                    " x" +
                                    item.items[2].qty
                                  }}
                                </li>
                              </ul>
                              <!-- <ul v-else>
                                <li>{{ item.items[2].penyimpanan.nama + ' - ' + item.items[2].penyimpanan.varian + ' x'
                                  +
                                  item.items[2].qty }}</li>
                              </ul> -->
                              <li>Lensa</li>
                              <ul>
                                <li>
                                  Kiri :
                                  {{ item.prescription.left.sph + " x" + 1 }}
                                </li>
                                <li>
                                  Kanan :
                                  {{ item.prescription.right.sph + " x" + 1 }}
                                </li>
                              </ul>
                              <li>Accessories</li>
                              <ul></ul>
                              <li>Packaging</li>
                              <ul>
                                <template v-for="akses in item.accessories">
                                  <li>
                                    {{ akses.penyimpanan.varian + " x" + 1 }}
                                  </li>
                                </template>
                              </ul>
                            </ul>
                            <!-- <b-col cols="5"> -->
                            <b-button
                              variant="primary"
                              block
                              @click="printResep2(item)"
                              ><feather-icon icon="PrinterIcon" class="mr-1" />
                              {{ $t("Print Data Prescription ") + (index + 1) }}
                            </b-button>
                            <b-button
                              variant="danger"
                              block
                              @click="deleteresep(item)"
                              ><feather-icon icon="TrashIcon" class="mr-1" />
                              {{
                                $t("Delete Data Prescription ") + (index + 1)
                              }}
                            </b-button>
                            <!-- </b-col> -->
                          </b-popover>
                        </b-button>
                      </template>
                    </b-col>
                  </b-row>
                  <!-- <b-row v-else>
                <b-col> -->
                  <div v-else>
                    <b-card-text class="mt-1">
                      <b-alert show variant="danger">
                        <h4 class="alert-heading">
                          {{ $t("Data Prescription not yet created") }}
                        </h4>
                      </b-alert>
                    </b-card-text>
                  </div>
                  <!-- </b-col>
              </b-row> -->
                  <!-- </template> -->
                </b-card>
                <!-- {{ aksesoris }} -->
                <b-modal v-model="showModal" title="Submit Data">
                  <b-row>
                    <b-col md="12">
                      <p>
                        <strong>No Invoice:</strong>
                        {{ wh ? wh.no_invoice : "-" }}
                      </p>
                      <p><strong>Name:</strong> {{ wh ? wh.name : "-" }}</p>
                      <p><strong>Date:</strong> {{ wh ? wh.date : "-" }}</p>

                      <h5>Items:</h5>
                      <ul v-if="wh">
                        <li v-for="(item, index) in wh.items" :key="index">
                          Item ID: {{ item.barang ? item.barang : "-" }} - Qty:
                          {{ item.qty ? item.qty : "-" }}
                        </li>
                      </ul>
                    </b-col>
                    <b-col md="6">
                      <h5>Prescription Right:</h5>
                      <p>
                        Sph:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.sph
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Cyl:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.cyl
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Axis:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.axis
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Add:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.add
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        PD:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.pd
                              : "-"
                            : "-"
                        }}
                      </p>
                    </b-col>
                    <b-col md="6">
                      <h5>Prescription Left:</h5>
                      <p>
                        Sph:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.sph
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Cyl:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.cyl
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Axis:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.axis
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Add:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.add
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        PD:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.pd
                              : "-"
                            : "-"
                        }}
                      </p>
                    </b-col>
                    <b-col md="12">
                      <h5>Accessories:</h5>
                    </b-col>
                    <b-col md="12">
                      <h5>Packaging:</h5>
                      <ul v-if="wh">
                        <li v-for="(acc, index) in wh.accessories" :key="index">
                          {{ acc.nama ? acc.nama : "-" }} ({{
                            acc.type ? acc.type : "-"
                          }}) - Qty: {{ acc.qty ? acc.qty : "-" }}
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <template #modal-footer="{ ok, cancel }">
                    <b-button variant="secondary" @click="cancel()"
                      >Close</b-button
                    >
                    <b-button variant="primary" @click="oklensa()">OK</b-button>
                  </template>
                </b-modal>
              </b-overlay>
              <b-button variant="warning" @click="openchat">
                <feather-icon class="mr-1" icon="MessageCircleIcon" />
                Open Chat
              </b-button>
              <!-- <b-modal size="xl" v-model="modalopenchat" title="Submit Data">
                <b-row>
                  <b-col md="12">
                    <iframe allow="camera; microphone; autoplay; encrypted-media" :src="webUrl" width="100%"
                      height="400px" frameborder="0" allowfullscreen></iframe>
                    <b-embed type="iframe" aspect="16by9" allowfullscreen :src="webUrl"></b-embed>
                  </b-col>
                </b-row>
              </b-modal> -->
            </b-col>
            <b-col xl="8" lg="8" md="12" sm="12">
              <b-card
                header-bg-variant="warning"
                header-text-variant="white"
                header-tag="header"
                border-variant="warning">
                <template #header>
                  <h3 class="mb-0">
                    <b>{{ $t("Eyeglass Prescription") }}</b>
                  </h3>
                </template>
                <b-tabs fill variant="warning">
                  <b-tab active>
                    <template #title>
                      <!-- <b-button block variant="warning"> -->
                      <h2>Frame + Lens + Acc</h2>
                      <!-- </b-button> -->
                    </template>
                    <b-form>
                      <!-- Title -->
                      <!-- <b-row class="mb-1">
                  <b-col cols="12">
                    <h3 class="text-left">{{ $t('Eyeglass Prescription') }}</h3>
                  </b-col>
                </b-row> -->

                      <!-- Name Field -->
                      <b-row class="mb-1 mt-1">
                        <b-col cols="2" class="d-flex align-items-center">
                          <h5><b>Name</b></h5>
                        </b-col>
                        <b-col cols="5">
                          <b-form-input
                            v-model="resep.name"
                            placeholder="Enter name"></b-form-input>
                        </b-col>
                        <b-col cols="1" class="d-flex align-items-center">
                          <h5><b>Date</b></h5>
                        </b-col>
                        <b-col cols="4">
                          <!-- <b-form-input v-model="resep.date" type="date"></b-form-input> -->
                          <b-form-datepicker
                            id="example-datepicker"
                            :value="resep.date"
                            locale="id"
                            v-model="resep.date" />
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Right Eye (OD) Prescription -->

                      <b-row class="mb-1">
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Prescription</b></h5>
                        </b-col>
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Right / OD</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <!-- <b-overlay :show="loadinglensa">
                              <b-col>
                                <v-select placeholder="SPH" v-model="resep.r_sph" :options="localBarangsOptions"
                                  label="text"></v-select>
                                <p class="text-center">SPH</p>
                              </b-col>
                            </b-overlay> -->
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    v-model="lensasearch"
                                    placeholder="Search lensa"
                                    @keydown.enter.prevent="
                                      getDataBarang()
                                    "></b-form-input>
                                </b-col>
                              </b-row>
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.r_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.r_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <!-- <hr> -->

                      <!-- Left Eye (OS) Prescription -->
                      <b-row class="mb-1">
                        <!-- <b-col cols="12" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Left / OS</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input v-model="lensasearchleft" placeholder="Search lensa"
                                    @keydown.enter.prevent="getDataBarangLeft()"></b-form-input>
                                </b-col>
                              </b-row>
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.l_sph"
                                      :options="localBarangsOptionsleft"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.l_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr />
                      <!-- Lens fields -->
                      <b-row>
                        <b-col md="12" style="text-align: left">
                          <!-- <p> -->
                          <h5><b>Lens</b></h5>
                          <!-- </p> -->
                        </b-col>
                        <b-col md="2" style="text-align: left">
                          <h5>Class</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <!-- <b-form-select v-model="resep.lensClass" :options="classOptions"
                        placeholder="Select Class"></b-form-select> -->
                            <v-select
                              placeholder="Choose class"
                              v-model="resep.lensClass"
                              label="text"
                              :options="classOptions"
                              @input="getDataKategorifix()"></v-select>
                            <b-button
                              v-if="classOptions.length == 0"
                              @click="getDataKelas()"
                              >Refresh</b-button
                            >
                          </b-form-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left">
                          <h5>Material</h5>
                        </b-col>
                        <b-col>
                          <b-overlay :show="loadingkategorilensa">
                            <b-form-group>
                              <v-select
                                placeholder="Choose lensa category"
                                v-model="resep.lensa"
                                label="text"
                                :options="categorylensaOptions"></v-select>
                            </b-form-group>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Frame fields -->
                      <b-row>
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Frame</b></h5>
                        </b-col>
                        <b-col cols="2" style="text-align: left">
                          <h5>Type</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <v-select
                              placeholder="Choose frame category"
                              v-model="resep.framecategory"
                              label="text"
                              :options="categoryframeOptions"></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="2" class="">
                          <h5>Varian / Color</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <v-select
                              placeholder="Choose frame"
                              v-model="resep.frame"
                              label="text"
                              :options="penyimpananOptions"></v-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Accessories (Additional)</b></h5>
                        </b-col>
                        <template v-for="(hem, i) in accadd">
                          <b-col cols="2" style="text-align: left">
                            <!-- <h5></h5> -->
                          </b-col>
                          <b-col cols="6">
                            {{ hem.barang.nama }} - {{ hem.barang.varian }} x
                            {{ hem.quantity }}
                          </b-col>
                          <b-col cols="2"> Stok :{{ hem.stok }} </b-col>
                          <b-col cols="2" style="text-align: left">
                            <!-- <template> -->
                            <b-button
                              @click.prevent="removeadditional(i)"
                              variant="flat-danger"
                              size="sm">
                              <feather-icon icon="XIcon" />
                            </b-button>
                            <!-- </template> -->
                          </b-col>
                        </template>
                      </b-row>
                      <hr />
                      <!-- Accessories fields -->
                      <!-- <b-row>
                        <b-col cols="2" style="text-align: left">
                          <h5><b>Packaging</b></h5>
                        </b-col>
                        <b-col cols="3" style="text-align: left">
                          <b-form-radio-group v-model="aksesorisadd.case" name="accessory">
                            <b-form-radio value="plastik">Softcase (Plastik)</b-form-radio>
                            <b-form-radio value="metal">Hardcase (Metal)</b-form-radio>
                            <b-form-radio value="">Tanpa Case</b-form-radio>
                          </b-form-radio-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left"> </b-col>
                        <b-col style="text-align: left">
                          <b-form-checkbox v-model="aksesorisadd.lap" value="lap">Lap</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.lensCleaner" value="cleaner">Lens
                            Cleaner</b-form-checkbox>
                        </b-col>
                        <b-col style="text-align: left">
                          <b-form-checkbox v-model="aksesorisadd.kartuResep" value="resep">Kartu Resep</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuGaransi" value="garansi">Kartu
                            Garansi</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuVoucher" value="voucher">Kartu
                            Voucher</b-form-checkbox>
                        </b-col>
                      </b-row> -->
                      <hr />
                      <b-row>
                        <b-col cols="6" style="text-align: left"> </b-col>
                        <!-- <b-col cols="3" style="text-align: left">
                          <h5><b>Frame</b></h5>
                          <b-button
                            variant="outline-warning"
                            block
                            @click="modalLensa = false"
                            >Cancel</b-button
                          >
                        </b-col> -->
                        <b-col cols="3" style="text-align: left">
                          <!-- <h5><b>Frame</b></h5> -->
                          <b-button variant="warning" block @click="submitresep"
                            >Save</b-button
                          >
                        </b-col>
                      </b-row>

                      <!-- Accessories and Date -->
                      <!-- <b-row class="mb-1">
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Lens</label>
                  </b-col>
                  <b-col cols="4">
                    <v-select placeholder="Enter lensa type" v-model="resep.category" label="text"
                      :options="categoryOptions" @input="getDataBarang"></v-select>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Accs. <span class="text-info">(optional)</span></label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input placeholder="Accessories"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Frame</label>
                  </b-col>
                  <b-col cols="4">
                    <v-select placeholder="Enter frame type" v-model="resep.frame" label="text"
                      :options="penyimpananOptions"></v-select>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Date <span class="text-info">(optional)</span></label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input v-model="resep.date" type="date"></b-form-input>
                  </b-col>
                </b-row> -->

                      <!-- <b-row>
                  <b-col md="3" class="d-flex align-items-center">
                    <b-form-checkbox></b-form-checkbox>
                    <label>Acc</label>
                  </b-col>
                  <b-col md="4" class="d-flex align-items-center">
                    <label>Product</label>
                  </b-col>
                  <b-col md="4" class="d-flex align-items-center">
                    <label>Qty</label>
                  </b-col>
                  <b-col md="1" class="d-flex align-items-center">
                  </b-col>
                </b-row> -->
                    </b-form>
                    <!-- </b-card> -->
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <!-- <b-button block variant="warning"> -->
                      <h2>Lens Only</h2>
                      <!-- </b-button> -->
                    </template>
                    <!-- <b-card header-bg-variant="warning" header-text-variant="white" header-tag="header"
                    border-variant="warning">
                    <template #header>
                      <h3 class="mb-0"><b>{{ $t('Eyeglass Prescription') }}</b></h3>
                    </template> -->
                    <b-form>
                      <!-- Name Field -->
                      <b-row class="mb-1 mt-1">
                        <b-col cols="2" class="d-flex align-items-center">
                          <h5><b>Name</b></h5>
                        </b-col>
                        <b-col cols="5">
                          <b-form-input
                            v-model="resep.name"
                            placeholder="Enter name"></b-form-input>
                        </b-col>
                        <b-col cols="1" class="d-flex align-items-center">
                          <h5><b>Date</b></h5>
                        </b-col>
                        <b-col cols="4">
                          <!-- <b-form-input v-model="resep.date" type="date"></b-form-input> -->
                          <b-form-datepicker
                            id="example-datepicker"
                            :value="resep.date"
                            locale="id"
                            v-model="resep.date" />
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Right Eye (OD) Prescription -->

                      <b-row class="mb-1">
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Prescription</b></h5>
                        </b-col>
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Right / OD</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    v-model="lensasearch"
                                    placeholder="Search lensa"
                                    @keydown.enter.prevent="
                                      getDataBarang()
                                    "></b-form-input>
                                </b-col>
                              </b-row>
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.r_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <!-- <b-overlay :show="loadinglensa">
                              <b-col>
                                <v-select placeholder="SPH" v-model="resep.r_sph" :options="localBarangsOptions"
                                  label="text"></v-select>
                                <p class="text-center">SPH</p>
                              </b-col>
                            </b-overlay> -->
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.r_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <!-- <hr> -->

                      <!-- Left Eye (OS) Prescription -->
                      <b-row class="mb-1">
                        <!-- <b-col cols="12" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Left / OS</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <b-col>
                              <!-- <b-row>
                                <b-col>
                                  <b-form-input v-model="lensasearch" placeholder="Search lensa"
                                    @keydown.enter.prevent="getDataBarang()"></b-form-input>
                                </b-col>
                              </b-row> -->
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.l_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.l_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr />
                      <!-- Lens fields -->
                      <b-row>
                        <b-col md="12" style="text-align: left">
                          <!-- <p> -->
                          <h5><b>Lens</b></h5>
                          <!-- </p> -->
                        </b-col>
                        <b-col md="2" style="text-align: left">
                          <h5>Class</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <!-- <b-form-select v-model="resep.lensClass" :options="classOptions"
                        placeholder="Select Class"></b-form-select> -->
                            <v-select
                              placeholder="Choose class"
                              v-model="resep.lensClass"
                              label="text"
                              :options="classOptions"
                              @input="getDataKategorifix()"></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left">
                          <h5>Material</h5>
                        </b-col>
                        <b-col
                          ><b-overlay :show="loadingkategorilensa">
                            <b-form-group>
                              <v-select
                                placeholder="Choose lensa category"
                                v-model="resep.lensa"
                                label="text"
                                :options="categorylensaOptions"></v-select>
                            </b-form-group>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Frame fields -->
                      <!-- <b-row>
                    <b-col cols="12" style="text-align: left;">
                      <h5><b>Frame</b></h5>
                    </b-col>
                    <b-col cols="2" style="text-align: left;">
                      <h5>Type</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame category" v-model="resep.framecategory" label="text"
                          :options="categoryframeOptions"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2" class="">
                      <h5>Varian / Color</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame" v-model="resep.frame" label="text"
                          :options="penyimpananOptions"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr> -->
                      <b-row>
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Accessories (Additional)</b></h5>
                        </b-col>
                        <template v-for="(hem, i) in accadd">
                          <b-col cols="2" style="text-align: left">
                            <!-- <h5></h5> -->
                          </b-col>
                          <b-col cols="8">
                            {{ hem.barang.nama }} - {{ hem.barang.varian }} x
                            {{ hem.quantity }}
                          </b-col>
                          <b-col cols="2" style="text-align: left">
                            <!-- <template> -->
                            <b-button
                              @click.prevent="removeadditional(i)"
                              variant="flat-danger"
                              size="sm">
                              <feather-icon icon="XIcon" />
                            </b-button>
                            <!-- </template> -->
                          </b-col>
                        </template>
                      </b-row>
                      <hr />
                      <!-- Accessories fields -->
                      <!-- <b-row>
                        <b-col cols="2" style="text-align: left">
                          <h5><b>Packaging</b></h5>
                        </b-col>
                        <b-col cols="3" style="text-align: left"> -->
                      <!-- <b-form-group label=""> -->
                      <!-- <b-form-radio-group v-model="aksesorisadd.case" name="accessory">
                            <b-form-radio value="plastik">Softcase (Plastik)</b-form-radio>
                            <b-form-radio value="metal">Hardcase (Metal)</b-form-radio>
                            <b-form-radio value="">Tanpa Case</b-form-radio>
                          </b-form-radio-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left"> </b-col>
                        <b-col style="text-align: left"> -->
                      <!-- <b-form-checkbox-group id="checkbox-group-2" v-model="selected" stacked name="flavour-2"> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.lap" value="lap">Lap</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.lensCleaner" value="cleaner">Lens
                            Cleaner</b-form-checkbox> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.kartuResep">Kartu Resep</b-form-checkbox>
                      <b-form-checkbox v-model="aksesorisadd.kartuGaransi">Kartu Garansi</b-form-checkbox> -->
                      <!-- </b-form-checkbox-group> -->
                      <!-- </b-form-group> -->
                      <!-- </b-col> -->
                      <!-- <b-col style="text-align: left"> -->
                      <!-- <b-form-checkbox-group id="checkbox-group-2" v-model="selected" stacked name="flavour-2"> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.lap">Lap</b-form-checkbox>
                      <b-form-checkbox v-model="aksesorisadd.lensCleaner">Lens Cleaner</b-form-checkbox> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.kartuResep" value="resep">Kartu Resep</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuGaransi" value="garansi">Kartu
                            Garansi</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuVoucher" value="voucher">Kartu
                            Voucher</b-form-checkbox> -->
                      <!-- </b-form-checkbox-group> -->
                      <!-- </b-form-group> -->
                      <!-- </b-col> 
                      </b-row>
                      <hr /> -->
                      <b-row>
                        <b-col cols="6" style="text-align: left"> </b-col>
                        <!-- <b-col cols="3" style="text-align: left">
                          <b-button
                            variant="outline-warning"
                            block
                            @click="this.modalLensa = false"
                            >Cancel</b-button
                          >
                        </b-col> -->
                        <b-col cols="3" style="text-align: left">
                          <!-- <h5><b>Frame</b></h5> -->
                          <b-button
                            variant="warning"
                            block
                            @click="submitreseplensaonly"
                            >Save</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-tab>
                </b-tabs>
              </b-card>
              <!-- <h4>{{ $t('Eyeglass Prescription') }}</h4> -->
            </b-col>
          </b-row>
        </b-overlay>
          </b-col>
        </b-row>
      </b-card>
      <!-- <b-card >
        <h5>Floating Card</h5>
        <p>{{selectedginee}}</p>
      </b-card> -->
      <b-card
        no-body
        v-if="selectedginee"
        :style="cardStyle"
        class="draggable-card"
        @mousedown="startDrag">
        <b-row>
          <b-col>
            Nomor Orders : {{ selectedginee.externalOrderId }}
          </b-col>
          <b-col>
            <b-button
              class="mr-1"
              variant="primary"
              @click="previousGinee()"
              block
              >
              {{ $t("Previous") }}
              </b-button>
          </b-col>
          <b-col>
            <b-button variant="primary" @click="nextGinee()" block>
              {{$t('Next')}}
            </b-button>
          </b-col>
        </b-row>
      </b-card>


      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        :title="$t('Choose Lensa')"
        centered
        size="xl"
        hide-footer
        v-model="modalLensa">
        <b-overlay :show="loadingsubmitresep">
          <!-- <b-card> -->
          <b-row>
            <b-col xl="4" lg="4" md="12" sm="12">
              <b-card
                header-bg-variant="warning"
                header-text-variant="white"
                header-tag="header"
                border-variant="warning">
                <template #header>
                  <h3 class="mb-0">
                    <b>{{ $t("Note By Buyer") }}</b>
                  </h3>
                </template>
                <b-row>
                  <b-col md="12" sm="12" class="mt-1">
                    <!-- <h4 align="left">{{ $t('Note By Buyer') }}</h4> -->
                    <b-card-text align="left">
                      <h5>
                        <b>{{ customer ? customer["customerName"] : null }}</b>
                      </h5>
                    </b-card-text>
                    <b-card-text align="left">{{
                      lensa ? lensa["buyerNote"] : null
                    }}</b-card-text>
                    <b-card-text align="left">
                      <h5>
                        <b
                          >{{ customer ? customer.externalOrderId : null }}
                          <b-button
                            @click="copyToClipboard(customer)"
                            :title="$t('Copy Order Number')"
                            size="sm"
                            variant="flat-primary"
                            class="btn-icon rounded-circle">
                            <feather-icon icon="CopyIcon" /> </b-button
                        ></b>
                      </h5>
                      <h5>
                        <b>{{
                          customer
                            ? customer.extraInfo
                              ? customer.extraInfo.noteByBuyer
                              : null
                            : null
                        }}</b>
                      </h5>
                    </b-card-text>
                  </b-col>
                </b-row>
                <!-- </b-card>
						<b-card border-variant="primary" header-border-variant="primary" align="center"> -->
                <b-row class="mt-1">
                  <b-col md="12" sm="12">
                    <h4 align="left">
                      <b>{{ $t("Product Information") }}</b>
                    </h4>
                    <template
                      v-for="lensa in customer ? customer['items'] : null">
                      <b-row no-gutters>
                        <b-col md="3">
                          <b-card-img
                            style="width: 80px"
                            :src="lensa ? lensa.productImageUrl : null"
                            alt="Image"
                            class="rounded-0"></b-card-img>
                        </b-col>
                        <b-col md="9">
                          <b-card-text align="left">
                            <span :title="lensa ? lensa.productName : null">{{
                              truncateString(
                                lensa ? lensa.productName : null,
                                30
                              )
                            }}</span
                            ><br />
                            {{ lensa ? lensa.variationName : null }}<br />
                            {{ lensa ? lensa.masterSku : null }}<br />
                            {{ formatRupiah(lensa ? lensa.actualPrice : null) }}
                            x {{ lensa ? lensa.quantity : null }}
                          </b-card-text>
                          <b-button
                            variant="primary"
                            @click="getAcc(lensa)"
                            v-if="
                              (!lensa.productName.match(/lensa/gi) &&
                                !lensa.productName.match(/frame/gi)) ||
                              lensa.productName.match(/pembersih/gi) ||
                              lensa.productName.match(/rubber/gi)
                            "
                            >Add</b-button
                          >
                        </b-col>
                      </b-row>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
              <b-overlay :show="loadingresep">
                <b-card
                  header-bg-variant="warning"
                  header-text-variant="white"
                  header-tag="header"
                  border-variant="warning">
                  <template #header>
                    <h3 class="mb-0">
                      <b>{{ $t("Data Prescription") }}</b>
                    </h3>
                  </template>
                  <b-row class="mt-1" v-if="dataresep.length > 0">
                    <b-col>
                      <template v-for="(item, index) in dataresep">
                        <b-button
                          variant="warning"
                          block
                          :id="'popover-target-' + item.id"
                          ><feather-icon
                            icon="PrinterIcon"
                            class="mr-1"
                            @click="printResep2(item)" />
                          {{ $t("Print Data Prescription") + (index + 1) }}
                          <b-popover
                            variant="warning"
                            :target="'popover-target-' + item.id"
                            triggers="hover"
                            placement="top">
                            <template #title>Resep</template>
                            <!-- I am popover <b>component</b> content! -->
                            <ul class="list-unstyled">
                              <li v-if="item.items[2]">Frame</li>
                              <ul v-if="item.items[2]">
                                <li>
                                  {{
                                    item.items[2].penyimpanan.nama +
                                    " - " +
                                    item.items[2].penyimpanan.varian +
                                    " x" +
                                    item.items[2].qty
                                  }}
                                </li>
                              </ul>
                              <!-- <ul v-else>
                                <li>{{ item.items[2].penyimpanan.nama + ' - ' + item.items[2].penyimpanan.varian + ' x'
                                  +
                                  item.items[2].qty }}</li>
                              </ul> -->
                              <li>Lensa</li>
                              <ul>
                                <li>
                                  Kiri :
                                  {{ item.prescription.left.sph + " x" + 1 }}
                                </li>
                                <li>
                                  Kanan :
                                  {{ item.prescription.right.sph + " x" + 1 }}
                                </li>
                              </ul>
                              <li>Accessories</li>
                              <ul></ul>
                              <li>Packaging</li>
                              <ul>
                                <template v-for="akses in item.accessories">
                                  <li>
                                    {{ akses.penyimpanan.varian + " x" + 1 }}
                                  </li>
                                </template>
                              </ul>
                            </ul>
                            <!-- <b-col cols="5"> -->
                            <b-button
                              variant="primary"
                              block
                              @click="printResep2(item)"
                              ><feather-icon icon="PrinterIcon" class="mr-1" />
                              {{ $t("Print Data Prescription ") + (index + 1) }}
                            </b-button>
                            <b-button
                              variant="danger"
                              block
                              @click="deleteresep(item)"
                              ><feather-icon icon="TrashIcon" class="mr-1" />
                              {{
                                $t("Delete Data Prescription ") + (index + 1)
                              }}
                            </b-button>
                            <!-- </b-col> -->
                          </b-popover>
                        </b-button>
                      </template>
                    </b-col>
                  </b-row>
                  <!-- <b-row v-else>
                <b-col> -->
                  <div v-else>
                    <b-card-text class="mt-1">
                      <b-alert show variant="danger">
                        <h4 class="alert-heading">
                          {{ $t("Data Prescription not yet created") }}
                        </h4>
                      </b-alert>
                    </b-card-text>
                  </div>
                  <!-- </b-col>
              </b-row> -->
                  <!-- </template> -->
                </b-card>
                <!-- {{ aksesoris }} -->
                <b-modal v-model="showModal" title="Submit Data">
                  <b-row>
                    <b-col md="12">
                      <p>
                        <strong>No Invoice:</strong>
                        {{ wh ? wh.no_invoice : "-" }}
                      </p>
                      <p><strong>Name:</strong> {{ wh ? wh.name : "-" }}</p>
                      <p><strong>Date:</strong> {{ wh ? wh.date : "-" }}</p>

                      <h5>Items:</h5>
                      <ul v-if="wh">
                        <li v-for="(item, index) in wh.items" :key="index">
                          Item ID: {{ item.barang ? item.barang : "-" }} - Qty:
                          {{ item.qty ? item.qty : "-" }}
                        </li>
                      </ul>
                    </b-col>
                    <b-col md="6">
                      <h5>Prescription Right:</h5>
                      <p>
                        Sph:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.sph
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Cyl:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.cyl
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Axis:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.axis
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Add:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.add
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        PD:
                        {{
                          wh
                            ? wh.prescription_right
                              ? wh.prescription_right.pd
                              : "-"
                            : "-"
                        }}
                      </p>
                    </b-col>
                    <b-col md="6">
                      <h5>Prescription Left:</h5>
                      <p>
                        Sph:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.sph
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Cyl:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.cyl
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Axis:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.axis
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        Add:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.add
                              : "-"
                            : "-"
                        }}
                      </p>
                      <p>
                        PD:
                        {{
                          wh
                            ? wh.prescription_left
                              ? wh.prescription_left.pd
                              : "-"
                            : "-"
                        }}
                      </p>
                    </b-col>
                    <b-col md="12">
                      <h5>Accessories:</h5>
                    </b-col>
                    <b-col md="12">
                      <h5>Packaging:</h5>
                      <ul v-if="wh">
                        <li v-for="(acc, index) in wh.accessories" :key="index">
                          {{ acc.nama ? acc.nama : "-" }} ({{
                            acc.type ? acc.type : "-"
                          }}) - Qty: {{ acc.qty ? acc.qty : "-" }}
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <template #modal-footer="{ ok, cancel }">
                    <b-button variant="secondary" @click="cancel()"
                      >Close</b-button
                    >
                    <b-button variant="primary" @click="oklensa()">OK</b-button>
                  </template>
                </b-modal>
              </b-overlay>
              <b-button variant="warning" @click="openchat">
                <feather-icon class="mr-1" icon="MessageCircleIcon" />
                Open Chat
              </b-button>
              <!-- <b-modal size="xl" v-model="modalopenchat" title="Submit Data">
                <b-row>
                  <b-col md="12">
                    <iframe allow="camera; microphone; autoplay; encrypted-media" :src="webUrl" width="100%"
                      height="400px" frameborder="0" allowfullscreen></iframe>
                    <b-embed type="iframe" aspect="16by9" allowfullscreen :src="webUrl"></b-embed>
                  </b-col>
                </b-row>
              </b-modal> -->
            </b-col>
            <b-col xl="8" lg="8" md="12" sm="12">
              <b-card
                header-bg-variant="warning"
                header-text-variant="white"
                header-tag="header"
                border-variant="warning">
                <template #header>
                  <h3 class="mb-0">
                    <b>{{ $t("Eyeglass Prescription") }}</b>
                  </h3>
                </template>
                <b-tabs fill variant="warning">
                  <b-tab active>
                    <template #title>
                      <!-- <b-button block variant="warning"> -->
                      <h2>Frame + Lens + Acc</h2>
                      <!-- </b-button> -->
                    </template>
                    <b-form>
                      <!-- Title -->
                      <!-- <b-row class="mb-1">
                  <b-col cols="12">
                    <h3 class="text-left">{{ $t('Eyeglass Prescription') }}</h3>
                  </b-col>
                </b-row> -->

                      <!-- Name Field -->
                      <b-row class="mb-1 mt-1">
                        <b-col cols="2" class="d-flex align-items-center">
                          <h5><b>Name</b></h5>
                        </b-col>
                        <b-col cols="5">
                          <b-form-input
                            v-model="resep.name"
                            placeholder="Enter name"></b-form-input>
                        </b-col>
                        <b-col cols="1" class="d-flex align-items-center">
                          <h5><b>Date</b></h5>
                        </b-col>
                        <b-col cols="4">
                          <!-- <b-form-input v-model="resep.date" type="date"></b-form-input> -->
                          <b-form-datepicker
                            id="example-datepicker"
                            :value="resep.date"
                            locale="id"
                            v-model="resep.date" />
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Right Eye (OD) Prescription -->

                      <b-row class="mb-1">
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Prescription</b></h5>
                        </b-col>
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Right / OD</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <!-- <b-overlay :show="loadinglensa">
                              <b-col>
                                <v-select placeholder="SPH" v-model="resep.r_sph" :options="localBarangsOptions"
                                  label="text"></v-select>
                                <p class="text-center">SPH</p>
                              </b-col>
                            </b-overlay> -->
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    v-model="lensasearch"
                                    placeholder="Search lensa"
                                    @keydown.enter.prevent="
                                      getDataBarang()
                                    "></b-form-input>
                                </b-col>
                              </b-row>
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.r_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.r_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <!-- <hr> -->

                      <!-- Left Eye (OS) Prescription -->
                      <b-row class="mb-1">
                        <!-- <b-col cols="12" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Left / OS</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <b-col>
                              <!-- <b-row>
                                <b-col>
                                  <b-form-input v-model="lensasearch" placeholder="Search lensa"
                                    @keydown.enter.prevent="getDataBarang()"></b-form-input>
                                </b-col>
                              </b-row> -->
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.l_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.l_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr />
                      <!-- Lens fields -->
                      <b-row>
                        <b-col md="12" style="text-align: left">
                          <!-- <p> -->
                          <h5><b>Lens</b></h5>
                          <!-- </p> -->
                        </b-col>
                        <b-col md="2" style="text-align: left">
                          <h5>Class</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <!-- <b-form-select v-model="resep.lensClass" :options="classOptions"
                        placeholder="Select Class"></b-form-select> -->
                            <v-select
                              placeholder="Choose class"
                              v-model="resep.lensClass"
                              label="text"
                              :options="classOptions"
                              @input="getDataKategorifix()"></v-select>
                            <b-button
                              v-if="classOptions.length == 0"
                              @click="getDataKelas()"
                              >Refresh</b-button
                            >
                          </b-form-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left">
                          <h5>Material</h5>
                        </b-col>
                        <b-col>
                          <b-overlay :show="loadingkategorilensa">
                            <b-form-group>
                              <v-select
                                placeholder="Choose lensa category"
                                v-model="resep.lensa"
                                label="text"
                                :options="categorylensaOptions"></v-select>
                            </b-form-group>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Frame fields -->
                      <b-row>
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Frame</b></h5>
                        </b-col>
                        <b-col cols="2" style="text-align: left">
                          <h5>Type</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <v-select
                              placeholder="Choose frame category"
                              v-model="resep.framecategory"
                              label="text"
                              :options="categoryframeOptions"></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="2" class="">
                          <h5>Varian / Color</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <v-select
                              placeholder="Choose frame"
                              v-model="resep.frame"
                              label="text"
                              :options="penyimpananOptions"></v-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Accessories (Additional)</b></h5>
                        </b-col>
                        <template v-for="(hem, i) in accadd">
                          <b-col cols="2" style="text-align: left">
                            <!-- <h5></h5> -->
                          </b-col>
                          <b-col cols="6">
                            {{ hem.barang.nama }} - {{ hem.barang.varian }} x
                            {{ hem.quantity }}
                          </b-col>
                          <b-col cols="2"> Stok :{{ hem.stok }} </b-col>
                          <b-col cols="2" style="text-align: left">
                            <!-- <template> -->
                            <b-button
                              @click.prevent="removeadditional(i)"
                              variant="flat-danger"
                              size="sm">
                              <feather-icon icon="XIcon" />
                            </b-button>
                            <!-- </template> -->
                          </b-col>
                        </template>
                      </b-row>
                      <hr />
                      <!-- Accessories fields -->
                      <!-- <b-row>
                        <b-col cols="2" style="text-align: left">
                          <h5><b>Packaging</b></h5>
                        </b-col>
                        <b-col cols="3" style="text-align: left">
                          <b-form-radio-group v-model="aksesorisadd.case" name="accessory">
                            <b-form-radio value="plastik">Softcase (Plastik)</b-form-radio>
                            <b-form-radio value="metal">Hardcase (Metal)</b-form-radio>
                            <b-form-radio value="">Tanpa Case</b-form-radio>
                          </b-form-radio-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left"> </b-col>
                        <b-col style="text-align: left">
                          <b-form-checkbox v-model="aksesorisadd.lap" value="lap">Lap</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.lensCleaner" value="cleaner">Lens
                            Cleaner</b-form-checkbox>
                        </b-col>
                        <b-col style="text-align: left">
                          <b-form-checkbox v-model="aksesorisadd.kartuResep" value="resep">Kartu Resep</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuGaransi" value="garansi">Kartu
                            Garansi</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuVoucher" value="voucher">Kartu
                            Voucher</b-form-checkbox>
                        </b-col>
                      </b-row> -->
                      <hr />
                      <b-row>
                        <b-col cols="6" style="text-align: left"> </b-col>
                        <!-- <b-col cols="3" style="text-align: left">
                          <h5><b>Frame</b></h5>
                          <b-button
                            variant="outline-warning"
                            block
                            @click="modalLensa = false"
                            >Cancel</b-button
                          >
                        </b-col> -->
                        <b-col cols="3" style="text-align: left">
                          <!-- <h5><b>Frame</b></h5> -->
                          <b-button variant="warning" block @click="submitresep"
                            >Save</b-button
                          >
                        </b-col>
                      </b-row>

                      <!-- Accessories and Date -->
                      <!-- <b-row class="mb-1">
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Lens</label>
                  </b-col>
                  <b-col cols="4">
                    <v-select placeholder="Enter lensa type" v-model="resep.category" label="text"
                      :options="categoryOptions" @input="getDataBarang"></v-select>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Accs. <span class="text-info">(optional)</span></label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input placeholder="Accessories"></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Frame</label>
                  </b-col>
                  <b-col cols="4">
                    <v-select placeholder="Enter frame type" v-model="resep.frame" label="text"
                      :options="penyimpananOptions"></v-select>
                  </b-col>
                  <b-col cols="2" class="d-flex align-items-center">
                    <label>Date <span class="text-info">(optional)</span></label>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input v-model="resep.date" type="date"></b-form-input>
                  </b-col>
                </b-row> -->

                      <!-- <b-row>
                  <b-col md="3" class="d-flex align-items-center">
                    <b-form-checkbox></b-form-checkbox>
                    <label>Acc</label>
                  </b-col>
                  <b-col md="4" class="d-flex align-items-center">
                    <label>Product</label>
                  </b-col>
                  <b-col md="4" class="d-flex align-items-center">
                    <label>Qty</label>
                  </b-col>
                  <b-col md="1" class="d-flex align-items-center">
                  </b-col>
                </b-row> -->
                    </b-form>
                    <!-- </b-card> -->
                  </b-tab>
                  <b-tab>
                    <template #title>
                      <!-- <b-button block variant="warning"> -->
                      <h2>Lens Only</h2>
                      <!-- </b-button> -->
                    </template>
                    <!-- <b-card header-bg-variant="warning" header-text-variant="white" header-tag="header"
                    border-variant="warning">
                    <template #header>
                      <h3 class="mb-0"><b>{{ $t('Eyeglass Prescription') }}</b></h3>
                    </template> -->
                    <b-form>
                      <!-- Name Field -->
                      <b-row class="mb-1 mt-1">
                        <b-col cols="2" class="d-flex align-items-center">
                          <h5><b>Name</b></h5>
                        </b-col>
                        <b-col cols="5">
                          <b-form-input
                            v-model="resep.name"
                            placeholder="Enter name"></b-form-input>
                        </b-col>
                        <b-col cols="1" class="d-flex align-items-center">
                          <h5><b>Date</b></h5>
                        </b-col>
                        <b-col cols="4">
                          <!-- <b-form-input v-model="resep.date" type="date"></b-form-input> -->
                          <b-form-datepicker
                            id="example-datepicker"
                            :value="resep.date"
                            locale="id"
                            v-model="resep.date" />
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Right Eye (OD) Prescription -->

                      <b-row class="mb-1">
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Prescription</b></h5>
                        </b-col>
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Right / OD</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <b-col>
                              <b-row>
                                <b-col>
                                  <b-form-input
                                    v-model="lensasearch"
                                    placeholder="Search lensa"
                                    @keydown.enter.prevent="
                                      getDataBarang()
                                    "></b-form-input>
                                </b-col>
                              </b-row>
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.r_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <!-- <b-overlay :show="loadinglensa">
                              <b-col>
                                <v-select placeholder="SPH" v-model="resep.r_sph" :options="localBarangsOptions"
                                  label="text"></v-select>
                                <p class="text-center">SPH</p>
                              </b-col>
                            </b-overlay> -->
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.r_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.r_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <!-- <hr> -->

                      <!-- Left Eye (OS) Prescription -->
                      <b-row class="mb-1">
                        <!-- <b-col cols="12" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                        <b-col cols="2" style="text-align: left">
                          <h5>Left / OS</h5>
                        </b-col>
                        <b-col cols="10">
                          <b-row>
                            <b-col>
                              <!-- <b-row>
                                <b-col>
                                  <b-form-input v-model="lensasearch" placeholder="Search lensa"
                                    @keydown.enter.prevent="getDataBarang()"></b-form-input>
                                </b-col>
                              </b-row> -->
                              <b-overlay :show="loadinglensa">
                                <b-row>
                                  <b-col>
                                    <v-select
                                      placeholder="SPH"
                                      v-model="resep.l_sph"
                                      :options="localBarangsOptions"
                                      label="text"></v-select>
                                  </b-col>
                                </b-row>
                              </b-overlay>
                              <b-row>
                                <b-col>
                                  <p class="text-center">SPH</p>
                                  <b-progress
                                    :value="progressValue"
                                    max="100"
                                    show-progress
                                    animated></b-progress>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col>
                              <b-form-input
                                disabled
                                v-model="resep.l_cyl"
                                placeholder="CYL"></b-form-input>
                              <p class="text-center">CYL</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_axis"
                                placeholder="AXIS"></b-form-input>
                              <p class="text-center">AXIS</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_add"
                                placeholder="ADD"></b-form-input>
                              <p class="text-center">ADD</p>
                            </b-col>
                            <b-col>
                              <b-form-input
                                v-model="resep.l_pd"
                                placeholder="PD"></b-form-input>
                              <p class="text-center">PD</p>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr />
                      <!-- Lens fields -->
                      <b-row>
                        <b-col md="12" style="text-align: left">
                          <!-- <p> -->
                          <h5><b>Lens</b></h5>
                          <!-- </p> -->
                        </b-col>
                        <b-col md="2" style="text-align: left">
                          <h5>Class</h5>
                        </b-col>
                        <b-col>
                          <b-form-group>
                            <!-- <b-form-select v-model="resep.lensClass" :options="classOptions"
                        placeholder="Select Class"></b-form-select> -->
                            <v-select
                              placeholder="Choose class"
                              v-model="resep.lensClass"
                              label="text"
                              :options="classOptions"
                              @input="getDataKategorifix()"></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left">
                          <h5>Material</h5>
                        </b-col>
                        <b-col
                          ><b-overlay :show="loadingkategorilensa">
                            <b-form-group>
                              <v-select
                                placeholder="Choose lensa category"
                                v-model="resep.lensa"
                                label="text"
                                :options="categorylensaOptions"></v-select>
                            </b-form-group>
                          </b-overlay>
                        </b-col>
                      </b-row>
                      <hr />

                      <!-- Frame fields -->
                      <!-- <b-row>
                    <b-col cols="12" style="text-align: left;">
                      <h5><b>Frame</b></h5>
                    </b-col>
                    <b-col cols="2" style="text-align: left;">
                      <h5>Type</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame category" v-model="resep.framecategory" label="text"
                          :options="categoryframeOptions"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2" class="">
                      <h5>Varian / Color</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame" v-model="resep.frame" label="text"
                          :options="penyimpananOptions"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr> -->
                      <b-row>
                        <b-col cols="12" style="text-align: left">
                          <h5><b>Accessories (Additional)</b></h5>
                        </b-col>
                        <template v-for="(hem, i) in accadd">
                          <b-col cols="2" style="text-align: left">
                            <!-- <h5></h5> -->
                          </b-col>
                          <b-col cols="8">
                            {{ hem.barang.nama }} - {{ hem.barang.varian }} x
                            {{ hem.quantity }}
                          </b-col>
                          <b-col cols="2" style="text-align: left">
                            <!-- <template> -->
                            <b-button
                              @click.prevent="removeadditional(i)"
                              variant="flat-danger"
                              size="sm">
                              <feather-icon icon="XIcon" />
                            </b-button>
                            <!-- </template> -->
                          </b-col>
                        </template>
                      </b-row>
                      <hr />
                      <!-- Accessories fields -->
                      <!-- <b-row>
                        <b-col cols="2" style="text-align: left">
                          <h5><b>Packaging</b></h5>
                        </b-col>
                        <b-col cols="3" style="text-align: left"> -->
                      <!-- <b-form-group label=""> -->
                      <!-- <b-form-radio-group v-model="aksesorisadd.case" name="accessory">
                            <b-form-radio value="plastik">Softcase (Plastik)</b-form-radio>
                            <b-form-radio value="metal">Hardcase (Metal)</b-form-radio>
                            <b-form-radio value="">Tanpa Case</b-form-radio>
                          </b-form-radio-group>
                        </b-col>
                        <b-col cols="2" style="text-align: left"> </b-col>
                        <b-col style="text-align: left"> -->
                      <!-- <b-form-checkbox-group id="checkbox-group-2" v-model="selected" stacked name="flavour-2"> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.lap" value="lap">Lap</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.lensCleaner" value="cleaner">Lens
                            Cleaner</b-form-checkbox> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.kartuResep">Kartu Resep</b-form-checkbox>
                      <b-form-checkbox v-model="aksesorisadd.kartuGaransi">Kartu Garansi</b-form-checkbox> -->
                      <!-- </b-form-checkbox-group> -->
                      <!-- </b-form-group> -->
                      <!-- </b-col> -->
                      <!-- <b-col style="text-align: left"> -->
                      <!-- <b-form-checkbox-group id="checkbox-group-2" v-model="selected" stacked name="flavour-2"> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.lap">Lap</b-form-checkbox>
                      <b-form-checkbox v-model="aksesorisadd.lensCleaner">Lens Cleaner</b-form-checkbox> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.kartuResep" value="resep">Kartu Resep</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuGaransi" value="garansi">Kartu
                            Garansi</b-form-checkbox>
                          <b-form-checkbox v-model="aksesorisadd.kartuVoucher" value="voucher">Kartu
                            Voucher</b-form-checkbox> -->
                      <!-- </b-form-checkbox-group> -->
                      <!-- </b-form-group> -->
                      <!-- </b-col> 
                      </b-row>
                      <hr /> -->
                      <b-row>
                        <b-col cols="6" style="text-align: left"> </b-col>
                        <!-- <b-col cols="3" style="text-align: left">
                          <h5><b>Frame</b></h5>
                          <b-button
                            variant="outline-warning"
                            block
                            @click="this.modalLensa = false"
                            >Cancel</b-button
                          >
                        </b-col> -->
                        <b-col cols="3" style="text-align: left">
                          <!-- <h5><b>Frame</b></h5> -->
                          <b-button
                            variant="warning"
                            block
                            @click="submitreseplensaonly"
                            >Save</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-tab>
                </b-tabs>
              </b-card>
              <!-- <h4>{{ $t('Eyeglass Prescription') }}</h4> -->
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>

      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        :title="$t('Choose Frame + Accessories')"
        centered
        size="xl"
        hide-footer
        v-model="modalframeacc">
        <b-overlay :show="loadingsubmitresep">
          <!-- <b-card> -->
          <b-row>
            <b-col xl="4" lg="4" md="12" sm="12">
              <b-card
                header-bg-variant="warning"
                header-text-variant="white"
                header-tag="header"
                border-variant="warning">
                <template #header>
                  <h3 class="mb-0">
                    <b>{{ $t("Note By Buyer") }}</b>
                  </h3>
                </template>
                <b-row>
                  <b-col md="12" sm="12" class="mt-1">
                    <!-- <h4 align="left">{{ $t('Note By Buyer') }}</h4> -->
                    <b-card-text align="left">
                      <h5>
                        <b>{{ customer ? customer["customerName"] : null }}</b>
                      </h5>
                    </b-card-text>
                    <b-card-text align="left">{{
                      lensa ? lensa["buyerNote"] : null
                    }}</b-card-text>
                    <b-card-text align="left">
                      <h5>
                        <b>{{
                          customer
                            ? customer.extraInfo
                              ? customer.extraInfo.noteByBuyer
                              : null
                            : null
                        }}</b>
                      </h5>
                    </b-card-text>
                  </b-col>
                </b-row>
                <!-- </b-card>
						<b-card border-variant="primary" header-border-variant="primary" align="center"> -->
                <b-row class="mt-1">
                  <b-col md="12" sm="12">
                    <h4 align="left">
                      <b>{{ $t("Product Information") }}</b>
                    </h4>
                    <template
                      v-for="lensa in customer ? customer['items'] : null">
                      <b-row no-gutters>
                        <b-col md="3">
                          <b-card-img
                            style="width: 80px"
                            :src="lensa ? lensa.productImageUrl : null"
                            alt="Image"
                            class="rounded-0"></b-card-img>
                        </b-col>
                        <b-col md="9">
                          <b-card-text align="left">
                            <span :title="lensa ? lensa.productName : null">{{
                              truncateString(
                                lensa ? lensa.productName : null,
                                30
                              )
                            }}</span
                            ><br />
                            {{ lensa ? lensa.variationName : null }}<br />
                            {{ lensa ? lensa.masterSku : null }}<br />
                            {{ formatRupiah(lensa ? lensa.actualPrice : null) }}
                            x {{ lensa ? lensa.quantity : null }}
                          </b-card-text>
                          <b-button
                            variant="primary"
                            @click="getAcc(lensa)"
                            v-if="
                              (!lensa.productName.match(/lensa/gi) &&
                                lensa.productName.match(/frame/gi)) ||
                              lensa.productName.match(/pembersih/gi) ||
                              lensa.productName.match(/rubber/gi)
                            "
                            >Add</b-button
                          >
                        </b-col>
                      </b-row>
                    </template>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col xl="8" lg="8" md="12" sm="12">
              <b-card
                header-bg-variant="warning"
                header-text-variant="white"
                header-tag="header"
                border-variant="warning">
                <template #header>
                  <h3 class="mb-0">
                    <b>{{ $t("Frame Only / Accessories") }}</b>
                  </h3>
                </template>
                <b-form class="mt-1">
                  <!-- <b-row>
                    <b-col cols="12" style="text-align: left;">
                      <h5><b>Frame</b></h5>
                    </b-col>
                    <b-col cols="2" style="text-align: left;">
                      <h5>Type</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame category" v-model="resep.framecategory" label="text"
                          :options="categoryframeOptions"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2" class="">
                      <h5>Varian / Color</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame" v-model="resep.frame" label="text"
                          :options="penyimpananOptions"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr> -->
                  <b-row>
                    <b-col cols="12" style="text-align: left">
                      <h5><b>Items</b></h5>
                    </b-col>
                    <template v-for="(hem, i) in accadd">
                      <b-col cols="2" style="text-align: left">
                        <!-- <h5></h5> -->
                      </b-col>
                      <b-col cols="8">
                        {{ hem.barang.nama }} - {{ hem.barang.varian }} x
                        {{ hem.quantity }}
                      </b-col>
                      <b-col cols="2" style="text-align: left">
                        <!-- <template> -->
                        <b-button
                          @click.prevent="removeadditional(i)"
                          variant="flat-danger"
                          size="sm">
                          <feather-icon icon="XIcon" />
                        </b-button>
                        <!-- </template> -->
                      </b-col>
                    </template>
                  </b-row>
                  <hr />
                  <!-- Accessories fields -->
                  <b-row>
                    <b-col cols="12" style="text-align: left">
                      <h5><b>Packaging</b></h5>
                    </b-col>
                    <b-col cols="1" style="text-align: left"> </b-col>
                    <b-col cols="" style="text-align: left">
                      <!-- <b-form-group label=""> -->
                      <b-form-radio-group
                        v-model="aksesorisadd.case"
                        name="accessory">
                        <b-form-radio value="plastik"
                          >Softcase (Plastik)
                          <b-form-spinbutton
                            v-model="aksesorisadd.plastikqty"
                            min="1"
                            max="1000"
                        /></b-form-radio>
                        <b-form-radio value="metal"
                          >Hardcase (Metal)
                          <b-form-spinbutton
                            v-model="aksesorisadd.metalqty"
                            min="1"
                            max="1000"
                        /></b-form-radio>
                        <b-form-radio value="">Tanpa Case</b-form-radio>
                      </b-form-radio-group>
                    </b-col>
                    <b-col style="text-align: left">
                      <!-- <b-form-checkbox-group id="checkbox-group-2" v-model="selected" stacked name="flavour-2"> -->
                      <b-form-checkbox v-model="aksesorisadd.lap" value="lap"
                        >Lap</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="aksesorisadd.lensCleaner"
                        value="cleaner"
                        >Lens Cleaner</b-form-checkbox
                      >
                      <!-- <b-form-checkbox v-model="aksesorisadd.kartuResep">Kartu Resep</b-form-checkbox>
                      <b-form-checkbox v-model="aksesorisadd.kartuGaransi">Kartu Garansi</b-form-checkbox> -->
                      <!-- </b-form-checkbox-group> -->
                      <!-- </b-form-group> -->
                    </b-col>
                    <b-col style="text-align: left">
                      <!-- <b-form-checkbox-group id="checkbox-group-2" v-model="selected" stacked name="flavour-2"> -->
                      <!-- <b-form-checkbox v-model="aksesorisadd.lap">Lap</b-form-checkbox>
                      <b-form-checkbox v-model="aksesorisadd.lensCleaner">Lens Cleaner</b-form-checkbox> -->
                      <b-form-checkbox
                        v-model="aksesorisadd.kartuResep"
                        value="resep"
                        >Kartu Resep</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="aksesorisadd.kartuGaransi"
                        value="garansi"
                        >Kartu Garansi</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="aksesorisadd.kartuVoucher"
                        value="voucher"
                        >Kartu Voucher</b-form-checkbox
                      >
                      <!-- </b-form-checkbox-group> -->
                      <!-- </b-form-group> -->
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col cols="6" style="text-align: left"> </b-col>
                    <b-col cols="3" style="text-align: left">
                      <!-- <h5><b>Frame</b></h5> -->
                      <b-button variant="warning" block @click="submitnonlensa"
                        >Save</b-button
                      >
                    </b-col>

                    <b-modal v-model="showmodalnonlensa" title="Submit Data">
                      <b-row>
                        <b-col md="12">
                          <p>
                            <strong>No Invoice:</strong>
                            {{ wh ? wh.no_invoice : "-" }}
                          </p>

                          <h5>Items:</h5>
                          <ul v-if="wh">
                            <li v-for="(item, index) in wh.items" :key="index">
                              Item ID: {{ item.barang ? item.barang : "-" }} -
                              Qty: {{ item.qty ? item.qty : "-" }}
                            </li>
                          </ul>
                        </b-col>
                        <b-col md="12">
                          <h5>Packaging:</h5>
                          <ul v-if="wh">
                            <li
                              v-for="(acc, index) in wh.accessories"
                              :key="index">
                              {{ acc.nama ? acc.nama : "-" }} ({{
                                acc.type ? acc.type : "-"
                              }}) - Qty: {{ acc.qty ? acc.qty : "-" }}
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                      <template #modal-footer="{ ok, cancel }">
                        <b-button variant="secondary" @click="cancel()"
                          >Close</b-button
                        >
                        <b-button variant="primary" @click="oknonlensa()"
                          >OK</b-button
                        >
                      </template>
                    </b-modal>
                  </b-row>
                </b-form>
              </b-card>

              <!-- </b-tab> -->
              <!-- </b-tabs> -->
              <!-- <h4>{{ $t('Eyeglass Prescription') }}</h4> -->
            </b-col>
          </b-row>
        </b-overlay>
      </b-modal>
      <b-modal
        no-close-on-backdrop
        no-close-on-esc
        :title="$t('Choose Frame + Accessories')"
        centered
        size="xl"
        hide-footer
        v-model="modalinvoice">
        <b-row class="justify-content-center">
          <b-col md="8">
            <h1 class="text-center">INVOICE</h1>
            <b-card>
              <b-row>
                <b-col>
                  <p><strong>Store:</strong> Heykama</p>
                  <p>
                    <strong>Address:</strong> JL Bangreng No. 8, Turangga,
                    Bandung
                  </p>
                  <p><strong>Phone:</strong> 081222400057</p>
                </b-col>
                <b-col>
                  <b-form-group label="Invoice Date:">
                    <b-form-input v-model="invoice.date"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Invoice Number:">
                    <b-form-input v-model="invoice.number"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                striped
                hover
                :items="formattedItems"
                :fields="fieldsinv">
              </b-table>
              <b-row class="mt-4">
                <b-col class="text-right">
                  <b-button @click="printPreview" variant="primary"
                    >Print Preview</b-button
                  >
                  <!-- <b-button @click="printinvoice" variant="success">Print to PDF</b-button> -->
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col>
                  <p><strong>Notes:</strong></p>
                  <p>1. Please pay only through BCA (283 142 2540).</p>
                  <p>2. Process of item making is 3-5 working days.</p>
                  <p>3. Items cannot be returned or canceled.</p>
                  <p>
                    4. Unclaimed items after 2 months are not the store's
                    responsibility.
                  </p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-model="showModalPrintHistory"
        title="Print History"
        size="xl"
        hide-footer>
        <!-- Tabel List Print History -->
        <b-table :items="printHistory" :fields="fieldsPrintHistory" responsive>
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(printTime)="row">
            {{ humanDateTime(row.item.created_at) }}
          </template>
          <template #cell(printOperator)="row">
            {{ row.item.creator.email }}
          </template>
          <template #cell(action)="row">
            <b-button
              size="sm"
              variant="warning"
              @click="downloadFile(row.item.taskId)">
              <feather-icon icon="EyeIcon"></feather-icon>
              View
            </b-button>
          </template>
        </b-table>
      </b-modal>
      <!-- Print Area -->
      <div id="print-area" ref="printArea" style="display: none">
        <h1 class="text-center">INVOICE</h1>
        <div>
          <p><strong>Store:</strong> Heykama</p>
          <p><strong>Address:</strong> JL Bangreng No. 8, Turangga, Bandung</p>
          <p><strong>Phone:</strong> 081222400057</p>
        </div>
        <div>
          <p><strong>Invoice Date:</strong> {{ invoice.date }}</p>
          <p><strong>Invoice Number:</strong> {{ invoice.number }}</p>
        </div>
        <!-- Table for Print -->
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Product</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in formattedItems" :key="index">
              <td>{{ item.no }}</td>
              <td>{{ item.product }}</td>
              <td>{{ item.qty }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.discount }}</td>
              <td>{{ item.total }}</td>
            </tr>
          </tbody>
        </table>

        <!-- Notes for Print -->
        <p><strong>Notes:</strong></p>
        <p>1. Please pay only through BCA (283 142 2540).</p>
        <p>2. Process of item making is 3-5 working days.</p>
        <p>3. Items cannot be returned or canceled.</p>
        <p>
          4. Unclaimed items after 2 months are not the store's responsibility.
        </p>
      </div>
    </b-overlay>
  </main>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BEmbed,
  BAlert,
  BPopover,
  VBPopover,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BProgress,
  BProgressBar,
  BLink,
  BTable,
  BCard,
  BCardBody,
  BCardImg,
  BCardText,
  BCardTitle,
  VBTooltip,
  BTabs,
  BTab,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormDatepicker,
  BFormSpinbutton,
  BButtonGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFDocument } from "pdf-lib";
// import { missingKeys } from '@libs/i18n/index.js'
// console.log('Missing translation keys:', missingKeys)
// const maxLength = 20;
// const truncatedString = truncateString(originalString, maxLength);

export default {
  mixins: [mixin],
  components: {
    BProgress,
    BProgressBar,
    BEmbed,
    flatPickr,
    jsPDF,
    BButtonGroup,
    BFormDatepicker,
    BPopover,
    BTabs,
    BTab,
    BFormSpinbutton,
    BAlert,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BLink,
    ToastificationContent,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BCardTitle,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BImg,
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      jumlahchooselensa: [],
      start_dateexport: null,
      end_dateexport: null,
      loadingkategorilensa: false,
      position: {
        x: 290, // Default x position
        y: 910, // Default y position
      },
      typeOptions: [],
      productType: null,
      dragging: false, // Track if dragging is active
      dragOffset: { x: 0, y: 0 }, // Offset to handle smooth dragging
      namaproduk: null,
      start_datesync: null,
      end_datesync: null,
      rangeDate: null,
      selectedginee: null,
      start_date: null,
      end_date: null,
      loadinglensa: false,
      loadingsubmitresep: false,
      loadingresep: false,
      dataresep: [],
      //Aksesoris Tambahan
      aksesorisadd: {
        case: "plastik",
        plastikqty: 1,
        metalqty: 1,
        lap: "lap",
        lensCleaner: "cleaner",
        kartuResep: "resep",
        kartuGaransi: "garansi",
        kartuVoucher: "voucher",
      },
      //Kelas
      classOptions: [],
      accessoryOptions: [
        { text: "Softcase (Plastik)", value: "Softcase" },
        { text: "Hardcase (Metal)", value: "Hardcase" },
      ],
      //accs
      aksesorisOptions: [],
      itemsacc: [
        { acc: "Case / Cepuk", product: null, qty: 1, selected: false },
        { acc: "Lens Cleaner", product: null, qty: 1, selected: false },
        { acc: "Lap", product: null, qty: 1, selected: false },
        { acc: "Kartu Garansi", product: null, qty: 1, selected: false },
        { acc: "Kartu Resep", product: null, qty: 1, selected: false },
        { acc: "Voucher", product: null, qty: 1, selected: false },
      ],
      fieldsacc: [
        { key: "selected", label: "Acc", class: "text-center" },
        { key: "acc", label: "Accessories" },
        { key: "product", label: "Choose Product" },
        { key: "qty", label: "Qty" },
      ],
      productsList: [
        ["Product A1", "Product B1", "Product C1"],
        ["Product A2", "Product B2", "Product C2"],
        ["Product A3", "Product B3", "Product C3"],
        ["Product A4", "Product B4", "Product C4"],
        ["Product A5", "Product B5", "Product C5"],
        ["Product A5", "Product B5", "Product C5"],
      ],
      // ginee
      statusorderOptions: [],
      statusordermodel: null,
      storeOptions: [],
      logisticNames: [],
      logisticNamesOptions: [],
      statuslabel: null,
      statuslabelOptions: [
        { value: null, text: "--ALL--" },
        { value: "PRINTED", text: "Printed" },
        { value: "NOT_PRINTED", text: "Unprinted" },
      ],
      storemodel: null,
      selected: [],
      allSelected: false,
      indeterminate: false,
      channelOptions: [],
      channelmodel: null,
      frame: null,

      //RESEP
      //CASE
      //CASE
      case: {
        penyimpanan_id: null,
        qty: 0,
      },
      //Garansi
      lap: {
        penyimpanan_id: null,
        qty: 0,
      },
      //CASE
      lenscleaner: {
        penyimpanan_id: null,
        qty: 0,
      },
      //Garansi
      garansi: {
        penyimpanan_id: null,
        qty: 0,
      },
      //Garansi
      cardresep: {
        penyimpanan_id: null,
        qty: 0,
      },
      caseOptions: [],
      //FRAME
      penyimpananOptions: [],
      kategori_search: null,
      aksesoris: null,
      barangOptions: [],
      kategori_ids: null,
      wh: null,
      resep: {
        name: null,
        r_sph: null,
        r_cyl: null,
        r_axis: null,
        r_add: null,
        r_pd: null,
        l_sph: null,
        l_cyl: null,
        l_axis: null,
        l_add: null,
        l_pd: null,
        acc: null,
        lensa: null,
        frame: null,
        date: new Date().toISOString().substr(0, 10),
      },
      accadd: [],
      categorylensaOptions: [],
      categoryframeOptions: [],
      categoryaksesorisOptions: [],
      category: null,
      localBarangsOptions: [],
      localBarangsOptionsleft: [],
      localBarangs: null,
      modalLensa: false,
      modalframeacc: false,
      lensa: null,
      customer: null,
      showModalEdit: false,
      showModalAdd: false,
      showModalTerapis: false,
      loadingTerapis: false,
      itemTransaksiTerapis: {},
      id: null,
      form: {
        blok: null,
        gudang_id: null,
      },
      dataGudang: [],
      listTerapis: [],
      perPage: 50,
      pageOptions: [50, 80, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortOptions: [],
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      dataExport: {
        konsumen_id: null,
        sales_id: null,
      },
      ginee: [],
      fieldsginee: [
        // { key: "selectedginee", label: "#" },
        // 'selectedginee',
        {
          key: "no",
          label: "No",
        },
        { key: "productinfo", label: "Product Info" },
        // { key: "outbondorder", label: "Outbond Order" },
        // {
        //   key: "logistics",
        //   label: "Logistics / Customer",
        //   thStyle: { width: "30%" },
        // },
        // // { key: "customer", label: "Customer", },
        // { key: "durasi", label: "Duration" },
        // { key: "action", label: "Operate" },
      ],
      // selected: [],
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "nama", label: "Konsumen" },
        // { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "action", label: "#" },
      ],
      fieldsTerapis: [
        {
          key: "no",
          label: "No",
        },
        { key: "nama_lengkap", label: "Nama Lengkap", sortable: true },
        { key: "no_hp", label: "No. HP" },
        { key: "alamat", label: "Alamat" },
        { key: "on_progress_orders", label: "Sedang Orderan" },
        { key: "completed_orders", label: "Selesai Orderan" },
        { key: "action", label: "#" },
      ],
      items: [],
      swalOptions: {
        title: "Pindahkan Stok Retur ke Mobil?",
        text: `Stok Barang Retur akan kembali ke Stok Mobil Anda`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Retur Barang",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      },
      salesOptions: [],
      konsumenOptions: [],
      loading: false,
      loadingfoto: false,
      showKonfirmasi: false,
      formkonfirmasi: {
        no_invoice: null,
        bukti_tf: {
          file: null,
        },
      },
      konfirmasi: null,
      id_akun: [],
      id_kas: [],
      transactionType: null,
      debitAkun: [],
      kreditAkun: [],
      foto: null,
      status_bayar: null,
      optstatus_bayar: [
        { text: "Lunas", value: "lunas" },
        { text: "Belum Lunas", value: "belum_lunas" },
      ],
      statuskirim: null,
      optstatuskirim: [
        { value: "selesai", text: "Selesai" },
        { value: "diproses", text: "Diproses" },
        { value: "dikirim", text: "Dikirim" },
        { value: "dibatalkan", text: "Dibatalkan" },
        { value: "pending", text: "Pending" },
      ],
      typeTransaksi: null,
      opttypetransaksi: [
        { text: "Member", value: "member" },
        { text: "Non Member", value: "non-member" },
      ],
      member_id: null,
      is_accounted: null,
      optis_accounted: [
        { text: "Ya", value: 1 },
        // { text: 'Tidak', value: != 1 },
      ],
      masuk: null,
      optis_member: null,
      is_member: null,
      showModal: false,
      showmodalnonlensa: false,
      fieldsinv: [
        { key: "no", label: "No" },
        { key: "product", label: "Product" },
        { key: "qty", label: "Qty" },
        { key: "price", label: "Price" },
        { key: "discount", label: "Discount" },
        { key: "total", label: "Total" },
      ],
      invoice: {
        date: null,
        number: null,
      },
      modalinvoice: false,
      formattedItems: [],
      allChecked: false,
      selectAll2: false,
      skulensa: [],
      modalopenchat: false,
      lensasearch: null,
      lensasearchleft: null,
      progressValue: 0,
      // webUrl: "https://www.blackbox.ai/"
      webUrl:
        "https://accounts.ginee.com/?redirect_uri=https%3A%2F%2Fchat.ginee.com&system_id=SYS_CHAT",
      showModalPrintHistory: false,
      fieldsPrintHistory: [
        { key: "no", label: "No.", sortable: false },
        { key: "taskId", label: "Task ID", sortable: true },
        { key: "printTime", label: "Print Time", sortable: true },
        { key: "printOperator", label: "Print Operator", sortable: true },
        { key: "action", label: "Action", sortable: false },
      ],
      // Data print history
      printHistory: [],
    };
  },
  computed: {
    apiFile() {
      return "https://api-heykama.notive.my.id/";
    },
    cardStyle() {
      let isMobile = window.innerWidth <= 768;
      return {
        position: "fixed",
        top: `${this.position.y}px`,
        left: isMobile ? `30px` : `${this.position.x}px`,
        // left: `30px`,
        width: "900px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        borderRadius: "8px",
        backgroundColor: "white",
        zIndex: 1000,
        cursor: "move", // Change cursor to indicate draggable
      };
    },
    isKreditAkun() {
      return this.transactionType == 2;
    },
    isDebitAkun() {
      return this.transactionType == 1;
    },
    totalPenjualan() {
      if (!this.items || this.items.length < 1) {
        return 0;
      }

      return this.items.reduce(
        (total, item) => (total += parseInt(item.uang_masuk)),
        0
      );
    },
    // sortOptions() {
    //   // Create an options list from our fields
    //   return this.fields
    //     .filter((f) => f.sortable)
    //     .map((f) => ({ text: f.label, value: f.key }));
    // },
  },
  watch: {
    // "resep.lensClass"(val) {
    //   this.getDataKategorifix()
    // },
    selectedginee(val){
      // getlensa(val);
                                this.getCustomer(val);
                                this.getResep(val.externalOrderId);
                                this.getInfo(val);
    },
    "resep.frame"(val) {
      if (val) {
        this.resep.framecategory = {
          value: val.barang.kategori.id,
          text: val.barang.kategori.kategori,
        };
      } else {
        this.resep.framecategory = null;
      }
    },
    rangeDate(val) {
      const [start, end] = val.split(" to ");
      // Assign the values to your variables
      this.start_date = start;
      this.end_date = end;
      // this.getGinee();
    },
    logisticNames(newVal) {
      if (newVal.length === 0) {
        this.logisticNames2 = []; // Mengosongkan semua pilihan
        this.getGinee();
      } else {
        this.logisticNames2 = [];
        this.logisticNames2 = newVal;
        this.getGinee();
      }
    },
    allChecked(val) {
      if (val) {
        this.selectedginee = this.ginee;
      } else {
        this.selectedginee = [];
      }
    },
    "resep.l_sph"(vall) {
      let va = vall ? vall.text : null;
      if (
        va &&
        this.resep.l_sph.text.match(/\+/gi) &&
        this.resep.lensClass.text != "Progresif"
      ) {
        if (va.length > 3) {
          this.resep.l_cyl = va.slice(-3);
        } else {
          return (this.resep.l_cyl = null);
        }
      } else if (
        va &&
        this.resep.l_sph.text.match(/MAX/gi) &&
        this.resep.lensClass.text != "Progresif"
      ) {
        if (va.length > 3) {
          this.resep.l_cyl = va.slice(-3);
        } else {
          return (this.resep.l_cyl = null);
        }
      } else if (
        va &&
        this.resep.l_sph.text.match(/\+/gi) &&
        this.resep.lensClass.text === "Progresif" &&
        this.skulensa.some((item) => item.match(/NonCYL/gi))
      ) {
        if (va.length > 3) {
          this.resep.l_add = va.slice(-5);
        } else {
          return (this.resep.l_add = null);
        }
      } else if (
        va &&
        this.resep.l_sph.text.match(/\+/gi) &&
        this.resep.lensClass.text === "Progresif" &&
        this.skulensa.some((item) => item.match(/CYL/gi))
      ) {
        if (va.length > 3) {
          this.resep.l_cyl = va.substring(8, 13);
        } else {
          return (this.resep.l_cyl = null);
        }
      } else {
        return (this.resep.l_cyl = null);
      }
    },
    "resep.r_sph"(val) {
      let vat = val ? val.text : null;
      // console.log("CEKKKKK", va, this.resep.r_sph.text.match(/\+/gi), this.resep.lensClass.text === 'Progresif', this.skulensa.some(item => item.match(/CYL/gi)))
      if (
        vat &&
        this.resep.r_sph.text.match(/\+/gi) &&
        this.resep.lensClass.text != "Progresif"
      ) {
        if (vat.length > 3) {
          this.resep.r_cyl = vat.slice(-3);
        } else {
          return (this.resep.r_cyl = null);
        }
      } else if (
        vat &&
        this.resep.r_sph.text.match(/MAX/gi) &&
        this.resep.lensClass.text != "Progresif"
      ) {
        if (vat.length > 3) {
          this.resep.r_cyl = vat.slice(-3);
        } else {
          return (this.resep.r_cyl = null);
        }
      } else if (
        vat &&
        this.resep.r_sph.text.match(/\+/gi) &&
        this.resep.lensClass.text === "Progresif" &&
        this.skulensa.some((item) => item.match(/NonCYL/gi))
      ) {
        if (vat.length > 3) {
          this.resep.r_add = vat.slice(-5);
        } else {
          return (this.resep.r_add = null);
        }
      } else if (
        vat &&
        this.resep.r_sph.text.match(/\+/gi) &&
        this.resep.lensClass.text === "Progresif" &&
        this.skulensa.some((item) => item.match(/CYL/gi))
      ) {
        if (vat.length > 3) {
          this.resep.r_cyl = vat.substring(8, 13);
        } else {
          return (this.resep.r_cyl = null);
        }
      } else {
        return (this.resep.r_cyl = null);
      }
    },
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + "...";
      } else {
        return str;
      }
    },
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
    perPage(value) {
      if (value) {
        this.getGinee();
      }
    },
    // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getTransaksi();
    // }
    //},
    currentPage(val) {
      if (val) {
        this.getGinee();
        // this.items
      }
    },
    // storemodel(newValue) {
    //   if (newValue == null) {
    //     this.storemodel == []
    //   }
    // },
    selectAll(newVal) {
      this.itemsacc.forEach((item) => {
        item.selected = newVal;
      });
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      const isSelected = this.selectedginee && this.selectedginee.id === item.id;

  if (isSelected) {
    return "table-warning"; // Kelas untuk baris yang dipilih
  }
      if (item.scanned === "complete") {
        return "table-success"; // Green for complete
      } else if (item.scanned === "incomplete") {
        return "table-warning"; // Yellow for incomplete
      } else if (item.scanned === "exceed") {
        return "table-danger"; // Red for incomplete
      } else {
        return "table-default"; // Default/white for none
      }
    },
    onRowSelected(items) {
      // this.selectedginee = items
      if (items.length > 0) {
        this.selectedginee = items[0];
        this.currentIndex = this.ginee.findIndex(
          (item) => item === this.selectedginee
        );
      } else {
        this.selectedginee = null;
      }
    },
    nextGinee() {
      if (this.ginee.length === 0) {
        console.warn("Data ginee kosong.");
        return;
      }

      // Pindah ke item berikutnya
      this.currentIndex = (this.currentIndex + 1) % this.ginee.length;
      this.setSelectedGinee(this.currentIndex);
    },
    previousGinee() {
      if (this.ginee.length === 0) {
        console.warn("Data ginee kosong.");
        return;
      }

      // Pindah ke item sebelumnya
      this.currentIndex =
        (this.currentIndex - 1 + this.ginee.length) % this.ginee.length;
      this.setSelectedGinee(this.currentIndex);
    },
    setSelectedGinee(index) {
      this.selectedginee = this.ginee[index];
      // Mengupdate tabel agar baris dipilih
      this.$refs.selectableTable.selectRow(this.selectedginee);
    },
    aturchooselensa() {
      sessionStorage.setItem(
        "itemChooselensa",
        JSON.stringify(this.jumlahchooselensa)
      );
      const routeData = this.$router.resolve({
        path: "/pesanan/chooselensa",
        name: "route-pesanan-chooselensa",
        // params: { id: item.orderId } // contoh parameter
      });
      window.open(routeData.href, "_blank");
    },
    copyToClipboard(customer) {
      const orderId = customer ? customer.externalOrderId : "";
      if (orderId) {
        // Menggunakan API Clipboard untuk menyalin teks
        navigator.clipboard
          .writeText(orderId)
          .then(() => {
            console.log("Copied to clipboard:", orderId);
            // Anda bisa menampilkan notifikasi atau pesan sukses di sini
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
            // Anda bisa menampilkan notifikasi atau pesan gagal di sini
          });
      }
    },
    openchat() {
      // this.modalopenchat = true
      // this.$nextTick(() => {
      //   const iframe = this.$refs.iframe; // Pastikan Anda memberikan ref pada iframe
      //   if (iframe) {
      //     iframe.focus();
      //   }
      // });
      window.open(this.webUrl, "_blank");
    },
    isTokenSyncAvailable() {
      return (
        typeof sessionStorage !== "undefined" &&
        sessionStorage.getItem("tokenSync")
      );
    },
    prosestowh() {
      const coba = this.selectedginee.map((item) => ({
        no_invoice: item.externalOrderId,
        order_type: "ginee",
      }));
      this.$store
        .dispatch("pesanan/cutoff", coba)
        .then(() => {
          this.displaySuccess({
            text: "Cutoff pesanan berhasil disimpan",
          });
        })
        .catch((e) => {
          this.$swal.close();
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });

      //   }
      // })
    },
    toggleAll2() {
      if (this.selectAll2) {
        // Select all options
        this.logisticNames = this.logisticNamesOptions.map(
          (option) => option.value
        );
      } else {
        // Deselect all options
        this.logisticNames = [];
      }
    },
    updateSelectAll() {
      this.selectAll2 =
        this.logisticNames.length === this.logisticNamesOptions.length;
    },
    customLabel(selected) {
      if (selected.length === this.logisticNamesOptions.length)
        return "--ALL--";
      if (selected.length > 0) return `${selected.length} selected`;
      return "-Select separately-";
    },
    toggleOption(value) {
      if (this.logisticNames.includes(value)) {
        this.logisticNames = this.logisticNames.filter(
          (item) => item !== value
        );
      } else {
        this.logisticNames.push(value);
      }
    },
    ceksync() {
      const routeData = this.$router.resolve({
        path: "/pesanan/sync",
        name: "route-pesanan-sync",
        // params: { id: item.orderId } // contoh parameter
      });
      window.open(routeData.href, "_blank");
    },
    toggle(checked) {
      if (checked) {
        this.logisticNames = []; // Pilih semua opsi
        // this.logisticNames = []
      } else {
        this.logisticNames = []; // Kosongkan semua pilihan
      }
    },
    aturpengiriman() {
      sessionStorage.setItem(
        "itemDatapengiriman",
        JSON.stringify(this.selectedginee)
      );
      const routeData = this.$router.resolve({
        path: "/pesanan/pengiriman",
        name: "route-pesanan-pengiriman",
        // params: { id: item.orderId } // contoh parameter
      });
      window.open(routeData.href, "_blank");
    },
    aturpengirimansingle(item) {
      sessionStorage.setItem("itemDatapengiriman", JSON.stringify(item));
      const routeData = this.$router.resolve({
        path: "/pesanan/pengiriman",
        name: "route-pesanan-pengiriman",
        // params: { id: item.orderId } // contoh parameter
      });
      window.open(routeData.href, "_blank");
    },
    startDrag(event) {
      this.dragging = true;
      // Calculate the offset between mouse position and card position
      this.dragOffset.x = event.clientX - this.position.x;
      this.dragOffset.y = event.clientY - this.position.y;

      // Add event listeners for mousemove and mouseup
      document.addEventListener("mousemove", this.onDrag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onDrag(event) {
      if (!this.dragging) return;
      // Update card position based on mouse movement and offset
      this.position.x = event.clientX - this.dragOffset.x;
      this.position.y = event.clientY - this.dragOffset.y;
    },
    stopDrag() {
      this.dragging = false;
      // Remove event listeners when dragging stops
      document.removeEventListener("mousemove", this.onDrag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    todaydate() {
      const date = new Date(); // contoh: Mon Oct 28 2024 13:02:47 GMT+0700 (Western Indonesia Time)

      // Mendapatkan tahun, bulan, dan tanggal
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Tambah 1 karena bulan dimulai dari 0
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      this.rangeDate = formattedDate;
      // this.start_date = formattedDate
      // this.end_date = formattedDate
      this.getGinee();
    },
    seventodaydate() {
      const today = new Date();

      // Mengatur tanggal mulai sebagai 7 hari yang lalu
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      // Format tanggal ke 'YYYY-MM-DD'
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      this.rangeDate = formatDate(sevenDaysAgo) + " to " + formatDate(today);
    },
    tigapuluhtodaydate() {
      const today = new Date();

      // Mengatur tanggal mulai sebagai 7 hari yang lalu
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 30);

      // Format tanggal ke 'YYYY-MM-DD'
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      this.rangeDate = formatDate(sevenDaysAgo) + " to " + formatDate(today);
    },
    syncModal() {
      if (this.start_datesync && this.end_datesync) {
        this.$swal({
          title: "Sedang Memproses...",
          text: "Tunggu sebentar, proses sedang berjalan.",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        this.loading = true;
        let params = {
          start_date: this.start_datesync,
          end_date: this.end_datesync,
        };
        this.$store
          .dispatch("ginee/sync", params)
          .then((response) => {
            sessionStorage.setItem("tokenSync", JSON.stringify(response));
            const routeData = this.$router.resolve({
              path: "/pesanan/sync",
              name: "route-pesanan-sync",
              // params: { id: item.orderId } // contoh parameter
            });
            window.open(routeData.href, "_blank");
            this.isTokenSyncAvailable();

            this.$swal.close();
            this.getGinee();
            this.loading = false;
            // window.open(response.link, '_blank');
            // console.log('CEK', response)
            // this.loadingresep = false
          })
          .catch((e) => {
            this.$swal.close();
            this.loading = false;
            this.displayError(e);
            return false;
          });
      } else {
        this.displayWarning({
          message: "Harap isi form tanggal yang disediakan",
        });
      }
    },
    exportexcelModal() {
      if (this.start_dateexport && this.end_dateexport) {
        this.$swal({
          title: "Sedang Memproses...",
          text: "Tunggu sebentar, proses sedang berjalan.",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        this.loading = true;
        let params = {
          start_date: this.start_dateexport,
          end_date: this.end_dateexport,
        };
        this.$store
          .dispatch("ginee/exportExcel", params)
          .then((response) => {
            if (response && response.url) {
              const fileUrl =
                "https://api-heykama.notive.my.id/" + response.url; // Gabungkan base URL dengan URL dari response
              window.open(fileUrl, "_blank"); // Membuka tab baru dengan URL yang benar
            } else {
              console.error("Response tidak mengandung URL");
              this.displayError("Gagal mendapatkan file untuk diunduh.");
            }

            this.$swal.close();
            // this.getGinee();
            this.loading = false;
            // window.open(response.link, '_blank');
            // console.log('CEK', response)
            // this.loadingresep = false
          })
          .catch((e) => {
            this.$swal.close();
            this.loading = false;
            this.displayError(e);
            return false;
          });
      } else {
        this.displayWarning({
          message: "Harap isi form tanggal yang disediakan",
        });
      }
    },
    exportpdfModal() {
      if (this.start_dateexport && this.end_dateexport) {
        this.$swal({
          title: "Sedang Memproses...",
          text: "Tunggu sebentar, proses sedang berjalan.",
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading();
          },
        });
        this.loading = true;
        let params = {
          start_date: this.start_dateexport,
          end_date: this.end_dateexport,
        };
        this.$store
          .dispatch("ginee/exportPdf", params)
          .then((response) => {
            if (response && response.url) {
              const fileUrl =
                "https://api-heykama.notive.my.id/" + response.url; // Gabungkan base URL dengan URL dari response
              window.open(fileUrl, "_blank"); // Membuka tab baru dengan URL yang benar
            } else {
              console.error("Response tidak mengandung URL");
              this.displayError("Gagal mendapatkan file untuk diunduh.");
            }

            this.$swal.close();
            // this.getGinee();
            this.loading = false;
            // window.open(response.link, '_blank');
            // console.log('CEK', response)
            // this.loadingresep = false
          })
          .catch((e) => {
            this.$swal.close();
            this.loading = false;
            this.displayError(e);
            return false;
          });
      } else {
        this.displayWarning({
          message: "Harap isi form tanggal yang disediakan",
        });
      }
    },
    removeadditional(i, item) {
      this.accadd.splice(i, 1);
    },
    printPreview() {
      const printContent = this.$refs.printArea.innerHTML;
      const originalContent = document.body.innerHTML;
      // Set the body content to the print area
      document.body.innerHTML = printContent;
      // Trigger the print dialog
      window.print();
      // Restore original content after print
      document.body.innerHTML = originalContent;
      window.location.reload(); // Reload page to restore Vue bindings
    },
    printmodalinv(item) {
      this.invoice.date = item.createAt;
      this.invoice.number = item.externalOrderId;
      this.modalinvoice = true;
      this.formattedItems = item.items;
      this.formattedItems.map((barang) => {
        barang.product = barang.productName;
        barang.qty = barang.quantity;
        barang.price = barang.price;
        barang.discount = barang.discount;
        barang.total = barang.total;
      });
      qty;
      price;
      discount;
      total;
      console.log("DATA", this.formattedItems);
    },
    // printinvoice() {
    //   const doc = new jsPDF();

    //   doc.text("INVOICE", 105, 10, { align: "center" });

    //   // Company details
    //   doc.text("Heykama", 10, 20);
    //   doc.text("JL Bangreng No. 8, Turangga, Bandung", 10, 30);
    //   doc.text("Phone: 081222400057", 10, 40);

    //   // Invoice details
    //   doc.text(`Invoice Date: ${this.invoice.date}`, 150, 20);
    //   doc.text(`Invoice Number: ${this.invoice.number}`, 150, 30);

    //   // Table
    //   doc.autoTable({
    //     startY: 50,
    //     head: [["No", "Product", "Qty", "Price", "Discount", "Total"]],
    //     body: this.formattedItems.map((item) => [
    //       item.no,
    //       item.product,
    //       item.qty,
    //       item.price,
    //       item.discount,
    //       item.total,
    //     ]),
    //   });

    //   // Notes
    //   doc.text("Notes:", 10, doc.autoTable.previous.finalY + 10);
    //   doc.text(
    //     "1. Please pay only through BCA (283 142 2540).",
    //     10,
    //     doc.autoTable.previous.finalY + 20
    //   );
    //   doc.text(
    //     "2. Process of item making is 3-5 working days.",
    //     10,
    //     doc.autoTable.previous.finalY + 30
    //   );
    //   doc.text(
    //     "3. Items cannot be returned or canceled.",
    //     10,
    //     doc.autoTable.previous.finalY + 40
    //   );
    //   doc.text(
    //     "4. Unclaimed items after 2 months are not the store's responsibility.",
    //     10,
    //     doc.autoTable.previous.finalY + 50
    //   );

    //   doc.save("invoice.pdf");
    // },
    printinv(item) {
      let params = {
        orderId: item.orderId,
        type: "INVOICE",
      };
      this.$swal({
        title: "Sedang Memproses...",
        text: "Tunggu sebentar, proses sedang berjalan.",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$store
        .dispatch("ginee/print", params)
        .then((response) => {
          this.$swal.close();
          window.open(response.link, "_blank");
          // console.log("CEK", response);
          // this.loadingresep = false
        })
        .catch((e) => {
          this.$swal.close();
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
      //   }
      // })
    },
    printinvv2(item) {
      let params = {
        no_invoice: item.externalOrderId,
        type: "ginee",
      };
      this.$swal({
        title: "Sedang Memproses...",
        text: "Tunggu sebentar, proses sedang berjalan.",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$store
        .dispatch("ginee/printinvv2", params)
        .then((response) => {
          this.$swal.close();
          window.open(
            "https://api-heykama.notive.my.id" + response.url,
            "_blank"
          );
          // console.log("CEK", response);
          // this.loadingresep = false
        })
        .catch((e) => {
          this.$swal.close();
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
      //   }
      // })
    },
    syncper(item) {
      let params = {
        orderId: item.orderId,
        // type: "ginee",
      };
      this.$swal({
        title: "Sedang Memproses...",
        text: "Tunggu sebentar, proses sedang berjalan.",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$store
        .dispatch("ginee/syncorder", params)
        .then((response) => {
          this.getGinee()
          this.$swal.close();
          // window.open(
          //   "https://api-heykama.notive.my.id" + response.url,
          //   "_blank"
          // );
          // console.log("CEK", response);
          // this.loadingresep = false
        })
        .catch((e) => {
          this.$swal.close();
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
      //   }
      // })
    },
    async syncpermass() {
//       if (Array.isArray(this.selectedginee) && this.selectedginee.length > 0) {
//   this.$swal({
//     title: "Sedang Memproses...",
//     text: "Tunggu sebentar, proses sedang berjalan.",
//     allowOutsideClick: false,
//     didOpen: () => {
//       this.$swal.showLoading();
//     },
//   });

//   // Gunakan Promise.all untuk menunggu semua request selesai
//   const requests = this.selectedginee.map((item) => {
//     let params = {
//       orderId: item.orderId,
//     };

//     return this.$store.dispatch("ginee/syncorder", params);
//   });

//   Promise.all(requests)
//     .then((responses) => {
//       this.getGinee(); // Memperbarui data setelah semua request selesai
//       this.$swal.close();

//       // Jika Anda ingin memproses respons, lakukan di sini
//       responses.forEach((response) => {
//         console.log("Response:", response);
//         // Misalnya: membuka URL dari setiap respons
//         // window.open("https://api-heykama.notive.my.id" + response.url, "_blank");
//       });
//     })
//     .catch((e) => {
//       this.$swal.close();
//       this.displayError(e);
//     });
// } else {
//   this.$swal({
//     title: "Tidak Ada Data!",
//     text: "Silakan pilih data yang ingin diproses.",
//     icon: "warning",
//   });

if (!Array.isArray(this.selectedginee) || this.selectedginee.length === 0) {
    this.$swal({
      title: "Tidak Ada Data!",
      text: "Silakan pilih data yang ingin diproses.",
      icon: "warning",
    });
    return;
  }

  // Tampilkan SweetAlert untuk loading
  this.$swal({
    title: "Sedang Memproses...",
    text: `0/${this.selectedginee.length} data telah diproses.`,
    allowOutsideClick: false,
    didOpen: () => {
      this.$swal.showLoading();
    },
  });

  for (let i = 0; i < this.selectedginee.length; i++) {
    const item = this.selectedginee[i];
    let params = {
      orderId: item.orderId,
    };

    try {
      // Proses sinkronisasi
      await this.$store.dispatch("ginee/syncorder", params);

      // Perbarui progres di SweetAlert
      this.$swal.update({
        text: `${i + 1}/${this.selectedginee.length} data telah diproses.`,
      });
    } catch (e) {
      // Tampilkan error di console tetapi tetap lanjut
      console.error(`Error pada Order ID: ${item.orderId}`, e);
    }
  }

  // Tutup SweetAlert setelah semua selesai
  this.$swal.close();

  // Panggil fungsi untuk memperbarui data
  this.getGinee();

  // Tampilkan notifikasi selesai
  this.$swal({
    title: "Selesai!",
    text: "Semua data berhasil diupdate.",
    icon: "success",
  });
    },
    printdoc(item) {
      let params = {
        orderId: item.orderId,
        type: "LABEL",
      };
      this.$swal({
        title: "Sedang Memproses...",
        text: "Tunggu sebentar, proses sedang berjalan.",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$store
        .dispatch("ginee/print", params)
        .then(async (response) => {
          this.$swal.close();
          try {
            const mergedPdf = await this.mergePdfs([response.link]);

            // Buat objek URL dari Blob dan buka di tab baru
            const blob = new Blob([mergedPdf], { type: "application/pdf" });

            let init = await this.$store.dispatch("pesanan/initTaskPrint");
            let taskId = init.taskId;

            const formData = new FormData();
            formData.append("taskId", taskId);
            formData.append("file", blob, "print.pdf");

            await this.$store.dispatch("pesanan/uploadMergeDoc", formData);
            this.getPrintHistory();
            window.open(response.link, "_blank");
          } catch (e) {
            console.log(e);
          }
          // this.loadingresep = false
        })
        .catch((e) => {
          this.$swal.close();
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
      //   }
      // })
    },
    getPrintHistory() {
      this.loading = true;
      this.$store
        .dispatch("pesanan/historyPrint")
        .then((res) => {
          this.printHistory = res.data;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    async downloadFile(taskId) {
      try {
        let payload = {
          taskId: taskId,
        };
        let response = await this.$store.dispatch(
          "pesanan/downloadDokumen",
          payload
        );
        let url = URL.createObjectURL(response);
        window.open(url, "_blank");
      } catch (e) {
        console.log(e);
      }
    },
    printlabelmass() {
      if (this.selectedginee.length === 0) {
        this.$swal({
          title: "Tidak ada data",
          text: "Pilih setidaknya satu item untuk mencetak label.",
          icon: "warning",
        });
        return;
      }

      this.$swal({
        title: "Sedang Memproses...",
        html: "Proses: <b>0%</b>",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      let pdfLinks = [];
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      const updateProgress = (current, total) => {
        const percentage = Math.round((current / total) * 100);
        this.$swal.update({
          html: `Proses: <b>${percentage}%</b>`,
        });
      };

      const processSequentially = async () => {
        await this.selectedginee.reduce(async (prevPromise, item, index) => {
          await prevPromise;

          let params = {
            orderId: item.orderId,
            type: "LABEL",
          };

          try {
            const response = await this.$store.dispatch("ginee/print", params);
            if (response.link) {
              pdfLinks.push(response.link);
            }
          } catch (e) {
            this.displayError(e);
          }

          // Update progres di SweetAlert
          updateProgress(index + 1, this.selectedginee.length);

          return delay(1000);
        }, Promise.resolve());
      };

      processSequentially()
        .then(async () => {
          if (pdfLinks.length > 0) {
            try {
              // Gabungkan semua PDF
              const mergedPdf = await this.mergePdfs(pdfLinks);

              // Buat objek URL dari Blob dan buka di tab baru
              const blob = new Blob([mergedPdf], { type: "application/pdf" });
              console.log("mergedPdf", mergedPdf);
              console.log("blob", blob);

              let init = await this.$store.dispatch("pesanan/initTaskPrint");
              let taskId = init.taskId;

              const formData = new FormData();
              formData.append("taskId", taskId);
              formData.append("file", blob, "merged.pdf");

              await this.$store.dispatch("pesanan/uploadMergeDoc", formData);
              this.getPrintHistory();

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank"); // Membuka PDF gabungan di tab baru
            } catch (error) {
              console.error("Error merging PDFs:", error);
              this.$swal({
                title: "Gagal",
                text: "Terjadi kesalahan saat menggabungkan PDF.",
                icon: "error",
              });
            }
          } else {
            this.$swal({
              title: "Gagal",
              text: "Tidak dapat menghasilkan tautan PDF.",
              icon: "error",
            });
          }
        })
        .finally(() => {
          this.$swal.close();
        });
    },

    async mergePdfs(links) {
      const pdfDoc = await PDFDocument.create();

      for (const link of links) {
        try {
          // Panggil proxy backend melalui Vuex
          const existingPdfBytes = await this.$store.dispatch(
            "ginee/proxyPrint",
            {
              url: link,
            }
          );
          const existingPdf = await PDFDocument.load(existingPdfBytes);
          const copiedPages = await pdfDoc.copyPages(
            existingPdf,
            existingPdf.getPageIndices()
          );

          // Tambahkan halaman dari PDF yang sudah diunduh
          copiedPages.forEach((page) => pdfDoc.addPage(page));
        } catch (error) {
          console.error("Error processing PDF from:", link, error);
        }
      }

      // Simpan PDF gabungan
      return await pdfDoc.save();
    },

    printInvoiceMass() {
      if (this.selectedginee.length === 0) {
        this.$swal({
          title: "Tidak ada data",
          text: "Pilih setidaknya satu item untuk mencetak label.",
          icon: "warning",
        });
        return;
      }
      this.$swal({
        title: "Sedang Memproses...",
        text: "Tunggu sebentar, proses sedang berjalan.",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });

      console.log("this.selectedginee", this.selectedginee);

      let payloads = {
        invoices: [],
      };
      this.selectedginee.forEach((res) => {
        let payload = {
          no_invoice: res.externalOrderId,
          type: "ginee",
        };
        payloads.invoices.push(payload);
      });
      this.$store
        .dispatch("pesanan/downloadMultiInvoice", payloads)
        .then((res) => {
          let url = URL.createObjectURL(res);
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.$swal.close();
        });
    },

    getDatablok() {
      let params = {
        jenis: 1,
      };
      // if (this.isAdminGudang) {
      params.gudang_id = this.myGudang ? this.myGudang.id : null;
      // }
      this.$store.dispatch("tokoblok/getData", params).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.tokoblok.datas));
        ter.map((item) => {
          item.value = item.id;
          item.text = item.blok;
        });
        this.blok_id = ter;
      });
    },
    openNewTabWithParam(item) {
      if (!item.orderId) {
        console.error("ID item tidak ditemukan");
        this.displayInfo({
          message: "ID item tidak ditemukan",
        });
        return;
      }
      const routeData = this.$router.resolve({
        path: "/pesanan/detail/:id",
        name: "route-pesanan-detail",
        params: { id: item.orderId }, // contoh parameter
      });
      window.open(routeData.href, "_blank");
    },
    async submitresep() {
      // console.log("CEK", this.resep.r_sph);
      this.wh = null;
      this.wh = [];
      this.showModal = false;
      if (
        
        this.resep.lensClass &&
        this.resep.lensa && // kategori
        this.resep.framecategory && // kategori
        this.resep.frame &&
        this.resep.r_sph &&
        this.resep.l_sph
      ) {
        let submit = {
          no_invoice: this.customer.externalOrderId,
          name: this.resep.name ? this.resep.name : "",
          date: this.resep.date,
          items: [
            {
              penyimpanan_id: this.resep.r_sph ? this.resep.r_sph.id : null,
              barang: this.resep.r_sph
                ? this.resep.r_sph.barang.nama +
                  " - " +
                  this.resep.r_sph.barang.varian
                : null,
              qty: 1,
            },
            {
              penyimpanan_id: this.resep.l_sph ? this.resep.l_sph.id : null,
              barang: this.resep.l_sph
                ? this.resep.l_sph.barang.nama +
                  " - " +
                  this.resep.l_sph.barang.varian
                : null,
              qty: 1,
            },
            {
              penyimpanan_id: this.resep.frame ? this.resep.frame.id : null,
              barang: this.resep.frame
                ? this.resep.frame.barang.nama +
                  " - " +
                  this.resep.frame.barang.varian
                : null,
              qty: 1,
            },
          ],
          prescription_right: {
            sph: this.resep.r_sph.text.replace(/Stok \(.*?\) - /, ""),
            cyl: this.resep.r_cyl,
            axis: this.resep.r_axis,
            add: this.resep.r_add,
            pd: this.resep.r_pd,
          },
          prescription_left: {
            sph: this.resep.l_sph.text.replace(/Stok \(.*?\) - /, ""),
            cyl: this.resep.l_cyl,
            axis: this.resep.l_axis,
            add: this.resep.l_add,
            pd: this.resep.l_pd,
          },
          lens_class_id: this.resep.lensClass.id,
          lens_material_id: this.resep.lensa.id, // kategori
          frame_type_id: this.resep.framecategory.id, // kategori
          frame_varian_id: this.resep.frame.barang.id, // barang
        };
        // let submitaksesoris = [];
        // this.aksesoris.map((jb) => {
        //   if (jb.barang.nama.match(/lap/gi) && this.aksesorisadd.lap == "lap") {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "lap",
        //     });
        //   }
        //   if (
        //     jb.barang.varian.match(/cleaner jadi/gi) &&
        //     this.aksesorisadd.lensCleaner == "cleaner"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "lens_cleaner",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/garansi/gi) &&
        //     this.aksesorisadd.kartuGaransi == "garansi"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "kartu_garansi",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/resep/gi) &&
        //     this.aksesorisadd.kartuResep == "resep"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "kartu_resep",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/voucher/gi) &&
        //     this.aksesorisadd.kartuVoucher == "voucher"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "kartu_voucher",
        //     });
        //   }
        //   if (
        //     jb.barang.varian.match(/plastik/gi) &&
        //     this.aksesorisadd.case == "plastik"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: this.aksesorisadd.plastikqty
        //         ? this.aksesorisadd.plastikqty
        //         : 1,
        //       type: "case",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/metal/gi) &&
        //     this.aksesorisadd.case == "metal"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: this.aksesorisadd.metalqty ? this.aksesorisadd.metalqty : 1,
        //       type: "case",
        //     });
        //   }
        // });
        // submit.accessories = submitaksesoris;
        this.wh = submit;
        this.showModal = true;
        // console.log('SUBMIT', submit)
        // submitaksesoris = []
        //   this.$swal({
        //     title: "Anda yakin?",
        //     html: `
        //   <strong>No Invoice:</strong> ${submit.no_invoice} <br/>
        //   <strong>Name:</strong> ${submit.name} <br/>
        //   <strong>Date:</strong> ${submit.date} <br/>
        //   <strong>Items:</strong> <br/>
        //   ${submit.items.map(item => `<strong>Item:</strong> ${item.penyimpanan_id}, Qty: ${item.qty}`).join('<br/>')} <br/>
        //   <strong>Prescription Right:</strong> <br/>
        //   Sph: ${submit.prescription_right.sph}, Cyl: ${submit.prescription_right.cyl}, Axis: ${submit.prescription_right.axis}, Add: ${submit.prescription_right.add}, PD: ${submit.prescription_right.pd} <br/>
        //   <strong>Prescription Left:</strong> <br/>
        //   Sph: ${submit.prescription_left.sph}, Cyl: ${submit.prescription_left.cyl}, Axis: ${submit.prescription_left.axis}, Add: ${submit.prescription_left.add}, PD: ${submit.prescription_left.pd} <br/>
        //   <strong>Accessories:</strong> <br/>
        //   ${submit.accessories.map(acc => `<strong>${acc.nama}</strong> (${acc.type}), Qty: ${acc.qty}`).join('<br/>')}
        // `,
        //     icon: 'info',
        //     showCancelButton: true,
        //     confirmButtonText: "Ya",
        //     cancelButtonText: "Batal",
        //     customClass: {
        //       confirmButton: "btn btn-success",
        //       cancelButton: "btn btn-danger ml-1",
        //     },
        //     buttonsStyling: false,
        //   }).then((result) => {
        //     if (result.value) {
        // } else if (result.dismiss === 'cancel') {
        //   this.$swal({
        //     title: 'Cancelled',
        //     text: 'Your imaginary file is safe :)',
        //     icon: 'error',
        //     customClass: {
        //       confirmButton: 'btn btn-success',
        //     },
        //   })
        // }
        // })
      } else {
        this.displayInfo({
          message: "Form resep harap dilengkapi",
        });
        this.loadingsubmitresep = false;
        return false;
      }
    },
    async submitreseplensaonly() {
      // console.log("CEK", this.resep.r_sph);
      this.wh = null;
      this.wh = [];
      this.showModal = false;
      if (
        
        this.resep.lensClass &&
        this.resep.lensa && // kategori
        this.resep.r_sph &&
        this.resep.l_sph
      ) {
        let submit = {
          no_invoice: this.customer.externalOrderId,
          name: this.resep.name,
          date: this.resep.date,
          items: [
            {
              penyimpanan_id: this.resep.r_sph ? this.resep.r_sph.id : null,
              barang: this.resep.r_sph
                ? this.resep.r_sph.barang.nama +
                  " - " +
                  this.resep.r_sph.barang.varian
                : null,
              qty: 1,
            },
            {
              penyimpanan_id: this.resep.l_sph ? this.resep.l_sph.id : null,
              barang: this.resep.l_sph
                ? this.resep.l_sph.barang.nama +
                  " - " +
                  this.resep.l_sph.barang.varian
                : null,
              qty: 1,
            },
            // {
            //   penyimpanan_id: this.resep.frame ? this.resep.frame.id : null,
            //   barang: this.resep.frame ? this.resep.frame.barang.nama + " - " + this.resep.frame.barang.varian : null,
            //   qty: 1
            // }
          ],
          prescription_right: {
            sph: this.resep.r_sph.text.replace(/Stok \(.*?\) - /, ""),
            cyl: this.resep.r_cyl,
            axis: this.resep.r_axis,
            add: this.resep.r_add,
            pd: this.resep.r_pd,
          },
          prescription_left: {
            sph: this.resep.l_sph.text.replace(/Stok \(.*?\) - /, ""),
            cyl: this.resep.l_cyl,
            axis: this.resep.l_axis,
            add: this.resep.l_add,
            pd: this.resep.l_pd,
          },
          lens_class_id: this.resep.lensClass.id,
          lens_material_id: this.resep.lensa.id, // kategori
          // frame_type_id: this.resep.framecategory.id, // kategori
          // frame_varian_id: this.resep.frame.barang.id, // barang
        };
        // let submitaksesoris = [];
        // this.aksesoris.map((jb) => {
        //   if (jb.barang.nama.match(/lap/gi) && this.aksesorisadd.lap == "lap") {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "lap",
        //     });
        //   }
        //   if (
        //     jb.barang.varian.match(/cleaner jadi/gi) &&
        //     this.aksesorisadd.lensCleaner == "cleaner"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "lens_cleaner",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/garansi/gi) &&
        //     this.aksesorisadd.kartuGaransi == "garansi"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "kartu_garansi",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/resep/gi) &&
        //     this.aksesorisadd.kartuResep == "resep"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "kartu_resep",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/voucher/gi) &&
        //     this.aksesorisadd.kartuVoucher == "voucher"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: 1,
        //       type: "kartu_voucher",
        //     });
        //   }
        //   if (
        //     jb.barang.varian.match(/plastik/gi) &&
        //     this.aksesorisadd.case == "plastik"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: this.aksesorisadd.plastikqty
        //         ? this.aksesorisadd.plastikqty
        //         : 1,
        //       type: "case",
        //     });
        //   }
        //   if (
        //     jb.barang.nama.match(/metal/gi) &&
        //     this.aksesorisadd.case == "metal"
        //   ) {
        //     submitaksesoris.push({
        //       penyimpanan_id: jb.id,
        //       nama: jb.barang.nama + " - " + jb.barang.varian,
        //       qty: this.aksesorisadd.metalqty ? this.aksesorisadd.metalqty : 1,
        //       type: "case",
        //     });
        //   }
        // });
        // submit.accessories = submitaksesoris;
        this.wh = submit;
        this.showModal = true;
        // console.log('SUBMIT', submit)
        // submitaksesoris = []
        //   this.$swal({
        //     title: "Anda yakin?",
        //     html: `
        //   <strong>No Invoice:</strong> ${submit.no_invoice} <br/>
        //   <strong>Name:</strong> ${submit.name} <br/>
        //   <strong>Date:</strong> ${submit.date} <br/>
        //   <strong>Items:</strong> <br/>
        //   ${submit.items.map(item => `<strong>Item:</strong> ${item.penyimpanan_id}, Qty: ${item.qty}`).join('<br/>')} <br/>
        //   <strong>Prescription Right:</strong> <br/>
        //   Sph: ${submit.prescription_right.sph}, Cyl: ${submit.prescription_right.cyl}, Axis: ${submit.prescription_right.axis}, Add: ${submit.prescription_right.add}, PD: ${submit.prescription_right.pd} <br/>
        //   <strong>Prescription Left:</strong> <br/>
        //   Sph: ${submit.prescription_left.sph}, Cyl: ${submit.prescription_left.cyl}, Axis: ${submit.prescription_left.axis}, Add: ${submit.prescription_left.add}, PD: ${submit.prescription_left.pd} <br/>
        //   <strong>Accessories:</strong> <br/>
        //   ${submit.accessories.map(acc => `<strong>${acc.nama}</strong> (${acc.type}), Qty: ${acc.qty}`).join('<br/>')}
        // `,
        //     icon: 'info',
        //     showCancelButton: true,
        //     confirmButtonText: "Ya",
        //     cancelButtonText: "Batal",
        //     customClass: {
        //       confirmButton: "btn btn-success",
        //       cancelButton: "btn btn-danger ml-1",
        //     },
        //     buttonsStyling: false,
        //   }).then((result) => {
        //     if (result.value) {
        // } else if (result.dismiss === 'cancel') {
        //   this.$swal({
        //     title: 'Cancelled',
        //     text: 'Your imaginary file is safe :)',
        //     icon: 'error',
        //     customClass: {
        //       confirmButton: 'btn btn-success',
        //     },
        //   })
        // }
        // })
      } else {
        this.displayInfo({
          message: "Form resep harap dilengkapi",
        });
        this.loadingsubmitresep = false;
        return false;
      }
    },
    async oklensa() {
      this.loadingsubmitresep = true;
      this.$store
        .dispatch("pesanan/saveResep", this.wh)
        .then((response) => {
          this.displaySuccess({
            text: "Kartu resep berhasil disimpan",
          });
          //CUT OFF
          // let cut = {
          //   no_invoice: this.wh.no_invoice,
          //   order_type: "ginee",
          // };
          // this.$store
          //   .dispatch("pesanan/cutoff", [cut])
          //   .then(() => {
          //     this.displaySuccess({
          //       text: "Cutoff pesanan berhasil disimpan",
          //     });
          //   })
          //   .catch((e) => {
          //     this.loadingsubmitresep = false;
          //     this.displayError(e);
          //     return false;
          //   });
          // console.log('RESPON', response)
          this.printResep2(response);
          this.getResep(this.wh.no_invoice);
          this.getlensa();
          this.loadingsubmitresep = false;
        })
        .catch((e) => {
          this.loadingsubmitresep = false;
          this.displayError(e);
          return false;
        });
    },
    async submitnonlensa() {
      this.wh = null;
      this.wh = [];
      this.showmodalnonlensa = false;
      if (this.accadd.length > 0) {
        let itemmm = [];
        this.accadd.map((barang) => {
          itemmm.push({
            penyimpanan_id: barang.id,
            barang: barang
              ? barang.barang.nama + " - " + barang.barang.varian
              : null,
            qty: 1,
          });
        });
        let submit = {
          no_invoice: this.customer.externalOrderId,
          items: itemmm,
        };
        let submitaksesoris = [];
        this.aksesoris.map((jb) => {
          if (
            jb.barang.nama.match(/garansi/gi) &&
            this.aksesorisadd.kartuGaransi == "garansi"
          ) {
            submitaksesoris.push({
              penyimpanan_id: jb.id,
              nama: jb.barang.nama + " - " + jb.barang.varian,
              qty: 1,
              type: "kartu_garansi",
            });
          }
          if (
            jb.barang.nama.match(/voucher/gi) &&
            this.aksesorisadd.kartuVoucher == "voucher"
          ) {
            submitaksesoris.push({
              penyimpanan_id: jb.id,
              nama: jb.barang.nama + " - " + jb.barang.varian,
              qty: 1,
              type: "kartu_voucher",
            });
          }
          if (
            jb.barang.varian.match(/plastik/gi) &&
            this.aksesorisadd.case == "plastik"
          ) {
            submitaksesoris.push({
              penyimpanan_id: jb.id,
              nama: jb.barang.nama + " - " + jb.barang.varian,
              qty: this.aksesorisadd.plastikqty
                ? this.aksesorisadd.plastikqty
                : 1,
              type: "case",
            });
          }
          if (
            jb.barang.nama.match(/metal/gi) &&
            this.aksesorisadd.case == "metal"
          ) {
            submitaksesoris.push({
              penyimpanan_id: jb.id,
              nama: jb.barang.nama + " - " + jb.barang.varian,
              qty: this.aksesorisadd.metalqty ? this.aksesorisadd.metalqty : 1,
              type: "case",
            });
          }
        });
        submit.accessories = submitaksesoris;
        this.wh = submit;
        this.showmodalnonlensa = true;
        // console.log('aaaaaa', this.wh)
      } else {
        this.displayInfo({
          message: "Form resep harap dilengkapi",
        });
        // this.loadingsubmitresep = false
        return false;
      }
    },
    async oknonlensa() {
      this.loadingsubmitresep = true;
      this.$store
        .dispatch("pesanan/saveWarehouse", submit)
        .then((response) => {
          this.displaySuccess({
            text: "Data produk berhasil diserahkan ke warehouse",
          });
          // console.log('RESPON', response)
          this.printResep2(response);
          // this.getResep(submit.no_invoice)
          // this.getlensa();
          this.loadingsubmitresep = false;
        })
        .catch((e) => {
          this.loadingsubmitresep = false;
          this.displayError(e);
          return false;
        });
    },
    printResep2(item) {
      let params = item.id;
      // console.log('CEK', params)
      this.$store
        .dispatch("pesanan/printResep", params)
        .then((response) => {
          window.open(response.link, "_blank");
          // console.log('CEK', response.datali)
          // this.loadingresep = false
        })
        .catch((e) => {
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
    },
    getResep(item) {
      this.loadingresep = true;
      let payload = {
        no_invoice: item,
        order: "asc",
      };
      this.$store
        .dispatch("pesanan/getResep", payload)
        .then((response) => {
          this.dataresep = response.data.data;
          // console.log('get RESEP', response.data)
          // let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
          // this.classOptions = items
          // this.classOptions.map((barang) => {
          //   barang.text = barang.kelas;
          //   barang.value = barang.id;
          // });
          this.loadingresep = false;
        })
        .catch((e) => {
          this.loadingresep = false;
          this.displayError(e);
          return false;
        });
    },
    getDataKelas() {
      this.loading = true;
      this.$store
        .dispatch("kelas/getData")
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
          this.classOptions = items;
          this.classOptions.map((barang) => {
            barang.text = barang.kelas;
            barang.value = barang.id;
          });
          this.loading = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    //accs
    toggleSelectAll() {
      this.itemsacc.forEach((item) => {
        item.selected = this.selectAll;
      });
    },
    getProductOptions(index) {
      // Return different product options for each row
      return this.productsList[index].map((product) => ({
        value: product,
        text: product,
      }));
    },
    getImageUrl(channel) {
      // Mengembalikan path gambar dari channel yang diberikan
      return require(`@/assets/images/svg/${channel}.svg`);
    },
    selectAll(event) {
      if (event) {
        // Jika checkbox "All" dipilih, kosongkan model
        // this.storemodel = [];
        // this.storemodel = null;
        this.getGinee();
      }
    },
    toggleAll(checked) {
      console.log("cek", checked);
      // this.storemodel = checked ? this.storeOptions.slice() : []
    },
    getlensa(item) {
      this.lensa = item;
      // this.modalLensa = true;
    },
    async getInfo(val) {
      // let payload = {}
      let payload = {
        orderId: val.orderId,
      };
      // console.log('CEK payload', val)
      await this.$store
        .dispatch("ginee/getinfo", payload)
        .then((response) => {
          let item = response.data;
          this.resep.lensClass = {
            value: item.lens_class ? item.lens_class.id : null,
            text: item.lens_class ? item.lens_class.kelas : null,
            id: item.lens_class ? item.lens_class.id : null,
          };
          this.resep.lensa = {
            value:  item.lens_material ? item.lens_material.id : null,
            text: item.lens_material ?  item.lens_material.kategori : null,
            id: item.lens_material ?  item.lens_material.id : null,
          };
          this.resep.framecategory = {
            value: item.frame_type ? item.frame_type.id : null,
            text: item.frame_type ? item.frame_type.kategori : null,
            id: item.frame_type ? item.frame_type.id : null,
          };
          this.lensasearch = item.right ? item.right : null;
          this.lensasearchleft = item.left ? item.left : null;
          this.getDataBarang();
          this.getDataBarangLeft();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getCustomerFrame(item) {
      // this.getFrame(item)
      this.customer = item;
      this.resep = {
        name: item ? item["customerName"] : null,
        date: new Date().toISOString().substr(0, 10),
        lensClass: this.classOptions[0],
        framecategory: this.categoryframeOptions[0],
      };
      // this.modalframeacc = true;
      // if (!item.productName.match(/lensa/gi) && !item.productName.match(/pembersih/gi)) {

      // }
    },
    getCustomer2(item) {
      // this.getFrame(item)
      this.customer = item;
      this.resep = {
        name: item ? item["customerName"] : null,
        date: new Date().toISOString().substr(0, 10),
        lensClass: this.classOptions[0],
        framecategory: this.categoryframeOptions[0],
      };
      // if (!item.productName.match(/lensa/gi) && !item.productName.match(/pembersih/gi)) {

      // }
    },
    getCustomer(item) {
      this.getFrame(item);
      this.getlensafix(item);
      this.customer = item;
      this.resep = {
        name: item ? item["customerName"] : null,
        date: new Date().toISOString().substr(0, 10),
        lensClass: this.classOptions[0],
        framecategory: this.categoryframeOptions[0],
      };
      // if (!item.productName.match(/lensa/gi) && !item.productName.match(/pembersih/gi)) {

      // }
    },
    getDataKategorifix() {
      this.loadingkategorilensa = true;
      let payload = {
        kelas_id: this.resep.lensClass ? this.resep.lensClass.value : null,
      };
      this.$store
        .dispatch("kategori/getData", payload)
        .then((response) => {
          let res = response;
          // let akses = [];
          // res.map((jb) => {
          //   if (
          //     jb.master_kategori.master_kategori.match(/Lensa/gi) ||
          //     jb.master_kategori.master_kategori.match(/lensa/gi)
          //   ) {
          //     this.categorylensaOptions.push(jb);
          //   } else {
          //     return false;
          //   }
          // });
          this.categorylensaOptions = res;
          // this.categoryOptions = this.$store.state.kategori.datas
          this.categorylensaOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          this.loadingkategorilensa = false;
        })
        .catch((e) => {
          this.loadingkategorilensa = false;
          console.error(e);
        });
    },
    async getDataKategori() {
      await this.$store
        .dispatch("kategori/getData")
        .then((response) => {
          let res = response;
          let akses = [];
          res.map((jb) => {
            // if (
            //   jb.master_kategori.master_kategori.match(/Lensa/gi) ||
            //   jb.master_kategori.master_kategori.match(/lensa/gi)
            // ) {
            //   this.categorylensaOptions.push(jb);
            // }
            if (
              jb.master_kategori &&
              jb.master_kategori.master_kategori &&
              (jb.master_kategori.master_kategori.match(/Frame/gi) ||
                jb.master_kategori.master_kategori.match(/frame/gi))
            ) {
              this.categoryframeOptions.push(jb);
            }
            if (
              jb.master_kategori &&
              jb.master_kategori.master_kategori &&
              (jb.kategori.match(/pkg - lens cleaner/gi) ||
                jb.kategori.match(/kartu/gi) ||
                jb.kategori.match(/pkg - lap/gi) ||
                jb.kategori.match(/cepuk/gi) ||
                jb.kategori.match(/case/gi) ||
                jb.kategori.match(/voucher/gi))
            ) {
              this.categoryaksesorisOptions.push(jb);
              akses.push(jb.id);
            } else {
              return false;
            }
          });
          // this.categoryOptions = this.$store.state.kategori.datas
          this.categorylensaOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          this.categoryframeOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          this.categoryaksesorisOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          if (this.categoryaksesorisOptions.length > 1) {
            this.getDataAksesoris(akses);
          }
        })
        .catch((e) => console.error(e));
    },
    async getDataAksesoris(akses) {
      const payload = {
        kategori_id: akses != null ? akses.join(",") : null,
        blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,

        // search: 'XIOMI'
      };
      await this.$store
        .dispatch("tokopenyimpanan/getData", payload)
        .then((response) => {
          // let res = response
          this.aksesoris = response;
          this.loading = false;
        })

        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getDataBarangFrame() {
      await this.$store.dispatch("kategori/getData", {}).then((response) => {
        let kategoris = response;
        // console.log('HEMKEMKEMN', kategoris)
        //   const requiredKeys = ['admin_gudang', 'admin_asset', '']
        // kategoris = kategoris || [];

        kategoris.map((jb) => {
          if (
            jb.kategori.match(/frame/gi)
            //   jb.kategori.match(/aset/gi) ||
            //   jb.kategori.match(/asset/gi)
          ) {
            this.kategori_ids = this.kategori_ids || [];
            this.kategori_ids.push(jb.id);
          }
        });
        this.customer.items.map((jb2) => {
          if (
            // jb2.kategori.match(/lensa/gi) ||
            // jb2.kategori.match(/pembersih/gi)
            jb2.sku.match(/F/gi)
          ) {
            this.kategori_search = this.kategori_search || [];
            this.kategori_search.push(jb2.sku);
          }
        });
        // console.log('JIJIJIJ', this.kategori_search)
      });
      // this.getdatabarangframeasli()
      await this.getFrame();
    },
    async getdatabarangframeasli() {
      this.loading = true;
      const payload = {
        search:
          this.kategori_search != null ? this.kategori_search.join(",") : null,
        no_batch:
          this.kategori_search != null ? this.kategori_search.join(",") : null,
        blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,
        // order: this.sortBy,
        // start: currentPage,
        // length: this.perPage,
        // filter_by: "nama_lengkap",
        // kategori_id: this.kategori_ids ? this.kategori_ids.join(',') : null,
      };
      this.$store
        .dispatch("tokopenyimpanan/getData", payload)
        .then((response) => {
          let items = response;
          // let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          //   let items_total = this.$store.state.barang.totals;
          this.penyimpananOptions = items;
          //   this.totalRows = items_total
          this.penyimpananOptions.map((barang) => {
            barang.value = barang.id;
            barang.text =
              barang.nama +
              " - " +
              barang.varian_item[0].varian +
              " - Stok (" +
              barang.jumlah +
              ")";
            // barang.harga_beli = 0;
            // barang.isChangePrice = false;
            // barang.harga_dasar_baru = 0;
          });
          this.loading = false;
          this.kategori_search = null;
        });
    },

    async getDataBarang() {
      this.loadinglensa = true;
      this.progressValue = 0;

      const payload = {
        kategori_id: this.resep?.lensa?.value || null,
        kelas_id: this.resep?.lensClass?.value || null,
        blok_id: this.blok_id?.[0]?.value || null,
        main_sku: this.skulensa ? this.skulensa.join(",") : null,
        search: this.lensasearch || null,
      };

      const estimatedTime = 5000; // Estimasi waktu API dalam milidetik (misal 5 detik)
      const intervalTime = 100; // Interval pembaruan progress (100ms)

      const interval = setInterval(() => {
        this.progressValue += (intervalTime / estimatedTime) * 100;
        if (this.progressValue >= 95) {
          clearInterval(interval); // Stop saat progress mendekati selesai
        }
      }, intervalTime);

      try {
        const startTime = performance.now(); // Waktu mulai
        const response = await this.$store.dispatch(
          "tokopenyimpanan/getData",
          payload
        );
        const endTime = performance.now(); // Waktu selesai
        const actualTime = endTime - startTime; // Durasi aktual

        console.log(`Durasi API: ${actualTime} ms`);

        // Sinkronkan progress ke 100% jika API selesai
        this.progressValue = 100;
        clearInterval(interval);

        // Proses data respons
        this.localBarangsOptions = response.map((barang) => ({
          value: barang.id,
          text: `Stok (${barang.jumlah}) - ${barang.barang.varian_item[0].varian}`,
          ...barang,
        }));
        this.progressValue = 0;
      } catch (error) {
        console.error(error);
        this.displayError(error);
      } finally {
        this.loadinglensa = false; // Hentikan overlay
      }
    },

    async getDataBarangLeft() {
      this.loadinglensa = true;
      this.progressValue = 0;

      const payload = {
        kategori_id: this.resep?.lensa?.value || null,
        kelas_id: this.resep?.lensClass?.value || null,
        blok_id: this.blok_id?.[0]?.value || null,
        main_sku: this.skulensa ? this.skulensa.join(",") : null,
        search: this.lensasearchleft || null,
      };

      const estimatedTime = 5000; // Estimasi waktu API dalam milidetik (misal 5 detik)
      const intervalTime = 100; // Interval pembaruan progress (100ms)

      const interval = setInterval(() => {
        this.progressValue += (intervalTime / estimatedTime) * 100;
        if (this.progressValue >= 95) {
          clearInterval(interval); // Stop saat progress mendekati selesai
        }
      }, intervalTime);

      try {
        const startTime = performance.now(); // Waktu mulai
        const response = await this.$store.dispatch(
          "tokopenyimpanan/getData",
          payload
        );
        const endTime = performance.now(); // Waktu selesai
        const actualTime = endTime - startTime; // Durasi aktual

        // console.log(`Durasi API: ${actualTime} ms`);

        // Sinkronkan progress ke 100% jika API selesai
        this.progressValue = 100;
        clearInterval(interval);

        // Proses data respons
        this.localBarangsOptionsleft = response.map((barang) => ({
          value: barang.id,
          text: `Stok (${barang.jumlah}) - ${barang.barang.varian_item[0].varian}`,
          ...barang,
        }));
        this.progressValue = 0;
      } catch (error) {
        console.error(error);
        this.displayError(error);
      } finally {
        this.loadinglensa = false; // Hentikan overlay
      }
    },
    getFrame(item) {
      let hem2 =[]
//       if (Array.isArray(item.items)) {
  hem2 = item.items;
// }
      let hem3 = [];
      hem2.map((jb2) => {
        if (
          // jb2.kategori.match(/lensa/gi) ||
          // jb2.kategori.match(/pembersih/gi)
          jb2.sku.match(/F/gi) &&
          !jb2.sku.match(/lns/gi) &&
          !jb2.sku.match(/rn/gi)
        ) {
          // hem3 = []
          hem3.push(jb2.sku);
        }
      });

      let aaa = [];
      // this.loading = true;
      hem3.map((barang) => {
        // barang.sku = barang.sku;
        aaa.push(barang.sku);
      });

      // console.log("hem2", hem2);
      // console.log("hem3", hem3);
      // let hem2 = [hem.items.sku]
      const payload = {
        main_sku: hem3 != null ? hem3.join(",") : null,
        blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,
        // search: 'XIOMI'
      };
      this.$store
        .dispatch("tokopenyimpanan/getData", payload)
        .then((response) => {
          let items = response;
          // let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          //   let items_total = this.$store.state.barang.totals;
          this.penyimpananOptions = items;
          //   this.totalRows = items_total
          this.penyimpananOptions.map((barang) => {
            barang.value = barang.id;
            barang.text =
              barang.barang.nama +
              " - " +
              barang.barang.varian_item[0].varian +
              " - Stok (" +
              barang.jumlah +
              ")";
            // barang.harga_beli = 0;
            // barang.isChangePrice = false;
            // barang.harga_dasar_baru = 0;
          });
          if (this.penyimpananOptions.length === 1) {
            this.resep.frame = this.penyimpananOptions[0];
            this.resep.framecategory = {
              value: this.resep.frame.barang.kategori.id,
              text: this.resep.frame.barang.kategori.kategori,
            };
          } else if (this.penyimpananOptions.length > 1) {
          }
          this.loading = false;
        })

        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    getlensafix(item) {
      let hem2 = []
//       if (Array.isArray(item.items)) {
  hem2 = item.items;
// }
      let hem3 = [];
      hem2.map((jb2) => {
        if (
          // jb2.kategori.match(/lensa/gi) ||
          // jb2.kategori.match(/pembersih/gi)
          (!jb2.sku.match(/F/gi) && jb2.sku.match(/LNS/gi)) ||
          jb2.masterSku.match(/LNS/gi) ||
          jb2.masterSku.match(/Prog/gi) ||
          jb2.masterSku.match(/CR/gi) ||
          jb2.masterSku.match(/CYL/gi)
        ) {
          // hem3 = []
          hem3.push(jb2.sku);
        }
      });

      this.skulensa = hem3;

      console.log("LENSAAAAA", hem3);
      // let aaa = [];
      // this.loading = true;
      // hem3.map((barang) => {
      //   // barang.sku = barang.sku;
      //   aaa.push(barang.sku);
      // });

      // // let hem2 = [hem.items.sku]
      // const payload = {
      //   no_batch: hem3 != null ? hem3.join(",") : null,
      //   blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,
      //   // search: 'XIOMI'
      // };
      // this.$store
      //   .dispatch("tokopenyimpanan/getData", payload)
      //   .then((response) => {
      //     let items = response;
      //     // let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
      //     //   let items_total = this.$store.state.barang.totals;
      //     this.penyimpananOptions = items;
      //     //   this.totalRows = items_total
      //     this.penyimpananOptions.map((barang) => {
      //       barang.value = barang.id;
      //       barang.text =
      //         barang.barang.varian_item[0].varian +
      //         " - Stok (" +
      //         barang.jumlah +
      //         ")";
      //       // barang.harga_beli = 0;
      //       // barang.isChangePrice = false;
      //       // barang.harga_dasar_baru = 0;
      //     });
      //     if (this.penyimpananOptions.length === 1) {
      //       this.resep.frame = this.penyimpananOptions[0];
      //     } else if (this.penyimpananOptions.length > 1) {
      //     }
      //     this.loading = false;
      //   })

      //   .catch((e) => {
      //     this.loading = false;
      //     this.displayError(e);
      //     return false;
      //   });
    },
    getAcc(hem) {
      // console.log('HEM', hem)
      let hem2 = hem.masterSku;
      // let hem3 = []
      // hem2.map(jb2 => {
      //   if (
      //     jb2.sku.match(/F/gi)
      //   ) {
      //     hem3.push(jb2.sku)
      //   }
      // })

      // console.log('Á', hem3)
      // let aaa = []
      // this.loading = true
      // hem3.map((barang) => {
      //   aaa.push(barang.sku);
      // });
      const payload = {
        no_batch: hem2 != null ? hem2 : null,
        blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,
        // search: 'XIOMI'
      };
      this.$store
        .dispatch("tokopenyimpanan/getData", payload)
        .then((response) => {
          let items = response;
          // let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          //   let items_total = this.$store.state.barang.totals;
          // this.accadd = items
          //   this.totalRows = items_total
          if (items.length > 0) {
            items.map((barang) => {
              this.accadd.push({
                value: barang.id,
                text:
                  barang.barang.varian_item[0].varian +
                  " - Stok (" +
                  barang.jumlah +
                  ")",
                barang: barang.barang,
                quantity: hem.quantity,
                stok: barang.jumlah,
              });
            });
          } else {
            this.displayInfo({
              message: `Produk ${hem.masterSku} belum terdata di WMS, silakan menambah barang tersebut`,
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    async getAksesoris() {
      // await this.$store.dispatch('kategori/getData', {})
      //   .then((response) => {
      //     let kategoris = response
      //     kategoris.map(jb => {
      //       if (
      //         jb.kategori.match(/aksesoris/gi)
      //         // ||
      //         // jb.kategori.match(/aksesoris/gi)
      //       ) {
      //         this.aksesoris = this.aksesoris || []
      //         this.aksesoris.push(jb.id);

      //         if (this.aksesoris.length > 0) {
      //           this.aksesoris.join(',')
      //         }

      //       }
      //     })
      //   })
      const payload = {
        kategori_id: this.aksesoris != null ? this.aksesoris.join(",") : null,
        blok_id: this.blok_id[0].value ? this.blok_id[0].value : null,
        // search: 'XIOMI'
      };
      this.$store
        .dispatch("tokopenyimpanan/getData", payload)
        .then((response) => {
          let res = response;
          res.map((jb) => {
            if (
              jb.master_kategori.master_kategori.match(/Lensa/gi) ||
              jb.master_kategori.master_kategori.match(/lensa/gi)
            ) {
              this.categorylensaOptions.push(jb);
            }
            if (
              jb.master_kategori.master_kategori.match(/Frame/gi) ||
              jb.master_kategori.master_kategori.match(/frame/gi)
            ) {
              this.categoryframeOptions.push(jb);
            }
            if (
              jb.master_kategori.master_kategori.match(/Aksesoris/gi) ||
              jb.master_kategori.master_kategori.match(/aksesoris/gi)
            ) {
              this.categoryaksesorisOptions.push(jb);
              // this.aksesoris.push(jb.id)
            } else {
              return false;
            }
          });
          // this.categoryOptions = this.$store.state.kategori.datas
          this.categorylensaOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          this.categoryframeOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          this.categoryaksesorisOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          // this.aksesorisOptions = items
          // this.aksesorisOptions.map((barang) => {
          //   barang.value = barang.barang.id;
          //   barang.text = barang.barang.varian_item[0].varian;
          // });
          // if (this.penyimpananOptions.length === 1) {
          //   this.resep.frame = this.penyimpananOptions[0]
          // } else if (this.penyimpananOptions.length > 1) {
          // }
          this.loading = false;
        })

        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
    submitkonfirmasi2() {
      this.label = "Loading...";
      let payload2 = {
        no_invoice: this.formkonfirmasi.no_invoice,
      };
      this.$store
        .dispatch("transaksiOnline/tandaiLunas", payload2)
        .then(() => {
          this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil dikonfirmasi",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.label = "Submit";
          this.displayError(e);
          return false;
        });
      //   }
      // });
    },
    submitkonfirmasi() {
      // this.$refs.vkonfirmasi.validate().then((success) => {
      //   if (success) {
      this.label = "Loading...";
      // let join = this.pilihteritori;
      // let string = join.join(",");
      // var array = JSON.parse("[" + string + "]");
      let payload = {
        no_invoice: this.formkonfirmasi.no_invoice,
        // nama_cabang: this.form.nama_cabang,
        akun_id: this.formkonfirmasi.id_akun,
        kas_id: this.formkonfirmasi.id_kas,
      };
      let payload2 = {
        no_invoice: this.formkonfirmasi.no_invoice,
      };
      this.$store
        .dispatch("transaksiOnline/tandaiLunas", payload2)
        .then(() => {
          this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil dikonfirmasi",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.label = "Submit";
          this.displayError(e);
          return false;
        });
      //   }
      // });
    },
    aksiKonfirmasiawal(item) {
      this.konfirmasi = item.id;
      this.formkonfirmasi = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
        bukti_tf: {
          file: item.bukti_tf ? item.bukti_tf.file : null,
        },
      };
      // this.submitkonfirmasi2()
      // console.log('AJI', this.foto)
      this.getTransaksiId();
      this.showKonfirmasi = true;
    },
    aksiKonfirmasipg(item) {
      // this.konfirmasi = item.id;
      // this.formkonfirmasi = {
      //   no_invoice: item.no_invoice ? item.no_invoice : "-",
      //   bukti_tf: {
      //     file: item.bukti_tf ? item.bukti_tf.file : null,
      //   },
      // };
      let payload = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
      };
      // this.$swal({
      //   title: "Anda yakin?",
      //   text: `Invoice : ${terapis.nama_lengkap} ini ditandai lunas?`,
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "Ya",
      //   cancelButtonText: "Batal",
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //     cancelButton: "btn btn-danger ml-1",
      //   },
      //   buttonsStyling: false,
      // }).then((result) => {
      //   if (result.value) {
      this.$store
        .dispatch("transaksiOnline/getDatapg", payload)
        .then((res) => {
          // this.formkonfirmasi = res;
          this.getTransaksi();
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
      //   }
      // });
    },
    aksiKonfirmasi(item) {
      this.konfirmasi = item.id;
      this.formkonfirmasi = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
        bukti_tf: {
          file: item.bukti_tf ? item.bukti_tf.file : null,
        },
      };
      this.submitkonfirmasi2();
      // console.log('AJI', this.foto)
      // this.getTransaksiId();
      // this.showKonfirmasi = true;
    },
    getTransaksiId(item) {
      this.loadingfoto = true;
      let params = {
        id: this.konfirmasi,
        show_bukti_tf: 1,
        view_as_invoice: 1,
      };
      this.$store
        .dispatch("transaksiOnline/getDataById", params)
        .then((res) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.transaksiOnline.datas));
          // let items_total = this.$store.state.transaksiOnline.totals;
          this.formkonfirmasi = res;
          // this.totalRows = items_total;
          this.loadingfoto = false;
          // this.items = res;
        })
        .catch((err) => {
          // console.error(err);
          this.loadingfoto = false;
          this.displayError(err);
          return false;
        });
    },
    async exportSales({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir,
        type_export,
      };
      if (this.isSales && this.myGudang)
        params.sales_id =
          this.user && this.user.karyawan ? this.user.karyawan.id : null;
      if (this.dataExport.konsumen_id)
        params.konsumen_id = this.dataExport.konsumen_id;
      if (this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id;

      try {
        this.loading = true;
        const response = await this.printSales(params);
        this.loading = false;
        if (type_export == "pdf") {
          window.open(URL.createObjectURL(response));
        } else {
          await window.open(response);
          setTimeout(async () => {
            // delete selected file
            const arrFile = response.split("/");
            const filename = arrFile[arrFile.length - 1];

            await this.clearExportedFile(filename);
          }, 1000);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },

    getTransaksi() {
      this.loading = true;
      // this.refreshToken()
      if (!this.isCabang && !this.isTerapis) {
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        var params = {
          search: this.filter != null ? this.filter : null,
          // order: "desc",
          start: currentPage,
          length: this.perPage,
          // metode_bayar: "manual_transfer",
          show_bukti_tf: 1,
          status_bayar: this.status_bayar != null ? this.status_bayar : null,
          status: this.statuskirim != null ? this.statuskirim : null,
          member_id: this.is_member != null ? this.is_member : null,
          is_accounted: this.is_accounted != null ? this.is_accounted : null,
        };
      } else if (this.isTerapis) {
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        var params = {
          search: this.filter != null ? this.filter : null,
          terapis: this.user.id,
          // order: "desc",
          start: currentPage,
          length: this.perPage,
          status_bayar: this.status_bayar != null ? this.status_bayar : null,
          status: this.statuskirim != null ? this.statuskirim : null,
          type_transaction:
            this.typeTransaksi != null ? this.typeTransaksi : null,
          // metode_bayar: "manual_transfer",
        };
      } else {
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        var params = {
          search: this.filter != null ? this.filter : null,
          member_id: this.user.member_id,
          // order: "desc",
          start: currentPage,
          length: this.perPage,
          status_bayar: this.status_bayar != null ? this.status_bayar : null,
          status: this.statuskirim != null ? this.statuskirim : null,
          type_transaction:
            this.typeTransaksi != null ? this.typeTransaksi : null,
          // metode_bayar: "manual_transfer",
        };
      }
      this.$store
        .dispatch("transaksiOnline/getData", params)
        .then((res) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.transaksiOnline.datas)
          );
          let items_total = this.$store.state.transaksiOnline.totals;
          let items_masuk = this.$store.state.transaksiOnline.masuks;
          this.items = items;
          this.totalRows = items_total;
          this.masuk = items_masuk;
          this.loading = false;
          // this.items = res;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          hem.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getAkun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akuns.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
          this.kreditAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getMember() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        sebagai: "cabang",
        // order: "desc",
        // start: currentPage,
        // length: this.perPage,
        // filter_by: "nama_lengkap",
        // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
        // user_id: this.user.id,
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        items.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.optis_member = items;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      });
    },
    getTerapis() {
      this.$store
        .dispatch("terapis/getData")
        .then((res) => {
          this.listTerapis = res;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
        });
    },
    getGinee() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;

      const payload = {
        productType: this.productType ? this.productType : null,
        logisticNames:
          this.logisticNames.length > 0 ? this.logisticNames : null,
        channel: this.channelmodel ? this.channelmodel : null,
        shopIds: this.storemodel != null ? this.storemodel : null,
        orderNumbers: this.filter ? this.filter : null,
        orderStatus: this.statusordermodel ? this.statusordermodel : null,
        start_date: this.start_date ? this.start_date : null,
        end_date: this.end_date ? this.end_date : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        labelStatus: this.statuslabel ? this.statuslabel : null,
        productName: this.namaproduk ? this.namaproduk : null,
        sortBy: this.sortBy ? this.sortBy : "creationTimeLatest",
      };
      // payload.channel = [this.channel]
      this.loading = true;
      this.$store
        .dispatch("ginee/getData", payload)
        .then((res) => {
          this.ginee = res.data.data;
          this.sortOptions = res.data.sortOptions;
          this.totalRows = res.data.recordsTotal;
          if (this.aksesoris == null) {
            this.getDataKategori();
          }
          this.loading = false;
          // this.getGineeChannel()
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    getGineeChannel() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      // const payload = {
      //   search: this.filter != null ? this.filter : null,
      //   order: "desc",
      //   start: currentPage,
      //   length: this.perPage,
      // };
      this.loading = true;
      this.$store
        .dispatch("ginee/getChannel")
        .then((res) => {
          // console.log(res.data.data)
          this.channelOptions = res.data.data;
          // this.ginee = res.data.data;
          // this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    getGineeStore() {
      this.loading = true;
      this.$store
        .dispatch("ginee/getShop")
        .then((res) => {
          // console.log(res.data.data)
          this.storeOptions = res.data.data;
          this.storeOptions.map((barang) => {
            barang.text = barang.channel + " - " + barang.name;
            barang.value = barang.shopId;
          });
          // this.ginee = res.data.data;
          // this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    getGineeStatus() {
      this.loading = true;
      this.$store
        .dispatch("ginee/getStatusOrder")
        .then((res) => {
          // console.log(res.data.data)
          this.statusorderOptions = res.data.data;
          // this.ginee = res.data.data;
          // this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    getGineeLogistic() {
      this.loading = true;
      this.$store
        .dispatch("ginee/getLogistic")
        .then((res) => {
          // console.log(res.data.data)
          this.logisticNamesOptions = res.data.data;
          // this.ginee = res.data.data;
          // this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    modalTerapis(item) {
      this.itemTransaksiTerapis = item;
      this.showModalTerapis = true;
    },
    deleteresep(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Menghapus resep ini`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingsubmitresep = true;
          let payload = {
            id: item.id,
          };
          this.$store
            .dispatch("pesanan/deleteResep", [payload])
            .then(() => {
              this.displaySuccess({
                text: "Hapus Resep berhasil",
              });
              this.loadingsubmitresep = false;
              // this.showModalTerapis = false;
              // this.itemTransaksiTerapis = {};
              this.getlensa();
              // this.getCustomer2();
              this.getResep(item.no_invoice);
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    pilihTerapis(terapis) {
      this.$swal({
        title: "Anda yakin?",
        text: `Apakah yakin untuk memilih ${terapis.nama_lengkap}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingTerapis = true;
          let payload = {
            transaksi_id: this.itemTransaksiTerapis.id,
            karyawan_id: terapis.id,
          };
          this.$store
            .dispatch("transaksiOnline/saveTerapis", payload)
            .then(() => {
              this.displaySuccess({
                text: "Terapis Berhasil dipilih",
              });
              this.loadingTerapis = false;
              this.showModalTerapis = false;
              this.itemTransaksiTerapis = {};
              this.getTransaksi();
              this.getTerapis();
            })
            .catch((e) => {
              this.loadingTerapis = false;
              this.showModalTerapis = false;
              this.itemTransaksiTerapis = {};
              this.displayError(e);
              return false;
            });
        }
      });
    },
    getGineeTypeProduct() {
      this.$store
        .dispatch("ginee/getType")
        .then((res) => {
          // console.log(res.data.data)
          this.typeOptions = res.data;
          // this.ginee = res.data.data;
          // this.totalRows = res.data.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching type product data:", error);
          this.loading = false;
        });
    },
  },
  async mounted() {
    // this.getGineeLogistic();
    // this.getGineeTypeProduct();
    // this.getDataKategori()
    this.ginee = JSON.parse(sessionStorage.getItem("itemChooselensa"));
    // if (this.ginee.length > 0) {
    //   this.selectedginee = this.ginee[0]; // Menetapkan item pertama sebagai terpilih
    // }
    this.getDatablok();
    this.getDataKelas();
    // this.getGineeStatus();
    // this.getGineeStore();
    // this.getPrintHistory();
    // this.getGineeChannel()
    // this.getGinee();
    // this.getDataAksesoris()
    // this.getAksesoris()
    // this.tigapuluhtodaydate();
    if (this.position.x > window.innerWidth - 100) {
      this.position.x = window.innerWidth - 100;
    }
    if (this.position.y > window.innerHeight - 100) {
      this.position.y = window.innerHeight - 100;
    }
    console.log("this.position.x", this.position.x);
  },
  async created() {
    // this.getKategoriAksesoris()

    // console.log("TGL", `${getdate}/${getMonth}/${date.getFullYear()}`)
    // this.getMember();
    // this.getTransaksi();
    // this.getKas();
    // this.getAkun();
    // this.getTerapis();
    if (!this.isFinance) {
      this.fields = [
        {
          key: "no",
          label: "No",
        },
        { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "member_id", label: "Affiliator" },
        { key: "nama", label: "Konsumen" },
        { key: "total_bayar", label: "Nominal" },
        { key: "transaction_type", label: "Tipe Transaksi" },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "action", label: "#" },
      ];
    } else if (this.isFinance) {
      this.fields = [
        { key: "actionFinance", label: "#", stickyColumn: true },
        {
          key: "no",
          label: "No",
        },
        { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "member_id", label: "Affiliator" },
        // { key: "nama", label: "Konsumen" },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "uang_masuk", label: "Uang Masuk" },
        { key: "total_bayar", label: "Total Bayar" },
        { key: "transaction_type", label: "Tipe Transaksi" },
        { key: "payment_option", label: "Metode Pembayaran" },
      ];
    }

    // this.getData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.floating-card {
  position: fixed;
  /* Fixed position to stick at the bottom */
  bottom: 0px;
  /* Adjust distance from bottom */
  left: 40%;
  /* Center horizontally */
  transform: translateX(-50%);
  width: 90%;
  /* Adjust width as needed */
  max-width: 400px;
  /* Optional max-width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Shadow effect for floating effect */
  border-radius: 8px;
  /* Rounded corners */
  background-color: white;
  z-index: 1000;
  /* Ensure it stays on top */
}
</style>
<style scoped>
.floating-full-width-card {
  position: fixed;
  bottom: 0;
  left: 240px;
  /* Adjust this to match the width of the sidebar */
  width: calc(100% - 240px);
  /* Full width minus the sidebar */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  /* Shadow for floating effect */
  background-color: white;
  z-index: 1000;
  padding: 15px;
  border-radius: 8px 8px 0 0;
  /* Rounded corners at the top */
}
</style>
<style scoped>
.controls {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.custom-position-card {
  padding: 15px;
}
</style>
<style scoped>
.draggable-card {
  padding: 15px;
  max-width: 100vw;
  /* Agar card tidak keluar dari lebar layar */
  max-height: 100vh;
  /* Agar card tidak keluar dari tinggi layar */
  border: 2px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0.9);
  /* Warna transparan untuk memastikan terlihat */
}
</style>
<!-- <style>
/* Optional styling for a more compact checkbox layout in the dropdown */
.v-select .vs__dropdown-option {
  padding: 0 !important;
}

.v-select .vs__selected-options {
  display: flex;
  flex-wrap: wrap;
}
</style> -->
