<template>
    <b-overlay :show="loading">
        <aset-modal @submit="onChooseBarang"></aset-modal>
        <b-row class="justify-content-center">
            <b-col cols="12" class="mb-2">
                <!-- Header Info -->
                <div v-if="canApprove" class="d-flex justify-content-end mb-1">
                    <b-button :variant="perbaikan.gm_approve == 1 ? `outline-secondary` : `outline-success`" class="mr-2" @click.prevent="approve(1)" :disabled="perbaikan && perbaikan.gm_approve == 1">Setujui Perbaikan</b-button>
                    <b-button :variant="perbaikan.gm_approve == 2 ? `outline-secondary` : `outline-danger`" @click.prevent="approve(2)" :disabled="perbaikan && (perbaikan.finance_approve == 1 || perbaikan.gm_approve == 2)">Tolak Perbaikan</b-button>
                </div>
                <div v-if="canPay" class="d-flex justify-content-end mb-1">
                    <b-button 
                        :variant="[0,2].includes(perbaikan.gm_approve) ? 'outline-secondary' : 'outline-primary'"
                        :disabled="[0,2].includes(perbaikan.gm_approve)"
                        @click.prevent="openFormAkun"
                    >Pilih Sumber Pengeluaran</b-button>
                </div>
                <b-card-actions style="overflow-x: auto" title="Info Perbaikan" action-collapse>
                    <table class="w-full table table-borderless table-responsive" border="1" style="overflow: auto">
                        <tr>
                            <td>No. Perbaikan</td>
                            <th>{{ perbaikan ? perbaikan.no : null }}</th>
                            <td>Judul</td>
                            <th>{{ perbaikan ? perbaikan.judul : null }}</th>
                        </tr>
                        <tr>
                            <td>Tanggal Perbaikan</td>
                            <th>{{ perbaikan ? perbaikan.tanggal : null }}</th>
                            <td>Keterangan</td>
                            <th>{{ perbaikan ? perbaikan.keterangan : null }}</th>
                        </tr>
                        <tr>
                            <td>Sudah disetujui GM?</td>
                            <th>
                                <b-badge :variant="perbaikan && perbaikan.gm_approve == 1 ? 'light-success' : 'light-danger'">{{ statusApproveGm }}</b-badge>
                            </th>
                            <td>Sudah disetujui Finance?</td>
                            <th>
                                <b-badge :variant="perbaikan && perbaikan.finance_approve != 0 ? 'light-success' : 'light-danger'">{{ statusApproveFinance }}</b-badge>
                            </th>
                        </tr>
                        <tr>
                            <td>Pembuat Ajuan</td>
                            <td>
                                <div v-html="infoCreator"></div>
                            </td>
                        </tr>
                    </table>
                </b-card-actions>
            </b-col>

            <b-col cols="12" class="mb-2">
                <div class="d-flex justify-content-between mb-1">
                    <h2 class="mb-1">Item Details
 <span v-if="rincians.length > 0">({{ rincians.length }})</span></h2>
                    <div>
                        <b-button variant="outline-primary" @click.prevent="completeRepair" v-if="selectedRincian.length > 0">Tandai Selesai Diperbaiki ({{ selectedRincian.length }})</b-button>
                    </div>
                </div>
                <b-card>
                    <b-button v-if="(allowCreate() && perbaikan && (perbaikan.gm_approve == 0 && perbaikan.finance_approve == 0))" variant="primary" @click.prevent="chooseBarang" class="mb-2">Add Barang</b-button>
                    <b-row class="mb-2">
                        <b-col sm="12" md="6">
                            <b-form-group class="mb-0">
                                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                                <b-form-select
                                    id="perPageSelect"
                                    v-model="perPage"
                                    size="sm"
                                    :options="pageOptions"
                                    class="w-50"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col sm="12" md="6">
                            <b-form-group
                            :label="$t('Filter')"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            label-for="filterInput"
                            class="mb-0"
                            >
                                <b-input-group size="sm">
                                    <b-form-input
                                    id="filterInput"
                                    v-model="filter"
                                    type="search"
                                    
                                    />
                                    <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-table striped responsive="" hover :items="rincians" :fields="fields" class="mb-2">
                        <template #head(checkbox)>
                            <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                        </template>
                        <template #cell(checkbox)="{item}">
                            <b-form-checkbox v-if="item.status == 0" v-model="selectedRincian" :value="item"></b-form-checkbox>
                            <feather-icon icon="CheckIcon" v-else class="text-success"></feather-icon>
                        </template>
                        <template #cell(no)="row">
                            {{ (row.index + 1) }}
                        </template>
                        <template #cell(nama)="{item}">
                            <span v-if="item.penyimpanan && item.penyimpanan.asset">
                                {{ item.penyimpanan.asset.nama }}
                            </span>
                            <i v-else class="text-danger">Aset tidak ditemukan</i>
                        </template>
                        <template #cell(satuan)="{item}">
                            {{item.jumlah}} {{ item.penyimpanan && item.penyimpanan.asset && item.penyimpanan.asset.satuan ? item.penyimpanan.asset.satuan.satuan : '-' }}
                        </template>
                        <template #cell(biaya)="{item}">
                            Rp {{ formatRupiah( parseInt(item.biaya)) }}
                        </template>
                        <template #cell(keterangan)="{item}">
                            <b-form-input v-model="item.keterangan"></b-form-input>
                        </template>
                        <template #cell(status)="{item}">
                            <b-badge :variant="item.status == 0 ? `light-danger` : 'light-success'">
                                {{item.status == 0 ? `Dalam Perbaikan` : 'Sudah Diperbaiki'}}
                            </b-badge>

                        </template>
                        <template #cell(action)="{index}">
                            <feather-icon v-if="(allowDelete() && (perbaikan.gm_approve == 0 && perbaikan.finance_approve == 0) )" class="cursor-pointer text-danger" @click="remove(index)" icon="MinusCircleIcon" size="24"></feather-icon>
                        </template>
                    </b-table>
                </b-card>
            </b-col>

            <b-col cols="12" v-if="(allowCreate() && perbaikan && (perbaikan.gm_approve == 0 && perbaikan.finance_approve == 0))">
                <div class="d-flex justify-content-end">
                    <b-button :disabled="rincians.length < 1" variant="primary" @click.prevent="submit">Simpan</b-button>
                </div>
            </b-col>

            <b-col cols="12" v-if="(canPay || canApprove)" class="text-right">
                <h2>
                    Total <strong class="text-danger">Rp {{ formatRupiah(totalPerbaikan) }}</strong>
                </h2>
            </b-col>
        </b-row>
        <form-akun @onSubmit="submitAkun"></form-akun>
    </b-overlay>
</template>
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue"
import AsetModal from './components/AsetModal.vue'
import FormAkun from './components/FormAkun.vue'
import {BButton, BFormCheckbox, BOverlay, BFormTextarea, BRow, BCol, BCard, BBadge, BTable, BFormInput, BFormSelect, BFormGroup, BInputGroup, BInputGroupAppend} from 'bootstrap-vue'
export default {
    components: {
        AsetModal, BCardActions, BButton, BFormCheckbox, BOverlay, BFormTextarea, BRow, BCol, BCard, BBadge, BTable, BFormInput, BFormSelect, BFormGroup, BInputGroup, BInputGroupAppend, FormAkun
    },
    computed: {
        infoCreator() {
            if(!this.perbaikan || !this.perbaikan.created_ket) {
                return `<i class="text-danger">Pembuat ajuan tidak ditemukan</i>`
            }

            return `<strong>${this.perbaikan.created_ket.nama_lengkap}</strong>`
        },
        totalPerbaikan() {
            if(!this.perbaikan) {
                return 0
            }

            return parseInt(this.perbaikan.total)
        },
        statusApproveGm() {
            if(!this.perbaikan || this.perbaikan.gm_approve == 0 ) {
                return 'Belum'
            }
            else if(this.perbaikan.gm_approve == 2) {
                return 'Ditolak GM'
            }
            else {
                return 'Disetujui'
            }
        },
        statusApproveFinance() {
            if(!this.perbaikan || this.perbaikan.finance_approve == 0 ) {
                return 'Belum'
            }
            else if(this.perbaikan.finance_approve == 2) {
                return 'Ditolak Finance'
            }
            else {
                return 'Disetujui'
            }
        },
    },
    watch: {
        allChecked(val) {
            if(val) {
                this.selectedRincian = Object.assign([], this.rincians.filter(item => item.status == 0))
            }
            else {
                this.selectedRincian = []
            }
        }
    },
    data: () => ({
        allChecked: false,
        selectedRincian: [],
        perPage: 10,
        totalRows: 0,
        currentPage: 1,
        pageOptions: [10, 30, 50],
        filter: null,
        loading: false,
        gudangInfo: null,
        rincians: [],
        fields: [
            {key: 'no', label: 'No', sortable: true},
            {key: 'nama', label: 'Nama Aset', sortable: true},
            {key: 'satuan', label: 'Satuan', sortable: true},
            {key: 'biaya', label: 'Biaya', sortable: true},
            {key: 'keterangan', label: 'Keterangan', sortable: true},
            {key: 'status', label: 'Status Aset'},
            {key: 'action', label: '#'}
        ],
        perbaikan: null,
        canApprove: false,
        canPay: false
    }),
    methods: {
        async submitAkun(payload) {
            try {
                this.loading = true
                const response = await this.$store.dispatch('asset/approvePerbaikan', [payload])
                this.displaySuccess({
                    message: response.message || 'Dana untuk perbaikan aset sudah dikeluarkan!'
                })
                this.getPerbaikan()
                this.$bvModal.hide('form-akun')
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        openFormAkun() {
            if(!this.perbaikan || [0,2].includes(this.perbaikan.gm_approve)) {
                this.displayError({
                    message: 'Ajuan perbaikan ini belum di setujui oleh GM!'
                })
                return false
            }
            
            this.$store.commit('asset/SET_CURRENT_TOTAL_PERBAIKAN', this.totalPerbaikan)
            this.$bvModal.show('form-akun')
        },
        async getKas() {
            const params = {}
            const kas = await this.$store.dispatch('kas/getData', params)
            const result = kas.map((item) => {
                item.value = item.id;
                const saldo = this.formatRupiah(item.saldo)
                item.text = `${item.nama} -> Rp ${saldo}`
                return { value: item.value, text: item.text, saldo: item.saldo }
            })

            this.$store.commit('asset/SET_DATA_KAS', result)
        },
        async getAkun() {
            const params = {}
            const akuns = await this.$store.dispatch('akun/getData', params)
            const result = []
            
            akuns.map((item) => {
                if( [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(item.jenis) ) {
                    item.value = item.id;
                    const saldo = this.formatRupiah(item.saldo)
                    item.text = `${item.nama} -> Rp ${saldo}`
                    result.push({ value: item.value, text: item.text, saldo: item.saldo })
                }
            })

            this.$store.commit('asset/SET_DATA_AKUN', result)
        },
        completeRepair() {
            if([0, 2].includes(this.perbaikan.gm_approve)) {
                this.displayError({
                    message: 'Manager belum mengizinkan perbaikan aset ini!'
                })
                return false
            }

            this.$swal({
                title: "Anda yakin?",
                text: `Aset-aset yang dipilih akan dikembalikan ke stok penyimpanannya.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Batal",
                customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false
            })
            .then(async res => {
                if(res.value) {
                    const payloads = this.selectedRincian.map(item => ({id: item.id}) )

                    try {
                        this.loading = true
                        const response = await this.$store.dispatch('asset/completePerbaikan', payloads)
                        this.loading = false
                        this.displaySuccess({
                            message: response.message
                        })
                        this.rincians = []
                        this.selectedRincian = []
                        await this.getRincianPerbaikan()
                        this.getPenyimpanan()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                    }
                }
            })


            // const rincian_ids = this.selectedRincian.map(item => item.id)
        },
        setField() {
            if(this.isAdminAsset && this.perbaikan.gm_approve == 1) {
                this.fields = [
                    {key: 'checkbox', label: '#', sortable: true},
                    {key: 'nama', label: 'Nama Aset', sortable: true},
                    {key: 'satuan', label: 'Satuan', sortable: true},
                    {key: 'biaya', label: 'Biaya', sortable: true},
                    {key: 'keterangan', label: 'Keterangan', sortable: true},
                    {key: 'status', label: 'Dalam Perbaikan'}
                ]
            }
        },
        approve(status) {
            this.$swal({
                title: "Anda yakin?",
                text: `Setelah ini perbaikan akan di proses lebih lanjut.`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Batal",
                customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false
            })
            .then(async res => {
                if(res.value) {
                    const payload = {
                        id: this.$route.params.id,
                        aktor: 'gm',
                        status
                    }

                    this.loading = true
                    try {
                        const response = await this.$store.dispatch('asset/approvePerbaikan', [payload])
                        this.displaySuccess({
                            message: response.message
                        })
                        this.loading = false
                        this.getPerbaikan()
                    }
                    catch(e) {
                        this.loading = false
                        this.displayError(e)
                        return false
                        
                    }
                }
            })
        },
        async getPerbaikan() {
            let perbaikan = await this.$store.dispatch('asset/getPerbaikanById', this.$route.params.id)
            if(!perbaikan) this.$router.push(`/asset-disposal/perbaikan`)

            this.perbaikan = perbaikan
        },
        async getRincianPerbaikan() {
            const params = {
                id_perbaikan_asset: this.$route.params.id
            }
            const rincians = await this.$store.dispatch('asset/getRincianPerbaikan', params)
            rincians.map(rincian => this.rincians.push(rincian))
        },
        async remove(index) {
            const rincian = this.rincians[index]
            if(!rincian.id) {
                this.rincians.splice(index, 1)
            }
            else {
                const payload = {
                    id: rincian.id,
                    fungsi: 2
                }
                this.loading = true
                await this.$store.dispatch('asset/saveRincianPerbaikan', [payload])
                await this.getPenyimpanan()
                this.loading = false
                this.rincians.splice(index, 1)
            }
        },
        async onChooseBarang(payloads) {
            this.$bvModal.hide('modal-asset')
            this.loading = true
            await this.getPenyimpanan()
            this.loading = false
            if(this.rincians.length < 1) {
                this.rincians = payloads
            }
            else {
                payloads.map(payload => this.rincians.push(payload))
            }
        },
        chooseBarang() {
            this.$bvModal.show('modal-asset')
        },
        async getPenyimpanan() {
            const params = {}
            if(this.myGudang) {
                params.gudang_id = this.myGudang.id
            }

            await this.$store.dispatch('asset/getStock', params)
        },
        async getGudangInfo() {
            if(this.perbaikan.id_gudang) {
                this.gudangInfo = await this.$store.dispatch('gudang/getDataById', this.perbaikan.id_gudang)
            }
        },
        async checkCanApprove() {
            const menu = await this.currentMenu(this.$route.meta.parent)
            const params = {
                level_id: this.user.level.id,
                menu_id: menu ? menu.id : null
            }

            const status = await this.$store.dispatch('statusrole/getData', params)
            this.canApprove = status.some(item => item.data_status == 1)
            this.canPay = status.some(item => item.data_status == 2)
            if(this.canPay) {
                this.getAkun()
                this.getKas()
            }
        },
        async submit() {
            if(this.rincians.length < 1) {
                this.displayError({
                    message: 'Harap pilih aset yang hendak diperbaiki!'
                })
                return false
            }
            const rincians = Object.assign([], this.rincians)
            const payloads = rincians.map(item => {
                const payload = {
                    id_perbaikan_asset: this.$route.params.id,
                    penyimpanan_id: item.penyimpanan_id,
                    jumlah: item.jumlah,
                    biaya: parseInt(item.biaya),
                    keterangan: item.keterangan
                }

                if(item.id) payload.id = item.id
                return payload
            })

            try {
                this.loading = true
                await this.$store.dispatch('asset/saveRincianPerbaikan', payloads)
                
                this.rincians = []
                await this.getRincianPerbaikan()
                await this.getPenyimpanan()

                this.loading = false
                this.displaySuccess({
                    message: 'Perubahan berhasil disimpan!'
                })
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        getGudangName(gudang_id) {
            const find = this.gudangs.find(item => item.id == gudang_id)
            return find ? find.nama_gudang : '-'
        }
    },
    async created() {
        this.loading = true
        await this.getPerbaikan()
        this.setField()
        
        await this.checkCanApprove()
        await this.getRincianPerbaikan()
        await this.getPenyimpanan()
        await this.getGudangInfo()
        this.loading = false
    }
}
</script>