var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    attrs: {
      "title": _vm.$t('History Scanned Items')
    }
  }, [_vm.allowCreate() ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('b-modal', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-no-backdrop",
      modifiers: {
        "modal-no-backdrop": true
      }
    }],
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "centered": "",
      "title": "Pilih Tanggal yang akan di print"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.unduhbop
          }
        }, [_vm._v(" Print / Cetak ")])], 1)];
      },
      proxy: true
    }], null, false, 712783526),
    model: {
      value: _vm.modalCetak,
      callback: function ($$v) {
        _vm.modalCetak = $$v;
      },
      expression: "modalCetak"
    }
  }, [_c('b-form', [_vm._v(" Dari : "), _c('b-form-group', [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "id": "example-datepicker"
    },
    model: {
      value: _vm.tgl,
      callback: function ($$v) {
        _vm.tgl = $$v;
      },
      expression: "tgl"
    }
  })], 1), _vm._v(" Ke : "), _c('b-form-group', [_c('b-form-datepicker', {
    staticClass: "mb-1",
    attrs: {
      "id": "example-datepicker"
    },
    model: {
      value: _vm.tglend,
      callback: function ($$v) {
        _vm.tglend = $$v;
      },
      expression: "tglend"
    }
  })], 1), _vm._v(" Pilih Gudang Penerima : "), _c('b-form-group', [_c('v-select', {
    attrs: {
      "options": _vm.dataGudang,
      "label": "text"
    },
    model: {
      value: _vm.gudangSelect,
      callback: function ($$v) {
        _vm.gudangSelect = $$v;
      },
      expression: "gudangSelect"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('Range Date'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        mode: 'range'
      }
    },
    model: {
      value: _vm.rangeDate,
      callback: function ($$v) {
        _vm.rangeDate = $$v;
      },
      expression: "rangeDate"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.rangeDateget();
      }
    }
  }, [_vm._v(" Search ")]), _c('b-button', {
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function ($event) {
        return _vm.rangeDatenull();
      }
    }
  }, [_vm._v(" Reset ")])], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Search'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getHistory();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append')], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "small": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.mutations,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.no_batch : "-") + " ")]), _vm._v(" "), _c('br')];
      }
    }, {
      key: "cell(barang)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-")), _c('br'), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-") + " ")])];
      }
    }, {
      key: "cell(scanned_qty)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.scanned_qty)) + " ")];
      }
    }, {
      key: "cell(creator)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.creator ? item.creator.nama_lengkap : '-') + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('section', {
          staticClass: "flex item-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }