<template>
  <b-overlay :show="loading">
    <b-row>
      <!-- <b-col xl="12" lg="12" md="12" sm="12">
                <b-card class="mb-2">
                    <h4 class="mb-2">Informasi Pembelian</h4>
                    <b-row class="mb-4">
                        <b-col md="3" class="mb-2">
                            <label for="">Tanggal</label>
                            <b-form-input v-model="form.tanggal" type="date" class="w-full" />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col> -->
      <b-col xl="12" lg="12" md="12" sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" md="12">
              <h4 class="mb-2">
                {{ $t("Item Details") }}
                <span v-if="selectedBarangs.length > 0"
                  >({{ selectedBarangs.length }})</span
                >
              </h4>
            </b-col>
            <b-col sm="12" md="2">
              <!-- <b-button-group> -->
              <b-button
                variant="primary"
                block
                class="mb-1"
                @click.prevent="chooseBarang"
                >{{ $t("Choose Items") }}</b-button
              >
              <!-- </b-button-group> -->
            </b-col>
            <b-col sm="12" md="2">
              <!-- <b-overlay :show="loading"> -->
              <b-form-group>
                <b-form-select :options="optblok" v-model="blok">
                  <template #first>
                    <b-form-select-option :value="null">{{
                      $t("Choose Area")
                    }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <!-- </b-overlay> -->
              <!-- / -->
            </b-col>
            <b-col sm="12" md="8">
              <b-overlay :show="loading">
                <b-form-group>
                  <b-form-tags
                    placeholder="Scan barcode disini..."
                    separator=" "
                    @keydown.enter.prevent="getpenyimpananBarangs()"
                    v-model="caribarang"
                    input-id="tags-basic"
                    class=""
                    @input="getPenyimpananBarang()"
                  />
                </b-form-group>
              </b-overlay>
              <!-- / -->
            </b-col>
          </b-row>
          <!-- <b-button variant="warning" class="mb-1" @click.prevent="chooseImport">Import Barang</b-button> -->

          <import-barang @getId="getId"></import-barang>
          <b-row>
            <b-col md="3" class="my-1">
              <label for="">Tanggal</label>
              <b-form-input v-model="form.tanggal" type="date" class="w-full" />
            </b-col>
            <b-col lg="9" class="my-1">
              <b-form-group
                :label="$t('Filter')"
                label-for="filter-input"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group>
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">{{
                      $t("Clear")
                    }}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col lg="6" class="my-1">
                <b-form-group v-model="sortDirection" label="Filter On"
                  description="Leave all unchecked to filter on all data" label-cols-sm="3" label-align-sm="right"
                  label-size="sm" class="mb-0" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group v-model="filterOn" :aria-describedby="ariaDescribedby" class="mt-1">
                    <b-form-checkbox value="name">Name</b-form-checkbox>
                    <b-form-checkbox value="age">Age</b-form-checkbox>
                    <b-form-checkbox value="isActive">Active</b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-col> -->
          </b-row>
          <b-row>
            <b-col>
              <b-table
                v-if="selectedBarangs.length > 0"
                responsive
                small
                :fields="fields"
                :items="selectedBarangs"
                :filter="filter"
                :filter-included-fields="filterOn"
              >
                <template #table-colgroup="{ fields }">
                  <col
                    v-for="field in fields"
                    :key="field.key"
                    :style="{ width: field.key == 'no' ? '20px' : '' }"
                  />
                </template>
                <template #cell(no)="{ index }">
                  {{ 1 + index }}
                </template>
                <template #cell(kode)="{ item }">
                  {{ item.barang.kode ? item.barang.kode : "-" }}<br />
                  <small>{{ item.barang.varian_item[0].no_batch }}</small>
                </template>
                <template #cell(kategori)="{ item }">
                  {{
                    item.barang.kategori ? item.barang.kategori.kategori : "-"
                  }}
                </template>
                <template #cell(no_container)="{ item }">
                  <b-form-input
                    v-model="item.no_container"
                    placeholder="Ex: MRT123213"
                  ></b-form-input>
                </template>
                <template #cell(nama)="{ item }">
                  <span v-if="item">
                    {{ item.barang.varian_item[0].varian }}<br />
                    <small>{{ item.barang.kategori.kategori }}</small> /
                    <small>{{ item.barang.nama }}</small>
                  </span>
                  <i v-else class="text-danger">Barang tidak ada.</i>
                </template>
                <template #cell(no_seal)="{ item }">
                  <b-form-input
                    v-model="item.no_seal"
                    placeholder="Ex: GR123"
                  ></b-form-input>
                </template>
                <template #cell(harga_beli)="{ item }">
                  <div>{{ formatRupiah(item.harga_beli) }}</div>
                  <strong
                    class="text-danger"
                    v-if="item.diskon && item.diskon > 0"
                  >
                    diskon {{ formatRupiah(item.diskon) }}
                  </strong>
                </template>
                <template #cell(diskon)="{ item }">
                  {{ formatRupiah(item.diskon) }}
                </template>
                <template #cell(qty)="{ item }">
                  <b-input-group>
                    <b-form-input
                      type="number"
                      v-model="item.qty"
                      @input="updateQty(item)"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-dark">
                        {{
                          item.barang.satuan ? item.barang.satuan.satuan : ""
                        }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <small>Stok saat ini: {{ item.jumlah }}</small
                  ><br />
                  <small class="text-danger" v-if="item.qty > item.jumlah"
                    ><i
                      >Produk yang di disposal harap tidak melebihi stok</i
                    ></small
                  >
                </template>

                <template #cell(total)="{ item }">
                  {{
                    formatRupiah(parseInt(item.harga_beli) * parseInt(item.qty))
                  }}
                </template>
                <template #cell(action)="{ item, index }">
                  <feather-icon
                    icon="MinusCircleIcon"
                    size="24"
                    class="text-danger cursor-pointer"
                    @click="removeBarang(item, index)"
                  ></feather-icon>
                </template>
                <template #cell(keterangan)="{ item, index }">
                  <b-form-input v-model="item.keterangan"></b-form-input>
                </template>
                <template #cell(type)="{ item, index }">
                  <b-form-select
                    :options="opttype"
                    v-model="item.type"
                  ></b-form-select>
                </template>
              </b-table>
              <div v-else class="alert alert-danger p-2">
                {{ $t("Please select item") }}
              </div>
              <div
                class="d-flex justify-content-end align-items-center mt-3 px-2"
              >
                <h4>Total Qty:</h4>
                <h2 class="ml-2">{{ formatRupiah(totalPembelian) }}</h2>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <barang-modal @submit="onChooseBarang"></barang-modal>
        <div class="d-flex justify-content-end">
          <b-button
            :disabled="!isValidForm"
            @click.prevent="submit"
            block
            :variant="!isValidForm ? 'secondary' : 'primary'"
            >Simpan</b-button
          >
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import BarangModal from "./components/Modal.vue";
import ImportBarang from "./ImportBarang.vue";
import vSelect from "vue-select";
import {
  BButtonGroup,
  BFormSelectOption,
  BFormTags,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BCard,
  BButton,
  BRow,
  BOverlay,
  BTable,
  BCol,
  BFormInput,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
export default {
  components: {
    ImportBarang,
    BarangModal,
    BButtonGroup,
    BFormSelectOption,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BCard,
    BOverlay,
    BButton,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    vSelect,
  },
  data: () => ({
    //Data Barang
    caribarang: [],
    blok: null,
    optblok: [],
    pageOptions: [10, 20, 50, 100],
    sortBy: null,
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    perPage: 10,
    totalRows: 1,
    currentPage: 1,
    fieldsbarang: [
      // { key: "show_detail", label: "#" },
      { key: "no", label: "No", sortable: true },
      { key: "kode", label: "Barcode" },
      { key: "nama", label: "Nama Produk", sortable: true },
      // { key: "id_satuan", label: "Satuan" },
      { key: "action", label: "#" },
    ],
    supplier: null, // for selected supplier
    fields: [
      // { key: "no_container", label: "No. Container" },
      // { key: "no_seal", label: "No. Seal" },
      // { key: "kategori", label: "kategori" },
      { key: "kode", label: "Barcode" },
      { key: "nama", label: "Nama Barang" },
      { key: "qty", label: "Qty" },
      { key: "type", label: "Tipe" },
      { key: "keterangan", label: "Keterangan" },
      { key: "action", label: "" },
    ],
    opttype: [
      { value: null, text: "Pilih" },
      { value: "hilang", text: "Hilang" },
      { value: "rusak", text: "Rusak" },
      { value: "marketing", text: "Marketing" },
      { value: "hadiah", text: "Hadiah" },
      { value: "retur", text: "Retur" },
    ],
    selectedBarangs: [],
    form: {
      tanggal: null,
      jenis: 2,
      kategori: 0,
      id_kas: null,
      id_supplier: null,
      keterangan: null,
      jatuh_tempo: null,
    },
    suppliers: [],
    barangs: [],
    loading: false,
    hasPermissionApprove: false,
  }),
  watch: {},
  computed: {
    isKas() {
      return (
        this.akun &&
        this.akun.kategori &&
        this.akun.kategori.kategori == "Kas & Bank"
      );
    },
    totalPembelian() {
      return this.selectedBarangs.reduce(
        (total, barang) => (total += parseInt(barang.qty)),
        0
      );
    },
    isValidForm() {
      if (!this.form.tanggal || this.selectedBarangs.length < 1) {
        return false;
      }

      return true;
    },
    updateMode() {
      if (this.$route.params.id) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateQty(item) {
      // Memastikan reaktivitas tetap berjalan
      item.qty = parseInt(item.qty) || 0;
      this.$set(this.selectedBarangs, this.selectedBarangs.indexOf(item), item);
    },
    getPenyimpananBarang() {
      if (this.blok == null) {
        this.displayWarning({
          message: "Harap form lokasi asal dan tujuan mutasi dipilih",
        });
        this.caribarang = null;
        this.caribarang = [];
        return false;
      }
      this.loading = true;
      for (let i = 0; i < this.caribarang.length; i++) {
        const kodeBarang = this.caribarang[i];

        let params = {
          kode_barang: kodeBarang,
        };
        // let params = {
        //   kode_barang: this.caribarang.join(","),
        // };
        if (this.gudangutama) {
          params.gudang_id = this.gudangutama.value;
        }
        this.$store
          .dispatch("tokopenyimpanan/getData", params)
          .then((stocks) => {
            // if (this.blok) {
            // console.log("AJI", this.blok)
            let filteredSama = [];
            //   const filteredData = stocks.filter(data => data.blok_id == this.blokasal.value);
            filteredSama = stocks.filter((data) => data.blok_id == this.blok);
            //             const hasZeroQty = filteredSama.some(data => data.jumlah == 0);
            // console.log('tes',hasZeroQty)
            // if (hasZeroQty) {
            //   this.displayWarning({ message: 'Kuantitas barang yang dipilih stok nya 0 dan tidak bisa di disposal' });
            // } else {
            this.onChooseBarang(filteredSama);
            // }

            this.loading = false;
            this.caribarang = null;
            this.caribarang = [];
            // }
          })
          .catch((e) => {
            this.loading = false;
            this.displayError(e);
            return false;
          });
      }
    },
    async checkPermission() {
      const menu = await this.currentMenu(this.$route.meta.parent);
      const params = {
        level_id: this.user.level.id,
      };
      if (menu) {
        params.menu_id = menu.id;
      }
      this.listStatus = await this.$store.dispatch(
        "statusrole/getData",
        params
      );

      // data_status => 1 approve gm, data_status => 2 pay finance
      const hasPermissionApprove = this.listStatus.some(
        (status) => status.data_status == 1
      );

      this.hasPermissionApprove = hasPermissionApprove;
    },
    async submit() {
      if (!this.isValidForm) {
        this.displayError({
          message: "Harap lengkapi form pembelian!",
        });
        return false;
      }
      let payload = [];
      // make payload item rincian barang
      this.selectedBarangs.map((barang) => {
        const payloadBarang = {
          tanggal: this.form.tanggal,
          penyimpanan_id: barang.id,
          id_gudang: barang.gudang.id,
          type: barang.type,
          // id_barang: barang.id,
          // harga_beli: barang.harga_beli,
          jumlah: parseInt(barang.qty),
          keterangan: barang.keterangan,
        };
        payload.push(payloadBarang);
      });

      // console.log('CEk', payload)

      // check jika ada harga dasar baru, apakah ingin update harga dasar baru
      try {
        this.loading = true;
        await this.$store.dispatch("disposal/save", payload);
        // if (this.updateMode) {
        //     await this.$store.dispatch("pembelian-rincian/save", payload.item);
        // }

        this.loading = false;
        this.displaySuccess({
          message: "Disposal berhasil",
        });
        const thereChangePrice = this.selectedBarangs.some(
          (barang) => barang.isChangePrice == true
        );

        if (thereChangePrice) {
          this.updateBasePrice();
        } else {
          setTimeout(() => this.$router.push("/outbound/disposal"), 500);
        }
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    updateBasePrice() {
      this.$swal({
        title: "Update harga dasar?",
        text: "Harga dasar barang yang anda pilih akan diupdate!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
      }).then((res) => {
        if (res.value) {
          const barangs = this.selectedBarangs.filter(
            (barang) => barang.isChangePrice
          );
          const payloads = barangs.map((barang) => ({
            id: barang.id,
            kode: barang.kode,
            nama: barang.nama,
            varian: barang.varian
              ? barang.varian
              : barang.varian_item[0].varian,
            kategori: barang.kategori.kategori,
            jenis: barang.jenis,
            id_kategori: barang.kategori ? barang.kategori.id : null,
            id_satuan: barang.satuan ? barang.satuan.id : null,
            harga_dasar: barang.harga_dasar_baru,
          }));

          this.loading = true;
          this.$store
            .dispatch("barang/save", payloads)
            .then(() => {
              this.loading = false;
              setTimeout(() => this.$router.push("/pembelian"), 500);
            })
            .catch((e) => {
              this.loading = false;
              this.displayError(e);
              return false;
            });
        } else {
          setTimeout(() => this.$router.push("/pembelian"), 500);
        }
      });
    },
    removeBarang(item, index) {
      if (!item.rincian_id) {
        this.selectedBarangs.splice(index, 1);
      } else {
        // remove via API
        const payload = {
          id: item.rincian_id,
          fungsi: 2,
        };
        this.$store
          .dispatch("pembelian-rincian/save", [payload])
          .then(() => this.selectedBarangs.splice(index, 1))
          .catch((e) => {
            this.displayError(e);
            return false;
          });
      }
    },
    onChooseBarang(selectedBarangs) {
      selectedBarangs.map((barang) => {
        let existBarang = this.selectedBarangs.find(
          (res) => res.id == barang.id
        );
        if (existBarang) {
          existBarang.qty = parseInt(existBarang.qty) + 1;
          this.updateQty(existBarang);
        } else {
          barang.no_container = null;
          barang.no_seal = null;
          barang.qty =
            barang.qty == null
              ? parseInt((barang.qty = 1))
              : parseInt(barang.qty);
          this.selectedBarangs.push(barang);
        }
      });
      this.$bvModal.hide("barang-modal");
    },
    chooseBarang() {
      this.$bvModal.show("barang-modal");
    },
    chooseImport() {
      this.$bvModal.show("import-barang");
    },
    // async getBarang() {
    //   // const jenis = this.isOwner ? 1 : 2
    //   // const payload = {
    //   //   order: "desc",
    //   //   start: 1,
    //   //   length: 10,
    //   // };
    //   const perPage = parseInt(this.perPage);
    //   const currentPage = (parseInt(this.currentPage) - 1) * perPage;
    //   const payload = {
    //     search: this.filter != null ? this.filter : null,
    //     order: "desc",
    //     start: currentPage,
    //     length: this.perPage,
    //     filter_by: "nama_lengkap",
    //   };
    //   // if (this.isGM) payload.jenis = 2;
    //   this.barangs = await this.$store.dispatch("barang/getData", payload);
    //   this.barangs.map((barang) => {
    //     barang.qty = 0;
    //     barang.diskon = 0;
    //     barang.harga_beli = 0;
    //     barang.isChangePrice = false;
    //     barang.harga_dasar_baru = 0; // bila ingin merubha harga dasar
    //   });
    // },
    async getSupplier() {
      const params = this.isGM ? { jenis: 2 } : {};
      const suppliers = await this.$store.dispatch("supplier/getData", params);
      suppliers.map((supplier) => {
        this.suppliers.push({
          text: supplier.instansi,
          value: supplier.id,
        });
      });
    },
    async getId() {
      if (this.updateMode) {
        this.selectedBarangs = [];
        const pembelian = await this.$store.dispatch(
          "pembelian/getDataById",
          this.$route.params.id
        );
        if (!pembelian) {
          this.$router.push("/pembelian");
        }
        if (this.isGM && pembelian.jenis == 1) {
          this.$router.push("/pembelian");
        }
        this.form.tanggal = pembelian.tanggal;
        this.form.jatuh_tempo = pembelian.jatuh_tempo;
        this.form.jenis = pembelian.jenis;
        this.form.keterangan = pembelian.keterangan;
        this.form.id_supplier = pembelian.id_supplier;
        this.supplier = this.suppliers.find(
          (sp) => sp.value == pembelian.id_supplier
        );
        pembelian.rincian.map((rincian) => {
          if (rincian.barang) {
            const { barang } = rincian;
            const item = {
              rincian_id: rincian.id,
              id: barang.id,
              nama: barang.nama,
              kode: barang.varian_item[0].kode,
              varian_item: [
                {
                  varian: barang.varian_item[0].varian,
                  no_batch: barang.varian_item[0].no_batch,
                },
              ],
              kategori: {
                kategori: barang.kategori ? barang.kategori.kategori : null,
              },
              satuan: { satuan: barang.satuan ? barang.satuan.satuan : null },
              qty: rincian.qty,
              diskon: rincian.diskon,
              harga_beli: rincian.harga_beli,
            };
            this.selectedBarangs.push(item);
          }
        });
      }
    },
  },
  async created() {
    // this.getBarang();
    this.form.jenis = 2;
    await this.checkPermission();
    // if (!this.3) {
    //   this.$router.push("/pembelian");
    // }
    await this.getSupplier();

    this.form.tanggal = this.getCurrentDate();
    this.getId();
    let arr = JSON.parse(localStorage.getItem("myBlok"));
    arr.map((stock) => {
      stock.text = stock.blok;
      stock.value = stock.id;
    });
    this.optblok = arr;
    // if (this.optblok.length > 0){
    //     this.blok = this.optblok[0]
    // }
  },
};
</script>
