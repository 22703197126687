<template>
  <section>
    <b-row>
      <b-col>

        <template>
          <!-- <b-card> -->
          <b-row class="align-items-center">
            <b-col sm="12" md="6" class="my-1">
              <!-- <b-button variant="warning" @click.prevent="$router.push('/outbound/historyscan')">
                <feather-icon icon="ListIcon" class="mr-50" />
                {{ $t('History Scanned Items') }}
              </b-button> -->
        <b-button-group>
          <b-button @click.prevent="adddisposal" variant="primary">
            {{ $t('Add') }}
          </b-button>
          <!-- <xlsx-workbook>
            <xlsx-sheet :collection="items" sheet-name="Sheet1" />
            <xlsx-download filename="Export Data Disposal">
              <b-button v-if="allowCreate()" variant="success">
                {{ $t('Export') }}
              </b-button>
            </xlsx-download>
          </xlsx-workbook>
          <b-button v-if="allowCreate()" @click.prevent="addimport" variant="warning">
            {{ $t('Import') }}
          </b-button>
          <modal-import @getData="getData"></modal-import> -->
        </b-button-group>
            </b-col>
            <b-col sm="12" md="6" class="mb-1 d-flex justify-content-end">
              <b-button-group>
                <b-button @click="perattach" variant="outline-primary">
                  <feather-icon icon="MenuIcon" class="mr-25" />Per Attachment</b-button>
                <b-button @click="peritems" variant="outline-warning">
                  <feather-icon icon="PackageIcon" class="mr-25" />Per Items</b-button>
              </b-button-group>
            </b-col>
          </b-row>
          <!-- </b-card> -->
        </template><template v-if="mode == 'Per Items'">
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <list-items />
            </b-col>
          </b-row>
        </template>
        <template v-if="mode == 'Per Attachment'">
          <b-row>
            <b-col sm="12" md="12" lg="12" xl="12">
              <list-attach />
            </b-col>
          </b-row>
        </template>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "../../../docs/dist/vue-xlsx.es.js"
// import ModalImport from './Import.vue'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListItems from "./ListItems.vue";
import ListAttach from "./Listpersurat.vue";
import {
  BTable, VBTooltip,BTab, BTabs,
  BAvatar,
  BBadge,
  BModal,
  BRow, BCard,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BButtonGroup,
  BOverlay
} from "bootstrap-vue";
// import ModalDisposal from './components/Modal.vue'

import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

export default {
  components: {
    Viewer,
    // ModalDisposal,
    // ModalImport,
    ListItems,
ListAttach,
    BCardActions, VBTooltip, ToastificationContent,BTab, BTabs,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BButtonGroup, BCard,
    // XlsxRead,
    // XlsxTable,
    // XlsxSheets,
    // XlsxJson,
    // XlsxWorkbook,
    // XlsxSheet,
    // XlsxDownload,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // modalimport: false,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "Sheet", data: [{ nama: 'Contoh: disposal nama', no_hp_pic: '841982419', instansi: 'PT. heykama', nohp: '08124812894', email: 'owner@heykama.com', top: 'Cash', alamat: 'Bandung, buahbatu', memo: 'Note' }] }],
      collection: [{ a: 1, b: 2 }],
      //
      loading: false,
      showModalAdd: false,
      id: null,
      nama_jabatan: null,
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "desc",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      currentItem: {},
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "fotopro",
          label: "",
        },
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "kode", label: "Barcode", sortable: true },
        { key: "penyimpanan", label: "Barang", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        // { key: "nohp", label: "No. Handphone", sortable: true },
        { key: "jumlah", label: "Qty / Nominal", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
        // { key: "memo", label: "Memo", sortable: true },
        { key: "actions", label: "Aksi" }
      ],
      items: [],
      gudangOptions: [],
      currentImage: null,
      mode: 'Per Attachment',
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
      } else {
        return str;
      }
    },
  },
  created() {
    // Set the initial number of items

    this.getGudang()
  },
  methods: {
    perattach() {
      this.mode = 'Per Attachment'
    },
    peritems() {
      this.mode = 'Per Items'
    },
    openModal(src) {
      // Set gambar saat ini dan buka modal
      this.currentImage = src;
      // this.currentBarcode = src2;
      this.$refs.imageModal.show();

      // Inisialisasi Viewer.js
      this.$nextTick(() => {
        if (this.viewer) {
          this.viewer.destroy();
        }
        this.viewer = new Viewer(this.$refs.imageViewer, {
          inline: false,
          zoomable: true,
          scalable: true
        });
      });
    },
    adddisposal() {
      this.$router.push(`/outbound/ajuanmarketing/add`)
    },
    addimport() {
      // this.resetForm()
      this.$bvModal.show('modal-import')
    },
    async submitimport(cek) {

      cek.map((hem) => {
        hem.jenis = 2;
      });
      let payload = cek
      // console.log('cek', cek)
      // if (this.id) {
      //   payload.id = this.id
      // }
      if (this.isOwner || this.isCEO) {
        payload.jenis = 2
      }
      else {
        payload.jenis = 2
      }
      try {
        this.loading = true
        await this.$store.dispatch('disposal/save', payload)
        this.displaySuccess({
          message: 'Berhasil'
        })
        this.getData()
        this.$bvModal.hide('modal-disposal')

        this.loading = false
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },
    toggleLoading(val) {
      this.loading = val
    },
    add() {
      this.resetForm()
      // this.$bvModal.show('modal-disposal')
    },
    remove(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Data Disposal ${item.tanggal} ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          const payload = {
            // fungsi: 1,
            id: item.id
          }
          this.$store.dispatch('disposal/delete', [payload])
            .then(() => {
              this.displaySuccess({
                message: 'Berhasil'
              })
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
        }
      })
    },
    resetForm() {
      this.id = null
    },
    async submit(payload) {
      if (this.id) {
        payload.id = this.id
      }
      if (this.isOwner || this.isCEO) {
        payload.jenis = 1
      }
      else {
        payload.jenis = 2
      }
      try {
        this.loading = true
        await this.$store.dispatch('disposal/save', [payload])
        this.displaySuccess({
          message: 'Berhasil'
        })
        this.getData()
        // this.$bvModal.hide('modal-disposal')

        this.loading = false
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    edit(item) {
      // this.getDataId(item)
      // this.id = item.id
      this.currentItem = item
      if (this.currentItem) {
        this.$router.push(`/outbound/ajuanmarketing/add/${item.id}`)
        // this.$bvModal.show('modal-disposal')
      }
      // console.log('CEK', this.currentItem)
    },
    async getDataId(item) {
      this.loading = true
      this.$store
        .dispatch("disposal/getDataById", item.id)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.disposal.datas));
          let items_total = this.$store.state.disposal.totals;
          this.items = items
          this.totalRows = items_total
          this.loading = false
        }).catch((e) => {
          this.displayError(e);
          this.loading = false
          return false;
        });
    },
    async getData() {
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        // search: this.filter ? this.filter : null,
        has_attachment: 1,
        order: this.sortDesc ? this.sortDesc : 'desc',
        start: currentPage,
        length: this.perPage,
        id_gudang: this.myGudang ? this.myGudang.id ? this.myGudang.id : this.myGudang.data.id : this.gudangOptions[0].id
        // filter_by: "nama_lengkap",
        // disposal_id: this.disposal,
        // id_disposal: this.disposal,
      };
      if (this.filter) {
        params.search = this.filter
      }
      this.$store
        .dispatch("disposal/getData", params)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.disposal.datas));
          let items_total = this.$store.state.disposal.totals;
          this.items = items
          this.totalRows = items_total
          this.loading = false
        }).catch((e) => {
          this.displayError(e);
          this.loading = false
          return false;
        });
    },

    getGudang() {
      this.$store.dispatch('gudang/getData', { jenis: 1 })
        .then(() => {
          this.gudangOptions = this.$store.state.gudang.datas
          this.gudangOptions.map((hem) => {
            hem.value = hem.id;
            hem.text = hem.nama_gudang;
          })
          this.getData()
          // this.totalRows = this.items.length;
        })
        .catch(e => console.error(e))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.running-text-container {
  overflow: hidden;
  /* Hides the text that goes beyond the container */
  white-space: nowrap;
  /* Prevents text from wrapping */
  width: 100%;
  /* Full width */
  background-color: #f8f9fa;
  /* Optional: background color */
  padding: 10px 0;
  /* Optional: padding for visual enhancement */
  border: 1px solid #ddd;
  /* Optional: border */
}

.running-text {
  display: inline-block;
  /* Text behaves like an inline element */
  animation: marquee 10s linear infinite;
  /* Runs the marquee animation */
  font-size: 1.2rem;
  /* Optional: font size */
  color: #333;
  /* Optional: text color */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
