var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-frame",
      "size": "xl",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Choose Items")))])];
      },
      proxy: true
    }])
  }, [_c('header', {
    staticClass: "mb-1"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Items Selected")) + " (" + _vm._s(_vm.selectedBarangs.length) + ").")]), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPage",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Warehouse'),
      "label-for": "warehouse",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "blokSelect",
      "size": "sm",
      "options": _vm.blok_id
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.blok,
      callback: function ($$v) {
        _vm.blok = $$v;
      },
      expression: "blok"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v(_vm._s(_vm.$t('All')))])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Category'),
      "label-for": "category",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "categorySelect",
      "size": "sm",
      "options": _vm.categoryOptions
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }), _vm.categoryOptions.length == 0 || _vm.categoryOptions == [] ? _c('small', [_vm._v("Data not found "), _c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getDataKategori();
      }
    }
  }, [_vm._v("Refresh")])], 1) : _vm._e()], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari",
      "size": "sm"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_vm.localBarangs.length > 0 ? [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "fields": _vm.fields,
      "items": _vm.localBarangs
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (scope) {
        return _vm._l(scope.fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key === 'diskon' ? '150px' : field.key === 'nama' ? '400px' : field.key === 'satuan' ? '80px' : field.key === 'checkbox' ? '70px' : '180px'
            }
          });
        });
      }
    }, {
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedBarangs,
            callback: function ($$v) {
              _vm.selectedBarangs = $$v;
            },
            expression: "selectedBarangs"
          }
        })];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.barang.kategori ? item.barang.kategori.kategori : '-') + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.barang.kode)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.no_batch))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Data not found.")])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.barang.varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori.kategori) + " / " + _vm._s(item.barang.nama))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t('Room')) + " : ")]), _c('small', [_vm._v(_vm._s(item.blok ? item.blok.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.palet ? item.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.rak ? item.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.laci ? item.laci.laci : "-"))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Data not found.")])];
      }
    }, {
      key: "cell(harga_jual)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item.barang.harga_jual[0] ? _vm.formatRupiah(item.barang.harga_jual[0].harga) : 0) + " ")];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('strong', [_vm._v(_vm._s(_vm.formatRupiah(item.barang.varian_item[0].harga_dasar)))])];
      }
    }, {
      key: "cell(harga_dasar_baru)",
      fn: function (_ref7) {
        var item = _ref7.item,
          index = _ref7.index;
        return [item.isChangePrice ? _c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiahHDB(index);
            }
          },
          model: {
            value: item.barang.harga_dasar_baru,
            callback: function ($$v) {
              _vm.$set(item.barang, "harga_dasar_baru", $$v);
            },
            expression: "item.barang.harga_dasar_baru"
          }
        }) : _c('strong', [_vm._v(_vm._s(item.barang.harga_dasar))])];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref8) {
        var item = _ref8.item,
          index = _ref8.index;
        return [_c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiahHB(index);
            }
          },
          model: {
            value: item.harga_beli,
            callback: function ($$v) {
              _vm.$set(item, "harga_beli", $$v);
            },
            expression: "item.harga_beli"
          }
        })];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref9) {
        var item = _ref9.item,
          index = _ref9.index;
        return [_c('b-form-input', {
          on: {
            "keyup": function ($event) {
              return _vm.doFormatRupiahDiskon(index);
            }
          },
          model: {
            value: item.diskon,
            callback: function ($$v) {
              _vm.$set(item, "diskon", $$v);
            },
            expression: "item.diskon"
          }
        })];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.qty,
            callback: function ($$v) {
              _vm.$set(item, "qty", $$v);
            },
            expression: "item.qty"
          }
        }, [_vm._v("1 ")])];
      }
    }, {
      key: "cell(available_stock)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.jumlah) + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : '') + " ")];
      }
    }, {
      key: "cell(isChangePrice)",
      fn: function (_ref13) {
        var item = _ref13.item,
          index = _ref13.index;
        return [_c('b-form-checkbox', {
          on: {
            "change": function ($event) {
              return _vm.onChangePrice(index);
            }
          },
          model: {
            value: item.isChangePrice,
            callback: function ($$v) {
              _vm.$set(item, "isChangePrice", $$v);
            },
            expression: "item.isChangePrice"
          }
        })];
      }
    }], null, false, 2962167388)
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)] : [_c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_c('strong', [_vm._v(_vm._s(_vm.$t('Data not found')))])])]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }