export default [
  //JASA
  {
    path: "/penerimaanbarang",
    name: "route-penerimaanbarang",
    component: () => import("@/views/pages/heykama/penerimaanbarang/List.vue"),
    meta: {
      module: "Inbound",
      pageTitle: "Inbound",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penerimaanbarang/in-transit-stock",
    name: "route-penerimaanbarang-in-transit-stock",
    component: () =>
      import("@/views/pages/heykama/penerimaanbarang/InTransit.vue"),
    meta: {
      module: "Inbound",
      pageTitle: "Inbound",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/penerimaanbarang",
        },
        {
          text: "In Transit Stock",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penerimaanbarang/detail/:id",
    name: "route-penerimaanbarang-detail",
    component: () =>
      import("@/views/pages/heykama/penerimaanbarang/Detail.vue"),
    meta: {
      module: "Inbound",
      pageTitle: "Inbound",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/penerimaanbarang",
        },
        {
          text: "Detail Penerimaan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penerimaanbarang/checking/:id",
    name: "route-penerimaanbarang-detail",
    component: () =>
      import("@/views/pages/heykama/penerimaanbarang/Detail.vue"),
    meta: {
      module: "Inbound",
      pageTitle: "Inbound",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/penerimaanbarang",
        },
        {
          text: "Checking Process",
          active: true,
        },
      ],
    },
  },
  {
    path: "/penerimaanbarang/detailpembelian/:id",
    name: "route-penerimaanbarang-detailpembelian",
    component: () =>
      import("@/views/pages/heykama/penerimaanbarang/DetailPembelian.vue"),
    meta: {
      parent: "/penerimaanbarang",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/penerimaanbarang",
        },
        {
          text: "Detail Pembelian",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/laporan2/add',
  //   name: 'route-laporan2-add',
  //   component: () => import('@/views/pages/laporan2/Store.vue'),
  //   meta: {
  //     parent: '/laporan2',
  //     module: 'Laporan',
  //     authRequired: true,
  //     pageTitle: 'Laporan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/laporan2',
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/laporan2/edit/:id',
  //   name: 'route-laporan2-edit',
  //   component: () => import('@/views/pages/laporan2/Edit.vue'),
  //   meta: {
  //     parent: '/laporan2',
  //     module: 'Laporan',
  //     authRequired: true,
  //     pageTitle: 'Laporan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/laporan2',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
