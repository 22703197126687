<template>
  <b-modal
    id="barang-modal"
    size="xl"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true">
    <header class="mb-1">
      <h4>
        <b
          ><u>{{ $t("Items Selected") }} ({{ selectedBarangs.length }})</u></b
        >
      </h4>

      <!-- <header> -->
      <b-row class="mt-1">
        <b-col md="1" sm="1"> Category </b-col>
        <b-col md="3" sm="3">
          <b-form-group>
            <b-form-select
              v-model="kategori_id"
              :options="itemsKategori"
              @change="getData()">
              <template #first>
                <b-form-select-option :value="null"
                  >-- {{ $t("All") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="1"> Sort </b-col>
        <b-col md="2" sm="2">
          <b-form-group>
            <b-form-select
              v-model="sortBarang"
              :options="[
                { value: 'asc', text: 'Ascending' },
                { value: 'desc', text: 'Descending' },
              ]"
              @change="getData()">
              <template #first>
                <b-form-select-option :value="null"
                  >-- {{ $t("None") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="1" sm="1" class=""> Lainnya </b-col>
        <b-col md="4" sm="4" class="">
          <b-form-group>
            <b-input-group>
              <!-- b-form-select sebagai bagian dari input group -->
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchOptions"
                  :options="[
                    { value: 'kelas_id', text: 'Kelas' },
                    { value: 'main_sku', text: 'SKU Ginee' },
                    { value: 'kode', text: 'Barcode' },
                  ]"
                  class="custom-select"></b-form-select>
              </b-input-group-prepend>

              <!-- b-form-input sebagai bagian dari input group -->
              <b-form-input
                v-if="searchOptions !== 'kelas_id'"
                @keydown.enter.prevent="getData()"
                v-model="customefilter"
                placeholder="Masukkan teks"></b-form-input>

              <b-form-select
                v-if="searchOptions == 'kelas_id'"
                v-model="kelas_id"
                :options="itemsKelas"
                @change="getData()">
                <template #first>
                  <b-form-select-option :value="null"
                    >-- {{ $t("All") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="1" sm="1"> Pre Order </b-col>
        <b-col md="3" sm="3">
          <b-form-group>
            <b-form-select
              v-model="is_pre_order"
              :options="[
                // { value: null, text: 'All' },
                { value: 1, text: 'Ya' },
                { value: 0, text: 'Tidak' },
              ]"
              @change="getData()">
              <template #first>
                <b-form-select-option :value="null"
                  >-- {{ $t("All") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2" sm="2" class="my-1">
          <b-form-group
            class="mb-0"
            :label="$t('Per page')"
            label-for="perPage"
            size="sm">
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions" />
          </b-form-group>
        </b-col>
        <b-col md="6" sm="6" class="my-1">
          <b-form-group
            :label="$t('Filter')"
            label-for="filterInput"
            class="mb-0"
            size="sm">
            <b-input-group>
              <b-form-input
                v-model="filter"
                placeholder="Tekan enter untuk cari"
                size="sm"
                @keydown.enter.prevent="getData()"></b-form-input>
              <b-input-group-append>
                <b-button
                  variant="outline-primary"
                  @click="getData()"
                  size="sm">
                  Cari
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <b-col md="4" sm="4" class="my-1">
                <b-form-group label="Tabel" label-for="tabel" class="mb-0" label-size="sm">
                  <b-form-select v-model="tabel" size="sm" :options="tabeloption">
                  </b-form-select>
                </b-form-group>
              </b-col> -->
      </b-row>
      <!-- </header> -->
      <!-- <b-row>
        <b-col md="2" sm="2" class="my-1">
          <b-form-group class="mb-0" :label="$t('Category')" label-for="category" size="sm">
            <b-form-select id="categorySelect" v-model="category" size="sm" :options="categoryOptions"
              @change="getData()">
              <b-form-select-option :value="null">{{ $t('All') }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row> -->
    </header>
    <b-overlay :show="loading">
      <template v-if="localBarangs.length > 0">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :fields="fields"
          :items="localBarangs">
          <!-- <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width:
                  field.key === 'diskon'
                    ? '150px'
                    : field.key === 'nama'
                    ? '400px'
                    : field.key === 'satuan'
                    ? '80px'
                    : field.key === 'checkbox'
                    ? '70px'
                    : '180px',
              }" />
          </template> -->
          <template #head(checkbox)>
            <b-form-checkbox v-model="allChecked"></b-form-checkbox>
          </template>
          <template #cell(checkbox)="{ item }">
            <b-form-checkbox
              v-model="selectedBarangs"
              :value="item"></b-form-checkbox>
          </template>
          <template #cell(no)="row">
              {{ row.index + 1 }}
          </template>
          <template #cell(kode)="{ item }">
            {{ item ? item.kode : "-" }}<br />
            <small>{{ item ? item.no_batch : "-" }}</small>
          </template>
          <template #cell(fotopro)="{ item }">
            <template
              v-if="item.photo && item.photo.length > 0"
              class="image-gallery">
              <!-- <img v-for="(image, index) in item.photo" :key="index" :src="image.path" :alt="image.path"
                  class="viewer-image" ref="imageElement" /> -->
              <img
                style="width: 50px"
                @click="openModal(item.photo[0].path, item.barcode)"
                :src="item.photo[0].path"
                alt="Image description" />
            </template>
            <small v-else class="text-danger"
              >Photo product item not yet created</small
            >
            <!-- <feather-icon v-else icon="Icon" size="32" /> -->
            <br />
            <img
              v-if="item.barcode"
              @click="
                openModal(
                  item.photo.length > 0 ? item.photo[0].path : null,
                  item.barcode
                )
              "
              style="width: 75px"
              :src="item.barcode"
              alt="Foto" />
            <small v-else class="text-danger">Barcode not yet created</small>
            <!-- <feather-icon v-else icon="Icon" size="32" /> -->
            <b-modal
              id="image-modal"
              title="Preview Photo Product Item and Barcode"
              ref="imageModal"
              :hide-footer="true"
              size="lg"
              centered>
              <div ref="imageViewer">
                <img
                  v-if="currentImage"
                  :src="currentImage"
                  class="img-fluid"
                  alt="Full-size" />
                <small v-else class="text-danger"
                  >Photo product item not yet created</small
                >
                <br />
                <img
                  v-if="currentBarcode"
                  :src="currentBarcode"
                  class="img-fluid"
                  alt="Full-size" />
                <small v-else class="text-danger"
                  >Barcode not yet created</small
                >
              </div>
            </b-modal> </template
          >
          <template #cell(kode)="{ item }">
            {{ item ? item.kode : "-" }}<br />
            <small>{{ item ? item.no_batch : "-" }}</small>
          </template>
          <template #cell(fotopro)="{ item }">
            <template
              v-if="item.photo && item.photo.length > 0"
              class="image-gallery">
              <!-- <img v-for="(image, index) in item.photo" :key="index" :src="image.path" :alt="image.path"
                  class="viewer-image" ref="imageElement" /> -->
              <img
                style="width: 50px"
                @click="openModal(item.photo[0].path, item.barcode)"
                :src="item.photo[0].path"
                alt="Image description" />
            </template>
            <small v-else class="text-danger"
              >Photo product item not yet created</small
            >
            <!-- <feather-icon v-else icon="Icon" size="32" /> -->
            <br />
            <img
              v-if="item.barcode"
              @click="
                openModal(
                  item.photo.length > 0 ? item.photo[0].path : null,
                  item.barcode
                )
              "
              style="width: 75px"
              :src="item.barcode"
              alt="Foto" />
            <small v-else class="text-danger">Barcode not yet created</small>
            <!-- <feather-icon v-else icon="Icon" size="32" /> -->
            <b-modal
              id="image-modal"
              title="Preview Photo Product Item and Barcode"
              ref="imageModal"
              :hide-footer="true"
              size="lg"
              centered>
              <div ref="imageViewer">
                <img
                  v-if="currentImage"
                  :src="currentImage"
                  class="img-fluid"
                  alt="Full-size" />
                <small v-else class="text-danger"
                  >Photo product item not yet created</small
                >
                <br />
                <img
                  v-if="currentBarcode"
                  :src="currentBarcode"
                  class="img-fluid"
                  alt="Full-size" />
                <small v-else class="text-danger"
                  >Barcode not yet created</small
                >
              </div>
            </b-modal>
          </template>
          <template #cell(nama)="{ item }">
            <!-- <div class="v-align-top"> -->
            {{ item ? item.nama : "-" }}<br />
            <small>{{ item.kategori.kategori }}</small> /
            <small>
              {{ item ? item.varian : "-" }}
            </small>
            <br />
            {{ item.kelas ? item.kelas.kelas : "-" }}
            <!-- <br>
                <small>{{ $t('Room') }} : </small>
                <small>{{ item.blok ? item.blok.blok : "-" }}</small>
                <small>{{ item.palet ? item.palet.palet : "-" }}</small>
                <small>{{ item.rak ? item.rak.rak : "-" }}</small>
                <small>{{ item.laci ? item.laci.laci : "-" }}</small> -->
            <!-- </div> -->
          </template>
          <template #cell(stok_ginee)="{ item }">
            {{ item.stok_ginee ? formatRupiah(item.stok_ginee) : 0 }}
          </template>
          <template #cell(stocks)="{ item }">
            <!-- {{ item.stocks }} -->
            <b-card border-variant="secondary" class="mb-0">
              <div class="text-center">
                <template v-for="data in item.stocks">
                  <b-row>
                    <b-col>
                      <div class="text-right">{{ data.blok.blok }}</div>
                    </b-col>
                    <b-col md="1"> : </b-col>
                    <b-col>
                      <template v-if="data.jumlah > data.minimum_stok">
                        <div class="text-left">
                          <b class="text-success">{{
                            formatRupiah(data.jumlah)
                          }}</b>
                        </div>
                      </template>
                      <template v-if="data.jumlah <= data.minimum_stok">
                        <div class="text-left">
                          <b class="text-danger">{{
                            formatRupiah(data.jumlah)
                          }}</b>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </template>
                <b-row v-if="item.stocks.length == 0">
                  <b-col>
                    <small>
                      <span class="text-danger">{{$t("Data not found")}}</span>
                    </small>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="text-right">Total</div>
                  </b-col>
                  <b-col md="1"> : </b-col>
                  <b-col>
                    <!-- {{formatRupiah(item.total_stok)}} -->
                    <template v-if="item.total_stok > totalJumlah(item.stocks)">
                      <div class="text-left">
                        <b class="text-success">{{
                          formatRupiah(item.total_stok)
                        }}</b>
                      </div>
                    </template>
                    <template
                      v-if="item.total_stok <= totalJumlah(item.stocks)">
                      <div class="text-left">
                        <b class="text-danger">{{
                          formatRupiah(item.total_stok)
                        }}</b>
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </div>
              <div class="text-center">
                (Min. Stocks : {{ formatRupiah(totalJumlah(item.stocks)) }})
              </div>
            </b-card>
          </template>
          <template #cell(blok)="{ item }">
            {{ item.blok ? item.blok.blok : "-" }}
          </template>
          <template #cell(palet)="{ item }">
            {{ item.palet ? item.palet.palet : "-" }}
          </template>
          <template #cell(rak)="{ item }">
            {{ item.rak ? item.rak.rak : "-" }}
          </template>
          <template #cell(laci)="{ item }">
            {{ item.laci ? item.laci.laci : "-" }}
          </template>
          <template #cell(jumlah)="{ item }">
            <template v-if="item.jumlah > item.minimum_stok">
              <b class="text-success">{{ item.jumlah ? item.jumlah : 0 }}</b>
              / {{ formatRupiah(item.jumlah * item.harga_dasar) }}<br />
              <small>
                (Konversi Satuan :
                {{ item.konversi_ket == null ? 0 : item.konversi_ket }})
                <br />(Min. Stok = {{ item.minimum_stok }})
              </small>
            </template>
            <template v-if="item.jumlah <= item.minimum_stok">
              <b class="text-danger">{{ item.jumlah ? item.jumlah : 0 }}</b>
              / {{ formatRupiah(item.jumlah * item.harga_dasar) }}<br />
              <small>
                (Konversi Satuan :
                {{ item.konversi_ket == null ? 0 : item.konversi_ket }})
                <br />(Min. Stok = {{ item.minimum_stok }})
              </small>
            </template>
          </template>
          <template #cell(satuan)="{ item }">
            {{ item.konversi_ket }}
          </template>

          <template #cell(selesai)="data">
            <b-badge variant="primary">
              {{
                data.item.status_ket ? data.item.status_ket.toUpperCase() : "-"
              }}
            </b-badge>
          </template>
          <template #cell(jumlah)="{ item }">
            <template v-if="item.jumlah > item.minimum_stok">
              <b class="text-success">{{ item.jumlah ? item.jumlah : 0 }}</b> /
              {{ formatRupiah(item.jumlah * item.barang.harga_dasar) }}<br />
              <small>
                (Konversi Satuan :
                {{ item.konversi_ket == null ? 0 : item.konversi_ket }})
                <br />(Min. Stok = {{ item.minimum_stok }})
              </small>
            </template>
            <template v-if="item.jumlah <= item.minimum_stok">
              <b class="text-danger">{{ item.jumlah ? item.jumlah : 0 }}</b> /
              {{ formatRupiah(item.jumlah * item.barang.harga_dasar) }}<br />
              <small>
                (Konversi Satuan :
                {{ item.konversi_ket == null ? 0 : item.konversi_ket }})
                <br />(Min. Stok = {{ item.minimum_stok }})
              </small>
            </template>
          </template>
          <template #cell(satuan)="{ item }">
            <!-- {{ item.barang.satuan ? item.barang.satuan.satuan : '-' }} -->
            {{ item.konversi_ket }}
          </template>
        </b-table>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div class="alert alert-danger p-2">
          <strong>{{ $t("Data not found") }}</strong>
        </div>
      </template>
    </b-overlay>
    <template #modal-footer>
      <b-button @click.prevent="submit" variant="primary">{{
        $t("Choose Items")
      }}</b-button>
    </template>
  </b-modal>
</template>
<script>
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import {
  BInputGroupPrepend,
  BFormSelectOption,
  BModal,
  BOverlay,
  BFormGroup,
  BInputGroup,
  BFormCheckbox,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BPagination,BCard,
} from "bootstrap-vue";
export default {
  props: ["barangs"],
  components: {
    Viewer,
    BInputGroupPrepend,
    BFormSelectOption,
    BModal,
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTable,
    BRow,
    BCol,
    BFormSelect,
    BPagination,BCard,
  },
  data: () => ({
    loadingkategori: false,
    itemsKategori: [],
    kategori_id: null,
    searchOptions: [],
    customefilter: null,
    kelas_id: null,
    itemsKelas: [],
    loadingkelas: false,
    sortBarang: null,
    is_pre_order: null,
    //List Barang
    category: null,
    categoryOptions: [],
    pageOptions: [10, 20, 50, 100],
    sortBy: "desc",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    perPage: 10,
    totalRows: 1,
    currentPage: 1,
    loading: false,
    allChecked: false,
    selectedBarangs: [],
    fields: [
      { key: "checkbox", label: "#" },
      {
        key: "no",
        label: "No",
      },
      { key: "fotopro", label: "Foto" },
      {
        key: "kode",
        label: "Barcode",
        sortable: true,
      },
      { key: "nama", label: "Nama Barang", sortable: true },
      {
        key: "stok_ginee",
        label: "Qty Ginee",
        // sortable: true,
      },
      {
        key: "stocks",
        label: "STOCK",
        // sortable: true,
      },
      // { key: "varian", label: "Varian", sortable: true },
      // {
      //   key: "jumlah",
      //   label: "Qty WMS",
      //   sortable: true,
      // },
      // {
      //   key: "barang.stok_ginee",
      //   label: "Qty Ginee",
      //   sortable: true,
      // },
      // { key: "actionsCabang", label: "Aksi" },
    ],
    localBarangs: [],
    currentImage: null,
    currentBarcode: null,
  }),
  methods: {
    totalJumlah(item) {
      return item.reduce((total, data) => total + data.minimum_stok, 0);
    },
    getKategori() {
      this.loadingkategori = true;
      this.$store
        .dispatch("kategori/getData")
        .then(() => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.kategori.datas)
          );
          // let items_total = this.$store.state.kategori.totals;
          this.itemsKategori = items;
          this.itemsKategori.map((item) => {
            item.text = item.kategori;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKategori)
          this.loadingkategori = false;
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    getKelas() {
      this.loadingkelas = true;
      this.$store
        .dispatch("kelas/getData")
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
          // let items_total = this.$store.state.kelas.totals;
          this.itemsKelas = items;
          this.itemsKelas.map((item) => {
            item.text = item.kelas;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKelas)
          this.loadingkelas = false;
          // this.items = this.$store.state.kelas.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    openModal(src, src2) {
      // Set gambar saat ini dan buka modal
      this.currentImage = src;
      this.currentBarcode = src2;
      this.$refs.imageModal.show();

      // Inisialisasi Viewer.js
      this.$nextTick(() => {
        if (this.viewer) {
          this.viewer.destroy();
        }
        this.viewer = new Viewer(this.$refs.imageViewer, {
          inline: false,
          zoomable: true,
          scalable: true,
        });
      });
    },
    getDataKategori() {
      this.$store
        .dispatch("kategori/getData", {})
        .then(() => {
          this.categoryOptions = this.$store.state.kategori.datas;
          this.categoryOptions.map((barang) => {
            barang.text = barang.kategori;
            barang.value = barang.id;
          });
          // this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    async getData() {
      // const jenis = this.isOwner ? 1 : 2
      // const payload = {
      //   order: "desc",
      //   start: 1,
      //   length: 10,
      // };
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        order: "desc",
        start: currentPage,
        length: this.perPage,
        type: "barang",
        product_type: "barang",
        kategori_id: this.kategori_id ? this.kategori_id : null,
        sort: this.sortBarang ? this.sortBarang : null,
        is_pre_order: this.is_pre_order,
        hide_empty_stock: 1,

        // filter_by: "nama_lengkap",
      };
      if (this.searchOptions == "kelas_id") {
        payload.kelas_id = this.kelas_id ? this.kelas_id : null;
      }
      if (this.searchOptions == "main_sku") {
        payload.main_sku = this.customefilter ? this.customefilter : null;
      }
      if (this.searchOptions == "kode") {
        payload.kode = this.customefilter ? this.customefilter : null;
      }
      // if (this.isGM) payload.jenis = 2;
      this.$store
        .dispatch("barang/getDatawithstok", payload)
        .then((response) => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.barang.datas)
          );
          let items_total = this.$store.state.barang.totals;
          this.localBarangs = items;
          this.totalRows = items_total;
          this.localBarangs.map((barang) => {
            barang.qty = 0;
            barang.diskon = 0;
            barang.harga_beli = 0;
            barang.isChangePrice = false;
            barang.harga_dasar_baru = 0; // bila ingin merubha harga dasar
          });
          this.loading = false;
        })
        .catch((e) => {
          // this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });
    },
    doFormatRupiahHB(index) {
      const barangs = Object.assign([], this.localBarangs);
      barangs[index].harga_beli = this.formatRupiah(barangs[index].harga_beli);
      // update reactivity
      this.localBarangs = barangs;
    },
    doFormatRupiahHDB(index) {
      const barangs = Object.assign([], this.localBarangs);
      barangs[index].harga_dasar_baru =
        (parseInt(
          this.unFormatRupiah(barangs[index].varian_item[0].harga_dasar)
        ) *
          parseInt(
            barangs[index].available_stock
              ? this.unFormatRupiah(barangs[index].available_stock)
              : 0
          ) +
          parseInt(this.unFormatRupiah(barangs[index].harga_beli)) *
            parseInt(this.unFormatRupiah(barangs[index].qty))) /
        (parseInt(
          barangs[index].available_stock
            ? this.unFormatRupiah(barangs[index].available_stock)
            : 0
        ) +
          parseInt(this.unFormatRupiah(barangs[index].qty)));
      // barangs[index].harga_dasar_baru = (this.formatRupiah(barangs[index].harga_dasar) * this.formatRupiah(barangs[index].available_stock)) + (this.formatRupiah(barangs[index].harga_beli) * this.formatRupiah(barangs[index].qty))
      // barangs[index].harga_dasar_baru = this.formatRupiah(barangs[index].harga_dasar_baru)
      // update reactivity
      // console.log('CEKKK', barangs)
      this.localBarangs = barangs;
    },
    doFormatRupiahDiskon(index) {
      const barangs = Object.assign([], this.localBarangs);
      barangs[index].diskon = this.formatRupiah(barangs[index].diskon);
      // update reactivity
      this.localBarangs = barangs;
    },
    onChangePrice(index) {
      const barangs = Object.assign([], this.localBarangs);
      const isThereChange = this.localBarangs.some(
        (barang) => barang.isChangePrice == true
      );
      barangs[index].harga_dasar_baru =
        (parseInt(
          this.unFormatRupiah(barangs[index].varian_item[0].harga_dasar)
        ) *
          parseInt(
            barangs[index].available_stock
              ? this.unFormatRupiah(barangs[index].available_stock)
              : 0
          ) +
          parseInt(this.unFormatRupiah(barangs[index].harga_beli)) *
            parseInt(this.unFormatRupiah(barangs[index].qty))) /
        (parseInt(
          barangs[index].available_stock
            ? this.unFormatRupiah(barangs[index].available_stock)
            : 0
        ) +
          parseInt(this.unFormatRupiah(barangs[index].qty)));

      if (isThereChange) {
        this.fields = [
          { key: "checkbox", label: "#" },
          { key: "fotopro", label: "Foto" },
          { key: "kode", label: "Barcode" },
          // { key: 'kategori', label: 'Kategori' },
          { key: "nama", label: "Nama Barang" },
          // { key: 'varian', label: 'Varian', sortable: true },
          { key: "harga_dasar", label: "Harga Beli Awal" },
          { key: "harga_beli", label: "Harga Beli Supplier" },
          { key: "isChangePrice", label: "Ubah Harga Dasar" },
          { key: "harga_dasar_baru", label: "Harga Baru" },
          { key: "diskon", label: "Diskon" },
          { key: "available_stock", label: "Qty Stok" },
          { key: "forecast_stock", label: "Qty Forecast" },
          { key: "qty", label: "Qty" },
          { key: "satuan", label: "Satuan" },
        ];

        setTimeout(() => {
          this.localBarangs = barangs;
        }, 500);
      } else {
        this.fields = [
          { key: "checkbox", label: "#" },
          { key: "fotopro", label: "Foto" },
          { key: "kode", label: "Barcode" },
          // { key: 'kategori', label: 'Kategori' },
          { key: "nama", label: "Nama Barang" },
          // { key: 'varian', label: 'Varian', sortable: true },
          { key: "harga_dasar", label: "Harga Beli Awal" },
          { key: "harga_beli", label: "Harga Beli Supplier" },
          { key: "isChangePrice", label: "Ubah Harga Dasar" },
          { key: "diskon", label: "Diskon" },
          { key: "available_stock", label: "Qty Stok" },
          { key: "forecast_stock", label: "Qty Forecast" },
          { key: "qty", label: "Qty" },
          { key: "satuan", label: "Satuan" },
        ];

        setTimeout(() => {
          this.localBarangs = barangs;
        }, 500);
      }
    },
    submit() {
      if (this.selectedBarangs.length < 1) {
        this.displayError({
          message: "Harap pilih barang!",
        });
        return false;
      }

      const thereZeroQty = this.selectedBarangs.some(
        (barang) => parseInt(barang.qty) < 1
      );
      // if (thereZeroQty) {
      //   this.displayError({
      //     message: 'Kuantitas barang yang dipilih harus melebihi 0!'
      //   })
      //   return false
      // }

      this.selectedBarangs.map((barang) => {
        barang.harga_beli = this.unFormatRupiah(barang.harga_beli);
        barang.harga_dasar_baru = this.unFormatRupiah(barang.harga_dasar_baru);
        barang.diskon = this.unFormatRupiah(barang.diskon);
      });

      this.$emit("submit", this.selectedBarangs);
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.selectedBarangs = this.localBarangs;
      } else {
        this.selectedBarangs = [];
      }
    },
    localBarangs(val) {
      if (val) {
        this.localBarangs = val;
      }
    },

    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
  },

  async mounted() {
    this.getData();
    // this.getDataKategori()
    this.getKategori();
    this.getKelas();
  },
};
</script>
