var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', [[_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.adddisposal($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Add')) + " ")])], 1)], 1), _c('b-col', {
    staticClass: "mb-1 d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.perattach
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "MenuIcon"
    }
  }), _vm._v("Per Attachment")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.peritems
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PackageIcon"
    }
  }), _vm._v("Per Items")], 1)], 1)], 1)], 1)], _vm.mode == 'Per Items' ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('list-items')], 1)], 1)] : _vm._e(), _vm.mode == 'Per Attachment' ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('list-attach')], 1)], 1)] : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }