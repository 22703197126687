<template>
  <main>
    <b-card>
      <b-row>
        <b-col cols="12" class="">
          <b-tabs>
            <b-tab :active="true">
              <template #title>
                {{ $t("Pick Up") }}
                <b-badge class="ml-1" variant="primary">{{
                  pickUp.length
                }}</b-badge>
              </template>
              <b-table
                hover
                responsive
                :items="pickUp"
                :fields="fieldspengiriman"
              >
                <template #head(checkbox)>
                  <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #head(alamat)>
                  {{ $t("Alamat Pickup & Waktu Pickup ")
                  }}<b-link
                    :title="$t('Atur Masal Waktu Pickup')"
                    size="sm"
                    class="text-dark"
                    @click.prevent="aturmass()"
                  >
                    <b-badge variant="primary"
                      ><feather-icon icon="EditIcon"
                    /></b-badge>
                  </b-link>
                  <b-modal
                    size="xl"
                    id="aturmass-modal"
                    ref="aturmassModal"
                    title="Atur Massal Pickup"
                    hide-footer
                  >
                    <form @submit.prevent="applySelections">
                      <b-table
                        :items="computedProviders"
                        :fields="[
                          {
                            key: 'no',
                            label: 'No',
                          },
                          {
                            key: 'logisticsProviderName',
                            label: 'Kurir',
                          },
                          { key: 'addresses', label: 'Alamat Pickup' },
                          { key: 'pickUpTimeList', label: 'Waktu Pickup' },
                        ]"
                        striped
                        hover
                        responsive
                      >
                        <template #cell(no)="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template #cell(logisticsProviderName)="data">
                          {{ data.item.logisticsProviderName }}
                        </template>
                        <template #cell(addresses)="data">
                          <b-form-select
                            v-if="
                              data.item.channel == 'SHOPEE_ID' ||
                              data.item.channel == 'LAZADA_ID'
                            "
                            v-model="selectedAddresses[data.index]"
                            :options="data.item.addressesOptions"
                            class="mb-2"
                            @change="
                              updateSelected(
                                data.index,
                                'selectedAddress',
                                selectedAddresses[data.index]
                              )
                            "
                            required
                          ></b-form-select>
                          <center v-else>
                            <i class="text-danger text-center">Tidak Perlu</i>
                          </center>
                        </template>
                        <template #cell(pickUpTimeList)="data">
                          <b-form-select
                            v-if="
                              data.item.channel == 'TIKTOK_ID' ||
                              data.item.channel == 'LAZADA_ID' ||
                              data.item.channel == 'SHOPEE_ID'
                            "
                            v-model="selectedPickupTimes[data.index]"
                            :options="data.item.pickupTimeOptions"
                            class="mb-2"
                            @change="
                              updateSelected(
                                data.index,
                                'selectedPickupTime',
                                selectedPickupTimes[data.index]
                              )
                            "
                            required
                          ></b-form-select>
                          <center v-else>
                            <i class="text-danger text-center">Tidak Perlu</i>
                          </center>
                        </template>
                      </b-table>
                      <b-button
                        variant="dark"
                        class="text-dark float-right"
                        type="submit"
                      >
                        Arrange Shipment
                      </b-button>
                    </form>
                  </b-modal>
                </template>
                <template #cell(checkbox)="{ item }">
                  <b-form-checkbox
                    v-model="selectedginee"
                    :value="item"
                  ></b-form-checkbox>
                </template>
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>
                <template #cell(pesanan)="{ item }">
                  <span
                    ><b-img
                      :src="getImageUrl(item.channel)"
                      style="width: 15px"
                      :title="item.channel"
                  /></span>
                  {{ item.externalOrderId }}<br />
                  {{ item.orderStatus }}
                </template>
                <template #cell(kurir)="{ item }">
                  {{
                    item.logisticsInfos
                      ? item.logisticsInfos[0].logisticsProviderName
                      : null
                  }}<br />
                  {{ item.logist }}
                  {{
                    item.logisticsInfos
                      ? item.logisticsInfos[0].logisticsTrackingNumber
                      : null
                  }}
                </template>
                <template #cell(alamat)="{ item }">
                  <div v-if="item.channel != 'TOKOPEDIA_ID'">
                    <b-form-select
                      v-if="
                        item.channel == 'SHOPEE_ID' ||
                        item.channel == 'LAZADA_ID'
                      "
                      v-model="item.selected"
                    >
                      <!-- Slot untuk mengubah opsi pertama, jika diperlukan -->
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >Pilih Alamat Pickup</b-form-select-option
                        >
                      </template>
                      <!-- Slot untuk setiap opsi -->
                      <template v-for="option in item.addresses">
                        <b-form-select-option :value="option">
                          {{ option.address }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-select
                      v-if="item.channel == 'TIKTOK_ID' || item.selected"
                      v-model="item.selected2"
                    >
                      <!-- Slot untuk mengubah opsi pertama, jika diperlukan -->
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >Pilih Waktu Pickup</b-form-select-option
                        >
                      </template>
                      <!-- Slot untuk setiap opsi -->

                      <template
                        v-for="option in item.selected
                          ? item.selected.pickupTimeList
                          : item.addresses[0].pickupTimeList"
                      >
                        <b-form-select-option :value="option">
                          {{
                            option.timeText !== ""
                              ? option.timeText
                              : formatTimestamp(option.pickupStartTime).slice(
                                  0,
                                  5
                                ) +
                                " - " +
                                formatTimestamp(option.pickupEndTime).slice(
                                  0,
                                  5
                                ) +
                                " " +
                                formatTimestamp(option.pickupStartTime).slice(6)
                          }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <center v-else>
                    <i class="text-danger text-center">Tidak Perlu</i>
                  </center>
                </template>
                <template #cell(action)="{ item }">
                  <div
                    v-if="
                      item.channel == 'SHOPEE_ID' || item.channel == 'LAZADA_ID'
                    "
                  >
                    <b-button
                      v-if="item.selected && item.selected2"
                      size="sm"
                      variant="dark"
                      class="mt-2 text-dark"
                      @click.prevent="aturpengirimansingle(item)"
                    >
                      {{ $t("Arrange Shipment") }}
                    </b-button>
                    <b-button
                      v-else
                      size="sm"
                      variant="dark"
                      class="mt-2 text-dark"
                      disabled
                    >
                      {{ $t("Arrange Shipment") }}
                    </b-button>
                  </div>
                  <div v-else-if="item.channel == 'TIKTOK_ID'">
                    <b-button
                      v-if="item.selected2"
                      size="sm"
                      variant="dark"
                      class="mt-2 text-dark"
                      @click.prevent="aturpengirimansingle(item)"
                    >
                      {{ $t("Arrange Shipment") }}
                    </b-button>
                    <b-button
                      v-else
                      size="sm"
                      variant="dark"
                      class="mt-2 text-dark"
                      disabled
                    >
                      {{ $t("Arrange Shipment") }}
                    </b-button>
                  </div>
                  <div v-else>
                    <!-- <b-form-input
                      type="number"
                      v-model="item.logisticsTrackingNumber"
                      placeholder="Tracking No"
                    ></b-form-input> -->
                    <b-button
                      size="sm"
                      variant="dark"
                      class="mt-2 text-dark"
                      @click.prevent="aturpengirimansingle(item)"
                    >
                      {{ $t("Arrange Shipment") }}
                    </b-button>
                  </div>
                  <br />
                </template>
              </b-table>
            </b-tab>
            <b-tab title="Drop Off">
              <template #title>
                {{ $t("Drop Off") }}
                <b-badge class="ml-1" variant="primary">{{
                  dropOff.length
                }}</b-badge>
              </template>

              <b-table
                small
                hover
                responsive
                :items="dropOff"
                :fields="fieldspengiriman"
              >
                <template #head(checkbox)>
                  <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #cell(checkbox)="{ item }">
                  <b-form-checkbox
                    v-model="selectedginee"
                    :value="item"
                  ></b-form-checkbox>
                </template>
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(pesanan)="{ item }">
                  {{ item.externalOrderId }}
                </template>

                <template #cell(kurir)="{ item }">
                  {{
                    item.logisticsInfos
                      ? item.logisticsInfos[0].logisticsProviderName
                      : null
                  }}<br />
                  {{
                    item.logisticsInfos
                      ? item.logisticsInfos[0].logisticsTrackingNumber
                      : null
                  }}<br /><br />
                  <!-- {{ item.customerInfo ? item.customerInfo.name : null }}<br>
              {{ item.customerInfo ? item.customerInfo.mobile : null }}<br><br>
              {{ $t('Note By Buyer') }} : <br> -->
                  <!-- <template v-if="item.extraInfo.noteByBuyer">
                <p
                  :title="item ? item.extraInfo ? item.extraInfo.noteByBuyer == '' && item.extraInfo.noteByBuyer == null ? null : item.extraInfo.noteByBuyer : null : null">
                  <b-img src="@/assets/images/svg/message.svg" style="width: 20px;" alt="logo" />
                </p>
              </template>
    <template v-else>
                <p class="text-danger">{{ $t('Note not found') }}</p>
              </template> -->
                  <!-- <p><b>{{ item ? item.extraInfo ? item.extraInfo.noteByBuyer : null : null }}</b></p> -->
                </template>
                <template #cell(outbondorder)="{ item }">
                  <b-alert variant="primary" show></b-alert>
                  <template v-for="hem in item.items">
                    <b-card
                      bg-variant="transparent"
                      no-body
                      class="overflow-hidden"
                    >
                      <b-row no-gutters>
                        <b-col md="9">
                          <!-- <b-card-body :title="hem.productName"> -->
                          <b-card-text>
                            <!-- <span :title="hem.productName">{{ truncateString(hem.productName,
														20) }}</span><br>
													{{ hem.variationName }}<br>
													{{ hem.sku }}<br>
													{{ formatRupiah(hem.actualPrice) }} x {{ hem.quantity }} -->
                          </b-card-text>
                          <!-- </b-card-body> -->
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </template>
                <template #cell(status_bayar)="{ item }">
                  <b-badge v-if="item.status_bayar == 'lunas'" variant="success"
                    >LUNAS</b-badge
                  >
                  <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
                </template>

                <template #cell(action)="{ item }">
                  <b-link
                    size="sm"
                    variant="dark"
                    class="text-dark"
                    @click.prevent="aturpengirimansingle(item)"
                  >
                    <feather-icon icon="CalendarIcon" />
                    {{ $t("Arrange Shipment") }}
                  </b-link>
                  <br />
                  <b-link
                    size="sm"
                    variant="dark"
                    class="text-dark"
                    @click.prevent="printdoc(item)"
                  >
                    <feather-icon icon="PrinterIcon" /> Print Label
                  </b-link>
                  <br />
                  <!-- <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                  printmodalinv(item)
                  ">
                  <feather-icon icon="PrinterIcon" /> Print Invoice
                </b-link>
                <br> -->
                  <!-- <template v-for="hem in item.items"> -->
                  <b-link
                    size="sm"
                    variant="dark"
                    class="text-dark"
                    @click.prevent="getCustomerFrame(item)"
                  >
                    <feather-icon icon="BoxIcon" /> Process to Warehouse
                  </b-link>
                  <!-- </template> -->
                  <!-- <template v-for=" hem in item.items">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="printdoc(item)"
                    v-if="!hem.productName.match(/lensa/gi)">
                    <feather-icon icon="BoxIcon" /> Process Warehouse
                  </b-link>
                </template> -->
                  <b-button
                    v-if="
                      item.transaction_type == 'jasa' &&
                      item.status != 'dibatalkan'
                    "
                    size="sm"
                    variant="outline-primary"
                    @click.prevent="modalTerapis(item)"
                    v-b-tooltip.hover.top="'Pilih Terapis'"
                  >
                    <feather-icon icon="UserIcon" />
                  </b-button>
                  <b-button
                    v-if="
                      item.status == 0 &&
                      allowUpdate() &&
                      item.tak_tertagih == 0 &&
                      item.bayar == 0
                    "
                    size="sm"
                    variant="outline-warning"
                    @click.prevent="
                      $router.push(`/transaksi-penjualan/edit/${item.id}`)
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
                <template #cell(actionFinance)="{ item }">
                  <b-button
                    size="sm"
                    variant="warning"
                    @click.prevent="
                      $router.push(`/transaksi-penjualan/detail/${item.id}`)
                    "
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    title="Konfirmasi Pembayaran Manual"
                    v-if="item.status_bayar != 'lunas'"
                    size="sm"
                    variant="primary"
                    @click="aksiKonfirmasiawal(item)"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                  <b-button
                    title="Cek Pembayaran Otomatis"
                    v-if="
                      item.status_bayar != 'lunas' &&
                      item.payment_option == 'pg_midtrans'
                    "
                    size="sm"
                    variant="info"
                    @click="aksiKonfirmasipg(item)"
                  >
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="Manual Ship">
              <template #title>
                {{ $t("Manual Ship") }}
                <b-badge class="ml-1" variant="primary">{{
                  manualShip.length
                }}</b-badge>
              </template>

              <b-table
                small
                hover
                responsive
                :items="dropOff"
                :fields="fieldspengiriman"
              >
                <template #head(checkbox)>
                  <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                </template>
                <template #cell(checkbox)="{ item }">
                  <b-form-checkbox
                    v-model="selectedginee"
                    :value="item"
                  ></b-form-checkbox>
                </template>
                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(pesanan)="{ item }">
                  {{ item.externalOrderId }}
                </template>

                <template #cell(kurir)="{ item }">
                  {{
                    item.logisticsInfos
                      ? item.logisticsInfos[0].logisticsProviderName
                      : null
                  }}<br />
                  {{
                    item.logisticsInfos
                      ? item.logisticsInfos[0].logisticsTrackingNumber
                      : null
                  }}<br /><br />
                  <!-- {{ item.customerInfo ? item.customerInfo.name : null }}<br>
              {{ item.customerInfo ? item.customerInfo.mobile : null }}<br><br>
              {{ $t('Note By Buyer') }} : <br> -->
                  <!-- <template v-if="item.extraInfo.noteByBuyer">
                <p
                  :title="item ? item.extraInfo ? item.extraInfo.noteByBuyer == '' && item.extraInfo.noteByBuyer == null ? null : item.extraInfo.noteByBuyer : null : null">
                  <b-img src="@/assets/images/svg/message.svg" style="width: 20px;" alt="logo" />
                </p>
              </template>
    <template v-else>
                <p class="text-danger">{{ $t('Note not found') }}</p>
              </template> -->
                  <!-- <p><b>{{ item ? item.extraInfo ? item.extraInfo.noteByBuyer : null : null }}</b></p> -->
                </template>
                <template #cell(outbondorder)="{ item }">
                  <b-alert variant="primary" show></b-alert>
                  <template v-for="hem in item.items">
                    <b-card
                      bg-variant="transparent"
                      no-body
                      class="overflow-hidden"
                    >
                      <b-row no-gutters>
                        <b-col md="9">
                          <!-- <b-card-body :title="hem.productName"> -->
                          <b-card-text>
                            <!-- <span :title="hem.productName">{{ truncateString(hem.productName,
														20) }}</span><br>
													{{ hem.variationName }}<br>
													{{ hem.sku }}<br>
													{{ formatRupiah(hem.actualPrice) }} x {{ hem.quantity }} -->
                          </b-card-text>
                          <!-- </b-card-body> -->
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                </template>
                <template #cell(status_bayar)="{ item }">
                  <b-badge v-if="item.status_bayar == 'lunas'" variant="success"
                    >LUNAS</b-badge
                  >
                  <b-badge v-else variant="danger">BELUM LUNAS</b-badge>
                </template>

                <template #cell(action)="{ item }">
                  <b-link
                    size="sm"
                    variant="dark"
                    class="text-dark"
                    @click.prevent="aturpengirimansingle(item)"
                  >
                    <feather-icon icon="CalendarIcon" />
                    {{ $t("Arrange Shipment") }}
                  </b-link>
                  <br />
                  <b-link
                    size="sm"
                    variant="dark"
                    class="text-dark"
                    @click.prevent="printdoc(item)"
                  >
                    <feather-icon icon="PrinterIcon" /> Print Label
                  </b-link>
                  <br />
                  <!-- <b-link size="sm" variant="dark" class="text-dark" @click.prevent="
                  printmodalinv(item)
                  ">
                  <feather-icon icon="PrinterIcon" /> Print Invoice
                </b-link>
                <br> -->
                  <!-- <template v-for="hem in item.items"> -->
                  <b-link
                    size="sm"
                    variant="dark"
                    class="text-dark"
                    @click.prevent="getCustomerFrame(item)"
                  >
                    <feather-icon icon="BoxIcon" /> Process to Warehouse
                  </b-link>
                  <!-- </template> -->
                  <!-- <template v-for=" hem in item.items">
                  <b-link size="sm" variant="dark" class="text-dark" @click.prevent="printdoc(item)"
                    v-if="!hem.productName.match(/lensa/gi)">
                    <feather-icon icon="BoxIcon" /> Process Warehouse
                  </b-link>
                </template> -->
                  <b-button
                    v-if="
                      item.transaction_type == 'jasa' &&
                      item.status != 'dibatalkan'
                    "
                    size="sm"
                    variant="outline-primary"
                    @click.prevent="modalTerapis(item)"
                    v-b-tooltip.hover.top="'Pilih Terapis'"
                  >
                    <feather-icon icon="UserIcon" />
                  </b-button>
                  <b-button
                    v-if="
                      item.status == 0 &&
                      allowUpdate() &&
                      item.tak_tertagih == 0 &&
                      item.bayar == 0
                    "
                    size="sm"
                    variant="outline-warning"
                    @click.prevent="
                      $router.push(`/transaksi-penjualan/edit/${item.id}`)
                    "
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </template>
                <template #cell(actionFinance)="{ item }">
                  <b-button
                    size="sm"
                    variant="warning"
                    @click.prevent="
                      $router.push(`/transaksi-penjualan/detail/${item.id}`)
                    "
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                  <b-button
                    title="Konfirmasi Pembayaran Manual"
                    v-if="item.status_bayar != 'lunas'"
                    size="sm"
                    variant="primary"
                    @click="aksiKonfirmasiawal(item)"
                  >
                    <feather-icon icon="ListIcon" />
                  </b-button>
                  <b-button
                    title="Cek Pembayaran Otomatis"
                    v-if="
                      item.status_bayar != 'lunas' &&
                      item.payment_option == 'pg_midtrans'
                    "
                    size="sm"
                    variant="info"
                    @click="aksiKonfirmasipg(item)"
                  >
                    <feather-icon icon="DollarSignIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
    <!-- {{ computedProviders }} -->
  </main>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BAlert,
  BPopover,
  VBPopover,
  BFormRadio,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BLink,
  BTable,
  BCard,
  BCardBody,
  BCardImg,
  BCardText,
  BCardTitle,
  VBTooltip,
  BTabs,
  BTab,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormDatepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFDocument } from "pdf-lib";

export default {
  mixins: [mixin],
  components: {
    flatPickr,
    jsPDF,
    BFormDatepicker,
    BPopover,
    BTabs,
    BTab,
    BFormSpinbutton,
    BAlert,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BLink,
    ToastificationContent,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,
    BCardBody,
    BCardImg,
    BCardText,
    BCardTitle,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BImg,
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      ship: [],
      test: [],
      itemData: [],
      selectedginee: [],
      fieldspengiriman: [
        // {
        //   key: "checkbox",
        //   label: "#",
        //   thStyle: { width: "2%" },
        //   tdClass: "text-left",
        // },
        {
          key: "no",
          label: "No",
          thStyle: { width: "2%" },
          tdClass: "text-left",
        },
        {
          key: "pesanan",
          label: "Pesanan",
          thStyle: { width: "20%" },
          tdClass: "text-left",
        },
        {
          key: "kurir",
          label: "Kurir",
          thStyle: { width: "20%" },
          tdClass: "text-left",
        },
        {
          key: "alamat",
          label: "Alamat Pickup & Waktu Pickup",
          thStyle: { width: "30%" },
          tdClass: "text-right",
        },
        {
          key: "action",
          label: "Operate",
          thStyle: { width: "20%" },
          tdClass: "text-left",
        },
      ],
      allChecked: false,
      pickUp: [],
      dropOff: [],
      manualShip: [],
      selected: null,
      selected2: null,
      selectedAddresses: [],
      selectedPickupTimes: [],
    };
  },
  computed: {
    formattedProviders() {
      const uniqueProviders = {};
      // Gabungkan hasil dari semua order ke dalam computedProvidersobjek unik
      this.pickUp.forEach((order) => {
        this.separateByLogisticsProvider(order).forEach((provider) => {
          // Gunakan providerName sebagai kunci untuk menyimpan penyedia yang unik
          if (!uniqueProviders[provider.logisticsProviderName]) {
            uniqueProviders[provider.logisticsProviderName] = provider;
          }
        });
      });

      // Kembalikan nilai unik sebagai array
      return Object.values(uniqueProviders);
    },
    computedProviders() {
      return this.formattedProviders.map((provider, index) => {
        return {
          ...this.pickUp[index],
          ...provider,
          selectedAddress: this.selectedAddresses[index] || null,
          selectedPickupTime: this.selectedPickupTimes[index] || null,
        };
      });
    },
    isFormValid() {
      return (
        this.selectedAddresses.every((address) => address) && // Semua alamat terisi
        this.selectedPickupTimes.every((time) => time) // Semua waktu pickup terisi
      );
    },
  },
  watch: {
    allChecked(val) {
      if (val) {
        this.selectedginee = this.ship;
      } else {
        this.selectedginee = [];
      }
    },
  },
  methods: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp * 1000); // Konversi dari detik ke milidetik
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const day = date.getUTCDate().toString().padStart(2, "0");
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const year = date.getUTCFullYear();

      return `${hours}:${minutes} ${day}-${month}-${year}`;
    },

    aturpengirimansingle(item) {
      let logistics = item.logistics.find(
        (res) => res.logisticsDeliveryType == "PICK_UP"
      );
      if (item.channel == "SHOPEE_ID") {
        var payload = {
          orderId: item.orderId,
          deliveryType: item.deliveryType,
          shippingProvider:
            logistics.logisticDetailList[0]?.logisticsProviderName,
          timeText: item.selected2?.timeText ?? null,
          pickupTimeId: item.selected2?.pickupTimeId ?? null,
          addressId: item.selected?.addressId ?? null,
          address: item.selected?.address ?? null,
        };
      } else if (item.channel == "TIKTOK_ID") {
        var payload = {
          orderId: item.orderId,
          deliveryType: item.deliveryType,
          shippingProvider:
            logistics.logisticDetailList[0]?.logisticsProviderName,
          // timeText: item.selected2?.timeText ?? null,
          // trackingNo: item.logisticsTrackingNumber ?? null,
          pickupStartTime: item.selected2?.pickupStartTime ?? null,
          pickupEndTime: item.selected2?.pickupEndTime ?? null,
        };
      } else if (item.channel == "LAZADA_ID") {
        var payload = {
          orderId: item.orderId,
          deliveryType: item.deliveryType,
          shippingProvider:
            logistics.logisticDetailList[0]?.logisticsProviderName,
          timeText: item.selected2?.timeText ?? null,
          pickupTimeId: item.selected2?.pickupTimeId ?? null,
          addressId: item.selected?.addressId ?? null,
          address: item.selected?.address ?? null,
        };
      } else if (item.channel == "TOKOPEDIA_ID") {
        var payload = {
          orderId: item.orderId,
          deliveryType: item.deliveryType,
          shippingProvider:
            logistics.logisticDetailList[0]?.logisticsProviderName,
          trackingNo:
            item.logisticsTrackingNumber != ""
              ? item.logisticsTrackingNumber
              : "TKJNT-12345",
        };
      }
      this.loading = true;
      this.$store
        .dispatch("ginee/saveShippingTime", [payload])
        .then((res) => {
          this.loading = false;
          this.displaySuccess({
            message: res.message,
          });
          let params = {
            orderId: item.orderId,
            type: "LABEL",
          };
          this.$swal({
            title: "Sedang Memproses...",
            text: "Tunggu sebentar, proses sedang berjalan.",
            allowOutsideClick: false,

            didOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$store
            .dispatch("ginee/print", params)
            .then((response) => {
              this.$swal.close();
              window.open(response.link, "_blank");
              // console.log('CEK', response)
              // this.loadingresep = false
            })
            .catch((e) => {
              this.$swal.close();
              this.loadingresep = false;
              this.displayError(e);
              return false;
            });
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error fetching data:", error);
          this.displayError(error);
        });

      let productExist = this.itemData.filter(
        (res) => res.order_id == item.order_id
      );
      let jumlahchooselensa = productExist
        .filter((group) =>
          group.items.some(
            (item) => !/Frame|Pembersih|Rubber/i.test(item.productName)
          )
        )
        .map((group) => ({
          ...group, // Salin semua properti dari grup
          items: group.items, // Pertahankan semua items di grup
        }));
      if (jumlahchooselensa.length > 0) {
        sessionStorage.setItem(
          "itemChooselensa",
          JSON.stringify(jumlahchooselensa)
        );
        const routeData = this.$router.resolve({
          path: "/pesanan/chooselensa",
          name: "route-pesanan-chooselensa",
          // params: { id: item.orderId } // contoh parameter
        });
        window.open(routeData.href, "_blank");
      }

      let jumlahBukanChooselensa = productExist.filter(
        (group) => !jumlahchooselensa.includes(group)
      );

      if (jumlahBukanChooselensa.length > 0) {
        const payload = jumlahBukanChooselensa.map((item) => ({
          no_invoice: item.externalOrderId,
          order_type: "ginee",
        }));
        this.$store
          .dispatch("pesanan/cutoff", payload)
          .then(() => {
            this.displaySuccess({
              text: "Cutoff pesanan berhasil disimpan",
            });
          })
          .catch((e) => {
            this.$swal.close();
            this.loadingresep = false;
            this.displayError(e);
            return false;
          });
      }
    },

    applySelections() {
      // console.log('tablemodal', this.selectedAddresses[0]);

      // Iterasi setiap provider dalam formattedProviders
      var payloads = [];
      this.pickUp.map((item) => {
        let providers = this.computedProviders.find(
          (provider) =>
            provider.channel == item.channel &&
            provider.logisticsInfos[0].logisticsProviderName ==
              item.logisticsInfos[0].logisticsProviderName
        );
        // Terapkan pemilihan ke semua item ship yang sesuai dengan nama penyedia logistik
        let logistics = providers.logistics.find(
          (res) => res.logisticsDeliveryType == "PICK_UP"
        );
        if (
          logistics.logisticDetailList[0].logisticsProviderName ===
          providers.logisticsProviderName
        ) {
          item.selected = providers.selectedAddress;
          item.selectedPickupTime = providers.selectedPickupTime;
          // Pastikan selectedAddress memiliki properti pickupTimeId
        }
        if (item.channel == "SHOPEE_ID") {
          var payload = {
            orderId: item.orderId,
            deliveryType: item.deliveryType,
            shippingProvider:
              logistics.logisticDetailList[0]?.logisticsProviderName,
            timeText: item.selectedPickupTime?.timeText ?? null,
            pickupTimeId: item.selectedPickupTime?.pickupTimeId ?? null,
            addressId: item.selected?.addressId ?? null,
            address: item.selected?.address ?? null,
          };
        } else if (item.channel == "TIKTOK_ID") {
          var payload = {
            orderId: item.orderId,
            deliveryType: item.deliveryType,
            shippingProvider:
              logistics.logisticDetailList[0]?.logisticsProviderName,
            // timeText: item.selectedPickupTime?.timeText ?? null,
            // trackingNo: item.logisticsTrackingNumber ?? null,
            pickupStartTime: item.selectedPickupTime?.pickupStartTime ?? null,
            pickupEndTime: item.selectedPickupTime?.pickupEndTime ?? null,
          };
        } else if (item.channel == "LAZADA_ID") {
          var payload = {
            orderId: item.orderId,
            deliveryType: item.deliveryType,
            shippingProvider:
              logistics.logisticDetailList[0]?.logisticsProviderName,
            timeText: item.selectedPickupTime?.timeText ?? null,
            pickupTimeId: item.selectedPickupTime?.pickupTimeId ?? null,
            addressId: item.selected?.addressId ?? null,
            address: item.selected?.address ?? null,
          };
        } else if (item.channel == "TOKOPEDIA_ID") {
          var payload = {
            orderId: item.orderId,
            deliveryType: item.deliveryType,
            shippingProvider:
              logistics.logisticDetailList[0]?.logisticsProviderName,
            trackingNo:
              item.logisticsTrackingNumber != ""
                ? item.logisticsTrackingNumber
                : "TKJNT-12345",
          };
        }
        payloads.push(payload);
      });
      this.loading = true;
      this.$store
        .dispatch("ginee/saveShippingTime", payloads)
        .then((res) => {
          this.loading = false;
          this.displaySuccess({
            message: res.message,
          });
          this.$swal({
            title: "Sedang Memproses...",
            html: "Proses: <b>0%</b>",
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading();
            },
          });

          let pdfLinks = [];
          const delay = (ms) =>
            new Promise((resolve) => setTimeout(resolve, ms));

          const updateProgress = (current, total) => {
            const percentage = Math.round((current / total) * 100);
            this.$swal.update({
              html: `Proses: <b>${percentage}%</b>`,
            });
          };

          const processSequentially = async () => {
            await this.pickUp.reduce(async (prevPromise, item, index) => {
              await prevPromise;

              let params = {
                orderId: item.orderId,
                type: "LABEL",
              };

              try {
                const response = await this.$store.dispatch(
                  "ginee/print",
                  params
                );
                if (response.link) {
                  pdfLinks.push(response.link);
                }
              } catch (e) {
                this.displayError(e);
              }

              // Update progres di SweetAlert
              updateProgress(index + 1, this.pickUp.length);

              return delay(1000);
            }, Promise.resolve());
          };

          processSequentially()
            .then(async () => {
              if (pdfLinks.length > 0) {
                try {
                  // Gabungkan semua PDF
                  const mergedPdf = await this.mergePdfs(pdfLinks);

                  // Buat objek URL dari Blob dan buka di tab baru
                  const blob = new Blob([mergedPdf], {
                    type: "application/pdf",
                  });
                  let init = await this.$store.dispatch(
                    "pesanan/initTaskPrint"
                  );
                  let taskId = init.taskId;

                  const formData = new FormData();
                  formData.append("taskId", taskId);
                  formData.append("file", blob, "merged.pdf");

                  await this.$store.dispatch(
                    "pesanan/uploadMergeDoc",
                    formData
                  );

                  const url = URL.createObjectURL(blob);
                  window.open(url, "_blank"); // Membuka PDF gabungan di tab baru
                } catch (error) {
                  console.error("Error merging PDFs:", error);
                  this.$swal({
                    title: "Gagal",
                    text: "Terjadi kesalahan saat menggabungkan PDF.",
                    icon: "error",
                  });
                }
              } else {
                this.$swal({
                  title: "Gagal",
                  text: "Tidak dapat menghasilkan tautan PDF.",
                  icon: "error",
                });
              }
            })
            .finally(() => {
              this.$swal.close();
            });
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error fetching data:", error);
          this.displayError(error);
        });

      let jumlahchooselensa = this.itemData
        .filter((group) =>
          group.items.some(
            (item) => !/Frame|Pembersih|Rubber/i.test(item.productName)
          )
        )
        .map((group) => ({
          ...group, // Salin semua properti dari grup
          items: group.items, // Pertahankan semua items di grup
        }));
      if (jumlahchooselensa.length > 0) {
        sessionStorage.setItem(
          "itemChooselensa",
          JSON.stringify(jumlahchooselensa)
        );
        const routeData = this.$router.resolve({
          path: "/pesanan/chooselensa",
          name: "route-pesanan-chooselensa",
          // params: { id: item.orderId } // contoh parameter
        });
        window.open(routeData.href, "_blank");
      }

      let jumlahBukanChooselensa = this.itemData.filter(
        (group) => !jumlahchooselensa.includes(group)
      );
      if (jumlahBukanChooselensa.length > 0) {
        const payload = jumlahBukanChooselensa.map((item) => ({
          no_invoice: item.externalOrderId,
          order_type: "ginee",
        }));
        this.$store
          .dispatch("pesanan/cutoff", payload)
          .then(() => {
            this.displaySuccess({
              text: "Cutoff pesanan berhasil disimpan",
            });
          })
          .catch((e) => {
            this.$swal.close();
            this.loadingresep = false;
            this.displayError(e);
            return false;
          });
      }
    },

    async mergePdfs(links) {
      const pdfDoc = await PDFDocument.create();

      for (const link of links) {
        try {
          // Panggil proxy backend melalui Vuex
          const existingPdfBytes = await this.$store.dispatch(
            "ginee/proxyPrint",
            {
              url: link,
            }
          );
          const existingPdf = await PDFDocument.load(existingPdfBytes);
          const copiedPages = await pdfDoc.copyPages(
            existingPdf,
            existingPdf.getPageIndices()
          );

          // Tambahkan halaman dari PDF yang sudah diunduh
          copiedPages.forEach((page) => pdfDoc.addPage(page));
        } catch (error) {
          console.error("Error processing PDF from:", link, error);
        }
      }

      // Simpan PDF gabungan
      return await pdfDoc.save();
    },

    separateByLogisticsProvider(order, orderIndex) {
      const providers = {};

      order.logistics.forEach((logistic) => {
        logistic.logisticDetailList.forEach((detail) => {
          const providerName = detail.logisticsProviderName;

          if (!providerName) return;

          // Inisialisasi provider jika belum ada
          if (!providers[providerName]) {
            providers[providerName] = {
              logisticsProviderName: providerName,
              addressesOptions: [],
              pickupTimeOptions: [],
            };
          }

          // Tambahkan alamat dan waktu pickup jika belum ada
          if (order.addresses) {
            order.addresses.forEach((address) => {
              // Tambahkan alamat jika belum ada di addressesOptions
              if (
                !providers[providerName].addressesOptions.some(
                  (opt) => opt.value.addressId === address.addressId
                )
              ) {
                providers[providerName].addressesOptions.push({
                  text: address.address,
                  value: address, // Menyimpan seluruh objek address
                });
              }

              // Tambahkan seluruh objek pickupTime jika belum ada di pickupTimeOptions
              if (address.pickupTimeList) {
                address.pickupTimeList.forEach((pickupTime) => {
                  let providersItems =
                    providers[providerName].pickupTimeOptions;

                  if (
                    !providers[providerName].pickupTimeOptions.some((opt) => {
                      return (
                        opt.value != undefined &&
                        opt?.value.timeText === pickupTime.timeText
                      );
                    })
                  ) {
                    providers[providerName].pickupTimeOptions.push({
                      text: pickupTime.timeText,
                      value: pickupTime,
                    });
                  }
                });
              }
            });
          }
        });
      });

      // Set default selected values untuk setiap index di tabel
      const providerKeys = Object.keys(providers);
      this.selectedAddresses[orderIndex] =
        providerKeys.length > 0
          ? providers[providerKeys[0]].addressesOptions[0]?.value || null
          : null;
      this.selectedPickupTimes[orderIndex] =
        providerKeys.length > 0
          ? providers[providerKeys[0]].pickupTimeOptions[0]?.value || null
          : null;

      return Object.values(providers);
    },

    updateSelected(index, key, value) {
      if (key === "selectedAddress") {
        this.selectedAddresses[index] = value;
      } else if (key === "selectedPickupTime") {
        this.selectedPickupTimes[index] = value;
      }
    },

    getImageUrl(channel) {
      // Menggunakan URL langsung ke folder public
      return `/assets/svg/${channel}.svg`;
    },
    getShip(data) {
      const payload = {
        orderIds: data.join(","),
      };
      // payload.channel = [this.channel]
      this.loading = true;
      this.$store
        .dispatch("ginee/getShip", payload)
        .then((res) => {
          this.ship = res.data.data;
          // this.test = res.data.data;
          // this.totalRows = res.data.recordsTotal;
          this.itemData.forEach((newOrder) => {
            const matchingShip = this.ship.find(
              (shipOrder) => shipOrder.orderId === newOrder.orderId
            );
            console.log("matchingShip", matchingShip);
            console.log("newOrder", newOrder);

            if (matchingShip) {
              // Jika orderId ditemukan, gabungkan data dari newOrder ke matchingShip
              Object.assign(
                matchingShip,
                { externalOrderId: newOrder.externalOrderId },
                { channel: newOrder.channel },
                { logisticsInfos: newOrder.logisticsInfos }
              );
            } else {
              // Jika orderId tidak ditemukan, tambahkan newOrder sebagai objek baru di ship
              this.ship.push(
                { externalOrderId: newOrder.externalOrderId },
                { channel: newOrder.channel },
                { logisticsInfos: newOrder.logisticsInfos }
              );
            }
          });

          this.ship.forEach((order) => {
            order.logistics.forEach((logistic) => {
              if (logistic.logisticDetailList.length > 0) {
                // Pastikan logisticDetailList tidak kosong
                switch (logistic.logisticsDeliveryType) {
                  case "PICK_UP":
                    this.pickUp.push(order);
                    this.pickUp.map((order) => {
                      this.$set(order, "selected", null);
                      this.$set(order, "selected2", null);
                    });
                    break;
                  case "DROP_OFF":
                    this.dropOff.push(order);
                    break;
                  case "MANUAL_SHIP":
                    this.manualShip.push(order);
                    break;
                }
              }
            });
          });
          this.loading = false;
          // this.getGineeChannel()
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.loading = false;
        });
    },
    aturmass() {
      this.$refs.aturmassModal.show();
    },
  },
  async mounted() {},
  async created() {
    this.pickUp = null;
    this.dropOff = null;
    this.manualShip = null;
    this.pickUp = [];
    this.dropOff = [];
    this.manualShip = [];
    // Di halaman baru
    this.itemData = JSON.parse(sessionStorage.getItem("itemDatapengiriman"));
    const data = this.itemData.map((order) => order.orderId);
    this.getShip(data);
    // Jangan lupa menghapus data setelah digunakan jika diperlukan
    sessionStorage.removeItem("itemData");
  },
};
</script>
