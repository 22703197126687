<template>
    <b-modal size="xl" id="ajuan-modal" :no-close-on-backdrop="true">
        <b-row>
            <b-col cols="12">
                <b-form-group label="Pilih Daftar Ajuan Barang">
                    <v-select :options="ajuan" label="text" v-model="selectedAjuan" :reduce="option => option.value" />
                </b-form-group>
            </b-col>
            <b-col v-if="barangs.length > 0" cols="12">
                <b-table
                    striped
                    hover
                    small
                    responsive
                    :fields="fields"
                    :items="barangs"
                >
                    <template #head(checkbox)>
                        <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                    </template>
                    <template #cell(checkbox)="{item}">
                        <b-form-checkbox :value="item" v-model="selectedBarangs"></b-form-checkbox>
                    </template>
                    <template #cell(qty)="{item, index}">
                        <b-form-input type="number" @keyup="onSatuanKonversiSelected(index)" v-model="item.qty" class="w-75"></b-form-input>
                    </template>
                    <template #cell(harga_jual)="{item}">
                        {{ formatRupiah(item.harga_jual) }}
                    </template>
                    <template #cell(stocks)="{item}">
                        {{ item.stocks.reduce((total, item) => total += item.jumlah, 0) }}
                    </template>
                    <template #cell(satuan)="{item, index}">
                        <b-form-select @change="onSatuanKonversiSelected(index)" v-model="item.selectedKonversi" v-if="item.konversi && item.konversi.length > 0" :options="item.konversi"></b-form-select>
                        <span v-else>
                            {{ item.satuan ? item.satuan.satuan : '-' }}
                        </span>
                    </template>
                    <template #cell(converted)="{item}">
                        <strong v-if="item.konversi && item.konversi.length > 0">
                            {{ item.converted }} <span v-if="item.converted > 0">{{ item.satuan ? item.satuan.satuan.toLowerCase() : '' }}</span>
                        </strong>
                        <i v-else class="text-danger">
                            <small>Satuan Konversi belum diinput</small>
                        </i>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <template #modal-footer>
            <b-button variant="warning" @click.prevent="chooseBarang">{{$t("Choose Items")}}</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BRow, BCol, BButton, BFormGroup, BFormCheckbox, BFormInput, BTable, BFormSelect} from 'bootstrap-vue'
import vSelect from 'vue-select'
import mixin from '../mixin'
export default {
    props: ['ajuanList', 'konsumen'],
    components: {
        vSelect, BRow, BCol, BFormGroup, BFormCheckbox, BFormInput, BTable, BButton, BFormSelect
    },
    mixins: [mixin],
    data: () => ({
        allChecked: false,
        ajuan: [],
        barangs: [],
        selectedBarangs: [],
        selectedAjuan: null,
        fields: [
            { key: 'checkbox', label: 'No' },
            { key: 'nama', label: 'Barang' },
            { key: 'harga', label: 'Harga' },
            { key: 'stocks', label: 'Stok Mobil' },
            { key: 'qty', label: 'Jumlah' },
            { key: 'satuan', label: 'Satuan' },
            { key: 'converted', label: 'Jumlah Setelah Konversi' }
        ]
    }),
    methods: {
        async onSatuanKonversiSelected(index) {
            const barangs = Object.assign([], this.barangs)
            const barang = barangs[index]
            const qtyRequest = barang.qty

            if(barang.selectedKonversi && qtyRequest > 0) {
                const konversi = barang.selectedKonversi
                
                // set payload for konversi
                const payload = {
                    tipe: 2, // konversi ke satuan terkecil, 1 sebaliknya
                    id_barang: konversi.id_barang,
                    id_satuan: konversi.id_satuan,
                    jumlah: qtyRequest
                }
                const hasilKonversi = await this.$store.dispatch('konversi/konversiBarang', payload)
                barangs[index].converted = hasilKonversi

                // update reactivity of array
                this.barangs = barangs
            }
        },
        chooseBarang() {
            this.selectedBarangs.map(barang => {
                barang.old_qty = barang.qty
                barang.qty = barang.converted && barang.converted > 0 ? barang.converted : barang.qty
            })
            this.$emit('selected-barang', this.selectedBarangs)
            this.$bvModal.hide('ajuan-modal')
        },
        async getBarangAjuan(ajuanId) {
            const rincians = await this.$store.dispatch('requestbarangrincian/getData', {
                request_id: ajuanId
            })
            let barangs = []
            rincians.map(rincian => {
                const barangId = rincian.barang && rincian.barang.id ? rincian.barang.id : null
                const find = barangs.find(barang => barang.id == barangId)
                if(!find) {
                    // find harga jual by tipe konsumen (Agen, Grosir, dll)
                    let currentHarga = 0,
                        currentHargaId = null
                    const therePrice = rincian.barang && (rincian.barang.harga_jual && rincian.barang.harga_jual.length > 0)
                    if(therePrice) {
                        currentHarga = rincian.barang.harga_jual.find( harga => harga.tipe_konsumen_id == this.konsumen.tipe_konsumen )
                        if(currentHarga) {
                            currentHargaId = currentHarga.id
                            currentHarga = currentHarga.harga
                        }
                    }
                    if(rincian.barang.konversi) {
                        rincian.barang.konversi.map(konversi => {
                            konversi.value = konversi
                            konversi.text = konversi.satuan ? konversi.satuan.satuan : konversi.id_satuan
                        })
                    }
                    barangs.push({
                        checked: false,
                        qty: 0,
                        converted: null,
                        selectedKonversi: null,
                        harga: currentHarga,
                        harga_id: currentHargaId,
                        ...rincian.barang
                    })
                }
            })

            // get stocks mobil for barang
            barangs = await this.getStockMobil(barangs)

            this.barangs = barangs
        }
    },
    watch: {
        konsumen(val) {
            if(val) {
                this.barangs = []
                this.selectedAjuan = null
                this.$emit('changeKonsumen', true)
            }
            else {
                this.$emit('changeKonsumen', true)
            }
        },
        selectedAjuan(ajuanId) {
            if(ajuanId) {
                // get barang ajuan
                this.getBarangAjuan(ajuanId)
            }
        },
        ajuanList(list) {
            if(list) {
                list.map(ajuan => {
                    this.ajuan.push({
                        value: ajuan.id,
                        text: `${ajuan.tanggal_waktu} - ${ajuan.keterangan}`
                    })
                })
            }
        },
        allChecked(val) {
            if(val) {
                this.selectedBarangs = this.barangs
            }
            else {
                this.selectedBarangs = []
            }
        }
    }
}
</script>