export default [
  {
    path: "/dashboard",
    name: "my-dashboard",
    component: () => import("@/views/pages/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      authRequired: true,
    },
  },

  // Sales
  {
    path: "/transaksi",
    name: "route-transaksi",
    component: () => import("@/views/pages/transaksi/List.vue"),
    meta: {
      module: "Transaksi",
      authRequired: true,
      pageTitle: "Transaksi",
      breadcrumb: [
        {
          text: "Transaksi",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/hutang",
  //   name: "route-hutang",
  //   component: () => import("@/views/pages/hutang/List.vue"),
  //   meta: {
  //     module: "Hutang",
  //     authRequired: true,
  //     pageTitle: "Hutang",
  //     breadcrumb: [
  //       {
  //         text: "Hutang",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // SPV

  // {
  //   path: "/biaya",
  //   name: "route-biaya",
  //   component: () => import("@/views/pages/biaya/List.vue"),
  //   meta: {
  //     module: "Ajuan BOP",
  //     authRequired: true,
  //     pageTitle: "Biaya Operasional Sales",
  //     breadcrumb: [
  //       {
  //         text: "Biaya Operasional Sales",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: "/biaya-operasional",
  //   name: "route-biaya-operasional",
  //   component: () => import("@/views/pages/biayaoperasional/List.vue"),
  //   meta: {
  //     module: "Operasional Gudang",
  //     authRequired: true,
  //     pageTitle: "Operasional Gudang",
  //     breadcrumb: [
  //       {
  //         text: "Operasional Gudang",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/biaya-operasional/detail/:id",
  //   name: "route-biaya-operasional",
  //   component: () => import("@/views/pages/biayaoperasional/Store.vue"),
  //   meta: {
  //     parent: "/biaya-operasional",
  //     module: "Operasional Gudang",
  //     authRequired: true,
  //     pageTitle: "Operasional Gudang",
  //     breadcrumb: [
  //       {
  //         text: "Operasional Gudang",
  //         to: "/biaya-operasional",
  //       },
  //       {
  //         text: "Rincian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/barang-entertain",
  //   name: "route-barang-entertain",
  //   component: () => import("@/views/pages/barangentertain/List.vue"),
  //   meta: {
  //     module: "Barang Entertain",
  //     authRequired: true,
  //     pageTitle: "Barang Entertain",
  //     breadcrumb: [
  //       {
  //         text: "Barang Entertain",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/barang-entertain/detail/:id",
  //   name: "route-barang-entertain",
  //   component: () => import("@/views/pages/barangentertain/Store.vue"),
  //   meta: {
  //     parent: "/barang-entertain",
  //     module: "Barang Entertain",
  //     authRequired: true,
  //     pageTitle: "Barang Entertain",
  //     breadcrumb: [
  //       {
  //         text: "Barang Entertain",
  //         to: "/barang-entertain",
  //       },
  //       {
  //         text: "Rincian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: "/biaya-entertain",
  //   name: "route-biaya-entertain",
  //   component: () => import("@/views/pages/biayaentertain/List.vue"),
  //   meta: {
  //     module: "Biaya Entertain",
  //     authRequired: true,
  //     pageTitle: "Biaya Entertain",
  //     breadcrumb: [
  //       {
  //         text: "Biaya Entertain",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/biaya-entertain/detail/:id",
  //   name: "route-biaya-entertain",
  //   component: () => import("@/views/pages/biayaentertain/Store.vue"),
  //   meta: {
  //     parent: "/biaya-entertain",
  //     module: "Biaya Entertain",
  //     authRequired: true,
  //     pageTitle: "Biaya Entertain",
  //     breadcrumb: [
  //       {
  //         text: "Biaya Entertain",
  //         to: "/biaya-entertain",
  //       },
  //       {
  //         text: "Rincian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/biaya-pajak",
  //   name: "route-biaya-pajak",
  //   component: () => import("@/views/pages/biayapajak/List.vue"),
  //   meta: {
  //     module: "Biaya Pajak",
  //     authRequired: true,
  //     pageTitle: "Biaya Pajak",
  //     breadcrumb: [
  //       {
  //         text: "Biaya Pajak",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/biaya-pajak/detail/:id",
  //   name: "route-biaya-pajak",
  //   component: () => import("@/views/pages/biayapajak/Store.vue"),
  //   meta: {
  //     parent: "/biaya-pajak",
  //     module: "Biaya Pajak",
  //     authRequired: true,
  //     pageTitle: "Biaya Pajak",
  //     breadcrumb: [
  //       {
  //         text: "Biaya Pajak",
  //         to: "/biaya-pajak",
  //       },
  //       {
  //         text: "Rincian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/konsumen/tipe",
  //   name: "route-tipe-konsumen",
  //   component: () => import("@/views/pages/tipe-konsumen/List.vue"),
  //   meta: {
  //     module: "Tipe Konsumen",
  //     authRequired: true,
  //     pageTitle: "Tipe Konsumen",
  //     breadcrumb: [
  //       {
  //         text: "Tipe Konsumen",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/biaya/detail",
  //   name: "route-biaya-detail",
  //   component: () => import("@/views/pages/biaya/DetailList.vue"),
  //   meta: {
  //     module: "Biaya Operasional Sales",
  //     authRequired: true,
  //     pageTitle: "Rincian Biaya Operasional",
  //     breadcrumb: [
  //       {
  //         text: "Rincian Biaya Operasional",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/sales",
  //   name: "route-sales",
  //   component: () => import("@/views/pages/sales/List.vue"),
  //   meta: {
  //     module: "Sales",
  //     authRequired: true,
  //     pageTitle: "Sales",
  //     breadcrumb: [
  //       {
  //         text: "Sales",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/setoran",
  //   name: "route-setoran",
  //   component: () => import("@/views/pages/setoran/List.vue"),
  //   meta: {
  //     module: "Data Setoran",
  //     authRequired: true,
  //     pageTitle: "Data Setoran",
  //     breadcrumb: [
  //       {
  //         text: "Data Setoran",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/setoran/detail/:id",
  //   name: "route-setoran-id",
  //   component: () => import("@/views/pages/setoran/Detail.vue"),
  //   meta: {
  //     parent: "/setoran",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Data Setoran",
  //         to: "/setoran",
  //       },
  //       {
  //         text: "Data Setoran Detail",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pembayaran/belum",
  //   name: "route-pembayaran-belum",
  //   component: () => import("@/views/pages/belum-setor/ListSetoran.vue"),
  //   meta: {
  //     module: "Belum Setor Pembayaran",
  //     authRequired: true,
  //     pageTitle: "Belum Setor Pembayaran",
  //     breadcrumb: [
  //       {
  //         text: "Belum Setor Pembayaran",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pembayaran/sudah",
  //   name: "route-pembayaran-sudah",
  //   component: () => import("@/views/pages/sudah-setor/List.vue"),
  //   meta: {
  //     module: "Sudah Setor Pembayaran",
  //     authRequired: true,
  //     pageTitle: "Sudah Setor Pembayaran",
  //     breadcrumb: [
  //       {
  //         text: "Sudah Setor Pembayaran",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/konsumen",
  //   name: "route-konsumen",
  //   component: () => import("@/views/pages/konsumen/List.vue"),
  //   meta: {
  //     module: "Data Konsumen",
  //     authRequired: true,
  //     pageTitle: "Konsumen",
  //     breadcrumb: [
  //       {
  //         text: "Konsumen",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/ajuankonsumen",
  //   name: "route-ajuan-konsumen",
  //   component: () => import("@/views/pages/ajuankonsumen/List.vue"),
  //   meta: {
  //     module: "Ajuan Konsumen",
  //     authRequired: true,
  //     pageTitle: "Ajuan Konsumen",
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Konsumen",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/ajuankonsumen/:id",
  //   name: "route-ajuan-konsumen-detail",
  //   component: () => import("@/views/pages/ajuankonsumen/Detail.vue"),
  //   meta: {
  //     parent: "/ajuankonsumen",
  //     authRequired: true,
  //     pageTitle: "Detail Ajuan Konsumen",
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Konsumen",
  //         to: "/ajuankonsumen",
  //       },
  //       {
  //         text: "Detail",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/analisis",
  //   name: "route-analisis",
  //   component: () => import("@/views/pages/analisis/List.vue"),
  //   meta: {
  //     module: "Data Analisis",
  //     authRequired: true,
  //     pageTitle: "Analisis",
  //     breadcrumb: [
  //       {
  //         text: "Analisis",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/analisis/view/:id",
  //   name: "analisis-view",
  //   component: () => import("@/views/pages/analisis/Detail.vue"),
  //   meta: {
  //     parent: "/analisis",
  //     module: "Analisis",
  //     authRequired: true,
  //     pageTitle: "Info Analisis",
  //     breadcrumb: [
  //       {
  //         text: "Analisis",
  //         to: "/analisis",
  //       },
  //       {
  //         text: "Info",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/analisis/add",
  //   name: "analisis-add",
  //   component: () => import("@/views/pages/analisis/Store.vue"),
  //   meta: {
  //     parent: "/analisis",
  //     module: "Analisis",
  //     authRequired: true,
  //     pageTitle: "Add Analisis",
  //     breadcrumb: [
  //       {
  //         text: "Analisis",
  //         to: "/analisis",
  //       },
  //       {
  //         text: "Add",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/analisis/edit/:id",
  //   name: "analisis-edit",
  //   component: () => import("@/views/pages/analisis/Store.vue"),
  //   meta: {
  //     parent: "/analisis",
  //     module: "Analisis",
  //     authRequired: true,
  //     pageTitle: "Edit Analisis",
  //     breadcrumb: [
  //       {
  //         text: "Analisis",
  //         to: "/analisis",
  //       },
  //       {
  //         text: "Edit",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/kas",
  //   name: "route-kas",
  //   component: () => import("@/views/pages/kas/List.vue"),
  //   meta: {
  //     module: "Kas",
  //     authRequired: true,
  //     pageTitle: "Kas",
  //     breadcrumb: [
  //       {
  //         text: "Kas",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: "/penjualan",
  //   name: "route-penjualan",
  //   component: () => import("@/views/pages/penjualan/List.vue"),
  //   meta: {
  //     module: "Penjualan",
  //     authRequired: true,
  //     pageTitle: "Penjualan",
  //     breadcrumb: [
  //       {
  //         text: "Penjualan",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/penjualan/bayar/:id",
  //   name: "route-penjualan-bayar",
  //   component: () => import("@/views/pages/penjualan/Bayar.vue"),
  //   meta: {
  //     bayar: true,
  //     parent: "/penjualan",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Penjualan",
  //         to: "/penjualan",
  //       },
  //       {
  //         text: "Bayar",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/penjualan/detail/:id",
  //   name: "route-penjualan-detail",
  //   component: () => import("@/views/pages/penjualan/Detail.vue"),
  //   meta: {
  //     parent: "/penjualan",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Penjualan",
  //         to: "/penjualan",
  //       },
  //       {
  //         text: "Detail",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/penjualan/create",
  //   name: "route-penjualan-create",
  //   component: () => import("@/views/pages/penjualan/Store.vue"),
  //   meta: {
  //     diskonable: true,
  //     parent: "/penjualan",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Penjualan",
  //         to: "/penjualan",
  //       },
  //       {
  //         text: "Add",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/penjualan/edit/:id",
  //   name: "route-penjualan-edit",
  //   component: () => import("@/views/pages/penjualan/Store.vue"),
  //   meta: {
  //     edit: true,
  //     diskonable: true,
  //     parent: "/penjualan",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Penjualan",
  //         to: "/penjualan",
  //       },
  //       {
  //         text: "Edit",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/collection",
  //   name: "route-collection",
  //   component: () => import("@/views/pages/collection/List.vue"),
  //   meta: {
  //     module: "Collection",
  //     authRequired: true,
  //     pageTitle: "Collection",
  //     breadcrumb: [
  //       {
  //         text: "Collection",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/checklistkendaraan",
  //   name: "route-checklist-kendaraan",
  //   component: () => import("@/views/pages/checklistkendaraan/List.vue"),
  //   meta: {
  //     module: "Checklist Kendaraan",
  //     authRequired: true,
  //     pageTitle: "Checklist Kendaraan",
  //     breadcrumb: [
  //       {
  //         text: "Checklist Kendaraan",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/perbaikankendaraan",
  //   name: "route-perbaikan-kendaraan",
  //   component: () => import("@/views/pages/perbaikan/List.vue"),
  //   meta: {
  //     module: "Perbaikan Kendaraan",
  //     authRequired: true,
  //     pageTitle: "Perbaikan Kendaraan",
  //     breadcrumb: [
  //       {
  //         text: "Perbaikan Kendaraan",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/ajuanbiaya",
  //   name: "route-ajuanbiaya",
  //   component: () => import("@/views/pages/ajuanbiaya/List.vue"),
  //   meta: {
  //     module: "Ajuan Biaya",
  //     authRequired: true,
  //     pageTitle: "Ajuan Biaya",
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Biaya",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // Finance
  // {
  //   path: "/kas-alur/:id",
  //   name: "route-kas-alur",
  //   component: () => import("@/views/pages/kasalur/List.vue"),
  //   meta: {
  //     parent: "/kas",
  //     module: "Kas",
  //     authRequired: true,
  //     pageTitle: "Mutasi Kas",
  //     breadcrumb: [
  //       {
  //         text: "Kas",
  //         to: "/kas",
  //       },
  //       {
  //         text: "Mutasi Kas",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pengembalian-bop",
  //   name: "route-pengembalian-bop",
  //   component: () => import("@/views/pages/biaya/Pengembalian.vue"),
  //   meta: {
  //     module: "Pengembalian BOP",
  //     authRequired: true,
  //     pageTitle: "Pengembalian BOP",
  //     breadcrumb: [
  //       {
  //         text: "Pengembalian BOP",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/reimburse",
  //   name: "route-reimburse",
  //   component: () => import("@/views/pages/biaya/Reimburse.vue"),
  //   meta: {
  //     module: "Reimburse",
  //     authRequired: true,
  //     pageTitle: "Reimburse BOP",
  //     breadcrumb: [
  //       {
  //         text: "Reimburse BOP",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pengambilan-barang",
  //   name: "route-pengambilan-barang",
  //   component: () => import("@/views/pages/pengambilanbarang/List.vue"),
  //   meta: {
  //     module: "Ajuan Biaya Ekspedisi",
  //     authRequired: true,
  //     pageTitle: "Ajuan Biaya Ekspedisi",
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Biaya Ekspedisi",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pengembalian",
  //   name: "route-pengembalian",
  //   component: () => import("@/views/pages/pengembalian/List.vue"),
  //   meta: {
  //     module: "Pengembalian Barang",
  //     authRequired: true,
  //     pageTitle: "Pengembalian Barang Sales",
  //     breadcrumb: [
  //       {
  //         text: "Pengembalian Barang Sales",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pengembalian/detail/:id",
  //   name: "route-pengembalian-detail",
  //   component: () => import("@/views/pages/pengembalian/Detail.vue"),
  //   meta: {
  //     parent: "/pengembalian",
  //     module: "Pengembalian Barang",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Pengembalian Barang",
  //         to: "/pengembalian",
  //       },
  //       {
  //         text: "Detail",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pengambilan-barang/add",
  //   name: "route-pengambilan-barang-add",
  //   component: () => import("@/views/pages/pengambilanbarang/Store.vue"),
  //   meta: {
  //     parent: "/pengambilan-barang",
  //     module: "Ajuan Biaya Ekspedisi",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Biaya Ekspedisi",
  //         to: "/pengembalian-barang",
  //       },
  //       {
  //         text: "Add",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/pengambilan-barang/edit/:id",
  //   name: "route-pengambilan-barang-edit",
  //   component: () => import("@/views/pages/pengambilanbarang/Store.vue"),
  //   meta: {
  //     parent: "/pengambilan-barang",
  //     module: "Ajuan Biaya Ekspedisi",
  //     authRequired: true,
  //     pageTitle: "Ajuan Biaya Ekspedisi",
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Biaya Ekspedisi",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: "/pengambilan-barang/detail/:id",
  //   name: "route-pengambilan-barang-detail",
  //   component: () => import("@/views/pages/pengambilanbarang/Store.vue"),
  //   meta: {
  //     parent: "/pengambilan-barang",
  //     module: "Ajuan Biaya Ekspedisi",
  //     authRequired: true,
  //     pageTitle: "Ajuan Biaya Ekspedisi",
  //     breadcrumb: [
  //       {
  //         text: "Ajuan Biaya Ekspedisi",
  //         to: "/pengambilan-barang",
  //       },
  //       {
  //         text: "Rincian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // HRD
  {
    path: "/jabatan",
    name: "route-jabatan",
    component: () => import("@/views/pages/jabatan/List.vue"),
    meta: {
      authRequired: true,
      module: "Position",
      pageTitle: "Position",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/karyawan",
    name: "route-karyawan",
    component: () => import("@/views/pages/karyawan/List.vue"),
    meta: {
      authRequired: true,
      module: "Karyawan",
      pageTitle: "Karyawan",
      breadcrumb: [
        {
          text: "Karyawan",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/penggajian",
  //   name: "route-penggajian",
  //   component: () => import("@/views/pages/penggajian/List.vue"),
  //   meta: {
  //     authRequired: true,
  //     module: "Penggajian",
  //     pageTitle: "Penggajian",
  //     breadcrumb: [
  //       {
  //         text: "Penggajian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: "/pencapaian",
  //   name: "route-pencapaian",
  //   component: () => import("@/views/pages/pencapaian/List.vue"),
  //   meta: {
  //     module: "Pencapaian",
  //     authRequired: true,
  //     pageTitle: "Pencapaian",
  //     breadcrumb: [
  //       {
  //         text: "Pencapaian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // GM
  // {
  //   path: "/vendor",
  //   name: "route-vendor",
  //   component: () => import("@/views/pages/vendor/List.vue"),
  //   meta: {
  //     module: "Vendor",
  //     authRequired: true,
  //     pageTitle: "Vendor",
  //     breadcrumb: [
  //       {
  //         text: "Vendor",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/satuan",
    name: "route-satuan",
    component: () => import("@/views/pages/satuan/List.vue"),
    meta: {
      authRequired: true,
      module: "Unit",
      pageTitle: "Unit",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/kategori",
    name: "route-kategori",
    component: () => import("@/views/pages/kategori/List.vue"),
    meta: {
      authRequired: true,
      module: "Category",
      pageTitle: "Category",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/kategori/settingpackaging",
    name: "route-kategori-packaging",
    component: () => import("@/views/pages/kategori/SettingPackaging.vue"),
    meta: {
      authRequired: true,
      module: "Category",
      pageTitle: "Setting Packaging",
      breadcrumb: [
        {
          text: "List",
          to: '/kategori',
        },
        {
          text: "List Setting Packaging",
          active: true,
        },
      ],
    },
  },
  {
    path: "/kategori/master",
    name: "route-kategori-master",
    component: () => import("@/views/pages/kategori/Master.vue"),
    meta: {
      authRequired: true,
      module: "Category",
      pageTitle: "Master Category",
      breadcrumb: [
        {
          text: "List",
          to: '/kategori',
        },
        {
          text: "List Master",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/jenis-penyakit",
  //   name: "route-jenis-penyakit",
  //   component: () => import("@/views/pages/jenis-penyakit/List.vue"),
  //   meta: {
  //     authRequired: true,
  //     module: "Jenis Penyakit",
  //     pageTitle: "Jenis Penyakit",
  //     breadcrumb: [
  //       {
  //         text: "Jenis Penyakit",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/kelompok",
  //   name: "route-kelompok",
  //   component: () => import("@/views/pages/kelompok/List.vue"),
  //   meta: {
  //     authRequired: true,
  //     module: "Kelompok",
  //     pageTitle: "Kelompok",
  //     breadcrumb: [
  //       {
  //         text: "Kelompok",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/dokter",
  //   name: "route-dokter",
  //   component: () => import("@/views/pages/dokter/List.vue"),
  //   meta: {
  //     authRequired: true,
  //     module: "Dokter",
  //     pageTitle: "Dokter",
  //     breadcrumb: [
  //       {
  //         text: "Dokter",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/bagian",
  //   name: "route-bagian",
  //   component: () => import("@/views/pages/bagian/List.vue"),
  //   meta: {
  //     authRequired: true,
  //     module: "Bagian",
  //     pageTitle: "Bagian",
  //     breadcrumb: [
  //       {
  //         text: "Bagian",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/disposal-hilang",
  //   name: "route-disposal-hilang",
  //   component: () => import("@/views/pages/disposal-hilang/List.vue"),
  //   meta: {
  //     authRequired: true,
  //     module: "Disposal Hilang",
  //     pageTitle: "Disposal Hilang",
  //     breadcrumb: [
  //       {
  //         text: "Disposal Hilang",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/teritori',
  //   name: 'route-teritori',
  //   component: () => import('@/views/pages/teritori/List.vue'),
  //   meta: {
  //     module: 'Teritori',
  //     authRequired: true,
  //     pageTitle: 'Teritori',
  //     breadcrumb: [
  //       {
  //         text: 'Teritori',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/konversi",
  //   name: "route-konversi",
  //   component: () => import("@/views/pages/konversi/List.vue"),
  //   meta: {
  //     module: "Konversi Satuan",
  //     authRequired: true,
  //     pageTitle: "Konversi Satuan",
  //     breadcrumb: [
  //       {
  //         text: "Konversi Satuan",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/gudang",
    name: "route-gudang",
    component: () => import("@/views/pages/gudang/List.vue"),
    meta: {
      module: "Gudang",
      authRequired: true,
      pageTitle: "Gudang",
      breadcrumb: [
        {
          text: "Gudang",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/pallet",
  //   name: "route-pallet",
  //   component: () => import("@/views/pages/pallet/List.vue"),
  //   meta: {
  //     module: "Pallet",
  //     authRequired: true,
  //     pageTitle: "Pallet",
  //     breadcrumb: [
  //       {
  //         text: "Pallet",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/settingmarketing",
  //   name: "route-settingmarketing",
  //   component: () => import("@/views/pages/settingmarketing/List.vue"),
  //   meta: {
  //     module: "Setting Marketing",
  //     authRequired: true,
  //     pageTitle: "Setting Marketing",
  //     breadcrumb: [
  //       {
  //         text: "Setting Marketing",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/blok",
  //   name: "route-blok",
  //   component: () => import("@/views/pages/blok/List.vue"),
  //   meta: {
  //     module: "Blok",
  //     authRequired: true,
  //     pageTitle: "Blok",
  //     breadcrumb: [
  //       {
  //         text: "Blok",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/rak",
  //   name: "route-rak",
  //   component: () => import("@/views/pages/rak/List.vue"),
  //   meta: {
  //     module: "Rak",
  //     authRequired: true,
  //     pageTitle: "Rak",
  //     breadcrumb: [
  //       {
  //         text: "Rak",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/laci",
  //   name: "route-laci",
  //   component: () => import("@/views/pages/laci/List.vue"),
  //   meta: {
  //     module: "Laci",
  //     authRequired: true,
  //     pageTitle: "Laci",
  //     breadcrumb: [
  //       {
  //         text: "Laci",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/role-management",
    name: "route-role-management",
    component: () => import("@/views/pages/role-management/List.vue"),
    meta: {
      module: "Role Management",
      authRequired: true,
      pageTitle: "Role Management",
      breadcrumb: [
        {
          text: "Role Management",
          active: true,
        },
      ],
    },
  },
  {
    path: "/role-management/sub-menu/:id",
    name: "route-role-management-sub-menu",
    component: () => import("@/views/pages/role-management/ListDetail.vue"),
    meta: {
      parent: "/role-management",
      module: "Role Management",
      authRequired: true,
      pageTitle: "Sub Role Management",
      breadcrumb: [
        {
          text: "Role Management",
          to: "/role-management",
        },
        {
          text: "Submenu Role Management",
          active: true,
        },
      ],
    },
  },
  {
    path: "/statusrole",
    name: "route-statusrole",
    component: () => import("@/views/pages/statusrole/List.vue"),
    meta: {
      authRequired: true,
      module: "Status Management",
      pageTitle: "Status Management",
      breadcrumb: [
        {
          text: "Status Management",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/kendaraan",
  //   name: "route-kendaraan",
  //   component: () => import("@/views/pages/kendaraan/List.vue"),
  //   meta: {
  //     module: "Kendaraan",
  //     authRequired: true,
  //     pageTitle: "Kendaraan",
  //     breadcrumb: [
  //       {
  //         text: "Kendaraan",
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  {
    path: "/akun-pengguna",
    name: "route-akun-pengguna",
    component: () => import("@/views/pages/akunkaryawan/AccountSetting.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          active: true,
        },
      ],
    },
  },
  {
    path: "/akun-pengguna-tambah-alamat-toko",
    name: "route-akun-pengguna-tambah-alamat-toko",
    component: () => import("@/views/pages/akunkaryawan/alamat/TambahToko.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          to: "/akun-pengguna",
        },
        {
          text: "Add Alamat Toko",
          active: true,
        },
      ],
    },
  },
  {
    path: "/akun-pengguna-edit-alamat-toko/:id",
    name: "route-akun-pengguna-edit-alamat-toko",
    component: () => import("@/views/pages/akunkaryawan/alamat/UbahToko.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          to: "/akun-pengguna",
        },
        {
          text: "Edit Alamat Toko",
          active: true,
        },
      ],
    },
  },
  {
    path: "/akun-pengguna-tambah-alamat-konsumen",
    name: "route-akun-pengguna-tambah-alamat-konsumen",
    component: () => import("@/views/pages/akunkaryawan/alamat/TambahKonsumen.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          to: "/akun-pengguna",
        },
        {
          text: "Add Alamat Konsumen",
          active: true,
        },
      ],
    },
  },
  {
    path: "/akun-pengguna-edit-alamat-konsumen/:id",
    name: "route-akun-pengguna-edit-alamat-konsumen",
    component: () => import("@/views/pages/akunkaryawan/alamat/UbahKonsumen.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          to: "/akun-pengguna",
        },
        {
          text: "Edit Alamat Konsumen",
          active: true,
        },
      ],
    },
  },
  {
    path: "/akun-pengguna-tambah-alamat-retur",
    name: "route-akun-pengguna-tambah-alamat-retur",
    component: () => import("@/views/pages/akunkaryawan/alamat/TambahRetur.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          to: "/akun-pengguna",
        },
        {
          text: "Add Alamat Retur",
          active: true,
        },
      ],
    },
  },
  {
    path: "/akun-pengguna-edit-alamat-retur/:id",
    name: "route-akun-pengguna-edit-alamat-retur",
    component: () => import("@/views/pages/akunkaryawan/alamat/UbahRetur.vue"),
    meta: {
      pageTitle: "Akun Pengguna",
      breadcrumb: [
        {
          text: "Akun Pengguna",
          to: "/akun-pengguna",
        },
        {
          text: "Edit Alamat Retur",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pengguna",
    name: "route-pengguna",
    component: () => import("@/views/pages/pengguna/List.vue"),
    meta: {
      module: "User",
      authRequired: true,
      pageTitle: "User",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  // SUPPLIER
  {
    path: "/supplier",
    name: "route-supplier",
    component: () => import("@/views/pages/supplier/List.vue"),
    meta: {
      module: "Supplier",
      pageTitle: "Supplier",
      authRequired: true,
      breadcrumb: [
        {
          text: "Supplier",
          active: true,
        },
      ],
    },
  },

  // Transaksi PENJUALAN
  {
    path: "/transaksi-penjualan",
    name: "route-transaksi-penjualan",
    component: () => import("@/views/pages/transaksi-penjualan/List.vue"),
    meta: {
      module: "Transaksi Penjualan",
      authRequired: true,
      pageTitle: "Transaksi Penjualan",
      breadcrumb: [
        {
          text: "Transaksi Penjualan",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transaksi-penjualan/detail/:id",
    name: "route-transaksi-penjualan-detail",
    component: () => import("@/views/pages/transaksi-penjualan/Detail.vue"),
    meta: {
      parent: "/transaksi-penjualan",
      authRequired: true,
      breadcrumb: [
        {
          text: "Transaksi Penjualan",
          to: "/transaksi-penjualan",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transaksi-penjualan/create",
    name: "route-transaksi-penjualan-create",
    component: () => import("@/views/pages/transaksi-penjualan/Store.vue"),
    meta: {
      diskonable: true,
      parent: "/transaksi-penjualan",
      authRequired: true,
      breadcrumb: [
        {
          text: "Transaksi Penjualan",
          to: "/transaksi-penjualan",
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/transaksi-penjualan/edit/:id",
    name: "route-transaksi-penjualan-edit",
    component: () => import("@/views/pages/transaksi-penjualan/Store.vue"),
    meta: {
      edit: true,
      diskonable: true,
      parent: "/transaksi-penjualan",
      authRequired: true,
      breadcrumb: [
        {
          text: "Transaksi Penjualan",
          to: "/transaksi-penjualan",
        },
        {
          text: "Edit",
          active: true,
        },
      ],
    },
  },
    // Transaksi PENJUALAN TOKO
    {
      path: "/transaksi-penjualan-toko",
      name: "route-transaksi-penjualan-toko",
      component: () => import("@/views/pages/transaksi-toko/List.vue"),
      meta: {
        module: "Transaksi Penjualan Toko",
        authRequired: true,
        pageTitle: "Transaksi Penjualan Toko",
        breadcrumb: [
          {
            text: "List",
            active: true,
          },
        ],
      },
    },
    {
      path: "/transaksi-penjualan-toko/detail/:id",
      name: "route-transaksi-penjualan-toko-detail",
      component: () => import("@/views/pages/transaksi-toko/Detail.vue"),
      meta: {
        parent: "/transaksi-penjualan-toko",
        authRequired: true,
        breadcrumb: [
          {
            text: "List",
            to: "/transaksi-penjualan-toko",
          },
          {
            text: "Detail",
            active: true,
          },
        ],
      },
    },
    {
      path: "/transaksi-penjualan-toko/create",
      name: "route-transaksi-penjualan-toko-create",
      component: () => import("@/views/pages/transaksi-toko/Store.vue"),
      meta: {
        diskonable: true,
        parent: "/transaksi-penjualan-toko",
        authRequired: true,
        breadcrumb: [
          {
            text: "List",
            to: "/transaksi-penjualan-toko",
          },
          {
            text: "Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/transaksi-penjualan-toko/edit/:id",
      name: "route-transaksi-penjualan-toko-edit",
      component: () => import("@/views/pages/transaksi-toko/Store.vue"),
      meta: {
        edit: true,
        diskonable: true,
        parent: "/transaksi-penjualan-toko",
        authRequired: true,
        breadcrumb: [
          {
            text: "List",
            to: "/transaksi-penjualan-toko",
          },
          {
            text: "Edit",
            active: true,
          },
        ],
      },
  },
  // Transaksi Terapis
  // {
  //   path: "/transaksi-terapis",
  //   name: "route-transaksi-terapis",
  //   component: () => import("@/views/pages/transaksi-terapis/List.vue"),
  //   meta: {
  //     module: "Transaksi Terapis",
  //     authRequired: true,
  //     pageTitle: "Transaksi Terapis",
  //     breadcrumb: [
  //       {
  //         text: "Transaksi Terapis",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/transaksi-terapis/detail/:id",
  //   name: "route-transaksi-terapis-detail",
  //   component: () => import("@/views/pages/transaksi-terapis/Detail.vue"),
  //   meta: {
  //     parent: "/transaksi-terapis",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Transaksi Terapis",
  //         to: "/transaksi-terapis",
  //       },
  //       {
  //         text: "Detail",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/transaksi-terapis/create",
  //   name: "route-transaksi-terapis-create",
  //   component: () => import("@/views/pages/transaksi-terapis/Store.vue"),
  //   meta: {
  //     diskonable: true,
  //     parent: "/transaksi-terapis",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Transaksi Terapis",
  //         to: "/transaksi-terapis",
  //       },
  //       {
  //         text: "Add",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: "/transaksi-terapis/edit/:id",
  //   name: "route-transaksi-terapis-edit",
  //   component: () => import("@/views/pages/transaksi-terapis/Store.vue"),
  //   meta: {
  //     edit: true,
  //     diskonable: true,
  //     parent: "/transaksi-terapis",
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: "Transaksi Terapis",
  //         to: "/transaksi-terapis",
  //       },
  //       {
  //         text: "Edit",
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
