var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "barang-modal",
      "size": "xl",
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Choose Items")))])];
      },
      proxy: true
    }])
  }, [_c('header', {
    staticClass: "mb-1"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Items Selected")) + " (" + _vm._s(_vm.checkedBarangs.length) + ").")]), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('Filter')
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1), _c('validation-observer', {
    ref: "stok"
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "filter": _vm.filter,
      "items": _vm.localBarangs,
      "responsive": ""
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": item.checked ? 'checked' : ''
          },
          model: {
            value: item.checked,
            callback: function ($$v) {
              _vm.$set(item, "checked", $$v);
            },
            expression: "item.checked"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.barang ? _c('span', [_vm._v(_vm._s(item.barang.nama))]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ditemukan")])];
      }
    }, {
      key: "cell(lokasi)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.getLokasi(item)) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('validation-provider', {
          attrs: {
            "name": "qty",
            "rules": {
              max_value: item.stok
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-input', {
                attrs: {
                  "type": "number",
                  "state": errors.length > 0 ? false : null
                },
                on: {
                  "keyup": function ($event) {
                    return _vm.onQtyChange(index);
                  }
                },
                model: {
                  value: item.qty,
                  callback: function ($$v) {
                    _vm.$set(item, "qty", $$v);
                  },
                  expression: "item.qty"
                }
              }), _c('small', {
                staticClass: "text-danger"
              }, [_vm._v(_vm._s(errors[0]))])];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref6) {
        var item = _ref6.item,
          index = _ref6.index;
        return [item.barang && item.barang.konversi ? _c('b-form-select', {
          attrs: {
            "options": _vm.getKonversiOption(item)
          },
          on: {
            "change": function ($event) {
              return _vm.onKonversiChange(index);
            }
          },
          model: {
            value: item.selectedKonversi,
            callback: function ($$v) {
              _vm.$set(item, "selectedKonversi", $$v);
            },
            expression: "item.selectedKonversi"
          }
        }) : item.barang && item.barang.satuan ? _c('span', [_vm._v(" " + _vm._s(item.barang.satuan.satuan) + " ")]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Satuan tidak ditemukan")])];
      }
    }, {
      key: "cell(converted)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.converted ? ((item.converted) + " /" + (item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-')) : ("0 /" + (item.barang && item.barang.satuan ? item.barang.satuan.satuan : '-'))) + " ")];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }