var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h4', [_vm._v("Outbond")])];
      },
      proxy: true
    }])
  }, [[_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push('/outbound/historyscan');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ListIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('History Scanned Items')) + " ")], 1)], 1), _c('b-col', {
    staticClass: "mb-1 d-flex justify-content-end",
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.perorder
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "MenuIcon"
    }
  }), _vm._v("Per Order")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.peritems
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "PackageIcon"
    }
  }), _vm._v("Per Items")], 1)], 1)], 1)], 1)], _vm.mode == 'Per Items' ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('list-items')], 1)], 1)] : _vm._e(), _vm.mode == 'Per Order' ? [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c('list-order')], 1)], 1)] : _vm._e()], 2), this.user.level != 'SPV CRM' ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h4', [_vm._v("Disposal")])];
      },
      proxy: true
    }], null, false, 3093024841)
  }, [_c('page-disposal')], 1) : _vm._e(), this.user.level != 'SPV CRM' ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('h4', [_vm._v("Ajuan Marketing")])];
      },
      proxy: true
    }], null, false, 2285698883)
  }, [_c('ajuan-marketing')], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }