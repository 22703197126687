<template>
    <form-wizard 
        ref="form"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        :finish-button-text="$t('Simpan')"
        back-button-text="Kembali"
        hide-buttons
        class="checkout-form-wizard steps-transparent"
    >
        <!-- Pemilihan Barang -->
        <tab-content title="Informasi Penjualan" icon="feather icon-file-text">
            <penjualan-info @next-step="saveInfo" :ajuanList="ajuanList">
                <template #content-selected-barang="{barangs, doFormatRupiah, remove}">
                    <b-table responsive bordered striped hover :fields="fields" :items="barangs">
                        <template #table-colgroup="{fields}">
                            <col
                                v-for="field in fields"
                                :key="field.key"
                                :style="{width: field.key == 'qty' ? '140px' : (field.key == 'diskon' ? '160px' : '')}"
                            />
                        </template>
                        <template #cell(satuan)="{item}">
                            {{ item.satuan ? item.satuan.satuan : '-' }}
                        </template>
                        <template #cell(stocks)="{item}">
                            {{ item.stocks.length > 0 ? item.stocks.reduce((total, item) => total += item.jumlah,0) : 0 }}
                        </template>
                        <template #cell(qty)="{item}">
                            <b-form-input type="number" v-model="item.qty"></b-form-input>
                        </template>
                        <template #cell(harga)="{item}">
                            {{ formatRupiah(item.harga) }}
                        </template>
                        <template #cell(diskon)="{item, index}">
                            <b-form-input v-model="item.diskon" @keyup="doFormatRupiah(index)"></b-form-input>
                        </template>
                        <template #cell(action)="{index, item}">
                            <feather-icon icon="XCircleIcon" size="24" class="text-danger cursor-pointer" @click.prevent="remove(index, item)" />
                        </template>
                    </b-table>
                </template>
            </penjualan-info>
        </tab-content>

        <!-- Metode Pembayaran -->
        <tab-content 
            title="Metode Pembayaran"
            icon="feather icon-credit-card"
        >
            <penjualan-payment @submit="submit" :payload="payload"></penjualan-payment>
        </tab-content>
    </form-wizard>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import PenjualanInfo from './components/Info.vue'
import PenjualanPayment from './components/Payment.vue'
import mixin from './mixin'
import {BTable, BFormInput } from 'bootstrap-vue'

export default {
    mixins:[mixin],
    components: {
        FormWizard, TabContent, PenjualanInfo, PenjualanPayment, BTable, BFormInput
    },
    data: () => ({
        fields: [
            {key: 'nama', label: 'Barang'},
            {key: 'qty', label: 'Jumlah'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'harga', label: 'Harga Jual'},
            {key: 'diskon', label: 'Diskon'},
            {key: 'action', label: '#'}
        ],
        penjualan: null,
        payload: null,
        ajuanList: [],
        rutes:[]
    }),
    methods: {
        async getMyRute() {
            const params = {
                sales_id: this.user && this.user.karyawan ? this.user.karyawan.id : null
            }

            const rutes = await this.$store.dispatch('rute/getMyRouteJoin', params)
            this.rutes = rutes.map(item => ({
                value:item.id,
                text:item.nama_rute
            }))
            this.$store.commit('penjualan/SET_RUTE',this.rutes)
        },
        async updateTransaction(totalBayar) {
            this.$store.commit('penjualan/SET_CURRENT_TOTAL_BAYAR', totalBayar)
            try {
                const penjualan = Object.assign({}, this.payload)
                penjualan.total_bayar = totalBayar
                await this.$store.dispatch('penjualan/update', penjualan)
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        },
        async saveInfo(payload) {
            this.payload = payload
            // if action is edit
            if(this.$route.params.id) {
                const payloadBarang = []
                payload.item.map(barang => {
                    let dataRincian = {
                        id_penjualan: this.$route.params.id,
                        id_barang: barang.id_barang,
                        qty: barang.qty,
                        harga_jual: barang.harga_jual,
                        diskon: barang.diskon
                    }
                    if(barang.id_rincian) {
                        dataRincian.id = barang.id_rincian
                    }
                    payloadBarang.push(dataRincian)
                })
                this.$store.commit('penjualan/SET_CURRENT_BARANG', payloadBarang)
                this.$refs.form.nextTab()
                // await this.updateBarang(payloadBarang)
            }
            else {
                
                this.$refs.form.nextTab()
            }
        },
        async updateBarang(payload) {
            try {
                await this.$store.dispatch('penjualan/saveBarang', payload)
                
                this.$refs.form.nextTab()
            }
            catch(e) {
                this.displayError(e)
                this.$refs.form.nextTab()
            }
        },
        async submit(payload) {
            try {
                if(this.$route.params.id) {
                    // first cancel
                    const date = new Date()
                    const now = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                    const payloadCancel = {
                        tanggal: now,
                        id_penjualan: this.penjualan.id,
                        no_kwitansi: this.penjualan.no_kwitansi,
                        keterangan: 'Cancel karena update barang'
                    }
                    // updat barang
                    const payloadBarang = this.$store.state.penjualan.barangs
                    await this.updateBarang(payloadBarang)
                    await this.$store.dispatch('penjualan/cancel', [payloadCancel])

                    await this.$store.dispatch('penjualan/update', payload)

                    this.displaySuccess({
                        message: 'Transaksi Berhasil!'
                    })
                    const params = this.isSales
                        ? { order: "desc", id_sales: this.user.karyawan.id }
                        : { order: "desc" };
                    await this.$store.dispatch("penjualan/getData", params); // belum difilter per sales
                    setTimeout(() => {
                        this.$router.push(`/penjualan/detail/${this.$route.params.id}`)
                    }, 1000)
                }
                else { // create
                    await this.$store.dispatch('penjualan/save', payload)

                    this.displaySuccess({
                        message: 'Transaksi Berhasil!'
                    })

                    // get last inserted id penjulaan
                    const params = this.isSales
                    ? { order: "desc", id_sales: this.user.karyawan.id }
                    : { order: "desc" }
                    const penjualans = await this.$store.dispatch('penjualan/getData', params)

                    setTimeout(() => {
                        this.$router.push(`/penjualan/detail/${penjualans[0].id}`)
                    }, 1000)
                }
            }
            catch(e) {
                this.displayError(e)
                return false
            }
        }
    },
    computed: {
        thereOverStock() {
            this.barangs.map(item => {
                const totalStock = item.stocks.reduce((total, item) => total += item.jumlah,0)
            })
        }
    },
    async created() {
        await this.getMyRute()
        const params = this.isSales ? { sales_id: this.user.karyawan.id } : {}

        this.ajuanList = await this.$store.dispatch('requestbarang/getData', params)
        if(this.$route.params.id) {
            this.payload = this.$store.getters['penjualan/search'](this.$route.params.id)
            this.penjualan = this.$store.getters['penjualan/search'](this.$route.params.id)
            
            if(this.penjualan && this.penjualan.rute_id) {
                const rute = this.rutes.find(item => item.value == this.penjualan.rute_id)
                if(rute) {
                    this.$store.commit('penjualan/SET_CURRENT_RUTE',rute)
                }
            }
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
