var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container mt-3"
  }, [_c('b-card', [_c('validation-observer', {
    ref: "formKaryawan"
  }, [_c('b-form', {}, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-8 mt-2"
  }, [_c('b-card-title', {
    staticClass: "bg-success text-white p-1"
  }, [_vm._v("Personal Info ")]), _c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-table-simple', {
    staticClass: "table border"
  }, [_c('b-tbody', [_c('tr', [_c('td', [_vm._v("Full Name")]), _c('td', [_vm._v(_vm._s(_vm.profile.nama_lengkap))])]), _c('tr', [_c('td', [_vm._v("Gender")]), _c('td', [_vm._v(_vm._s(_vm.profile.jk === "l" ? "Male" : "Female"))])]), _c('tr', [_c('td', [_vm._v("Birth Place")]), _c('td', [_vm._v(_vm._s(_vm.profile.tempat_lahir))])]), _c('tr', [_c('td', [_vm._v("Birth Date")]), _c('td', [_vm._v(_vm._s(_vm.profile.tanggal_lahir))])]), _c('tr', [_c('td', [_vm._v("Religion")]), _c('td', [_vm._v(_vm._s(_vm.religion(_vm.profile.agama)))])]), _c('tr', [_c('td', [_vm._v("ID Type")]), _c('td', [_vm._v("KTP")])]), _c('tr', [_c('td', [_vm._v("ID No")]), _c('td', [_vm._v(_vm._s(_vm.profile.nik))])])])], 1)], 1), _c('div', {
    staticClass: "col-md-4 mt-2"
  }, [_c('b-card-title', {
    staticClass: "bg-success text-white p-1"
  }, [_vm._v("Work")]), _c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-table-simple', {
    staticClass: "table border"
  }, [_c('b-tbody', [_c('tr', [_c('td', [_vm._v("Division")]), _c('td', [_vm._v("Warehouse")])]), _c('tr', [_c('td', [_vm._v("Occupation")]), _c('td', [_vm._v(_vm._s(_vm.profile.jabatan))])]), _c('tr', [_c('td', [_vm._v("Employee ID")]), _c('td', [_vm._v(_vm._s(_vm.profile.nip))])])])], 1), _c('center', [_vm.myAvatarProfile ? _c('img', {
    staticClass: "rounded mt-2",
    staticStyle: {
      "width": "100px",
      "height": "100px",
      "object-fit": "cover"
    },
    attrs: {
      "src": _vm.myAvatarProfile,
      "alt": "Profile Photo"
    }
  }) : _vm._e(), _c('br'), _c('b-button', {
    staticClass: "w-30",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openFotoModal($event);
      }
    }
  }, [_vm._v(" Change Photo ")])], 1)], 1)]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('b-card-title', {
    staticClass: "bg-success text-white p-1"
  }, [_vm._v("Contact")]), _c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-form-group', {
    attrs: {
      "label": "Personal Email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.profile.email
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Phone Number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.profile.no_hp
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Residential Address"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "value": _vm.profile.alamat
    }
  })], 1)], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('b-card-title', {
    staticClass: "bg-success text-white p-1"
  }, [_vm._v("Emergency Contact")]), _c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-form-group', {
    attrs: {
      "label": "Contact Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": "Emergency Name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Phone Number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": "Emergency Phone"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Relationship"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": "Relationship"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('b-card-title', {
    staticClass: "bg-success text-white p-1"
  }, [_vm._v("Password")]), _c('hr', {
    staticStyle: {
      "height": "3px",
      "background-color": "black",
      "border": "none"
    }
  }), _c('b-form-group', {
    attrs: {
      "label": "Current Password"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": _vm.showCurrentPassword ? 'text' : 'password'
    },
    model: {
      value: _vm.passwords.current,
      callback: function ($$v) {
        _vm.$set(_vm.passwords, "current", $$v);
      },
      expression: "passwords.current"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.togglePasswordVisibility('current');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.showCurrentPassword ? 'EyeOffIcon' : 'EyeIcon'
    }
  })], 1)], 1)], 1)]), _c('b-form-group', {
    attrs: {
      "label": "New Password"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": _vm.showNewPassword ? 'text' : 'password'
    },
    model: {
      value: _vm.passwords.new,
      callback: function ($$v) {
        _vm.$set(_vm.passwords, "new", $$v);
      },
      expression: "passwords.new"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.togglePasswordVisibility('new');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.showNewPassword ? 'EyeOffIcon' : 'EyeIcon'
    }
  })], 1)], 1)], 1)]), _c('b-form-group', {
    attrs: {
      "label": "Confirm Password"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('b-form-input', {
    attrs: {
      "type": _vm.showConfirmPassword ? 'text' : 'password'
    },
    model: {
      value: _vm.passwords.confirm,
      callback: function ($$v) {
        _vm.$set(_vm.passwords, "confirm", $$v);
      },
      expression: "passwords.confirm"
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.togglePasswordVisibility('confirm');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.showConfirmPassword ? 'EyeOffIcon' : 'EyeIcon'
    }
  })], 1)], 1)], 1)]), _c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("UPDATE")])], 1)])])], 1), _c('foto-modal', {
    on: {
      "submitFoto": _vm.submitFoto
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }