var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('div', {
    staticClass: "bg-success text-white p-2"
  }, [_vm._v("PRODUCT SOLD by STORE")]), _c('div', {
    staticClass: "p-1 border"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadProductSold
    }
  }, [_c('div', {
    staticClass: "p-1",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Total Product Sold")])]), _c('div', {
    staticClass: "col-md-2"
  }, [_c('div', {
    staticClass: "text-white text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#9fa0a2"
    }
  }, [_c('b-badge', {
    staticClass: "text-white p-1 mr-1",
    attrs: {
      "variant": "secondary"
    }
  }, [_c('span', [_vm._v("Total")])]), _c('strong', [_vm._v(_vm._s(_vm.totalProductSold))])], 1), _c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#fde6ba"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("Lenses")])]), _c('strong', [_vm._v(_vm._s(_vm.total_lensa))])], 1), _c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#ebf6fc"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Frames")])]), _c('strong', [_vm._v(_vm._s(_vm.total_frame))])], 1), _c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1",
    staticStyle: {
      "background": "#eff6ef"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Accs.")])]), _c('strong', [_vm._v(_vm._s(_vm.total_accs))])], 1)]), _c('div', {
    staticClass: "col-md-8 mt-1"
  }, [_c('div', {
    staticClass: "bg-white"
  }, [_c('canvas', {
    attrs: {
      "height": "300",
      "id": "productSoldChart"
    }
  })])])])])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loadProductSoldPerStore
    }
  }, [_c('div', {
    staticClass: "mt-1 p-1",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1 col-sm-4",
    staticStyle: {
      "background": "#fde6ba"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_c('span', [_vm._v("Lenses")])]), _c('strong', [_vm._v(_vm._s(_vm.lensa.total))])], 1), _c('table', {
    staticClass: "table table-bordered table-responsive table-striped bg-white mt-4"
  }, [_c('tr', _vm._l(_vm.lensa.datas, function (data, i) {
    return _c('td', {
      key: i
    }, [_vm._v(" " + _vm._s(data.channel_name) + " ")]);
  }), 0), _c('tr', _vm._l(_vm.lensa.datas, function (data, i) {
    return _c('td', {
      key: i
    }, [_vm._v(" " + _vm._s(data.total) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('canvas', {
    staticClass: "bg-white",
    attrs: {
      "id": "lensaChart"
    }
  })])])])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loadProductSoldPerStore
    }
  }, [_c('div', {
    staticClass: "mt-1 p-1",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1 col-sm-4",
    staticStyle: {
      "background": "#ebf6fc"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "info"
    }
  }, [_c('span', [_vm._v("Frames")])]), _c('strong', [_vm._v(_vm._s(_vm.frame.total) + " ")])], 1), _c('table', {
    staticClass: "table table-bordered table-responsive table-striped bg-white mt-4"
  }, [_c('tr', _vm._l(_vm.frame.datas, function (data, i) {
    return _c('td', {
      key: i
    }, [_vm._v(" " + _vm._s(data.channel_name) + " ")]);
  }), 0), _c('tr', _vm._l(_vm.frame.datas, function (data, i) {
    return _c('td', {
      key: i
    }, [_vm._v(" " + _vm._s(data.total) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('canvas', {
    staticClass: "bg-white",
    attrs: {
      "id": "frameChart"
    }
  })])])])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loadProductSoldPerStore
    }
  }, [_c('div', {
    staticClass: "mt-1 p-1",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "text-dark text-center p-1 rounded mt-1 col-sm-4",
    staticStyle: {
      "background": "#eff6ef"
    }
  }, [_c('b-badge', {
    staticClass: "text-dark p-1 mr-1",
    attrs: {
      "variant": "success"
    }
  }, [_c('span', [_vm._v("Accs.")])]), _c('strong', [_vm._v(_vm._s(_vm.accs.total))])], 1), _c('table', {
    staticClass: "table table-bordered table-responsive table-striped bg-white mt-4"
  }, [_c('tr', _vm._l(_vm.accs.datas, function (data, i) {
    return _c('td', {
      key: i
    }, [_vm._v(" " + _vm._s(data.channel_name) + " ")]);
  }), 0), _c('tr', _vm._l(_vm.accs.datas, function (data, i) {
    return _c('td', {
      key: i
    }, [_vm._v(" " + _vm._s(data.total) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('canvas', {
    staticClass: "bg-white",
    attrs: {
      "id": "accsChart"
    }
  })])])])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loadProductSoldAllStore
    }
  }, [_c('div', {
    staticClass: "mt-1 p-1",
    staticStyle: {
      "background-color": "#eeeeee",
      "border": "1px solid rgba(0, 0, 0, 0.166)",
      "border-radius": "5px",
      "box-shadow": "0px 3px 10px rgba(0, 0, 0, 0.166)"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-2 mt-1"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Total Product Sold By Store")])]), _c('div', {
    staticClass: "col-md-3 mt-1"
  }, [_c('table', {
    staticClass: "bg-white table table-bordered"
  }, _vm._l(_vm.dataProductSoldAllStore, function (data, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(data.text))]), _c('td', [_vm._v(_vm._s(data.total))])]);
  }), 0)]), _c('div', {
    staticClass: "col-md-7 mt-1"
  }, [_c('div', {
    staticClass: "bg-white"
  }, [_c('canvas', {
    attrs: {
      "height": "300",
      "id": "productSoldAllStoreChart"
    }
  })])])])])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }