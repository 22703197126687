import QueryString from 'qs'
import service from '@/services'

export default {
  namespaced: true,
  state: {
    datas: [],
    status: [],
    total: 0,
  },
  getters: {
    search(state) {
      return (id) => state.datas.find((bop) => bop.id == id);
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_DATA_INSTOCK(state, data) {
      state.datas = data;
    },
    // SET_PENERIMAAN_DATA(state, data) {
    //   state.penerimaandatas = data
    // },
    SET_STATUS(state, data) {
      state.status = data;
    },
    SET_TOTAL_DATA(state, data) {
      state.total = data;
    },
  },
  actions: {
    async printPembayaran({}, id) {
      try {
        const config = {
          url: `/cetak-pembelian-bayar/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async print({}, id) {
      try {
        const config = {
          url: `/cetak-pembelian/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/pembelian",
          method: "get",
          params,
          paramsSerializer: (params) => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        const total = response.data ? response.data.total : result.length;
        commit("SET_TOTAL_DATA", total);
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: "/pembelian" + `/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data && response.data ? response.data : [];
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/pembelian",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async confirm({}, payload = {}) {
      try {
        const config = {
          url: "/confirm-penerimaan",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async penerimaan({}, id) {
      try {
        const config = {
          url: `/penerimaan/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;
        // const total = response.data ? response.data.total : result.length
        // commit('SET_TOTAL_DATA', total)
        // commit('SET_PENERIMAAN_DATA', result)
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async tempatkan({}, payload = {}) {
      try {
        const config = {
          url: "/tempatkan-barang",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async download({}, payload = {}) {
      try {
        const config = {
          url: "/download-template-pembelian-items",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async upload({}, payload = {}) {
      try {
        const config = {
          url: "/import-pembelian-items",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async export({}, payload = {}) {
      try {
        const config = {
          url: "/export-pembelian",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async cancel({}, payload = {}) {
      try {
        const config = {
          url: "/input/pembelian-tolak",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getInstock({ commit }, params = {}) {
      try {
        const config = {
          url: "/barang-transit",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;
        commit("SET_DATA_INSTOCK", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async movetransit({}, payload = {}) {
      try {
        const config = {
          url: "/move-transit-stock",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
