<template>
  <div class="container mt-2">
    <!-- Existing Section -->

    <div class="sticky-range border p-1">
      <b-form-group label="Select Range:" label-cols-sm="3">
        <b-form-radio-group
          v-model="selectedRange"
          name="range-options"
          buttons
          button-variant="outline-secondary"
          size="sm"
        >
          <b-form-radio value="current_month" @click="loadData()"
            >This Month</b-form-radio
          >
          <b-form-radio value="selectPeriod">Select Period</b-form-radio>
          <b-form-radio value="selectMonth">Select Month</b-form-radio>
        </b-form-radio-group>

        <!-- Period Dropdown -->
        <b-form-select
          v-if="selectedRange === 'selectPeriod'"
          v-model="selectedPeriod"
          :options="periodOptions"
          class="mt-1"
        />

        <!-- Month Range -->
        <div
          v-if="selectedRange === 'selectMonth'"
          class="d-flex align-items-center mt-1"
        >
          <input
            type="month"
            v-model="startMonthRaw"
            @input="formatStartMonth"
            max="endMonthRaw"
            class="form-control me-2"
            placeholder="Start Month"
          />
          <span class="mx-2">to</span>
          <input
            type="month"
            v-model="endMonthRaw"
            @input="formatEndMonth"
            min="startMonthRaw"
            class="form-control"
            placeholder="End Month"
          />
        </div>

        <b-button
          variant="warning"
          class="mt-1 float-right"
          @click.prevent="exportDashboard"
          ><b-overlay :show="loadExport">Export</b-overlay></b-button
        >
      </b-form-group>
    </div>
    <!-- Estimated Revenue Section -->

    <estimated-revenue
      :load="loadEstimatedRevenue"
      :data="dataEstimatedRevenue"
      :total="totalEstimatedRevenue"
    ></estimated-revenue>

    <!-- Order Section -->
    <order
      :loadOrder="loadOrder"
      :loadCancel="loadCancel"
      :loadReturn="loadReturn"
      :data="dataOrder"
      :total="totalOrder"
      :grafikData="grafikDataOrder"
      :dataCancel="dataCancelOrder"
      :totalCancel="totalCancelOrder"
      :dataReturn="dataReturnOrder"
      :totalReturn="totalReturnOrder"
    ></order>

    <!-- Pie Chart Section -->
    <product-by-store
      :loadProductSold="loadProductSold"
      :loadProductSoldPerStore="loadProductSoldPerStore"
      :loadProductSoldAllStore="loadProductSoldAllStore"
      :dataProductSold="dataProductSold"
      :totalProductSold="totalProductSold"
      :grafikProductSold="grafikProductSold"
      :dataProductSoldPerStore="dataProductSoldPerStore"
      :dataProductSoldAllStore="dataProductSoldAllStore"
      :grafikProductSoldAllStore="grafikProductSoldAllStore"
    ></product-by-store>
  </div>
</template>

<script>
import Chart from "chart.js";
import {
  BCard,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BFormDatepicker,
  BButton,
  BBadge,
  BOverlay,
} from "bootstrap-vue";
import EstimatedRevenue from "./components/EstimatedRevenue.vue";
import Order from "./components/Order.vue";
import ProductByStore from "./components/ProductByStore.vue";
export default {
  components: {
    BCard,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BFormDatepicker,
    BButton,
    BBadge,
    BOverlay,
    EstimatedRevenue,
    Order,
    ProductByStore,
  },
  data() {
    return {
      loadExport: false,
      loadEstimatedRevenue: false,
      dataEstimatedRevenue: [],
      totalEstimatedRevenue: 0,
      loadOrder: false,
      dataOrder: [],
      totalOrder: 0,
      grafikDataOrder: {},
      loadCancel: false,
      dataCancelOrder: [],
      totalCancelOrder: 0,
      loadReturn: false,
      dataReturnOrder: [],
      totalReturnOrder: 0,
      loadProductSold: false,
      dataProductSold: [],
      totalProductSold: 0,
      grafikProductSold: {},
      loadProductSoldPerStore: false,
      dataProductSoldPerStore: [],
      loadProductSoldAllStore: false,
      dataProductSoldAllStore: [],
      grafikProductSoldAllStore: {},
      selectedRange: "current_month",
      selectedPeriod: null,
      startMonthRaw: null,
      endMonthRaw: null,
      startMonth: null,
      endMonth: null,
      periodOptions: [
        { value: null, text: "Select Periode.." },
        { value: "last_3_months", text: "Last 3 months" },
        { value: "last_semester", text: "Last Semester" },
        { value: "year_to_year", text: "Year to Year" },
      ],
      monthOptions: [
        { value: null, text: "Select Month.." },
        { value: "2023-01", text: "January 2023" },
        { value: "2023-02", text: "February 2023" },
      ],
    };
  },
  watch: {
    selectedRange(val) {
      if (val == "current_month") {
        this.loadData();
      }
      if (val == "selectPeriod") {
        if (this.selectedPeriod != null) {
          this.loadData();
        }
      }
      if (val == "selectMonth") {
        if (this.startMonthRaw != null && this.endMonthRaw != null) {
          this.loadData();
        }
      }
    },
    selectedPeriod(val) {
      this.loadData();
    },
    startMonthRaw(val) {
      if (this.endMonthRaw != null) {
        this.loadData();
      }
    },
    endMonthRaw(val) {
      if (this.startMonthRaw != null) {
        this.loadData();
      }
    },
  },
  async created() {
    await this.getEstimatedRevenue();
    this.order();
    this.orderCancel();
    this.orderReturn();
    this.productSold();
    this.productSoldPerStore();
    this.productSoldAllStore();
  },
  computed: {
    apiFile() {
      return "https://api-heykama.notive.my.id";
    },
  },
  methods: {
    renderOrderChart() {
      const ctx2 = document.getElementById("orderChart2").getContext("2d");
      const ctx3 = document.getElementById("orderChart3").getContext("2d");
      new Chart(ctx2, {
        type: "line",
        data: {
          labels: ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
          datasets: [
            {
              label: "Lenses",
              data: [500, 1000, 1500, 1900, 2000, 1951],
              borderColor: "rgba(255, 206, 86, 1)",
              backgroundColor: "rgba(255, 206, 86, 0.2)",
              fill: true,
            },
            {
              label: "Frames",
              data: [600, 1200, 1800, 2200, 2500, 2554],
              borderColor: "rgba(54, 162, 235, 1)",
              backgroundColor: "rgba(54, 162, 235, 0.2)",
              fill: true,
            },
            {
              label: "Accs.",
              data: [200, 400, 600, 800, 1000, 680],
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
      new Chart(ctx3, {
        type: "line",
        data: {
          labels: ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov"],
          datasets: [
            {
              label: "Shopee",
              data: [500, 1000, 1500, 1900, 2000, 1951],
              borderColor: "rgba(254, 174, 75, 1)",
              backgroundColor: "rgba(254, 174, 75, 0.2)",
              fill: true,
            },
            {
              label: "Tokopedia",
              data: [600, 1200, 1800, 2200, 2500, 2554],
              borderColor: "rgba(111, 209, 150, 1)",
              backgroundColor: "rgba(111, 209, 150, 0.2)",
              fill: true,
            },
            {
              label: "Tiktok",
              data: [200, 400, 600, 800, 1000, 680],
              borderColor: "rgba(73, 74, 78, 1)",
              backgroundColor: "rgba(73, 74, 78, 0.2)",
              fill: true,
            },
            {
              label: "Lazada",
              data: [100, 450, 500, 900, 200, 880],
              borderColor: "rgba(137, 121, 254, 1)",
              backgroundColor: "rgba(137, 121, 254, 0.2)",
              fill: true,
            },
            {
              label: "WooComm",
              data: [260, 200, 700, 100, 400, 980],
              borderColor: "rgba(60, 195, 224, 1)",
              backgroundColor: "rgba(60, 195, 224, 0.2)",
              fill: true,
            },
            {
              label: "Manual",
              data: [100, 700, 900, 1000, 500, 380],
              borderColor: "rgba(255, 146, 139, 1)",
              backgroundColor: "rgba(255, 146, 139, 0.2)",
              fill: true,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
    renderPieChart() {
      const ctx = document.getElementById("revenuePieChart").getContext("2d");
      const ctx2 = document.getElementById("revenuePieChart2").getContext("2d");
      const ctx3 = document.getElementById("revenuePieChart3").getContext("2d");
      new Chart(ctx, {
        type: "pie",
        data: {
          labels: [
            "Lazada",
            "Shopee",
            "WooComm",
            "Manual",
            "Tiktok",
            "Tokopedia",
          ],
          datasets: [
            {
              data: [206, 247, 204, 70, 110, 189],
              backgroundColor: [
                "#8979fe",
                "#feae4b",
                "#3cc3e0",
                "#ff928b",
                "#494a4e",
                "#6fd196",
              ],
              borderColor: [
                "#8979fe",
                "#feae4b",
                "#3cc3e0",
                "#ff928b",
                "#494a4e",
                "#6fd196",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
      new Chart(ctx2, {
        type: "pie",
        data: {
          labels: [
            "Lazada",
            "Shopee",
            "WooComm",
            "Manual",
            "Tiktok",
            "Tokopedia",
          ],
          datasets: [
            {
              data: [206, 247, 204, 70, 110, 189],
              backgroundColor: [
                "#8979fe",
                "#feae4b",
                "#3cc3e0",
                "#ff928b",
                "#494a4e",
                "#6fd196",
              ],
              borderColor: [
                "#8979fe",
                "#feae4b",
                "#3cc3e0",
                "#ff928b",
                "#494a4e",
                "#6fd196",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
      new Chart(ctx3, {
        type: "pie",
        data: {
          labels: [
            "Lazada",
            "Shopee",
            "WooComm",
            "Manual",
            "Tiktok",
            "Tokopedia",
          ],
          datasets: [
            {
              data: [206, 247, 204, 70, 110, 189],
              backgroundColor: [
                "#8979fe",
                "#feae4b",
                "#3cc3e0",
                "#ff928b",
                "#494a4e",
                "#6fd196",
              ],
              borderColor: [
                "#8979fe",
                "#feae4b",
                "#3cc3e0",
                "#ff928b",
                "#494a4e",
                "#6fd196",
              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    },
    formatStartMonth() {
      if (this.startMonthRaw) {
        const [year, month] = this.startMonthRaw.split("-");
        this.startMonth = `${month}-${year}`;
      } else {
        this.startMonth = "";
      }
    },
    formatEndMonth() {
      if (this.endMonthRaw) {
        const [year, month] = this.endMonthRaw.split("-");
        this.endMonth = `${month}-${year}`;
      } else {
        this.endMonth = "";
      }
    },
    loadData() {
      this.getEstimatedRevenue();
      this.order();
      this.orderCancel();
      this.orderReturn();
      this.productSold();
      this.productSoldPerStore();
      this.productSoldAllStore();
    },
    exportDashboard() {
      this.$swal({
        title: "Anda yakin?",
        text: "Data Dashboard ini akan diexport",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (res) => {
        if (res.value) {
          this.loadExport = true;
          try {
            let params = {
              filter_type: this.selectedRange,
            };
            if (this.selectedRange == "selectPeriod") {
              params.filter_type = this.selectedPeriod;
            }
            if (this.selectedRange == "selectMonth") {
              params.filter_type = "custom_year_to_year";
              params.start_month = this.startMonth;
              params.end_month = this.endMonth;
            }
            let response = await this.$store.dispatch(
              "dashboard/Export",
              params
            );
            const fileUrl = this.apiFile + response.url;
            window.open(fileUrl, "_blank");
            this.displaySuccess({
              message: "Data Dashboard Berhasil di Export",
            });
            this.loadExport = false;
          } catch (e) {
            console.log("error", e);
          }
        }
      });
    },
    async getEstimatedRevenue() {
      this.loadEstimatedRevenue = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch(
          "dashboard/EstimatedRevenue",
          params
        );
        let data = response.data;
        let total = response.total;
        this.dataEstimatedRevenue = data;
        this.totalEstimatedRevenue = total;
        this.loadEstimatedRevenue = false;
      } catch (e) {
        console.log("error", e);
      }
    },
    async order() {
      this.loadOrder = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch("dashboard/Order", params);
        let data = response.data;
        let total = response.total;
        let graphic_data = response.graphic_data;
        this.dataOrder = data;
        this.totalOrder = total;
        this.grafikDataOrder = graphic_data;
        this.loadOrder = false;
      } catch (e) {
        console.log("error", e);
      }
    },
    async orderCancel() {
      this.loadCancel = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch(
          "dashboard/OrderCancel",
          params
        );
        let data = response.data;
        let total = response.total;
        this.dataCancelOrder = data;
        this.totalCancelOrder = total;
        this.loadCancel = false;
      } catch (e) {
        console.log("error", e);
      }
    },
    async orderReturn() {
      this.loadReturn = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch(
          "dashboard/OrderReturn",
          params
        );
        let data = response.data;
        let total = response.total;
        this.dataReturnOrder = data;
        this.totalReturnOrder = total;
        this.loadReturn = false;
      } catch (e) {
        console.log("error", e);
      }
    },
    async productSold() {
      this.loadProductSold = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch(
          "dashboard/ProductSold",
          params
        );
        let data = response.data;
        let total = response.total;
        let grafik = response.graphic_data;
        this.dataProductSold = data;
        this.totalProductSold = total;
        this.grafikProductSold = grafik;
        this.loadProductSold = false;
      } catch (e) {
        console.log("error", e);
      }
    },
    async productSoldPerStore() {
      this.loadProductSoldPerStore = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch(
          "dashboard/ProductSoldPerStore",
          params
        );
        let data = response;
        this.dataProductSoldPerStore = data;
        this.loadProductSoldPerStore = false;
      } catch (e) {
        console.log("error", e);
      }
    },
    async productSoldAllStore() {
      this.loadProductSoldAllStore = true;
      try {
        let params = {
          filter_type: this.selectedRange,
        };
        if (this.selectedRange == "selectPeriod") {
          params.filter_type = this.selectedPeriod;
        }
        if (this.selectedRange == "selectMonth") {
          params.filter_type = "custom_year_to_year";
          params.start_month = this.startMonth;
          params.end_month = this.endMonth;
        }
        let response = await this.$store.dispatch(
          "dashboard/ProductSoldAllStore",
          params
        );
        let data = response.data;
        let grafik = response.graphic_data;
        this.dataProductSoldAllStore = data;
        this.grafikProductSoldAllStore = grafik;
        this.loadProductSoldAllStore = false;
      } catch (e) {
        console.log("error", e);
      }
    },
  },
};
</script>
<style scoped>
.border {
  background-color: white;
  border-radius: 8px;
}
.bg-success {
  border-radius: 8px 8px 0 0;
  font-weight: bold;
}
.b-badge {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.9rem;
}
.sticky-range {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 90px; /* Distance from the top when scrolling */
  z-index: 1000; /* To make sure it's above other content */
}
canvas {
  max-height: 600px;
}
</style>
