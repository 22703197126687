var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "24"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.myGudang ? _c('header', [_c('h5', [_c('strong', [_vm._v("Lokasi Gudang: " + _vm._s(this.myGudang.nama_gudang ? this.myGudang.nama_gudang : this.myGudang.data.nama_gudang))])])]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.so.tanggal))]), _vm.so.gm ? _c('section', {
    staticClass: "mt-1"
  }, [_c('p', [_c('strong', [_vm._v("General Manager")])]), _c('p', [_vm._v(" " + _vm._s(_vm.so.gm.nama_lengkap) + " ")])]) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.so.keterangan ? _vm.so.keterangan : "-"))])]), _vm.so.status == 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "size": "sm"
    }
  }, [_vm._v("Belum Selesai")])], 1) : _vm._e(), _vm.so.status == 1 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e()], 1), _vm.isGM && !_vm.so.id_gm ? _c('section', {
    staticClass: "d-flex justify-content-end my-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.approve($event);
      }
    }
  }, [_vm._v("Approve Stok Opname")])], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "500px"
    }
  }, [_c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "small": "",
      "bordered": "",
      "striped": "",
      "sticky-header": "400px",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.so_rincian
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.stok) + " ")];
      }
    }, {
      key: "cell(so)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.id_stok_opname) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.stok_real - item.stok_in) + " "), _c('p', [item.stok < item.stok_realComputed ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("(Stok Hasil SO lebih)")])]) : _vm._e(), item.stok == item.stok_realComputed ? _c('small', {
          staticClass: "text-success"
        }, [_c('i', [_vm._v("(Stok sesuai)")])]) : _vm._e(), item.stok > item.stok_realComputed ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("(Stok Hasil SO kurang)")])]) : _vm._e()])];
      }
    }, {
      key: "cell(kode2)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.penyimpanan ? _c('span', [_vm._v(" " + _vm._s(item.penyimpanan && item.penyimpanan.barang && item.penyimpanan.barang.kode ? item.penyimpanan.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan && item.penyimpanan.barang && item.penyimpanan.barang.no_batch ? item.penyimpanan.barang.no_batch : "-"))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Data terhapus")])];
      }
    }, {
      key: "cell(nama2)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.penyimpanan ? _c('span', [_vm._v(" " + _vm._s(item.penyimpanan ? item.penyimpanan.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan && item.penyimpanan.barang.kategori ? item.penyimpanan.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.penyimpanan ? item.penyimpanan.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.penyimpanan && item.penyimpanan.blok ? item.penyimpanan.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan && item.penyimpanan.palet ? item.penyimpanan.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan && item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan && item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-"))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Data Terhapus")])];
      }
    }])
  })], 1), _vm.alreadyApproved ? _c('section', {
    staticClass: "my-2 d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v("Simpan Penyesuaian")])], 1) : _vm._e()], 1)], 1), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.save($event);
      }
    }
  }, [_vm._v("Simpan")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }