var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle ? _c('b-row', {
    staticClass: "content-header"
  }, [_c('b-col', {
    staticClass: "content-header-left mb-2",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "breadcrumbs-top"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "content-header-title float-left pr-1 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]), _c('div', {
    staticClass: "breadcrumb-wrapper"
  }, [_c('b-breadcrumb', [_c('b-breadcrumb-item', {
    attrs: {
      "to": "/"
    }
  }, [_c('feather-icon', {
    staticClass: "align-text-top",
    attrs: {
      "icon": "HomeIcon",
      "size": "16"
    }
  })], 1), _vm._l(_vm.$route.meta.breadcrumb, function (item, index) {
    return _c('b-breadcrumb-item', {
      key: item.text,
      attrs: {
        "active": item.active,
        "to": item.to
      }
    }, [_vm.$route.meta.breadcrumb.length > 1 && index !== _vm.$route.meta.breadcrumb.length - 1 ? [_c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm",
        "pill": ""
      }
    }, [_c('u', [_vm._v(" " + _vm._s(_vm.$t('Back to ')) + _vm._s(_vm.$t(item.text)) + " ")])])] : [_vm._v(" " + _vm._s(_vm.$t(item.text)) + " ")]], 2);
  })], 2)], 1)])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }