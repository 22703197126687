<template>
  <main>
    <div class="bg-success text-white p-1">ESTIMATED REVENUE</div>
    <div class="p-1 border">
      <b-overlay :show="load">
        <div
          class="p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row mb-2">
            <div class="col-md-3">
              <h3 class="mb-0 mt-1">Estimated Total Revenue</h3>
            </div>
            <div class="col-md-9">
              <div
                style="background: #9fa0a2"
                class="text-white text-center p-1 rounded"
              >
                <b-badge variant="secondary" class="p-1 mr-1">
                  <span>Total </span>
                </b-badge>
                <strong>~Rp.{{ formatRupiah(total) }} </strong>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mt-1">
              <div
                style="background: #fde6ba"
                class="text-dark text-center p-1 rounded"
              >
                <b-badge variant="warning" class="text-dark p-1 mr-1">
                  <span>Lenses</span>
                </b-badge>
                <strong>~Rp.{{ formatRupiah(total_lensa) }}</strong>
              </div>
            </div>
            <div class="col-md-4 mt-1">
              <div
                style="background: #ebf6fc"
                class="text-dark text-center p-1 rounded"
              >
                <b-badge variant="info" class="text-dark p-1 mr-1">
                  <span>Frames</span>
                </b-badge>
                <strong>~Rp.{{ formatRupiah(total_frame) }}</strong>
              </div>
            </div>
            <div class="col-md-4 mt-1">
              <div
                style="background: #eff6ef"
                class="text-dark text-center p-1 rounded"
              >
                <b-badge variant="success" class="text-dark p-1 mr-1">
                  <span>Accs.</span>
                </b-badge>
                <strong>~Rp.{{ formatRupiah(total_accs) }}</strong>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>
  </main>
</template>

<script>
import { BBadge, BOverlay } from "bootstrap-vue";

export default {
  props: ["load", "data", "total"],
  data() {
    return {
      total_lensa: 0,
      total_frame: 0,
      total_accs: 0,
    };
  },
  components: {
    BBadge,
    BOverlay,
  },
  watch: {
    data(val) {
      this.total_lensa = val.find((res) => {
        return res.mark_category_as == "lense";
      }).total_revenue;
      this.total_frame = val.find((res) => {
        return res.mark_category_as == "frame";
      }).total_revenue;
      this.total_accs = val.find((res) => {
        return res.mark_category_as == "accessory";
      }).total_revenue;
    },
  },
};
</script>

<style>
.border {
  background-color: white;
  border-radius: 8px;
}

.bg-success {
  border-radius: 8px 8px 0 0;
  font-weight: bold;
}
</style>
