import Vue from "vue";

// axios
import axios from "axios";
import secret from "@/auth/secret";
import router from "@/router";

const baseURL = () =>
  process.env.VUE_APP_API ||
  process.env.VUE_APP_API2 ||
  "https://api-heykama.notive.my.id/v1" ||
  "https://api-heykama.notive.my.id/v2";
// const baseURL = () => process.env.VUE_APP_API || "https://api.armezza.com/v1";
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: baseURL(),
  headers: {
    secret:
      "aKndsan23928h98hKJbkjwlKHD9dsbjwiobqUJGHBDWHvkHSJQUBSQOPSAJHVwoihdapq",
    device: "web",
  },
});

// mod aauthorization header
axiosIns.interceptors.request.use(
  function (conf) {
    let headers = conf.headers;
    if (headers && (headers.authorization || headers.Authorization)) {
      const authorizationHeader =
        headers.authorization || headers.Authorization;
      headers.author = authorizationHeader;
      conf.headers = headers;
    }
    return conf;
  },
  function (e) {
    return Promise.reject(e);
  }
);

// Add Interceptors to response
axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error.response);
    localStorage.setItem("lastPathName", router.history.current.name);

    if (error.response.status == 401) {
      // router.push('/login')
      axios
        .post(
          baseURL + "/auth/refresh",
          {},
          {
            headers: {
              Authorization: `${localStorage.tokenType} ${localStorage.token}`,
              secret,
              device: "web",
            },
          }
        )
        .then((res) => res.data)
        .then(({ tokens }) => {
          localStorage.setItem("token", tokens);
          return Promise.resolve();
        })
        .catch((e) => {
          const path = window.location.pathname;
          router.push(`/login?to=${path}`);
        });
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
