<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <!-- <img
        <!-- <img
              src="../../../../public/assets/logo-heykama.png"
              alt=""
              style="width: 130px"
            /> -->
        <!-- <h2 class="brand-text text-primary ml-1">
          {{ appFullName }}
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t('Welcome to ') }} {{ appName }}
          </b-card-title>
          <!-- <b-card-text class="mb-2">
            Silahkan masuk dengan akun anda
          </b-card-text> -->

          <!-- form --><validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="email" label="Email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="email" v-model="userEmail" name="login-email"
                    :state="errors.length > 0 ? false : null" placeholder="john@example.com" autofocus />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="password">Password</label>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="password" v-model="password" :type="passwordFieldType" class="form-control-merge"
                      :state="errors.length > 0 ? false : null" name="login-password" placeholder="Password" />

                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <div class="d-flex justify-content-between">
                  <label for="password"></label>
                  <b-link :to="{ path: 'forgot-password' }">
                    <small>Lupa Password?</small>
                  </b-link>
                </div>
              </b-form-group>

              <!-- submit button -->
              <b-button variant="info" type="submit" class="gradient-affoca border border-0" block
                :disabled="!isValidForm" @click.prevent="submit">
                <strong>{{ loginLabel }}</strong>
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
            </div>
          </div> -->

          <div
            class="auth-footer-btn d-flex justify-content-center align-items"
          >
            <img
              src="../../../../public/assets/logo-heykama.png"
              alt=""
              style="width: 130px"
            />
          </div>

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      userEmail: null,
      password: null,
      status: "",
      // validation rules
      loading: false,
      loginLabel: "LOGIN",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    appLogoImage() {
      return $themeConfig.app.appLogoImage;
    },
    appName() {
      return $themeConfig.app.appName;
    },
    appFullName() {
      return $themeConfig.app.appFullName;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    isValidForm() {
      if (!this.password || !this.userEmail) {
        return false;
      }

      return true;
    },
  },
  methods: {
    submit() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          try {
            this.loading = true;
            this.loginLabel = "LOADING...";
            const response = await useJwt.login({
              email: this.userEmail,
              password: this.password,
            });
            let {
              data: userData,
              access: accessMenu,
              type: typeToken,
              tokens: token,
            } = response.data;

            if (!userData.level) {
              this.displayError({
                message: "User tidak terdaftar",
              });

              return false;
            }
            useJwt.setToken({ type: typeToken, token });

            // get role user
            const roles = await this.$store.dispatch("role/getData", {
              level_id: userData.level.id,
            });
            if (!roles) {
              this.displayError({
                message: "User belum memiliki akses",
              });
              return false;
            }
            const myMenus = [];
            accessMenu.map((parent) => {
              const payload = {
                title: parent.name,
                icon: parent.icon,
                route: parent.url,
                url: parent.url,
              };

              // parent menu
              if (parent.sub_menu) {
                payload.children = [];
                // loop sub menu
                parent.sub_menu.map((sb) => {
                  const sb_payload = {
                    title: sb.name,
                    // icon: "CircleIcon",
                    icon: sb.icon,
                    route: sb.url,
                    url: sb.url,
                  };

                  if (sb.notifikasi && sb.notifikasi.notif > 0) {
                    sb_payload.tag = sb.notifikasi.notif;
                  }

                  payload.children.push(sb_payload);
                });
              } else {
                // link menu
                payload.route = parent.url;
                payload.url = parent.url;
                // get notifikasi if they > 0
                if (parent.notifikasi && parent.notifikasi.notif > 0) {
                  payload.tag = parent.notifikasi.notif;
                }
              }

              myMenus.push(payload);
            });

            // check if user is admin gudang, get his gudang
            const isSPV =
              userData.level &&
              this.SPV_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isAdminGudang =
              userData.level &&
              this.GUDANG_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isStaffGudang =
              userData.level &&
              this.STAFFGUDANG_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isCabang =
              userData.level &&
              this.CABANG_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isMarketing =
              userData.level &&
              this.MKT_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            const isTerapis =
              userData.level &&
              this.TERAPIS_KEYS.includes(
                userData.level.nama_level
                  .split(" ")
                  .map((word) => word.toLowerCase())
                  .join("_")
              );
            // if (isSPV || isCabang || isMarketing) {
            // const myKaryawanId = userData.karyawan
            //   ? userData.karyawan.id
            //   : userData.member_id;

            // let payload =
            //   userData.level.nama_level == "cabang" ||
            //     userData.level.nama_level == "marketing"
            //     ? { member_id: myKaryawanId }
            //     : { karyawan_id: myKaryawanId };

            const myGudang = await this.$store.dispatch(
              "gudang/getData"
            );
            let params = {}
            params.gudang_id = myGudang[0].id ? myGudang[0].id : myGudang[0].data.id;
            const myBlok = await this.$store.dispatch(
              "tokoblok/getData",
              params
            );

            // if (!myGudang || myGudang.length < 1) {
            //   localStorage.clear();
            //   sessionStorage.clear();
            //   this.displayError({
            //     message: "Akun Gudang belum ditempatkan, hubungi Admin",
            //   });
            //   this.loginLabel = "LOGIN";
            //   return false;
            // }

            localStorage.setItem("myGudang", JSON.stringify(myGudang[0]));
            localStorage.setItem("myBlok", JSON.stringify(myBlok));
            // } else if (isStaffGudang || isAdminGudang) {
            //   const items = await this.$store.dispatch("kasir/getData", {kasir_id : userData.karyawan.id});
            //   localStorage.setItem("StaffGudang", JSON.stringify(items[0]));
            //   console.log('CEK ITEMS GUDANG', items)
            //   const myGudangStaff = await this.$store.dispatch("gudang/getDataById",items[0].gudang_id);
            //   // console.log('CEK ITEMS GUDANG', myGudangStaff)

            //   localStorage.setItem("myGudang", JSON.stringify(myGudangStaff));
            // }

            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("accessMenu", JSON.stringify(myMenus));
            localStorage.setItem("userRoles", JSON.stringify(roles));
            this.updateAbility(roles);
            this.loading = false;
            this.displaySuccess({
              message: 'Selamat anda berhasil login'
            })

            if (this.$route.query.to) {
              const path = this.$route.query.to;
              window.location.href = path;
            } else {
              window.location.reload();
            }
          } catch (e) {
            this.loading = false;
            this.loginLabel = "LOGIN";
            this.displayError(e);
            return false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
