<template>
    <b-modal modal-header-close id="modal-supplier" cancel-variant="secondary" ok-title="Add" cancel-title="Batal"
        centered title="Form Supplier" size="lg" no-close-on-esc no-close-on-backdrop>
        <template #modal-header-close>
            <b-button variant="danger" class="close" @click="resetForm(); closemodal();">
                &times;
            </b-button>
        </template>
        <b-row>
            <b-col md="12" sm="12">
                <b-form-group>
                    <label for="kode">{{ $t('Kode Supplier') }}<span class="text-danger"><i> (*)</i></span></label>
                    <b-form-input v-model="form.kode" id="kode" type="text" placeholder="Kode Supplier" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="nama">{{ $t('Nama PIC') }}<span class="text-danger"><i> (*)</i></span></label>
                    <b-form-input v-model="form.nama" id="nama" type="text" placeholder="Ex: Budi" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="nohp">{{ $t('No. Handphone PIC') }}<span class="text-danger"><i> (*)</i></span></label>
                    <b-form-input v-model="form.no_hp_pic" id="nohp" type="text" placeholder="Ex: 08888888888" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="instansi">{{ $t('Nama Instansi') }}<span class="text-danger"><i> (*)</i></span></label>
                    <b-form-input v-model="form.instansi" id="instansi" type="text" placeholder="Ex: PT. Indo Jaya" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="nohp">{{ $t('No. Handphone') }} <span class="text-danger"><i>(*)</i></span></label>
                    <b-form-input v-model="form.nohp" id="nohp" type="text" placeholder="Ex: 08888888888" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="email">{{ $t('Email') }} <span class="text-info"><i>(opsional)</i></span></label>
                    <b-form-input v-model="form.email" id="email" type="email" placeholder="Ex: supplier@gmail.com" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="top">{{ $t('Terms Of Payment') }} <span
                            class="text-info"><i>(opsional)</i></span></label>
                    <b-form-input v-model="form.top" id="top" type="text" placeholder="COD/Cash dll" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="alamat">{{ $t('Alamat') }} <span class="text-info"><i>(opsional)</i></span></label>
                    <b-form-textarea v-model="form.alamat"></b-form-textarea>
                </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
                <b-form-group>
                    <label for="memo">{{ $t('Memo') }} <span class="text-info"><i>(opsional)</i></span></label>
                    <b-form-textarea v-model="form.memo"></b-form-textarea>
                </b-form-group>
            </b-col>
            <!-- <b-col md="10" sm="10">
                <b-form-group label-cols="4" label-size="sm" label-cols-lg="2" :label="$t('Bank Account')"
                    label-for="input-default">
                    <v-select v-model="form.rekening_ids" label="text" multiple :options="rekeningOptions"></v-select>
                </b-form-group>
            </b-col> -->
            <!-- <b-col md="2" sm="2">
                <b-form-group>
                    <b-button variant="primary" @click="addrekening">
                        Add
                    </b-button>
                </b-form-group>
            </b-col> -->
        </b-row>

        <hr>
        <div>
            <b-form ref="form" :style="{ height: trHeight }" class="repeater-form" @submit.prevent="repeateAgain">

                <!-- Row Loop -->
                <b-row v-for="(item, index) in this.form.rekenings" :id="item.id" :key="item.id" ref="row">

                    <!-- Nama Bank -->
                    <b-col md="2">
                        <b-form-group :label="$t('Bank Name')" label-for="nama">
                            <b-form-input id="nama" type="text" placeholder="BSI/BRI etc" v-model="item.nama" />
                        </b-form-group>
                    </b-col>

                    <!-- Cost -->
                    <b-col md="4">
                        <b-form-group :label="$t('Bank Account Number')" label-for="no_rekening">
                            <b-form-input id="no_rekening" type="text" placeholder="81798169286"
                                v-model="item.no_rekening" />
                        </b-form-group>
                    </b-col>

                    <!-- Quantity -->
                    <b-col md="4">
                        <b-form-group :label="$t('Description')" label-for="description">
                            <b-form-input id="description" type="text" placeholder="A.n Heykama"
                                v-model="item.deskripsi" />
                        </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <b-col lg="2" md="3" class="mb-50" v-if="item.id == null">
                        <b-button size="sm" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>{{ $t('Delete') }}</span>
                        </b-button>
                    </b-col>
                    <b-col lg="2" md="3" class="mb-50" v-if="item.id">
                        <b-button size="sm" variant="danger" class="mt-0 mt-md-2" @click="remove(item)">
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>{{ $t('Delete') }}</span>
                        </b-button>
                    </b-col>
                    <b-col cols="12">
                        <hr>
                    </b-col>
                </b-row>

            </b-form>
        </div>
        <b-button variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>{{ $t('Add New') }}</span>
        </b-button>
        <!-- <br>
        <label>Rekenings</label>
        {{ form.rekenings }}
        <br>
        <label>rekening_ids</label>
        {{ form.rekening_ids }} -->

        <template #modal-footer>
            <section class="d-flex justify-content-end align-rekenings-center">
                <b-button :disabled="!isValidForm" class="mr-1" variant="primary" @click="submit1">
                    {{ $t('Save') }}
                </b-button>
                <b-button class="mr-1" variant="danger" @click="closemodal()">
                    {{ $t('Close') }}
                </b-button>
            </section>
        </template>
    </b-modal>
</template>
<script>
import { BModal, BButton, BForm, BRow, BCol, BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue'
import vSelect from "vue-select";
import { heightTransition } from '@core/mixins/ui/transition'
export default {
    props: ['item'],
    mixins: [heightTransition],
    components: {
        vSelect, BModal, BButton, BForm, BRow, BCol, BFormGroup, BFormInput, BFormTextarea
    },
    watch: {
        item(val) {
            // if (val.nama) {
            this.form.id = val.id ? val.id : null
            this.form.kode = val.kode
            this.form.nama = val.nama
            this.form.instansi = val.instansi
            this.form.email = val.email
            this.form.nohp = val.nohp
            this.form.alamat = val.alamat
            this.form.memo = val.memo
            this.form.no_hp_pic = val.no_hp_pic
            this.form.top = val.top
            this.form.rekenings = val.rekenings
            this.form.rekening_ids = []
            // }
        }
    },
    data: () => ({
        // item: {},
        // selected: 'male',
        // selected1: 'designer',
        // prevHeight: 0,
        // nextTodoId: 2,
        editMode: false,
        form: {
            kode: null,
            nama: null,
            instansi: null,
            email: null,
            nohp: null,
            alamat: null,
            memo: null,
            no_hp_pic: null,
            top: null,
            rekening_ids: [],
            rekenings: [{
                nama: null,
                no_rekening: null,
                type: 'withdraw',
                deskripsi: null,
                rekening_utama: 0,
            }],
        },
        rekeningOptions: [],
    }),
    computed: {
        isValidForm() {
            if (!this.form.nama || !this.form.instansi || !this.form.nohp || !this.form.no_hp_pic) {
                return false
            }

            return true
        }
    },
    // created() {
    //     window.addEventListener('resize', this.initTrHeight)
    // },
    // destroyed() {
    //     window.removeEventListener('resize', this.initTrHeight)
    // },
    // mounted() {
    //     this.initTrHeight()
    // },
    methods: {
        repeateAgain() {
            this.form.rekenings.push({
                type: 'withdraw',
                rekening_utama: 0,
            })

            this.$nextTick(() => {
                this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
        },
        removeItem(index) {
            this.form.rekenings.splice(index, 1)
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
        },
        initTrHeight() {
            this.trSetHeight(null)
            // this.$nextTick(() => {
            //     this.trSetHeight(this.$refs.form.scrollHeight)
            // })
        },
        closemodal() {
            this.$bvModal.hide('modal-supplier')
            this.resetForm()
        },
        resetForm() {
            this.form.kode = null
            this.form.nama = null
            this.form.instansi = null
            this.form.email = null
            this.form.nohp = null
            this.form.alamat = null
            this.form.memo = null
            this.form.no_hp_pic = null
            this.form.top = null
            this.form.rekening_ids = []
            this.form.rekenings = []
        },
        submit1() {
            this.form.rekening_ids = []
            if (!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form'
                })
            }
            if (this.form.rekenings.length > 0 && this.id == null) {
                for (let i = 0; i < this.form.rekenings.length; i++) {
                    let payload2 = new FormData();
                    payload2.append("nama", this.form.rekenings[i].nama);
                    payload2.append("no_rekening", this.form.rekenings[i].no_rekening);
                    payload2.append("type", this.form.rekenings[i].type ? this.form.rekenings[i].type : 'withdraw');
                    payload2.append("deskripsi", this.form.rekenings[i].deskripsi);
                    if (this.form.rekenings[i].id) {
                        payload2.append("id", this.form.rekenings[i].rekening_id);
                        // payload2.append("supplier_id", this.form.rekenings[i].supplier_id);
                        // payload2.append("rekening_utama", this.form.rekenings[i].rekening_utama == true ? 1 : 0);
                    }

                    this.$store
                        .dispatch("rekening/save", payload2)
                        .then((response) => {
                            let res = response.id
                            // console.log('cel', res)
                            this.form.rekening_ids.push({ id: res })
                        })

                    if (this.form.rekening_ids.length === i) {
                        // console.log('POST', this.form.rekening_ids)
                        setTimeout(() => this.submit(), 2000)
                    }
                }
            }

            // this.submit()
        },
        submit() {
            if (!this.isValidForm) {
                this.displayError({
                    message: 'Harap lengkapi form'
                })
            }
            // console.log('rekening', this.form.rekening_ids)
            // for (let i = 0; i < this.rekenings.length; i++) {
            //     let payload2 = new FormData();
            //     payload2.append("nama", this.rekenings[i].nama);
            //     payload2.append("no_rekening", this.rekenings[i].no_rekening);
            //     payload2.append("type", this.rekenings[i].type);
            //     payload2.append("deskripsi", this.rekenings[i].deskripsi);
            //     if (this.id) {
            //         payload2.append("id", this.id);
            //         payload2.append("rekening_utama", this.rekenings[i].rekening_utama == true ? 1 : 0);
            //     }

            //     this.$store
            //         .dispatch("rekening/save", payload2)
            //         .then((response) => {
            //             let res = response.id
            //             console.log('cel', res)
            //             console.log('celres', response)
            //             this.form.rekening_ids.push({ id: res })
            //         })
            // }

            // this.form.rekening_ids = response.id
            // console.log('rek', this.form.rekening_ids)
            const payload = this.form
            if (this.form.id) {
                payload.id = this.form.id ? this.form.id : null
            }
            if (!payload.alamat) {
                payload.alamat = '-'
            }
            if (!payload.memo) {
                payload.memo = '-'
            }

            this.$emit('submit', payload, (status) => {
                if (status) this.resetForm
            })
            setTimeout(() => this.resetForm(), 500)
        },
        remove(item) {
            this.$swal({
                title: 'Anda yakin?',
                text: `Data Supplier ${item.nama} ini akan dihapus`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Batal',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    item.fungsi = 1 // soft delete
                    const payloadrek = {
                        fungsi: 1,
                        id: item.rekening_id,
                        // supplier_id: item.supplier_id,
                        // rekening_id: item.rekening_id,
                    }
                    const payload = {
                        // fungsi: 1,
                        // id: item.rekening_id,
                        supplier_id: item.supplier_id,
                        rekening_id: item.rekening_id,
                    }
                    this.$store.dispatch('supplier/deleterekinsup', [payload])
                        .then(() => {
                            this.displaySuccess({
                                message: 'Berhasil'
                            })
                            // this.resetForm()
                            this.$store.dispatch('rekening/save', payloadrek)

                                .then(() => {
                                    this.displaySuccess({
                                        message: 'Berhasil'
                                    })
                                    // this.resetForm()
                                    this.$bvModal.hide('modal-supplier')
                                    this.$emit('getData', payload, (status) => {
                                        if (status) this.resetForm
                                    })
                                    setTimeout(() => this.resetForm(), 500)
                                })
                                .catch(e => {
                                    this.displayError(e)
                                    return false
                                })
                        })
                        .catch(e => {
                            this.displayError(e)
                            return false
                        })
                }
            })
        },
        getDataRekening() {
            var params = {
            };
            this.$store
                .dispatch("rekening/getData", params)
                .then(() => {
                    let items = JSON.parse(
                        JSON.stringify(this.$store.state.rekening.datas)
                    );
                    //   let items_total = this.$store.state.rekening.totals;
                    this.rekeningOptions = items;
                    this.rekeningOptions.map((hem) => {
                        hem.text = hem.nama + ' - ' + hem.no_rekening + ' - ' + hem.deskripsi;
                        hem.value = hem.id;
                    });
                    //   this.totalRows = items_total;
                })
                .catch((e) => {
                    this.displayError(e)
                    console.error(e);
                    this.modeerror = 1;
                });
        },
    }
}
</script>
<style lang="scss" scoped>
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}
</style>