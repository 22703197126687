import service from "@/services";

// Module Vuex kategori
export default {
  namespaced: true,
  state: {
    datas: [],
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_TOTAL(state, total) {
      state.totals = total;
    },
    SET_DATA_SP(state, data) {
      state.datassetting = data;
    },
    SET_TOTAL_SP(state, total) {
      state.totalssetting = total;
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/kategori",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/kategori",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataMaster({ commit }, params = {}) {
      try {
        const config = {
          url: "/master-kategori",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.total;
        commit("SET_DATA", result);
        commit("SET_TOTAL", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveMaster({}, payload = {}) {
      try {
        const config = {
          url: "/input/master-kategori",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataSetting({ commit }, params = {}) {
      try {
        const config = {
          url: "/setting-packaging",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        const result_total = response.data.recordsTotal;
        commit("SET_DATA_SP", result);
        commit("SET_TOTAL_SP", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveSetting({}, payload = {}) {
      try {
        const config = {
          url: "/setting-packaging",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async deleteSetting({}, payload = {}) {
      try {
        const config = {
          url: "/setting-packaging",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "delete",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
