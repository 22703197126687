import service from "@/services";

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getData: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
  },
  actions: {
    async printAjuan({}, params = {}) {
      try {
        const config = {
          url: "/cetak-disposal",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "blob",
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        if (e.response && e.response.data) {
          const blob = e.response.data;
          const text = await blob.text();
          const errorJson = JSON.parse(text);

          return Promise.reject(errorJson);
        }
        return Promise.reject(e);
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/gudang-disposal/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/gudang-disposal",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/gudang-disposal",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async delete({}, payload = {}) {
      try {
        const config = {
          url: "/delete/gudang-disposal",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async savesurat({}, payload = {}) {
      try {
        const config = {
          url: "/input/gudang-disposal-attachment",
          // baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDatapersurat({ commit }, params = {}) {
      try {
        const config = {
          url: "/gudang-disposal-per-attachment",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result =
          response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
