var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container mt-2"
  }, [_c('div', {
    staticClass: "sticky-range border p-1"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Range:",
      "label-cols-sm": "3"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "range-options",
      "buttons": "",
      "button-variant": "outline-secondary",
      "size": "sm"
    },
    model: {
      value: _vm.selectedRange,
      callback: function ($$v) {
        _vm.selectedRange = $$v;
      },
      expression: "selectedRange"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "current_month"
    },
    on: {
      "click": function ($event) {
        return _vm.loadData();
      }
    }
  }, [_vm._v("This Month")]), _c('b-form-radio', {
    attrs: {
      "value": "selectPeriod"
    }
  }, [_vm._v("Select Period")]), _c('b-form-radio', {
    attrs: {
      "value": "selectMonth"
    }
  }, [_vm._v("Select Month")])], 1), _vm.selectedRange === 'selectPeriod' ? _c('b-form-select', {
    staticClass: "mt-1",
    attrs: {
      "options": _vm.periodOptions
    },
    model: {
      value: _vm.selectedPeriod,
      callback: function ($$v) {
        _vm.selectedPeriod = $$v;
      },
      expression: "selectedPeriod"
    }
  }) : _vm._e(), _vm.selectedRange === 'selectMonth' ? _c('div', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.startMonthRaw,
      expression: "startMonthRaw"
    }],
    staticClass: "form-control me-2",
    attrs: {
      "type": "month",
      "max": "endMonthRaw",
      "placeholder": "Start Month"
    },
    domProps: {
      "value": _vm.startMonthRaw
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.startMonthRaw = $event.target.value;
      }, _vm.formatStartMonth]
    }
  }), _c('span', {
    staticClass: "mx-2"
  }, [_vm._v("to")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.endMonthRaw,
      expression: "endMonthRaw"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "month",
      "min": "startMonthRaw",
      "placeholder": "End Month"
    },
    domProps: {
      "value": _vm.endMonthRaw
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.endMonthRaw = $event.target.value;
      }, _vm.formatEndMonth]
    }
  })]) : _vm._e(), _c('b-button', {
    staticClass: "mt-1 float-right",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.exportDashboard($event);
      }
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadExport
    }
  }, [_vm._v("Export")])], 1)], 1)], 1), _c('estimated-revenue', {
    attrs: {
      "load": _vm.loadEstimatedRevenue,
      "data": _vm.dataEstimatedRevenue,
      "total": _vm.totalEstimatedRevenue
    }
  }), _c('order', {
    attrs: {
      "loadOrder": _vm.loadOrder,
      "loadCancel": _vm.loadCancel,
      "loadReturn": _vm.loadReturn,
      "data": _vm.dataOrder,
      "total": _vm.totalOrder,
      "grafikData": _vm.grafikDataOrder,
      "dataCancel": _vm.dataCancelOrder,
      "totalCancel": _vm.totalCancelOrder,
      "dataReturn": _vm.dataReturnOrder,
      "totalReturn": _vm.totalReturnOrder
    }
  }), _c('product-by-store', {
    attrs: {
      "loadProductSold": _vm.loadProductSold,
      "loadProductSoldPerStore": _vm.loadProductSoldPerStore,
      "loadProductSoldAllStore": _vm.loadProductSoldAllStore,
      "dataProductSold": _vm.dataProductSold,
      "totalProductSold": _vm.totalProductSold,
      "grafikProductSold": _vm.grafikProductSold,
      "dataProductSoldPerStore": _vm.dataProductSoldPerStore,
      "dataProductSoldAllStore": _vm.dataProductSoldAllStore,
      "grafikProductSoldAllStore": _vm.grafikProductSoldAllStore
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }