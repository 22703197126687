import { render, staticRenderFns } from "./Order.vue?vue&type=template&id=4ae067f8"
import script from "./Order.vue?vue&type=script&lang=js"
export * from "./Order.vue?vue&type=script&lang=js"
import style0 from "./Order.vue?vue&type=style&index=0&id=4ae067f8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports