<template>
  <main>
    <div class="bg-success text-white p-1">ORDER</div>
    <div class="p-1 border">
      <b-overlay :show="loadOrder">
        <div
          class="p-1"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <div class="row">
            <div class="col-md-2">
              <h3 class="mb-0">Total Order</h3>
            </div>
            <div class="col-md-2">
              <div
                style="background: #9fa0a2"
                class="text-white text-center p-1 rounded mt-1"
              >
                <b-badge variant="secondary" class="text-white p-1 mr-1">
                  <span>Total</span>
                </b-badge>
                <strong>{{ total }}</strong>
              </div>
              <div
                style="background: #fde6ba"
                class="text-dark text-center p-1 rounded mt-1"
              >
                <b-badge variant="warning" class="text-dark p-1 mr-1">
                  <span>Lenses</span>
                </b-badge>
                <strong>{{ total_lensa }}</strong>
              </div>
              <div
                style="background: #ebf6fc"
                class="text-dark text-center p-1 rounded mt-1"
              >
                <b-badge variant="info" class="text-dark p-1 mr-1">
                  <span>Frames</span>
                </b-badge>
                <strong>{{ total_frame }}</strong>
              </div>
              <div
                style="background: #eff6ef"
                class="text-dark text-center p-1 rounded mt-1"
              >
                <b-badge variant="success" class="text-dark p-1 mr-1">
                  <span>Accs.</span>
                </b-badge>
                <strong>{{ total_accs }}</strong>
              </div>
            </div>
            <div class="col-md-8 mt-1">
              <div class="bg-white">
                <canvas height="300" id="orderChart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
      <div class="p-1 row mt-1">
        <div
          class="p-1 col-md-6"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <b-overlay :show="loadCancel">
            <div class="row">
              <div class="col-md-6">
                <h3 class="mb-0 mt-1">Total Products Cancel</h3>
              </div>
              <div class="col-md-6">
                <div
                  style="background: #9fa0a2"
                  class="text-white text-center p-1 rounded"
                >
                  <b-badge variant="secondary" class="p-1 mr-1">
                    <span>Total</span>
                  </b-badge>
                  <strong>{{ totalCancel }}</strong>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div
                  style="background: #fde6ba"
                  class="text-dark text-center p-1 rounded mt-1"
                >
                  <b-badge variant="warning" class="text-dark p-1 mr-1">
                    <span>Lenses</span>
                  </b-badge>
                  <strong>{{ product_cancel.total_lensa }}</strong>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  style="background: #ebf6fc"
                  class="text-dark text-center p-1 rounded mt-1"
                >
                  <b-badge variant="info" class="text-dark p-1 mr-1">
                    <span>Frames</span>
                  </b-badge>
                  <strong>{{ product_cancel.total_frame }}</strong>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  style="background: #eff6ef"
                  class="text-dark text-center p-1 rounded mt-1"
                >
                  <b-badge variant="success" class="text-dark p-1 mr-1">
                    <span>Accs.</span>
                  </b-badge>
                  <strong>{{ product_cancel.total_accs }}</strong>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>

        <div
          class="p-1 col-md-6"
          style="
            background-color: #eeeeee;
            border: 1px solid rgba(0, 0, 0, 0.166);
            border-radius: 5px;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.166);
          "
        >
          <b-overlay :show="loadReturn">
            <div class="row">
              <div class="col-md-6">
                <h3 class="mb-0 mt-1">Total Products Return</h3>
              </div>
              <div class="col-md-6">
                <div
                  style="background: #9fa0a2"
                  class="text-white text-center p-1 rounded"
                >
                  <b-badge variant="secondary" class="p-1 mr-1">
                    <span>Total</span>
                  </b-badge>
                  <strong>{{ totalReturn }}</strong>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div
                  style="background: #fde6ba"
                  class="text-dark text-center p-1 rounded mt-1"
                >
                  <b-badge variant="warning" class="text-dark p-1 mr-1">
                    <span>Lenses</span>
                  </b-badge>
                  <strong>{{ product_return.total_lensa }}</strong>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  style="background: #ebf6fc"
                  class="text-dark text-center p-1 rounded mt-1"
                >
                  <b-badge variant="info" class="text-dark p-1 mr-1">
                    <span>Frames</span>
                  </b-badge>
                  <strong>{{ product_return.total_frame }}</strong>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  style="background: #eff6ef"
                  class="text-dark text-center p-1 rounded mt-1"
                >
                  <b-badge variant="success" class="text-dark p-1 mr-1">
                    <span>Accs.</span>
                  </b-badge>
                  <strong>{{ product_return.total_accs }}</strong>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Chart from "chart.js";
import { BBadge, BOverlay } from "bootstrap-vue";

export default {
  props: [
    "loadOrder",
    "loadCancel",
    "loadReturn",
    "data",
    "total",
    "grafikData",
    "dataCancel",
    "totalCancel",
    "dataReturn",
    "totalReturn",
  ],
  data() {
    return {
      total_lensa: 0,
      total_frame: 0,
      total_accs: 0,
      product_cancel: {
        total_lensa: 0,
        total_frame: 0,
        total_accs: 0,
      },
      product_return: {
        total_lensa: 0,
        total_frame: 0,
        total_accs: 0,
      },
    };
  },
  components: {
    BBadge,
    BOverlay,
  },
  watch: {
    data(val) {
      this.total_lensa = val.find((res) => {
        return res.mark_category_as == "lense";
      }).total;
      this.total_frame = val.find((res) => {
        return res.mark_category_as == "frame";
      }).total;
      this.total_accs = val.find((res) => {
        return res.mark_category_as == "accessory";
      }).total;
    },
    dataCancel(val) {
      this.product_cancel.total_lensa = val.find((res) => {
        return res.mark_category_as == "lense";
      }).total;
      this.product_cancel.total_frame = val.find((res) => {
        return res.mark_category_as == "frame";
      }).total;
      this.product_cancel.total_accs = val.find((res) => {
        return res.mark_category_as == "accessory";
      }).total;
    },
    dataReturn(val) {
      this.product_return.total_lensa = val.find((res) => {
        return res.mark_category_as == "lense";
      }).total;
      this.product_return.total_frame = val.find((res) => {
        return res.mark_category_as == "frame";
      }).total;
      this.product_return.total_accs = val.find((res) => {
        return res.mark_category_as == "accessory";
      }).total;
    },
    grafikData(val) {
      this.renderOrderChart();
    },
  },
  methods: {
    renderOrderChart() {
      const ctx = document.getElementById("orderChart").getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: this.grafikData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });
    },
  },
};
</script>

<style>
canvas {
  max-height: 600px;
}
</style>
