var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h4', [_vm._v("Label Design")]), _c('b-card', [_c('b-form-group', {
    attrs: {
      "label": "Select Label Design:"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "space-around"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "exqr"
    },
    model: {
      value: _vm.selectlabel,
      callback: function ($$v) {
        _vm.selectlabel = $$v;
      },
      expression: "selectlabel"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    staticStyle: {
      "width": "75%"
    },
    attrs: {
      "src": require("./exqr.png"),
      "alt": "QR"
    }
  })])], 1)]), _c('b-form-group', {
    attrs: {
      "label": "Select Size"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "value": "33x18"
    },
    model: {
      value: _vm.selectsize,
      callback: function ($$v) {
        _vm.selectsize = $$v;
      },
      expression: "selectsize"
    }
  }, [_vm._v(" 33 x 18 (mm) ")]), _c('b-form-radio', {
    attrs: {
      "value": "a4"
    },
    model: {
      value: _vm.selectsize,
      callback: function ($$v) {
        _vm.selectsize = $$v;
      },
      expression: "selectsize"
    }
  }, [_vm._v(" A4 ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h4', [_vm._v("Select Items")]), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filter-input",
      "type": "search",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "size": "sm",
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Clear")))])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.chooseBarang($event);
      }
    }
  }, [_vm._v("Add Bulk")])], 1)], 1), _c('b-row', [_c('b-col', [_vm.selectedBarangs.length > 0 ? _c('b-table', {
    attrs: {
      "small": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.selectedBarangs,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    scopedSlots: _vm._u([{
      key: "table-colgroup",
      fn: function (_ref) {
        var fields = _ref.fields;
        return _vm._l(fields, function (field) {
          return _c('col', {
            key: field.key,
            style: {
              width: field.key == 'no' ? '20px' : ''
            }
          });
        });
      }
    }, {
      key: "cell(no)",
      fn: function (_ref2) {
        var index = _ref2.index;
        return [_vm._v(" " + _vm._s(1 + index) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.kode ? item.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.varian_item[0].no_batch))])];
      }
    }, {
      key: "cell(kategori)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.kategori ? item.kategori.kategori : "-") + " ")];
      }
    }, {
      key: "cell(no_container)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: MRT123213"
          },
          model: {
            value: item.no_container,
            callback: function ($$v) {
              _vm.$set(item, "no_container", $$v);
            },
            expression: "item.no_container"
          }
        })];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [item ? _c('span', [_vm._v(" " + _vm._s(item.varian_item[0].varian)), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.nama))])]) : _c('i', {
          staticClass: "text-danger"
        }, [_vm._v("Barang tidak ada.")])];
      }
    }, {
      key: "cell(no_seal)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Ex: GR123"
          },
          model: {
            value: item.no_seal,
            callback: function ($$v) {
              _vm.$set(item, "no_seal", $$v);
            },
            expression: "item.no_seal"
          }
        })];
      }
    }, {
      key: "cell(harga_beli)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('div', [_vm._v(_vm._s(_vm.formatRupiah(item.harga_beli)))]), item.diskon && item.diskon > 0 ? _c('strong', {
          staticClass: "text-danger"
        }, [_vm._v(" diskon " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(diskon)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.diskon)) + " ")];
      }
    }, {
      key: "cell(copies)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('b-input-group', {
          attrs: {
            "append": item.satuan ? item.satuan.satuan : '-'
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          on: {
            "change": function ($event) {
              return _vm.generatePDF();
            }
          },
          model: {
            value: item.copies,
            callback: function ($$v) {
              _vm.$set(item, "copies", $$v);
            },
            expression: "item.copies"
          }
        })], 1)];
      }
    }, {
      key: "cell(total)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(parseInt(item.harga_beli) * parseInt(item.copies))) + " ")];
      }
    }, {
      key: "cell(custom_text)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('b-input-group', {}, [_c('b-form-input', {
          attrs: {
            "placeholder": "Masukkan teks pertama"
          },
          model: {
            value: item.custom_text,
            callback: function ($$v) {
              _vm.$set(item, "custom_text", $$v);
            },
            expression: "item.custom_text"
          }
        })], 1)];
      }
    }, {
      key: "cell(kode_supplier)",
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('b-input-group', {}, [_c('v-select', {
          attrs: {
            "options": _vm.suppliers,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          on: {
            "input": function ($event) {
              return _vm.generatePDF();
            }
          },
          model: {
            value: item.kode_supplier,
            callback: function ($$v) {
              _vm.$set(item, "kode_supplier", $$v);
            },
            expression: "item.kode_supplier"
          }
        })], 1)];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref14) {
        var item = _ref14.item,
          index = _ref14.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeBarang(item, index);
            }
          }
        })];
      }
    }], null, false, 437709958)
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_vm._v(" Harap pilih produk yang akan di print Barcode/QR Code ")]), _c('barang-modal', {
    on: {
      "submit": _vm.onChooseBarang
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h4', [_vm._v("PDF Preview")]), _c('b-card', [_vm.pdfSrc ? _c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100vh",
      "border": "none"
    },
    attrs: {
      "src": _vm.pdfSrc,
      "frameborder": "0"
    }
  }) : _c('div', {
    staticClass: "alert alert-danger p-2"
  }, [_vm._v(" Harap pilih produk yang akan di print Barcode/QR Code ")])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }