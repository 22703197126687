<template>
  <main>
    <b-overlay :show="loading" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <feather-icon icon="ClockIcon" size="24" />
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
      <header v-if="myGudang">
        <b-row>
          <b-col sm="12" md="12">
            <h5>
              <strong
                >Lokasi Gudang:
                {{
                  this.myGudang.nama_gudang
                    ? this.myGudang.nama_gudang
                    : this.myGudang.data.nama_gudang
                }}
              </strong>
            </h5>
          </b-col>
        </b-row>
      </header>
      <b-card>
        <b-row>
          <b-col sm="12" md="12">
            <!-- Tujuan Gudang -->
            <b-form-group>
              <label
                ><strong
                  >Pilih lokasi 2 <span class="text-danger">(*)</span></strong
                ></label
              >
              <v-select
                :options="optbloks"
                v-model="blokasal"
                label="text"
                @input="cekData()"
              />
            </b-form-group>
            <!-- / -->
          </b-col>
          <b-col md="4" sm="12">
            <p><strong>Tanggal</strong></p>
            <p>{{ so.tanggal }}</p>
          </b-col>
          <!-- <b-col md="2" sm="12">
            <p><strong>No</strong></p>
            <p>{{ so.no }}</p>
          </b-col> -->
          <b-col md="5" sm="12">
            <p><strong>Keterangan</strong></p>
            <p>{{ so.keterangan ? so.keterangan : "-" }}</p>
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 0">
            <p><strong>Status</strong></p>
            <b-badge variant="light-danger" size="sm">Belum Selesai</b-badge>
          </b-col>
          <b-col md="3" sm="12" v-if="so.status == 1">
            <p><strong>Status</strong></p>
            <b-badge variant="light-success" size="sm">Selesai</b-badge>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="1" sm="1"> Category </b-col>
          <b-col md="3" sm="3">
            <b-form-group>
              <b-form-select
                v-model="kategori_id"
                :options="itemsKategori"
                @change="getData()"
                :disabled="!blokasal"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >-- {{ $t("All") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="1" sm="1"> Sort </b-col>
          <b-col md="2" sm="2">
            <b-form-group>
              <b-form-select
                v-model="sortBarang"
                :options="[
                  { value: 'asc', text: 'Ascending' },
                  { value: 'desc', text: 'Descending' },
                ]"
                @change="getData()"
                :disabled="!blokasal"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >-- {{ $t("None") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="1" sm="1" class=""> Lainnya </b-col>
          <b-col md="4" sm="4" class="">
            <b-form-group>
              <b-input-group>
                <!-- b-form-select sebagai bagian dari input group -->
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchOptions"
                    :options="[
                      { value: 'kelas_id', text: 'Kelas' },
                      { value: 'main_sku', text: 'SKU Ginee' },
                      { value: 'kode', text: 'Barcode' },
                    ]"
                    class="custom-select"
                    :disabled="!blokasal"
                  ></b-form-select>
                </b-input-group-prepend>

                <!-- b-form-input sebagai bagian dari input group -->
                <b-form-input
                  v-if="searchOptions !== 'kelas_id'"
                  @keydown.enter.prevent="getData()"
                  v-model="customefilter"
                  placeholder="Masukkan teks"
                  :disabled="!blokasal"
                ></b-form-input>

                <b-form-select
                  v-if="searchOptions == 'kelas_id'"
                  v-model="kelas_id"
                  :options="itemsKelas"
                  @change="getData()"
                  :disabled="!blokasal"
                >
                  <template #first>
                    <b-form-select-option :value="null"
                      >-- {{ $t("All") }} --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col md="1" sm="1"> Pre Order </b-col>
          <b-col md="3" sm="3">
            <b-form-group>
              <b-form-select
                v-model="is_pre_order"
                :options="[
                  { value: 1, text: 'Ya' },
                  { value: 0, text: 'Tidak' },
                ]"
                @change="getData()"
                :disabled="!blokasal"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >-- {{ $t("All") }} --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group
              class="mb-0"
              label-size="sm"
              :label="$t('Per page')"
              label-for="perPage"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                :disabled="!blokasal"
              />
            </b-form-group>
          </b-col>
          <b-col md="10" sm="8" class="my-1">
            <b-form-group
              :label="$t('Filter')"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Tekan enter untuk cari"
                  @keydown.enter.prevent="getData()"
                  :disabled="!blokasal"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="
                      filter = '';
                      getData();
                    "
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Input Stock Opname -->
          <b-col sm="12" md="12">
            <b-overlay :show="loadingpenyimpanan" rounded="sm">
              <template #overlay>
                <div class="text-center">
                  <feather-icon icon="ClockIcon" size="24" />
                  <p id="cancel-label">Please wait...</p>
                </div>
              </template>
              <b-card style="overflow-y: auto; max-height: 500px">
                <b-table
                  sticky-header="400px"
                  small
                  bordered
                  striped
                  responsive
                  :fields="fields"
                  :items="penyimpanan"
                  :per-page="perPage"
                >
                  <template #head(checkbox)>
                    <b-form-checkbox v-model="allChecked"></b-form-checkbox>
                  </template>
                  <template #cell(checkbox)="{ item }">
                    <!-- {{ selectedPenyimpanan }} -->
                    <b-form-checkbox
                      v-model="selectedPenyimpanan"
                      :value="item"
                    ></b-form-checkbox>
                  </template>
                  <template #cell(no)="row">
                    <div class="text-center">
                      {{ row.index + 1 }}
                    </div>
                  </template>
                  <template #cell(stok)="{ item }">
                    {{ item.stok }}
                    <!-- <b-form-input type="number" v-model="item.stok" /> -->
                  </template>
                  <template #cell(kode)="{ item }">
                    {{ item.barang ? item.barang.kode : "-" }}<br />
                    <small>{{
                      item.barang ? item.barang.no_batch : "-"
                    }}</small>
                  </template>
                  <template #cell(nama)="{ item }">
                    {{ item.barang ? item.barang.varian : "-" }}<br />
                    <small>{{
                      item.barang.kategori ? item.barang.kategori.kategori : "-"
                    }}</small>
                    /
                    <small>
                      {{ item.barang ? item.barang.nama : "-" }}
                    </small>
                    <br />
                    <small>{{ $t("Room") }} : </small>
                    <small>{{ item.blok ? item.blok.blok : "-" }}</small>
                    <small>{{ item.palet ? item.palet.palet : "-" }}</small>
                    <small>{{ item.rak ? item.rak.rak : "-" }}</small>
                    <small>{{ item.laci ? item.laci.laci : "-" }}</small>
                  </template>
                  <template #cell(hasil_stok_opname)="{ item, index }">
                    <b-form-input
                      v-model="item.hasil_stok_opname"
                      type="number"
                    />
                  </template>
                  <template #cell(so)="{ item }">
                    {{ item.id_stok_opname }}
                  </template>
                  <template #cell(selisih)="{ item }">
                    {{ item.hasil_stok_opname - item.stok }}
                    <p>
                      <small
                        class="text-info"
                        v-if="item.stok < item.hasil_stok_opname"
                        ><i>(Stok Hasil SO lebih)</i></small
                      >
                      <small
                        class="text-success"
                        v-if="item.stok == item.hasil_stok_opname"
                        ><i>(Stok sesuai)</i></small
                      >
                      <small
                        class="text-danger"
                        v-if="item.stok > item.hasil_stok_opname"
                        ><i>(Stok Hasil SO kurang)</i></small
                      >
                    </p>
                  </template>
                  <template #cell(kode2)="{ item }">
                    {{
                      item.penyimpanan.barang
                        ? item.penyimpanan.barang.kode
                        : "-"
                    }}<br />
                    <small>{{
                      item.penyimpanan.barang
                        ? item.penyimpanan.barang.no_batch
                        : "-"
                    }}</small>
                  </template>
                  <template #cell(nama2)="{ item }">
                    {{
                      item.penyimpanan.barang
                        ? item.penyimpanan.barang.varian
                        : "-"
                    }}<br />
                    <small>{{
                      item.penyimpanan.barang.kategori.kategori
                    }}</small>
                    /
                    <small>
                      {{
                        item.penyimpanan.barang
                          ? item.penyimpanan.barang.nama
                          : "-"
                      }}
                    </small>
                    <br />
                    <small>{{ $t("Room") }} : </small>
                    <small>{{
                      item.penyimpanan.blok ? item.penyimpanan.blok : "-"
                    }}</small>
                    <small>{{
                      item.penyimpanan.palet
                        ? item.penyimpanan.palet.palet
                        : "-"
                    }}</small>
                    <small>{{
                      item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-"
                    }}</small>
                    <small>{{
                      item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-"
                    }}</small>
                  </template>

                  <!-- <template #cell(actions)="row">
                  <b-link class="text-danger" @click.prevent="barangs.splice(row.index, 1)">
                    <feather-icon size="24" svgClasses="text-success" icon="XCircleIcon" />
                  </b-link>
                </template> -->
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-card>
            </b-overlay>
          </b-col>
          <!-- / -->
        </b-row>
        <footer class="d-flex justify-content-end">
          <b-button
            v-if="so.status == 0"
            variant="outline-primary"
            class="mr-2"
            @click.prevent="simpan"
            :disabled="!blokasal"
            >Simpan Halaman Ini</b-button
          >
          <b-button
            v-if="so.status == 0"
            variant="primary"
            @click.prevent="simpanSelesai"
            :disabled="!blokasal"
            >Selesaikan</b-button
          >
          <!-- <b-button variant="info" @click.prevent="resetform">Reset</b-button> -->
        </footer>
      </b-card>
      <b-card v-if="selectedPenyimpanan.length > 0">
        <b-overlay :show="loadingpenyimpanan" rounded="sm">
          <template #overlay>
            <div class="text-center">
              <feather-icon icon="ClockIcon" size="24" />
              <p id="cancel-label">Please wait...</p>
            </div>
          </template>
          <h4 class="mb-2">Item yang dipilih:</h4>
          <b-table
            sticky-header="400px"
            small
            bordered
            striped
            responsive
            :fields="fieldsSelected"
            :items="selectedPenyimpanan"
          >
            <template #cell(no)="row">
              <div class="text-center">
                {{ row.index + 1 }}
              </div>
            </template>
            <template #cell(stok)="{ item }">
              {{ item.stok }}
              <!-- <b-form-input type="number" v-model="item.stok" /> -->
            </template>
            <template #cell(kode)="{ item }">
              {{ item.barang ? item.barang.kode : "-" }}<br />
              <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
            </template>
            <template #cell(nama)="{ item }">
              {{ item.barang ? item.barang.varian : "-" }}<br />
              <small>{{
                item.barang.kategori ? item.barang.kategori.kategori : "-"
              }}</small>
              /
              <small>
                {{ item.barang ? item.barang.nama : "-" }}
              </small>
              <br />
              <small>{{ $t("Room") }} : </small>
              <small>{{ item.blok ? item.blok.blok : "-" }}</small>
              <small>{{ item.palet ? item.palet.palet : "-" }}</small>
              <small>{{ item.rak ? item.rak.rak : "-" }}</small>
              <small>{{ item.laci ? item.laci.laci : "-" }}</small>
            </template>
            <template #cell(hasil_stok_opname)="{ item, index }">
              <b-form-input v-model="item.hasil_stok_opname" type="number" />
            </template>
            <template #cell(so)="{ item }">
              {{ item.id_stok_opname }}
            </template>
            <template #cell(selisih)="{ item }">
              {{ item.hasil_stok_opname - item.stok }}
              <p>
                <small
                  class="text-info"
                  v-if="item.stok < item.hasil_stok_opname"
                  ><i>(Stok Hasil SO lebih)</i></small
                >
                <small
                  class="text-success"
                  v-if="item.stok == item.hasil_stok_opname"
                  ><i>(Stok sesuai)</i></small
                >
                <small
                  class="text-danger"
                  v-if="item.stok > item.hasil_stok_opname"
                  ><i>(Stok Hasil SO kurang)</i></small
                >
              </p>
            </template>
            <template #cell(kode2)="{ item }">
              {{ item.penyimpanan.barang ? item.penyimpanan.barang.kode : "-"
              }}<br />
              <small>{{
                item.penyimpanan.barang ? item.penyimpanan.barang.no_batch : "-"
              }}</small>
            </template>
            <template #cell(nama2)="{ item }">
              {{ item.penyimpanan.barang ? item.penyimpanan.barang.varian : "-"
              }}<br />
              <small>{{ item.penyimpanan.barang.kategori.kategori }}</small>
              /
              <small>
                {{
                  item.penyimpanan.barang ? item.penyimpanan.barang.nama : "-"
                }}
              </small>
              <br />
              <small>{{ $t("Room") }} : </small>
              <small>{{
                item.penyimpanan.blok ? item.penyimpanan.blok : "-"
              }}</small>
              <small>{{
                item.penyimpanan.palet ? item.penyimpanan.palet.palet : "-"
              }}</small>
              <small>{{
                item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-"
              }}</small>
              <small>{{
                item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-"
              }}</small>
            </template>
            <template #cell(action)="{ item, index }">
              <feather-icon
                icon="MinusCircleIcon"
                size="24"
                class="text-danger cursor-pointer"
                @click="removeSelected(item, index)"
              ></feather-icon>
            </template>
          </b-table>
          <!-- <b-pagination
            v-model="currentPageSelected"
            :total-rows="selectedPenyimpanan.length"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          /> -->
          <footer class="d-flex justify-content-end">
            <b-button
              v-if="so.status == 0"
              variant="outline-primary"
              class="mr-2"
              @click.prevent="simpanSelected"
              :disabled="!blokasal"
              >Simpan</b-button
            >
          </footer>
        </b-overlay>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="showModal"
      id="modal-simpan"
      ok-title="Tutup"
      ok-variant="secondary"
      no-close-on-backdrop
      ok-only
      centered
      size="xl"
      title="Determine Adjustments"
    >
      <div v-if="penyimpananKurang.length > 0">
        <h2>Less Adjustment</h2>
        <b-table responsive :fields="fieldsKurang" :items="adjustKurang">
          <template #cell(no)="row">
            <div class="text-center">
              {{ row.index + 1 }}
            </div>
          </template>
          <template #cell(kode)="{ item }">
            {{ item.barang ? item.barang.kode : "-" }}<br />
            <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
          </template>
          <template #cell(nama)="{ item }">
            {{ item.barang ? item.barang.varian : "-" }}<br />
            <small>{{
              item.barang.kategori ? item.barang.kategori.kategori : ""
            }}</small>
            /
            <small>{{ item.barang ? item.barang.nama : "-" }}</small>
          </template>
          <template #cell(jumlah)="{ item, index }">
            <b-form-input
              v-model="item.jumlah"
              @input="checkSelisih(item, index)"
            />
            <p>
              <small class="text-info" v-if="item.jumlah == 0"
                ><i>Jumlah Setidaknya harus 1</i></small
              >
              <small class="text-danger" v-if="item.jumlah > item.selisih"
                ><i>Jumlah maksimal {{ item.selisih }}</i></small
              >
            </p>
          </template>
          <template #cell(type)="{ item, index }">
            <b-form-select
              :options="opttype"
              v-model="item.type"
            ></b-form-select>
          </template>
          <template #cell(keterangan)="{ item, index }">
            <b-form-textarea v-model="item.keterangan"></b-form-textarea>
          </template>
        </b-table>
      </div>
      <div v-if="penyimpananLebih.length > 0">
        <h2>More Adjustment</h2>
        <b-table responsive :fields="fieldsLebih" :items="adjustLebih">
          <template #cell(no)="row">
            <div class="text-center">
              {{ row.index + 1 }}
            </div>
          </template>
          <template #cell(kode)="{ item }">
            {{ item.barang ? item.barang.kode : "-" }}<br />
            <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
          </template>
          <template #cell(nama)="{ item }">
            {{ item.barang ? item.barang.varian : "-" }}<br />
            <small>{{
              item.barang.kategori ? item.barang.kategori.kategori : "-"
            }}</small>
            /
            <small>{{ item.barang ? item.barang.nama : "-" }}</small>
          </template>
          <template #cell(jumlah)="{ item, index }">
            <b-form-input v-model="item.jumlah" readonly />
            <p>
              <small class="text-info" v-if="item.jumlah == 0"
                ><i>Jumlah Setidaknya harus 1</i></small
              >
              <small class="text-danger" v-if="item.jumlah > item.selisih"
                ><i>Jumlah maksimal {{ item.selisih }}</i></small
              >
            </p>
          </template>
          <template #cell(type)="{ item, index }">
            <b-form-input v-model="item.type" readonly />
          </template>
          <template #cell(keterangan)="{ item, index }">
            <b-form-textarea v-model="item.keterangan"></b-form-textarea>
          </template>
        </b-table>
      </div>
      <template #modal-footer>
        <section class="d-flex w-100 justify-content-end">
          <b-button
            @click.prevent="selesai"
            variant="info"
            :disabled="buttonSelesai"
          >
            Simpan
          </b-button>
        </section>
      </template>
    </b-modal>
    <b-modal
      v-model="showModalSelected"
      id="modal-simpan"
      ok-title="Tutup"
      ok-variant="secondary"
      no-close-on-backdrop
      ok-only
      centered
      size="xl"
      title="Determine Adjustments"
    >
      <div v-if="penyimpananKurang.length > 0">
        <h2>Less Adjustment</h2>
        <b-table responsive :fields="fieldsKurang" :items="adjustKurang">
          <template #cell(no)="row">
            <div class="text-center">
              {{ row.index + 1 }}
            </div>
          </template>
          <template #cell(kode)="{ item }">
            {{ item.barang ? item.barang.kode : "-" }}<br />
            <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
          </template>
          <template #cell(nama)="{ item }">
            {{ item.barang ? item.barang.varian : "-" }}<br />
            <small>{{
              item.barang.kategori ? item.barang.kategori.kategori : ""
            }}</small>
            /
            <small>{{ item.barang ? item.barang.nama : "-" }}</small>
          </template>
          <template #cell(jumlah)="{ item, index }">
            <b-form-input
              v-model="item.jumlah"
              @input="checkSelisih(item, index)"
            />
            <p>
              <small class="text-info" v-if="item.jumlah == 0"
                ><i>Jumlah Setidaknya harus 1</i></small
              >
              <small class="text-danger" v-if="item.jumlah > item.selisih"
                ><i>Jumlah maksimal {{ item.selisih }}</i></small
              >
            </p>
          </template>
          <template #cell(type)="{ item, index }">
            <b-form-select
              :options="opttype"
              v-model="item.type"
            ></b-form-select>
          </template>
          <template #cell(keterangan)="{ item, index }">
            <b-form-textarea v-model="item.keterangan"></b-form-textarea>
          </template>
        </b-table>
      </div>
      <div v-if="penyimpananLebih.length > 0">
        <h2>More Adjustment</h2>
        <b-table responsive :fields="fieldsLebih" :items="adjustLebih">
          <template #cell(no)="row">
            <div class="text-center">
              {{ row.index + 1 }}
            </div>
          </template>
          <template #cell(kode)="{ item }">
            {{ item.barang ? item.barang.kode : "-" }}<br />
            <small>{{ item.barang ? item.barang.no_batch : "-" }}</small>
          </template>
          <template #cell(nama)="{ item }">
            {{ item.barang ? item.barang.varian : "-" }}<br />
            <small>{{
              item.barang.kategori ? item.barang.kategori.kategori : "-"
            }}</small>
            /
            <small>{{ item.barang ? item.barang.nama : "-" }}</small>
          </template>
          <template #cell(jumlah)="{ item, index }">
            <b-form-input v-model="item.jumlah" readonly />
            <p>
              <small class="text-info" v-if="item.jumlah == 0"
                ><i>Jumlah Setidaknya harus 1</i></small
              >
              <small class="text-danger" v-if="item.jumlah > item.selisih"
                ><i>Jumlah maksimal {{ item.selisih }}</i></small
              >
            </p>
          </template>
          <template #cell(type)="{ item, index }">
            <b-form-input v-model="item.type" readonly />
          </template>
          <template #cell(keterangan)="{ item, index }">
            <b-form-textarea v-model="item.keterangan"></b-form-textarea>
          </template>
        </b-table>
      </div>
      <template #modal-footer>
        <section class="d-flex w-100 justify-content-end">
          <b-button
            @click.prevent="selesaiSelected"
            variant="info"
            :disabled="buttonSelesai"
          >
            Simpan
          </b-button>
        </section>
      </template>
    </b-modal>
  </main>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BCard,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BOverlay,
  BTable,
  BFormInput,
  BLink,
  BBadge,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormSelectOption,
  BInputGroupPrepend,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import penyimpanan from "../../../../../store/penyimpanan";
// import barang from "../../../../../store/barang";
export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BCard,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BOverlay,
    vSelect,
    BTable,
    BFormInput,
    BLink,
    BBadge,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormSelectOption,
    BInputGroupPrepend,
  },
  data() {
    return {
      loadingpenyimpanan: false,
      optbloks: [],
      blok: null,
      blokasal: null,
      loading: false,
      selectedBarang: null,
      barangs: [],
      penyimpanan: [],
      jumlah: 0,
      stok: 0,
      id_stok_opname: 0,
      status: 1,
      so: {
        tanggal: null,
        no: null,
        keterangan: null,
        status: null,
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      fields: [
        {
          key: "checkbox",
          label: "#",
        },
        {
          key: "no",
          label: "No",
        },
        {
          key: "kode",
          label: "Kode/SKU",
          sortable: true,
        },
        {
          key: "nama",
          label: "Product",
          sortable: true,
        },
        // {
        //   key: "blok.blok",
        //   label: "Blok",
        //   sortable: true
        // },
        // {
        //   key: "palet.palet",
        //   label: "palet",
        // },
        // {
        //   key: "rak.rak",
        //   label: "rak",
        // },
        // {
        //   key: "laci.laci",
        //   label: "laci",
        // },
        {
          key: "stok",
          label: "Stok",
        },
        {
          key: "barang.satuan.satuan",
          label: "satuan",
        },
        {
          key: "hasil_stok_opname",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        },
      ],
      showModal: false,
      showModalSelected: false,
      buttonSelesai: false,
      opttype: [
        { value: null, text: "Pilih" },
        { value: "hilang", text: "Hilang" },
        { value: "rusak", text: "Rusak" },
        { value: "marketing", text: "Marketing" },
        { value: "hadiah", text: "Hadiah" },
        { value: "retur", text: "Retur" },
      ],
      fieldsLebih: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "kode",
          label: "Kode/SKU",
          sortable: true,
        },
        {
          key: "nama",
          label: "Product",
          sortable: true,
        },
        {
          key: "jumlah",
          label: "Jumlah",
        },
        { key: "type", label: "Tipe" },
        {
          key: "keterangan",
          label: "Keterangan",
        },
      ],
      penyimpananLebih: [],
      adjustLebih: [],
      fieldsKurang: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "kode",
          label: "Kode/SKU",
          sortable: true,
        },
        {
          key: "nama",
          label: "Product",
          sortable: true,
        },
        {
          key: "jumlah",
          label: "Jumlah",
        },
        { key: "type", label: "Tipe" },
        {
          key: "keterangan",
          label: "Keterangan",
        },
      ],
      penyimpananKurang: [],
      adjustKurang: [],
      currentPage: 1,
      totalRows: 1,
      perPage: 20,
      itemsKategori: [],
      kategori_id: null,
      sortBarang: null,
      searchOptions: [],
      customefilter: null,
      kelas_id: null,
      itemsKelas: [],
      is_pre_order: null,
      pageOptions: [20, 50, 100],
      filter: null,
      allChecked: false,
      selectedPenyimpanan: [],
      fieldsSelected: [
        {
          key: "no",
          label: "No",
        },
        {
          key: "kode",
          label: "Kode/SKU",
          sortable: true,
        },
        {
          key: "nama",
          label: "Product",
          sortable: true,
        },
        {
          key: "stok",
          label: "Stok",
        },
        {
          key: "barang.satuan.satuan",
          label: "satuan",
        },
        {
          key: "hasil_stok_opname",
          label: "Hasil Stok Opname",
        },
        {
          key: "selisih",
          label: "selisih barang",
        },
        { key: "action", label: "" },
      ],
    };
  },
  computed: {
    // Computed property untuk hasil_stok_opname
    "item.hasil_stok_opname": {
      get() {
        // Mengembalikan nilai hasil_stok_opname dan menetapkan 0 jika undefined
        return this.penyimpanan.map((item) =>
          item.hasil_stok_opname === null ||
          item.hasil_stok_opname === undefined
            ? (item.hasil_stok_opname = 0)
            : item.hasil_stok_opname
        );
      },
      set(value) {
        // Set nilai hasil_stok_opname pada item terkait
        value.forEach((val, index) => {
          this.penyimpanan[index].hasil_stok_opname = val;
        });
      },
    },
  },
  mounted() {
    this.getBloks();
    // this.getData()
    this.getDataSo();
    if (this.$route.params.id) {
      this.id_stok_opname = this.$route.params.id;
    }
  },
  methods: {
    cekData() {
      // if (this.so.rincian.length > 0) {
      //   this.getDataSoRincian();
      // } else {
      this.getKategori();
      this.getKelas();
      this.getData();
      // }
    },
    resetform() {
      this.item.jumlah = 0;
      this.jumlah = 0;
    },
    checkSelisih(item, index) {
      if (item.jumlah) {
        let penyimpanan = this.penyimpananKurang.find(
          (res) => res.id === item.penyimpanan_id
        );

        if (item.jumlah == 0) {
          this.buttonSelesai = true;
          return false;
        }
        if (item.jumlah > item.selisih) {
          this.buttonSelesai = true;
          return false;
        }

        if (item.jumlah < item.selisih) {
          this.buttonSelesai = false;
          if (penyimpanan && Array.isArray(penyimpanan.adjust)) {
            let adjust = {
              id_gudang: penyimpanan.gudang_id,
              penyimpanan_id: penyimpanan.id,
              type: "",
              tanggal: this.so.tanggal,
              jumlah: item.selisih - item.jumlah,
              keterangan: "",
              selisih: item.selisih - item.jumlah,
              adjustPlus: item.adjustPlus + 1,
              barang: penyimpanan.barang,
            };

            let adjustm = [];
            penyimpanan.adjust.map((res, index) => {
              if (index <= item.adjustPlus) {
                adjustm.push(res);
              }
            });
            this.adjustKurang = [];
            penyimpanan.adjust = [];
            penyimpanan.adjust = adjustm;
            penyimpanan.adjust.push(adjust);
            this.penyimpananKurang.forEach((res) => {
              if (res.adjust && Array.isArray(res.adjust)) {
                res.adjust.forEach((response) => {
                  this.adjustKurang.push(response);
                });
              }
            });
          }
        } else {
          this.buttonSelesai = false;
          let penyimpanan = this.penyimpananKurang.find(
            (res) => res.id === item.penyimpanan_id
          );
          let adjust = [];
          penyimpanan.adjust.map((res, index) => {
            if (index <= item.adjustPlus) {
              adjust.push(res);
            }
          });
          penyimpanan.adjust = [];
          penyimpanan.adjust = adjust;
          console.log("adjust", adjust);
          console.log("penyimpanan.adjust", penyimpanan.adjust);
          this.adjustKurang = [];
          this.penyimpananKurang.forEach((res) => {
            if (res.adjust && Array.isArray(res.adjust)) {
              res.adjust.forEach((response) => {
                this.adjustKurang.push(response);
              });
            }
          });
        }
      } else {
        this.buttonSelesai = true;
        let penyimpanan = this.penyimpananKurang.find(
          (res) => res.id === item.penyimpanan_id
        );
        let adjust = [];
        penyimpanan.adjust.map((res, index) => {
          if (index <= item.adjustPlus) {
            adjust.push(res);
          }
        });
        penyimpanan.adjust = [];
        penyimpanan.adjust = adjust;
        console.log("peyimpanan", penyimpanan);
        this.adjustKurang = [];
        this.penyimpananKurang.forEach((res) => {
          if (res.adjust && Array.isArray(res.adjust)) {
            res.adjust.forEach((response) => {
              this.adjustKurang.push(response);
            });
          }
        });
      }
    },
    changePenyimpananKurang() {
      this.penyimpananKurang = [];
      this.penyimpananKurang = this.penyimpanan.filter(
        (item) => item.stok > item.hasil_stok_opname
      );
      this.penyimpananKurang.map((item) => {
        item.selisih = item.stok - item.hasil_stok_opname;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustKurang = [];
      this.penyimpananKurang.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustKurang.push(response);
          });
        }
      });
    },
    async simpan() {
      this.penyimpananLebih = [];
      this.penyimpananLebih = this.penyimpanan.filter(
        (item) => item.stok < item.hasil_stok_opname
      );
      this.penyimpananLebih.map((item) => {
        item.selisih = item.hasil_stok_opname - item.stok;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "adjustment",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustLebih = [];
      this.penyimpananLebih.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustLebih.push(response);
          });
        }
      });

      this.penyimpananKurang = [];
      this.penyimpananKurang = this.penyimpanan.filter(
        (item) => item.stok > item.hasil_stok_opname
      );
      this.penyimpananKurang.map((item) => {
        item.selisih = item.stok - item.hasil_stok_opname;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustKurang = [];
      this.penyimpananKurang.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustKurang.push(response);
          });
        }
      });

      if (
        this.penyimpananKurang.length == 0 &&
        this.penyimpananLebih.length == 0
      ) {
        this.save();
        this.getData();
      } else {
        this.showModal = true;
      }
    },
    async simpanSelesai() {
      this.penyimpananLebih = [];
      this.penyimpananLebih = this.penyimpanan.filter(
        (item) => item.stok < item.hasil_stok_opname
      );
      this.penyimpananLebih.map((item) => {
        item.selisih = item.hasil_stok_opname - item.stok;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "adjustment",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustLebih = [];
      this.penyimpananLebih.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustLebih.push(response);
          });
        }
      });

      this.penyimpananKurang = [];
      this.penyimpananKurang = this.penyimpanan.filter(
        (item) => item.stok > item.hasil_stok_opname
      );
      this.penyimpananKurang.map((item) => {
        item.selisih = item.stok - item.hasil_stok_opname;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustKurang = [];
      this.penyimpananKurang.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustKurang.push(response);
          });
        }
      });

      if (
        this.penyimpananKurang.length == 0 &&
        this.penyimpananLebih.length == 0
      ) {
        this.saveSelesai();
      } else {
        this.showModal = true;
      }
    },
    async simpanSelected() {
      this.penyimpananLebih = [];
      this.penyimpananLebih = this.selectedPenyimpanan.filter(
        (item) => item.stok < item.hasil_stok_opname
      );
      this.penyimpananLebih.map((item) => {
        item.selisih = item.hasil_stok_opname - item.stok;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "adjustment",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustLebih = [];
      this.penyimpananLebih.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustLebih.push(response);
          });
        }
      });

      this.penyimpananKurang = [];
      this.penyimpananKurang = this.selectedPenyimpanan.filter(
        (item) => item.stok > item.hasil_stok_opname
      );
      this.penyimpananKurang.map((item) => {
        item.selisih = item.stok - item.hasil_stok_opname;
        item.adjust = [
          {
            id_gudang: item.gudang_id,
            penyimpanan_id: item.id,
            type: "",
            tanggal: this.so.tanggal,
            jumlah: item.selisih,
            keterangan: "",
            selisih: item.selisih,
            adjustPlus: 0,
            barang: item.barang,
          },
        ];
      });
      this.adjustKurang = [];
      this.penyimpananKurang.forEach((res) => {
        if (res.adjust && Array.isArray(res.adjust)) {
          res.adjust.forEach((response) => {
            this.adjustKurang.push(response);
          });
        }
      });

      if (
        this.penyimpananKurang.length == 0 &&
        this.penyimpananLebih.length == 0
      ) {
        this.saveSelected();
        this.getData();
      } else {
        this.showModalSelected = true;
      }
    },
    async save() {
      if (this.id) {
        this.id = this.id;
      }
      let payloads = [];
      this.penyimpanan.map((item) => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          penyimpanan_id: item.id,
          stok_in: item.jumlah,
          stok_real: item.hasil_stok_opname ? item.hasil_stok_opname : 0,
        });
      });
      let payload1 = {
        status: 1,
        gudang_id: this.myGudang.id ?? this.myGudang.data.id,
        karyawan_id: this.userData.karyawan.id,
      };
      if (this.$route.params.id) {
        payload1.id = this.$route.params.id;
      }
      try {
        this.loading = true;
        await this.$store.dispatch("tokostokopname/saveSo", payloads);

        this.loading = false;

        this.displaySuccess({
          message: "Stok Opname berhasil disimpan!",
        });
        this.getData();
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async saveSelesai() {
      if (this.id) {
        this.id = this.id;
      }
      let payloads = [];
      this.penyimpanan.map((item) => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          penyimpanan_id: item.id,
          stok_in: item.jumlah,
          stok_real: item.hasil_stok_opname ? item.hasil_stok_opname : 0,
        });
      });
      let payload1 = {
        status: 1,
        gudang_id: this.myGudang.id ?? this.myGudang.data.id,
        karyawan_id: this.userData.karyawan.id,
      };
      if (this.$route.params.id) {
        payload1.id = this.$route.params.id;
      }
      try {
        this.loading = true;

        await this.$store.dispatch("tokostokopname/saveSo", payloads);

        await this.$store.dispatch("tokostokopname/save", [payload1]);
        this.loading = false;

        this.displaySuccess({
          message: "Stok Opname berhasil disimpan!",
        });
        this.getData();
        setTimeout(() => {
          this.loading = false;
          this.$router.push({
            name: "stock-opname-rincian",
            params: { id: this.$route.params.id },
          });
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    async saveSelected() {
      if (this.id) {
        this.id = this.id;
      }
      let payloads = [];
      this.selectedPenyimpanan.map((item) => {
        payloads.push({
          stok_opname_id: this.$route.params.id,
          penyimpanan_id: item.id,
          stok_in: item.jumlah,
          stok_real: item.hasil_stok_opname ? item.hasil_stok_opname : 0,
        });
      });
      let payload1 = {
        status: 1,
        gudang_id: this.myGudang.id ?? this.myGudang.data.id,
        karyawan_id: this.userData.karyawan.id,
      };
      if (this.$route.params.id) {
        payload1.id = this.$route.params.id;
      }
      try {
        this.loading = true;
        await this.$store.dispatch("tokostokopname/saveSo", payloads);

        this.loading = false;

        this.displaySuccess({
          message: "Stok Opname berhasil disimpan!",
        });
        this.getData();
        this.selectedPenyimpanan = [];
      } catch (e) {
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },
    selesai() {
      this.$swal({
        title: "Anda yakin?",
        text: `Data adjustment ini akan disimpan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = [];
          this.adjustLebih.map((item) => {
            payload.push(item);
          });
          this.adjustKurang.map((item) => {
            payload.push(item);
          });
          this.loading = true;
          this.$store
            .dispatch("disposal/save", payload)
            .then(() => {
              this.loading = false;
              this.displaySuccess({
                message: "Disposal berhasil",
              });
              this.showModal = false;
              this.save();
            })
            .catch((e) => {
              this.loading = false;
              this.showModal = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    selesaiSelected() {
      this.$swal({
        title: "Anda yakin?",
        text: `Data adjustment ini akan disimpan`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let payload = [];
          this.adjustLebih.map((item) => {
            payload.push(item);
          });
          this.adjustKurang.map((item) => {
            payload.push(item);
          });
          this.loading = true;
          this.$store
            .dispatch("disposal/save", payload)
            .then(() => {
              this.loading = false;
              this.displaySuccess({
                message: "Disposal berhasil",
              });
              this.showModalSelected = false;
              this.saveSelected();
            })
            .catch((e) => {
              this.loading = false;
              this.showModalSelected = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    async getData() {
      console.log("this.selectedPenyimpanan", this.selectedPenyimpanan);
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        stok_opname_id: this.$route.params.id,
        start: currentPage,
        length: this.perPage,
        kategori_id: this.kategori_id ? this.kategori_id : null,
        sort: this.sortBarang ? this.sortBarang : null,
        is_pre_order: this.is_pre_order,
        blok_id: this.cariblok ? this.cariblok : null,
        search: this.filter != null ? this.filter : null,
      };
      if (this.searchOptions == "kelas_id") {
        params.kelas_id = this.kelas_id ? this.kelas_id : null;
      }
      if (this.searchOptions == "main_sku") {
        params.main_sku = this.customefilter ? this.customefilter : null;
      }
      if (this.searchOptions == "kode") {
        params.kode = this.customefilter ? this.customefilter : null;
        params.no_batch = this.customefilter ? this.customefilter : null;
      }
      if (this.myGudang) params.gudang_id = this.myGudang.id;
      if (this.myGudang) params.blok_id = this.blokasal.value;
      // params.length = 10
      this.loadingpenyimpanan = true;
      this.$store
        .dispatch("tokopenyimpanan/getData", params)
        .then((response) => {
          this.penyimpanan = response;
          this.penyimpanan.map((item) => {
            item.jumlahso = item.jumlah;
            item.stok = item.jumlah;
            if (this.selectedPenyimpanan.length > 0) {
              let matchHasil = this.selectedPenyimpanan.find(
                (res) => res.id == item.id
              );
              if (matchHasil) {
                item.hasil_stok_opname = matchHasil.hasil_stok_opname;
                item.selisih = matchHasil.selisih;
                item.adjust = matchHasil.adjust;
              }
            }
          });
          this.penyimpanan.sort((a, b) => b.stok - a.stok);
          // this.penyimpanan.forEach(item => {
          //   if (item.hasil_stok_opname === null || item.hasil_stok_opname === undefined) {
          //     item.hasil_stok_opname = 0;
          //   }
          // });
          this.totalRows = this.$store.state.tokopenyimpanan.totals;
          console.log("this.penyimpanan", this.penyimpanan);
          this.loadingpenyimpanan = false;
        })
        .catch((e) => {
          this.loadingpenyimpanan = false;
          this.displayError(e);
          return false;
        });
      // const stocks = await this.$store.dispatch("tokopenyimpanan/getData", params)
      // stocks.map(stock => {
      //   stock.jumlah = 0
      // })
      // this.penyimpanan = JSON.parse(JSON.stringify(stocks))
      // this.totalRows = JSON.parse(JSON.stringify(stocks)).length
    },
    async getDataSoRincian() {
      // const params = {}
      // if (this.myGudang) params.gudang_id = this.myGudang.data.id
      // if (this.myGudang) params.blok_id = this.blokasal.value
      // paramsid_stok_opname: this.$route.params.id
      // params.length = 10
      this.loadingpenyimpanan = true;
      // if (this.user.level.id == 0) {
      this.$store
        .dispatch("tokostokopname/getDataSo", {
          id_stok_opname: this.$route.params.id,
        })
        .then((response) => {
          // console.log('cek', response)
          this.penyimpanan = response;
          this.penyimpanan.map((item) => {
            item.jumlahso = item.stok_real;
            item.stok = item.stok_in;
          });
          this.penyimpanan.sort((a, b) => b.stok - a.stok);
          this.fields = [
            {
              key: "no",
              label: "No",
            },
            {
              key: "kode2",
              label: "Kode/SKU",
              sortable: true,
            },
            {
              key: "nama2",
              label: "Product",
              sortable: true,
            },
            {
              key: "stok_in",
              label: "Stok",
            },
            // {
            //   key: "penyimpanan.barang.satuan.satuan",
            //   label: "satuan",
            // },
            {
              key: "jumlahso",
              label: "Hasil Stok Opname Sebelumnya",
            },
            {
              key: "hasil_stok_opname",
              label: "Hasil Stok Opname",
            },
            {
              key: "selisih",
              label: "selisih barang",
            },
          ];
          this.loadingpenyimpanan = false;
          // this.items = this.$store.state.stokopname.datas;
          // this.totalRows = this.items.length;
        })
        .catch((e) => {
          this.loadingpenyimpanan = false;
          this.displayError(e);
          return false;
        });
      // }
    },
    async getDataSo() {
      const so = await this.$store.dispatch(
        "tokostokopname/getDataById",
        this.$route.params.id
      );
      this.so = so;
    },
    async getBloks(item) {
      const bloks = await this.$store.dispatch("tokoblok/getData", {
        gudang_id: item,
      });
      bloks.map((gudang) => {
        // this.otherGudangs = response
        this.optbloks.push({
          text: gudang.blok,
          value: gudang.id,
        });
        // this.blok = this.optbloks

        // if (gudang.id != this.myGudang.id) {
        // this.otherGudangs.push({
        //   value: gudang.id,
        //   text: gudang.nama_gudang,
        // });
        // }
      });
    },
    getKategori() {
      this.loadingkategori = true;
      this.$store
        .dispatch("kategori/getData")
        .then(() => {
          let items = JSON.parse(
            JSON.stringify(this.$store.state.kategori.datas)
          );
          // let items_total = this.$store.state.kategori.totals;
          this.itemsKategori = items;
          this.itemsKategori.map((item) => {
            item.text = item.kategori;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKategori)
          this.loadingkategori = false;
          // this.items = this.$store.state.kategori.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    getKelas() {
      this.loadingkelas = true;
      this.$store
        .dispatch("kelas/getData")
        .then(() => {
          let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
          // let items_total = this.$store.state.kelas.totals;
          this.itemsKelas = items;
          this.itemsKelas.map((item) => {
            item.text = item.kelas;
            item.value = item.id;
          });
          // console.log('KATT', this.itemsKelas)
          this.loadingkelas = false;
          // this.items = this.$store.state.kelas.datas
          // this.totalRows = this.items.length;
        })

        .catch((e) => {
          this.loadingkategori = false;
          this.displayError(e);
          return false;
        });
    },
    removeSelected(item, index) {
      this.selectedPenyimpanan.splice(index, 1);
    },
  },
  watch: {
    selectedBarang(barang) {
      if (barang) {
        this.barangs.push({
          id: barang.value,
          name: barang.text,
          jumlah: 0,
          isFinalStock: false,
        });
      }
    },
    currentPage(val) {
      if (val) {
        // this.simpan();
        this.getData();
        // this.getData();
      }
    },
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    allChecked(val) {
      if (val) {
        this.selectedPenyimpanan = this.penyimpanan;
      } else {
        this.selectedPenyimpanan = [];
      }
    },
  },
};
</script>
