import service from "@/services";

// Module Vuex kas
export default {
  namespaced: true,
  state: {
    datas: [],
    currentKas: null,
    kasOptions: [],
  },
  mutations: {
    SET_DATA_STOK(state, data) {
      state.datasstok = data;
    },
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_DATA_TOTALS(state, data) {
      state.totals = data;
    },
    SET_CURRENT_KAS(state, data) {
      state.currentKas = data;
    },
    SET_KAS_OPTION(state, data) {
      state.kasOptions = data;
    },
  },
  actions: {
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/order/ginee-order-list",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        commit("SET_DATA", result);
        // commit("SET_DATA_TOTALS", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getChannel({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/channel-list",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getLogistic({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/logistic-list",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getShip({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/shipping-params",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getShop({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/shop-list",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getStatusOrder({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/order-status-list",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async sync({}, payload = {}) {
      try {
        const config = {
          url: "/sync/ginee-orders",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async ceksync({ commit }, params = {}) {
      try {
        const config = {
          url: "/sync/tracking-progress",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async print({}, payload = {}) {
      try {
        const config = {
          url: "/order/print-ginee-document",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // async printinv({}, params) {
    //   try {
    async printinv({}, id) {
      try {
        const config = {
          // url: `/affiliator/member/${id}`,
          url: `/view-invoice/${id}`,
          method: "get",
          // url: "/view-invoice/",
          // baseURL: "https://api-heykama.notive.my.id/v2",
          // method: "post",
          // params,
          // data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async printinvv2({}, params = {}) {
      try {
        const config = {
          // url: `/affiliator/member/${id}`,
          url: `/download-invoice`,
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          // url: "/view-invoice/",
          // baseURL: "https://api-heykama.notive.my.id/v2",
          // method: "post",
          params,
          // data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async proxyPrint({ commit }, params = {}) {
      try {
        const config = {
          url: "/proxy-pdf",
          baseURL: "https://api-heykama.notive.my.id/v1",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
          responseType: "arraybuffer",
        };
        const response = await service.sendRequest(config);

        const result = response.data;
        const result_total = response.data.total;
        commit("SET_DATA", result);
        // commit("SET_DATA_TOTALS", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDatastokginee({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/get-stok-barang",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        commit("SET_DATA_STOK", result);
        // commit("SET_DATA_TOTALS", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async getType({ commit }, params = {}) {
      try {
        const config = {
          url: "/ginee/product-type-list",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        const result_total = response.data.total;
        // commit('SET_DATA', result)

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async exportExcel({ commit }, params = {}) {
      try {
        const config = {
          url: "/export/excel/transaksi-ginee",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async exportPdf({ commit }, params = {}) {
      try {
        const config = {
          url: "/export/pdf/transaksi-ginee",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);
        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async updatestokginee({}, payload = {}) {
      try {
        const config = {
          url: "/ginee/update-stok-barang",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getinfo({ commit }, params = {}) {
      try {
        const config = {
          url: "/kartu-resep/get-info",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response;
        // const result_total = response.data.total;
        // commit("SET_DATA_STOK", result);
        // commit("SET_DATA_TOTALS", result_total);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/kas/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async save({}, payload = {}) {
      try {
        const config = {
          url: "/input/kas",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async mutasiKas({}, payload) {
      try {
        const config = {
          url: "/kas-mutasi",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveJurnalKas({}, payload = {}) {
      try {
        const config = {
          url: "/input/jurnal",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async saveShippingTime({}, payload = {}) {
      try {
        const config = {
          url: "/ginee/set-shipping-time",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async syncorder({}, payload = {}) {
      try {
        const config = {
          url: "/ginee/sync-per-order",
          baseURL: "https://api-heykama.notive.my.id/v2",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
