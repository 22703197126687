<template>
  <main>
    <b-overlay :show="loading">
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            {{pb ? pb.no_invoice : '-'}} 
            <!-- <small class="text-muted">{{ pb.selesai == 0 ? 'Belum ditempatkan' : 'Selesai' }}</small> -->
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col sm="12" md="12" lg="6" xl="6">
          <dl class="row">
            <dt class="col-sm-4">
              Tanggal Invoice
            </dt>
            <dd class="col-sm-8">
              {{ pb ? humanDate(pb.tanggal_invoice) : '-' }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Tanggal Penerimaan
            </dt>
            <dd class="col-sm-8">
              {{ pb ? humanDate(pb.tanggal_penerimaan) : '-'  }}
            </dd>
          </dl>

            </b-col>
            <b-col sm="12" md="12" lg="6" xl="6">
          <dl class="row">
            <dt class="col-sm-4">
              Supplier
            </dt>
            <dd class="col-sm-8">
              {{ pb ? pb.supplier_id : '-' }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4 text-truncate">
              Keterangan
            </dt>
            <dd class="col-sm-8">
              {{ pb ? pb.keterangan : '-' }}
            </dd>
          </dl>
              </b-col>
          </b-row>
          <!-- <dl class="row">
              <dt />
              <dd class="col-sm-9 ml-auto">
                Supplier
              </dd>
            </dl> -->
        </b-card-body>
      </b-card>

      <form-wizard color="#ff9801" :title="null" :subtitle="null" :finish-button-text="$t('Simpan')"
        back-button-text="Sebelumnya" next-button-text="Selanjutnya" @on-complete="formSubmitted">
        <!-- data no surat -->
        <!-- / -->

        <!-- List barang -->
        <tab-content title="Daftar Barang">
          <validation-observer ref="formBarang">
            <div class="w-100 w-full">
              <!-- Form tambah -->
              <header class="mb-2">
                <form>
                  <b-row>
                    <b-col sm="12" md="12" lg="12">
                      <b-card border-variant="primary" title="Barang" bg-variant="transparent" class="shadow-none">
                        <b-row>
                          <!-- <b-col sm="12" md="6" lg="4">
                            <b-form-group>
                              <label for="{{$t("Choose Items")}}">{{$t("Choose Items")}}</label>
                              <b-button block @click="listbarang()">
                                {{$t("Choose Items")}}
                              </b-button> -->
                          <!-- <validation-provider
                          #default="{ errors }"
                          name="barang"
                          rules="required"
                        >
                          <v-select
                            v-model="formBarang.barang_id"
                            :reduce="(option) => option.value"
                            :options="barangOptions"
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider> -->
                          <!-- </b-form-group>
                          </b-col> -->
                          <b-col sm="12" md="12" lg="12">
                            <b-form-group>
                              <label for="Cari Barang">Cari Barang</label>
                              <b-input-group>
                                <b-form-input v-model="caribarang" placeholder="Tekan enter untuk cari"
                                  @keydown.enter.prevent="getDataBarangs()"></b-form-input>
                                <!-- <b-form-input placeholder="Button on right" /> -->
                                <b-input-group-append>
                                  <b-button variant="outline-primary" @click="getDataBarangs()">
                                    Cari
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col sm="12" md="12" lg="12">
                            <b-table v-if="loaded" striped small hover responsive :per-page="perPageBarangs"
                              :items="dataBarangs" :fields="fieldsBarangs" :sort-by.sync="sortByBarangs"
                              :sort-desc.sync="sortDescBarangs" :sort-direction="sortDirectionBarangs"
                              :filter-included-fields="filterOnBarangs" @filtered="onFilteredBarangs">
                              <template #cell(penyimpanan)="data">
                                <!-- <b-form-checkbox @change="selectAll" v-model="selected" /> -->
                                <b-form-checkbox :value="data.item" v-model="pilihbarang" />
                              </template>
                              <template #cell(no)="row">
                                <strong class="text-center">
                                  {{ row.index + 1 }}
                                </strong>
                              </template>
                              <template #cell(kode)="{ item }">
                                {{ item.kode ? item.kode : '-' }}
                              </template>
                              <template #cell(harga_dasar)="{ item }">
                                {{ item.harga_dasar ? formatRupiah(item.harga_dasar) : '-' }}
                              </template>
                              <template #cell(satuan)="{ item }">
                                {{ item.satuan ? item.satuan.satuan : '-' }}
                              </template>
                            </b-table>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <!-- <b-col sm="12" md="6" lg="4">
                      <b-form-group label="{{$t("Choose Items")}}">
                        <validation-provider
                          #default="{ errors }"
                          name="barang"
                          rules="required"
                        >
                          <v-select
                            v-model="formBarang.barang_id"
                            :reduce="(option) => option.value"
                            :options="barangOptions"
                            label="text"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col> -->
                    <b-col sm="12" md="6" lg="2">
                      <b-form-group label="No. Batch">
                        <b-form-input v-model="formBarang.no_container"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="2">
                      <b-form-group label="Harga Beli">
                        <b-form-input :value="pilihbarang ? pilihbarang.harga_dasar : 0" @keyup="formBarang.harga = formatRupiah(formBarang.harga)" v-model="formBarang.harga" type="number"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="2" v-if="konvs.length > 0">
                      <b-form-group label="Jumlah">
                        <validation-provider #default="{ errors }" name="nilai" rules="required|positive">
                          <b-form-input @keyup="formBarang.nilai = formatRupiah(formBarang.nilai)" v-model="formBarang.nilai" type="number"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3" v-if="konvs.length > 0">
                      <b-form-group label="Pilih Satuan Konversi">
                        <validation-provider #default="{ errors }" name="jumlah" rules="">
                          <v-select v-model="formBarang.id_satuan" :reduce="(option) => option.value" :options="konvs"
                            label="text" />
                          <!-- <b-form-input
                              v-model="formBarang.jumlah"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                            /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3" v-if="konvs.length > 0">
                      <b-form-group label="Jumlah Hasil Konversi">
                        <validation-provider #default="{ errors }" name="jumlah" rules="required|positive">
                          <b-input-group>
                            <b-form-input v-model="formBarang.jumlah" @keyup="formBarang.jumlah = formatRupiah(formBarang.jumlah)" type="number"
                              :state="errors.length > 0 ? false : null" />
                            <!-- <b-input-group-append>
                              <b-button variant="outline-primary">
                                {{ idb.satuan.satuan }}
                              </b-button>
                            </b-input-group-append> -->
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="2" v-if="konvs.length == 0 || null">
                      <b-form-group label="Jumlah">
                        <validation-provider #default="{ errors }" name="jumlah" rules="required|positive">
                          <b-input-group>
                            <b-form-input v-model="formBarang.jumlah" type="number"
                              :state="errors.length > 0 ? false : null" />
                            <!-- <b-input-group-append>
                              <b-button variant="outline-primary">
                                {{ idb.satuan.satuan }}
                              </b-button>
                            </b-input-group-append> -->
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col sm="12" md="6" lg="3">
                      <b-form-group label="Satuan Terkecil" for="satuan">
                        <!-- <validation-provider
                          #default="{ errors }"
                          name="satuan"
                        > -->
                        <!-- <b-button
                            v-model="idb.satuan.satuan"
                            disabled
                            id="satuan"
                            variant="outline-primary"
                          > -->
                        {{ this.pilihbarang ? this.pilihbarang.satuan.satuan : "-" }}
                        <!-- </b-button> -->
                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        <!-- </validation-provider> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-button @click.prevent="addBarang"> Add </b-button>
                  <!-- {{ this.pilihbarang }} -->
                </form>
              </header>
              <!-- / -->
              <hr />
        <b-col sm="12" md="12" xl="12" class="d-flex justify-content-end">
          <h2 class="text-primary text-right">
            Total: <strong>Rp {{ formatRupiah(sum) }}</strong>
          </h2>
        </b-col>
              

              <!-- List added barang -->
              <footer>
                <b-table striped hover responsive :per-page="300" :current-page="1" :fields="tableFields"
                  :items="selectedBarangs" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                  @filtered="onFiltered">
                  <template #cell(no)="row">
                    <strong class="text-center">
                      {{ row.index + 1 }}
                    </strong>
                  </template>
                  <template #cell(total)="{ item }">
                      {{ formatRupiah(item.total) }}
                  </template>
                  <template #cell(harga_dasar)="{ item }">
                      {{ item.harga_dasar != null ? formatRupiah(item.harga_dasar) : 0 }}
                  </template>
                  <template #cell(jumlah)="{ item }">
                      {{ formatRupiah(item.jumlah) }}
                  </template>
                  <template #cell(harga)="{ item }">
                      {{ formatRupiah(item.harga) }}
                  </template>
                  <template #cell(actions)="row">
                    <b-button title="'Urungkan'" size="sm" rounded class="mr-1" variant="danger"
                      @click.prevent="removeBarang(row)">
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </template>
                </b-table>
              </footer>
              <!-- / -->
            </div>
          </validation-observer>
        </tab-content>
        <!-- / -->
      </form-wizard>
    </b-overlay>
  </main>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BFormRadio,
  BFormCheckbox,
  BFormCheckboxGroup,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCol,
  BFormSelect,
  BCard,
  BCardTitle,
  BCardText,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BOverlay,
  BTooltip,
  BButton,
  BTable,
  BFormTextarea,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("positive", (value) => {
  return value > 0;
});
export default {
  components: {
    BBadge,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationObserver,
    ValidationProvider,
    FormWizard,
    TabContent,
    BFormSelect,
    BRow,
    BOverlay,
    BTable,
    BCard,
    BCardTitle,
    BCardText,
    BCardHeader,
    BCardBody,
    BButton,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      idb: {},
      loading: false,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      selectedBarangs: [],
      // satuan: null,
      form: {
        // form tab content 1
        // gudang_id: 1, // sementara
        gudang_id: null,
        no_invoice: null,
        jenis: 0,
        tanggal_invoice: null,
        tanggal_penerimaan: null,
        keterangan: null,
      },
      formBarang: {
        penerimaan_id: null,
        barang_id: null,
        no_container: null,
        no_seal: null,
        harga: 1,
        jumlah: 0,
        id_satuan: 0,
        hasil: 0,
      },
      options: [
        { text: 'Otomatis', value: '1', disabled: false },
        { text: 'Manual', value: '0', disabled: false },
      ],
      konvs: [],
      tableFields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "harga_dasar", label: "Harga Dasar", sortable: true },
        { key: "harga", label: "Harga Beli", sortable: true },
        { key: "jumlah", label: "Kuantitas", sortable: true },
        { key: "satuan", label: "Satuan", sortable: true },
        { key: "total", label: "total", sortable: true },
        { key: "no_container", label: "No. Batch", sortable: true },
        { key: "actions", label: "#" },
      ],
      id: null,
      barangOptions: [],
      dataGudang: [],
      poOptions: [
        { value: 1, text: "725/SPJDD12" },
        { value: 2, text: "725/ADWDA12" },
      ],
      satuanOptions: [
        { text: "TON", value: 1 },
        { text: "GRAM", value: 2 },
      ],
      // sum: null,
      //Barangs
      selected: null,
      caribarang: null,
      pilihbarang: null,
      pageOptionsBarangs: [10, 20, 50],
      sortByBarangs: null,
      sortDescBarangs: false,
      sortDirectionBarangs: "asc",
      filterBarangs: null,
      filterOnBarangs: [],
      perPageBarangs: 10,
      totalRowsBarangs: 1,
      currentPageBarangs: 1,
      loaded: true,
      fieldsBarangs: [
        { key: "penyimpanan", label: "Pilih" },
        // { key: "penyimpanan", label: "Pilih Penyimpanan" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        { key: "harga_dasar", label: "Harga dasar", sortable: true },
        { key: "satuan", label: "Satuan" },
        // { key: "action", label: "#" },
      ],
      pb: null,
      // fieldsPenyimpananBarangs: [
      //   { key: "penyimpanan", label: "Pilih" },
      //   { key: "no", label: "No", sortable: true },
      //   { key: "kode", label: "Kode" },
      //   { key: "nama", label: "Nama Barang" },
      //   { key: "blok", label: "blok" },
      //   { key: "palet", label: "palet" },
      //   { key: "rak", label: "rak" },
      //   { key: "laci", label: "laci" },
      //   { key: "stok", label: "stok" },
      //   { key: "satuan", label: "Satuan" },
      // ],
      dataBarangs: [],
      loading: false,
      params: {
        order: "desc",
        // start: 0,
        // length: 10,
      },
      items: [],
    };
  },
  computed: {
    sum() {
      if (!this.selectedBarangs || this.selectedBarangs.length < 1) {
        return 0;
      }

      return this.selectedBarangs.reduce((total, item) => (total += parseInt(item.total)), 0);
    },
  },
  watch: {
    perPageBarangs(value) {
      if (value) {
        this.getDataBarangs();
      }
    },
    filterBarangs(keyword) {
      if (keyword.length > 2 || keyword.length == 0) {
        this.getDataBarangs();
      }
    },
    currentPageBarangs(val) {
      if (val) {
        this.getDataBarangs();
        // this.items
      }
    },
    // "form.jenis"(value = 0){
    //   form.no_invoice
    // },
    "form.jenis"(j) {
      if (j == 1) {
        this.form.no_invoice = this.getNewNoInvoice();
      }
    },
    "pilihbarang"(barang_id) {
      this.getKonversi(barang_id);
      this.getIDBarang(barang_id);
    },
    // "formBarang.barang_id"(barang_id) {
    //   this.getKonversi(barang_id);
    //   this.getIDBarang(barang_id);
    // },
    "formBarang.nilai"(nilai) {
      let hasil = nilai * this.formBarang.id_satuan;
      this.formBarang.jumlah = hasil;
    },
    "formBarang.id_satuan"(value) {
      let hasil = value * this.formBarang.nilai;
      this.formBarang.jumlah = hasil;
    },
  },
  methods: {
    onFilteredBarangs(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsBarangs = filteredItems.length;
      this.currentPageBarangs = 1;
    },
    async getNewNoInvoice() {
      const noInvoice = await this.$store.dispatch(
        "penerimaan-barang/getNewNoInvoice"
      );
      this.form.no_invoice = noInvoice.message.format;
    },
    removeBarang(row) {
      const { item, index } = row;
      this.$store
        .dispatch("penerimaan-barang/saveBarang", [
          {
            id: item.id,
            fungsi: 2,
          },
        ])
        .then(() => this.selectedBarangs.splice(index, 1));
    },
    addBarang() {
      this.$refs.formBarang.validate().then((success) => {
        if (success) {
          // const barang = this.$store.getters["barang/getBarang"](
          //   this.formBarang.barang_id
          // );
          // if (this.pilihbarang.stok <= 0) {
          //   this.displayError({
          //     message: "Barang tidak ditemukan, harap pilih barang lain",
          //   });

          //   return false;
          // }

          this.selectedBarangs.push({
            barang_id: this.pilihbarang.id,
            nama: this.pilihbarang.nama,
            jumlah: this.unFormatRupiah(this.formBarang.jumlah),
            satuan: this.pilihbarang.satuan ? this.pilihbarang.satuan.satuan : "-",
            no_container: this.formBarang.no_container,
            harga_dasar: this.pilihbarang.harga_dasar != null ? this.unFormatRupiah(this.pilihbarang.harga_dasar) : "-",
            harga: this.unFormatRupiah(this.formBarang.harga),
            total: this.formBarang.harga * this.formBarang.jumlah,
          });

          this.resetFormBarang();
        }
      });
    },
    resetFormBarang() {
      this.formBarang = {
        penerimaan_id: null,
        barang_id: null,
        jumlah: null,
        harga: 1,
      };
    },
    async getDataBarangs() {
      this.loading = true;
      this.loaded = false;
      const perPageBrg = parseInt(this.perPageBarangs);
      const currentPageBrg = (parseInt(this.currentPageBarangs) - 1) * perPageBrg;
      const payload = {
        search: this.caribarang != null ? this.caribarang : null,
        order: "desc",
        start: currentPageBrg,
        length: this.perPageBarangs,
        // filter_by: "nama",
        // kode: this.caribarang ? this.caribarang : "",
      };
      this.$store
        .dispatch("barang/getDataV2", payload)
        .then((response) => {
          // this.items = null
          // console.log('HEMMM',this.items)

          let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          let items_total = this.$store.state.barang.totals;
          this.dataBarangs = items;
          // const barangsId = response.map(barang => barang.id)
          // get stock
          // this.getStockBarang(barangsId)
          this.totalRowsBarangs = items_total;
          // this.$refs.table.refresh()
          // console.log('HEMMM',this.items)
          // this.$root.$emit("bv::refresh::table", "table");
          this.loaded = true;
          this.loading = false;
        })
        .catch((e) => {
          this.loaded = true;
          this.loading = false;
          console.error(e);
          this.displayError(e);

          return false;
        });

      // if edit action, get penerimaan barang
      // if (this.$route.params.id) {
      //   this.getPenerimaanBarang();
      // }
    },
    async getKonversi(barang_id) {
      const konvs = await this.$store.dispatch("konversi/getData", {
        id_barang: barang_id,
      });
      this.konvs = [];
      konvs.forEach((konv) => {
        this.konvs.push({
          value: konv.nilai,
          text: konv.satuan.satuan,
        });
      });
    },
    async getIDBarang(barang_id) {
      const idbar = await this.$store.dispatch("barang/getDataById", barang_id);
      this.idb = idbar;
    },
    async getPenerimaanBarang() {
      this.$store
        .dispatch("penerimaan-barang/getDataBarang", {
          penerimaan_id: this.$route.params.id,
          order: 'desc'
        })
        .then((barangsPenerimaan) => {
          barangsPenerimaan.map((bp) => {
            const barang_id = bp.barang ? bp.barang : null;
            bp.barang_id = barang_id.id;
            // const bar = this.$store.getters["barang/getBarang"](barang_id);
            bp.nama = barang_id ? barang_id.nama : "-";
            bp.satuan = barang_id ? barang_id.satuan.satuan : "-";
            bp.harga_dasar = barang_id ? barang_id.harga_dasar : "-";
            bp.total = barang_id ? bp.harga * bp.jumlah  : "-";
          });
          this.selectedBarangs = barangsPenerimaan;
        });
    },
    async getPenerimaanBarangId() {
      this.$store
        .dispatch("penerimaan-barang/getDataById", this.$route.params.id)
        .then((barangsPenerimaan) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state['penerimaan-barang'].datas));

          this.pb = barangsPenerimaan
        });
    },
    // selectAll(checked) {
    //   this.pilihbarang = checked ? this.dataBarangs.slice() : [];
    // },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onFiltered() { },
    getDataGudang() {
      this.$store
        .dispatch("gudang/getData", {
          jenis: 1,
        })
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];

          if (this.isAdminGudang) {
            ter.map((gudang) => {
              const itsMyGudang = gudang.id == this.myGudang.id;
              if (itsMyGudang) {
                this.form.gudang_id = this.myGudang.id;
                gudangs.push({
                  value: gudang.id,
                  text: gudang.nama_gudang,
                });
              }
            });
          } else {
            ter.map((item) => {
              gudangs.push({
                value: item.id,
                text: item.nama_gudang,
              });
            });
          }
          this.dataGudang = gudangs;
        });
    },
    getGudangName(data) {
      let find = this.gudang_id.find((item) => item.id == data.gudang_id);
      return find ? find.nama_gudang : "-";
    },
    async formSubmitted() {
      if (this.selectedBarangs.length > 0) {
        // set payload for api penerimaan

        try {
          if (this.id) {
            this.form.id = this.id;
          }
          const barangPenerimaan = this.selectedBarangs.map((bp) => {
            let prop = bp.id
              ? {
                id: bp.id,
                penerimaan_id: this.$route.params.id,
                barang_id: bp.barang_id,
                harga: 1,
                jumlah: bp.jumlah,
                no_container: bp.no_container,
                harga: bp.harga,
              }
              : {
                penerimaan_id: this.$route.params.id,
                barang_id: bp.barang_id,
                harga: 1,
                jumlah: bp.jumlah,
                no_container: bp.no_container,
                harga: bp.harga,
              };

            return prop;
          });
          this.loading = true;

          await this.$store.dispatch(
            "penerimaan-barang/saveBarang",
            barangPenerimaan
          );
          const message = this.$route.params.id
            ? "Barang Penerimaan Berhasil Diperbaharui!"
            : "Barang Penerimaan Berhasil Ditambahkan!";
          this.displaySuccess({
            message,
          });
          setTimeout(() => {
            this.loading = false;
            this.$router.push({ name: "penerimaan-barang" });
          }, 1000);
        } catch (e) {
          this.loading = false;
          this.displayError(e);
          return false;
        }
      }
      // show popup apakah mau dilanjutkan ke penyimpanan
      // this.$bvModal.msgBoxConfirm('Apakah kamu mau langsung mengatur penyimpanan untuk penerimaan barang ini?', {
      //     title: 'Sedikit lagi',
      //     size:"sm",
      //     okTitle: 'Ya, saya mau',
      //     cancelTitle: 'Tidak',
      //     cancelVariant: 'outline-secondary',
      //     hideHeaderClose: true,
      //     centered: true
      // })
      // .then(allowed => {
      //     if(allowed) {
      //         this.displaySuccess({
      //             message: 'Data Penerimaan Barang berhasil ditambahkan'
      //         })
      //         setTimeout(() => {
      //             this.$router.push({name: 'penyimpanan', query: {inv: 23}})
      //         }, 500)
      //     }

      // })
    },
  },
  created() {

  },
  mounted() {
    // if (this.$route.params.id) {
    //   this.getPenerimaanBarang();
    // }
      this.getPenerimaanBarangId();
    // this.getDataBarang();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
