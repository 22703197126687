<template>
    <main>
        <b-overlay :show="loadingInfoBarang">
            <b-card-actions :title="$t('Customer')" actionCollapse>
                <b-row class="mb-2">
                    <b-col md="12" sm="12">
                        <div>
                            <b-row>
                                <!-- <b-form> -->
                                <b-col md="6" sm="12">
                                    <b-row>
                                        <b-col md="12" sm="12">
                                            <b-alert variant="primary" show>
                                                <h2 class="alert-heading">
                                                    Informasi Pembeli & Penerima
                                                </h2>
                                            </b-alert>
                                        </b-col>
                                        <b-col md="12" sm="12">
                                            <b-form-group>
                                                <label for="nama_kontak">Nama Lengkap <small
                                                        class="text-danger"><i>(*)</i></small></label>
                                                <b-form-input v-model="form.nama" id="nama_kontak" type="text"
                                                    placeholder="Nama Lengkap" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <b-form-group>
                                                <label for="nomor_kontak">No. Telepon (Aktif) <small
                                                        class="text-danger"><i>(*)</i></small></label>
                                                <b-form-input v-model="form.no_hp" id="nomor_kontak" type="text"
                                                    placeholder="Nomor Telepon" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <b-form-group>
                                                <label for="email">E-mail <small
                                                        class="text-info"><i>(optional)</i></small></label>
                                                <b-form-input v-model="form.email" id="email" type="email"
                                                    placeholder="Ex : official@heykama.com" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12" sm="12">
                                            <b-alert variant="primary" show>
                                                <h4 class="alert-heading">
                                                    Pengiriman
                                                </h4>
                                            </b-alert>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col md="12" sm="12">
                                            <b-form-group>
                                                <label for="kurir">Kurir <small
                                                        class="text-info"><i>(optional)</i></small></label>
                                                <b-form-input type="text" v-model="form.nama_kurir"
                                                    placeholder="JNT/JNE etc" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12" sm="12">
                                            <b-form-group>
                                                <label>AWB / Nomor Tracking / Nomor Resi<small
                                                        class="text-info"><i>(optional)</i></small></label>
                                                <b-form-input type="text" v-model="form.no_resi"
                                                    placeholder="Ex : 16598169816" />
                                            </b-form-group>
                                        </b-col>
                                        <!-- </b-row>
                <b-row> -->
                                        <b-col md="12" sm="12">
                                            <b-form-group>
                                                <label>Label Pengiriman <small
                                                        class="text-info"><i>(optional)</i></small></label>
                                                <b-form-file v-model="form.label_pengiriman" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <!-- <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                                                    Simpan </b-button> -->
                                </b-col>
                                <b-col md="6" sm="12">
                                    <b-row>
                                        <b-col md="12" sm="12">
                                            <b-alert variant="primary" show>
                                                <h2 class="alert-heading">
                                                    Alamat
                                                </h2>
                                            </b-alert>
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <b-form-group>
                                                <label>Provinsi <small class="text-danger"><i>(*)</i></small></label>
                                                <v-select v-model="form.provinsi_id" :reduce="(option) => option.value"
                                                    label="text" :options="optprovinsi" @input="getkabkota()" />

                                            <b-button v-if="optprovinsi.length == 0"
                                                @click="getProvinsi()">Refresh</b-button>
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <b-form-group>
                                                <label>Kabupaten/Kota <small
                                                        class="text-danger"><i>(*)</i></small></label>
                                                <v-select v-model="form.kab_kota_id" :reduce="(option) => option.value"
                                                    label="text" :options="optkabko" @input="getkecamatan()" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <b-form-group>
                                                <label>Kecamatan <small class="text-danger"><i>(*)</i></small></label>
                                                <v-select v-model="form.kecamatan_id" :reduce="(option) => option.value"
                                                    label="text" :options="optkec" @input="getkelurahan()" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="6" sm="12">
                                            <b-form-group>
                                                <label>Desa/Kelurahan <small
                                                        class="text-danger"><i>(*)</i></small></label>
                                                <v-select v-model="form.desa_id" :reduce="(option) => option.value"
                                                    label="text" :options="optkel" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12" sm="12">
                                            <b-form-group>
                                                <label for="alamat">Alamat <small
                                                        class="text-danger"><i>(*)</i></small></label>
                                                <b-form-textarea v-model="form.alamat_pengiriman" id="alamat"
                                                    placeholder="Nama Jalan, Gang, Gedung, No Rumah" />
                                            </b-form-group>
                                        </b-col>
                                        <b-col md="12" sm="12">
                                            <b-form-group>
                                                <label for="reward">Catatan <small
                                                        class="text-info"><i>(optional)</i></small></label>
                                                <b-form-textarea v-model="form.catatan" id="deskripsi"
                                                    placeholder="Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <!-- </b-form> -->
                            </b-row>
                        </div>
                    </b-col>
                </b-row>

            </b-card-actions>
        </b-overlay>

        <!-- </b-tab> -->
        <!-- <b-tab title="Informasi Produk"> -->
        <!-- <b-overlay :show="loadingInfoBarang"> -->
        <b-row>
            <b-col md="12" sm="12">
                <b-card header-bg-variant="warning" header-text-variant="white" header-tag="header"
                    border-variant="warning">
                    <template #header>
                        <h3 class="mb-0"><b>{{ $t('Eyeglass Prescription') }}</b></h3>
                    </template>
                    <b-tabs fill variant="warning">
                        <b-tab active>
                            <template #title>
                                <!-- <b-button block variant="warning"> -->
                                <h2>Frame + Lens + Acc</h2>
                                <!-- </b-button> -->
                            </template>
                            <b-form>
                                <!-- Name Field -->
                                <b-row class="mb-1 mt-1">
                                    <b-col cols="2" class="d-flex align-items-center">
                                        <h5><b>Name</b></h5>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-form-input v-model="resep.name" placeholder="Enter name"></b-form-input>
                                    </b-col>
                                    <b-col cols="1" class="d-flex align-items-center">
                                        <h5><b>Date</b></h5>
                                    </b-col>
                                    <b-col cols="4">
                                        <!-- <b-form-input v-model="resep.date" type="date"></b-form-input> -->
                                        <b-form-datepicker id="example-datepicker" :value="resep.date" locale="id"
                                            v-model="resep.date" />
                                    </b-col>
                                </b-row>
                                <hr>

                                <!-- Right Eye (OD) Prescription -->

                                <b-row class="mb-1">
                                    <b-col cols="12" style="text-align: left;">
                                        <h5><b>Prescription</b></h5>
                                    </b-col>
                                    <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Right / OD</h5>
                                    </b-col>
                                    <b-col cols="10">
                                        <b-row>
                                            <b-col>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-input v-model="lensasearch" placeholder="Search lensa"
                                                            @keydown.enter.prevent="getDataBarang()"></b-form-input>
                                                    </b-col>
                                                </b-row>
                                                <b-overlay :show="loadinglensa">
                                                    <b-row>
                                                        <b-col>
                                                            <v-select placeholder="SPH" v-model="resep.r_sph"
                                                                :options="localBarangsOptions" label="text"></v-select>
                                                        </b-col>
                                                    </b-row>
                                                </b-overlay>
                                                <b-row>
                                                    <b-col>
                                                        <p class="text-center">SPH</p>
                                                        <b-progress :value="progressValue" max="100" show-progress
                                                            animated></b-progress>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <b-col>
                                                <b-form-input disabled v-model="resep.r_cyl"
                                                    placeholder="CYL"></b-form-input>
                                                <p class="text-center">CYL</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.r_axis" placeholder="AXIS"></b-form-input>
                                                <p class="text-center">AXIS</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.r_add" placeholder="ADD"></b-form-input>
                                                <p class="text-center">ADD</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.r_pd" placeholder="PD"></b-form-input>
                                                <p class="text-center">PD</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <!-- <hr> -->

                                <!-- Left Eye (OS) Prescription -->
                                <b-row class="mb-1">
                                    <!-- <b-col cols="12" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                                    <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Left / OS</h5>
                                    </b-col>
                                    <b-col cols="10">
                                        <b-row>
                                            <b-col>
                                                <!-- <b-row>
                                                    <b-col>
                                                        <b-form-input v-model="lensasearch"
                                                            placeholder="Search lensa"></b-form-input>
                                                    </b-col>
                                                </b-row> -->
                                                <b-overlay :show="loadinglensa">
                                                    <b-row>
                                                        <b-col>
                                                            <v-select placeholder="SPH" v-model="resep.l_sph"
                                                                :options="localBarangsOptions" label="text"></v-select>
                                                        </b-col>
                                                    </b-row>
                                                </b-overlay>
                                                <b-row>
                                                    <b-col>
                                                        <p class="text-center">SPH</p>
                                                        <b-progress :value="progressValue" max="100" show-progress
                                                            animated></b-progress>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                            <!-- <b-overlay :show="loadinglensa">
                                                <b-col>
                                                    <v-select placeholder="SPH" v-model="resep.l_sph"
                                                        :options="localBarangsOptions" label="text"></v-select>
                                                    <p class="text-center">SPH</p>
                                                </b-col>
                                            </b-overlay> -->
                                            <b-col>
                                                <b-form-input disabled v-model="resep.l_cyl"
                                                    placeholder="CYL"></b-form-input>
                                                <p class="text-center">CYL</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.l_axis" placeholder="AXIS"></b-form-input>
                                                <p class="text-center">AXIS</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.l_add" placeholder="ADD"></b-form-input>
                                                <p class="text-center">ADD</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.l_pd" placeholder="PD"></b-form-input>
                                                <p class="text-center">PD</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <hr>
                                <!-- Lens fields -->
                                <b-row>
                                    <b-col md="12" style="text-align: left;">
                                        <!-- <p> -->
                                        <h5><b>Lens</b></h5>
                                        <!-- </p> -->
                                    </b-col>
                                    <b-col md="2" style="text-align: left;">
                                        <h5>Class</h5>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <!-- <b-form-select v-model="resep.lensClass" :options="classOptions"
                        placeholder="Select Class"></b-form-select> -->

                                            <v-select placeholder="Choose class" v-model="resep.lensClass" label="text"
                                                :options="classOptions" @input="getDataKategorifix()"></v-select>
                                            <b-button v-if="classOptions.length == 0"
                                                @click="getDataKelas()">Refresh</b-button>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Material</h5>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>

                                            <b-overlay :show="loadingkategorilensa">
                                                <b-form-group>
                                                    <v-select placeholder="Choose lensa category" v-model="resep.lensa"
                                                        label="text" :options="categorylensaOptions"></v-select>
                                                </b-form-group>
                                            </b-overlay>
                                            <small
                                                v-if="categorylensaOptions.length == 0 || categorylensaOptions == []">Data
                                                tidak ada
                                                <b-button variant="primary" size="sm"
                                                    @click="getDatablok(); getDataKategori(); getDataAksesoris(); getDataKelas()">Refresh</b-button>
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <hr>

                                <!-- Frame fields -->
                                <b-row>
                                    <b-col cols="12" style="text-align: left;">
                                        <h5><b>Frame</b></h5>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Type</h5>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <v-select placeholder="Choose frame category" v-model="resep.framecategory"
                                                label="text" :options="categoryframeOptions"></v-select>
                                                <b-button v-if="categoryframeOptions.length == 0"
                                                @click="getDataKategori()">Refresh</b-button>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="2" class="">
                                        <h5>Varian / Color</h5>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <v-select placeholder="Choose frame" v-model="resep.frame" label="text"
                                                :options="penyimpananOptions"></v-select>
                                        </b-form-group>
                                        <b-form-group>
                                            <b-button @click="findbarangframe()" block variant="warning">
                                                {{ $t('Find Frame Product') }}</b-button>
                                            <modal-frame @submit="onChooseBarangframe"></modal-frame>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <hr>
                                <b-row>
                                    <b-col cols="8" style="text-align: left;">
                                        <h5><b>Accessories (Additional)</b></h5>
                                    </b-col>
                                    <b-col cols="4">
                                        <b-form-group>
                                            <b-button @click="findbarangacc()" block variant="warning">
                                                {{ $t('Find Accessories Product') }}</b-button>
                                            <modal-acc @submit="onChooseBarangacc"></modal-acc>
                                        </b-form-group>
                                    </b-col>
                                    <template v-for="(hem, i) in accadd">
                                        <b-col cols="2" style="text-align: left;">
                                            <!-- <h5></h5> -->
                                        </b-col>
                                        <b-col cols="6">
                                            {{ hem.barang.nama }} - {{ hem.barang.varian }} x {{ hem.qty }}
                                        </b-col>
                                        <b-col cols="2">
                                            Stok :{{ hem.stok }}
                                        </b-col>
                                        <b-col cols="2" style="text-align: left;">
                                            <!-- <template> -->
                                            <b-button @click.prevent="removeadditional(i)" variant="flat-danger"
                                                size="sm">
                                                <feather-icon icon="XIcon" />
                                            </b-button>
                                            <!-- </template> -->
                                        </b-col>
                                    </template>
                                </b-row>
                                <hr>
                                <!-- Accessories fields -->
                                <!-- <b-row>
                                    <b-col cols="8" style="text-align: left;">
                                        <h5><b>Packaging</b></h5>
                                    </b-col>
                                    <b-col cols="4">
                                        <b-form-group>
                                            <b-button v-if="aksesoris.length > 0" @click="submitpackaging()" block
                                                variant="warning">
                                                {{ $t('Add Packaging Product') }}</b-button>
                                            <small v-if="aksesoris.length == 0 || aksesoris == []">Data
                                                tidak ada
                                                <b-button variant="primary" size="sm"
                                                    @click="getDataAksesoris()">Refresh</b-button>
                                            </small>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">
                                    </b-col>
                                    <b-col cols="3" style="text-align: left;">
                                        <b-form-radio-group v-model="aksesorisadd.case" name="accessory">
                                            <b-form-radio value="plastik">Softcase (Plastik)</b-form-radio>
                                            <b-form-radio value="metal">Hardcase (Metal)</b-form-radio>
                                            <b-form-radio value="">Tanpa Case</b-form-radio>
                                        </b-form-radio-group>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">

                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b-form-checkbox v-model="aksesorisadd.lap" value="lap">Lap</b-form-checkbox>
                                        <b-form-checkbox v-model="aksesorisadd.lensCleaner" value="cleaner">Lens
                                            Cleaner</b-form-checkbox>
                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b-form-checkbox v-model="aksesorisadd.kartuResep" value="resep">Kartu
                                            Resep</b-form-checkbox>
                                        <b-form-checkbox v-model="aksesorisadd.kartuGaransi" value="garansi">Kartu
                                            Garansi</b-form-checkbox>
                                        <b-form-checkbox v-model="aksesorisadd.kartuVoucher" value="voucher">Kartu
                                            Voucher</b-form-checkbox>
                                    </b-col>
                                </b-row>
                                <hr> -->
                                <b-row>
                                    <b-col cols="2" style="text-align: left;">
                                        <h5><b>Payment</b></h5>
                                    </b-col>
                                    <b-col cols="3" style="text-align: left;">
                                        <b-form-radio-group v-model="metode_bayar" name="metode">
                                            <b-form-radio value="cash">Cash</b-form-radio>
                                            <b-form-radio value="transfer">Transfer</b-form-radio>
                                            <!-- <b-form-radio value="">E-Money</b-form-radio> -->
                                        </b-form-radio-group>
                                    </b-col>
                                    <b-col cols="3" style="text-align: left;">
                                        <b-form-input v-if="metode_bayar == 'cash'" disabled
                                            v-model="form.deskripsi_bayar" type="text"
                                            placeholder="Ex. BCA, Gopay, etc"></b-form-input>
                                        <b-form-input v-if="metode_bayar == 'transfer'" v-model="form.deskripsi_bayar"
                                            type="text" placeholder="Ex. BCA, Gopay, etc"></b-form-input>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">
                                        <!-- <h5><b>Frame</b></h5> -->
                                        <b-button variant="outline-warning" block>Cancel</b-button>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">
                                        <!-- <h5><b>Frame</b></h5> -->
                                        <b-button variant="warning" block @click="submitresep">Save</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                            <!-- </b-card> -->
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <!-- <b-button block variant="warning"> -->
                                <h2>Lens Only</h2>
                                <!-- </b-button> -->
                            </template>
                            <b-form>
                                <!-- Name Field -->
                                <b-row class="mb-1 mt-1">
                                    <b-col cols="2" class="d-flex align-items-center">
                                        <h5><b>Name</b></h5>
                                    </b-col>
                                    <b-col cols="5">
                                        <b-form-input v-model="resep.name" placeholder="Enter name"></b-form-input>
                                    </b-col>
                                    <b-col cols="1" class="d-flex align-items-center">
                                        <h5><b>Date</b></h5>
                                    </b-col>
                                    <b-col cols="4">
                                        <!-- <b-form-input v-model="resep.date" type="date"></b-form-input> -->
                                        <b-form-datepicker id="example-datepicker" :value="resep.date" locale="id"
                                            v-model="resep.date" />
                                    </b-col>
                                </b-row>
                                <hr>

                                <!-- Right Eye (OD) Prescription -->

                                <b-row class="mb-1">
                                    <b-col cols="12" style="text-align: left;">
                                        <h5><b>Prescription</b></h5>
                                    </b-col>
                                    <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Right / OD</h5>
                                    </b-col>
                                    <b-col cols="10">
                                        <b-row>
                                            <b-overlay :show="loadinglensa">
                                                <b-col>
                                                    <v-select placeholder="SPH" v-model="resep.r_sph"
                                                        :options="localBarangsOptions" label="text"></v-select>
                                                    <p class="text-center">SPH</p>
                                                </b-col>
                                            </b-overlay>
                                            <b-col>
                                                <b-form-input disabled v-model="resep.r_cyl"
                                                    placeholder="CYL"></b-form-input>
                                                <p class="text-center">CYL</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.r_axis" placeholder="AXIS"></b-form-input>
                                                <p class="text-center">AXIS</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.r_add" placeholder="ADD"></b-form-input>
                                                <p class="text-center">ADD</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.r_pd" placeholder="PD"></b-form-input>
                                                <p class="text-center">PD</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <!-- <hr> -->

                                <!-- Left Eye (OS) Prescription -->
                                <b-row class="mb-1">
                                    <!-- <b-col cols="12" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                                    <!-- <b-col cols="2" style="text-align: left;">
                    <h5><b>Prescription</b></h5>
                  </b-col> -->
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Left / OS</h5>
                                    </b-col>
                                    <b-col cols="10">
                                        <b-row>
                                            <b-overlay :show="loadinglensa">
                                                <b-col>
                                                    <v-select placeholder="SPH" v-model="resep.l_sph"
                                                        :options="localBarangsOptions" label="text"></v-select>
                                                    <p class="text-center">SPH</p>
                                                </b-col>
                                            </b-overlay>
                                            <b-col>
                                                <b-form-input disabled v-model="resep.l_cyl"
                                                    placeholder="CYL"></b-form-input>
                                                <p class="text-center">CYL</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.l_axis" placeholder="AXIS"></b-form-input>
                                                <p class="text-center">AXIS</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.l_add" placeholder="ADD"></b-form-input>
                                                <p class="text-center">ADD</p>
                                            </b-col>
                                            <b-col>
                                                <b-form-input v-model="resep.l_pd" placeholder="PD"></b-form-input>
                                                <p class="text-center">PD</p>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <hr>
                                <!-- Lens fields -->
                                <b-row>
                                    <b-col md="12" style="text-align: left;">
                                        <!-- <p> -->
                                        <h5><b>Lens</b></h5>
                                        <!-- </p> -->
                                    </b-col>
                                    <b-col md="2" style="text-align: left;">
                                        <h5>Class</h5>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>
                                            <!-- <b-form-select v-model="resep.lensClass" :options="classOptions"
                        placeholder="Select Class"></b-form-select> -->
                                            <v-select placeholder="Choose class" v-model="resep.lensClass" label="text"
                                                :options="classOptions"></v-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">
                                        <h5>Material</h5>
                                    </b-col>
                                    <b-col>
                                        <b-form-group>

                                            <b-overlay :show="loadingkategorilensa">
                                                <b-form-group>
                                                    <v-select placeholder="Choose lensa category" v-model="resep.lensa"
                                                        label="text" :options="categorylensaOptions"></v-select>
                                                </b-form-group>
                                            </b-overlay>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <hr>

                                <!-- Frame fields -->
                                <!-- <b-row>
                    <b-col cols="12" style="text-align: left;">
                      <h5><b>Frame</b></h5>
                    </b-col>
                    <b-col cols="2" style="text-align: left;">
                      <h5>Type</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame category" v-model="resep.framecategory" label="text"
                          :options="categoryframeOptions"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2" class="">
                      <h5>Varian / Color</h5>
                    </b-col>
                    <b-col>
                      <b-form-group>
                        <v-select placeholder="Choose frame" v-model="resep.frame" label="text"
                          :options="penyimpananOptions"></v-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr> -->
                                <b-row>
                                    <b-col cols="12" style="text-align: left;">
                                        <h5><b>Accessories (Additional)</b></h5>
                                    </b-col>
                                    <template v-for="(hem, i) in accadd">
                                        <b-col cols="2" style="text-align: left;">
                                            <!-- <h5></h5> -->
                                        </b-col>
                                        <b-col cols="8">
                                            {{ hem.barang.nama }} - {{ hem.barang.varian }} x {{ hem.quantity }}
                                        </b-col>
                                        <b-col cols="2" style="text-align: left;">
                                            <!-- <template> -->
                                            <b-button @click.prevent="removeadditional(i)" variant="flat-danger"
                                                size="sm">
                                                <feather-icon icon="XIcon" />
                                            </b-button>
                                            <!-- </template> -->
                                        </b-col>
                                    </template>
                                </b-row>
                                <hr>
                                <!-- Accessories fields -->
                                <!-- <b-row>
                                    <b-col cols="2" style="text-align: left;">
                                        <h5><b>Packaging</b></h5>
                                    </b-col>
                                    <b-col cols="3" style="text-align: left;">
                                        <b-form-radio-group v-model="aksesorisadd.case" name="accessory">
                                            <b-form-radio value="plastik">Softcase (Plastik)</b-form-radio>
                                            <b-form-radio value="metal">Hardcase (Metal)</b-form-radio>
                                            <b-form-radio value="">Tanpa Case</b-form-radio>
                                        </b-form-radio-group>
                                    </b-col>
                                    <b-col cols="2" style="text-align: left;">

                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b-form-checkbox v-model="aksesorisadd.lap" value="lap">Lap</b-form-checkbox>
                                        <b-form-checkbox v-model="aksesorisadd.lensCleaner" value="cleaner">Lens
                                            Cleaner</b-form-checkbox>
                                    </b-col>
                                    <b-col style="text-align: left;">
                                        <b-form-checkbox v-model="aksesorisadd.kartuResep" value="resep">Kartu
                                            Resep</b-form-checkbox>
                                        <b-form-checkbox v-model="aksesorisadd.kartuGaransi" value="garansi">Kartu
                                            Garansi</b-form-checkbox>
                                        <b-form-checkbox v-model="aksesorisadd.kartuVoucher" value="voucher">Kartu
                                            Voucher</b-form-checkbox>
                                    </b-col>
                                </b-row>
                                <hr> -->
                                <b-row>
                                    <b-col cols="6" style="text-align: left;">

                                    </b-col>
                                    <b-col cols="3" style="text-align: left;">
                                        <!-- <h5><b>Frame</b></h5> -->
                                        <b-button variant="outline-warning" block>Cancel</b-button>
                                    </b-col>
                                    <b-col cols="3" style="text-align: left;">
                                        <!-- <h5><b>Frame</b></h5> -->
                                        <b-button variant="warning" block @click="submitresep">Save</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!-- <h4>{{ $t('Eyeglass Prescription') }}</h4> -->

                <!-- </b-col> -->
            </b-col>
        </b-row>
        <b-card-actions :title="$t('Product')" actionCollapse>
            <b-row class="mb-2">
                <b-col md="12" sm="12">
                    <b-alert variant="primary" show>
                        <h2 class="alert-heading">
                            Informasi Produk
                        </h2>
                    </b-alert>
                </b-col>
                <b-col md="8" sm="12" class="">
                    <b-form-group label-size="sm">
                        <b-input-group>
                            <b-form-input id="filter-input" v-model="filter" type="search"
                                placeholder="Type to Search"></b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">{{ $t('Clear')
                                    }}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-button block variant="primary" @click.prevent="chooseBarang">
                        {{ $t('Choose Items')
                        }}</b-button>
                    <modal-barang @submit="onChooseBarang"></modal-barang>
                </b-col>
                <!-- <b-col md="12" sm="12">
                                <h4>{{ $t('Additional Items') }}<span
                                        v-if="selectedBarangs && selectedBarangs.length > 0">
                                        ({{ selectedBarangs.length
                                        }})</span></h4>
                            </b-col> -->
                <b-col md="12">
                    <b-table small responsive :fields="fieldstambahan" :items="selectedBarangs" :filter="filter"
                        :filter-included-fields="filterOn">
                        <template #table-colgroup="{ fields }">
                            <col v-for="field in fields" :key="field.key"
                                :style="{ width: field.key == 'no' ? '20px' : '' }" />
                        </template>

                        <template #cell(no)="{ index }">
                            {{ 1 + index }}
                        </template>
                        <template #cell(kode)="{ item }">
                            <span v-if="item">
                                {{ item.barang ? item.barang.kode : null }}<br>
                                <small>{{ item.barang ? item.barang.no_batch : null }}</small>
                            </span>
                            <i v-else class="text-danger">{{$t('Data not found')}}</i>
                        </template>
                        <template #cell(nama)="{ item }">
                            <span v-if="item">
                                {{ item.barang ? item.barang.varian : null }}<br>
                                <small>{{ item.barang ? item.barang.kategori.kategori : null }}</small> /
                                <small>{{ item.barang ? item.barang.nama : null }}</small>
                            </span>
                            <i v-else class="text-danger">{{$t('Data not found')}}</i>
                        </template>

                        <template #cell(harga_jual)="{ item }">
                            <b-form-input type="text" class="w-70" v-model.number="item.harga_jual"
                                @input="updateSubtotal(item)"></b-form-input>
                        </template>

                        <template #cell(qty)="{ item }">
                            <b-input-group size="lg" :append="item.satuan ? item.satuan.satuan : null">
                                <b-form-input type="number" class="w-70" v-model.number="item.qty"
                                    @input="updateSubtotal(item)"></b-form-input>
                            </b-input-group>
                        </template>

                        <template #cell(diskon)="{ item }">
                            <!-- {{ formatRupiah(item.diskon) }} -->
                            <b-form-input type="text" class="w-70" v-model.number="item.diskon"
                                @input="updateSubtotal(item)"></b-form-input>
                        </template>

                        <template #cell(subtotal)="{ item }">
                            {{ formatRupiah(item.subtotal) }}
                        </template>

                        <template #cell(keterangan)="{ item }">
                            <b-form-input v-model="item.keterangan" />
                        </template>

                        <template #cell(action)="{ item, index }">
                            <feather-icon icon="MinusCircleIcon" size="24" class="text-danger cursor-pointer"
                                @click="removeBarang(item, index)"></feather-icon>
                        </template>
                    </b-table>

                    <!-- Display the total below the table -->
                    <div class="total-container text-right">
                        <b-row>
                            <b-col>

                            </b-col>
                            <b-col cols="4">
                                <b-form-group label-cols-sm="3" label-align-sm="right" label="Ongkir">
                                    <b-input-group prepend="Rp">
                                        <b-form-input type="number" label="Ongkir" v-model="form.ongkir"
                                            placeholder="Ongkir"></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <h4>Total : Rp. {{ formatRupiah(parseInt(totalKeseluruhan) + parseInt(form.ongkir)) }}</h4>
                    </div>

                </b-col>
                <!-- <b-col md="12" sm="12">
                    {{ this.selectedBarangs }}
                </b-col> -->
            </b-row>
        </b-card-actions>
        <b-modal v-model="showModal" title="Submit Data">
            <b-row>
                <b-col md="12">
                    <p><strong>No Invoice:</strong> {{ wh ? wh.no_invoice : '-' }}</p>
                    <p><strong>Name:</strong> {{ wh ? wh.name : '-' }}</p>
                    <p><strong>Date:</strong> {{ wh ? wh.date : '-' }}</p>

                    <h5>Items:</h5>
                    <ul v-if="wh">
                        <li v-for="(item, index) in wh.items" :key="index">
                            Item ID: {{ item.barang ? item.barang : '-' }} - Qty: {{ item.qty ? item.qty :
                                '-' }}
                        </li>
                    </ul>
                </b-col>
                <b-col md="6">
                    <h5>Prescription Right:</h5>
                    <p>Sph: {{ wh ? wh.prescription_right ? wh.prescription_right.sph : '-' : '-' }}</p>
                    <p>Cyl: {{ wh ? wh.prescription_right ? wh.prescription_right.cyl : '-' : '-' }}</p>
                    <p>Axis: {{ wh ? wh.prescription_right ? wh.prescription_right.axis : '-' : '-' }}</p>
                    <p>Add: {{ wh ? wh.prescription_right ? wh.prescription_right.add : '-' : '-' }}</p>
                    <p>PD: {{ wh ? wh.prescription_right ? wh.prescription_right.pd : '-' : '-' }}</p>
                </b-col>
                <b-col md="6">
                    <h5>Prescription Left:</h5>
                    <p>Sph: {{ wh ? wh.prescription_left ? wh.prescription_left.sph : '-' : '-' }}</p>
                    <p>Cyl: {{ wh ? wh.prescription_left ? wh.prescription_left.cyl : '-' : '-' }}</p>
                    <p>Axis: {{ wh ? wh.prescription_left ? wh.prescription_left.axis : '-' : '-' }}</p>
                    <p>Add: {{ wh ? wh.prescription_left ? wh.prescription_left.add : '-' : '-' }}</p>
                    <p>PD: {{ wh ? wh.prescription_left ? wh.prescription_left.pd : '-' : '-' }}</p>
                </b-col>
                <b-col md="12">
                    <h5>Accessories:</h5>
                </b-col>
                <b-col md="12">
                    <h5>Packaging:</h5>
                    <ul v-if="wh">
                        <li v-for="(acc, index) in wh.accessories" :key="index">
                            {{ acc.nama ? acc.nama : '-' }} ({{ acc.type ? acc.type : '-' }}) - Qty: {{ acc.qty ?
                                acc.qty
                                :
                                '-' }}
                        </li>
                    </ul>
                </b-col>
            </b-row>
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="secondary" @click="cancel()">Close</b-button>
                <b-button variant="primary" @click="oklensa()">OK</b-button>
            </template>
        </b-modal>
        <!-- </b-modal> -->
    </main>
</template>
<script>
import ModalBarang from "./ModalBarang.vue";
import ModalFrame from "./ModalBarangframe.vue";
import ModalAcc from "./ModalBarangacc.vue";
// import { latLngBounds, icon } from 'leaflet';
// import {
//     LMap,
//     LTileLayer,
//     LMarker,
//     LPolyline,
//     LLayerGroup,
//     LTooltip,
//     LPopup,
//     LControlZoom,
//     LControlAttribution,
//     LControlScale,
//     LControlLayers
// } from 'vue2-leaflet';
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
    BInputGroup,
    BOverlay,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BPagination,
    BInputGroupPrepend,
    BInputGroupAppend,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BCard,
    BTable,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormFile,
    BFormTextarea,
    BAlert,
    BTableSimple,
    BTh, BTd, BTr, BThead, BTfoot, BTbody, BFormRadio, BFormDatepicker, BFormRadioGroup, BProgress, BProgressBar,
} from "bootstrap-vue";
import { getSubject } from "../../../../libs/acl/utils";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
    ...required,
    message: "This field is required",
});

export default {
    components: {
        ModalBarang, ModalFrame, ModalAcc, BProgress, BProgressBar,
        // LMap,
        // LTileLayer,
        // LMarker,
        // LPolyline,
        // LLayerGroup,
        // LTooltip,
        // LPopup,
        // LControlZoom,
        // LControlAttribution,
        // LControlScale,
        // LControlLayers,
        BAlert,
        BInputGroup,
        BTableSimple,
        BTh, BTd, BTr, BThead, BTfoot, BTbody,
        BOverlay,
        Cleave,
        BTabs,
        BTab,
        BTable,
        BCard,
        ValidationProvider,
        ValidationObserver,
        vSelect,
        BCardActions,
        BContainer,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormSelect,
        BFormInput,
        BFormFile,
        BFormTextarea,
        VBTooltip,
        BDropdown,
        BDropdownItem,
        BAvatar,
        BBadge,
        BModal,
        BPagination,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormCheckbox,
        BForm, BFormRadio, BFormDatepicker, BFormRadioGroup,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data: () => ({
        lensClass: [],
        accadd: [],
        //Aksesoris Tambahan
        aksesorisadd: {
            case: 'plastik',
            lap: 'lap',
            lensCleaner: 'cleaner',
            kartuResep: 'resep',
            kartuGaransi: 'garansi',
            kartuVoucher: 'voucher',
        },
        //Kelas
        classOptions: [],
        accessoryOptions: [
            { text: 'Softcase (Plastik)', value: 'Softcase' },
            { text: 'Hardcase (Metal)', value: 'Hardcase' }
        ],
        //accs
        aksesorisOptions: [],
        itemsacc: [
            { acc: 'Case / Cepuk', product: null, qty: 1, selected: false },
            { acc: 'Lens Cleaner', product: null, qty: 1, selected: false },
            { acc: 'Lap', product: null, qty: 1, selected: false },
            { acc: 'Kartu Garansi', product: null, qty: 1, selected: false },
            { acc: 'Kartu Resep', product: null, qty: 1, selected: false },
            { acc: 'Voucher', product: null, qty: 1, selected: false },
        ],
        categorylensaOptions: [],
        categoryframeOptions: [],
        categoryaksesorisOptions: [],
        penyimpananOptions: [],
        frame: null,
        kategori_search: null,
        barangOptions: [],
        kategori_ids: null,
        resep: {
            name: null,
            r_sph: {},
            r_cyl: null,
            r_axis: null,
            r_add: null,
            r_pd: null,
            l_sph: {},
            l_cyl: null,
            l_axis: null,
            l_add: null,
            l_pd: null,
            acc: null,
            lensa: null,
            frame: null,
            date: null,
        },
        loadinglensa: false,
        categoryOptions: [],
        category: null,
        localBarangsOptions: [],
        localBarangs: null,
        modalLensa: false,
        lensa: null,
        customer: null,
        //Produk
        filter: null,
        filterOn: [],
        selectedBarangs: [],
        fieldstambahan: [
            // { key: "no_container", label: "No. Container" },
            // { key: "no_seal", label: "No. Seal" },
            // { key: "kategori", label: "kategori" },
            { key: "kode", label: "Barcode" },
            { key: "nama", label: "Nama Barang" },
            { key: "harga_jual", label: "Harga Jual" },
            { key: "diskon", label: "Diskon" },
            { key: "qty", label: "Qty" },
            { key: "keterangan", label: "Keterangan" },
            { key: "subtotal", label: "Subtotal" },
            { key: "action", label: "" },
        ],
        label: 'Simpan',
        loadingInfoBarang: false,
        //PEmbeli
        form: {
            ongkir: 0,
            nama: null,
            no_hp: null,
            email: null,
            catatan: null,
            provinsi_id: null,
            kab_kota_id: null,
            kecamatan_id: null,
            desa_id: null,
            alamat_pengiriman: null,
            nama_kurir: null,
            no_resi: null,
            label_pengiriman: null,

        },
        optprovinsi: [],
        optkabko: [],
        optkec: [],
        optkel: [],

        //FIND BARANG
        showModal: false,
        wh: null,
        aksesoris: [],
        metode_bayar: 'cash',
        catatanmetode_bayar: null,
        total: 0,
        akses: [],
        gudangOptions: [],
        lensasearch: null,
        progressValue: 0,
        loadingkategorilensa: false,
    }),

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
        },
        totalKeseluruhan() {
            return this.selectedBarangs.reduce((total, item) => {
                return this.total = total + item.subtotal;
            }, 0);
        },
    },
    watch: {
        "form.nama"(val) {
            this.resep.name = this.form.nama
        },
        selectedBarangs: {
            handler() {
                // Force recalculation of total when any change happens to selectedBarangs
                this.total;
            },
            deep: true // Ensures Vue watches nested object properties
        },

        "resep.framecategory"(newValue, oldValue) {
            // Logika untuk menangani perubahan kategori frame
            console.log("Kategori frame berubah dari", oldValue, "menjadi", newValue);
            // Anda dapat menambahkan logika tambahan di sini jika perlu
        },

        "resep.lensClass"(newValue, oldValue) {
            // Logika untuk menangani perubahan kategori frame
            console.log('Kategori frame berubah dari', oldValue, 'menjadi', newValue);
            // Anda dapat menambahkan logika tambahan di sini jika perlu
        },
        "resep.l_sph"(val) {
            // console.log("CEK LEFT", val)
            // Cek apakah val adalah null atau undefined sebelum mengakses propertinya
            if (val.value) {
                val.keterangan = "Left / Kiri"; // Tetapkan keterangan untuk lensa kiri
                val.qty = 1;
                if (val.barang && Array.isArray(val.barang.harga_jual) && val.barang.harga_jual.length > 0) {
                    val.harga_jual = val.barang.harga_jual[0].harga;
                } else {
                    val.harga_jual = 0; // Atur harga_jual menjadi 0 jika tidak tersedia
                }
                val.diskon = 0;
                val.subtotal = val.harga_jual * val.qty; // Hitung subtotal

                // Selalu tambahkan item baru ke selectedBarangs tanpa memperbarui yang ada
                // if (val) {
                this.selectedBarangs.push(JSON.parse(JSON.stringify(val)));
                // return
                // }

                // Proses nilai CYL jika diperlukan
                let va = val.text;
                if (va && this.resep.l_sph.text.match(/\+/gi) && this.resep.lensClass.text != 'Progresif') {
                    if (va.length > 3) {
                        this.resep.l_cyl = va.slice(-3);
                    } else {
                        return (this.resep.l_cyl = null);
                    }
                } else if (va && this.resep.l_sph.text.match(/MAX/gi) && this.resep.lensClass.text != 'Progresif') {
                    if (va.length > 3) {
                        this.resep.l_cyl = va.slice(-3);
                    } else {
                        return (this.resep.l_cyl = null);
                    }
                } else if (va && this.resep.l_sph.text.match(/\+/gi) && this.resep.lensClass.text === 'Progresif' && this.skulensa.some(item => item.match(/NonCYL/gi))) {
                    if (va.length > 3) {
                        this.resep.l_add = va.slice(-5);
                    } else {
                        return (this.resep.l_add = null);
                    }
                } else if (va && this.resep.l_sph.text.match(/\+/gi) && this.resep.lensClass.text === 'Progresif' && this.skulensa.some(item => item.match(/CYL/gi))) {
                    if (va.length > 3) {
                        this.resep.l_cyl = va.substring(8, 13);
                    } else {
                        return (this.resep.l_cyl = null);
                    }
                } else {
                    return (this.resep.l_cyl = null);
                }

            } else {
                console.error("resep.l_sph is null or undefined");
            }
        },
        "resep.r_sph"(val2) {
            // console.log("CEK RIGHT", val2)
            // Cek apakah val2 adalah null atau undefined sebelum mengakses propertinya
            if (val2.value) {
                val2.keterangan = "Right / Kanan"; // Tetapkan keterangan untuk lensa kanan
                val2.qty = 1;
                if (val2.barang && Array.isArray(val2.barang.harga_jual) && val2.barang.harga_jual.length > 0) {
                    val2.harga_jual = val2.barang.harga_jual[0].harga;
                } else {
                    val2.harga_jual = 0; // Atur harga_jual menjadi 0 jika tidak tersedia
                }
                val2.diskon = 0;
                val2.subtotal = val2.harga_jual * val2.qty; // Hitung subtotal
                // if (val) {
                // Selalu tambahkan item baru ke selectedBarangs tanpa memperbarui yang ada
                this.selectedBarangs.push(JSON.parse(JSON.stringify(val2)));
                // return
                // }
                // Proses nilai CYL jika diperlukan
                let va = val2.text;
                if (va && this.resep.r_sph.text.match(/\+/gi) && this.resep.lensClass.text != 'Progresif') {
                    if (va.length > 3) {
                        this.resep.r_cyl = va.slice(-3);
                    } else {
                        return (this.resep.r_cyl = null);
                    }
                } else if (va && this.resep.r_sph.text.match(/MAX/gi) && this.resep.lensClass.text != 'Progresif') {
                    if (va.length > 3) {
                        this.resep.r_cyl = va.slice(-3);
                    } else {
                        return (this.resep.r_cyl = null);
                    }
                } else if (va && this.resep.r_sph.text.match(/\+/gi) && this.resep.lensClass.text === 'Progresif' && this.skulensa.some(item => item.match(/NonCYL/gi))) {
                    if (va.length > 3) {
                        this.resep.r_add = va.slice(-5);
                    } else {
                        return (this.resep.r_add = null);
                    }
                } else if (va && this.resep.r_sph.text.match(/\+/gi) && this.resep.lensClass.text === 'Progresif' && this.skulensa.some(item => item.match(/CYL/gi))) {
                    if (va.length > 3) {
                        this.resep.r_cyl = va.substring(8, 13);
                    } else {
                        return (this.resep.r_cyl = null);
                    }
                } else {
                    return (this.resep.r_cyl = null);
                }
            } else {
                console.error("resep.r_sph is null or undefined");
            }
        },


        truncateString(str, maxLength) {
            if (str.length > maxLength) {
                return str.substring(0, maxLength - 3) + '...';
            } else {
                return str;
            }
        },
    },
    methods: {
        startProgress() {
            // Simulasi pembaruan progress bar
            const interval = setInterval(() => {
                if (this.progressValue < 100) {
                    this.progressValue += 5; // Meningkatkan progress secara bertahap
                } else {
                    clearInterval(interval); // Stop jika sudah hampir penuh
                }
            }, 200);
        },
        getDataKategorifix() {
            this.loadingkategorilensa = true
            let payload = {
                kelas_id: this.resep.lensClass ? this.resep.lensClass.value : null,
            }
            this.$store
                .dispatch("kategori/getData", payload)
                .then((response) => {
                    let res = response;
                    // let akses = [];
                    // res.map((jb) => {
                    //   if (
                    //     jb.master_kategori.master_kategori.match(/Lensa/gi) ||
                    //     jb.master_kategori.master_kategori.match(/lensa/gi)
                    //   ) {
                    //     this.categorylensaOptions.push(jb);
                    //   } else {
                    //     return false;
                    //   }
                    // });
                    this.categorylensaOptions = res
                    // this.categoryOptions = this.$store.state.kategori.datas
                    this.categorylensaOptions.map((barang) => {
                        barang.text = barang.kategori;
                        barang.value = barang.id;
                    });
                    this.loadingkategorilensa = false
                })
                .catch((e) => {

                    this.loadingkategorilensa = false
                    console.error(e)
                });
        },
        getDataKelas() {
            this.loading = true;
            this.$store
                .dispatch("kelas/getData")
                .then(() => {
                    let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
                    this.classOptions = items;
                    this.classOptions.map((barang) => {
                        barang.text = barang.kelas;
                        barang.value = barang.id;
                    });
                    this.loading = false;
                })
                .catch((e) => {
                    this.displayError(e);
                    return false;
                });
        },
        printResep2(item) {
            let params = item.id
            // console.log('CEK', params)
            this.$store.dispatch('pesanan/printResep', params)
                .then((response) => {
                    window.open(response.link, '_blank');
                    // console.log('CEK', response.datali)
                    // this.loadingresep = false
                })
                .catch(e => {
                    this.loadingresep = false
                    this.displayError(e)
                    return false
                })
        },
        getResep(item) {
            this.loadingresep = true
            let payload = {
                no_invoice: item,
                order: 'asc',
            }
            this.$store.dispatch('pesanan/getResep', payload)
                .then((response) => {
                    this.dataresep = response.data.data
                    this.loadingresep = false
                })
                .catch(e => {
                    this.loadingresep = false
                    this.displayError(e)
                    return false
                })
        },
        async oklensa() {
            this.loadingsubmitresep = true
            this.$store
                .dispatch("pesanan/saveResep", this.wh)
                .then((response) => {
                    this.displaySuccess({
                        text: "Kartu resep berhasil disimpan",
                    });

                    // console.log('RESPON', response)
                    this.printResep2(response)
                    this.getResep(this.wh.no_invoice)
                    window.open(this.$router.resolve({
                        path: `/pesanan/manual-order-detail/${response.id}`
                    }).href, '_blank');
                    // this.getlensa();
                    this.loadingsubmitresep = false
                })
                .catch((e) => {
                    this.loadingsubmitresep = false
                    this.displayError(e);
                    return false;
                });
        },
        updateSubtotal(item) {
            this.$set(item, 'subtotal', (this.unFormatRupiah(item.harga_jual) * this.unFormatRupiah(item.qty)) - this.unFormatRupiah(item.diskon));
            // item.subtotal = (JSON.parse(JSON.stringify(item.harga_jual)) * JSON.parse(JSON.stringify(item.qty))) - JSON.parse(JSON.stringify(item.diskon));
        },
        async submitresep() {
            const fd = new FormData()
            fd.append(`nama`, this.form.nama)
            fd.append(`no_hp`, this.form.no_hp)
            fd.append(`metode_bayar`, this.metode_bayar)
            fd.append(`uang_masuk`, this.total)
            fd.append(`alamat_pengiriman`, this.form.alamat_pengiriman)
            fd.append(`provinsi`, this.form.provinsi_id)
            fd.append(`kab_kota`, this.form.kab_kota_id)
            fd.append(`kecamatan`, this.form.kecamatan_id)
            fd.append(`desa`, this.form.desa_id)
            fd.append(`ongkir`, this.form.ongkir ? this.form.ongkir : 0)
            fd.append(`email`, this.form.email)
            fd.append(`nama_kurir`, this.form.nama_kurir)
            fd.append(`no_resi`, this.form.no_resi ? this.form.no_resi : null)
            fd.append(`catatan`, this.form.catatan ? this.form.catatan : '-')
            fd.append(`label_pengiriman`, this.form.label_pengiriman ? this.form.label_pengiriman : null)
            fd.append(`deskripsi_bayar`, this.form.deskripsi_bayar ? this.form.deskripsi_bayar : null)
            this.selectedBarangs.forEach((item, index) => {
                fd.append(`item[${index}][penyimpanan_id]`, item.id)
                fd.append(`item[${index}][barang_id]`, item.barang.id)
                fd.append(`item[${index}][qty]`, item.qty)
                fd.append(`item[${index}][harga_satuan]`, item.harga_jual)
                fd.append(`item[${index}][diskon]`, item.diskon)
                fd.append(`item[${index}][keterangan]`, item.keterangan ? item.keterangan : '-')
                fd.append(`item[${index}][subtotal]`, item.subtotal)
            });
            // fd.forEach((value, key) => {
            //     console.log(`${key}: ${value}`);
            // });
            this.$store
                .dispatch("pesanan/manualordercheckout", fd)
                .then((response) => {
                    this.displaySuccess({
                        message: 'Berhasil simpan data manual order'
                    })
                    this.wh = null
                    this.wh = []
                    this.showModal = false
                    // if (this.resep.name && this.resep.lensClass &&
                    //     this.resep.lensa && // kategori
                    //     this.resep.framecategory && // kategori
                    //     this.resep.frame.barang && this.resep.r_sph && this.resep.l_sph) {
                    if (this.resep.name &&
                        this.resep.lensa && // kategori
                        this.resep.frame.barang && this.resep.r_sph && this.resep.l_sph) {

                        let submit = {
                            id: response.id,
                            no_invoice: response.no_invoice ? response.no_invoice : null,
                            name: this.resep.name,
                            date: this.resep.date,
                            items: [
                                {
                                    penyimpanan_id: this.resep.r_sph.value ? this.resep.r_sph.value : null,
                                    barang: this.resep.r_sph ? this.resep.r_sph.barang.nama + " - " + this.resep.r_sph.barang.varian : null,
                                    qty: 1
                                },
                                {
                                    penyimpanan_id: this.resep.l_sph.value ? this.resep.l_sph.value : null,
                                    barang: this.resep.l_sph ? this.resep.l_sph.barang.nama + " - " + this.resep.l_sph.barang.varian : null,
                                    qty: 1
                                },
                                {
                                    penyimpanan_id: this.resep.frame.value ? this.resep.frame.value : null,
                                    barang: this.resep.frame ? this.resep.frame.text + " - " + this.resep.frame.text : null,
                                    qty: 1
                                }
                            ],
                            prescription_right: {
                                sph: this.resep.r_sph.text.replace(/Stok \(\d+\) - /, ""),
                                cyl: this.resep.r_cyl,
                                axis: this.resep.r_axis,
                                add: this.resep.r_add,
                                pd: this.resep.r_pd,
                            },
                            prescription_left: {
                                sph: this.resep.l_sph.text.replace(/Stok \(\d+\) - /, ""),
                                cyl: this.resep.l_cyl,
                                axis: this.resep.l_axis,
                                add: this.resep.l_add,
                                pd: this.resep.l_pd,
                            },
                            lens_class_id: this.resep.lensClass.id,
                            lens_material_id: this.resep.lensa.id, // kategori
                            frame_type_id: this.resep.framecategory.id, // kategori
                            frame_varian_id: this.resep.frame.barang.id, // barang

                        }
                        let submitaksesoris = []
                        // this.aksesoris.map(jb => {
                        //     if (
                        //         jb.barang.nama.match(/lap/gi) && this.aksesorisadd.lap == 'lap'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: 1,
                        //             type: 'lap'
                        //         });
                        //     }
                        //     if (
                        //         jb.barang.varian.match(/cleaner jadi/gi) && this.aksesorisadd.lensCleaner == 'cleaner'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: 1,
                        //             type: 'lens_cleaner'
                        //         });
                        //     }
                        //     if (
                        //         jb.barang.nama.match(/garansi/gi) && this.aksesorisadd.kartuGaransi == 'garansi'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: 1,
                        //             type: 'kartu_garansi'
                        //         });
                        //     }
                        //     if (
                        //         jb.barang.nama.match(/resep/gi) && this.aksesorisadd.kartuResep == 'resep'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: 1,
                        //             type: 'kartu_resep'
                        //         });
                        //     }
                        //     if (
                        //         jb.barang.nama.match(/voucher/gi) && this.aksesorisadd.kartuVoucher == 'voucher'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: 1,
                        //             type: 'kartu_voucher'
                        //         });
                        //     }
                        //     if (
                        //         jb.barang.varian.match(/plastik/gi) && this.aksesorisadd.case == 'plastik'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: this.aksesorisadd.plastikqty ? this.aksesorisadd.plastikqty : 1,
                        //             type: 'case'
                        //         });
                        //     }
                        //     if (
                        //         jb.barang.nama.match(/metal/gi) && this.aksesorisadd.case == 'metal'
                        //     ) {
                        //         submitaksesoris.push({
                        //             penyimpanan_id: jb.id,
                        //             nama: jb.barang.nama + " - " + jb.barang.varian,
                        //             qty: this.aksesorisadd.metalqty ? this.aksesorisadd.metalqty : 1,
                        //             type: 'case'
                        //         });
                        //     }
                        // })
                        submit.accessories = submitaksesoris
                        this.wh = submit
                        this.showModal = true

                    } else {
                        this.displayInfo({
                            message: 'Form resep harap dilengkapi'
                        })
                        this.loadingsubmitresep = false
                        return false
                    }

                    // this.preview_image = null;
                    // this.label = "Submit";
                    // this.resetForm();
                    // this.showModal = false;
                    // this.getData();
                })
                .catch((e) => {
                    // this.label = "Submit";
                    this.displayError(e);
                    return false;
                });

        },
        async submitpackaging() {
            this.aksesoris.map(jb => {
                let exists = this.selectedBarangs.some(item => item.barang_id === jb.barang.id);
                if (!exists) { // Only push if the item doesn't exist
                    if (
                        jb.barang.nama.match(/lap/gi) && this.aksesorisadd.lap == 'lap'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: 1,
                            // type: 'lap'
                        });
                    }
                    if (
                        jb.barang.varian.match(/LENS CLEANER/gi) && this.aksesorisadd.lensCleaner == 'cleaner'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: 1,
                            // type: 'lens_cleaner'
                        });
                    }
                    if (
                        jb.barang.nama.match(/garansi/gi) && this.aksesorisadd.kartuGaransi == 'garansi'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: 1,
                            // type: 'kartu_garansi'
                        });
                    }
                    if (
                        jb.barang.nama.match(/resep/gi) && this.aksesorisadd.kartuResep == 'resep'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: 1,
                            // type: 'kartu_resep'
                        });
                    }
                    if (
                        jb.barang.nama.match(/voucher/gi) && this.aksesorisadd.kartuVoucher == 'voucher'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: 1,
                            // type: 'kartu_voucher'
                        });
                    }
                    if (
                        jb.barang.varian.match(/plastik/gi) && this.aksesorisadd.case == 'plastik'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: this.aksesorisadd.plastikqty ? this.aksesorisadd.plastikqty : 1,
                            // type: 'case'
                        });
                    }
                    if (
                        jb.barang.nama.match(/metal/gi) && this.aksesorisadd.case == 'metal'
                    ) {
                        this.selectedBarangs.push({
                            id: jb.id,
                            barang: jb.barang,
                            penyimpanan_id: jb.id,
                            barang_id: jb.barang.id,
                            diskon: 0,
                            subtotal: 0,
                            harga_jual: 0,
                            keterangan: null,
                            nama: jb.barang.nama + " - " + jb.barang.varian,
                            qty: this.aksesorisadd.metalqty ? this.aksesorisadd.metalqty : 1,
                            // type: 'case'
                        });
                    }
                }
            })
        },
        findbarangframe() {
            this.$bvModal.show("modal-frame");
        },
        findbarangacc() {
            this.$bvModal.show("modal-acc");
        },
        onChooseBarangacc(selectedBarangs) {
            selectedBarangs.map((barang) => {
                barang.text = barang.barang.nama + ' - ' + barang.barang.varian;
                barang.value = barang.id;
                barang.stok = barang.jumlah;
                barang.harga_jual = JSON.parse(JSON.stringify(barang.harga_jual))
                barang.qty = JSON.parse(JSON.stringify(barang.qty))
                barang.diskon = JSON.parse(JSON.stringify(barang.diskon))
                barang.subtotal = this.unFormatRupiah(JSON.parse(JSON.stringify(barang.subtotal)))
                this.accadd.push(barang);
                this.selectedBarangs.push(barang);
            });
            // this.resep.frame = this.penyimpananOptions[0]
            this.$bvModal.hide("modal-acc");
        },
        removeadditional(i, item) {
            this.accadd.splice(i, 1);
        },

        getAcc(hem) {
            // console.log('HEM', hem)
            let hem2 = hem.masterSku
            // let hem3 = []
            // hem2.map(jb2 => {
            //   if (
            //     jb2.sku.match(/F/gi)
            //   ) {
            //     hem3.push(jb2.sku)
            //   }
            // })

            // console.log('Á', hem3)
            // let aaa = []
            // this.loading = true
            // hem3.map((barang) => {
            //   aaa.push(barang.sku);
            // });
            const payload = {
                no_batch: hem2 != null ? hem2 : null,
                blok_id: this.blok_id[0] ? this.blok_id[0].value : null,
                // search: 'XIOMI'
            };
            this.$store
                .dispatch("tokopenyimpanan/getData", payload)
                .then((response) => {
                    let items = response
                    // let items = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
                    //   let items_total = this.$store.state.barang.totals;
                    // this.accadd = items
                    //   this.totalRows = items_total
                    if (items.length > 0) {
                        items.map((barang) => {
                            this.accadd.push({
                                value: barang.id,
                                text: barang.barang.varian_item[0].varian + ' - Stok (' + barang.jumlah + ')',
                                barang: barang.barang,
                                quantity: hem.quantity,
                                stok: barang.jumlah,
                            })
                        });
                    } else {
                        this.displayInfo({
                            message: `Produk ${hem.masterSku} belum terdata di WMS, silakan menambah barang tersebut`
                        })
                    }
                    this.loading = false
                })
                .catch((e) => {
                    this.loading = false
                    this.displayError(e);
                    return false;
                });
        },

        removeBarang(item, index) {
            if (!item.rincian_id) {
                this.selectedBarangs.splice(index, 1);
            } else {
                // remove via API
                const payload = {
                    id: item.rincian_id,
                    fungsi: 2,
                };
                this.$store
                    .dispatch("pembelian-rincian/save", [payload])
                    .then(() => this.selectedBarangs.splice(index, 1))
                    .catch((e) => {
                        this.displayError(e);
                        return false;
                    });
            }
        },
        async getDataBarang() {
            this.loadinglensa = true;
            this.progressValue = 0;

            const payload = {
                kategori_id: this.resep?.lensa?.value || null,
                kelas_id: this.resep?.lensClass?.value || null,
                blok_id: this.blok_id?.[0]?.value || null,
                search: this.lensasearch || null,
            };

            const estimatedTime = 5000; // Estimasi waktu API dalam milidetik (misal 5 detik)
            const intervalTime = 100; // Interval pembaruan progress (100ms)

            const interval = setInterval(() => {
                this.progressValue += (intervalTime / estimatedTime) * 100;
                if (this.progressValue >= 95) {
                    clearInterval(interval); // Stop saat progress mendekati selesai
                }
            }, intervalTime);

            try {
                const startTime = performance.now(); // Waktu mulai
                const response = await this.$store.dispatch("tokopenyimpanan/getData", payload);
                const endTime = performance.now(); // Waktu selesai
                const actualTime = endTime - startTime; // Durasi aktual

                console.log(`Durasi API: ${actualTime} ms`);

                // Sinkronkan progress ke 100% jika API selesai
                this.progressValue = 100;
                clearInterval(interval);
                this.localBarangsOptions = response
                //         ter.map((item) => {
                //   item.value = item.id;
                //   item.text = item.blok;
                // });
                // Proses data respons
                this.localBarangsOptions.map((barang) => {
                    barang.value = barang.id;
                    barang.text = `Stok (${barang.jumlah}) - ${barang.barang.varian_item[0].varian}`;
                });
                this.progressValue = 0;
            } catch (error) {
                console.error(error);
                this.displayError(error);
            } finally {
                this.loadinglensa = false; // Hentikan overlay
            }
        },
        onChooseBarangframe(selectedBarangs) {
            selectedBarangs.map((barang) => {
                barang.text = barang.barang.nama + ' - ' + barang.barang.varian;
                barang.value = barang.id;
                barang.harga_jual = this.unFormatRupiah(JSON.parse(JSON.stringify(barang.harga_jual)))
                barang.qty = this.unFormatRupiah(JSON.parse(JSON.stringify(barang.qty)))
                barang.diskon = JSON.parse(JSON.stringify(barang.diskon))
                barang.subtotal = this.unFormatRupiah(JSON.parse(JSON.stringify(barang.subtotal)))
                this.penyimpananOptions.push(barang);
                this.selectedBarangs.push(barang);
            });
            this.resep.frame = this.penyimpananOptions[0]
            this.$bvModal.hide("modal-frame");
        },
        onChooseBarang(selectedBarangs) {
            selectedBarangs.map((barang) => {
                barang.no_container = null;
                barang.no_seal = null;
                barang.harga_jual = JSON.parse(JSON.stringify(barang.harga_jual))
                barang.qty = JSON.parse(JSON.stringify(barang.qty))
                barang.diskon = JSON.parse(JSON.stringify(barang.diskon))
                barang.subtotal = this.unFormatRupiah(JSON.parse(JSON.stringify(barang.subtotal)))
                this.selectedBarangs.push(barang);
            });
            this.$bvModal.hide("modal-barang");
        },
        chooseBarang() {
            this.$bvModal.show("modal-barang");
        },
        async getProvinsi() {
            let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
            provinsi = JSON.parse(JSON.stringify(provinsi));
            let newprovinsi = [];
            provinsi.map((item) => {
                newprovinsi.push({
                    text: item.name,
                    value: item.id,
                });
            });
            // this.id_provinsi = newprovinsi;
            this.optprovinsi = newprovinsi;
            // });
        },
        async getkabkota() {
            const params = {
                id_provinsi: this.form.provinsi_id ? this.form.provinsi_id : null
            }
            let kabko = await this.$store.dispatch("wilayah/getkabko", params);
            kabko = JSON.parse(JSON.stringify(kabko));
            let newkabko = [];
            kabko.map((item) => {
                newkabko.push({
                    text: item.name,
                    value: item.id,
                });
            });
            this.optkabko = newkabko;
        },
        async getkecamatan() {
            const params = {
                id_kab_kota: this.form.kab_kota_id ? this.form.kab_kota_id : null
            }
            let kec = await this.$store.dispatch("wilayah/getkecamatan", params);
            kec = JSON.parse(JSON.stringify(kec));
            let newkec = [];
            kec.map((item) => {
                newkec.push({
                    text: item.name,
                    value: item.id,
                });
            });
            this.optkec = newkec;
        },
        async getkelurahan() {
            const params = {
                id_kecamatan: this.form.kecamatan_id
            }
            let kel = await this.$store.dispatch("wilayah/getkelurahan", params);
            kel = JSON.parse(JSON.stringify(kel));
            let newkel = [];
            kel.map((item) => {
                newkel.push({
                    text: item.name,
                    value: item.id,
                });
            });
            this.optkel = newkel;
        },
        getDataKelas() {
            this.loading = true
            this.$store.dispatch('kelas/getData',)
                .then(() => {
                    let items = JSON.parse(JSON.stringify(this.$store.state.kelas.datas));
                    this.classOptions = items
                    this.classOptions.map((barang) => {
                        barang.text = barang.kelas;
                        barang.value = barang.id;
                    });

                    this.lensClass = this.classOptions[0],
                        this.framecategory = this.categoryframeOptions[0]
                    this.loading = false
                })
                .catch(e => {
                    this.displayError(e)
                    return false
                })
        },
        async getDataKategori() {
            this.$store.dispatch('kategori/getData')
                .then((response) => {
                    let res = response
                    let akses = []
                    res.map(jb => {
                        if (
                            jb.master_kategori &&
                            jb.master_kategori.master_kategori && (
                                jb.master_kategori.master_kategori.match(/Lensa/gi) ||
                                jb.master_kategori.master_kategori.match(/lensa/gi)
                            )
                        ) {
                            this.categorylensaOptions.push(jb);
                        }
                        if (
                            jb.master_kategori &&
                            jb.master_kategori.master_kategori && (
                                jb.master_kategori.master_kategori.match(/Frame/gi) ||
                                jb.master_kategori.master_kategori.match(/frame/gi))
                        ) {
                            this.categoryframeOptions.push(jb);
                        }
                        if (
                            jb.master_kategori &&
                            jb.master_kategori.master_kategori && (
                                jb.kategori.match(/PKG - Lens Cleaner/gi) ||
                                jb.kategori.match(/kartu/gi) ||
                                jb.kategori.match(/lap/gi) ||
                                jb.kategori.match(/cepuk/gi) ||
                                jb.kategori.match(/case/gi) ||
                                jb.kategori.match(/voucher/gi))
                        ) {
                            this.categoryaksesorisOptions.push(jb);
                            this.akses.push(jb.id);
                        } else {
                            return false
                        }
                    })
                    // this.categoryOptions = this.$store.state.kategori.datas
                    this.categorylensaOptions = this.categorylensaOptions.map((barang) => ({
                        text: barang.kategori,
                        value: barang.id,
                    }));

                    this.categoryframeOptions = this.categoryframeOptions.map((barang) => ({
                        text: barang.kategori,
                        value: barang.id,
                    }));

                    this.categoryaksesorisOptions = this.categoryaksesorisOptions.map((barang) => ({
                        text: barang.kategori,
                        value: barang.id,
                    }));
                    // if (this.categoryaksesorisOptions.length > 1) {
                    //     this.getDataAksesoris(akses)
                    // }
                    // console.log('Kat lensa', this.categorylensaOptions)
                    // console.log('Kat frame', this.categoryframeOptions)
                    // console.log('Kat aksesoris', this.categoryaksesorisOptions)
                    // console.log('Kat aksesoris', akses)
                    // this.totalRows = this.items.length;
                })
                .catch(e => console.error(e))
        },

        async getDataAksesoris() {
            const payload = {
                kategori_id: this.akses != null ? this.akses.join(',') : null,
                blok_id: this.blok_id[0] ? this.blok_id[0].value : null,
                // search: 'XIOMI'
            };
            await this.$store
                .dispatch("tokopenyimpanan/getData", payload)
                .then((response) => {
                    let res = response
                    this.aksesoris = res
                    // res.map(jb => {
                    //   if (
                    //     jb.barang.nama.match(/lap/gi)
                    //   ) {
                    //     this.aksesorisadd.lap.push(jb);
                    //   }
                    //   if (
                    //     jb.barang.nama.match(/cleaner/gi) && jb.barang.varian.match(/jadi/gi)
                    //   ) {
                    //     this.aksesorisadd.lensCleaner.push(jb);
                    //   }
                    //   if (
                    //     jb.barang.nama.match(/garansi/gi)
                    //   ) {
                    //     this.aksesorisadd.kartuGaransi.push(jb);
                    //     // this.aksesoris.push(jb.id)
                    //   }
                    //   if (
                    //     jb.barang.nama.match(/resep/gi)
                    //   ) {
                    //     this.aksesorisadd.kartuResep.push(jb);
                    //     // this.aksesoris.push(jb.id)
                    //   }
                    //   if (
                    //     jb.barang.nama.match(/voucher/gi)
                    //   ) {
                    //     this.aksesorisadd.kartuVoucher.push(jb);
                    //     // this.aksesoris.push(jb.id)
                    //   } else {
                    //     return false
                    //   }
                    // })
                    this.loading = false
                })

                .catch((e) => {
                    this.loading = false
                    this.displayError(e);
                    return false;
                });
        },
        getGudang() {
            this.$store.dispatch('gudang/getData', { jenis: 1 })
                .then(() => {
                    this.gudangOptions = this.$store.state.gudang.datas
                    this.gudangOptions.map((hem) => {
                        hem.value = hem.id;
                        hem.text = hem.nama_gudang;
                    })
                    //   this.getTransaksi()
                    // this.totalRows = this.items.length;
                })
                .catch(e => console.error(e))
        },
        getDatablok(retryCount = 0) {
            let params = {
                jenis: 1,
            };
            params.gudang_id = this.myGudang.data ? this.myGudang.data.id : this.myGudang.id;

            this.$store.dispatch("tokoblok/getData", params)
                .then((response) => {
                    let ter = response
                    // let ter = JSON.parse(JSON.stringify(this.$store.va.state.tokoblok.datas));
                    ter.map((item) => {
                        item.value = item.id;
                        item.text = item.blok;
                    });

                    this.blok_id = ter;
                })
                .catch((error) => {
                    // Cek apakah error adalah 507
                    if (error.response && error.response.status === 507) {
                        console.error('Error 507: Insufficient Storage. Retrying...');

                        // Batasi jumlah retry (misalnya 5 kali)
                        if (retryCount < 5) {
                            setTimeout(() => {
                                this.getDatablok(retryCount + 1); // Panggil kembali fungsi dengan peningkatan retryCount
                            }, 3000); // Coba lagi setelah 3 detik
                        } else {
                            console.error('Gagal memuat data setelah 5 kali percobaan.');
                        }
                    } else {
                        console.error('Error lain terjadi:', error); // Tangani error lainnya
                    }
                });
        }

    },
    async mounted() {
        // this.getLocation()
        this.getProvinsi()
        this.resep.date = this.getCurrentDate()
    },
    async created() {
        await this.getGudang()
        // await this.getDatablok()

        let arr = JSON.parse(localStorage.getItem("myBlok"))
        arr.map((stock) => {
                stock.text = stock.blok;
                stock.value = stock.id;
              });
              this.blok_id = arr
        this.getDataKelas()
        this.getDataKategori()
        // const action = this.$route.params.id ? "update" : "create";

        // this.checkPermission(action);

        // this.getTypeKonsumen();

        // this.getKategori();

        // this.getJenisPenyakit();

        // this.getKelompok();

        // this.getLayananEkspedisi();

        // this.getSatuan();

        // // this.getStatus();

        // if (this.$route.params.id) {
        //     let barang = this.$store.getters["barang/getBarang"](this.$route.params.id);
        //     if (!barang) {
        //         barang = await this.$store.dispatch("barang/getDataById", this.$route.params.id);
        //     }

        //     // let ter = JSON.parse(JSON.stringify(barang));
        //     //     ter.map((item) => {
        //     //         item.value = item.id;
        //     //         item.text = item.nama + ' - ' + item.satuan.satuan;
        //     //     });
        //     this.form2 = {
        //         id_barang: barang.id,
        //     };
        //     this.form = {
        //         nama: barang.nama,
        //         jenis: barang.jenis,
        //         id_kategori: barang.kategori ? barang.kategori.id : null,
        //         id_satuan: barang.satuan ? barang.satuan.id : null,
        //         harga_dasar: barang.harga_dasar,
        //         variants: [
        //             {
        //                 kode: barang.kode,
        //                 varian: barang.varian,
        //             },
        //         ],
        //     };
        // }
    },
};
</script>
<style scoped>
.total-container {
    margin-top: 20px;
}
</style>