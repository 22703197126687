export default [
  //Gudang
  //Blok
  {
    path: '/warehouse/area',
    name: 'route-warehouse-area',
    component: () => import('@/views/pages/heykama/Area.vue'),
    meta: {
      authRequired: true,
      module: 'Area',
      pageTitle: 'Area',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  //Palet
  // {
  //   path: '/warehouse/palet',
  //   name: 'route-cabang-palet',
  //   component: () => import('@/views/pages/heykama/Palet.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Palet',
  //     pageTitle: 'Palet',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  //Rak
  // {
  //   path: '/warehouse/rak',
  //   name: 'route-cabang-rak',
  //   component: () => import('@/views/pages/heykama/Rak.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Rak',
  //     pageTitle: 'Rak',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  //Laci
  // {
  //   path: '/warehouse/laci',
  //   name: 'route-cabang-laci',
  //   component: () => import('@/views/pages/heykama/Laci.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Laci',
  //     pageTitle: 'Laci',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  //Penyimpanan
  {
    path: '/current-stock',
    name: 'route-current-stock',
    component: () => import('@/views/pages/heykama/current-stock/List.vue'),
    meta: {
      authRequired: true,
      module: 'Storage Warehouse',
      pageTitle: 'Storage Warehouse',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/warehouse/penyimpanan-sementara',
  //   name: 'route-cabang-penyimpanan-sementara',
  //   component: () => import('@/views/pages/heykama/penyimpanan/Sementara.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Belum ditempatkan',
  //     pageTitle: 'Belum ditempatkan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/penempatan',
  //   name: 'route-cabang-penempatan',
  //   component: () => import('@/views/pages/heykama/penempatan/List.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Penempatan Toko',
  //     pageTitle: 'Riwayat Penyimpanan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/retur-pusat',
  //   name: 'route-cabang-retur-pusat',
  //   component: () => import('@/views/pages/heykama/retur-pusat/List.vue'),
  //   meta: {
  //     authRequired: true,
  //     pageTitle: 'Retur Pusat',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/retur-pusat/detail/:id',
  //   name: 'route-cabang-retur-pusat-detail',
  //   component: () => import('@/views/pages/heykama/retur-pusat/Detail.vue'),
  //   meta: {
  //     parent: '/warehouse/retur-pusat',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/warehouse/retur-pusat',
  //       },
  //       {
  //         text: 'Detail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/retur-pusat/edit/:id',
  //   name: 'route-cabang-retur-pusat-edit',
  //   component: () => import('@/views/pages/heykama/retur-pusat/Store.vue'),
  //   meta: {
  //     parent: '/warehouse/retur-pusat',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/warehouse/retur-pusat',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/retur-pusat/tambah',
  //   name: 'route-cabang-retur-pusat-tambah',
  //   component: () => import('@/views/pages/heykama/retur-pusat/Store.vue'),
  //   meta: {
  //     parent: '/warehouse/retur-pusat',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/warehouse/retur-pusat',
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/warehouse/stokopname',
    name: 'route-cabang-stokopname',
    component: () => import('@/views/pages/heykama/stok-opname/List.vue'),
    meta: {
      module: 'Stok Opname',
      pageTitle: 'Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          active: true,

        },
      ],
    },
  },
  {
    path: '/warehouse/stokopname/:id',
    name: 'route-cabang-stokopname-rincian',
    component: () => import('@/views/pages/heykama/stok-opname/Rincian.vue'),
    meta: {
      parent: '/warehouse/stokopname',
      module: 'Stok Opname',
      pageTitle: 'Rincian Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/warehouse/stokopname',
        },
        {
          text: 'Rincian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/warehouse/stokopname/detail/:id',
    name: 'route-cabang-stokopname-detail',
    component: () => import('@/views/pages/heykama/stok-opname/Store.vue'),
    meta: {
      parent: '/warehouse/stokopname',
      module: 'Stok Opname',
      pageTitle: 'Detail Stok Opname',
      authRequired: true,
      breadcrumb: [
        {
          text: 'List',
          to: '/warehouse/stokopname',

        },
        {
          text: 'List Barang',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/warehouse/disposal-hilang',
  //   name: 'route-cabang-disposal-hilang',
  //   component: () => import('@/views/pages/heykama/disposal/DisposalHilang.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Hilang',
  //     pageTitle: 'Hilang',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/disposal-rusak',
  //   name: 'route-cabang-disposal-rusak',
  //   component: () => import('@/views/pages/heykama/disposal/DisposalRusak.vue'),
  //   meta: {
  //     authRequired: true,
  //     module: 'Rusak',
  //     pageTitle: 'Rusak',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // {
  //   path: '/warehouse/pembelian',
  //   name: 'route-pembelian',
  //   component: () => import('@/views/pages/warehouse/pembelian/List.vue'),
  //   meta: {
  //     authRequired: true,
  //     pageTitle: 'Pembelian Cabang',
  //     breadcrumb: [
  //       {
  //         text: 'Pembelian Cabang',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/pembelian/detail/:id',
  //   name: 'route-pembelian-detail',
  //   component: () => import('@/views/pages/warehouse/pembelian/Detail.vue'),
  //   meta: {
  //     parent: '/warehouse/pembelian',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'Pembelian',
  //         to: '/warehouse/pembelian',
  //       },
  //       {
  //         text: 'Detail',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/pembelian/edit/:id',
  //   name: 'route-pembelian-edit',
  //   component: () => import('@/views/pages/warehouse/pembelian/Store.vue'),
  //   meta: {
  //     parent: '/warehouse/pembelian',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'Pembelian Cabang',
  //         to: '/warehouse/pembelian',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/pembelian/bayar/:id',
  //   name: 'route-pembelian-bayar',
  //   component: () => import('@/views/pages/warehouse/pembelian/Bayar.vue'),
  //   meta: {
  //     parent: '/warehouse/pembelian',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'Pembelian Cabang',
  //         to: '/warehouse/pembelian',
  //       },
  //       {
  //         text: 'Input Pembayaran',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/warehouse/pembelian/add',
  //   name: 'route-pembelian-add',
  //   component: () => import('@/views/pages/warehouse/pembelian/Store.vue'),
  //   meta: {
  //     parent: '/warehouse/pembelian',
  //     authRequired: true,
  //     breadcrumb: [
  //       {
  //         text: 'Pembelian Cabang',
  //         to: '/warehouse/pembelian',
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
