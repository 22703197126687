var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('section', {
    staticClass: "d-flex justify-content-between"
  }, [_c('section', [_c('b-button-group', [_vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push({
          name: 'route-barang-add'
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")]) : _vm._e(), _vm.allowCreate() ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.addimport($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Import")) + " "), _c('modal-import', {
    on: {
      "getData": _vm.getData
    }
  })], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "disabled": _vm.selectedginee.length === 0,
      "variant": "danger"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.massremove($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Mass Delete")) + " ")])], 1)], 1), _c('section', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.exportexcelModal($event);
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "DownloadIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Export")) + " ")], 1)], 1), _c('b-modal', {
    attrs: {
      "header-bg-variant": "success",
      "id": "export-modal",
      "size": "lg",
      "title": "Export : Product List",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "no-close-on-backdrop": "",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return undefined;
      },
      proxy: true
    }]),
    model: {
      value: _vm.modalexport,
      callback: function ($$v) {
        _vm.modalexport = $$v;
      },
      expression: "modalexport"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('label', [_vm._v("Field(s) :")])]), _c('b-col', {
    attrs: {
      "xl": "10",
      "lg": "10",
      "md": "10",
      "sm": "10"
    }
  }, [_c('b-button', {
    staticClass: "text-center mb-1",
    attrs: {
      "variant": "primary",
      "show": "",
      "block": ""
    }
  }, [_c('div', {
    staticClass: "button-body"
  }, [_c('span', [_c('strong', [_vm._v("Field")])])])]), _c('b-form-checkbox-group', {
    staticClass: "row",
    model: {
      value: _vm.cekkolom,
      callback: function ($$v) {
        _vm.cekkolom = $$v;
      },
      expression: "cekkolom"
    }
  }, _vm._l(_vm.kolom, function (option, index) {
    return _c('b-form-checkbox', {
      key: index,
      staticClass: "ml-1 col-10 col-md-3 mb-1",
      attrs: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  }), 1)], 1), _c('hr'), _c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('label', [_vm._v("Format :")])]), _c('b-col', {
    attrs: {
      "xl": "2",
      "lg": "2",
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "export-format",
      "options": _vm.formatOptions
    },
    model: {
      value: _vm.selectedFormat,
      callback: function ($$v) {
        _vm.selectedFormat = $$v;
      },
      expression: "selectedFormat"
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "outline-warning"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('export-modal');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    attrs: {
      "block": "",
      "variant": "warning"
    },
    on: {
      "click": _vm.exportData
    }
  }, [_vm._v("Export")])], 1)], 1)], 1)], 1)]), _c('header', [_c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Category ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.itemsKategori
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.kategori_id,
      callback: function ($$v) {
        _vm.kategori_id = $$v;
      },
      expression: "kategori_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Sort ")]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'asc',
        text: 'Ascending'
      }, {
        value: 'desc',
        text: 'Descending'
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("None")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBarang,
      callback: function ($$v) {
        _vm.sortBarang = $$v;
      },
      expression: "sortBarang"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Lainnya ")]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-form-select', {
    staticClass: "custom-select",
    attrs: {
      "options": [{
        value: 'kelas_id',
        text: 'Kelas'
      }, {
        value: 'main_sku',
        text: 'SKU Ginee'
      }, {
        value: 'kode',
        text: 'Barcode'
      }]
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("Choose")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchOptions,
      callback: function ($$v) {
        _vm.searchOptions = $$v;
      },
      expression: "searchOptions"
    }
  })], 1), _vm.searchOptions !== 'kelas_id' ? _c('b-form-input', {
    attrs: {
      "placeholder": "Masukkan teks"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.customefilter,
      callback: function ($$v) {
        _vm.customefilter = $$v;
      },
      expression: "customefilter"
    }
  }) : _vm._e(), _vm.searchOptions == 'kelas_id' ? _c('b-form-select', {
    attrs: {
      "options": _vm.itemsKelas
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }], null, false, 1495490819),
    model: {
      value: _vm.kelas_id,
      callback: function ($$v) {
        _vm.kelas_id = $$v;
      },
      expression: "kelas_id"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Pre Order ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [
      // { value: null, text: 'All' },
      {
        value: 1,
        text: 'Ya'
      }, {
        value: 0,
        text: 'Tidak'
      }]
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.is_pre_order,
      callback: function ($$v) {
        _vm.is_pre_order = $$v;
      },
      expression: "is_pre_order"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Per page'),
      "label-for": "perPage",
      "size": "sm"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Tekan enter untuk cari",
      "size": "sm"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "variant": "outline-primary",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.getData();
      }
    }
  }, [_vm._v(" Cari ")])], 1)], 1)], 1)], 1), !_vm.isCabang && _vm.optis_member.length > 0 ? _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "xl": "3",
      "lg": "3",
      "md": "3",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Pilih Affiliator",
      "label-for": "is_member"
    }
  }, [_c('b-input-group', [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "id": "is_member",
      "options": _vm.optis_member
    },
    on: {
      "input": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- Semua --")])];
      },
      proxy: true
    }], null, false, 437768337),
    model: {
      value: _vm.is_member,
      callback: function ($$v) {
        _vm.is_member = $$v;
      },
      expression: "is_member"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedginee,
            callback: function ($$v) {
              _vm.selectedginee = $$v;
            },
            expression: "selectedginee"
          }
        })];
      }
    }, {
      key: "cell(fotopro)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [item.photo && item.photo.length > 0 ? [_c('img', {
          staticStyle: {
            "width": "50px"
          },
          attrs: {
            "src": item.photo[0].path,
            "alt": "Image description"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal(item.photo[0].path, item.barcode);
            }
          }
        })] : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Photo product item not yet created")]), _c('br'), item.barcode ? _c('img', {
          staticStyle: {
            "width": "75px"
          },
          attrs: {
            "src": item.barcode,
            "alt": "Foto"
          },
          on: {
            "click": function ($event) {
              return _vm.openModal(item.photo.length > 0 ? item.photo[0].path : null, item.barcode);
            }
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")]), _c('b-modal', {
          ref: "imageModal",
          attrs: {
            "id": "image-modal",
            "title": "Preview Photo Product Item and Barcode",
            "hide-footer": true,
            "size": "lg",
            "centered": ""
          }
        }, [_c('div', {
          ref: "imageViewer"
        }, [_vm.currentImage ? _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.currentImage,
            "alt": "Full-size"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Photo product item not yet created")]), _c('br'), _vm.currentBarcode ? _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.currentBarcode,
            "alt": "Full-size"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")])])])];
      }
    }, {
      key: "cell(foto)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.barcode ? _c('img', {
          staticStyle: {
            "width": "75px"
          },
          attrs: {
            "src": item.barcode,
            "alt": "Foto"
          }
        }) : _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Barcode not yet created")])];
      }
    }, {
      key: "cell(harga_dasarsts)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahvarian(item);
            }
          },
          model: {
            value: item.varian_item[0].harga_dasar,
            callback: function ($$v) {
              _vm.$set(item.varian_item[0], "harga_dasar", $$v);
            },
            expression: "item.varian_item[0].harga_dasar"
          }
        })];
      }
    }, {
      key: "cell(harga_jualsts)",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.harga_jual && item.harga_jual.length > 0 ? [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahhargajual(item);
            }
          },
          model: {
            value: item.harga_jual[0].share_penjual,
            callback: function ($$v) {
              _vm.$set(item.harga_jual[0], "share_penjual", $$v);
            },
            expression: "item.harga_jual[0].share_penjual"
          }
        })] : _vm._e()];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.kode)), _c('br'), _c('small', [_vm._v("SKU WMS : " + _vm._s(item.varian_item[0].no_batch))]), _c('br'), _c('small', [_vm._v("SKU Ginee : " + _vm._s(item.varian_item[0].main_sku))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.nama)), _c('br'), _c('small', [_vm._v(_vm._s(item.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.varian))]), _c('br'), _vm._v(" " + _vm._s(item.kelas ? item.kelas.kelas : "-") + " ")];
      }
    }, {
      key: "cell(kodests)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahvarian(item);
            }
          },
          model: {
            value: item.varian_item[0].kode,
            callback: function ($$v) {
              _vm.$set(item.varian_item[0], "kode", $$v);
            },
            expression: "item.varian_item[0].kode"
          }
        })];
      }
    }, {
      key: "cell(variansts)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahvarian(item);
            }
          },
          model: {
            value: item.varian_item[0].varian,
            callback: function ($$v) {
              _vm.$set(item.varian_item[0], "varian", $$v);
            },
            expression: "item.varian_item[0].varian"
          }
        })];
      }
    }, {
      key: "cell(namasts)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_c('b-form-input', {
          attrs: {
            "size": "sm"
          },
          on: {
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahnama(item);
            }
          },
          model: {
            value: item.nama,
            callback: function ($$v) {
              _vm.$set(item, "nama", $$v);
            },
            expression: "item.nama"
          }
        })];
      }
    }, {
      key: "cell(margin)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga_jual[0].share_penjual - item.varian_item[0].harga_dasar)) + " ")];
      }
    }, {
      key: "cell(show_detailsts)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function ($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "cell(show_detail)",
      fn: function (row) {
        return [_c('b-form-checkbox', {
          staticClass: "vs-checkbox-con",
          attrs: {
            "plain": ""
          },
          on: {
            "change": row.toggleDetails
          },
          model: {
            value: row.detailsShowing,
            callback: function ($$v) {
              _vm.$set(row, "detailsShowing", $$v);
            },
            expression: "row.detailsShowing"
          }
        }, [_c('span', {
          staticClass: "vs-checkbox"
        }, [_c('span', {
          staticClass: "vs-checkbox--check"
        }, [_c('i', {
          staticClass: "vs-icon feather icon-check"
        })])]), _c('span', {
          staticClass: "vs-label"
        }, [_vm._v(_vm._s(row.detailsShowing ? "Hide" : "Show"))])])];
      }
    }, {
      key: "row-details",
      fn: function (_ref12) {
        var toggleDetails = _ref12.toggleDetails,
          item = _ref12.item;
        return [_vm.tabel == 1 ? _c('b-row', {
          staticClass: "mb-1"
        }, [_c('b-col', [_c('quill-editor', {
          model: {
            value: item.deskripsi,
            callback: function ($$v) {
              _vm.$set(item, "deskripsi", $$v);
            },
            expression: "item.deskripsi"
          }
        })], 1)], 1) : _c('b-row', {
          staticClass: "mb-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-table', {
          attrs: {
            "fields": [{
              key: 'checkbox',
              label: '#'
            }, {
              key: 'fotopro',
              label: 'Photo'
            }, {
              key: 'foto',
              label: 'Barcode'
            }, {
              key: 'kode',
              label: 'Barcode'
            }, {
              key: 'main_sku',
              label: 'SKU Ginee'
            }, {
              key: 'no_batch',
              label: 'SKU'
            }, {
              key: 'varian',
              label: 'Varian'
            }, {
              key: 'harga_dasar',
              label: 'Harga Dasar'
            },
            // { key: 'harga_coret', label: 'Harga Coret' },
            // { key: 'diskon.value', label: 'Diskon' },
            {
              key: 'aksi',
              label: '#'
            }],
            "striped": "",
            "small": "",
            "responsive": "",
            "items": item ? item.varian_item : [],
            "table-variant": "primary"
          },
          scopedSlots: _vm._u([_vm.modeedit == true ? {
            key: "cell(harga_dasar)",
            fn: function (_ref13) {
              var item = _ref13.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.harga_dasar,
                  callback: function ($$v) {
                    _vm.$set(item, "harga_dasar", $$v);
                  },
                  expression: "item.harga_dasar"
                }
              })];
            }
          } : null, {
            key: "cell(checkbox)",
            fn: function (_ref14) {
              var item = _ref14.item;
              return [_c('b-form-checkbox', {
                attrs: {
                  "value": item
                },
                model: {
                  value: _vm.selectedginee,
                  callback: function ($$v) {
                    _vm.selectedginee = $$v;
                  },
                  expression: "selectedginee"
                }
              })];
            }
          }, {
            key: "cell(fotopro)",
            fn: function (_ref15) {
              var item = _ref15.item;
              return [item.photo ? _c('img', {
                attrs: {
                  "src": item.photo[0].link,
                  "alt": "Foto2"
                }
              }) : _c('feather-icon', {
                attrs: {
                  "icon": "UserIcon",
                  "size": "32"
                }
              })];
            }
          }, {
            key: "cell(foto)",
            fn: function (_ref16) {
              var item = _ref16.item;
              return [item.barcode ? _c('img', {
                attrs: {
                  "src": item.barcode,
                  "alt": "Foto"
                }
              }) : _c('feather-icon', {
                attrs: {
                  "icon": "UserIcon",
                  "size": "32"
                }
              })];
            }
          }, _vm.modeedit == true ? {
            key: "cell(harga_coret)",
            fn: function (_ref17) {
              var item = _ref17.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.harga_coret,
                  callback: function ($$v) {
                    _vm.$set(item, "harga_coret", $$v);
                  },
                  expression: "item.harga_coret"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(dis)",
            fn: function (_ref18) {
              var item = _ref18.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.aturdiskon(item);
                  }
                },
                model: {
                  value: item.diskon.value,
                  callback: function ($$v) {
                    _vm.$set(item.diskon, "value", $$v);
                  },
                  expression: "item.diskon.value"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(kode)",
            fn: function (_ref19) {
              var item = _ref19.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.kode,
                  callback: function ($$v) {
                    _vm.$set(item, "kode", $$v);
                  },
                  expression: "item.kode"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(varian)",
            fn: function (_ref20) {
              var item = _ref20.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.varian,
                  callback: function ($$v) {
                    _vm.$set(item, "varian", $$v);
                  },
                  expression: "item.varian"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(main_sku)",
            fn: function (_ref21) {
              var item = _ref21.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.main_sku,
                  callback: function ($$v) {
                    _vm.$set(item, "main_sku", $$v);
                  },
                  expression: "item.main_sku"
                }
              })];
            }
          } : null, _vm.modeedit == true ? {
            key: "cell(no_batch)",
            fn: function (_ref22) {
              var item = _ref22.item;
              return [_c('b-form-input', {
                attrs: {
                  "size": "sm"
                },
                on: {
                  "keydown": function ($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    $event.preventDefault();
                    return _vm.ubahvarianitem(item);
                  }
                },
                model: {
                  value: item.no_batch,
                  callback: function ($$v) {
                    _vm.$set(item, "no_batch", $$v);
                  },
                  expression: "item.no_batch"
                }
              })];
            }
          } : null, {
            key: "cell(aksi)",
            fn: function (_ref23) {
              var item = _ref23.item;
              return [_c('section', {
                staticClass: "d-flex justify-content-end"
              }, [_c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "id": ("edit-btn-" + (item.barang_id)),
                  "size": "sm",
                  "variant": "outline-info",
                  "title": "Atur Harga"
                },
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    return _vm.aturharga(item);
                  }
                }
              }, [_vm._v(" Atur Harga Jual ")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "id": ("delete-btn-" + (item.barang_id)),
                  "size": "sm",
                  "variant": "outline-danger",
                  "title": "Hapus Produk"
                },
                on: {
                  "click": function ($event) {
                    $event.preventDefault();
                    return _vm.deleteSingle(item.barang_id);
                  }
                }
              }, [_c('feather-icon', {
                attrs: {
                  "icon": "TrashIcon"
                }
              })], 1)], 1)];
            }
          }], null, true)
        })], 1)], 1), _vm.tabel == 1 ? _c('b-button', {
          staticClass: "mb-1 mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              return _vm.ubahnama(item);
            }
          }
        }, [_vm._v(" Simpan ")]) : _vm._e(), _c('b-button', {
          staticClass: "mb-1",
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": toggleDetails
          }
        }, [_vm._v(" Hide ")])];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref24) {
        var index = _ref24.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(jenis)",
      fn: function (_ref25) {
        var item = _ref25.item;
        return [_vm._v(" " + _vm._s(item.jenis == 1 ? "Rokok" : "Non-Rokok") + " ")];
      }
    }, {
      key: "cell(id_satuan)",
      fn: function (_ref26) {
        var item = _ref26.item;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "-") + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref27) {
        var item = _ref27.item;
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_vm.allowUpdate() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": ("edit-btn-" + (item.id)),
            "size": "sm",
            "variant": "outline-info",
            "title": "Edit Produk"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.$router.push(("/barang/edit/" + (item.id)));
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e(), _vm.allowDelete() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": ("delete-btn-" + (item.id)),
            "size": "sm",
            "variant": "outline-danger",
            "title": "Hapus Produk"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.deleteSingle(item.id);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1) : _vm._e()], 1)];
      }
    }])
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-lg",
      "size": "lg",
      "title": _vm.itembarang ? _vm.itembarang.varian : '',
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.showaturharga,
      callback: function ($$v) {
        _vm.showaturharga = $$v;
      },
      expression: "showaturharga"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Contoh yang salah")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("Jangan ada data tipe konsumen dan satuan yang sama "), _c('ol', [_c('li', [_vm._v(" Data Tipe Konsumen \"Retail\" dan Satuan \"Barang\", ")]), _c('li', [_vm._v(" Data Tipe Konsumen \"Retail\" dan Satuan \"Barang\", ")]), _vm._v(" Untuk Data kedua jangan sampai ada data yg berisikan seperti diatas, harus berbeda satuannya ")])])])])], 1), _c('b-col', {
    attrs: {
      "xl": "6",
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Contoh yang benar")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_c('ol', [_c('li', [_vm._v(" Data Tipe Konsumen \"Retail\" dan Satuan \"Barang\", ")]), _c('li', [_vm._v("Data Tipe Konsumen \"Retail\" dan Satuan \"Sesi\",")])])])])])], 1), _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "block": "",
      "variant": "primary",
      "title": "Add Harga Jual"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.tambahhargajual();
      }
    }
  }, [_vm._v(" Add Harga Jual ")]), _c('b-modal', {
    attrs: {
      "id": "modal-md",
      "size": "md",
      "title": _vm.itembarang ? _vm.itembarang.varian : '',
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "ok-only": "",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    model: {
      value: _vm.kelolahargajual,
      callback: function ($$v) {
        _vm.kelolahargajual = $$v;
      },
      expression: "kelolahargajual"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Konsumen",
      "label-for": "h-tipe-konsumen",
      "label-cols-md": "4"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.tipe_konsumen,
      "reduce": function (option) {
        return option.id;
      },
      "label": "nama"
    },
    model: {
      value: _vm.form2.tipe_konsumen_id,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "tipe_konsumen_id", $$v);
      },
      expression: "form2.tipe_konsumen_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Konversi Satuan",
      "label-for": "h-satuan",
      "label-cols-md": "4"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optsatuan,
      "reduce": function (option) {
        return option.id;
      },
      "label": "nama"
    },
    model: {
      value: _vm.form2.satuan_id,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "satuan_id", $$v);
      },
      expression: "form2.satuan_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isFinance ? [_c('b-form-group', {
    attrs: {
      "label": "Payout",
      "label-for": "h-share_jasa",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-share_jasa",
      "placeholder": "Payout"
    },
    model: {
      value: _vm.form2.share_jasa,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "share_jasa", $$v);
      },
      expression: "form2.share_jasa"
    }
  })], 1)] : [_c('b-form-group', {
    attrs: {
      "label": "Payout",
      "label-for": "h-share_jasa",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-share_jasa",
      "placeholder": "Payout",
      "disabled": ""
    },
    model: {
      value: _vm.form2.share_jasa,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "share_jasa", $$v);
      },
      expression: "form2.share_jasa"
    }
  })], 1)]], 2), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Margin",
      "label-for": "h-share_penjual",
      "label-cols-md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "h-share_penjual",
      "placeholder": "Harga Jual Mitra"
    },
    model: {
      value: _vm.form2.share_penjual,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "share_penjual", $$v);
      },
      expression: "form2.share_penjual"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "offset-md": "4"
    }
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submithargajual();
      }
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "fields": [{
        key: 'tipe',
        label: 'Tipe Konsumen'
      }, {
        key: 'satuan',
        label: 'Satuan'
      },
      // { key: 'share_jasa', label: 'Payout' },
      {
        key: 'harga_dasar',
        label: 'Harga Dasar'
      }, {
        key: 'share_penjual',
        label: 'Margin'
      }, {
        key: 'harga',
        label: 'Harga Jual'
      },
      // { key: 'margin2', label: 'Margin' },
      {
        key: 'aksi',
        label: '#'
      }],
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.listhargajual ? _vm.listhargajual : []
    },
    scopedSlots: _vm._u([{
      key: "cell(aksi)",
      fn: function (_ref28) {
        var item = _ref28.item;
        return [_c('section', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": ("edit-btn-" + (item.id)),
            "size": "sm",
            "variant": "outline-danger",
            "title": "Hapus Harga Jual"
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.hapushargajual(item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)], 1)];
      }
    }, {
      key: "cell(tipe)",
      fn: function (_ref29) {
        var item = _ref29.item,
          index = _ref29.index;
        return [_vm._v(" " + _vm._s(item.tipe ? item.tipe.nama : "-") + " ")];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref30) {
        var item = _ref30.item,
          index = _ref30.index;
        return [_vm._v(" " + _vm._s(item.satuan ? item.satuan.satuan : "-") + " ")];
      }
    }, {
      key: "cell(share_jasa)",
      fn: function (_ref31) {
        var item = _ref31.item,
          index = _ref31.index;
        return [_vm.isFinance ? [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Mark Up"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            },
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahharga(item);
            }
          },
          model: {
            value: item.share_jasa,
            callback: function ($$v) {
              _vm.$set(item, "share_jasa", $$v);
            },
            expression: "item.share_jasa"
          }
        })] : [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Mark Up",
            "readonly": ""
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            },
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahharga(item);
            }
          },
          model: {
            value: item.share_jasa,
            callback: function ($$v) {
              _vm.$set(item, "share_jasa", $$v);
            },
            expression: "item.share_jasa"
          }
        })]];
      }
    }, {
      key: "cell(share_penjual)",
      fn: function (_ref32) {
        var item = _ref32.item,
          index = _ref32.index;
        return [_c('b-form-radio', {
          attrs: {
            "name": "some-radios",
            "value": "persentase"
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        }, [_vm._v("Persentase")]), _c('b-form-radio', {
          attrs: {
            "name": "some-radios",
            "value": "nominal"
          },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v;
            },
            expression: "selected"
          }
        }, [_vm._v("Nominal")]), _vm.selected == 'nominal' ? [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Margin"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            },
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahharga(item);
            }
          },
          model: {
            value: item.share_penjual,
            callback: function ($$v) {
              _vm.$set(item, "share_penjual", $$v);
            },
            expression: "item.share_penjual"
          }
        })] : [_c('b-form-group', {
          attrs: {
            "label": _vm.selected,
            "label-for": "h-persentase"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "h-persentase",
            "placeholder": "Persentase"
          },
          model: {
            value: item.persentase,
            callback: function ($$v) {
              _vm.$set(item, "persentase", $$v);
            },
            expression: "item.persentase"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Margin",
            "label-for": "h-margin"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Margin"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            },
            "keydown": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              $event.preventDefault();
              return _vm.ubahharga(item);
            }
          },
          model: {
            value: item.share_penjual,
            callback: function ($$v) {
              _vm.$set(item, "share_penjual", $$v);
            },
            expression: "item.share_penjual"
          }
        })], 1)]];
      }
    }, {
      key: "cell(harga)",
      fn: function (_ref33) {
        var item = _ref33.item,
          index = _ref33.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Harga"
          },
          on: {
            "keyup": function ($event) {
              _vm.doFormatHarga({
                index: index,
                item: item
              });
              _vm.hargaJual(index, item);
            }
          },
          model: {
            value: item.harga,
            callback: function ($$v) {
              _vm.$set(item, "harga", $$v);
            },
            expression: "item.harga"
          }
        })];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function (_ref34) {
        var item = _ref34.item,
          index = _ref34.index;
        return [_vm._v(" " + _vm._s(item.harga_dasar) + " ")];
      }
    }, {
      key: "cell(margin)",
      fn: function (_ref35) {
        var item = _ref35.item,
          index = _ref35.index;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.harga - item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(margin2)",
      fn: function (_ref36) {
        var item = _ref36.item;
        return [_vm._v(" " + _vm._s(_vm.unFormatRupiah(item.harga) - _vm.unFormatRupiah(item.harga_dasar)) + " ")];
      }
    }])
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "id": "modal-diskon",
      "size": "md",
      "ok-variant": "secondary",
      "centered": "",
      "title": "Atur Diskon per Produk"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showaturdiskon = false;
              _vm.getData();
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showaturdiskon,
      callback: function ($$v) {
        _vm.showaturdiskon = $$v;
      },
      expression: "showaturdiskon"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "8",
      "lg": "8",
      "md": "8",
      "sm": "12"
    }
  }, [_vm.itembdiskon.diskon != null ? _c('b-form-group', {
    attrs: {
      "label": "Nilai Nominal",
      "label-for": "v-value"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.itembdiskon.diskon.value,
      callback: function ($$v) {
        _vm.$set(_vm.itembdiskon.diskon, "value", $$v);
      },
      expression: "itembdiskon.diskon.value"
    }
  })], 1) : _c('b-form-group', {
    attrs: {
      "label": "Nilai Nominal",
      "label-for": "v-value"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.itemformdiskon.value,
      callback: function ($$v) {
        _vm.$set(_vm.itemformdiskon, "value", $$v);
      },
      expression: "itemformdiskon.value"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "4",
      "md": "4",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "-",
      "label-for": "v-value"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitaturdiskon($event);
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }