var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "24"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.myGudang ? _c('header', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('h5', [_c('strong', [_vm._v("Lokasi Gudang: " + _vm._s(this.myGudang.nama_gudang ? this.myGudang.nama_gudang : this.myGudang.data.nama_gudang) + " ")])])])], 1)], 1) : _vm._e(), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-form-group', [_c('label', [_c('strong', [_vm._v("Pilih lokasi 2 "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("(*)")])])]), _c('v-select', {
    attrs: {
      "options": _vm.optbloks,
      "label": "text"
    },
    on: {
      "input": function ($event) {
        return _vm.cekData();
      }
    },
    model: {
      value: _vm.blokasal,
      callback: function ($$v) {
        _vm.blokasal = $$v;
      },
      expression: "blokasal"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Tanggal")])]), _c('p', [_vm._v(_vm._s(_vm.so.tanggal))])]), _c('b-col', {
    attrs: {
      "md": "5",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Keterangan")])]), _c('p', [_vm._v(_vm._s(_vm.so.keterangan ? _vm.so.keterangan : "-"))])]), _vm.so.status == 0 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-danger",
      "size": "sm"
    }
  }, [_vm._v("Belum Selesai")])], 1) : _vm._e(), _vm.so.status == 1 ? _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "12"
    }
  }, [_c('p', [_c('strong', [_vm._v("Status")])]), _c('b-badge', {
    attrs: {
      "variant": "light-success",
      "size": "sm"
    }
  }, [_vm._v("Selesai")])], 1) : _vm._e()], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Category ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": _vm.itemsKategori,
      "disabled": !_vm.blokasal
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.kategori_id,
      callback: function ($$v) {
        _vm.kategori_id = $$v;
      },
      expression: "kategori_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Sort ")]), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'asc',
        text: 'Ascending'
      }, {
        value: 'desc',
        text: 'Descending'
      }],
      "disabled": !_vm.blokasal
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("None")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBarang,
      callback: function ($$v) {
        _vm.sortBarang = $$v;
      },
      expression: "sortBarang"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Lainnya ")]), _c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('b-form-group', [_c('b-input-group', [_c('b-input-group-prepend', [_c('b-form-select', {
    staticClass: "custom-select",
    attrs: {
      "options": [{
        value: 'kelas_id',
        text: 'Kelas'
      }, {
        value: 'main_sku',
        text: 'SKU Ginee'
      }, {
        value: 'kode',
        text: 'Barcode'
      }],
      "disabled": !_vm.blokasal
    },
    model: {
      value: _vm.searchOptions,
      callback: function ($$v) {
        _vm.searchOptions = $$v;
      },
      expression: "searchOptions"
    }
  })], 1), _vm.searchOptions !== 'kelas_id' ? _c('b-form-input', {
    attrs: {
      "placeholder": "Masukkan teks",
      "disabled": !_vm.blokasal
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.customefilter,
      callback: function ($$v) {
        _vm.customefilter = $$v;
      },
      expression: "customefilter"
    }
  }) : _vm._e(), _vm.searchOptions == 'kelas_id' ? _c('b-form-select', {
    attrs: {
      "options": _vm.itemsKelas,
      "disabled": !_vm.blokasal
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }], null, false, 1495490819),
    model: {
      value: _vm.kelas_id,
      callback: function ($$v) {
        _vm.kelas_id = $$v;
      },
      expression: "kelas_id"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "1",
      "sm": "1"
    }
  }, [_vm._v(" Pre Order ")]), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Ya'
      }, {
        value: 0,
        text: 'Tidak'
      }],
      "disabled": !_vm.blokasal
    },
    on: {
      "change": function ($event) {
        return _vm.getData();
      }
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("-- " + _vm._s(_vm.$t("All")) + " --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.is_pre_order,
      callback: function ($$v) {
        _vm.is_pre_order = $$v;
      },
      expression: "is_pre_order"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-size": "sm",
      "label": _vm.$t('Per page'),
      "label-for": "perPage"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions,
      "disabled": !_vm.blokasal
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "10",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari",
      "disabled": !_vm.blokasal
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
        _vm.getData();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingpenyimpanan,
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "24"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }])
  }, [_c('b-card', {
    staticStyle: {
      "overflow-y": "auto",
      "max-height": "500px"
    }
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "400px",
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.fields,
      "items": _vm.penyimpanan,
      "per-page": _vm.perPage
    },
    scopedSlots: _vm._u([{
      key: "head(checkbox)",
      fn: function () {
        return [_c('b-form-checkbox', {
          model: {
            value: _vm.allChecked,
            callback: function ($$v) {
              _vm.allChecked = $$v;
            },
            expression: "allChecked"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.selectedPenyimpanan,
            callback: function ($$v) {
              _vm.selectedPenyimpanan = $$v;
            },
            expression: "selectedPenyimpanan"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.stok) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.blok ? item.blok.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.palet ? item.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.rak ? item.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.laci ? item.laci.laci : "-"))])];
      }
    }, {
      key: "cell(hasil_stok_opname)",
      fn: function (_ref5) {
        var item = _ref5.item,
          index = _ref5.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.hasil_stok_opname,
            callback: function ($$v) {
              _vm.$set(item, "hasil_stok_opname", $$v);
            },
            expression: "item.hasil_stok_opname"
          }
        })];
      }
    }, {
      key: "cell(so)",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.id_stok_opname) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.hasil_stok_opname - item.stok) + " "), _c('p', [item.stok < item.hasil_stok_opname ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("(Stok Hasil SO lebih)")])]) : _vm._e(), item.stok == item.hasil_stok_opname ? _c('small', {
          staticClass: "text-success"
        }, [_c('i', [_vm._v("(Stok sesuai)")])]) : _vm._e(), item.stok > item.hasil_stok_opname ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("(Stok Hasil SO kurang)")])]) : _vm._e()])];
      }
    }, {
      key: "cell(kode2)",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama2)",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan.barang.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.penyimpanan.blok ? item.penyimpanan.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.palet ? item.penyimpanan.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-"))])];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "outline-primary",
      "disabled": !_vm.blokasal
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.simpan($event);
      }
    }
  }, [_vm._v("Simpan Halaman Ini")]) : _vm._e(), _vm.so.status == 0 ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.blokasal
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.simpanSelesai($event);
      }
    }
  }, [_vm._v("Selesaikan")]) : _vm._e()], 1)], 1), _vm.selectedPenyimpanan.length > 0 ? _c('b-card', [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingpenyimpanan,
      "rounded": "sm"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "24"
          }
        }), _c('p', {
          attrs: {
            "id": "cancel-label"
          }
        }, [_vm._v("Please wait...")])], 1)];
      },
      proxy: true
    }], null, false, 3282028484)
  }, [_c('h4', {
    staticClass: "mb-2"
  }, [_vm._v("Item yang dipilih:")]), _c('b-table', {
    attrs: {
      "sticky-header": "400px",
      "small": "",
      "bordered": "",
      "striped": "",
      "responsive": "",
      "fields": _vm.fieldsSelected,
      "items": _vm.selectedPenyimpanan
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(stok)",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(item.stok) + " ")];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.barang ? item.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.blok ? item.blok.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.palet ? item.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.rak ? item.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.laci ? item.laci.laci : "-"))])];
      }
    }, {
      key: "cell(hasil_stok_opname)",
      fn: function (_ref13) {
        var item = _ref13.item,
          index = _ref13.index;
        return [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: item.hasil_stok_opname,
            callback: function ($$v) {
              _vm.$set(item, "hasil_stok_opname", $$v);
            },
            expression: "item.hasil_stok_opname"
          }
        })];
      }
    }, {
      key: "cell(so)",
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(item.id_stok_opname) + " ")];
      }
    }, {
      key: "cell(selisih)",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(item.hasil_stok_opname - item.stok) + " "), _c('p', [item.stok < item.hasil_stok_opname ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("(Stok Hasil SO lebih)")])]) : _vm._e(), item.stok == item.hasil_stok_opname ? _c('small', {
          staticClass: "text-success"
        }, [_c('i', [_vm._v("(Stok sesuai)")])]) : _vm._e(), item.stok > item.hasil_stok_opname ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("(Stok Hasil SO kurang)")])]) : _vm._e()])];
      }
    }, {
      key: "cell(kode2)",
      fn: function (_ref16) {
        var item = _ref16.item;
        return [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama2)",
      fn: function (_ref17) {
        var item = _ref17.item;
        return [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.penyimpanan.barang.kategori.kategori))]), _vm._v(" / "), _c('small', [_vm._v(" " + _vm._s(item.penyimpanan.barang ? item.penyimpanan.barang.nama : "-") + " ")]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.$t("Room")) + " : ")]), _c('small', [_vm._v(_vm._s(item.penyimpanan.blok ? item.penyimpanan.blok : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.palet ? item.penyimpanan.palet.palet : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.rak ? item.penyimpanan.rak.rak : "-"))]), _c('small', [_vm._v(_vm._s(item.penyimpanan.laci ? item.penyimpanan.laci.laci : "-"))])];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref18) {
        var item = _ref18.item,
          index = _ref18.index;
        return [_c('feather-icon', {
          staticClass: "text-danger cursor-pointer",
          attrs: {
            "icon": "MinusCircleIcon",
            "size": "24"
          },
          on: {
            "click": function ($event) {
              return _vm.removeSelected(item, index);
            }
          }
        })];
      }
    }], null, false, 3749301558)
  }), _c('footer', {
    staticClass: "d-flex justify-content-end"
  }, [_vm.so.status == 0 ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "outline-primary",
      "disabled": !_vm.blokasal
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.simpanSelected($event);
      }
    }
  }, [_vm._v("Simpan")]) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "modal-simpan",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "no-close-on-backdrop": "",
      "ok-only": "",
      "centered": "",
      "size": "xl",
      "title": "Determine Adjustments"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex w-100 justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": _vm.buttonSelesai
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.selesai($event);
            }
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_vm.penyimpananKurang.length > 0 ? _c('div', [_c('h2', [_vm._v("Less Adjustment")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fieldsKurang,
      "items": _vm.adjustKurang
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref19) {
        var item = _ref19.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref20) {
        var item = _ref20.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : ""))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref21) {
        var item = _ref21.item,
          index = _ref21.index;
        return [_c('b-form-input', {
          on: {
            "input": function ($event) {
              return _vm.checkSelisih(item, index);
            }
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), _c('p', [item.jumlah == 0 ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("Jumlah Setidaknya harus 1")])]) : _vm._e(), item.jumlah > item.selisih ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Jumlah maksimal " + _vm._s(item.selisih))])]) : _vm._e()])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref22) {
        var item = _ref22.item,
          index = _ref22.index;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.opttype
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref23) {
        var item = _ref23.item,
          index = _ref23.index;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }], null, false, 1727214125)
  })], 1) : _vm._e(), _vm.penyimpananLebih.length > 0 ? _c('div', [_c('h2', [_vm._v("More Adjustment")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fieldsLebih,
      "items": _vm.adjustLebih
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref24) {
        var item = _ref24.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref25) {
        var item = _ref25.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref26) {
        var item = _ref26.item,
          index = _ref26.index;
        return [_c('b-form-input', {
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), _c('p', [item.jumlah == 0 ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("Jumlah Setidaknya harus 1")])]) : _vm._e(), item.jumlah > item.selisih ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Jumlah maksimal " + _vm._s(item.selisih))])]) : _vm._e()])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref27) {
        var item = _ref27.item,
          index = _ref27.index;
        return [_c('b-form-input', {
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref28) {
        var item = _ref28.item,
          index = _ref28.index;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }], null, false, 1125952428)
  })], 1) : _vm._e()]), _c('b-modal', {
    attrs: {
      "id": "modal-simpan",
      "ok-title": "Tutup",
      "ok-variant": "secondary",
      "no-close-on-backdrop": "",
      "ok-only": "",
      "centered": "",
      "size": "xl",
      "title": "Determine Adjustments"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex w-100 justify-content-end"
        }, [_c('b-button', {
          attrs: {
            "variant": "info",
            "disabled": _vm.buttonSelesai
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.selesaiSelected($event);
            }
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalSelected,
      callback: function ($$v) {
        _vm.showModalSelected = $$v;
      },
      expression: "showModalSelected"
    }
  }, [_vm.penyimpananKurang.length > 0 ? _c('div', [_c('h2', [_vm._v("Less Adjustment")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fieldsKurang,
      "items": _vm.adjustKurang
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref29) {
        var item = _ref29.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref30) {
        var item = _ref30.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : ""))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref31) {
        var item = _ref31.item,
          index = _ref31.index;
        return [_c('b-form-input', {
          on: {
            "input": function ($event) {
              return _vm.checkSelisih(item, index);
            }
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), _c('p', [item.jumlah == 0 ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("Jumlah Setidaknya harus 1")])]) : _vm._e(), item.jumlah > item.selisih ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Jumlah maksimal " + _vm._s(item.selisih))])]) : _vm._e()])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref32) {
        var item = _ref32.item,
          index = _ref32.index;
        return [_c('b-form-select', {
          attrs: {
            "options": _vm.opttype
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref33) {
        var item = _ref33.item,
          index = _ref33.index;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }], null, false, 1727214125)
  })], 1) : _vm._e(), _vm.penyimpananLebih.length > 0 ? _c('div', [_c('h2', [_vm._v("More Adjustment")]), _c('b-table', {
    attrs: {
      "responsive": "",
      "fields": _vm.fieldsLebih,
      "items": _vm.adjustLebih
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(kode)",
      fn: function (_ref34) {
        var item = _ref34.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.kode : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.no_batch : "-"))])];
      }
    }, {
      key: "cell(nama)",
      fn: function (_ref35) {
        var item = _ref35.item;
        return [_vm._v(" " + _vm._s(item.barang ? item.barang.varian : "-")), _c('br'), _c('small', [_vm._v(_vm._s(item.barang.kategori ? item.barang.kategori.kategori : "-"))]), _vm._v(" / "), _c('small', [_vm._v(_vm._s(item.barang ? item.barang.nama : "-"))])];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref36) {
        var item = _ref36.item,
          index = _ref36.index;
        return [_c('b-form-input', {
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.jumlah,
            callback: function ($$v) {
              _vm.$set(item, "jumlah", $$v);
            },
            expression: "item.jumlah"
          }
        }), _c('p', [item.jumlah == 0 ? _c('small', {
          staticClass: "text-info"
        }, [_c('i', [_vm._v("Jumlah Setidaknya harus 1")])]) : _vm._e(), item.jumlah > item.selisih ? _c('small', {
          staticClass: "text-danger"
        }, [_c('i', [_vm._v("Jumlah maksimal " + _vm._s(item.selisih))])]) : _vm._e()])];
      }
    }, {
      key: "cell(type)",
      fn: function (_ref37) {
        var item = _ref37.item,
          index = _ref37.index;
        return [_c('b-form-input', {
          attrs: {
            "readonly": ""
          },
          model: {
            value: item.type,
            callback: function ($$v) {
              _vm.$set(item, "type", $$v);
            },
            expression: "item.type"
          }
        })];
      }
    }, {
      key: "cell(keterangan)",
      fn: function (_ref38) {
        var item = _ref38.item,
          index = _ref38.index;
        return [_c('b-form-textarea', {
          model: {
            value: item.keterangan,
            callback: function ($$v) {
              _vm.$set(item, "keterangan", $$v);
            },
            expression: "item.keterangan"
          }
        })];
      }
    }], null, false, 1125952428)
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }