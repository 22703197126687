import service from '@/services'
import QueryString from 'qs'

export default {
  namespaced: true,
  state: {
    datas: [],
  },
  getters: {
    getData: (state) => (id) => state.datas.find((item) => item.id == id),
  },
  mutations: {
    SET_DATA(state, data) {
      state.datas = data;
    },
    SET_DATA_INSTOCK(state, data) {
      state.datas = data;
    },
  },
  actions: {
    // send data penerimaan barang
    async save({}, payload) {
      try {
        const config = {
          url: "/input/penerimaan",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async saveBarang({}, payload) {
      try {
        const config = {
          url: "/input/penerimaan-barang",
          method: "post",
          data: payload,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getDataById({}, id) {
      try {
        const config = {
          url: `/penerimaan/${id}`,
          method: "get",
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getDataBarang({ commit }, params = {}) {
      try {
        const config = {
          url: "/penerimaan-barang",
          method: "get",
          params,
          paramsSerializer: (params) => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);
        const result =
          response.data && response.data.data ? response.data.data : [];

        return Promise.resolve(result);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getDataBarangId({}, id) {
      try {
        const config = {
          url: `/penerimaan-barang/${id}`,
          method: "get",
          params,
          paramsSerializer: (params) => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);
        const result =
          response.data && response.data.data ? response.data.data : [];

        return Promise.resolve(result);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getData({ commit }, params = {}) {
      try {
        const config = {
          url: "/penerimaan",
          method: "get",
          params,
          paramsSerializer: (params) => QueryString.stringify(params),
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);
        const result =
          response.data && response.data.data ? response.data.data : [];
        commit("SET_DATA", result);

        return Promise.resolve(result);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getNewNoInvoice({ commit }, params = {}) {
      try {
        const config = {
          url: "/penerimaan-invoice",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };

        const response = await service.sendRequest(config);

        return Promise.resolve(response.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getInstock({ commit }, params = {}) {
      try {
        const config = {
          url: "/barang-transit",
          method: "get",
          params,
          headers: {
            Authorization: `${localStorage.tokenType} ${localStorage.token}`,
          },
        };
        const response = await service.sendRequest(config);

        const result = response.data;
        commit("SET_DATA_INSTOCK", result);

        return Promise.resolve(result);
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
