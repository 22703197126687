<template>
  <main>
    <b-overlay :show="loading">
      <!-- <b-button @click.prevent="playSound()" label="Trumpet"> 🎺 </b-button> -->
      <!-- <form-filter-export :type_export="['excel']" @onExport="exportSales">
      <template #default>
        <b-form-group v-if="!isSales" label="Sales " class="mr-2">
          <v-select :options="salesOptions" v-model="dataExport.sales_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group label="Konsumen (opsional)">
          <v-select :options="konsumenOptions" v-model="dataExport.konsumen_id" label="text" :reduce="option => option.value"></v-select>
        </b-form-group>
      </template>
</form-filter-export> -->
      <b-card title="Per Items">
        <b-row class="align-items-center">
          <!-- <b-col sm="12" md="6" class="my-1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click.prevent="$router.push('/transaksi-penjualan-toko')"
            >
              <feather-icon icon="ShoppingBagIcon" class="mr-50" />
              Toko
            </b-button>
          </b-col> -->

          <!-- <b-col sm="12" md="6" class="d-flex justify-content-end">
            <h2 class="text-success text-right">
              Total Uang Masuk:
              <strong>Rp {{ formatRupiah(this.masuk) }}</strong>
            </h2>
          </b-col> -->
        </b-row>

        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Logistic</label>
          </b-col>
          <b-col
            xl="9"
            lg="9"
            md="9"
            sm="12"
            class="mb-1 d-flex align-items-center"
          >
            <b-form-checkbox
              v-model="selectAll2"
              @change="toggleAll2"
              plain
              class="mr-1"
            >
              --ALL--
            </b-form-checkbox>
            <v-select
              class="flex-grow-1"
              v-model="logisticNames"
              :options="logisticNamesOptions"
              label="text"
              multiple
              :reduce="(option) => option.value"
              clearable
              placeholder="-Select separately-"
              :close-on-select="false"
              @input="updateSelectAll"
            >
            </v-select>
          </b-col>
          <b-col xl="1" lg="1" md="1" sm="12" class="">
            <b-form-group>
              <b-button
                class="mr-1"
                variant="primary"
                @click="getTransaksi()"
                >{{ $t("Search") }}</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="2" lg="2" md="2" sm="12" class="">
            <label>Date</label>
          </b-col>
          <b-col xl="3" lg="3" md="3" sm="12" class="">
            <b-form-group>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range' }"
              />
            </b-form-group>
          </b-col>
          <b-col xl="7" lg="7" md="7" sm="12" class="">
            <b-form-group>
              <b-button class="mr-1" variant="primary" @click="todaydate()"
                >Today</b-button
              >
              <b-button class="mr-1" variant="primary" @click="seventodaydate()"
                >7 Days</b-button
              >
              <b-button variant="primary" @click="tigapuluhtodaydate()"
                >30 Days</b-button
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="mt-1">
            <b-row>
              <b-col xl="2" lg="2" md="4" sm="4" class="">
                <b-form-group
                  class="mb-0"
                  :label="'Per page'"
                  label-for="perPageSelect"
                >
                  <b-form-select
                    id="perPageSelect"
                    size="sm"
                    v-model="perPage"
                    :options="pageOptions"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col md="4" sm="8" class="">
            <b-form-group :label="$t('Sort')"   label-for="sortBySelect"
              class="mb-0">
              <b-input-group >
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc"  :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col> -->
              <b-col xl="10" lg="10" md="8" sm="8" class="">
                <b-form-group
                  :label="$t('Search')"
                  label-for="filterInput"
                  class="mb-0"
                >
                  <!-- <b-input-group>
                    <b-form-input id="filterInput" size="sm" v-model="filter" type="search"
                      placeholder="Tekan enter untuk cari" @keydown.enter.prevent="getTransaksi()" />
                    <b-input-group-append>
                      <b-button :disabled="!filter" size="sm" @click="
                        filter = '';
                      getTransaksi();
                      ">
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group> -->
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isFinance || isOwner || isCEO">
              <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                <b-form-group
                  label="Status Bayar"
                  label-for="status_bayar"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="status_bayar"
                      v-model="status_bayar"
                      :options="optstatus_bayar"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                <b-form-group
                  label="Status"
                  label-for="statuskirim"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="statuskirim"
                      v-model="statuskirim"
                      :options="optstatuskirim"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                <b-form-group
                  label="Daftar yang sudah masuk ke akun dan kas"
                  label-for="is_accounted"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="is_accounted"
                      v-model="is_accounted"
                      :options="optis_accounted"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" xl="3" lg="3" md="3" sm="12">
                <b-form-group
                  label="Pilih Affiliator"
                  label-for="is_member"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="is_member"
                      v-model="is_member"
                      :options="optis_member"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isCabang || isMarketing">
              <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
                <b-form-group
                  label="Status Bayar"
                  label-for="status_bayar"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="status_bayar"
                      v-model="status_bayar"
                      :options="optstatus_bayar"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
                <b-form-group
                  label="Status"
                  label-for="statuskirim"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="statuskirim"
                      v-model="statuskirim"
                      :options="optstatuskirim"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" xl="4" lg="4" md="4" sm="12">
                <b-form-group
                  label="Status"
                  label-for="typeTransaksi"
                  class="mb-0"
                >
                  <b-input-group>
                    <b-form-select
                      size="sm"
                      id="typeTransaksi"
                      v-model="typeTransaksi"
                      :options="opttypetransaksi"
                      class=""
                      @input="getTransaksi()"
                    >
                      <template #first>
                        <b-form-select-option :value="null"
                          >-- Semua --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <!-- <b-form-group label="Selection mode:" label-for="table-select-mode-select" label-cols-md="4">
              <b-form-select id="table-select-mode-select" v-model="selectMode" :options="modes"
                class="mb-3"></b-form-select>
            </b-form-group> -->
                <b-table
                  :tbody-tr-class="rowClass"
                  small
                  hover
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  responsive="sm"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>
                  <template #cell(barcode)="{ item }">
                    {{
                      item.item_data.barcode
                        ? item.item_data.barcode
                            .split("/")
                            .pop()
                            .split(".png")[0]
                        : item.item_data.kode
                    }}
                  </template>
                  <template #cell(item_data)="{ item }">
                    <!-- <template
                      v-if="item.item_data.penyimpanan ? item.item_data.penyimpanan.nama == item.item_data.penyimpanan.varian : item.item_data.penyimpanan"> -->
                    {{
                      item.item_data.penyimpanan
                        ? item.item_data.penyimpanan.nama
                        : item.item_data.barang_nama
                    }}<br />
                    {{
                      item.item_data.penyimpanan
                        ? item.item_data.penyimpanan.varian
                        : item.item_data.varian
                    }}
                    <!-- </template>
            <template v-else>
                      {{ item.item_data.penyimpanan ? item.item_data.penyimpanan.nama : item.item_data.barang_nama
                      }}
                    </template> -->
                  </template>
                  <template #cell(quantity)="{ item }">
                    {{
                      item.item_data.qty
                        ? item.item_data.qty
                        : item.item_data.quantity
                    }}
                  </template>
                  <template #cell(no)="{ index }">
                    {{ index + 1 }}
                  </template>
                  <template #cell(tanggal)="{ item }">
                    {{
                      item.item_data.createAt
                        ? humanDateTime(item.item_data.createAt)
                        : humanDateTime(item.item_data.waktu_transaksi)
                    }}
                  </template>
                  <template #cell(no_invoice)="{ item }">
                    {{
                      item
                        ? item.item_data.externalOrderId
                        : item.item_data.no_invoice
                    }}
                  </template>
                  <template #cell(qtyScanned)="{ item }">
                    {{ item.item_data.qtyScanned }}
                  </template>
                  <template #cell(scanned_qty)="{ item }">
                    {{ item.item_data.scanned_qty }}
                  </template>
                  <template #cell(scanned)="{ item }">
                    {{ item.item_data.scanned }}
                  </template>

                  <template #cell(action)="{ item }">
                    <b-button
                      v-if="
                        item.item_data.transaction_type == 'jasa' &&
                        item.item_data.status != 'dibatalkan'
                      "
                      size="sm"
                      variant="outline-primary"
                      @click.prevent="modalTerapis(item)"
                      v-b-tooltip.hover.top="'Pilih Terapis'"
                    >
                      <feather-icon icon="UserIcon" />
                    </b-button>
                    <b-button
                      v-if="
                        item.item_data.status == 0 &&
                        allowUpdate() &&
                        item.item_data.tak_tertagih == 0 &&
                        item.item_data.bayar == 0
                      "
                      size="sm"
                      variant="outline-warning"
                      @click.prevent="
                        $router.push(
                          `/transaksi-penjualan/edit/${item.item_data.id}`
                        )
                      "
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </template>
                  <template #cell(actionFinance)="{ item }">
                    <b-button
                      size="sm"
                      variant="warning"
                      @click.prevent="
                        $router.push(
                          `/transaksi-penjualan/detail/${item.item_data.id}`
                        )
                      "
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      title="Konfirmasi Pembayaran Manual"
                      v-if="item.item_data.status_bayar != 'lunas'"
                      size="sm"
                      variant="primary"
                      @click="aksiKonfirmasiawal(item)"
                    >
                      <feather-icon icon="ListIcon" />
                    </b-button>
                    <b-button
                      title="Cek Pembayaran Otomatis"
                      v-if="
                        item.item_data.status_bayar != 'lunas' &&
                        item.item_data.payment_option == 'pg_midtrans'
                      "
                      size="sm"
                      variant="info"
                      @click="aksiKonfirmasipg(item)"
                    >
                      <feather-icon icon="DollarSignIcon" />
                    </b-button>
                    <!-- <b-button title="Konfirmasi Pembayaran" v-if="item.item_data.status_bayar != 'lunas' && item.item_data.status != 'dibatalkan'" size="sm" variant="primary" @click="aksiKonfirmasi(item)">
                  <feather-icon icon="CheckIcon" />
                </b-button> -->
                    <b-modal
                      hide-backdrop
                      no-close-on-esc
                      no-close-on-backdrop
                      v-model="showKonfirmasi"
                      id="modal-konfirmasi"
                      ok-variant="secondary"
                      centered
                      :title="
                        'Konfirmasi Pembayaran ' + formkonfirmasi.no_invoice
                      "
                    >
                      <b-row>
                        <!-- {{ this.konfirmasi != null ? this.konfirmasi : '-' }} -->
                        <b-col xl="12" lg="12" md="12" sm="12">
                          <!-- <b-card> -->
                          <!-- <validation-observer ref="vkonfirmasi">
                        <b-form>
                            <b-row>
                              <b-col sm="12" md="12">
                                <b-form-group label="Jenis Transaksi">
                                  <b-form-select
                                    v-model="transactionType"
                                    :options="[
                                      { value: 1, text: 'Debit' },
                                      { value: 2, text: 'Kredit' },
                                    ]"
                                    id="v-transaction_type"
                                    name="transaction_type"
                                  />
                                </b-form-group>
                              </b-col>
                              <b-col sm="12" md="12" v-if="transactionType">
                                <b-form-group label="Pilih Akun">
                                  <validation-provider #default="{ errors }" rules="required" name="id_akun">
                                    <b-form-select v-model="formkonfirmasi.id_akun" :options="id_akun" id="v-id_akun" name="id_akun" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col sm="12" md="12">
                                <b-form-group label="Pilih Kas">
                                  <validation-provider #default="{ errors }" rules="required" name="id_kas">
                                    <b-form-select v-model="formkonfirmasi.id_kas" :options="id_kas" id="v-id_kas" name="id_kas" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-form> -->
                          <!-- <b-button block>Lunas</b-button> -->
                          <!-- </validation-observer> -->
                          <b-button
                            variant="primary"
                            @click.prevent="submitkonfirmasi"
                            block
                            class="mb-2"
                          >
                            Konfirmasi Lunas
                          </b-button>
                          <!-- </b-card> -->
                          <!-- </b-col>
                    <b-col xl="6" lg="6" md="12" sm="12"> -->
                          <!-- <b-card border-variant="primary">
                        
                      </b-card> -->
                          <!-- </b-col>
                    <b-col xl="6" lg="6" md="12" sm="12"> -->
                          <!-- <b-card> -->
                          <b-overlay :show="loadingfoto">
                            <h3 align="center">Bukti Transfer</h3>
                            <template v-if="formkonfirmasi.bukti_tf">
                              <b-img
                                :src="apiFile + formkonfirmasi.bukti_tf.file"
                                alt=""
                                fluid-grow
                              />
                            </template>
                            <template v-else>
                              <center>
                                <h3 class="text-danger">Belum diupload</h3>
                              </center>
                            </template>
                          </b-overlay>
                          <!-- </b-card> -->
                        </b-col>
                      </b-row>
                      <template #modal-footer>
                        <section
                          class="d-flex justify-content-end align-items-center"
                        >
                          <b-button
                            size="sm"
                            variant="danger"
                            @click="
                              showKonfirmasi = false;
                              getTransaksi();
                            "
                          >
                            Keluar
                          </b-button>
                        </section>
                      </template>
                    </b-modal>
                  </template>
                </b-table>

                <b-col cols="12">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                    class="my-0"
                  />

                  <!-- <p>
                <b-button size="sm" @click="selectAllRows">Select all</b-button>
                <b-button size="sm" @click="clearSelected">Clear selected</b-button>
                <b-button size="sm" @click="selectThirdRow">Select 3rd row</b-button>
                <b-button size="sm" @click="unselectThirdRow">Unselect 3rd row</b-button>
              </p> -->
                </b-col>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="6" class="mt-1">
            <b-tabs>
              <b-tab active title="Scan Outbond">
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" class="">
                    <b-form-group
                      :label="$t('Scan Barcode')"
                      label-for="filterbarcode"
                      class="mb-0"
                    >
                      <b-form-tags
                        id="filterbarcode"
                        v-model="value"
                        @input="resetInputValue()"
                        tag-variant="success"
                        class="mb-2"
                        no-outer-focus
                        placeholder="Enter a new barcode value and click Add or enter"
                        :state="state"
                      >
                        <template
                          v-slot="{
                            tags,
                            inputId,
                            placeholder,
                            addTag,
                            removeTag,
                          }"
                        >
                          <b-input-group>
                            <!-- Always bind the id to the input so that it can be focused when needed -->
                            <b-form-input
                              ref="autoFocusInput"
                              @keydown.enter.prevent="
                                addTag(newTag);
                                cekbarcode(newTag);
                              "
                              v-model="newTag"
                              :id="inputId"
                              :placeholder="placeholder"
                              autoFocusInput2
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                @click="
                                  addTag(newTag);
                                  cekbarcode(newTag);
                                "
                                variant="primary"
                                >Add</b-button
                              >
                              <b-button @click="saveScan()" variant="primary"
                                >Save Scan</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-invalid-feedback :state="state">
                            Duplicate tag value cannot be added again!
                          </b-form-invalid-feedback>
                          <template v-if="cekbar.length > 0">
                            <!-- <ul id="my-custom-tags-list" class="list-unstyled d-inline-flex flex-wrap mb-0"
                          aria-live="polite" aria-atomic="false" aria-relevant="additions removals"> -->
                            <!-- <div class="d-inline-block" style="font-size: 1.5rem;"> -->
                            <template v-for="tag in cekbar">
                              <b-badge
                                @remove="removeTag(tag)"
                                :key="tag.text"
                                :title="tag.text"
                                :variant="
                                  tag.variant ? tag.variant : 'light-danger'
                                "
                                class="mr-2"
                                >{{ tag.text }}
                                <!-- ({{ tag.qty }}) -->
                                <b-button-group>
                                  <b-button
                                    size="sm"
                                    class="btn-icon"
                                    @click="updateQtymin(tag)"
                                    variant="light"
                                  >
                                    <feather-icon icon="MinusIcon" />
                                  </b-button>
                                  <b-form-input
                                    size="sm"
                                    type="number"
                                    v-model="tag.qty"
                                    @keydown.enter.prevent="updateQtyform(tag)"
                                    class="w-50"
                                    variant="light"
                                  >
                                    <!-- {{ tag.qty }} -->
                                  </b-form-input>
                                  <b-button
                                    size="sm"
                                    class="btn-icon mr-1"
                                    @click="updateQtyplus(tag)"
                                    variant="light"
                                  >
                                    <feather-icon icon="PlusIcon" />
                                  </b-button>
                                </b-button-group>
                                <!-- <b-form-spinbutton size="sm" id="sb-inline" v-model="tag.qty" inline
                              @change="updateQty(tag)" min="0"></b-form-spinbutton> -->
                                <b-button
                                  size="sm"
                                  class="btn-icon"
                                  variant="light"
                                  @click="removecekbar(tag)"
                                >
                                  <feather-icon icon="XIcon" /> </b-button
                              ></b-badge>
                            </template>
                          </template>
                          <b-form-text v-else>
                            There are no tags specified. Add a new tag above.
                          </b-form-text>
                        </template>
                      </b-form-tags>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <!-- <b-tab title="Batal Outbond">
                <b-row>
                  <b-col xl="12" lg="12" md="12" sm="12" class="">
                    <b-form-group
                      :label="$t('Scan Barcode')"
                      label-for="filterbarcodeundo"
                      class="mb-0"
                    >
                      <b-form-tags
                        id="filterbarcodeundo"
                        v-model="valueundo"
                        @input="resetInputValue()"
                        tag-variant="success"
                        class="mb-2"
                        no-outer-focus
                        placeholder="Enter a new barcode value and click Add or enter"
                        :state="stateundo"
                      >
                        <template
                          v-slot="{
                            tags,
                            inputId,
                            placeholder,
                            addTag,
                            removeTag,
                          }"
                        >
                          <b-input-group>
                            <b-form-input
                              ref="autoFocusInput"
                              @keydown.enter.prevent="
                                addTag(newTagundo);
                                cekbarcodeundo(newTagundo);
                              "
                              v-model="newTagundo"
                              :id="inputId"
                              :placeholder="placeholder"
                              autoFocusInput2
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                @click="
                                  addTag(newTagundo);
                                  cekbarcodeundo(newTagundo);
                                "
                                variant="primary"
                                >Add</b-button
                              >
                              <b-button
                                @click="saveScanundo()"
                                variant="primary"
                                >Save Scan</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-invalid-feedback :state="stateundo">
                            Duplicate tag value cannot be added again!
                          </b-form-invalid-feedback>
                          <template v-if="cekbarundo.length > 0">
                            <template v-for="tag in cekbarundo">
                              <b-badge
                                @remove="removeTag(tag)"
                                :key="tag.text"
                                :title="tag.text"
                                :variant="
                                  tag.value == 'ada' ? 'success' : 'danger'
                                "
                                class="mr-1"
                                >{{ tag.text }}
                                <b-button-group>
                                  <b-button
                                    size="sm"
                                    class="btn-icon"
                                    @click="updateQtymin(tag)"
                                    variant="light"
                                  >
                                    <feather-icon icon="MinusIcon" />
                                  </b-button>
                                  <b-button
                                    size="sm"
                                    class="btn-icon"
                                    variant="light"
                                  >
                                    {{ tag.qty }}
                                  </b-button>
                                  <b-button
                                    size="sm"
                                    class="btn-icon mr-1"
                                    @click="cekbarcodeundo(tag.text)"
                                    variant="light"
                                  >
                                    <feather-icon icon="PlusIcon" />
                                  </b-button>
                                </b-button-group>
                                <b-button
                                  size="sm"
                                  class="btn-icon"
                                  variant="light"
                                  @click="removecekbarundo(tag)"
                                >
                                  <feather-icon icon="XIcon" /> </b-button
                              ></b-badge>
                            </template>
                          </template>
                          <b-form-text v-else>
                            There are no tags specified. Add a new tag above.
                          </b-form-text>
                        </template>
                      </b-form-tags>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab> -->
            </b-tabs>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </main>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FormFilterExport from "@core/components/form-filter-export/FormFilterExport.vue";
import {
  BTable,
  BCard,
  VBTooltip,
  BImg,
  BForm,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BFormSelectOption,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTags,
  BFormText,
  BFormInvalidFeedback,
  BFormSpinbutton,
  BButtonGroup,
  BTabs,
  BTab,
} from "bootstrap-vue";
import vSelect from "vue-select";
import mixin from "./mixin";
// import { useSound } from '@vueuse/sound'
// import buttonSfx from '@/assets/sound/correct.mp3'

export default {
  // setup() {
  //   const { play } = useSound(buttonSfx)

  //   return {
  //     play,
  //   }
  // },
  mixins: [mixin],
  components: {
    flatPickr,
    BFormTags,
    BFormText,
    BFormInvalidFeedback,
    BFormSpinbutton,
    ToastificationContent,
    BButtonGroup,
    FormFilterExport,
    VBTooltip,
    BModal,
    BDropdown,
    BDropdownItem,
    BTable,
    BCard,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BImg,
    BTabs,
    BTab,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      newTagundo: "",
      newTag: "",
      value: [],
      showModalEdit: false,
      showModalAdd: false,
      showModalTerapis: false,
      loadingTerapis: false,
      itemTransaksiTerapis: {},
      id: null,
      form: {
        blok: null,
        gudang_id: null,
      },
      dataGudang: [],
      listTerapis: [],
      perPage: 100,
      pageOptions: [100, 150, 200],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      dataExport: {
        konsumen_id: null,
        sales_id: null,
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "nama", label: "Konsumen" },
        // { key: "tanggal", label: "Tanggal Transaksi", sortable: true },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "action", label: "#" },
      ],
      fieldsTerapis: [
        {
          key: "no",
          label: "No",
        },
        { key: "nama_lengkap", label: "Nama Lengkap", sortable: true },
        { key: "no_hp", label: "No. HP" },
        { key: "alamat", label: "Alamat" },
        { key: "on_progress_orders", label: "Sedang Orderan" },
        { key: "completed_orders", label: "Selesai Orderan" },
        { key: "action", label: "#" },
      ],
      items: [],
      swalOptions: {
        title: "Pindahkan Stok Retur ke Mobil?",
        text: `Stok Barang Retur akan kembali ke Stok Mobil Anda`,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Retur Barang",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      },
      salesOptions: [],
      konsumenOptions: [],
      loading: false,
      loadingfoto: false,
      showKonfirmasi: false,
      formkonfirmasi: {
        no_invoice: null,
        bukti_tf: {
          file: null,
        },
      },
      konfirmasi: null,
      id_akun: [],
      id_kas: [],
      transactionType: null,
      debitAkun: [],
      kreditAkun: [],
      foto: null,
      status_bayar: null,
      optstatus_bayar: [
        { text: "Lunas", value: "lunas" },
        { text: "Belum Lunas", value: "belum_lunas" },
      ],
      statuskirim: null,
      optstatuskirim: [
        { value: "selesai", text: "Selesai" },
        { value: "diproses", text: "Diproses" },
        { value: "dikirim", text: "Dikirim" },
        { value: "dibatalkan", text: "Dibatalkan" },
        { value: "pending", text: "Pending" },
      ],
      typeTransaksi: null,
      opttypetransaksi: [
        { text: "Member", value: "member" },
        { text: "Non Member", value: "non-member" },
      ],
      member_id: null,
      is_accounted: null,
      optis_accounted: [
        { text: "Ya", value: 1 },
        // { text: 'Tidak', value: != 1 },
      ],
      fieldsdetail: [],
      masuk: null,
      optis_member: null,
      is_member: null,
      selected: null,
      selectMode: "single",
      modes: ["multi", "single", "range"],
      selecteditem: [],
      rangeDate: null,
      start_date: null,
      end_date: null,
      perPage2: 10,
      pageOptions2: [10, 30, 50],
      totalRows2: 1,
      currentPage2: 1,
      sortBy2: "",
      sortDesc2: false,
      sortDirection2: "asc",
      filter2: null,
      filterOn2: [],
      gudangOptions: [],
      data_awal: [],
      cekbar: [],
      cekbarundo: [],
      datascanitems: [],
      valueundo: [],
      datascanitemsundo: [],
      selectAll2: false,
      logisticNames: [],
      logisticNamesOptions: [],
    };
  },
  computed: {
    state() {
      // Return false (invalid) if new tag is a duplicate
      return this.value.indexOf(this.newTag.trim()) > -1 ? false : null;
    },
    stateundo() {
      // Return false (invalid) if new tag is a duplicate
      return this.valueundo.indexOf(this.newTagundo.trim()) > -1 ? false : null;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    sortOptions2() {
      // Create an options list from our fields
      return this.fieldsdetail
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    apiFile() {
      return "https://api-heykama.notive.my.id/";
    },
  },
  watch: {
    // logisticNames(newVal) {
    //   if (newVal.length === 0) {
    //     this.logisticNames = []; // Mengosongkan semua pilihan
    //     this.getTransaksi()
    //   } else {
    //     this.logisticNames = []
    //     this.logisticNames = newVal
    //     this.getTransaksi()
    //   }
    // },
    rangeDate(val) {
      const [start, end] = val.split(" to ");
      // Assign the values to your variables
      this.start_date = start;
      this.end_date = end;
      this.getTransaksi();
    },
    transactionType(val) {
      if (val) {
        if (val == 1) {
          this.id_akun = this.debitAkun;
        } else {
          this.id_akun = this.kreditAkun;
        }
      }
    },
    perPage(value) {
      if (value) {
        this.getTransaksi();
      }
    },
    // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getTransaksi();
    // }
    //},
    currentPage(val) {
      if (val) {
        this.getTransaksi();
        // this.items
      }
    },
  },
  methods: {
    toggleAll2() {
      if (this.selectAll2) {
        // Select all options
        this.logisticNames = this.logisticNamesOptions.map(
          (option) => option.value
        );
      } else {
        // Deselect all options
        this.logisticNames = [];
      }
    },
    updateSelectAll() {
      this.selectAll2 =
        this.logisticNames.length === this.logisticNamesOptions.length;
    },
    customLabel(selected) {
      if (selected.length === this.logisticNamesOptions.length)
        return "--ALL--";
      if (selected.length > 0) return `${selected.length} selected`;
      return "-Select separately-";
    },
    toggleOption(value) {
      if (this.logisticNames.includes(value)) {
        this.logisticNames = this.logisticNames.filter(
          (item) => item !== value
        );
      } else {
        this.logisticNames.push(value);
      }
    },
    toggle(checked) {
      if (checked) {
        this.logisticNames = []; // Pilih semua opsi
        // this.logisticNames = []
      } else {
        this.logisticNames = []; // Kosongkan semua pilihan
      }
    },
    getGineeLogistic() {
      // this.loading = true
      this.$store
        .dispatch("ginee/getLogistic")
        .then((res) => {
          // console.log(res.data.data)
          this.logisticNamesOptions = res.data.data;
          // this.ginee = res.data.data;
          // this.totalRows = res.data.data.length;
          // this.loading = false
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
          this.loading = false;
        });
    },
    saveScanundo() {
      this.loading = true;
      this.$store
        .dispatch("outbond/undoScan", { items: this.datascanitemsundo })
        .then(() => {
          this.loading = false;
          // this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil scan",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.loading = false;
          // this.label = "Submit";
          this.displayError(e);
          return false;
        });
    },
    saveScan() {
      this.loading = true;
      this.$store
        .dispatch("outbond/tandaiScan", { items: this.datascanitems })
        .then(() => {
          this.loading = false;
          // this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil scan",
          });
          this.cekbar = [];
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.loading = false;
          // this.label = "Submit";
          this.displayError(e);
          return false;
        });
    },
    updateQty(tag) {
      // Temukan item dalam cekbar yang cocok berdasarkan text
      const item = this.cekbar.find((item) => item.text === tag.text);

      // Temukan item dalam items yang cocok berdasarkan barcode atau kode
      const item2 = this.items.find((item) => item.item_data.kode === tag.text);
      //   const barcodeText =
      //   return barcodeText === tag.text || item.item_data.kode === tag.text;
      // });

      // Perbarui qty di cekbar jika ditemukan
      if (item) {
        item.qty = tag.qty; // Update qty di cekbar
      }

      // Perbarui qtyScanned dan scanned di items jika ditemukan
      if (item2) {
        item2.item_data.qtyScanned = tag.qty;
        item2.item_data.scanned = this.getScannedStatus(item2); // Perbarui status yang dipindai
      }

      // Urutkan ulang this.items menggunakan metode this.sorting()
      this.sorting();
    },
    updateQtymin(tag) {
      // Temukan item dalam cekbar yang cocok berdasarkan text
      const item = this.cekbar.find((item) => item.text === tag.text);

      // Temukan item dalam items yang cocok berdasarkan barcode atau kode
      const item2 = this.items.find((item) => item.item_data.kode === tag.text);

      // Perbarui qty di cekbar jika ditemukan
      if (item) {
        item.qty = tag.qty == 0 ? (tag.qty = 0) : tag.qty - 1; // Update qty di cekbar
      }
      this.cekbar = [...this.cekbar];

      // Perbarui qtyScanned dan scanned di items jika ditemukan
      if (item2) {
        item2.item_data.qtyScanned = item.qty;
        item2.item_data.scanned = this.getScannedStatus(item2); // Perbarui status yang dipindai
      }
      // this.items = [...this.items]

      // Urutkan ulang this.items menggunakan metode this.sorting()
      this.sorting();
    },
    updateQtyplus(tag) {
      // Temukan item dalam cekbar yang cocok berdasarkan text
      const item = this.cekbar.find((item) => item.text === tag.text);

      // Temukan item dalam items yang cocok berdasarkan barcode atau kode
      const item2 = this.items.find((item) => item.item_data.kode === tag.text);

      // Perbarui qty di cekbar jika ditemukan
      if (item) {
        item.qty = tag.qty == 0 ? (tag.qty = 0) : parseInt(tag.qty) + 1; // Update qty di cekbar
      }
      this.cekbar = [...this.cekbar];

      // Perbarui qtyScanned dan scanned di items jika ditemukan
      if (item2) {
        item2.item_data.qtyScanned = item.qty;
        item2.item_data.scanned = this.getScannedStatus(item2); // Perbarui status yang dipindai
      }
      // this.items = [...this.items]

      // Urutkan ulang this.items menggunakan metode this.sorting()
      this.sorting();
    },

    updateQtyform(tag) {
      // Temukan item dalam cekbar yang cocok berdasarkan text
      const item = this.cekbar.find((item) => item.text === tag.text);

      // Temukan item dalam items yang cocok berdasarkan barcode atau kode
      const item2 = this.items.find((item) => item.item_data.kode === tag.text);

      // Perbarui qty di cekbar jika ditemukan
      if (item) {
        item.qty = tag.qty; // Update qty di cekbar
      }

      // Perbarui qtyScanned dan scanned di items jika ditemukan
      if (item2) {
        item2.item_data.qtyScanned = item.qty;
        item2.item_data.scanned = this.getScannedStatus(item2); // Perbarui status yang dipindai
      }

      // Urutkan ulang this.items menggunakan metode this.sorting()
      this.sorting();
    },
    sorting() {
      const statusOrder = ["incomplete", "none", "complete", "exceed"];

      // Fungsi untuk mendapatkan indeks status
      const getStatusIndex = (status) => {
        return statusOrder.indexOf(status); // Pastikan menggunakan parameter 'status'
      };

      // Mengurutkan array berdasarkan scanned dan qtyScanned
      this.items.sort((a, b) => {
        const indexA = getStatusIndex(a.item_data.scanned);
        const indexB = getStatusIndex(b.item_data.scanned);

        // Jika status tidak ditemukan, anggap sebagai urutan terakhir
        const adjustedIndexA = indexA === -1 ? statusOrder.length : indexA;
        const adjustedIndexB = indexB === -1 ? statusOrder.length : indexB;

        // Urutkan berdasarkan status terlebih dahulu
        if (adjustedIndexA !== adjustedIndexB) {
          return adjustedIndexA - adjustedIndexB;
        }

        // Jika status sama, urutkan berdasarkan qtyScanned dari terbesar ke terkecil
        return b.item_data.qtyScanned - a.item_data.qtyScanned;
      });

      return this.items;
    },
    cekbarcode(cek) {
      // cek.qty = parseInt(cek.qty)
      // this.cekbar = cek
      const found = this.items.some(
        (item) =>
          cek.includes(item.item_data.sku) ||
          cek.includes(item.item_data.no_batch)
      );
      const foundcode = this.items.some((item) =>
        cek.includes(item.item_data.kode)
      );

      // Cek apakah item dengan text yang sama sudah ada di cekbar
      const existingItem = this.cekbar.find((item) => item.text === cek);

      if (existingItem) {
        // Jika item sudah ada, tambahkan qty
        existingItem.qty = (parseInt(existingItem.qty) || 1) + 1;

        // Cek apakah qty sama dengan qtyScanned
        if (foundcode) {
          this.displaySuccess({
            message: `Produk ${cek} tersedia dan duplikat`,
          });
          this.playSoundBenar2();
          this.updateScannedQuantity(cek);
          this.resetInputValue();
          this.sorting();
        } else {
          this.displayWarning({
            message: `Produk ${cek} tidak tersedia dan duplikat`,
          });
          this.playSoundSalah2();
          this.resetInputValue();
          this.sorting();
        }
      } else {
        // Jika item belum ada, tambahkan item baru dengan qty = 1
        if (foundcode) {
          this.displaySuccess({
            message: `Produk ${cek} tersedia`,
          });
          this.playSoundBenar();
          this.cekbar.push({
            text: cek,
            value: "ada",
            variant: "warning",
            icon: "CheckCircleIcon",
            qty: 1,
          });
          this.updateScannedQuantity(cek);
          this.resetInputValue();
          this.sorting();
          // console.log("Item dengan SKU yang dicari ditemukan!");
        } else {
          this.cekbar.push({
            text: cek,
            value: "tidak ada",
            icon: "XCircleIcon",
            qty: 1,
          });
          this.displayWarning({
            message: `Produk ${cek} tidak tersedia`,
          });
          this.playSoundSalah();
          this.resetInputValue();
          this.sorting();
          // console.log("Item dengan SKU yang dicari tidak ditemukan.");
        }
      }
      // console.log('CCEL', this.cekbar)
      // console.log('CCELITEM', this.selecteditem)
    },
    // Function to update scanned quantity
    updateScannedQuantity(cek) {
      // let currentIndex = 0
      this.items.map((item) => {
        // Periksa apakah item cocok dengan salah satu identifier di cek
        if (
          cek.includes(item.item_data.sku) ||
          cek.includes(item.item_data.no_batch) ||
          cek.includes(item.item_data.kode)
        ) {
          item.item_data.qtyScanned =
            (parseInt(item.item_data.qtyScanned) || 0) + 1; // Tambahkan jumlah yang sudah discan
          item.item_data.scanned = this.getScannedStatus(item); // Tetapkan status yang sudah discan

          let unscannedItem = Array.isArray(item.item_data?.order_details)
            ? item.item_data.order_details.find(
                (detail) => detail.is_scanned === false
              ) // Ambil item pertama yang belum discan
            : item.kartu_resep_datas.find(
                (detail) => detail.is_scanned === false
              );
          if (unscannedItem) {
            unscannedItem.is_scanned = true; // Tandai item pertama sebagai sudah discan
          } else {
            // Jika tidak ditemukan di order_details, cari di item.kartu_resep_datas
            if (Array.isArray(item.kartu_resep_datas)) {
              // Memeriksa apakah ada item yang belum discan di kartu_resep_datas
              unscannedItem = item.kartu_resep_datas.find(
                (detail) => detail.is_scanned === false
              );

              // Jika item ditemukan di kartu_resep_datas, ubah menjadi sudah discan
              if (unscannedItem) {
                unscannedItem.is_scanned = true; // Tandai item pertama di kartu_resep_datas sebagai sudah discan
              }
            } else {
              console.log("item.kartu_resep_datas bukan array atau kosong");
            }
          }
          // console.log('COBA items', unscannedItem);
          // Menambahkan item ke array 'items'
          if (unscannedItem) {
            if (unscannedItem.item_id) {
              this.datascanitems.push({
                item_source: unscannedItem.item_source,
                item_id: unscannedItem.item_id,
                qty: 1,
              });
            } else {
              this.datascanitems.push({
                item_source: unscannedItem.item_source,
                kartu_resep_item_id: unscannedItem.kartu_resep_id,
                qty: 1,
              });
            }
          }
          // console.log('COBA items', this.datascanitems); // Cetak data yang telah dibentuk

          // Cetak data yang telah dibentuk

          // this.$refs.vkonfirmasi.validate().then((success) => {
          //   if (success) {
          // this.label = "Loading...";

          // this.$store
          //   .dispatch("outbond/tandaiScan", { items })
          //   .then(() => {
          //     // this.showKonfirmasi = false;
          //     this.displaySuccess({
          //       text: "Berhasil scan",
          //     });
          //     this.getTransaksi();
          //     // this.formKonfirmasi = null
          //   })
          //   .catch((e) => {
          //     // this.label = "Submit";
          //     this.displayError(e);
          //     return false;
          //   });
          //   }
          // });
        }
        // });
      });
      // Cari data order_details yang is_scanned = false secara berurutan
    },

    // Function to determine scanned status
    getScannedStatus(item) {
      console.log("CEK ITEM:", item);
      const { scanned_qty, qty, qtyScanned, quantity } = item.item_data;
      const totalQty = scanned_qty + (qtyScanned ? parseInt(qtyScanned) : 0);

      // console.log('CEK totalQty:', totalQty); // Menambahkan log untuk memeriksa nilai totalQty

      if (qty) {
        // console.log('CEK qty:', qty, scanned_qty, qtyScanned, totalQty);
        if (qty === totalQty) {
          this.playSoundSesuai();
          const foundItem = this.cekbar.find(
            (cek) => cek.text === item.item_data.kode
          );
          if (foundItem) {
            foundItem.variant = "success";
            console.log("Item ditemukan dan diubah:", foundItem);
          } else {
            console.log("Item tidak ditemukan.");
          }
          return "complete"; // All quantities scanned
        } else if (totalQty < qty) {
          const foundItem = this.cekbar.find(
            (cek) => cek.text === item.item_data.kode
          );
          if (foundItem) {
            foundItem.variant = "warning";
            console.log("Item ditemukan dan diubah:", foundItem);
          } else {
            console.log("Item tidak ditemukan.");
          }
          return "incomplete"; // Partial quantities scanned
        } else if (totalQty > qty) {
          this.playSoundLebih();
          const foundItem = this.cekbar.find(
            (cek) => cek.text === item.item_data.kode
          );
          if (foundItem) {
            foundItem.variant = "danger";
            console.log("Item ditemukan dan diubah:", foundItem);
          } else {
            console.log("Item tidak ditemukan.");
          }
          return "exceed"; // Partial quantities scanned
        } else {
          return "none"; // No quantities scanned
        }
      } else if (quantity) {
        console.log("CEK quantity:", quantity);
        if (quantity === totalQty) {
          this.playSoundSesuai();
          const foundItem = this.cekbar.find(
            (cek) => cek.text === item.item_data.kode
          );
          if (foundItem) {
            foundItem.variant = "success";
            console.log("Item ditemukan dan diubah:", foundItem);
          } else {
            console.log("Item tidak ditemukan.");
          }
          return "complete"; // All quantities scanned
        } else if (totalQty < quantity) {
          const foundItem = this.cekbar.find(
            (cek) => cek.text === item.item_data.kode
          );
          if (foundItem) {
            foundItem.variant = "warning";
            console.log("Item ditemukan dan diubah:", foundItem);
          } else {
            console.log("Item tidak ditemukan.");
          }
          return "incomplete"; // Partial quantities scanned
        } else if (totalQty > quantity) {
          this.playSoundLebih();
          const foundItem = this.cekbar.find(
            (cek) => cek.text === item.item_data.kode
          );
          if (foundItem) {
            foundItem.variant = "danger";
            console.log("Item ditemukan dan diubah:", foundItem);
          } else {
            console.log("Item tidak ditemukan.");
          }
          return "exceed"; // Partial quantities scanned
        } else {
          return "none"; // No quantities scanned
        }
      } else {
        console.log("No qty or quantity available");
      }
    },
    cekbarcodeundo(cek) {
      // this.cekbar = cek
      const found = this.items.some(
        (item) =>
          cek.includes(item.item_data.sku) ||
          cek.includes(item.item_data.no_batch)
      );
      const foundcode = this.items.some((item) =>
        cek.includes(item.item_data.kode)
      );

      // Cek apakah item dengan text yang sama sudah ada di cekbar
      const existingItem = this.cekbarundo.find((item) => item.text === cek);

      if (existingItem) {
        // Jika item sudah ada, tambahkan qty
        existingItem.qty = (existingItem.qty || 1) + 1;

        // Cek apakah qty sama dengan qtyScanned
        if (foundcode) {
          this.displaySuccess({
            message: `Produk ${cek} tersedia dan duplikat`,
          });
          this.playSoundBenar2();

          // Cek apakah barcode sudah ada di datascanitemsundo
          const scannedItem = this.datascanitemsundo.find(
            (item) => item.barcode === cek
          );
          if (scannedItem) {
            // Jika sudah ada, tambahkan qty
            scannedItem.qty += 1;
          } else {
            // Jika belum ada, tambahkan item baru
            this.datascanitemsundo.push({
              barcode: cek,
              action: "minus",
              qty: 1,
            });
          }

          console.log("undo", this.datascanitemsundo);
          this.resetInputValue();
          this.sorting();
        } else {
          this.displayWarning({
            message: `Produk ${cek} tidak tersedia dan duplikat`,
          });
          this.playSoundSalah2();
          this.resetInputValue();
          this.sorting();
        }
      } else {
        // Jika item belum ada, tambahkan item baru dengan qty = 1
        if (foundcode) {
          this.displaySuccess({
            message: `Produk ${cek} tersedia`,
          });
          this.playSoundBenar();
          this.datascanitemsundo.push({
            barcode: cek,
            action: "minus",
            qty: 1,
          });
          this.cekbarundo.push({
            text: cek,
            value: "ada",
            icon: "CheckCircleIcon",
            qty: 1,
          });
          // this.updateScannedQuantityundo(cek);
          this.resetInputValue();
          this.sorting();
          // console.log("Item dengan SKU yang dicari ditemukan!");
        } else {
          this.cekbarundo.push({
            text: cek,
            value: "tidak ada",
            icon: "XCircleIcon",
            qty: 1,
          });
          this.displayWarning({
            message: `Produk ${cek} tidak tersedia`,
          });
          this.playSoundSalah();
          this.resetInputValue();
          this.sorting();
          // console.log("Item dengan SKU yang dicari tidak ditemukan.");
        }
      }
      // console.log('CCEL', this.cekbar)
      // console.log('CCELITEM', this.selecteditem)
    },
    // Function to update scanned quantity
    updateScannedQuantityundo(cek) {
      // let currentIndex = 0
      this.items.map((item) => {
        // Periksa apakah item cocok dengan salah satu identifier di cek
        if (
          cek.includes(item.item_data.sku) ||
          cek.includes(item.item_data.no_batch) ||
          cek.includes(item.item_data.kode)
        ) {
          item.item_data.qtyScanned = (item.item_data.qtyScanned || 0) + 1; // Tambahkan jumlah yang sudah discan
          item.item_data.scanned = this.getScannedStatus(item); // Tetapkan status yang sudah discan

          let unscannedItem = Array.isArray(item.item_data?.order_details)
            ? item.item_data.order_details.find(
                (detail) => detail.is_scanned === false
              ) // Ambil item pertama yang belum discan
            : item.kartu_resep_datas.find(
                (detail) => detail.is_scanned === false
              );
          if (unscannedItem) {
            unscannedItem.is_scanned = true; // Tandai item pertama sebagai sudah discan
          } else {
            // Jika tidak ditemukan di order_details, cari di item.kartu_resep_datas
            if (Array.isArray(item.kartu_resep_datas)) {
              // Memeriksa apakah ada item yang belum discan di kartu_resep_datas
              unscannedItem = item.kartu_resep_datas.find(
                (detail) => detail.is_scanned === false
              );

              // Jika item ditemukan di kartu_resep_datas, ubah menjadi sudah discan
              if (unscannedItem) {
                unscannedItem.is_scanned = true; // Tandai item pertama di kartu_resep_datas sebagai sudah discan
              }
            } else {
              console.log("item.kartu_resep_datas bukan array atau kosong");
            }
          }
          // console.log('COBA items', unscannedItem);
          // Menambahkan item ke array 'items'
          if (unscannedItem) {
            if (unscannedItem.item_id) {
              this.datascanitemsundo.push({
                barcode: cek.text,
                action: "minus",
                qty: cek.qty,
              });
            } else {
              this.datascanitemsundo.push({
                barcode: cek.text,
                kartu_resep_item_id: unscannedItem.kartu_resep_id,
                qty: 1,
              });
            }
          }
        }
      });
      // Cari data order_details yang is_scanned = false secara berurutan
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;

      if (item.item_data.scanned === "complete") {
        return "table-success"; // Green for complete
      } else if (item.item_data.scanned === "incomplete") {
        return "table-warning"; // Yellow for incomplete
      } else if (item.item_data.scanned === "exceed") {
        return "table-danger"; // Red for incomplete
      } else {
        return "table-default"; // Default/white for none
      }
    },
    removecekbar(tagToRemove) {
      // Cari indeks dari tag yang ingin dihapus
      const index = this.cekbar.findIndex(
        (tag) => tag.text === tagToRemove.text
      );

      // Jika tag ditemukan, hapus item pada indeks tersebut
      if (index !== -1) {
        this.cekbar.splice(index, 1);

        // Perbarui this.items sesuai perubahan di cekbar
        // Temukan item dalam cekbar yang cocok berdasarkan text

        // Temukan item dalam items yang cocok berdasarkan barcode atau kode
        const item2 = this.items.find(
          (item) => item.item_data.kode === tagToRemove.text
        );

        // Perbarui qtyScanned dan scanned di items jika ditemukan
        if (item2) {
          item2.item_data.qtyScanned = 0;
          item2.item_data.scanned = this.getScannedStatus(item2); // Perbarui status yang dipindai
        }

        // Urutkan ulang this.items menggunakan metode this.sorting()
        this.sorting();
      }
    },
    removecekbarundo(tagToRemove) {
      // Cari indeks dari tag yang ingin dihapus
      const index = this.cekbarundo.findIndex(
        (tag) => tag.text === tagToRemove.text
      );

      // Jika tag ditemukan, hapus item pada indeks tersebut
      if (index !== -1) {
        this.cekbarundo.splice(index, 1);
      }
    },
    resetInputValue() {
      this.newTag = "";
      this.value = [];
      this.newTagundo = "";
      this.valueundo = [];
    },
    formatter(value) {
      return value.toUpperCase();
    },
    playSoundBenar() {
      var data = "/assets/sound/correct.mp3";
      var audio = new Audio(data);
      audio.play();
    },
    playSoundBenar2() {
      var data = "/assets/sound/correct2.mp3";
      var audio = new Audio(data);
      audio.play();
    },
    playSoundSalah() {
      var data = "/assets/sound/incorrect.mp3";
      var audio = new Audio(data);
      audio.play();
    },
    playSoundSalah2() {
      var data = "/assets/sound/incorrect2.mp3";
      var audio = new Audio(data);
      audio.play();
    },
    playSoundLebih() {
      var data = "/assets/sound/exceed.mp3";
      var audio = new Audio(data);
      audio.play();
    },
    playSoundSesuai() {
      var data = "/assets/sound/appropriate.mp3";
      var audio = new Audio(data);
      audio.play();
    },
    todaydate() {
      const date = new Date(); // contoh: Mon Oct 28 2024 13:02:47 GMT+0700 (Western Indonesia Time)

      // Mendapatkan tahun, bulan, dan tanggal
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Tambah 1 karena bulan dimulai dari 0
      const day = String(date.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      this.rangeDate = formattedDate;
      // this.start_date = formattedDate
      // this.end_date = formattedDate
      // this.getTransaksi()
    },
    seventodaydate() {
      const today = new Date();

      // Mengatur tanggal mulai sebagai 7 hari yang lalu
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 7);

      // Format tanggal ke 'YYYY-MM-DD'
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      this.rangeDate = formatDate(sevenDaysAgo) + " to " + formatDate(today);
    },
    tigapuluhtodaydate() {
      const today = new Date();

      // Mengatur tanggal mulai sebagai 7 hari yang lalu
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 30);

      // Format tanggal ke 'YYYY-MM-DD'
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      this.rangeDate = formatDate(sevenDaysAgo) + " to " + formatDate(today);
    },
    onRowSelected(hem) {
      let cek = hem[0];
      this.selected = cek;
      // if (this.selected.type == 'ginee') {
      this.selecteditem = cek
        ? cek
          ? cek.items
            ? cek.items
            : cek.item
          : []
        : [];
      this.fieldsdetail = cek
        ? cek
          ? cek.items
            ? [
                { key: "masterSku", label: "SKU" },
                { key: "quantity", label: "Qty" },
              ]
            : [
                { key: "kode", label: "Barcode / SKU" },
                { key: "barang_nama", label: "SKU" },
                { key: "qty", label: "Qty" },
                { key: "keterangan", label: "Keterangan" },
              ]
          : []
        : [];
      // this.fieldsdetail = [
      //   { key: "masterSku", label: "SKU" },
      //   { key: "quantity", label: "Qty" },
      // ]
      //   console.log('ginee', this.selecteditem)
      // }
      // if (this.selected.type == 'wms') {
      //   this.selecteditem = cek.data.item
      // this.fieldsdetail = [
      //   { key: "barang_nama", label: "SKU" },
      //   { key: "qty", label: "Qty" },
      // ]
      //   console.log('wms', this.selecteditem)
      // }

      // if (cek.type == 'ginee') {
      //   this.selected = null
      //   this.selected = []
      //   this.selected = cek.data.items
      //   console.log('ginee', this.selected)
      //   this.fieldsdetail = [
      //     { key: "masterSku", label: "SKU" },
      //     { key: "quantity", label: "Qty" },
      //   ]
      // } else if (cek.type == 'wms') {
      //   this.selected = null
      //   this.selected = []
      //   this.selected = cek.data.item
      //   console.log('wms', this.selected)
      //   this.fieldsdetail = [
      //     { key: "masterSku", label: "SKU" },
      //     { key: "quantity", label: "Qty" },
      //   ]
      // } else {
      //   this.selected = null
      //   this.selected = []
      //   return
      // }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },
    submitkonfirmasi2() {
      // this.$refs.vkonfirmasi.validate().then((success) => {
      //   if (success) {
      this.label = "Loading...";
      let payload2 = {
        no_invoice: this.formkonfirmasi.no_invoice,
      };
      this.$store
        .dispatch("outbond/tandaiLunas", payload2)
        .then(() => {
          this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil dikonfirmasi",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.label = "Submit";
          this.displayError(e);
          return false;
        });
      //   }
      // });
    },
    submitkonfirmasi() {
      // this.$refs.vkonfirmasi.validate().then((success) => {
      //   if (success) {
      this.label = "Loading...";
      // let join = this.pilihteritori;
      // let string = join.join(",");
      // var array = JSON.parse("[" + string + "]");
      let payload = {
        no_invoice: this.formkonfirmasi.no_invoice,
        // nama_cabang: this.form.nama_cabang,
        akun_id: this.formkonfirmasi.id_akun,
        kas_id: this.formkonfirmasi.id_kas,
      };
      let payload2 = {
        no_invoice: this.formkonfirmasi.no_invoice,
      };
      this.$store
        .dispatch("outbond/tandaiLunas", payload2)
        .then(() => {
          this.showKonfirmasi = false;
          this.displaySuccess({
            text: "Berhasil dikonfirmasi",
          });
          this.getTransaksi();
          // this.formKonfirmasi = null
        })
        .catch((e) => {
          this.label = "Submit";
          this.displayError(e);
          return false;
        });
      //   }
      // });
    },
    aksiKonfirmasiawal(item) {
      this.konfirmasi = item.item_data.id;
      this.formkonfirmasi = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
        bukti_tf: {
          file: item.bukti_tf ? item.bukti_tf.file : null,
        },
      };
      // this.submitkonfirmasi2()
      // console.log('AJI', this.foto)
      this.getTransaksiId();
      this.showKonfirmasi = true;
    },
    aksiKonfirmasipg(item) {
      // this.konfirmasi = item.id;
      // this.formkonfirmasi = {
      //   no_invoice: item.no_invoice ? item.no_invoice : "-",
      //   bukti_tf: {
      //     file: item.bukti_tf ? item.bukti_tf.file : null,
      //   },
      // };
      let payload = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
      };
      // this.$swal({
      //   title: "Anda yakin?",
      //   text: `Invoice : ${terapis.nama_lengkap} ini ditandai lunas?`,
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "Ya",
      //   cancelButtonText: "Batal",
      //   customClass: {
      //     confirmButton: "btn btn-success",
      //     cancelButton: "btn btn-danger ml-1",
      //   },
      //   buttonsStyling: false,
      // }).then((result) => {
      //   if (result.value) {
      this.$store
        .dispatch("outbond/getDatapg", payload)
        .then((res) => {
          // this.formkonfirmasi = res;
          this.getTransaksi();
        })
        .catch((err) => {
          this.displayError(err);
          return false;
        });
      //   }
      // });
    },
    aksiKonfirmasi(item) {
      this.konfirmasi = item.id;
      this.formkonfirmasi = {
        no_invoice: item.no_invoice ? item.no_invoice : "-",
        bukti_tf: {
          file: item.bukti_tf ? item.bukti_tf.file : null,
        },
      };
      this.submitkonfirmasi2();
      // console.log('AJI', this.foto)
      // this.getTransaksiId();
      // this.showKonfirmasi = true;
    },
    getTransaksiId(item) {
      this.loadingfoto = true;
      let params = {
        id: this.konfirmasi,
        show_bukti_tf: 1,
        view_as_invoice: 1,
      };
      this.$store
        .dispatch("outbond/getDataById", params)
        .then((res) => {
          // let items = JSON.parse(JSON.stringify(this.$store.state.outbond.datas));
          // let items_total = this.$store.state.outbond.totals;
          this.formkonfirmasi = res;
          // this.totalRows = items_total;
          this.loadingfoto = false;
          // this.items = res;
        })
        .catch((err) => {
          // console.error(err);
          this.loadingfoto = false;
          this.displayError(err);
          return false;
        });
    },
    async exportSales({ tgl_awal, tgl_akhir, type_export }) {
      if (!tgl_awal || !tgl_akhir) {
        this.displayError({
          message: "Harap isi tanggal awal dan akhir!",
        });
        return false;
      }

      // validate type_export pdf || excel
      const params = {
        tgl_awal,
        tgl_akhir,
        type_export,
      };
      if (this.isSales && this.myGudang)
        params.sales_id =
          this.user && this.user.karyawan ? this.user.karyawan.id : null;
      if (this.dataExport.konsumen_id)
        params.konsumen_id = this.dataExport.konsumen_id;
      if (this.dataExport.sales_id) params.sales_id = this.dataExport.sales_id;

      try {
        this.loading = true;
        const response = await this.printSales(params);
        this.loading = false;
        if (type_export == "pdf") {
          window.open(URL.createObjectURL(response));
        } else {
          await window.open(response);
          setTimeout(async () => {
            // delete selected file
            const arrFile = response.split("/");
            const filename = arrFile[arrFile.length - 1];

            await this.clearExportedFile(filename);
          }, 1000);
        }
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.displayError(e);
        return false;
      }
    },

    getGudang() {
      this.$store
        .dispatch("gudang/getData", { jenis: 1 })
        .then(() => {
          this.gudangOptions = this.$store.state.gudang.datas;
          this.gudangOptions.map((hem) => {
            hem.value = hem.iad;
            hem.text = hem.nama_gudang;
          });
          this.getGineeLogistic();
          this.getTransaksi();
          // this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getTransaksi() {
      // console.log('TGL', this.start_date)
      this.loading = true;
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      var params = {
        search: this.filter != null ? this.filter : null,
        start: currentPage,
        length: this.perPage,
        sortByLogisticNames:
          this.logisticNames.length > 0 ? this.logisticNames : null,
        start_date: this.start_date ? this.start_date : null,
        end_date: this.end_date ? this.end_date : null,
        id_gudang: this.myGudang
          ? this.myGudang.id
            ? this.myGudang.id
            : this.myGudang.data.id
          : this.gudangOptions[0].id,
      };
      this.$store
        .dispatch("outbond/getDataItems", params)
        .then((res) => {
          this.loading = false;

          let hem = res.data;

          // const orderDetails = hem.flatMap((entry) => {
          //   // Pastikan order_details adalah array sebelum memanggil .map
          //   if (Array.isArray(entry.item_data.order_details)) {
          //     return entry.item_data.order_details.map((detail) => ({
          //       item_source: "transaksi-item",
          //       item_id: detail.item_id,
          //       qty: detail.qty,
          //     }));
          //   }
          //   if (Array.isArray(entry.kartu_resep_datas)) {
          //     return entry.kartu_resep_datas.map((detail) => ({
          //       item_source: "kartu-resep",
          //       kartu_resep_item_id: detail.kartu_resep_id,
          //       qty: detail.qty,
          //     }));
          //   }
          //   // Jika order_details bukan array, kembalikan array kosong
          //   return [];
          // });

          // // Output
          // console.log('coba', { items: orderDetails });
          // const modifiedDataArray = []

          hem.forEach((item) => {
            item.item_data.qtyScanned = 0;
            // item.item_data.scanned_qty = item.scanned_qty;
            item.item_data.scanned = this.getScannedStatus(item);
          });
          this.items = hem;
          // this.sorting()

          // this.data_awal = JSON.parse(JSON.stringify(this.$store.state.outbond.datas));
          // let items_total = this.$store.state.outbond.totals;
          // const modifiedDataArray = []

          // this.items = Object.values(uniqueItems);
          // console.log('Ikhsan', this.items)
          // this.items.map(obj => ({
          //   ...obj,
          //   ...obj.item_data,
          //   // Memindahkan semua properti dari 'data' ke tingkat atas
          // }));
          // return

          // console.log('CEK', modifiedDataArray)
          // this.items = hem;
          // this.totalRows = items_total;
          // this.masuk = items_masuk;
          // this.items = res;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.displayError(err);
          return false;
        });
    },
    getKas() {
      this.$store
        .dispatch("kas/getData", {})
        .then(() => {
          let hem = JSON.parse(JSON.stringify(this.$store.state.kas.datas));
          hem.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });
          this.id_kas = hem;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    getAkun() {
      this.$store
        .dispatch("akun/getData", {})
        .then(() => {
          let akuns = JSON.parse(JSON.stringify(this.$store.state.akun.datas));
          akuns.map((item) => {
            item.value = item.id;
            item.text =
              item.saldo >= 0
                ? item.nama + " -> " + this.formatRupiah(item.saldo)
                : item.nama + "( " + this.formatRupiah(item.saldo * -1) + " )";
          });

          this.debitAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.debit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
          this.kreditAkun = akuns.filter((akun) =>
            [this.JENIS_AKUN.kredit, this.JENIS_AKUN.debit_kredit].includes(
              akun.jenis
            )
          );
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFiltered2(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems.length;
      this.currentPage2 = 1;
    },
    getMember() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        search: this.filter != null ? this.filter : null,
        sebagai: "cabang",
        // order: "desc",
        // start: currentPage,
        // length: this.perPage,
        // filter_by: "nama_lengkap",
        // category_paket_id: this.filterKatPaket != null ? this.filterKatPaket.id : null,
        // paket_id: this.filterPaket != null ? this.filterPaket.id : null,
        // jadwal_id: this.filterJadwal != null ? this.filterJadwal.id : null,
        // user_id: this.user.id,
      };
      this.$store.dispatch("member/getData", payload).then(() => {
        let items = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        items.map((item) => {
          item.value = item.id;
          item.text = item.nama_lengkap;
        });
        this.optis_member = items;
        // this.items = this.$store.state.member.datas;
        // this.totalRows = this.items.length;
      });
    },
    getTerapis() {
      this.$store
        .dispatch("terapis/getData")
        .then((res) => {
          this.listTerapis = res;
        })
        .catch((error) => {
          console.error("Error fetching terapis data:", error);
        });
    },
    modalTerapis(item) {
      this.itemTransaksiTerapis = item;
      this.showModalTerapis = true;
    },
    pilihTerapis(terapis) {
      this.$swal({
        title: "Anda yakin?",
        text: `Apakah yakin untuk memilih ${terapis.nama_lengkap}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingTerapis = true;
          let payload = {
            transaksi_id: this.itemTransaksiTerapis.id,
            karyawan_id: terapis.id,
          };
          this.$store
            .dispatch("outbond/saveTerapis", payload)
            .then(() => {
              this.displaySuccess({
                text: "Terapis Berhasil dipilih",
              });
              this.loadingTerapis = false;
              this.showModalTerapis = false;
              this.itemTransaksiTerapis = {};
              this.getTransaksi();
              this.getTerapis();
            })
            .catch((e) => {
              this.loadingTerapis = false;
              this.showModalTerapis = false;
              this.itemTransaksiTerapis = {};
              this.displayError(e);
              return false;
            });
        }
      });
    },
  },
  mounted() {
    // Automatically focus the input field when the component is mounted
    // this.$refs.autoFocusInput.focus();
    if (this.$refs.autoFocusInput) {
      this.$refs.autoFocusInput.focus();
    }
    // this.getGineeLogistic()
  },
  created() {
    // this.$refs.autoFocusInput.focus();
    // this.getMember();
    this.getGudang();
    // this.getKas();
    // this.getAkun();
    // this.getTerapis();
    if (!this.isFinance) {
      this.fields = [
        // { key: "selected", label: '#' },
        {
          key: "no",
          label: "No",
        },
        // { key: "type", label: "From", sortable: true },
        { key: "barcode", label: "Barcode", sortable: true },
        { key: "item_data", label: "Product", sortable: true },
        { key: "quantity", label: "Qty", sortable: true },
        {
          key: "scanned_qty",
          label: "Previously Saved Scan Qty",
          sortable: true,
        },
        { key: "qtyScanned", label: "Qty Scanned", sortable: true },
        { key: "scanned", label: "status", sortable: true },
        // { key: "member_id", label: "Affiliator" },
        // { key: "nama", label: "Konsumen" },
        // { key: "total_bayar", label: "Nominal" },
        // { key: "transaction_type", label: "Tipe Transaksi" },
        // { key: "status_bayar", label: "Status Bayar" },
        // { key: "logisticsTrackingNumber", label: "Status WMS" },
        // { key: "orderStatus", label: "Status Ginee" },
        // { key: "type", label: "Type" },
        // { key: "action", label: "#" },
      ];
    } else if (this.isFinance) {
      this.fields = [
        { key: "actionFinance", label: "#", stickyColumn: true },
        {
          key: "no",
          label: "No",
        },
        { key: "waktu_transaksi", label: "Tanggal Transaksi", sortable: true },
        { key: "no_invoice", label: "No. Invoice", sortable: true },
        { key: "member_id", label: "Affiliator" },
        // { key: "nama", label: "Konsumen" },
        { key: "status_bayar", label: "Status Bayar" },
        { key: "status", label: "Status Pengiriman" },
        { key: "uang_masuk", label: "Uang Masuk" },
        { key: "total_bayar", label: "Total Bayar" },
        { key: "transaction_type", label: "Tipe Transaksi" },
        { key: "payment_option", label: "Metode Pembayaran" },
      ];
    }

    // this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
