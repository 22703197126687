<template>
  <b-overlay :show="loading" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <feather-icon icon="ClockIcon" size="24" />
        <p id="cancel-label">Please wait...</p>
      </div>
    </template>
    <b-card-actions action-collapse ref="refreshCard" title="Stok Opname">
      <b-row v-if="allowCreate()">
        <b-col md="2" sm="4" class="my-1">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="add()">
            <!-- @click.prevent="$router.push({name: 'stock-opname-add'})" -->
            <feather-icon icon="PlusIcon" class="mr-50" />
            {{ $t('Add') }}
          </b-button>
        </b-col>
      </b-row>
      <b-modal v-if="allowCreate() || allowUpdate()" v-model="showModal" size="lg" id="modal-" ok-title="Tutup"
        ok-variant="secondary" ok-only centered title="Form ">
        <validation-observer ref="formanalisis">
          <b-form>
            <b-row>
              <!-- Tanggal Ajuan -->
              <b-col cols="12">
                <b-form-group label="Tanggal Ajuan" label-for="v-tanggal">
                  <validation-provider #default="{ errors }" name="tanggal" rules="required">
                    <flat-pickr v-model="form.tanggal" class="form-control"
                      :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- <b-col cols="12">
              <b-form-group label="Pilih Admin Gudang" label-for="v-id_karyawan" v-if="this.user.level.id != 5">
                <validation-provider #default="{ errors }" name="id_karyawan" :rules="{ required: true }">
                  <b-form-select :state="errors.length > 0 ? false : null" v-model="form.id_karyawan"
                    :options="id_karyawan" id="v-id_karyawan" name="id_karyawan" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Pilih Admin Gudang" label-for="v-id_karyawan" v-if="this.user.level.id == 5">
                <validation-provider #default="{ errors }" name="id_karyawan" :rules="{ required: true }">
                  <b-form-select :state="errors.length > 0 ? false : null" v-model="form.id_karyawan"
                    :options="id_karyawan" id="v-id_karyawan" name="id_karyawan" disabled />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

              <!-- id_gudang -->
              <b-col cols="12">
                <b-form-group label="Pilih Gudang" label-for="v-id_gudang">
                  <validation-provider #default="{ errors }" name="id_gudang" rules="required">
                    <b-form-select :state="errors.length > 0 ? false : null" v-model="form.id_gudang"
                      :options="id_gudang" id="v-id_gudang" name="id_gudang" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- keterangan -->
              <b-col cols="12">
                <b-form-group label="Keterangan" label-for="v-keterangan">
                  <validation-provider #default="{ errors }" name="keterangan" rules="required">
                    <b-form-textarea :state="errors.length > 0 ? false : null" v-model="form.keterangan"
                      id="v-keterangan" placeholder="Isi Keterangan" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="submit"
                  variant="primary" class="mr-1">
                  Simpan
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary">
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm"
            label-for="sortBySelect" class="mb-0">
            <b-input-group size="sm">
              <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" class="my-1">
          <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm"
            label-for="filterInput" class="mb-0">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">{{ $t('Clear') }}</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
            :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
            <template #cell(no)="row">
              <strong class="text-center">
                {{ row.index + 1 }}
              </strong>
            </template>
            <template #cell(barang)="{ item }">
              {{ item.barang.name }}
            </template>
            <template #cell(status)="data">
              <b-badge :variant="istatus[1][data.value]">
                {{ istatus[0][data.value] }}
              </b-badge>
            </template>
            <template #cell(actions)="{ item }">
              <!-- <b-button v-if="item.status == 1" title="'Print / Cetak'" size="sm" @click.prevent="unduhbop(item)"
                variant="outline-warning">
                <feather-icon icon="PrinterIcon"></feather-icon>
              </b-button> -->
              <b-button v-if="item.status == 1" title="'Rincian'" size="sm"
                @click.prevent="$router.push({ name: 'route-cabang-stokopname-rincian', params: { id: item.id } })"
                variant="outline-success">
                <feather-icon icon="EyeIcon"></feather-icon>
              </b-button>
              <b-button v-if="allowUpdate() && item.status == 0" title="'List Barang'" size="sm" @click="
                $router.push({ name: 'route-cabang-stokopname-detail', params: { id: item.id } })
                " class="" variant="outline-success">
                <feather-icon icon="PackageIcon" />
              </b-button>
              <!-- <b-button v-if="allowUpdate() && item.status == 0" title="'Ubah'" size="sm" @click="edit(item)" class=""
              variant="outline-info">
              <feather-icon icon="EditIcon" />
            </b-button> -->
              <b-button v-if="allowUpdate() && item.status == 0" title="'Ubah'" size="sm"
                @click.prevent="$router.push({ name: 'route-cabang-stokopname-rincian', params: { id: item.id } })"
                class="" variant="outline-info">
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-if="(allowDelete() && item.status == 0) || (allowDelete() && item.status == 1 && isOwner || isCEO)"
                title="'Hapus'" size="sm" @click="remove(item)" class="" variant="outline-danger">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
            class="my-0" />
        </b-col>
      </b-row>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { extend } from "vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import "@core/scss/vue/libs/vue-flatpicker.scss";
extend("required", {
  ...required,
  message: "This field is required",
});
import Ripple from "vue-ripple-directive";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BForm,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea, BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions, BOverlay,
    VBTooltip,
    ToastificationContent,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormTextarea,
    flatPickr,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      form: {
        id_karyawan: "",
        tanggal: new Date(),
        id_gudang: "",
        keterangan: "",
      },
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "gudang.nama_gudang", label: "Gudang", sortable: true },
        { key: "tanggal", label: "Tanggal", sortable: true },
        // { key: "member.nama_lengkap", label: "Admin", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
        { key: "status", label: "status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      id_gudang: [],
      id_karyawan: [],
      istatus: [
        {
          0: "Belum",
          1: "Sudah",
          // 2: "Giro",
          // 3: "Nota Putih",
        },
        {
          0: "light-danger",
          1: "light-success",
          // 2: "light-warning",
          // 4: "light-primary",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async mounted() {
    // Set the initial number of items
    // const userData = JSON.parse(localStorage.getItem("userData"));
    // if (userData.id) {
    //   this.form.id_karyawan = userData.karyawan.id;
    //   if (this.myGudang) this.form.id_gudang = this.myGudang.id;
    // }

    this.getDataGudang();
    await this.getData();
    // this.getkaryawan();
  },
  methods: {
    async unduhbop(item) {
      this.loading = true
      const response = await this.$store.dispatch("tokostokopname/printAjuan", item.id);
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    // getkaryawan() {
    //   this.$store
    //     .dispatch("karyawan/getData", { jabatan_id: 5 })
    //     .then(() => {
    //       let karyawan = JSON.parse(JSON.stringify(this.$store.state.karyawan.datas));
    //       karyawan.map((item) => {
    //         item.value = item.id;
    //         item.text = item.nama_lengkap;
    //       });
    //       this.id_karyawan = karyawan;
    //     })
    //     .catch((e) => {
    //       this.displayError(e);
    //       return false;
    //     });
    // },
    add() {
      this.id = null;
      this.activeAction = "tambah";
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Stok Opname Tanggal "${item.tanggal}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("tokostokopname/save", [item])
            .then(() => {
              // this.pesanBerhasilHapus();
              this.displaySuccess({
                text: 'Tanggal Stok Opname berhasil disimpan!'
              })
              this.resetForm();
              this.getData();
            })
            .catch((e) => {
              // this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form.keterangan = null;
      this.showModal = false;
    },
    submit() {
      if (this.form.tanggal == null || this.form.tanggal == "") {
        this.pesanGagalSimpan();
        return false;
      }
      let payload = {
        tanggal: this.form.tanggal,
        // member_id: this.user.karyawan.id,
        gudang_id: this.form.id_gudang,
        keterangan: this.form.keterangan,
      };
      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("tokostokopname/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.keterangan = null;
          this.showModal = false;
          this.displaySuccess({
            text: "Stok Opname berhasil disimpan!",
          });
          this.getData();
          this.resetForm();
        })
        .catch((e) => console.error(e));
    },
    edit(item) {
      this.activeAction = "tambah";
      this.showModal = true;
      let { id, karyawan, gudang } = item
      this.id = item.id
      this.form.id = id
      this.form.tanggal = item.tanggal
      this.form.keterangan = item.keterangan
      if (karyawan) {
        this.form.id_karyawan = karyawan.id;
      }
      if (gudang) {
        this.form.id_gudang = gudang.id;
      }
    },
    getDataGudang() {
      this.$store
        .dispatch("gudang/getData", {
          jenis: 1,
        })
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.gudang.datas));
          const gudangs = [];

          // if (this.isAdminGudang) {
          //   ter.map((gudang) => {
          //     const itsMyGudang = gudang.id == this.myGudang.id;
          //     if (itsMyGudang) {
          //       this.form.gudang_id = this.myGudang.id;
          //       gudangs.push({
          //         value: gudang.id,
          //         text: gudang.nama_gudang,
          //       });
          //     }
          //   });
          // } else {
          ter.map((item) => {
            gudangs.push({
              value: item.id,
              text: item.nama_gudang,
            });
          });
          // }
          this.id_gudang = gudangs;
          this.form.id_gudang = this.id_gudang[0].value
        });
    },
    getGudangName(data) {
      let find = this.id_gudang.find((item) => item.id == data.id_gudang);
      return find ? find.nama_gudang : "-";
    },
    async getData() {
      this.loading = true
      if (this.isAdminGudang || this.isCabang || this.isSPV) {
        //gudang
        const perPage = parseInt(this.perPage);
        const currentPage = (parseInt(this.currentPage) - 1) * perPage;
        const payload = {
          search: this.filter != null ? this.filter : null,
          order: "desc",
          start: currentPage,
          length: this.perPage,
          gudang_id: this.myGudang.id,
        };
        const penyimpananBarangs = await this.$store.dispatch(
          "tokostokopname/getData",
          payload
        );
        let items = JSON.parse(JSON.stringify(this.$store.state.tokostokopname.datas));
        let items_total = this.$store.state.tokostokopname.totals;
        this.items = items;
        this.totalRows = items_total;
        this.loading = false
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
