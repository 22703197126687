export default [
  //PEsanan
  {
    path: "/pesanan",
    name: "route-pesanan",
    component: () => import("@/views/pages/heykama/pesanan/List.vue"),
    meta: {
      module: "Order",
      pageTitle: "Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/detail/:id",
    name: "route-pesanan-detail",
    component: () => import("@/views/pages/heykama/pesanan/Detail.vue"),
    meta: {
      module: "Order",
      pageTitle: "Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/pengiriman",
    name: "route-pesanan-pengiriman",
    component: () => import("@/views/pages/heykama/pesanan/AturPengiriman.vue"),
    meta: {
      module: "Order",
      pageTitle: "Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "Atur Pengiriman",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/sync",
    name: "route-pesanan-sync",
    component: () => import("@/views/pages/heykama/pesanan/Ceksync.vue"),
    meta: {
      module: "Order",
      pageTitle: "Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "Cek Sync",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/chooselensa",
    name: "route-pesanan-chooselensa",
    component: () => import("@/views/pages/heykama/pesanan/Chooselensa.vue"),
    meta: {
      module: "Order",
      pageTitle: "Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "Choose Lensa",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/manual-order",
    name: "route-pesanan-manual-order",
    component: () => import("@/views/pages/heykama/pesanan/Manualorder.vue"),
    meta: {
      module: "Order",
      parent: "/pesanan",
      pageTitle: "Manual Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "List Manual Order",
          to: "/pesanan/manual-order-list",
        },
        {
          text: "Add",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/manual-order-list",
    name: "route-pesanan-manual-order-list",
    component: () =>
      import("@/views/pages/heykama/pesanan/ListManualOrder.vue"),
    meta: {
      module: "Order",
      parent: "/pesanan",
      pageTitle: "Manual Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "List Manual Order",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/pos",
    name: "route-pesanan-pos",
    component: () => import("@/views/pages/heykama/pesanan/Kasir.vue"),
    meta: {
      module: "Order",
      parent: "/pesanan",
      pageTitle: "POS",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "POS",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pesanan/manual-order-detail/:id",
    name: "route-pesanan-manual-order-detail",
    component: () =>
      import("@/views/pages/heykama/pesanan/DetailManualOrder.vue"),
    meta: {
      module: "Order",
      parent: "/pesanan",
      pageTitle: "Manual Order",
      authRequired: true,
      breadcrumb: [
        {
          text: "List",
          to: "/pesanan",
        },
        {
          text: "List Manual Order",
          to: "/pesanan/manual-order-list",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/laporan2/add',
  //   name: 'route-laporan2-add',
  //   component: () => import('@/views/pages/laporan2/Store.vue'),
  //   meta: {
  //     parent: '/laporan2',
  //     module: 'Laporan',
  //     authRequired: true,
  //     pageTitle: 'Laporan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/laporan2',
  //       },
  //       {
  //         text: 'Add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/laporan2/edit/:id',
  //   name: 'route-laporan2-edit',
  //   component: () => import('@/views/pages/laporan2/Edit.vue'),
  //   meta: {
  //     parent: '/laporan2',
  //     module: 'Laporan',
  //     authRequired: true,
  //     pageTitle: 'Laporan',
  //     breadcrumb: [
  //       {
  //         text: 'List',
  //         to: '/laporan2',
  //       },
  //       {
  //         text: 'Edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
];
