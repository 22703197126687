<template>
  <main>
    <b-overlay :show="loading">
      <b-card :title="$t('History Scanned Items')">
        <!-- Add Button -->
        <b-row v-if="allowCreate()">
          <b-col cols="3" class="">
            <!-- <b-button @click.prevent="$router.push('/mutasi/add')" variant="primary">
              <feather-icon icon="PlusIcon" class="mr-50" />
              {{ $t('Add') }}
            </b-button> -->
          </b-col>
          <!-- <b-col cols="3">
            <b-button variant="outline-warning" @click="modal()">

              <feather-icon icon="PrinterIcon"></feather-icon> Print / Cetak
            </b-button>
          </b-col> -->
          <b-modal v-model="modalCetak" id="modal-tambah" cancel-variant="secondary" ok-title="Add" cancel-title="Batal"
            no-close-on-backdrop no-close-on-esc centered title="Pilih Tanggal yang akan di print"
            v-b-modal.modal-no-backdrop>
            <b-form>
              Dari :
              <b-form-group>
                <b-form-datepicker id="example-datepicker" v-model="tgl" class="mb-1" />
              </b-form-group>
              Ke :
              <b-form-group>
                <b-form-datepicker id="example-datepicker" v-model="tglend" class="mb-1" />
              </b-form-group>
              Pilih Gudang Penerima :
              <b-form-group>
                <v-select :options="dataGudang" v-model="gudangSelect" label="text" />
              </b-form-group>
            </b-form>

            <template #modal-footer>
              <section class="d-flex justify-content-end align-items-center">
                <b-button size="sm" class="mr-1" variant="primary" @click="unduhbop">
                  Print / Cetak
                </b-button>
              </section>
            </template>
          </b-modal>
        </b-row>
        <!-- / -->

        <!-- Filter table -->
        <b-row>
          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
          </b-col>
          <b-col md="4" sm="8" class="my-1">
            <!-- <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm"
              label-for="sortBySelect" class="mb-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-75">
                  <template v-slot:first>
                    <option value="">-- none --</option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                  <option :value="false">Asc</option>
                  <option :value="true">Desc</option>
                </b-form-select>
              </b-input-group>
            </b-form-group> -->

            <b-form-group :label="$t('Range Date')" label-cols-sm="3" label-align-sm="right" label-size="sm">
              <b-input-group size="sm">
                <flat-pickr v-model="rangeDate" class="form-control" :config="{ mode: 'range' }" />

                <!-- <b-form-input id="filterInput" v-model="filter" type="search" /> -->
                <b-input-group-append>
                  <!-- <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button> -->
                  <b-button @click="rangeDateget()" variant="primary">
                    Search
                  </b-button>
                  <b-button @click="rangeDatenull()" variant="outline-danger">
                    Reset
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form-group :label="$t('Search')" label-cols-sm="3" label-align-sm="right" label-size="sm"
              label-for="filterInput" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Tekan enter untuk cari"
                  @keydown.enter.prevent="getHistory()" />
                <b-input-group-append>
                  <!-- <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button> -->
                  <!-- <b-button @click="rangeDateget()" variant="primary">
                    Search
                  </b-button> -->
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table striped hover small responsive :per-page="perPage" :items="mutations" :fields="fields">
              <template #cell(no)="row">
                <strong class="text-center">
                  {{ row.index + 1 }}
                </strong>
              </template>
              <template #cell(kode)="{ item }">
                <!-- {{ item.asal_stok.barang ? item.asal_stok.barang.nama : item.asal_stok.barang_id }} -->
                <!-- <strong>{{ item.barang ? item.barang.nama : "-" }}</strong> -->
                {{ item.barang ? item.barang.kode : "-" }}<br>
                <!-- <small>{{ item.barang.kategori.kategori }}</small> / -->
                <small>
                  {{ item.barang ? item.barang.no_batch : "-" }}
                </small> <br>
              </template>
              <template #cell(barang)="{ item }">
                <!-- {{ item.asal_stok.barang ? item.asal_stok.barang.nama : item.asal_stok.barang_id }} -->
                <!-- <strong>{{ item.barang ? item.barang.nama : "-" }}</strong> -->

                {{ item.barang ? item.barang.nama : "-" }}<br>
                <!-- <small>{{ item.barang.kategori.kategori }}</small> / -->
                <small>
                  {{ item.barang ? item.barang.varian : "-" }}
                </small>
              </template>
              <!-- <template #cell(created_at)="{ item }">
                {{ item.created_at ? humanDateTime(item.created_at) : '-' }}
              </template> -->
              <template #cell(scanned_qty)="{ item }">
                {{ formatRupiah(item.scanned_qty) }}
              </template>
              <template #cell(creator)="{ item }">
                {{ item.creator ? item.creator.nama_lengkap : '-' }}
              </template>
              <!-- <template #cell(appr)="data">
                <b-badge :variant="iappr[1][data.value]">
                  {{ iappr[0][data.value] }}
                </b-badge>
              </template> -->

              <template #cell(actions)="{ item }">
                <section class="flex item-center">
                  <b-button size="sm" @click="remove(item)" class="mr-1" variant="outline-danger">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </section>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
              class="my-0" />
          </b-col>
        </b-row>
        <!-- / -->
      </b-card>
    </b-overlay>
  </main>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import {
  BCard,
  BFormDatepicker,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BInputGroupAppend,
  BBadge,
  BModal,
} from "bootstrap-vue";
export default {
  components: {
    BModal,
    BCard,
    flatPickr,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BInputGroupAppend,
    BBadge,
    vSelect,
    BFormDatepicker,
  },
  data() {
    return {
      dataGudang: [],
      gudangSelect: null,
      tgl: this.getCurrentDate(),
      tglend: this.getCurrentDate(),
      modalCetak: false,
      params: {},
      totalRows: 1,
      filter: null,
      filterOn: [],
      id: null,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      perPage: 10,
      pageOptions: [10, 50, 100],
      mutations: [],
      loading: false,
      iappr: [
        {
          0: "Belum",
          1: "Sudah",
          // 2: "Giro",
          // 3: "Nota Putih",
        },
        {
          0: "light-danger",
          1: "light-success",
          // 2: "light-warning",
          // 4: "light-primary",
        },
      ],
      start_date: null,
      end_date: null,
      rangeDate: null,
      fields: [
        { key: "no", label: "No" },
        { key: "scanned_at", label: "Saved scan date" },
        { key: "kode", label: "Kode/SKU" },
        { key: "barang", label: "Barang" },
        { key: "scanned_qty", label: "Scan qty" },
        { key: "creator", label: "Creator" },
        // { key: "jumlah", label: "Jumlah yang dimutasi" },
        // { key: "satuan", label: "satuan" },
        // { key: "appr", label: "Status" },
        // {key: 'satuan2', label: 'satuan'},
        // { key: "actions", label: "#" },
      ],
    }
  },
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getHistory();
      }
    },
    // filter(keyword) {
    //  if (keyword.length > 2 || keyword.length == 0) {
    //    this.getHistory();
    // }
    //},
    currentPage(val) {
      if (val) {
        this.getHistory();
        // this.items
      }
    },
  },
  methods: {
    rangeDateget() {
      const [start, end] = this.rangeDate.split(" to ");
      // Assign the values to your variables
      this.start_date = start;
      this.end_date = end;
      this.getHistory()
    },
    rangeDatenull() {
      // const [start, end] = this.rangeDate.split(" to ");
      // Assign the values to your variables
      this.rangeDate = null
      this.start_date = null;
      this.end_date = null;
      this.getHistory()
    },
    modal() {
      this.modalCetak = true;
    },
    async getDataGudang() {
      const gudangs = await this.$store.dispatch("gudang/getData", { jenis: 1 });
      gudangs.map((gudang) => {
        if (gudang.id != this.myGudang.id) {
          this.dataGudang.push({
            value: gudang.id,
            text: gudang.nama_gudang,
          });
        }
      });
    },
    async unduhbop() {
      try {

        this.loading = true;
        const response = await this.$store.dispatch("mutasi/printAjuan", {
          id_gudang: this.myGudang.id,
          id_gudang_tujuan: this.gudangSelect.value,
          tgl_awal: this.tgl,
          tgl_akhir: this.tglend,
        })
        this.loading = false;
        window.open(URL.createObjectURL(response));
        this.displaySuccess({
          message: "Data tersedia",
        });
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data Mutasi Barang ini akan terhapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        const allowed = result.value;
        if (allowed) {
          this.loading = true;
          let payload = null
          // item.fungsi = 1;
          // item.map((item) => {
          //   payload.push({
          //     id: item.id
          //   })
          // })
          payload = { id: item.id }
          // soft delete
          this.$store
            .dispatch("mutasi/deleteToko", [payload])
            .then(() => {
              this.loading = false;
              this.displaySuccess({
                message: "Data berhasil dihapus, dan jumlah mutasi sudah dikembalikan ke gudang asal",
              });
              this.getHistory();
            })
            .catch((e) => {
              this.loading = false;
              this.displayError(e);
              return false;
            });
        }
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getHistory() {
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      var params = {
        search: this.filter != null ? this.filter : null,
        // order: "desc",
        start: currentPage,
        length: this.perPage,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.loading = true;
      this.$store
        .dispatch("outbond/getHistory", params)
        .then((mutations) => {
          // console.log('cek', mutations.data)
          // let hem = JSON.parse(JSON.stringify(this.$store.state.outbond.datahiss));
          // let hemtotal = JSON.parse(JSON.stringify(this.$store.state.outbond.totalhiss));
          this.mutations = mutations.data.data;
          this.totalRows = mutations.data.recordsTotal;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
  },
  created() {
    this.getHistory();
    this.getDataGudang();
  },
};
</script>